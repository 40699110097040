import {call,put, takeLatest, take} from "redux-saga/effects";
import { fetchDeletedFoldersApi, fetchFavorisFoldersApi, fetchFileApi, fetchFileByIdUserApi, fetchShareFoldersApi, fetchSubFolderApi } from "../../api";
import { GET_FOLDER_BY_ID_USER_LIST, GET_FOLDER_LIST,GET_FOLDER_LIST_BY_ID,WATCH_DELETED_FOLDERS_LIST,WATCH_FAVORIS_FOLDER_LIST,WATCH_FOLDERBY_ID_USER_LIST,WATCH_FOLDER_LIST, WATCH_SHARE_FOLDER_LIST, WATCH_SUB_FOLDER_LIST } from "../actionTypes";

function* fetchFolderAsyn() {
    const Data = yield call(fetchFileApi);
    console.log(Data)
    yield put({type : GET_FOLDER_LIST,folders:Data});
}
function* fetchFavorisFolderAsyn() {
    const Data = yield call(fetchFavorisFoldersApi);

    yield put({type : GET_FOLDER_LIST,folders : Data});
}
function* fetchDeletedFolderAsyn() {
    const Data = yield call(fetchDeletedFoldersApi);
    console.log(Data)
    yield put({type : GET_FOLDER_LIST,folders:Data});
}
function* fetchShareFolderAsyn() {
    const Data = yield call(fetchShareFoldersApi);
    console.log(Data)
    yield put({type : GET_FOLDER_LIST,folders:Data});
}
function* fetchFolderByIdUserAsyn({id}) {
    const Data = yield call(fetchFileByIdUserApi,id);
    console.log(Data)
    yield put({type : GET_FOLDER_BY_ID_USER_LIST,folders:Data});
}
export function* watchFolderList() {
    
    yield takeLatest(WATCH_FOLDER_LIST,fetchFolderAsyn)
    yield takeLatest(WATCH_FAVORIS_FOLDER_LIST,fetchFavorisFolderAsyn)
    yield takeLatest(WATCH_DELETED_FOLDERS_LIST,fetchDeletedFolderAsyn)
    yield takeLatest(WATCH_FOLDERBY_ID_USER_LIST,fetchFolderByIdUserAsyn)
    
    yield takeLatest(WATCH_SHARE_FOLDER_LIST,fetchShareFolderAsyn)
}

function* fetchSubFolderAsyn({id}) {


    const Data = yield call(fetchSubFolderApi,id);

    yield put({type : GET_FOLDER_LIST_BY_ID,folders:Data});
}

export function* watchSubFolderList() {
 
 
    yield takeLatest(WATCH_SUB_FOLDER_LIST,fetchSubFolderAsyn)
}