import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Media, CardBody, Table } from 'reactstrap'
import {Email,MarekjecnoMailId,BOD,DDMMYY,Designer,ContactUs,ContactUsNumber,LocationDetails,JOHANDIO,UserProfileDesc1,UserProfileDesc2,UserProfileDesc3,Comment,MarkJecno,Like,Follower,Following,Location, Adresse, Dashboard, Summary, Daily, Weekly, Monthly, Yearly, Online, Store, ReferralEarning, CashBalance, SalesForcasting} from '../../../constant'
import { useLocation } from 'react-router';
import { Link, useHistory, useParams } from 'react-router-dom'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { useDispatch } from 'react-redux';
import { manageStateUser } from '../../../redux/user/action';
import ApexCharts from 'react-apexcharts'
import ChartistChart from 'react-chartist';
import Knob from "knob";
import { Currentlysale, Marketvalue } from '../../dashboard/chartsData/apex-charts-data'
import { manageStateSubfranchise } from '../../../redux/subfranchise/action';
import { fetchallInventairesDipensesByIdApi, fetchDispensesApi, fetchFranchiseByIdApi, fetchInventairesannualleDipensesByIdApi, fetchInventairesApi, fetchInventairesDipensesByIdApi, fetchInventairesJouDipensesByIdApi, fetchInventairesmensulleDipensesByIdApi, fetchSubFranchiseByIdsubApi } from '../../../api';
import ConfigDB from '../../../data/customizer/config';
import moment from 'moment';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;

const SubFranchiseDetail = (props) => {
  const history = useHistory()
  const {idSubFranch} = useParams();
  const location = useLocation();
  const [url, setUrl] = useState();
  const [stateUser, setStateUser] = useState();
  const [subfranchise, setSubfranchise] = useState({});
  const [franchise, setFranchise] = useState({});
  const [dipenses, setDipenses] = useState([]);
  const [inventaires, setInventaires] = useState([]);
  const [revenueanuelle,setrevenueanuelle] = useState(0)
  const [revenuemensuelle,setrevenuemensulle] = useState(0)
  const [revenuejournalier,setrevenuejournalier] = useState(0)
  const [dipenseanuelle,setdipenseanuelle] = useState(0)
  const [dipensemensuelle,setdipensemensulle] = useState(0)
  const [dipensejournalier,setdipensejournalier] = useState(0)


  const [activetab,setActivetab] = useState("journalier")

   
  const [listeDayInventaire,setlisteDayInventaire] = useState([])
  const [listemonthInventaire,setlisteMonthInventaire] = useState({monthcat : [],dipenseVal : [],gainVal : []})
  const [listeAnnuaireInventaire,setlisteAnnuaireInventaire] = useState({yearcat : [],dipenseVal : [],gainVal : []})
  
  const [searchText, setSearchText] = useState("")
  const [journalierchartstate, setjournalierchartstate] = useState({
    datadip : [],datagain : [],datacat :[]
  })

  useEffect(() => {
 
    fetchSubFranchiseByIdsubApi(idSubFranch).then(res => {
       if(res.franchise_name === undefined)
      history.goBack()
      console.log(res)
      setSubfranchise(res)
      setStateUser(res.subfranchise_state)
     // fetchDispensesApi(res.subfranchise.id).then(res => setDipenses(res))
      getInventairelisteByIdFranchise(idSubFranch)
      fetchallInventairesDipensesByIdApi(idSubFranch).then(res => {
        setInventaires(res)
      })
    //  dispatch({type: WATCH_SUBFRANCHISE_LIST})
   
    })
  
  
  

 }, []);
  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }
  const dispatch = useDispatch()
  const handleActivateChange = (e) => {
    if(e.target.checked === true){
      setStateUser(true)
      dispatch(manageStateSubfranchise(subfranchise.id, 'true'))
//dispatch(manageStateUser(user.id, true))

}
  else {
    setStateUser(false)
    dispatch(manageStateSubfranchise(subfranchise.id, 'false'))
  //  dispatch(manageStateUser(user.id, false))

  }
  }
  const getInventairelisteByIdFranchise = (idSubfranchise) => {
    let now =new Date()
    let lastdate = moment(now).format("YYYYMM")
    let year = now.getFullYear()
    fetchInventairesJouDipensesByIdApi(idSubfranchise,lastdate,year).then(res => {
      console.log(res)
  
  let journaliercharts = {}
  let journalierchartsgain = []
  
  
  let inventaireuser = res
  if(inventaireuser.length > 0){
  
    let datadip = []
    let datagain = []
    let datacat = []
    let toatlrevenue = 0
    let toatldipense = 0
  
    inventaireuser?.map((daysinver,idaysinventaire) => {
  datadip.push(Number(daysinver.dipense.replace(',', '')))
  datagain.push(Number(daysinver.gain.replace(',', '')))
  datacat.push(daysinver.date)
  toatlrevenue +=Number(daysinver.gain.replace(',', ''))
  toatldipense +=Number(daysinver.dipense.replace(',', ''))
    })
    setrevenuejournalier(toatlrevenue)
    setdipensejournalier(toatldipense)
    setjournalierchartstate({
      datadip : datadip,datagain : datagain,datacat :datacat
    })
  
  }
  else {
    setrevenuejournalier(0)
    setdipensejournalier(0)
    setjournalierchartstate({
      datadip : [],datagain : [],datacat :[]
    })
  }
  })
  fetchInventairesmensulleDipensesByIdApi(idSubfranchise,year).then(res => {
    console.log(res)
  let mensuellechartscat = []
  let mensuellechartsdipense = []
  let mensuellechartsgain = []
  
  let toatlrevenuemen = 0
  let toatldipensemen = 0
  res.map((inventaire ,index) =>{
    toatlrevenuemen = +inventaire.gain
    toatldipensemen = +inventaire.dipense
  mensuellechartscat.push(inventaire.monthName)
  mensuellechartsdipense.push(inventaire.dipense)
  mensuellechartsgain.push(inventaire.gain)
  
  })
  setrevenuemensulle(toatlrevenuemen)
  setdipensemensulle(toatldipensemen)
  
  
    setlisteMonthInventaire({monthcat : mensuellechartscat,dipenseVal : mensuellechartsdipense,gainVal : mensuellechartsgain})
    })
    fetchInventairesannualleDipensesByIdApi(idSubfranchise).then(res => {
      console.log(res)
  
    
    let annuellechartscat = []
    let annuellechartsdipense = []
    let annuellechartsgain = []
       let toatlrevenueann = 0
      let toatldipenseann = 0
      res.map((inventaire ,index) => {
        toatlrevenueann = +inventaire.gain
        toatldipenseann = +inventaire.dipense
    
            annuellechartscat.push(inventaire.year)
            annuellechartsdipense.push({year : inventaire.year , totaldipense : inventaire.dipense})
            annuellechartsgain.push({year : inventaire.year , totalgain : inventaire.gain})
          })
      
        
      
        setrevenueanuelle(toatlrevenueann)
        setdipenseanuelle(toatldipenseann)
        setlisteAnnuaireInventaire({yearcat : annuellechartscat,dipenseVal : annuellechartsdipense,gainVal : annuellechartsgain})
    })
  }
  return (
    <Fragment>
  <Breadcrumb parent="Enseignes" title="Détails Franchisé ou restaurant" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <CardHeader className="cardheader" style={{backgroundImage :`url(${subfranchise.franchise_image})` ,backgroundSize : 'cover'}}></CardHeader>
                <div className="user-image">
                  <div className="avatar"><Media body alt="" src={subfranchise ? subfranchise.manager_image : require('../../../assets/images/user/7.jpg')} data-intro="This is Profile image" /></div>
                  <div className="icon-wrapper" data-intro="Change Profile image here">
                    {/* <i className="icofont icofont-pencil-alt-5">
                      <input className="upload" type="file" onChange={(e) => readUrl(e)} />
                    </i> */}
                  </div>
                </div>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row >
                        <Col md="6">
                          <div className="ttl-info text-left">
                            <h6><i className="fa fa-align-justify mr-2"></i> {"Nom Franchisé ou restaurant"}</h6><span>{subfranchise.franchise_name}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-calendar"></i>   {"Description"}</h6><span>{subfranchise.subfranchise_bio}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title"><a target="_blank" href="#javascript">{subfranchise.manager_name}</a></div>
                      
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="4">
                          <div className="ttl-info text-left ttl-xs-mt">
                            <h6><i className="fa fa-phone"></i> {ContactUs}</h6><span>{subfranchise.phone_number}</span>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-location-arrow"></i> {Adresse}</h6><span>{subfranchise.adresse + " " + (subfranchise.ville !== undefined ? subfranchise.ville : "") + " " +(subfranchise.pays !== undefined  ? subfranchise.pays : "") }</span>
                          </div>
                        </Col>
                        <Col md="4">
                        <div className="ttl-info text-left ttl-sm-mb-0" data-intro="This is your Social details">
                        <h6>{"Désactiver"}</h6>
                  <Toggle
  //defaultChecked={stateUser}
  value={stateUser}
  aria-label='Activate'
  onChange={handleActivateChange} 
 />


                  </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                 
                  {/* <div className="social-media step4" data-intro="This is your Social details">
                    <ul className="list-inline">
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                    </ul>
                  </div>
                  <div className="follow">
                    <Row>
                      <Col col="6" className="text-md-right border-right">
                        <div className="follow-num counter">{"25869"}</div><span>{Follower}</span>
                      </Col>
                      <Col col="6" className="text-md-left">
                        <div className="follow-num counter">{"659887"}</div><span>{Following}</span>
                      </Col>
                    </Row>
                  </div> */}
                </div>
              </Card>
            </Col>
            <Col xl="12 xl-100" className="dashboard-sec box-col-12">
            <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  
                  <Col xl="12" className="p-0">
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                          <div className="inner-top-left">
                            <ul className="d-flex list-unstyled">
                              <li onClick={() => setActivetab('journalier')} className={ activetab === 'journalier' ? 'active' : ''}>{Daily}</li>
                             
                              <li onClick={() => setActivetab('mensuelle')} className={ activetab === 'mensuelle' ? 'active' : ''}>{Monthly}</li>
                              <li onClick={() => setActivetab('annuelle')} className={ activetab === 'annuelle' ? 'active' : ''}>{Yearly}</li>
                            </ul>
                          </div>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                            {activetab === 'journalier' ? 
                              <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: journalierchartstate.datacat,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: -15,
        top: -40
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépense',
    data: journalierchartstate.datadip
  }, {
    name: 'Gain',
    data: journalierchartstate.datagain
  }]} type='area' height={240} /> 
  : activetab === 'hebdomadaire' ?  <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: journalierchartstate.datacat,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: -15,
        top: -40
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépenses',
    data: journalierchartstate.datadip
  }, {
    name: 'Gain',
    data: journalierchartstate.datagain
  }]} type='area' height={240} /> :  activetab === 'mensuelle' ? <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: listemonthInventaire.monthcat,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: -15,
        top: -40
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépenses',
    data: listemonthInventaire.dipenseVal
  }, {
    name: 'Gain',
    data: listemonthInventaire.gainVal
  }]} type='area' height={240} /> : <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: listeAnnuaireInventaire.yearcat,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: -15,
        top: -40
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépense',
    data: listeAnnuaireInventaire.dipenseVal.map(res => res.totaldipense)
  }, {
    name: 'Gain',
    data: listeAnnuaireInventaire.gainVal.map(res => res.totalgain)
  }]} type='area' height={240} />}
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <Row className="border-top m-0">
                      <Col xl="4" md="6" sm="6" className="pl-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-sort-alt"></i></div>
                          <div className="media-body">
                            <h6>{"Total Revenue"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{revenuejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{revenuemensuelle}</p> : activetab === 'mensuelle' ?   <p>{revenuemensuelle}</p>:  <p>{revenueanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="6" sm="6">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-sort-alt"></i></div>
                          <div className="media-body">
                            <h6>{"Total Dépenses"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{dipensejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{dipensemensuelle}</p> : activetab === 'mensuelle' ?   <p>{dipensemensuelle}</p>:  <p>{dipenseanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="12" className="pr-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-cur-euro"></i></div>
                          <div className="media-body">
                            <h6>{"Total Gain"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{revenuejournalier - dipensejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{revenuemensuelle -  dipensemensuelle}</p> : activetab === 'mensuelle' ?   <p>{revenuemensuelle - dipensemensuelle}</p>:  <p>{revenueanuelle - dipenseanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12">
            <Row>
              <Col sm="6"> 
               <Card>
                                <CardHeader>
                                    <h5>{"Liste Revenue"}</h5>
                                      </CardHeader>
                                <div className="table-responsive">
                                {inventaires.map((inv, i) => ( <>
                                  <h5>{inv.monthName}</h5>
                                    <Table>
                                        <thead>
                                            <tr>
                                
                                                <th scope="col">{"date"}</th>
                                                <th scope="col">{"Valeur"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                         {
                                              inv.daysListeInventaire.map((inccop ,index) =>(
  <tr className="table-primary">
 

  <td>{inccop.date}</td>
  <td>{inccop.gain}</td>
</tr> 
                                          ))}
                                          
                                          
                                        </tbody>
                                    </Table>  </>  ))}
                                </div>
                            </Card></Col>
                            <Col sm="6">  <Card>
                                <CardHeader>
                                    <h5>{"Liste Dépenses"}</h5>
                                        </CardHeader>
                                <div className="table-responsive">
                                {inventaires.map((inv, i) => ( <>
                                  <h5>{inv.monthName}</h5>
                                    <Table>
                                        <thead>
                                            <tr>
                                          
                                                <th scope="col">{"date"}</th>
                                                <th scope="col">{"Valeur"}</th>
                                            </tr>
                                        </thead>
                                      
                                        <tbody>
                           
                                       
                                         {
                                            inv.daysListeInventaire.map((inccop ,index) => (
<tr className="table-primary">


<td>{inccop.date}</td>
<td>{inccop.dipense}</td>
</tr> 
                                        ))}
                                        </tbody>
                                    </Table>
                                  </>  ))}
                                </div>
                            </Card></Col>
            </Row>
        
            </Col>
            {/* <Col sm="12" >
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc1}</p>
                  <div className="img-container">
                    <div id="aniimated-thumbnials">
                      <a href="#javascript">
                        <Media className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img3.png")} alt="gallery" />
                      </a>
                    </div>
                  </div>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc1}</p>
                  <Row className="mt-4 pictures">
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                  </Row>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc2}</p>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col> */}

            {/* <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="12" xl="4">
                      <div id="aniimated-thumbnials-3"><a href="#javascript"><Media body className="img-fluid rounded" src={require("../../../assets/images/blog/img.png")} alt="gallery" /></a></div>
                      <div className="like-comment mt-4 like-comment-lg-mb">
                        <ul className="list-inline">
                          <li className="list-inline-item border-right pr-3">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                          </li>
                          <li className="list-inline-item ml-2">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xl="6">
                      <p>{UserProfileDesc3}</p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col> */}
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default SubFranchiseDetail;