import React,{useState, useReducer, useEffect} from 'react'
import './sticky.css';
import {v4 as uuid} from 'uuid'
import { Button, Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Plus, Save, Trash } from 'react-feather';
import SweetAlert from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { deleteNotes, NewNotes, UpdateNotes } from '../../redux/note/action';
import { Close, Textarea } from '../../constant';
import moment from 'moment';
import { WATCH_NOTE_LIST } from '../../redux/actionTypes';




function AppSticky({notes}) {


const [noteText, setNoteText] = useState('')
const [titreNote, setTitreNore] = useState('')
const [textNote, setTextNore] = useState('')
const dispatch = useDispatch()
const [addNoteState, setaddNoteState] = useState(false)

const [listenote, setListeNote] = useState([{id : '0' ,titre : "", text : ""}])
const AddNotetoggle = () => setaddNoteState(!addNoteState)
useEffect(() => {

  

},[])
const addNote = () => {


if(!textNote){
  return;
}
if(!titreNote){
    return;
  }
const newNote = {

  text: textNote,
  titre : titreNote,
  user_id : localStorage.getItem("@intranetidUser"),
  creation_note_date : moment(new Date()).format('yyyy-MM-DD HH:mm')

}
 dispatch(NewNotes(newNote))
 setTimeout(() => {
  dispatch({type : WATCH_NOTE_LIST})
 }, 1500);

 AddNotetoggle()
 setTitreNore("")
 setTextNore("")
}



const updateNote = (note) => {
    let newNote= note

    if(textNote){
        newNote.text = textNote
    
    }
    if(titreNote){
        newNote.titre = titreNote
      }
 
     dispatch(UpdateNotes(newNote))
     setTitreNore("")
     setTextNore("")
    }
    
const deleteNote = (id) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
      dispatch(deleteNotes(id))
   
    }
    else {
      SweetAlert.fire(
        'Votre note est sauvgardée'
      )
    }
  })

}
const [activeIndex, setActiveIndex] = useState(0);
const [animating, setAnimating] = useState(false);

const next = () => {
  if (animating) return;
  const nextIndex = activeIndex === notes.length - 1 ? 0 : activeIndex + 1;
  setActiveIndex(nextIndex);
}

const previous = () => {
  if (animating) return;
  const nextIndex = activeIndex === 0 ? notes.length - 1 : activeIndex - 1;
  setActiveIndex(nextIndex);
}

const goToIndex = (newIndex) => {
  setActiveIndex(newIndex);
  if (animating) return;
}
  return (
<>
<style>
          {
            `.carousel {
                margin-top: 0;
                height: 100%;
               
              }
              .carousel-inner {
               
                height: 100%;
               
              }
              .carousel-item {
               
                height: 100%;
               
              }.sticky-note {
                margin: 0px;
                }`
          }
        </style>
        {notes.length > 0 ?
<Carousel
interval={false}

style={{height: '100%', marginTop : 0}}
    activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
        
{/* Display the added notes */}

{notes.map((note,i)=> (
      <CarouselItem
      
      style={{height: '100%'}}
      //onExiting={() => setAnimating(true)}
      key={i+1}
    ><div  className="sticky-note" id="board" style={{height: '100%'}}>
  <div className={`note ui-draggable ui-draggable-handle `} style={{margin: 0,height: '100%',borderRadius : '15px',backgroundColor : '#000000'}}  key={i+1}>
    <div style={{flexDirection : 'row', marginLeft: '85%'}}>
    <a style={{color : 'white'}} onClick={() => AddNotetoggle()} ><Plus size={15}/></a>
    <a style={{color : 'white'}}  onClick={() => updateNote(note)}   ><Save size={15}/></a>
                           <a style={{color : 'white'}}  onClick={() => deleteNote(note.note_id)}   ><Trash size={15}/></a>
                        
    </div>
                        
                          <div className="note_cnt">
                            <textarea onChange={(e) => setTitreNore(e.target.value)} className="titlesticky" defaultValue={note.titre} placeholder="Titre de la note " style={{ height: "64px" ,color: 'white'}}></textarea>
                            <textarea  onChange={(e) => setTextNore(e.target.value)} className="cnt" defaultValue={note.text} placeholder="Contenu de la note" style={{ height: "250px",minHeight: 150 ,color: 'white'}}></textarea>
                          </div>
                        </div>
                        </div>
    </CarouselItem>
 
))} 

   <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
</Carousel> : 
<Carousel
interval={false}

style={{height: '100%', marginTop : 0}}
    activeIndex={activeIndex}
      
    >
        
{/* Display the added notes */}


{listenote.map((note,i)=> (
      <CarouselItem
      
      style={{height: '100%'}}
      //onExiting={() => setAnimating(true)}
      key={i+1}
    ><div  className="sticky-note" id="board" style={{height: '100%'}}>
  <div className={`note ui-draggable ui-draggable-handle `} style={{margin: 0,height: '100%',borderRadius : '15px',backgroundColor : '#000000'}}  key={i+1}>
    <div style={{flexDirection : 'row', marginLeft: '85%'}}>
    <a style={{color : 'white'}} onClick={() => AddNotetoggle()} ><Plus size={15}/></a>
               
    </div>
                        
                          <div className="note_cnt">
                            <textarea onChange={(e) => setTitreNore(e.target.value)} className="titlesticky" defaultValue={note.titre} placeholder="Titre de la note " style={{ height: "64px" ,color: 'white'}}></textarea>
                            <textarea  onChange={(e) => setTextNore(e.target.value)} className="cnt" defaultValue={note.text} placeholder="Contenu de la note" style={{ height: "250px",minHeight: 150 ,color: 'white'}}></textarea>
                          </div>
                        </div>
                        </div>
    </CarouselItem>
 
))} 
  
</Carousel>
}
<Modal isOpen={addNoteState} toggle={AddNotetoggle}>
               <ModalHeader toggle={AddNotetoggle}>
                {"Ajouter Note"}
               </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Titre Note:"}</Label>
                      <Input onChange={(e) => setTitreNore(e.target.value)}  value={titreNote} className="form-control" type="text" />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Texte Note:"}</Label>
                      <textarea onChange={(e) => setTextNore(e.target.value)}  value={textNote} className="form-control" type="text"></textarea>
                    </FormGroup>
                   
                  </Form>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={AddNotetoggle}>{Close}</Button>
                      <Button color="primary" onClick={addNote}>{"Ajouter"}</Button>
                </ModalFooter>
          </Modal>
</>
  );
}

export default AppSticky;