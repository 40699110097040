import React,{Fragment, useState,useEffect,useRef} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,Button,Media, CardBody} from 'reactstrap'
import Tablet from './tabsets';
import Slider from 'react-slick';
import {useHistory, useParams} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import { addToCart,removeProducts,watchfetchSingleProducts } from '../../../redux/ecommerce/product/action';
import { ADD_TO_CART, ADD_TO_WISHLIST } from '../../../redux/actionTypes'
import Ratings from 'react-ratings-declarative'
import { ProductReview,  Brand, Availability, AddToCart, BuyNow } from "../../../constant";
import { Truck, Gift,CreditCard,Clock } from 'react-feather';
import SweetAlert from 'sweetalert2'
import { fetchProductsByIdApi } from '../../../api';
import { urlbackend } from '../../../data/config';
const  ProductDetailProvider = (props)  => {
    const {idProduct} = useParams()
    const history = useHistory()
    const products = useSelector(content => content.data.products)
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [rating,setRating] = useState(0)
    const [singleItem,setsingleItem] = useState(null)
    // eslint-disable-next-line
    const [quantity,Setquantity] = useState(1)
    
    const slider1 = useRef();
    const slider2 = useRef();
    const dispatch = useDispatch()

    useEffect(() => {
        fetchProductsByIdApi(idProduct).then(res => {
            if(res.product_name !== undefined) 
            setsingleItem(res)
            else 
            history.push(`${process.env.PUBLIC_URL}/app/ecommerce/product-list/`)
         
            //setStateUser(res.user_state === "1" ? true : false)
              })
        setState({
            nav1: slider1.current,
            nav2: slider2.current
          });
      } ,[]);
    const { nav1, nav2 } = state;
    
    const symbol = useSelector(content => content.data.symbol)

    
    const RemoveProduct  = (Id ) => {
        SweetAlert.fire({
          title: 'Vous Êtes sûr?',
          text: "Une fois supprimé, vous ne pourrez plus récupérer ",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'cancel',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
           dispatch(removeProducts(Id));
           history.push(`${process.env.PUBLIC_URL}/app/ecommerce/product-list/`)
            SweetAlert.fire(
              'Supprimé!',
              'Votre produit a été supprimée',
              'success'
            )
          }
          else {
          
          }
        })
      }
    return (
        <Fragment>
                <Breadcrumb parent="Produits" title="Détails produit"/>
                <Container fluid={true}>
                   <Row>
                       <Col>
                       <Card>
                        <Row className="product-page-main">
                            <Col xl="4">
                            {/* <div className="item" >
                                                <Media src={singleItem.productImage} alt="" className="img-fluid" />
                                            </div> */}
                                <Slider  
                                    asNavFor={nav2} 
                                    arrows= {false}
                                        ref={slider => (slider1.current = slider)} className="product-slider">
                                    {singleItem?.imageListe?.length > 0 ? singleItem.imageListe.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <Media src={`${urlbackend}${item.media_contenu}`} alt="" className="img-fluid" />
                                            </div>
                                        )
                                    }) :
                                    singleItem?.product_image !== undefined ? <Media src={singleItem?.product_image} alt="" className="img-fluid" /> : ''
                                    }   
                                    </Slider>
                                    <Slider asNavFor={nav1}
                                    ref={slider => (slider2.current= slider)}
                                    slidesToShow={4}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    infinite={true}
                                    className="small-slick">
                                    {singleItem?.imageListe?.length > 0  ? singleItem.imageListe.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <Media src={`${urlbackend}${item.media_contenu}`} alt="" className="img-fluid" />
                                            </div>
                                        )
                                    }) : ''}
                                </Slider>
                            </Col>
                            <Col xl="5 xl-100">
                                <Card>
                                    <CardBody>
                                    <div className="product-page-details">
                                        <h3>{

                                        singleItem?.product_name !== undefined ? singleItem?.product_name : ""}</h3>
                                    </div>
                                
                                    {singleItem?.sizeListe?.length > 0 ?
                                    <div className="product-size">
                                        Liste taille
                            <ul>
                              {singleItem?.sizeListe?.map((items,i) => 
                                <li key={i}>
                                  <Button style={{width : 100}} color="outline-light">{items.size_name}</Button> : <Button style={{width : 150}}  color="outline-light">{items.size_price} {symbol}</Button>
                                  
                                </li>
                              )}
                            </ul>
                          </div> :     <div className="product-price f-28">
                                        {symbol}{
                                        singleItem?.product_price !== undefined ? singleItem?.product_price : ""
                                        }
                                        {/* <del>{symbol}{singleItem.discountPrice}</del> */}
                                    </div>
                          }
                                    {singleItem?.productColor !== undefined ?
                                    <ul className="product-color m-t-15">
                                        <li className="bg-primary"></li>
                                        <li className="bg-secondary"></li>
                                        <li className="bg-success"></li>
                                        <li className="bg-info"></li>
                                        <li className="bg-warning"></li>
                                    </ul>  : <></>}
                                    <hr/>
                                    <p>{singleItem?.product_desc !== undefined ? singleItem?.product_desc : ""}</p>
                                    <hr/>
                                    <div>
                                        <table className="product-page-width">
                                        <tbody>
                                            <tr>
                                            <td> <b>{"Marque"} &nbsp;&nbsp;&nbsp;:</b></td>
                                            <td>{
                                            singleItem?.product_marque !== undefined ? singleItem?.product_marque : ""
                                            }</td>
                                            </tr>
                                            <tr>
                                            <td> <b>{"Disponibililé"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td className="txt-success">{
                                            singleItem?.product_quantity !== undefined ? singleItem?.product_quantity : ""
                                            }</td>
                                            </tr>
                                            {/* <tr>
                                            <td> <b>{"Seller"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td>{"ABC"}</td>
                                            </tr> */}
                                            {/* <tr>
                                            <td> <b>{"Fabric"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td>{"Cotton"}</td>
                                            </tr> */}
                                        </tbody>
                                        </table>
                                    </div>
                                    <hr/>
                                    {/* <Row>
                                        <Col md="6">
                                        <h6 className="product-title">{"share it"}</h6>
                                        </Col>
                                        <Col md="6">
                                        <div className="product-icon">
                                            <ul className="product-social">
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                                            </ul>
                                            <form className="d-inline-block f-right"></form>
                                        </div>
                                        </Col>
                                    </Row>
                                    <hr/> */}
                                    {/* <Row>
                                        <Col md="6">
                                        <h6 className="product-title">{"Rate Now"}</h6>
                                        </Col>
                                        <Col md="6">
                                        <div className="d-flex">
                                                <Ratings
                                                rating={rating}
                                                widgetRatedColors="blue"
                                                changeRating={changeRating}
                                                >
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                            </Ratings>
                                            <span>{ProductReview}</span>
                                        </div>
                                        </Col>
                                    </Row>
                                    <hr/> */}
                                    <div className="m-t-15">
                                      
                                        <Button  color="secondary" className="m-r-10" onClick={() => RemoveProduct(singleItem?.product_id)}>
                                            <i className="fa fa-trash mr-1"></i>{"Supprimer"}
                                        </Button>
                                        {/* <Button color="secondary" onClick={() => addWishList(singleItem)}>
                                            <i className="fa fa-heart mr-1"></i>{"Add To WishList"}
                                        </Button> */}
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col xl="3 xl-cs-35">
                                <Card>
                                    <CardBody>
                                    <div className="filter-block">
                                        <h4>{"Brand"}</h4>
                                        <ul>
                                        <li>{"Clothing"}</li>
                                        <li>{"Bags"}</li>
                                        <li>{"Footwear"}</li>
                                        <li>{"Watches"}</li>
                                        <li>{"ACCESSORIES"}</li>
                                        </ul>
                                    </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                    <div className="collection-filter-block">
                                        <ul>
                                        <li>
                                            <div className="media"><Truck/>
                                            <div className="media-body">
                                                <h5>{"Free Shipping"}</h5>
                                                <p>{"Free Shipping World Wide"}</p>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media"><Clock/>
                                            <div className="media-body">
                                                <h5>{"24 X 7 Service"}</h5>
                                                <p>{"Online Service For New Customer"}</p>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media"><Gift/>
                                            <div className="media-body">
                                                <h5>{"Festival Offer"}</h5>
                                                <p>{"New Online Special Festival"}</p>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media"><CreditCard/>
                                            <div className="media-body">
                                                <h5>{"Online Payment"}</h5>
                                                <p>{"Contrary To Popular Belief."}</p>
                                            </div>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    </Card>
                    {/* <Tablet /> */}
                    </Col>
                   </Row>
                </Container>
            </Fragment>
    );
}
export default ProductDetailProvider
