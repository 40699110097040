import {call,put,takeLatest} from "redux-saga/effects";
import {fetchNoteApi, fetchTodoApi} from '../../api'
import { WATCH_NOTE_LIST,GET_NOTE_LIST } from "../../redux/actionTypes";

function* fetchNoteAsyn() {
    const data = yield call(fetchNoteApi);
    yield put({type : GET_NOTE_LIST, list: data});
}

export function* watchNoteList() {
    yield takeLatest(WATCH_NOTE_LIST, fetchNoteAsyn)
}