import {GET_EVENTS_LIST,
        ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        ADD_EVENT_CATEGORY,
        UPDATE_STATE_EVENTS,
        ADD_CATEGORY,
        GET_CATEGORY,
        ADD_SOUS_CATEGORY,
        REMOVE_SOUS_CATEGORY,
        UPADTE_CATEGORY,
        REMOVE_CATEGORY,
        GET_EVENT_CATEGORY,
        REMOVE_EVENT_CATEGORY,
        UPDATE_EVENT_CATEGORY,

  } from '../actionTypes'


const initial_state = {
    eventcategory : [],
    eventsmanagerdata:[],
    eventsfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_EVENT_CATEGORY:
            console.log(action.category)
            return {...state,eventcategory: action.category, loading: true,};
            
        case REMOVE_EVENT_CATEGORY:
                let event_my_event = state.eventcategory.filter(item=> action.payload.id !== item.event_category_id)
                return{...state,eventcategory: event_my_event}
                    
        case ADD_EVENT_CATEGORY:
            return {...state,eventcategory:[...state.eventcategory, { ...action.payload.eventcategory}]};
        
      
         
            case UPDATE_STATE_EVENTS:
                return {
                    ...state,events: state.events.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            eventState:action.payload.state
                        } : 
                        item
                    ) 
                };
                case UPDATE_EVENT_CATEGORY:
                    return {
                        ...state,eventcategory: state.eventcategory.map(item => item.event_category_id === action.payload.datacategorie.event_category_id ?
                            { ...item, 
                               
                                title:action.payload.datacategorie.title,
                                color:action.payload.datacategorie.color
                            } : 
                            item
                        ) 
                    };
       
       
        
       
                
        default: return { ...state };
    }
    
}