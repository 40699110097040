import {GET_USER_LIST,
        ADD_TO_MY_USER,
        ADD_NEW_USER,
        REMOVE_USER,
        REMOVE_FROM_MY_USER,
        UPDATE_USER,
        UPDATE_MY_USER,
        UPDATE_STATE_USER,
        UPDATE_USER_EMAIL,

  } from '../actionTypes'


const initial_state = {
    users : [],
    usersmanagerdata:[],
    usersfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_USER_LIST:
            return {...state,users: action.users, loading: true,};

        case ADD_TO_MY_USER:
            return {...state,myuserdata:[...state.myuserdata, { ...action.payload.myuserdata}]};
        
        case ADD_NEW_USER:
           let user=  state.users
           user.push(action.payload.userdata)
        return {...state,users:user};
        
        case UPDATE_USER:
            return {
                ...state,users: state.users.map(item => item.id === action.payload.id ?
                    { ...item, 
                        id: action.payload.id,
                        fillstar:action.payload.updatedata.fillstar,
                        image: action.payload.image_url,
                        title: action.payload.updatedata.title,
                        website_url: action.payload.updatedata.url,
                        desc: action.payload.updatedata.desc,
                        collection:action.payload.updatedata.collection
                    } : 
                    item
                ) 
            };
            case UPDATE_STATE_USER:
                return {
                    ...state,users: state.users.map(item => item.user_id === action.payload.id ?
                        { ...item, 
                           
                            user_state:action.payload.state
                        } : 
                        item
                    ) 
                };
                case UPDATE_USER_EMAIL:
                    return {
                        ...state,users: state.users.map(item => item.id === action.payload.id ?
                            { ...item, 
                               
                                emailList:action.payload.data
                            } : 
                            item
                        ) 
                    };
        case UPDATE_MY_USER:
                return {
                    ...state,users: state.users.map(item => item.id === action.payload.id ?
                        { ...item, 
                            id: action.payload.id,
                            ...action.payload.userdata
                            
                        } : 
                        item
                    ) 
                };

        case REMOVE_USER:
            let new_user = state.users.filter(item=> action.removeid !== item.id)
            return{...state,users: new_user}
        
        case REMOVE_FROM_MY_USER:
            let new_my_user = state.myuserdata.filter(item=> action.userid !== item.id)
            return{...state,myuserdata: new_my_user}
                
        default: return { ...state };
    }
    
}