import React, { Fragment, useEffect, useState ,useRef} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Dropzone from "react-dropzone-uploader";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  ProjectTitle,
  ClientName,
  ProjectRate,
  ProjectStatus,
  ProgressLevel,
  ProjectSize,
  Small,
  Medium,
  Big,
  StartingDate,
  EndingDate,
  EnterSomeDetails,
  UploadProjectFile,
  Add,
  Cancel,
  Done,
  Doing, Close, SaveChanges
} from "../../constant";
import {
  WATCH_FRANCHISE_LIST,
  WATCH_SUBFRANCHISE_LIST,
  WATCH_USER_LIST,
} from "../../redux/actionTypes";
import { firestorage } from "../../data/config";
import { toast } from "react-toastify";
import moment from "moment";
import { addNProject } from "../../redux/projectdev/action";
import { Typeahead } from "react-bootstrap-typeahead";


const AddDevproject = (props) => {
  const {idUser} = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const ref = useRef(null);
  const [emaildestination, setemaildestination] = useState([]);
  const [franchise, setFranchise] = useState("");
  const [random, setrandom] = useState(0)
  const [myfilee, setMyFile] = useState([])
  const [marque, setMarque] = useState("");
  const franchises = useSelector((content) => content.Franchiseapp.franchises);
  const subfranchises = useSelector(
    (content) => content.SubFranchiseapp.subfranchises
  );
  console.log(franchises);
  useEffect(() => {
    dispatch({ type: WATCH_FRANCHISE_LIST });
  }, [dispatch]);

  const handleStartDate = (date) => {
    setstartDate(date);
  };

  const handleEndDate = (date) => {
    setendDate(date);
  };

  const getUploadParams = ({ meta, file }) => {

       
    
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/projects/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/projects`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;

      
        myfils.push({
      url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          console.log(myfils)
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
if (status === "removed"){
let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
setMyFile(myfileeCopy)
}
  }

  const users = useSelector(content => content.Userapp.users);
  useEffect(() => {
  
    dispatch({type: WATCH_USER_LIST})
  },[])
      const [selectedUser, setSelectedUser] = useState({})
      const [scrollingmodal, setscrollingModal] = useState(false);
      const Scrollmodaltoggle = () => setscrollingModal(!scrollingmodal);

  const AddProject = (data) => {

    if (data !== "" && marque.length > 0 && franchise.length > 0) {
      if(  emaildestination.length > 0){


        let resemail = emaildestination.map(res =>  users.filter(res2 => res2.role === 'developer').find(franch => franch.lastName +" "+franch.firstName ==  res)?.id || 0)
  
     
data.status = "En Cours"
data.developerListe = resemail
data.comment = ""
data.progress = 0
data.marqueName = franchises.find(res => res.id === marque).FranchiseName
data.FranchiseName = subfranchises.find(res => res.id === franchise).FranchiseName
data.marqueImage = franchises.find(res => res.id === marque).franchiseImage
let franchiseById = subfranchises.find(res => res.id === franchise)
data.FranchiseAdresse = franchiseById.subfranchiseAdresse + " " + franchiseById.ville + " " + franchiseById.pays
data.numberTacheAfaire = 0
data.numberTacheEnCours = 0
data.numberTacheTermine = 0
data.marqueId = marque
data.franchiseId = franchise
data.createdAt = moment(new Date()).format('yyyy-MM-DD HH:mm')
data.files = myfilee    
data.startDate = startDate
data.endDate = endDate
data.managerprojetName = ""
data.managerprojetId = idUser
props.history.push(`${process.env.PUBLIC_URL}/app/users/developer-projet/${idUser}`);
      dispatch(addNProject(data));
      }
    } else {
      errors.showMessages();
    }
  };
  const selectFranchise = (id) => {
    dispatch({ type: WATCH_SUBFRANCHISE_LIST });
  };
  const productColumns = [
    {
        name:"Image",
  
        cell: row => {
          return (
            <img src={row.userImage} style={{ width: 50, height: 50 }} alt="" />
          )
        },
        sortable: true,
        center:true,
      },
      {
        name:"Nom responsable",
        selector: 'firstName',
        sortable: true,
        center:true,
      },
      {
        name:"Prenom responsable",
        selector: 'lastName',
        sortable: true,
        center:true,
      },
      {
        name:"Action",
        
        cell : row => {return(
  
   <Input style={{color : 'red'}} className='primary'   onClick={() => setSelectedUser(row)} type="checkbox" checked={row.id === selectedUser.id}  />

)},

        sortable: true,
        center:true,
      },
];
const conditionalRowStyles = [
  {
    when: row => row.id === selectedUser.id,
    style: {
      backgroundColor: 'white',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }
];
  return (
    <Fragment>
      <Breadcrumb parent="Projets" title="Créer Projet" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(AddProject)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{ProjectTitle}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="Titre projet *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Titre est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{"Choisir Marque"}</Label>
                        <select
                          onChange={(e) => {
                            let id = e.target.value;
                            localStorage.setItem("@intranet_franchiseid", id);

setMarque(id)
                            selectFranchise(id);
                          }}
                          className="form-control digits"
                        >
                          {franchises.map((franchise, index) => (
                            <option value={franchise.id}>
                              {franchise.FranchiseName}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <Label>{"Choisir Enseigne"}</Label>
                      <select onChange={(e) =>{
                        setFranchise(e.target.value)
                      }} className="form-control digits">
                         <option>
                            Choisir Enseigne
                         </option>
                        {subfranchises.map((subfranchise, i) => (
                          <option value={subfranchise.id}>
                            {subfranchise.FranchiseName}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{StartingDate}</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          selected={startDate}
                          onChange={handleStartDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{EndingDate}</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          selected={endDate}
                          endDate={endDate}
                          onChange={handleEndDate}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                                    <Label className="col-form-Label pt-0">{"Choisir développeur"}</Label>

                                    <Typeahead
                                    
                                    ref={ref}
                                                    id="custom-typeahead"
                                                    allowNew
                                                    multiple
onch
                                                    onInputChange={(t,e) => {
                                                  
                                                      if (t.includes(",")){
                                                
                                                       setemaildestination([...emaildestination,{customOption: true, label: t.replace(',',""), id: "new-id-"+(emaildestination.length+1)}])
                                                       const instance = ref.current
    instance.clear()
    instance.focus()
                                                  
                                                    }}
                                                    }
                                                    
                                                    onChange={(e) => setemaildestination(e)}
                                                    selected={emaildestination}
                                                    newSelectionPrefix="Ajouter développeur: "
                                                    options={users.filter(res => res.role === 'developer').map(res => res.lastName +" "+res.firstName)}
                                                    placeholder="choisir développeur..."
                                                />
                                    {/* <Input value={destinataire} onChange={(e)=> setDestinataire(e.target.value)} className="form-control" type="email"/> */}
                                  </FormGroup>
                                  </Col>
                      {/* <Col >
                          <FormGroup>
                            <Label>{"Responsable projet"}</Label>
                            <button color="success" onClick={Scrollmodaltoggle}  name="status"  className="form-control digits" >
                      Responsable projet
                            </button>
       <Modal isOpen={scrollingmodal} toggle={Scrollmodaltoggle}>
                      <ModalHeader toggle={Scrollmodaltoggle}>
                        {"Choisir responsable projet"}
                      </ModalHeader>
                      <ModalBody>
                      <div className="table-responsive product-table">
                                <DataTable
                             conditionalRowStyles={conditionalRowStyles}
                                onRowClicked={(e) => {
setSelectedUser(e)
//Scrollmodaltoggle()
                                } }
                                    noHeader
                                    columns={productColumns}
                                    data={users.filter(res => res.role === 'developer')}
                                />
                            </div>
                                              
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={Scrollmodaltoggle}>{Close}</Button>
                        <Button color="primary"   onClick={Scrollmodaltoggle}>{SaveChanges}</Button>
                      </ModalFooter>
                </Modal>
                {selectedUser.lastName !== undefined ? <Label>Responsable choisis : {selectedUser.lastName + " " +selectedUser.firstName}</Label> : ""}
                          </FormGroup>
                        </Col> */}
                      </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{EnterSomeDetails}</Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          name="description"
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Détails est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                          to={`${process.env.PUBLIC_URL}/app/project/project-list`}
                        >
                          <Button className="mr-3" color="secondary">{Cancel}</Button>
                        </Link>
                        <Button color="primary" >
                          {Add}
                        </Button>
                       
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default withRouter(AddDevproject);
