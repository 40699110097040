import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import Dropzone from "react-dropzone-uploader";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { addNewProject } from "../../../redux/project-app/action";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  ProjectTitle,
  ClientName,
  ProjectRate,
  ProjectStatus,
  ProgressLevel,
  ProjectSize,
  Small,
  Medium,
  Big,
  StartingDate,
  EndingDate,
  EnterSomeDetails,
  UploadProjectFile,
  Add,
  Cancel,
  Done,
  Doing, Close, SaveChanges, Save
} from "../../../constant";
import {
  WATCH_FRANCHISE_LIST,
  WATCH_SUBFRANCHISE_LIST,
  WATCH_USER_LIST,
} from "../../../redux/actionTypes";
import { firestorage } from "../../../data/config";
import { toast } from "react-toastify";
import moment from "moment";
import { updateProject } from "../../../redux/project/action";

const UpdateprojectModal = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [marque, setMarque] = useState("");
  const [franchise, setFranchise] = useState("");
  const [random, setrandom] = useState(0)
  const [myfilee, setMyFile] = useState([])
  const franchises = useSelector((content) => content.Franchiseapp.franchises);
  const subfranchises = useSelector(
    (content) => content.SubFranchiseapp.subfranchises
  );
  console.log(franchises);
  useEffect(() => {
    dispatch({ type: WATCH_FRANCHISE_LIST });
  }, [dispatch]);

  const handleStartDate = (date) => {
    setstartDate(date);
  };

  const handleEndDate = (date) => {
    setendDate(date);
  };

  const getUploadParams = ({ meta, file }) => {

       
    
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/projects/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/projects`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;

      
        myfils.push({
      url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          console.log(myfils)
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
if (status === "removed"){
let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
setMyFile(myfileeCopy)
}
  }

  const users = useSelector(content => content.Userapp.users);
  useEffect(() => {
    localStorage.setItem("@intranet_franchiseid", props.selectedProjet.marqueId);

    dispatch({ type: WATCH_SUBFRANCHISE_LIST });
    dispatch({type: WATCH_USER_LIST})
  },[])
      const [selectedUser, setSelectedUser] = useState({})
      const [scrollingmodal, setscrollingModal] = useState(false);
      const Scrollmodaltoggle = () => setscrollingModal(!scrollingmodal);

  const AddProject = (data) => {

    if (data !== "" ) {

data.marqueName = marque.length > 0 ?  franchises.find(res => res.id === marque).FranchiseName : props.selectedProjet.marqueName
data.FranchiseName = franchise.length > 0 ? subfranchises.find(res => res.id === franchise).FranchiseName : props.selectedProjet.FranchiseName
data.marqueImage =  marque.length > 0 ? franchises.find(res => res.id === marque).franchiseImage : props.selectedProjet.marqueImage
if (franchise.length > 0 ) {
let franchiseById =  subfranchises.find(res => res.id === franchise)
data.FranchiseAdresse = franchiseById.subfranchiseAdresse + " " + franchiseById.ville + " " + franchiseById.pays
}
else {
  data.FranchiseAdresse = props.selectedProjet.FranchiseAdresse
}

data.marqueId = marque.length > 0 ? marque : props.selectedProjet.marqueId
data.franchiseId = franchise.length > 0 ? franchise : props.selectedProjet.franchiseId
data.updatedAt = moment(new Date()).format('yyyy-MM-DD HH:mm')
data.files = myfilee.concat(props.selectedProjet.files)    
if (startDate !== null)
data.startDate =startDate
if (endDate !== null)
data.endDate =   endDate
data.managerprojetName = selectedUser === {} ? selectedUser.lastName + " " +selectedUser.firstName : props.selectedProjet.managerprojetName
data.managerprojetId =selectedUser === {} ? selectedUser.id : props.selectedProjet.managerprojetId
props.UpdateToogle()
      dispatch(updateProject(data, props.selectedProjet.id));
    
    } else {
      errors.showMessages();
    }
  };
  const selectFranchise = (id) => {
    dispatch({ type: WATCH_SUBFRANCHISE_LIST });
  };
  const productColumns = [
    {
        name:"Image",
  
        cell: row => {
          return (
            <img src={row.userImage} style={{ width: 50, height: 50 }} alt="" />
          )
        },
        sortable: true,
        center:true,
      },
      {
        name:"Nom responsable",
        selector: 'firstName',
        sortable: true,
        center:true,
      },
      {
        name:"Prenom responsable",
        selector: 'lastName',
        sortable: true,
        center:true,
      },
      {
        name:" Numero télephone",
        selector: 'phoneNumber',
        sortable: true,
        center:true,
      },
      {
        name:"region",
        selector: 'region',
        sortable: true,
        center:true,
      },
      {
        name:"Action",
        selector: 'action',
        sortable: true,
        center:true,
      },
];

const conditionalRowStyles = [
  {
    when: row => row.id === selectedUser.id,
    style: {
      backgroundColor: 'red',
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }
];
  return (
    <Modal isOpen={props.updateModal} toggle={props.UpdateToogle} size="lg">
    <ModalHeader toggle={props.UpdateToogle}>{"Modifier Projet"}</ModalHeader>
    <ModalBody>
    <Fragment>
     
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(AddProject)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{ProjectTitle}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="title"
                          defaultValue={props.selectedProjet.title}
                          placeholder="Titre projet *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Titre est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{"Choisir Marque"}</Label>
                        <select
                       
                          onChange={(e) => {
                            let id = e.target.value;
                            localStorage.setItem("@intranet_franchiseid", id);

setMarque(id)
                            selectFranchise(id);
                          }}
                          className="form-control digits"
                        >
                          {franchises.map((franchise, index) => (
                            <option selected={franchise.id === props.selectedProjet.marqueId} value={franchise.id}>
                              {franchise.FranchiseName}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <Label>{"Choisir Franchise"}</Label>
                      <select 
                      
                      
                      onChange={(e) =>{
                        setFranchise(e.target.value)
                      }} className="form-control digits">
                         <option>
                            Choisir Franchise
                         </option>
                        {subfranchises.map((subfranchise, i) => (
                          <option selected={subfranchise.id === props.selectedProjet.franchiseId} value={subfranchise.id}>
                            {subfranchise.FranchiseName}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{StartingDate}</Label>
                        <DatePicker
                     
                          className="datepicker-here form-control"
                          selected={startDate === null ?  props.selectedProjet?.startDate?.toDate() : startDate}
                          onChange={handleStartDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{EndingDate}</Label>
                        <DatePicker

                      
className="datepicker-here form-control"
selected={endDate === null ?  props.selectedProjet?.endDate?.toDate() : endDate}
endDate={endDate}
onChange={handleEndDate}
/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col >
                          <FormGroup>
                            <Label>{"Responsable projet"}</Label>
                            <button color="success" onClick={Scrollmodaltoggle}  name="status"  className="form-control digits" >
                      Responsable projet
                            </button>
       <Modal isOpen={scrollingmodal} toggle={Scrollmodaltoggle}>
                      <ModalHeader toggle={Scrollmodaltoggle}>
                        {"Choisir responsable projet"}
                      </ModalHeader>
                      <ModalBody>
                      <div className="table-responsive product-table">
                                <DataTable
                             conditionalRowStyles={conditionalRowStyles}
                                onRowClicked={(e) => {
setSelectedUser(e)
//Scrollmodaltoggle()
                                } }
                                    noHeader
                                    columns={productColumns}
                                    data={users}
                                />
                            </div>
                                              
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={Scrollmodaltoggle}>{Close}</Button>
                        <Button color="primary"   onClick={Scrollmodaltoggle}>{SaveChanges}</Button>
                      </ModalFooter>
                </Modal>
                {selectedUser.lastName !== undefined ? <Label>Responsable choisis : {selectedUser.lastName + " " +selectedUser.firstName}</Label> : <Label>Responsable choisis : {props.selectedProjet.managerprojetName}</Label>}
                          </FormGroup>
                        </Col>
                      </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{EnterSomeDetails}</Label>
                        <Input
                        defaultValue={props.selectedProjet.description}
                          type="textarea"
                          className="form-control"
                          name="description"
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Details est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col  className="text-right">
                      <FormGroup className="mb-0">
                      
                        <Link className="mr-3"
                         onClick={props.UpdateToogle}
                        >
                          <Button color="secondary" onClick={props.UpdateToogle}>{Cancel}</Button>
                        </Link>
                        <Button color="primary" >
                          {"Modifier Projet"}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
   
                    
                        </ModalBody>
                      </Modal>
  );
};

export default UpdateprojectModal;
