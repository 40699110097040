import React, { Fragment,useEffect,useRef,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import Dropzone from 'react-dropzone-uploader'
import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button, InputGroupAddon, InputGroupText, InputGroup} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import {addNewProject} from '../../../redux/project-app/action'
import { useDispatch, useSelector } from 'react-redux';
import {withRouter,Link, useParams,useLocation, useHistory} from 'react-router-dom'
import SunEditor from 'suneditor-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {ActualiteTitle,ClientName,ProjectRate,ProjectStatus,ProgressLevel,ProjectSize,Small,Medium,Big,StartingDate,EndingDate,EnterSomeDetails,UploadProjectFile,Add,Cancel,Done,Doing, ActualiteCategorie, ActualiteStatepublication, Option, Checked, Disabled, ActualiteDatepublication, ActualiteStateNotifierParEmail, Actualitecontenu, ActualiteAjouterSondage, ActualitéstateSondage, ActualiteChoixSondage, Files} from '../../../constant'
import { firestorage, urlbackend } from '../../../data/config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { deletemedianews, NewNews, removesondage, updateMynew, updatesondage } from '../../../redux/news/action';
import { fetchNewsByIdApi } from '../../../api';
import parse from 'html-react-parser';
import { UPDATE_MY_NEWS, WATCH_FRANCHISE_LIST } from '../../../redux/actionTypes';
import SweetAlert from 'sweetalert2'
const EditActualiteAdmin = (props) => {
const {newsId} = useParams()
const location = useLocation()
const history = useHistory()
const ref = useRef(null);
    const dispatch = useDispatch()
    const {register, handleSubmit, errors } = useForm();
    const [startDate, setstartDate] = useState(new Date())
    const [endDate, setendDate] = useState(new Date())
    const [publiucationState, setPublicationState] = useState(false)
    const [allMarque, setAllMarque] = useState(false)
    const [NotificationState, setNotificationState] = useState(false)
    const [stateSondage, setStateSondage] = useState(false)
    const [actualiteContenu, setActualiteContenu] = useState("")
    const [sondageListe, setSondageListe] = useState([])
    const [NewsondageListe, setNewSondageListe] = useState([])
    const [random, setrandom] = useState(0)
    const [myfilee, setMyFile] = useState([])
    const [myfileenew, setMyFileNew] = useState([])
    const [News, setNews] = useState({})
    const [marqueSelection, setmarqueSelection] = useState([]);
const [title, settitle] = useState("")
    const handleStartDate = date => {
      setstartDate(date);
    };
    const [marque, setMarque] = useState("");
    const franchises = useSelector((content) => content.Franchiseapp.franchises);
  
    

useEffect(() => {
  dispatch({ type: WATCH_FRANCHISE_LIST });
 // setActualiteContenu(location.state?.news?.actualitecontenu)
  fetchNewsByIdApi(newsId).then(res => {
  if(res.news_contenu === undefined)
  history.push(`${process.env.PUBLIC_URL}/app/actualites/actualitesliste`)

    setNews(res)
    settitle(res.title)
   
    setActualiteContenu(res.news_contenu)
    setPublicationState(res.publication_state === "1" ? true : false)
//setMarque(res.marqueId)
  setmarqueSelection(res.marqueListe)
   setAllMarque(res.allmarque === "1" ? true : false)
    //setstartDate(res.news_publication_date !== undefined ? res.news_publication_date.toDate() : new Date())


    setNotificationState(res.notifier_mail === "1" ? true : false)
    setMyFile(res.piecesJoint)
    setStateSondage(res.news_sondage_state === "1" ? true : false)
    setSondageListe(res.sondageListe)

  }).catch(e => console.log(e))
}, [])
    
const AddSondage = () => {

  let listesondafecopy = NewsondageListe
  listesondafecopy.push({ contenu_sondage : "" })

setNewSondageListe(listesondafecopy)
setrandom(Math.random())
}
const Addtext = (i, text, id) => {

 let sondageListCopy = sondageListe
 

 setSondageListe(sondageListCopy.map(res => res.id ===id ? {
  ...res , 
  contenu_sondage :text
} : res ))
 setrandom(Math.random())

}
const AddtextNew = (i, text, id) => {

  let sondageListCopy = NewsondageListe
  
  sondageListCopy[i].contenu_sondage = text
  setNewSondageListe(sondageListCopy)
  setrandom(Math.random())
 
 }
const DeleteSondage = (value, sondage_id) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
   
  if(value === ""){
    let listesondafecopy = sondageListe.filter((res, i) => i !== sondageListe.length - 1)
    console.log(listesondafecopy)
    setSondageListe(listesondafecopy)
    setrandom(Math.random())
    dispatch(removesondage(sondage_id));
  }
  else {
  let listesondafecopy = sondageListe.filter(res => res.id !== sondage_id)
  setSondageListe(listesondafecopy)

  setrandom(Math.random())
  dispatch(removesondage(sondage_id));
  }
    }
    else {
      SweetAlert.fire(
        'Votre sondage est sauvgardée'
      )
    }
  })

  
}
const updateSondage = (value, sondage_id) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Modifer le contenu de sondage",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
   
 
  let listesondafecopy = sondageListe.find(res => res.id == sondage_id).contenu_sondage


  setrandom(Math.random())
  dispatch(updatesondage(listesondafecopy,sondage_id));
  
    }
    else {
      SweetAlert.fire(
        'Votre sondage est sauvgardée'
      )
    }
  })

  
}
const DeleteFromNewSondage = (value) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
   
  if(value === ""){
    let listesondafecopy = NewsondageListe.filter((res, i) => i !== NewsondageListe.length - 1)
    console.log(listesondafecopy)
    setNewSondageListe(listesondafecopy)
   
  }
  else {
  let listesondafecopy = NewsondageListe.filter(res => res.contenu_sondage !== value)
  setNewSondageListe(listesondafecopy)
  }
    }
    else {
      SweetAlert.fire(
        'Votre sondage est sauvgardée'
      )
    }
  })

  
}
    const handleEndDate = date => {
      setendDate(date);
    };
    
    const getUploadParams = ({ meta, file }) => {

       
      const formData =  new FormData();
    formData.append('image', file);
    let url = "https://gocloud.groupe-fbh.com/intranet/api/medianews/uploadmedia.php";
  
        return { url: 'https://gocloud.groupe-fbh.com/intranet/api/medianews/uploadmedia.php', body : formData  } }
      const handleChangeStatus = ({xhr, meta, file }, status) => {
  
        if(status === "done" ){
            if (xhr.readyState == 4) {
              let myfils = myfileenew;
              const result = JSON.parse(xhr.response);
             myfils.push({
             url : result.url,
             name: meta.name,
             size: meta.size,
             type: meta.type,
             creation_date : moment(new Date()).format('yyyy-MM-DD HH:mm'),
             updated_date : moment(new Date()).format('yyyy-MM-DD HH:mm'),
             icon:"fa fa-file-text-o txt-info"
              })
              setMyFileNew(myfils)
              toast.success("Fichier Téléchargé avec succés")
            }
          }
 else if (status === "removed"){
    let myfileeCopy = myfileenew.filter(res => res.name !== meta.name)
    setMyFileNew(myfileeCopy)
  }
      }
    const AddProject = data => {
      data.preventDefault()
  
      if (data !== '') {
        News.title = title
        News.news_contenu = actualiteContenu
        News.publication_state = publiucationState === true ? "1" : 0
        News.news_publication_date = startDate
        News.news_sondage_state = stateSondage  === true ? "1" : 0
        News.sondageListe = NewsondageListe
        News.prop_id = localStorage.getItem("@intranetidUser")
        News.prop_type= "Admin"
        News.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
        News.allmarque = allMarque  === true ? "1" : 0
        News.news_id = newsId
        News.marqueSelection = marqueSelection
  
        News.notifier_mail = NotificationState  === true ? "1" : 0
        News.piecesJoint = myfileenew
       console.log(sondageListe)
    dispatch(updateMynew(News,newsId))
    props.history.push(`${process.env.PUBLIC_URL}/app/actualites/actualitesliste/`)
      } else {
        errors.showMessages();
      }
    };
    const stringToHTML = (str) => {
      var dom = document.createElement('p');
      dom.innerHTML = str;
      return dom;
    };
    const removefile = (name,id) => {
      SweetAlert.fire({
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
         let rescop = myfilee
         rescop = rescop.filter(res => res.media_news_id !== id)
        
         setMyFile(rescop)
         setrandom(Math.random())
         dispatch(deletemedianews(id))
      
          SweetAlert.fire(
            'Supprimé!',
            'Votre fichier a été supprimé',
            'success'
          )
         
       
         
        }
        else {
        
        }
      })
    }
    const filelist2 = myfilee?.map((data,i)=>{
      return(
        <li    onDoubleClick={() => {
           
       
           
            }} className="file-box" key={i}>
    
    
          <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={`${urlbackend}${data.url}`} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
          <div className="file-bottom">
            <h6>{data.name.slice(0,20)}...<i className="fa fa-trash" onClick={() => removefile(data.name,data.media_news_id)}></i></h6>
    
          </div>
       
               
        </li>
      )
    })
    return (
        <Fragment>
        <Breadcrumb parent="Actualité" title="Modifier actualité" /> 
        <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={AddProject}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{ActualiteTitle}</Label>
                            <Input className="form-control" type="text"  value={title} onChange={(e) => settitle(e.target.value)} name="title" placeholder="Titre Actualité *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{Actualitecontenu}</Label>
                            <SunEditor setContents={actualiteContenu}   onChange={(e) => setActualiteContenu(e)}/>
                            <span style={{ color: "red" }}>{errors.client_name && 'Contenu is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col>
                          <FormGroup>
                            <Label>{ActualiteCategorie}</Label>
                            <select className="form-control digits">
                              <option>{Small}</option>
                              <option>{Medium}</option>
                              <option>{Big}</option>
                            </select>
                          </FormGroup>
                        </Col>
                        </Row> */}
                        <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" checked={publiucationState} value={publiucationState} onChange={(e) => setPublicationState(e.target.checked)} />
                        <Label for="radio1">{ActualiteStatepublication}<span className="digits"> </span></Label>
                      </div>
                      {publiucationState === true ?
                      <Row>
                        <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{ActualiteDatepublication}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => setstartDate(e)} showPopperArrow={false} selected={startDate === null ?  startDate : startDate}   />
                          </div>
                        </FormGroup>
                        </Col>
                        </Row> : null
}
                      
                    </FormGroup>
                        </Col>
                        </Row>
                    
                      <Row>
                        <Col>
                        <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" checked={allMarque} value={allMarque} onChange={(e) => setAllMarque(e.target.checked)} />
                        <Label for="radio1">{"Tous les marques"}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row> 
{ allMarque === false ?
<Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Choisir Marque"}</Label>
                        <Typeahead
                                    
                                    ref={ref}
                                                            id="custom-typeahead"
                                                            allowNew={false}
                                                            multiple
        
            //                                                 onInputChange={(t,e) => {
            //                                                   if (t.includes(",")){
                                                        
            //                                                     setmarqueSelection([...marqueSelection,{customOption: true, label: t.replace(',',""), id: "new-id-"+(marqueSelection.length+1)}])
            //                                                    const instance = ref.current
            // instance.clear()
            // instance.focus()
                                                          
            //                                                 }}
            //                                                 }
                                                            
                                                            onChange={(e) => {
                                                              setmarqueSelection(e)}}
                                                            selected={marqueSelection}
                                                            newSelectionPrefix="Ajouter marque: "
                                                            options={franchises.map(res => {return   {id: res.franchise_id, label: res.franchise_name}} )}
                                                            placeholder="Destinataire(s)"
                                                        />
                        {/* <select 
                          onChange={(e) => {
                            let id = e.target.value;
                            localStorage.setItem("@intranet_franchiseid", id);

setMarque(id)
                           
                          }}
                          className="form-control digits"
                        >
                           <option >
                             Choisir marque
                            </option>
                          {franchises.map((franchise, index) => (
                            <option selected={franchise.id === marque} value={franchise.id}>
                              {franchise.FranchiseName}
                            </option>
                          ))}
                        </select> */}
                      </FormGroup>
                    </Col>
                  
                  </Row>: null
} 
<Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" checked={NotificationState} value={NotificationState} onChange={(e) => setNotificationState(e.target.checked)} />
                        <Label for="radio1">{ActualiteStateNotifierParEmail}<span className="digits"> </span></Label>
                      </div>
                     
                    </FormGroup>
                        </Col>
                        </Row>
                     
                    
                      <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" checked={stateSondage} value={stateSondage} onChange={(e) => setStateSondage(e.target.checked)} />
                        <Label for="radio1">{ActualitéstateSondage}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
              {stateSondage === true ? <> <Row>
                      <Col>
                      <FormGroup >
                      <div>
                      <Button color="primary" onClick={() => AddSondage()}  >{ActualiteAjouterSondage}</Button>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        { 
                        sondageListe.map((sondage,index) => 
                          <Row key={index}>
                      <Col>
                        <FormGroup>
                        
                            <InputGroup>
                              <Input onChange={(e) => Addtext(index, e.target.value, sondage.id)} className="form-control" type="text" value={sondage.contenu_sondage} placeholder={ActualiteChoixSondage} aria-label="Recipient's username"/>
                              <InputGroupAddon addonType="append"><InputGroupText onClick={() => DeleteSondage(sondage.contenu_sondage,sondage.id)}> {"-"}</InputGroupText></InputGroupAddon>
                              <InputGroupAddon addonType="append"><InputGroupText onClick={() => updateSondage(sondage.contenu_sondage,sondage.id)}> {"Modifier"}</InputGroupText></InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                          </Col>
                        </Row>
                        )
                        }{
                         NewsondageListe.map((sondage,index) => 
                          <Row key={index}>
                      <Col>
                        <FormGroup>
                        
                            <InputGroup>
                              <Input onChange={(e) => AddtextNew(index, e.target.value)} className="form-control" type="text" value={sondage.contenu_sondage} placeholder={ActualiteChoixSondage} aria-label="Recipient's username"/>
                              <InputGroupAddon addonType="append"><InputGroupText onClick={() => DeleteFromNewSondage(sondage.contenu_sondage)}> {"-"}</InputGroupText></InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                          </Col>
                        </Row>
                        )
                        }</>
                       : null }
                     <Row>
                    <Col>
                    <h6 className="mt-4">{Files}</h6>
                      {filelist2?.length > 0 ?
              <ul className="files">
                  { filelist2 }
       
              </ul> : ""}
                    </Col>
                  </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UploadProjectFile}</Label>
                                <Dropzone
                             
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    inputWithFilesContent={"Ajouter Fichier"}
                                    maxFiles={3}
                                    submitButtonDisabled={true}
                                    multiple={true}
                                    canCancel={true}
                                    inputContent="Uploader un fichier"
                                    styles={{
                                        dropzone: { width: '100%', height: 50 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{alignContent: 'flex-end'}} sm="12">
                          <FormGroup className="mb-0 text-right">
                             
                              <Link className="mr-3" to={`${process.env.PUBLIC_URL}/app/actualites/actualitesliste`}>
                              <Button color="secondary">{Cancel}</Button>
                              </Link>
                              <Button color="primary" >{"Modifier"}</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}

export default withRouter(EditActualiteAdmin);