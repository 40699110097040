import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, FormGroup, Progress, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle, Edit2, Play, Trash2, Tag, Share2 } from 'react-feather';
import {Link, useHistory, useParams} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {Issues,Resolved,Comment,Done,All,Doing,CreateNewProject, Save, Cancel} from '../../constant'
import { WATCH_DEV_PROCESS_LIST, WATCH_DEV_PROJET_LIST, WATCH_PROJET_LIST, WATCH_TACHE_LIST } from '../../redux/actionTypes';
import SweetAlert from 'sweetalert2'
import { removeProjets } from '../../redux/project/action';
//import UpdateprojectModal from './update-project-modal';
const ProcessListe = (props) => {
  const {idFranchise} = useParams();
  const history = useHistory()
  const dispatch = useDispatch()
  const [updateModal,setupdateModal] = useState(false)
  const UpdateToogle = () => { setupdateModal(!updateModal) }
  const [activeTab,setActiveTab] = useState("1")
  const [selectedProjet,setselectedProjet] = useState({})
  const processliste = useSelector(content => content.ProcessApp.process);

  
useEffect(() => {

  dispatch({type : WATCH_DEV_PROCESS_LIST,idFranchise : idFranchise})

}, [])
const removeProjet = (idprojet) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
   
      dispatch(removeProjets(idprojet));
  
  
      SweetAlert.fire(
        'Supprimé!',
        'Votre process a été supprimée',
        'success'
      )
    }
    else {
    
    }
  })
}
  return (
    <Fragment>
      <Breadcrumb parent="Processus" title="Liste processus" />
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  {/* <Nav tabs className="border-tab">
                    <NavItem><NavLink className={activeTab === "1" ? "active" : ''} onClick={() => setActiveTab("1")}><Target />{All}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "2" ? "active" : ''} onClick={() => setActiveTab("2")}><Info />{Doing}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><CheckCircle />{Done}</NavLink></NavItem>
                  </Nav> */}
                </Col>
                <Col sm="6">
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/app/process/process-add/${idFranchise}`}> <PlusCircle />{"Créer nouveau Processus"}</Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      {processliste.map((item, i) =>
                        <Col onDoubleClick={() => {


                          history.push(`${process.env.PUBLIC_URL}/app/process/process-liste/${idFranchise}/${item.id}`)

                        }} sm="4" className="mt-4" key={i}>
                          <div className="project-box">
                           
                            
                            <h6>{item.title}</h6>
                            <div className="customers hover-block">
                           <a onClick={() => history.push(`${process.env.PUBLIC_URL}/app/process/process-liste/${idFranchise}/${item.id}`)}  ><Play /></a>
                                              <a onClick={() =>{
                                                setselectedProjet(item)
                                                UpdateToogle()
                                              }} ><Edit2 /></a>

                                                <a onClick={() => removeProjet(item.id)} ><Trash2 /></a>
                                            
                            </div>
                            <div className="media">
                            
                              <div className="media-body">
                                <p>{item.processType}</p>
                              </div>
                            </div>
                            <p>{item.description}</p>
                            <Row className="details">
                         
                              <Col xs="6"><span>{'Nombre tâche'} </span></Col>
                              <Col xs="6" className={ 'text-primary'}>{item.tachenumber}</Col>
                             
                            
                        
                            </Row>

                                                                 
                          </div>
                        </Col>
                      )}
                    </Row>
                  </TabPane>

                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <UpdateprojectModal selectedProjet={selectedProjet} updateModal={updateModal} UpdateToogle={UpdateToogle} /> */}
      </Container>
    </Fragment>
  );
}

export default ProcessListe;