import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import Dropzone from 'react-dropzone-uploader'
import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button, InputGroupAddon, InputGroupText, InputGroup} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import {addNewProject} from '../../../redux/project-app/action'
import { useDispatch, useSelector } from 'react-redux';
import {withRouter,Link} from 'react-router-dom'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {UploadProjectFile,Add,Cancel, EventStatevisibilite, EventsCommentBlock, EventsCommentRequired, EventTitle, EventsDescription, EventsDateDebut, EventsDateFin, EventsDateLimite, EventsLieu, EventsOrganisator, EventstimeDebut, EventstimeFin} from '../../../constant'
import { firestorage } from '../../../data/config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { NewNews } from '../../../redux/news/action';
import { NewEVENTS } from '../../../redux/events/action';
import { WATCH_EVENT_CATEGORY, WATCH_FRANCHISE_LIST } from '../../../redux/actionTypes';
const NewEvents = (props) => {

    const dispatch = useDispatch()
    const {register, handleSubmit, errors } = useForm();
    const [startDate, setstartDate] = useState(new Date())
    const [endDate, setendDate] = useState(new Date())
    const [visibleState, setVisibleState] = useState(false)
    const [commentBlock, setCommentBlock] = useState(false)
    const [commentrequired, setcommentrequired] = useState(false)
    const [dateLimiteinscrit, setDateLimiteinscrit] = useState(new Date())
    const [titre, setTitre] = useState("")
    const [description, setDescription] = useState("")
    const [lieu, setLieu] = useState("")
    const [organisateur, setOrganisateur] = useState("")
    const [contenu, setcontenu] = useState("")
    const [category_id, setcategory_id] = useState("")
    const [organisateur_id, setorganisateur_id] = useState("")
    const [horaire_ouverture, sethoraire_ouverture] = useState("")
    const [horaire_fermeture, sethoraire_fermeture] = useState("")
    const listEventCategory = useSelector(state => state.EventCategory.eventcategory)
    const [random, setrandom] = useState(0)
    const [myfilee, setMyFile] = useState([])
    const handleStartDate = date => {
      setstartDate(date);
    };


    const [marque, setMarque] = useState("");
    const franchises = useSelector((content) => content.Franchiseapp.franchises);
  
    
    useEffect(() => {
      dispatch({type : WATCH_EVENT_CATEGORY})
      dispatch({ type: WATCH_FRANCHISE_LIST });
    }, [dispatch]);
  
   
    
    const getUploadParams = ({ meta, file }) => {
      const formData =  new FormData();
      formData.append('image', file);
   
          
          return { url: 'https://gocloud.groupe-fbh.com/intranet/api/mediaevents/uploadmedia.php', body : formData  } }
        const handleChangeStatus = ({xhr, meta, file }, status) => {
    
          if(status === "done" ){
              if (xhr.readyState == 4) {
                let myfils = myfilee;
                const result = JSON.parse(xhr.response);
               myfils.push({
               url : result.url,
               name: meta.name,
               size: meta.size,
               type: meta.type,
               creation_date : moment(new Date()).format('yyyy-MM-DD HH:mm'),
               updated_date : moment(new Date()).format('yyyy-MM-DD HH:mm'),
               icon:"fa fa-file-text-o txt-info"
                })
                setMyFile(myfils)
                toast.success("Fichier Téléchargé avec succés")
              }
            }
   else if (status === "removed"){
      let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
      setMyFile(myfileeCopy)
    }
        }
  const AddProject = data => {
 
      if (data !== '') {
       data.visibility_state = visibleState ? 1 : 0
       data.comment_state = commentBlock  ? 1 : 0
       data.comment_required = commentrequired  ? 1 : 0
       data.start = startDate+""
       data.end = endDate+""
       data.limit_date_inscrit = dateLimiteinscrit
       data.description = contenu
       data.event_state = true

        data.prop_id = localStorage.getItem("@intranetidUser")
  
        data.piecesJoint = myfilee
        data.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
        data.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
        data.prop_type = localStorage.getItem("@intranetTypeUser")
        data.category_id = category_id
        data.organisateur_id = data.organisateur
        data.horaire_ouverture = moment(horaire_ouverture).format('HH:mm')
        data.horaire_fermeture =  moment(horaire_fermeture).format('HH:mm')
        console.log(myfilee)
        if(localStorage.getItem("@intranetTypeUser") === 'admin'){

     //   data.marqueName = franchises.find(res => res.id === marque).FranchiseName
       // data.marqueImage = franchises.find(res => res.id === marque).franchiseImage
        data.marqueId = marque
      } else if (localStorage.getItem("@intranetTypeUser") === 'Fournisseur'){
       // data.marqueName = ""
        //data.marqueImage = ""
        data.marqueId =  ""
      }else {
       // data.marqueName = franchises.find(res => res.id === localStorage.getItem('@intranetidMarqueUser')).FranchiseName
        //data.marqueImage = franchises.find(res => res.id === localStorage.getItem('@intranetidMarqueUser')).franchiseImage
        data.marqueId = localStorage.getItem('@intranetidMarqueUser') || ""
    
      }
      dispatch(NewEVENTS(data))
        props.history.push(`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate/`)
      } else {
        errors.showMessages();
      }
    };

    return (
        <Fragment>
        <Breadcrumb parent="Evénements" title="Créer événement" /> 
        <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(AddProject)}>
                    <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventTitle}</Label>
                            <Input className="form-control" type="text"  name="title" placeholder="Titre Evenement *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventsDescription}</Label>
                            <SunEditor onChange={(e) => setcontenu(e)} />
                            <span style={{ color: "red" }}>{errors.client_name && 'Description is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
             
                        <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventsLieu}</Label>
                            <Input className="form-control" type="text"  name="lieu" placeholder="Lieu Evenement *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Lieu is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Choisir categorie"}</Label>
                        <select
                          onChange={(e) => {
                            let id = e.target.value;
                           // localStorage.setItem("@intranet_franchiseid", id);

setcategory_id(id)
                           
                          }}
                          className="form-control digits"
                        >
                           <option >
                             Choisir categorie
                            </option>
                          {listEventCategory.map((eventCategory, index) => (
                            <option value={eventCategory.event_category_id}>
                              {eventCategory.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                      {localStorage.getItem("@intranetTypeUser") === 'admin' ?
                      <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Choisir Marque"}</Label>
                        <select
                          onChange={(e) => {
                            let id = e.target.value;
                            localStorage.setItem("@intranet_franchiseid", id);

setMarque(id)
                           
                          }}
                          className="form-control digits"
                        >
                           <option >
                             Choisir marque
                            </option>
                          {franchises.map((franchise, index) => (
                            <option value={franchise.franchise_id}>
                              {franchise.franchise_name}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  
                  </Row> : ""}
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventsOrganisator}</Label>
                            <Input className="form-control" type="text"  name="organisateur" placeholder="Nom Organisateur" innerRef={register({ required: false })} />
                            <span style={{ color: "red" }}>{errors.title && 'organisateur is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                       <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventstimeDebut}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => {
                           
                                sethoraire_ouverture(e.getTime())
                            }} showPopperArrow={true} showTimeSelectOnly showTimeSelect selected={horaire_ouverture}  dateFormat="p" />
                          </div>
                        </FormGroup>
                        </Col>
                        <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventstimeFin}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => {
                          
                                sethoraire_fermeture(e.getTime())
                            }} showPopperArrow={true} showTimeSelectOnly showTimeSelect selected={horaire_fermeture}  dateFormat="p" />
                          </div>
                        </FormGroup>
                        </Col>
                         </Row>
                       <Row>
                       <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventsDateDebut}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => {
                              if(moment(e).isBefore(moment(new Date())) )
                                alert("Date invalid")
                              
                              else
                                setstartDate(e)
                            }} showPopperArrow={true} showTimeSelect selected={startDate}  dateFormat="Pp" />
                          </div>
                        </FormGroup>
                        </Col>
                         </Row>
                         <Row>
                       <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventsDateFin}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => {
                              if(moment(e).isBefore(moment(startDate)) )
                                alert("Choisir date fin supérieur date début")
                              else
                                setendDate(e)
                            }} showPopperArrow={true} showTimeSelect selected={endDate}  dateFormat="Pp" />
                          </div>
                        </FormGroup>
                        </Col>
                         </Row>
                        
                         <Row>
                       <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventsDateLimite}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => setDateLimiteinscrit(e)} showPopperArrow={true} showTimeSelect selected={dateLimiteinscrit}  dateFormat="Pp" />
                          </div>
                        </FormGroup>
                        </Col>
                         </Row>
                         <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={commentrequired} onChange={(e) => setcommentrequired(e.target.checked)} />
                        <Label for="radio1">{EventsCommentRequired}<span className="digits"> </span></Label>
                      </div>

                    </FormGroup>
                        </Col>
                        </Row>
                         <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={visibleState} onChange={(e) => setVisibleState(e.target.checked)} />
                        <Label for="radio1">{EventStatevisibilite}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={commentBlock} onChange={(e) => setCommentBlock(e.target.checked)} />
                        <Label for="radio1">{EventsCommentBlock}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UploadProjectFile}</Label>
                                <Dropzone
                              
                              inputWithFilesContent={"Ajouter Fichier"}
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    maxFiles={3}
                                    submitButtonDisabled={true}
                                    multiple={true}
                                    canCancel={true}

                                    inputContent="Glisser Fichier(s)"
                                    styles={{
                                        dropzone: { width: '100%', height: 50 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right">
                      
                          <Link  to={`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate`}>
                              <Button className="mr-3" color="secondary">{Cancel}</Button>
                              </Link>
                              <Button  color="primary" >{Add}</Button>
                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}

export default withRouter(NewEvents);