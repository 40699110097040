import React from "react";
import {
    ADD_DEV_COMMENT,
  ADD_NEW_DEV_PROJECT,
  ADD_NEW_DEV_SOUS_TACHE,
  ADD_NEW_DEV_TACHE,
  ADD_DEV_SOUS_COMMENT,
  GET_LIST_DEV_PROJECT,
  GET_LIST_DEV_SOUS_TACHE,
  GET_LIST_DEV_TACHE,
  REMOVE_DEV_PROJET,
  REMOVE_DEV_SOUS_TACHE,
  REMOVE_DEV_TACHE,
  UPDATE_DEV_PROJET,
} from "../actionTypes";
const { All, Doing, Done } = require("../../data/project");

const initial_state = {
  all_DevProject: [],
  doing_DevProject: [],
  done_DevProject: [],
  tache_DevProject: { lanes: [] },
  sous_tache_DevProject: { lanes: [] },
  tache_DevProjects: [],
  sous_tache_DevProjects: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case REMOVE_DEV_SOUS_TACHE:
      return state;
    case ADD_NEW_DEV_PROJECT:
      state.all_DevProject.push(action.payload.data);
    case ADD_NEW_DEV_TACHE:
      state.tache_DevProject.push(action.payload.data);
      return { ...state, tache_DevProject: state.tache_DevProject };
    case ADD_NEW_DEV_SOUS_TACHE:
      state.sous_tache_DevProjects.push(action.payload.data);
      return state;
    case UPDATE_DEV_PROJET:
      return {
        ...state,
        all_DevProject: state.all_DevProject.map((item) =>
          item.id === action.payload.id
            ? { ...item, ...action.payload.data }
            : item
        ),
      };
    case REMOVE_DEV_TACHE:
      let tacheproj = state.tache_DevProject.lanes.find(
        (res) => res.title === action.payload.status
      );
      let tachecopy = state.tache_DevProject.lanes.filter(
        (res) => res.title !== action.payload.status
      );
      let objU = {
        id: tacheproj.id,
        title: tacheproj.title,
        cards: tacheproj.cards.filter((res) => res.id !== action.payload.id),
      };
      tachecopy.push(objU);

      return {
        ...state,
        tache_DevProject: { lanes: tachecopy },
        tache_DevProjects: state.tache_DevProjects.filter(
          (res) => res.id !== action.payload.id
        ),
      };

    case REMOVE_DEV_PROJET:
      return {
        ...state,
        all_DevProject: state.all_DevProject.filter(
          (res) => res.id !== action.payload.idprojet
        ),
      };
    case GET_LIST_DEV_PROJECT:
      return { ...state, all_DevProject: action.projets };
    case GET_LIST_DEV_TACHE:
      console.log(action.taches);
      let tache = [];
      let obj = {
        id: 1,
        title: "A Faire",
        cards: action.taches.filter((res) => res.status === "A Faire"),
      };
      let obj1 = {
        id: 2,
        title: "En Cours",
        cards: action.taches.filter((res) => res.status === "En Cours"),
      };
      let obj2 = {
        id: 3,
        title: "Terminé",
        cards: action.taches.filter((res) => res.status === "Terminé"),
      };
      tache.push(obj);
      tache.push(obj1);
      tache.push(obj2);

      return {
        ...state,
        tache_DevProject: { lanes: tache },
        tache_DevProjects: action.taches,
      };
    case GET_LIST_DEV_SOUS_TACHE:
      let taches = [];
      let objS = {
        id: 1,
        
        title: "A Faire",
        cards: action.taches.filter((res) => res.status === "A Faire"),
      };
      let obj1S = {
        id: 2,
        title: "En Cours",
        cards: action.taches.filter((res) => res.status === "En Cours"),
      };
      let obj2S = {
        id: 3,
        title: "Terminé",
        cards: action.taches.filter((res) => res.status === "Terminé"),
      };
      taches.push(objS);
      taches.push(obj1S);
      taches.push(obj2S);

      return {
        ...state,
        sous_tache_DevProject: { lanes: taches },
        sous_tache_DevProjects: action.taches,
      };
    case ADD_DEV_COMMENT:
      return {
        ...state,
        tache_DevProjects: state.tache_DevProjects.map((item) =>
          item.id === action.payload.id
            ? { ...item, 
                
                comment : [item.comment,...action.payload.data] }
            : item
        ),
      };
      case ADD_DEV_SOUS_COMMENT:
        return {
          ...state,
          sous_tache_DevProjects: state.sous_tache_DevProjects.map((item) =>
            item.id === action.payload.id
              ? { ...item, 
                  
                  comment : [item.comment,...action.payload.data] }
              : item
          ),
        };
  
    default:
      return state;
  }
};
