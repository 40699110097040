import React,{useState,useEffect} from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button,NavItem, NavLink, Nav,TabContent,TabPane} from 'reactstrap'
import {firebase_app,googleProvider,facebookProvider,githubProvider, Jwt_token, db, urlbackend } from '../data/config'
import { handleResponse } from '../services/fack.backend'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify';
import {useHistory, withRouter} from 'react-router-dom'
import { Facebook, GitHub} from 'react-feather'
import img1 from '../assets/images/logo/login.png' ;
import img2 from '../assets/images/logo/logo_dark.png' ;
import {Password,SignIn, EmailAddress,RememberPassword,ForgotPassword ,CreateAccount,FIREBASE,AUTH0,JWT,LoginWithJWT } from '../constant';
import moment from 'moment';
import { newfolder, newIntialfolder, updateFirstConnection } from '../redux/foldermanager/action';
import axios from 'axios';
import { getContext, setContext } from 'redux-saga/effects';
import { setLocalStorage } from '../services/restfulapi';
const listfolder = [ "REGISTRE DU PERSONNEL",
  "CONTRAT ET AVENNANT DE TRAVAIL",
   "FICHE DE PAIE",
   "CARNET ENTRETIEN ET INCENDIE",
   "AUDIT SILLIKIER",
  "ENTRETIEN HOTTE/ ELECTRICITE",
   "KBIS" ,
   "AUTORISATION TERRASSE-MAIRIE/EXPLOITATION",
  "FICHE IDENTITE DU RESTAURANT" ]
const Logins = (props) => {
  const history = useHistory()
    const {loginWithRedirect} = useAuth0()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading,setLoading] = useState(false) 
    const [selected, setSelected] = useState("firebase");
    const [togglePassword,setTogglePassword] = useState(false)

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
        localStorage.getItem('Name')
    );

    useEffect(() => {

    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
    }, [value,name]);
    const handleAddFolder = (folderName,idUser) => {
      let folder = {}
      folder.folder_name = folderName
      folder.prop_id = idUser //idUser
      folder.prop_type = "Users"
      folder.folder_parent_id = null
      
      folder.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
      folder.folder_state = "0"
      folder.download_state = "1"
      folder.folder_state_deleted = "0"
      folder.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
      folder.download_url = ""
      folder.icon = "fa fa-file-image-o txt-primary"
      folder.files_number = "0"
      
      newIntialfolder(folder)
     // dispatch(newfolder(folder))
     
      }
    const loginAuth = async (e) => {
      e.preventDefault();
      setLoading(true)

      try {
          await  axios({
            method: 'post',
            url: `${urlbackend}login.php`,
            data: JSON.stringify({email : email,password : password}),
            config: { headers: {'Content-Type': 'application/json; charset=UTF-8'}}
        }).then(function (res) {
          if(res.data !== undefined){ 


            let datauser = res.data
         
            //localStorage.getItem("@intranetidUser")
            localStorage.setItem("@intranetidUser" ,res.data.data.user_id )
          //  window.document.cookie = "tokenintranet="+datauser.jwt+";"
          setLocalStorage(datauser.jwt)
          //  localStorage.setItem('intranetTypeUsertoken',datauser.jwt);
            setLoading(false)
            localStorage.setItem("@intranetTypeUser" ,datauser.data.role )
            
            localStorage.setItem("@intranetLastUser" , datauser.data.last_name )
            localStorage.setItem("@intranetFirstUser" , datauser.data.first_name )
            localStorage.setItem("@intranetimageUser" , datauser.data.image )
            localStorage.setItem("@intranetProEmailsUser" , JSON.stringify([]))
            localStorage.setItem("@intranetProviderListe" , JSON.stringify([]))
            localStorage.setItem("@intranetProviderCategoryId" , datauser.data.provider_category_id )
           // localStorage.setItem('@intranetidMarqueUser',res2.data().marquePID || 0)
            //localStorage.setItem("@intranet_franchiseid", res2.data().marquePID || 0)
            localStorage.setItem("authenticated", true)
            if(res.data.data.role === 'manager'){
              
               localStorage.setItem('@intranetidMarqueUserimage', datauser.extendeddatat?.franchise_image || null)
               localStorage.setItem('@intranetidMarqueUser', datauser.extendeddatat?.franchise_id || 0)
            }
            if(res.data.data.role === 'submanager'){

                localStorage.setItem('@intranetidMarqueUserimage',datauser.extendeddatat?.franchise_image || null)
               localStorage.setItem('@intranetidMarqueUser',datauser.extendeddatat?.franchise_id || 0)
                localStorage.setItem("@intranet_franchiseid", datauser.extendeddatat?.subfranchise_id || 0)
            }
          if((res.data.data.first_connexion == undefined || res.data.data.first_connexion == 1)&& (res.data.data.role === 'manager' || res.data.data.role === 'submanager')){
           for(let i = 0;i<listfolder.length ; i++)
            {
          handleAddFolder(listfolder[i],res.data.data.user_id)
          }
          updateFirstConnection(res.data.data.user_id)
             // db.collection("Users").doc(res.user.uid).update({firstConnexion : false})
             }
 
             setValue(man);
             setName("Admin ");
        
              history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
       
          }
          else {
            setLoading(false)
            setTimeout(() => {
                toast.error("Oppdddss.. Le mot de passe est invalide ou l'utilisateur n'a pas de mot de passe.");
            }, 200);
          }
                setValue(man);
                setName("Admin ");
               
        
                })
      } catch (error) {
        console.log(error)
        setLoading(false)
          setTimeout(() => {
              toast.error("ooooooOppss.. Le mot de passe est invalide ou l'utilisateur n'a pas de mot de passe.");
          }, 200);
      }
    }
   
    const googleAuth = async () => {
      
      try {
              firebase_app.auth().signInWithPopup(googleProvider).then(function (result) {
              setName(result.user.displayName);
              setValue(result.user.photoURL)
              localStorage.setItem('token', Jwt_token);
              setTimeout(() => {
                props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
              }, 200);
            
          });
      } catch (error) {
          setTimeout(() => {
              toast.error("Oppss.. The password is invalid or the user does not have a password.");
          }, 200);
      }
    };

    const facebookAuth = async () => {
      
      try {
              firebase_app.auth().signInWithPopup(facebookProvider).then(function (result) {
              setValue(result.user.photoURL);
              setName(result.user.displayName)
              localStorage.setItem('token', Jwt_token);
              setTimeout(() => {
                props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
              }, 200);
          });
      } catch (error) {
          setTimeout(() => {
              toast.error("Oppss.. The password is invalid or the user does not have a password.");
          }, 200);
      }
    }

    const githubAuth = async () => {
      
      try {
              firebase_app.auth().signInWithPopup(githubProvider).then(function (result) {
              setValue(result.user.photoURL);
              setName(result.additionalUserInfo.username)
              localStorage.setItem('token', Jwt_token);
              setTimeout(() => {
                props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
              }, 200);
          });
      } catch (error) {
          setTimeout(() => {
              toast.error("Oppss.. The password is invalid or the user does not have a password.");
          }, 200);
      }
    }

    const loginWithJwt = (email,password) => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: ({ email, password })
      };
      
      return fetch('/users/authenticate', requestOptions)
      .then(handleResponse)
      .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setValue(man);
        console.log("result", user);
        setName("Emay Walter");
        localStorage.setItem('token', user);
        window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/`
        return user;
      });
    }

    return (
        <Container fluid={true} className="p-0">
        <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
            
              <div className="login-main login-tab"> 
                {/* <Nav className="border-tab flex-column" tabs>
                  <NavItem>
                    <NavLink className={selected === 'firebase' ? 'active' : ''} onClick={() => setSelected('firebase')}>
                      <img src={require("../assets/images/firebase.svg")} alt="" />
                      <span>{FIREBASE}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={selected === 'jwt' ? 'active' : ''} onClick={() => setSelected('jwt')}>
                    <img src={require("../assets/images/jwt.svg")} alt="" />
                    <span>{JWT}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={selected === 'auth0' ? 'active' : ''} onClick={() => setSelected('auth0')}>
                    <img src={require("../assets/images/auth0.svg")} alt="" />
                    <span>{AUTH0}</span>
                    </NavLink>
                  </NavItem>
                </Nav> */}
                <TabContent activeTab={selected} className="content-login">
                  <TabPane  className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form">
                    <div>
                <a className="logo" href="index.html">
                  <img width="200" height="50" className="img-fluid" src={img1} alt=""/>
        {/*           <img width="200" height="50" className="img-fluid for-dark" src={img1} alt=""/> */}
                </a>
              </div>
                      <FormGroup>
                        <Label className="col-form-label">{EmailAddress}</Label>
                        <Input style={{background:  'white', color : 'black'}} className="form-control loginform" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{Password}</Label>
                        <Input style={{background:  'white', color : 'black'}} className="form-control" type={togglePassword ?  "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required=""/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </FormGroup>
                      <div className="form-group mb-0">
                        <div className="checkbox ml-3">
                          <Input  id="checkbox1" type="checkbox"/>
                          <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                        </div><a className="link" href="#javascript">{ForgotPassword}</a>
                        {selected === "firebase" ?
                        <Button color="primary" className="btn-block" disabled={loading ? loading : loading} onClick={(e) => loginAuth(e)}>{loading ? "LOADING..." : SignIn }</Button>
                        :
                        <Button color="primary" className="btn-block" onClick={() => loginWithJwt(email,password)}>{LoginWithJWT}</Button>
                        }
                      </div>
                   
                    
                    
                    </Form>
                  </TabPane>
                  <TabPane  className="fade show" tabId="auth0">
                    <div className="auth-content">
                        <img src={require("../assets/images/auth-img.svg")} alt="" />
                        <h4>{"Welcome to login with Auth0"}</h4>
                        <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                        <Button color="info" onClick={loginWithRedirect}>{AUTH0}</Button> 
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
        </Row>
        </Container>
    );
}

export default withRouter(Logins);