import {GET_FRANCHISE_LIST,
        ADD_TO_MY_FRANCHISE,
        ADD_NEW_FRANCHISE,
        REMOVE_FRANCHISE,
        REMOVE_FROM_MY_FRANCHISE,
        UPDATE_FRANCHISE,
        UPDATE_MY_FRANCHISE,
        UPDATE_STATE_FRANCHISE,
        GET_FRANCHISE_BY_ID,
        ADD_AUDIT_TO_FRANCHISE,
        ADD_ANNUAIRE_TO_FRANCHISE,

  } from '../actionTypes'


const initial_state = {
    franchises : [],
    franchise : {},
    franchisesmanagerdata:[],
    franchisesfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_FRANCHISE_LIST:
            return {...state,franchises: action.franchises, loading: true,};
            case GET_FRANCHISE_BY_ID:
                return {...state,franchise: action.payload, loading: true,};
    
        case ADD_TO_MY_FRANCHISE:
            return {...state,myfranchisesdata:[...state.myfranchisesdata, { ...action.payload.myfranchisesdata}]};
        
        case ADD_NEW_FRANCHISE:
       
            state.franchises.push(action.payload.franchisedata)
        return {...state,franchises: state.franchises};
        
        case UPDATE_FRANCHISE:
            return {
                ...state,franchises: state.franchises.map(item => item.franchise_id === action.payload.id ?
                    { ...item, 
                        franchise_id: action.payload.id,
                      
                      ...action.payload.updatedata
                    } : 
                    item
                ) 
            };
            case ADD_AUDIT_TO_FRANCHISE:
            return {
                ...state,franchises: state.franchises.map(item => item.id === action.payload.id ?
                    { ...item, 
                      
                        auditListe :action.payload.auditListe
                    } : 
                    item
                ) 
            };
            case ADD_ANNUAIRE_TO_FRANCHISE:
                return {
                    ...state,franchises: state.franchises.map(item => item.id === action.payload.id ?
                        { ...item, 
                          
                            annuairesListe :action.payload.annuairesListe
                        } : 
                        item
                    ) 
                };
            case UPDATE_STATE_FRANCHISE:
                return {
                    ...state,franchises: state.franchises.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            fournisseurState:action.payload.state
                        } : 
                        item
                    ) 
                };
        case UPDATE_MY_FRANCHISE:
                return {
                    ...state,myfranchisesdata: state.myfranchisesdata.map(item => item.id === action.payload.id ?
                        { ...item, 
                            id: action.payload.id,
                            fillstar:action.payload.updateMydata.fillstar,
                            image: action.payload.image_url,
                            title: action.payload.updateMydata.title,
                            website_url: action.payload.updateMydata.url,
                            desc: action.payload.updateMydata.desc,
                            collection:action.payload.updateMydata.collection
                        } : 
                        item
                    ) 
                };

        case REMOVE_FRANCHISE:
            let new_franchises = state.franchises.filter(item=> action.removeid !== item.id)
            return{...state,franchises: new_franchises}
        
        case REMOVE_FROM_MY_FRANCHISE:
            let new_my_franchises = state.myfranchisesdata.filter(item=> action.franchisesid !== item.id)
            return{...state,myfranchisesdata: new_my_franchises}
                
        default: return { ...state };
    }
    
}