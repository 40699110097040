import React,{useState,useEffect, Fragment, useRef} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import firebase from "firebase"
import {Container,Row,Col,Card,CardBody,Media,Label,Input,Button,Form,FormGroup, Modal, ModalHeader, ModalBody, InputGroup, Pagination, PaginationItem, PaginationLink, Progress} from 'reactstrap'
import {useDispatch,useSelector} from 'react-redux'
import {UPDATE_EMAIL_TYPES, GET_ALL_EMAIL_ASYN,WATCH_ALL_TYPE_EMAIL, GET_ALL_TYPE_ASYN, WATCH_EMAIL, WATCH_EMAIL_SEND, WATCH_EMAIL_TRASH, WATCH_MAIL_GROUP_LIST, WATCH_ALL_EMAIL_ASYN} from '../../../redux/actionTypes'
import {Dropdown,DropdownItem,DropdownToggle,DropdownMenu} from 'reactstrap'
import user from '../../../assets/images/user/1.jpg';
import email1 from '../../../assets/images/email/1.jpg';
import email2 from '../../../assets/images/email/2.jpg';
import email3 from '../../../assets/images/email/3.jpg';
import email4 from '../../../assets/images/capturegmail.png';

import {createAdresseEmail, deleteEmail, groupBy} from '../../../redux/email/action'
import htmr from 'htmr';
import {MARKJENCO,MARKJENCOEMAIL,NEWMAIL,Inbox,AllMail,Sent,Draft,TrashText,IMPORTANT,Starred,UNREAD,Spam,OUTBOX,UPDATE,ALERT,NOTES,NoMailFound,NewMessage,To,ATTACHMENTS,DownloadAll,Reply,ReplyAll,Forward,Send,Messages,More,Subject, AddNewActualite, MailSetting, Name, Age, Mobile, Save, Cancel, EmailAddress, Password, Imap, Previous, Next} from '../../../constant';
import { fetchemailsApiBy, fetchemailsdeleteFromInboxApiBy, fetchemailsdeleteFromSendpiBy, fetchemailsdeleteFromtrashApiBy, fetchemailssendApiBy, fetchemailstrashApiBy, fetchSendemailsApi } from '../../../api';
import {decode} from 'html-entities';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import emailjs from 'emailjs-com';
import SweetAlert from 'sweetalert2'
import { Typeahead } from 'react-bootstrap-typeahead';
import { db, firebase_app, firestorage } from '../../../data/config';
import { DivideSquare, Edit, Link2, Share2, Tag, Trash, Trash2, User, UserPlus, Users } from 'react-feather';
import { deleteNotes, NewGroupeMail, NewNotes } from '../../../redux/mail-group/action';
var rfc2047 = require('rfc2047');

const { CreateClient } = require('pop3-lib-promise/promise-pop')

//import Pop3Command from 'node-pop3';
// const client =  new ImapFlow.ImapFlow({
//   host: 'smtp.gmail.com',
//     port: 993,
//     secure: false,
//     auth: {
//         user: 'bougares.hatem@gmail.com',
//         pass: '23727105ZOHRAHATEM'
//     }
// });
//import  imaps  from 'imap-simple';

// const config = {
//     imap: {
//       user: 'bougares.hatem@gmail.com',
//       password: '23727105ZOHRAHATEM',
//       host: 'imap.gmail.com',
//       port: 993,
//         tls: true,
//         authTimeout: 3000
//     }
// };

// const pop3 = new Pop3Command({
//   user: 'bougares.hatem@gmail.com',
//   password: '23727105ZOHRAHATEM',
//   host: 'smtp.gmail.com',
// });

var images = require.context('../../../assets/images', true);


class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          let storage = firestorage.ref();
          let uploadTask = storage
            .child(file.name)
            .put(file);
          uploadTask.on(
          
            firebase.default.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function(snapshot) {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              
              switch (snapshot.state) {
                case firebase.default.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebase.default.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            function(error) {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              // eslint-disable-next-line default-case
              switch (error.code) {
                case "storage/unauthorized":
                  reject(" User doesn't have permission to access the object");
                  break;

                case "storage/canceled":
                  reject("User canceled the upload");
                  break;

                case "storage/unknown":
                  reject(
                    "Unknown error occurred, inspect error.serverResponse"
                  );
                  break;
              }
            },
            function() {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function(downloadURL) {
                  // console.log("File available at", downloadURL);
                  resolve({
                    default: downloadURL
                  });
                });
            }
          );
        })
    );
  }
}
const Email = (props) => {
  const inputFile = useRef(null);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const dispatch = useDispatch();
  const usersList = useSelector(content => content.EmailApp.allEmails);
  const emailsTrash = useSelector(content => content.EmailApp.allTrashEmails);
  const emailsSend = useSelector(content => content.EmailApp.allSendEmails);

 const [addUserModal, setaddUserModal] = useState(false)
  const addUserToggle = () => { setaddUserModal(!addUserModal) }

  const [addUser, setaddUser] = useState(false)
  const addUserbuttonToggle = () => { setaddUser(!addUser) }
  const numbersmessages = useSelector(content => content.EmailApp.numbersMessages);
  const numbersAllMsg = useSelector(content => content.EmailApp.numbersAllMsg);
  
  const onButtonClick = () => {
   inputFile.current.click();
  };
  const TypesOfData = useSelector(mailTypes => mailTypes.EmailApp.types);
  const mailsList = useSelector(mailTypes => mailTypes.EmailApp.listEmails);
  const [compose, setCompose] = useState(true);
  const { register, handleSubmit, errors } = useForm(); 
  const [dropdownOpen, setOpen] = useState(false);
  const [loading, setloading] = useState(true);
  
  const [type, setType] = useState(Inbox);
  const [emailIds, setEmailIds] = useState([]);
  const [emaildestination, setemaildestination] = useState([]);
  const [emailgroup, setemailgroup] = useState([]);

  const [firstname, setfirstname] = useState(localStorage.getItem("@intranetFirstUser"));
  const [lastname, setlastname] = useState(localStorage.getItem("@intranetLastUser"));
  const [userImage, setuserImage] = useState(localStorage.getItem("@intranetimageUser"));
  const [role, setrole] = useState(localStorage.getItem("@intranetTypeUser"));
  // eslint-disable-next-line
  const [mailData, setMailData] = useState([]);
  const [attachement, setattachement] = useState([]);
  const [checked, setchecked] = useState(false);
  const [selectedFav, setSelectedFav] = useState(false);
  const [singleMailRecord, setSingleMailRecord] = useState({});
  const [contenu, setContenu] = useState("");
  const [toadress, settoadress] = useState("");
  const [emaildate, setemaildate] = useState("");
  const [subjectemail, setsubjectemail] = useState("");
  const [email, setEmail] = useState("");
  const [destinataire, setDestinataire] = useState("");
  const [object, setobjet] = useState("");
  const [message, setMessage] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageMail, setselectedPageMail] = useState(1);
  const [disableNextPage, setdisableNextPage] = useState(false);
  const [disablepreviousPage, setdisablepreviousPage] = useState(false);
  const [Listemail, setListeEmail] = useState(localStorage.getItem("@intranetProEmailsUser") !== undefined ? JSON.parse(localStorage.getItem("@intranetProEmailsUser")) : []);

  const [selectedEmail2, setselectedEmail2] = useState(localStorage.getItem("@intranetProEmailsUser") !== undefined || localStorage.getItem("@intranetProEmailsUser") !== [] ? JSON.parse(localStorage.getItem("@intranetProEmailsUser"))[0] : {});
  const [selectedEmail, setselectedEmail] = useState({});

  const [selectedEmails, setselectedEmails] = useState(localStorage.getItem("@intranetProEmailsUser") !== undefined || localStorage.getItem("@intranetProEmailsUser") !== [] ? JSON.parse(localStorage.getItem("@intranetProEmailsUser"))[0]?.email : "");

  const [finalEmail, setfinalEmail] = useState();
  const [selectedDomaine, setselectedDomaine] = useState("");
  const [imap, setimap] = useState("");
  const [groupeName, setgroupeName] = useState("");
  const [stateupload, setstateupload] = useState(0);
  const [statebareupload, setstatebareupload] = useState(false);
  const [searchvalue, setsearchvalue] = useState("")
  const [selectedautre, setselectedautre] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const [modal, setModal] = useState(false);

  const toggleAddSetting = () => setModal(!modal);




  const userProfile = {
    // recent mode, get 30days email
    mail: 'recent:bougares.hatem@gmail.com',
    password: '23727105ZOHRAHATEM'
}
const port = 995
  const host = 'pop.gmail.com'
  const AddSetting = data => {
    if (data !== '') {
      data.email = email + selectedDomaine
      data.imap = imap
      data.user_id =localStorage.getItem("@intranetidUser")

     dispatch(createAdresseEmail(data));
      setModal(false)

    } else {
      errors.showMessages();
    }
  };

  const mailgroup = useSelector(content => content.MailGroupApp.mailgroup);
  useEffect(() => {

    

    dispatch({type : WATCH_ALL_EMAIL_ASYN})
   dispatch({type : WATCH_MAIL_GROUP_LIST})

setTimeout(() => {
  if(mailsList.length === 0){

    setModal(true)
  }
}, 2000);
   



  if(selectedEmail2 !== {} || selectedEmail2 !== undefined){
 localStorage.setItem("@intranetimapDriver", selectedEmail2?.imap || " ")
 localStorage.setItem("@intranetimapemail", selectedEmail2?.email || " ")
 localStorage.setItem("@intranetimappassword", selectedEmail2?.password || " ")
 localStorage.setItem("@intranetselectedPageMail",1)
 dispatch({type : WATCH_EMAIL_SEND});
 dispatch({type : WATCH_EMAIL_TRASH});
  }
  setTimeout(() => {
    setloading(false)
  }, 3000);
   // main().catch(err => console.error(err));
      if(usersList !== null){
      const result = groupBy(usersList, (item) => {
        return [item.type];
      });
    
    }
    
   }, [dispatch,usersList]);
   const main = async () => {
    const client = await CreateClient(995, 'pop.gmail.com', {
        tlserrs: true,
        enabletls: true,
        debug: false
    }).catch(res => console.log(res))
    let res = await client.login(userProfile.mail, userProfile.password)
    let list = await client.list()
    console.log(list)
  }
//   const main = async () => {
//     // Wait until client connects and authorizes
//     await client.connect();

//     // Select and lock a mailbox. Throws if mailbox does not exist
//     let lock = await client.getMailboxLock('INBOX');
//     try {
//         // fetch latest message source
//         // client.mailbox includes information about currently selected mailbox
//         // "exists" value is also the largest sequence number available in the mailbox
//         let message = await client.fetchOne(client.mailbox.exists, { source: true });
//         console.log(message.source.toString());

//         // list subjects for all messages
//         // uid value is always included in FETCH response, envelope strings are in unicode.
//         for await (let message of client.fetch('1:*', { envelope: true })) {
//             console.log(`${message.uid}: ${message.envelope.subject}`);
//         }
//     } finally {
//         // Make sure lock is released, otherwise next `getMailboxLock()` never returns
//         lock.release();
//     }

//     // log out and close connection
//     await client.logout();
// }; 
  const dynamicImage = (image) => {
    return images(`./${image}`);
  }
   
  const clickCompose = () => {
      setCompose(true);
  }

  const selectTypes = (types) => {
    setSelectedFav(false)
    setType(types)   
  }

  const selectFev = (types) => {
    setSelectedFav(true)
  }

  const moveEmails = (val) => {
    [...document.querySelectorAll('.checkbox_animated')].map((input) => {
        if (input.checked) {
            let fakeInput = {
                target: {
                    value: input.value,
                    checked: false
                }
            }
            input.checked = !input.checked;
            selectedmail(fakeInput);
        }
        return null;
    })
    for (var i = 0; i < usersList.length; i++) {
        if (emailIds.includes(usersList[i].id)) {
            usersList[i].type = val;
        }
    }

    var result = groupBy(usersList, function (item) {
        return [item.type];
    });

     dispatch({type : GET_ALL_EMAIL_ASYN, usersList})
     dispatch({ type: GET_ALL_TYPE_ASYN, result })

    }

  const selectedCompose = (email, i) => {
    setselectedEmail(email)
    fetchemailsApiBy(i).then(res=> {
      
    setContenu(decode(res.data.contenu[1]))
setattachement(res.data.attachement)
    settoadress(email.toadress)
    setemaildate(email.date)
    setsubjectemail(email.subject)
    

       
    })
    setCompose(false);
    setSingleMailRecord(email);
   }
   const selectedComposeSent = (email, i) => {
    setselectedEmail(email)
    fetchemailssendApiBy(i).then(res=> {
      
    setContenu(decode(res.data.contenu[1]))
    settoadress(email.toadress)
    setemaildate(email.date)
    setsubjectemail(email.subject)
    

       
    })
    setCompose(false);
    setSingleMailRecord(email);
   }
   const selectedComposeTrash = (email, i) => {
    setselectedEmail(email)
    fetchemailstrashApiBy(i).then(res=> {
      
    setContenu(decode(res.data.contenu[1]))
    settoadress(email.toadress)
    setemaildate(email.date)
    setsubjectemail(email.subject)
    

       
    })
    setCompose(false);
    setSingleMailRecord(email);
   }

  const addFavourite = (singleMailRecord) => {
    dispatch({ type: UPDATE_EMAIL_TYPES, payload: singleMailRecord })
  }

  const selectedmail = (e, emailID) => {

    const IDs = emailIds;
    setchecked(e.target.checked);
    
        if (e.target.checked) {
            IDs.push(emailID)
            setEmailIds(IDs)
           // const arr = [...new Set(IDs)];
            //setEmailIds(arr)
        } else {
            setEmailIds(IDs.filter(res => res !== emailID))
        }
    
  }   
  const handleselectedDomaine = () => {
    setselectedDomaine('');
  }
  const sendMail = async (e) =>{
    if(e !== null)
   e.preventDefault()
  
   setCompose(true);
  }
  const onChange = (evt) => {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setMessage(newContent)
  }
  
  const onBlur = (evt) =>{
    console.log("onBlur event called with event info: ", evt);
  }
  
  const afterPaste= (evt) =>{
    console.log("afterPaste event called with event info: ", evt);
  }
  const senMessage = () => {
  
if(selectedEmail2 === {} || selectedEmail2 === undefined){
  SweetAlert.fire(
    'Erreur ',
    'Veuillez choisir adresse email ou configurer email',
    'success'
  )

}
 else if(message !== "" &&  emaildestination.length > 0){
   let resemail = emaildestination.map(res => {return res.label})
   console.log(resemail.toString())
     fetchSendemailsApi(selectedEmails,"<html><body>"+message+"</body></html>",object,resemail.toString())
     setemaildestination([])
     setMessage("")
     setobjet("")
    }
    else {
      SweetAlert.fire(
        'Erreur ',
        'Veuillez configurer adresse email pour envoyer email',
        'success'
      )
    }
  }
  const RemoveMailfromInbox  = (email) => {

    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus voir votre messages",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
     fetchemailsdeleteFromInboxApiBy(email).then(res =>   {SweetAlert.fire(
      'Supprimé',
      'Votre email est supprimé',
      'success'
    )
    setEmailIds([])
    setTimeout(() => {
      dispatch({type : WATCH_EMAIL});
    }, 2000);


      } )
      
      }
      else {
        
      }
    })
  }
  const RemoveMailfromTrash  = (email) => {

    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus voir votre messages",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        fetchemailsdeleteFromtrashApiBy(email).then(res =>   {SweetAlert.fire(
          'Supprimé',
          'Votre email est supprimé',
          'success'
        )
        setEmailIds([])
 dispatch({type : WATCH_EMAIL_TRASH});
        }
        )
          
      }
      else {
        
      }
    })
  }
  const RemoveMailfromSend  = (email) => {

    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus voir votre messages",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        fetchemailsdeleteFromSendpiBy(email).then(res =>   {
          SweetAlert.fire(
          'Supprimé',
          'Votre email est supprimé',
          'success'
        )
        setEmailIds([])
 dispatch({type : WATCH_EMAIL_SEND});
          }
        )
          
      }
      else {
        
      }
    })
  }
  const RemoveConfigMail  = (email) => {

    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus voir votre messages",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
      
      
     
        dispatch(deleteEmail(email));
        SweetAlert.fire(
          'Supprimé',
          'Votre email est supprimé',
          'success'
        )
      }
      else {
        
      }
    })
  }
  const nextPage =() => {
    if(selectedPage + 5 <= numbersAllMsg / 25){
      setloading(true)
    setSelectedPage(selectedPage + 5)
    setTimeout(() => {
      setloading(false)
    }, 3000);
    }
    setdisableNextPage(true)
    setdisablepreviousPage(false)
  }
  const previousPage = () => {
    if(selectedPage - 5 >= 0){
      setloading(true)
    setSelectedPage(selectedPage - 5)
  
    setTimeout(() => {
      setloading(false)
    }, 3000);
    }
    setdisableNextPage(false)
    setdisablepreviousPage(true)

  }
  const getMailFromSelectPage = (i) => {
    setloading(true)
    setdisableNextPage(false)
    setdisablepreviousPage(false)
    setselectedPageMail(i)
    console.log(numbersAllMsg - ((i-1)*25))
    localStorage.setItem("@intranetselectedPageMail",i)
    dispatch({type : WATCH_EMAIL});
    dispatch({type : WATCH_EMAIL_SEND});
 dispatch({type : WATCH_EMAIL_TRASH});

 setTimeout(() => {
  setloading(false)
}, 3000);
  }
  
  const getPageNumber = () => {
    let res = []
for(let i = selectedPage ;i < numbersAllMsg / 25 && i < selectedPage + 5;i++){
res.push(<PaginationItem active={selectedPageMail == i}><PaginationLink onClick={() => {
  getMailFromSelectPage(i)}}>{i}</PaginationLink></PaginationItem>)
}
return res 
  }
  const searchUser = (value) =>{
    setsearchvalue(value)
  }
  const handleFileUpload = e => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;

      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.
      let storage = firestorage.ref();
      let uploadTask = storage
        .child(new Date().getTime()+filename)
        .put(files[0]);
      uploadTask.on(
      
        firebase.default.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot) {
          setstatebareupload(true)
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setstateupload(progress)
          switch (snapshot.state) {
            case firebase.default.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.default.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        function(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          // eslint-disable-next-line default-case
          switch (error.code) {
            case "storage/unauthorized":
              alert(" User doesn't have permission to access the object");
              break;

            case "storage/canceled":
              alert("User canceled the upload");
              break;

            case "storage/unknown":
              alert(
                "Unknown error occurred, inspect error.serverResponse"
              );
              break;
          }
        },
        function() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(function(downloadURL) {
              setstatebareupload(false)
              console.log("File available at", downloadURL);
             let messagecopy = message.slice(0,message.length - 4);
             messagecopy = messagecopy + `<a href="${downloadURL}">${filename}</a>`+"</p>"
             setMessage(messagecopy)
            });
        }
      );
   
    }
  };
  const addNote = () => {


    if(!groupeName){
      return;
    }
    if(!emailgroup.length >0){
        return;
      }

      let resemail = emailgroup.map(res => {return res.label})
    const newGroup = {
    
      groupeName: groupeName,
      emailListe : emailgroup,
      emailListeString : resemail.toString(),
      user_id : localStorage.getItem("@intranetidUser")
      
    
    }
     dispatch(NewGroupeMail(newGroup))
    
    }
    const [random, setrandom] = useState(0)
    const [selectedUser, setselectedUser] = useState([]);
    const [selectedUserInfo, setselectedUserInfo] = useState([]);
    const addToSelectedListe = (id,item) => {
      let selectedUserInfoCopy = selectedUserInfo
      let selectedUserCopy = selectedUser
      if(selectedUserCopy.includes(id) == true){
          selectedUserInfoCopy.splice(selectedUserInfoCopy.findIndex(res => res.id == id), 1)
        selectedUserCopy.splice(selectedUserCopy.findIndex(res => res == id), 1)
        setselectedUser(selectedUserCopy)
        setselectedUserInfo(selectedUserInfoCopy)
      setrandom(Math.random)
      }
      else {
        selectedUserCopy.push(id)
        selectedUserInfoCopy.push(item)
        setselectedUser(selectedUserCopy)
        setselectedUserInfo(selectedUserInfoCopy)
        setrandom(Math.random)
      }
        }
const setDestinataireEmail = async () => {

  let resemail = []
   for (let i = 0 ; i < selectedUserInfo.length;i++){

     resemail = resemail.concat(resemail, selectedUserInfo[i].emailListe)
    }
   console.log(resemail)
 setemaildestination(resemail)
 addUserToggle(

 )
}
const deleteNote = (id) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus voir votre groupe",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
      dispatch(deleteNotes(id))
 

    }
    } )
    
    
   
    
 

}
    return (
      <Fragment>
        <Breadcrumb parent="Email" title="Boite Email"/>
        <Container fluid={true}>
        {/* <form onSubmit={(e) => sendMail(e)}>
      <label>from</label>
      <input type="text" name="from_name" />
      <label>name</label>
      <input type="text" name="name" />
      <label>Email</label>
      <input type="email" name="to_name" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form> */}
        <div className="media-body text-right">
                <Form className="d-inline-flex">

                  <Link onClick={() => toggleAddSetting()} className="btn btn-primary  mr-1" > {MailSetting}</Link>
                  <Link onClick={() => addUserToggle()} className="btn btn-primary" > <Users/></Link>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
              
              </div>
              
<div style={{ overflow: 'auto',height: 90,
  whiteSpace: 'nowrap'}}>
    {mailsList.map((email , index) => (
  <Button color="primary"  onClick={()=> {
    localStorage.setItem("@intranetimapDriver", email.imap)
    localStorage.setItem("@intranetimapemail", email.email)
    localStorage.setItem("@intranetimappassword", email.password)
    dispatch({type : WATCH_EMAIL_SEND});
    dispatch({type : WATCH_EMAIL_TRASH});
    dispatch({type : WATCH_EMAIL}); 
    setselectedEmail2(email);  
    setloading(true)
    setTimeout(() => {
      setloading(false)
    }, 4000);
    setEmailIds([])
    setselectedEmails(email.email)}} outline={selectedEmails === email.email ? false :  true} onDoubleClick={()=> RemoveConfigMail(email.mail_id)} className="btn-air-primary" >{email.email}</Button>
                               

    ))}

                              
</div>
            <div className="email-wrap">
     {numbersAllMsg > 25  ? 
     <nav aria-label="Page navigation example">
     <Pagination className="pagination justify-content-end pagination-primary" aria-label="Page navigation example">
               <ul className="pagination pagination-primary">
                 <PaginationItem active={disablepreviousPage}><PaginationLink  onClick={() => previousPage()}>{Previous}</PaginationLink></PaginationItem>
                 {getPageNumber().map((res , i) => (
                   res
                 ) )}
                
                 <PaginationItem active={disableNextPage}><PaginationLink onClick={() => nextPage()}>{Next}</PaginationLink></PaginationItem>
               </ul>
             </Pagination>
             </nav>
             : ""
     
     }
            
                
              <Row>
                <Col xl="3" lg="4" md="6 box-col-6" sm="12">
                  <div className="email-left-aside">
                    <Card>
                      <CardBody>
                        <div className="email-app-sidebar">
                          <Media >
                            {/* <div className="media-size-email">
                            <Media   className="mr-3 rounded-circle img-50"  src={userImage !== null || userImage !== undefined ? userImage:  user} alt=""/></div>
                            */} 
                            <Media body>
                              <h6 className="f-w-400"  >{firstname +  " " + lastname}</h6>
                              <p>{selectedEmail2 !== undefined ?  selectedEmail2?.email : ""}</p>
                            </Media>
                          </Media>
                          <ul className="nav main-menu" role="tablist">
                            <li className="nav-item">
                              <a className="btn-primary btn-block btn-mail"  onClick={sendMail} href="#javascript">
                              <i className="icofont icofont-envelope mr-2"></i> 
                                {NEWMAIL}
                              </a>
                            </li>
                            <li className="nav-item" onClick={() => selectTypes(Inbox)}>
                              <a  className={`show ${type === Inbox ? 'active' : ''}`} href="#javascript">
                              <span style={{fontSize : 10,textTransform : 'none',width: '10%'}}  className='title'>
                              <i className="icon-import"></i> 
                             </span>
                              <span style={{fontSize : 10,textTransform : 'none'}}  className='title'>
                               
                                {Inbox}
                              </span>
                              <span className="badge pull-right digits">
                                ({numbersmessages})
                              </span>
                              </a>
                           </li>
                            {/* <li onClick={() => selectTypes('AllEmails')}>
                              <a className={`${type === 'AllEmails' ? 'active' : ''}`} href="#javascript">
                                <span className="title">
                                  <i className="icon-folder"></i> 
                                  {AllMail}
                                  </span>
                              </a>
                            </li> */}
                            <li onClick={() => selectTypes(Sent)}>
                              <a href="#javascript" className={`${type === Sent ? 'active' : ''}`}>
                              <span style={{fontSize : 10,textTransform : 'none',width: '10%'}}  className='title'>
                              <i className="icon-new-window"></i>
                             </span>
                                  <span style={{fontSize : 10,textTransform : 'none'}}  className="title">
                                   
                                      {Sent}
                                  </span>
                                  <span className="badge pull-right digits">
                                      ({emailsSend !== null  ? emailsSend.length : 0})
                                  </span>
                              </a>
                          </li>
                        
                          <li onClick={() => selectTypes(TrashText)}>
                              <a href="#javascript" className={`${type === TrashText ? 'active' : ''}`}>
                              <span style={{fontSize : 10,textTransform : 'none',width: '10%'}}  className='title'>
                              <i className="icon-trash"></i>
                             </span>
                                  <span style={{fontSize : 10,textTransform : 'none'}}  className="title">
                                     
                                      {TrashText}
                                  </span>
                                  <span className="badge pull-right digits">
                                  ({emailsTrash !== null  ? emailsTrash.length : 0})
                                  </span>
                              </a>
                          </li>
                         
                          <li>
                              <hr />
                          </li>
                         
                          </ul>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col xl="3"   lg="8" md="6 box-col-6" sm="12">
                  <div className="email-right-aside">
                    <Card className="email-body">
                    {emailIds.length > 0 ?
                    <div className="media-body text-right">
                <Form className="d-inline-flex">

                  <Link onClick={() => {
               if(  type === Inbox ){
                let ids = emailIds[0] 
                emailIds.forEach(res => {
                  console.log(res)
                  ids= ids + ',' + res
                } )
                RemoveMailfromInbox(ids)

               }else if (type === Sent){
                let ids = emailIds[0]  
                emailIds.forEach(res => {
                  console.log(res)
                  ids = ids + ',' + res
                } )
                RemoveMailfromSend(ids)
               } else if ( type === TrashText){
                let ids = emailIds[0]  
                emailIds.forEach(res => {
                  console.log(res)
                  ids =  ids + ',' + res
                } )
            
                RemoveMailfromTrash(ids)
               }
                  
                  }} className="btn btn-primary" >   <i className='fa fa-trash'></i></Link>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
              
              </div> : ""}
                      <div className="pr-0 b-r-light">
                        <div className="email-top">
                            <Row>
                                <Col>
                                    <h5>{selectedFav ? 'Favourite' : type}</h5>
                                </Col>
                                {/* <Col className="text-right">
                                    <div className="dropdown">
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle className="dropbtn" color="primary" caret> {More} </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => moveEmails("Draft")}>{Draft}</DropdownItem>
                                                <DropdownItem onClick={() => moveEmails("TrashText")}>{TrashText}</DropdownItem>
                                                <DropdownItem onClick={() => moveEmails("Spam")}>{Spam}</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                      <div className="inbox custom-scrollbar">
                        {
                         type === Inbox  ?
                             ( usersList?.length > 0  && loading === false ?
                                  usersList.map((list, index) => {
                                    console.log(list)
                                      return (
                                          <Media key={index} onClick={() => selectedCompose(list, list.value.id)}>
                                              <Label className="d-block">
                                              <Input className="checkbox_animated" 
                                                      name="chk-ani" type="checkbox" checked={emailIds.includes(list.value.id) === true} onChange={(e) => selectedmail(e, list.value.id)}  />
                                              </Label> 
                                              <div className="media-size-email">
                                                  
                                              </div>
                                              <Media body>
                                                <h6>{ rfc2047.decode(list?.value?.obj?.from[0]?.personal)}  <small><span className="digits">({list?.value?.obj?.Date})</span></small></h6>
                                                  <p>{list?.value?.obj?.from[0]?.personal}</p>
                                              </Media>
                                        <i onClick={() => RemoveMailfromInbox(list.value.id)} color='red' className='fa fa-trash'></i>
                                          </Media>
                                      )
                                  })
                                  : (loading === true ? 
                                  <div>
                                     
                                      <div className="loader-box">
                      <div className="loader-2"></div>
                    </div> </div>
                             :
                                  <div className="search-not-found text-center ng-star-inserted" >
                                      <div className="">
                                        
                                          <p className="mb-0">{NoMailFound}</p>
                                      </div>
                                  </div>))
                              : type === Sent ?
                              (emailsSend !== null ?
                                emailsSend.map((list, index) => {
                                      return (
                                        <Media key={index} onClick={() => selectedComposeSent(list, list.value.id)}>
                                        <Label className="d-block">
                                            <Input className="checkbox_animated" 
                                                name="chk-ani" type="checkbox" onChange={(e) => selectedmail(e, list.value.id)} />
                                        </Label> 
                                        <div className="media-size-email">
                                            
                                        </div>
                                        <Media body>
                                            <h6>{ rfc2047.decode(list.value.obj.from[0].personal)}  <small><span className="digits">({list.value.obj.Date})</span></small></h6>
                                            <p>{list.value.obj.from[0].personal}</p>
                                        </Media>
                                        <i onClick={() => RemoveMailfromSend(list.value.id)} color='red' className='fa fa-trash'></i>
                                    </Media>
                                      )
                                  })
                                  :
                                  <div className="search-not-found text-center ng-star-inserted" >
                                      <div className="">
                                          <Media alt="" className="second-search" src={images(`./search-not-found.png`)} />
                                          <p className="mb-0">{NoMailFound}</p>
                                      </div>
                                  </div>)
                                : type === TrashText ?
                                (emailsTrash !== null ?
                                  emailsTrash.map((list, index) => {
                                          return (
                                            <Media key={index} onClick={() => selectedComposeTrash(list, list.value.id)}>
                                            <Label className="d-block">
                                                <Input className="checkbox_animated" 
                                                    name="chk-ani" type="checkbox" onChange={(e) => selectedmail(e, list.value.id)}  />
                                            </Label> 
                                            <div className="media-size-email">
                                                
                                            </div>
                                            <Media body>
                                                <h6>{ rfc2047.decode(list.value.obj.from[0].personal)}  <small><span className="digits">({list.value.obj.Date})</span></small></h6>
                                                <p>{list.value.obj.from[0].personal}</p>
                                            </Media>
                                            <i onClick={() => RemoveMailfromTrash(list.value.id)} color='red' className='fa fa-trash'></i>
                                        </Media>
                                          )
                                      })
                                      :
                                      <div className="search-not-found text-center ng-star-inserted" >
                                          <div className="">
                                              <Media alt="" className="second-search" src={images(`./search-not-found.png`)} />
                                              <p className="mb-0">{NoMailFound}</p>
                                          </div>
                                      </div>)
                                      :  <div className="search-not-found text-center ng-star-inserted" >
                                      <div className="">
                                          <Media alt="" className="second-search" src={images(`./search-not-found.png`)} />
                                          <p className="mb-0">{NoMailFound}</p>
                                      </div>
                                  </div>
                          }
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col xl="6" md="12 box-col-12" >
                  
                  <div className="email-right-aside">
              
                    <Card className="email-body radius-left">

                      <div className="pl-0">
                        <div className="tab-content">
                        <div className={`tab-pane fade ${compose ? 'active show' : ''}`} id="pills-darkhome" role="tabpanel" aria-labelledby="pills-darkhome-tab">
                            <div className="email-compose">
                              <div className="email-top compose-border">
                                <Row>
                                  <Col sm="8 xl-50">
                                    <h4 className="mb-0">{NewMessage}</h4>
                                  </Col>
                                  <Col sm="4 xl-50" className="btn-middle">
                                    <Button disabled={selectedEmail2 === {} || selectedEmail2 === undefined} onClick={() => senMessage()} color="primary" className="btn-block btn-mail text-center mb-0 mt-0">
                                      <i className="fa fa-paper-plane mr-2"></i>{Send}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                              <div className="email-wrapper">
                                <Form className="theme-form">
                                  <FormGroup>
                                    <Label className="col-form-Label pt-0">{To}</Label>

                                    <Typeahead
                                    
                                    ref={ref}
                                                    id="custom-typeahead"
                                                    allowNew
                                                    multiple

                                                    onInputChange={(t,e) => {
                                                      if (t.includes(",")){
                                                
                                                       setemaildestination([...emaildestination,{customOption: true, label: t.replace(',',""), id: "new-id-"+(emaildestination.length+1)}])
                                                       const instance = ref.current
    instance.clear()
    instance.focus()
                                                  
                                                    }}
                                                    }
                                                    
                                                    onChange={(e) => setemaildestination(e)}
                                                    selected={emaildestination}
                                                    newSelectionPrefix="Ajouter email: "
                                                    options={[]}
                                                    placeholder="Destinataire(s)"
                                                />
                                    {/* <Input value={destinataire} onChange={(e)=> setDestinataire(e.target.value)} className="form-control" type="email"/> */}
                                  </FormGroup>
                                  <FormGroup>
                                    <Label >{Subject}</Label>
                                    <Input className="form-control" value={object} onChange={(e)=> setobjet(e.target.value)} type="text"/>
                                  </FormGroup>
                                  <FormGroup className="mb-0">
                                    <Label className="text-muted">{Messages}</Label>
                                    {statebareupload ? 
                                                    <Progress animated color="secondary" value={stateupload} /> : ""}
                                    <input
        style={{ display: "none" }}
        // accept=".zip,.rar"
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
      />
                                    <ul>
                                   
                                                    <li><a style={{position : 'relative',zIndex : 2,
                                                  padding: '6px 6px 1px 6px',
                                                  textAlign: 'center',
                                                  height: '39px',
                                                  border: '1px solid var(--ck-color-toolbar-border)',
                                                  borderLeftWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderBlockStartWidth: '1px',
                                                  color : 'black'
                                                  
                                                  }} className=' ml-1 pull-right text-right' ><Link2 onClick={() => onButtonClick()} /></a></li>
                                              </ul>
                                    <CKEditor
                             
                    editor={ ClassicEditor }
                    data={message}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "ckfinder",
                        "|",
                        "imageTextAlternative",
                        "imageUpload",
                        "imageStyle:full",
                        "imageStyle:side",
                        "|",
                        "mediaEmbed",
                        "insertTable",
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        "|",
                        "undo",
                        "redo"
                      ]
                      ,
                    ckfinder: {
                      // Open the file manager in the pop-up window.
                      openerMethod: 'popup'
                  }
                    }
                  }
                    onReady={editor => {
                      
                      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                        return new MyUploadAdapter(loader);
                      };
                    }}
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                    
                    //     console.log(data)
                    //     console.log(editor)
                   setMessage(data);
                    } }
                    onBlur={ ( event, editor ) => {
               
                    } }
                    onFocus={ ( event, editor ) => {
                      
                    } }
                />                                      
                                  </FormGroup>
                                </Form>
                              </div>
                            </div>
                          </div>
                          <div className={`tab-pane fade ${compose !== true ? 'active show' : ''}`}>
                              <div className="email-content">
                                  <div className="email-top">
                                      <Row>
                                          <Col md="6 xl-100" sm="12">
                                              <Media>
                                                
                                                  <Media body>
                                                      <h6>{selectedEmail?.value?.obj?.from[0].personal} <small><span className="digits">{selectedEmail?.value?.obj?.date}</span> </small></h6>
                                                      <p>{selectedEmail?.value?.obj?.toaddress}</p>
                                                  </Media>
                                              </Media>
                                          </Col>
                                          {/* <Col md="6" sm="12">
                                              <div className="float-right d-flex" onClick={() => addFavourite(singleMailRecord)}>
                                                  <p className="user-emailid">{"Lormlpsa"}<span className="digits">{"23"}</span>{"@company.com"}</p>
                                                  <i className={`fa fa-star-o f-18 mt-1 ${singleMailRecord.favourite ? 'starred' : ''} `} ></i>
                                              </div>
                                          </Col> */}
                                      </Row>
                                  </div>
                                  <div className="email-wrapper">
                                  {htmr(contenu.toString())}
                                
                            
                                  <hr />
                                      <div className="d-inline-block">
                                          <h6 className="text-muted"><i className="icofont icofont-clip"></i> {ATTACHMENTS}</h6><a className="text-muted text-right right-download" ><i className="fa fa-long-arrow-down mr-2"></i></a>
                                          <div className="clearfix"></div>
                                      </div>
                                      <div className="attachment">
                                          <ul >
                                          {attachement.map((attat,i) => (
                                   <li ><a  href={`https://gocloud.groupe-fbh.com/test/${attat}`}  target="_blank">{attat.replace("joint/", "")} </a></li>
                                   ))}
                                              
                                          </ul>
                                      </div> 
                                      <hr />
                                      <div className="action-wrapper">
                                          <ul className="actions">
                                              <li><a className="text-muted"  onClick={() => {
                                               
                                                sendMail(null)

                                                setemaildestination([...emaildestination,{customOption: true, label: selectedEmail?.value?.obj?.from[0].mailbox + '@'+ selectedEmail?.value?.obj?.from[0].host, id: "new-id-"+(emaildestination.length+1)}])
                                                setMessage(contenu.toString())
                                                }}><i className="fa fa-reply mr-2"></i>{Reply}</a></li>

                                              <li onClick={() => {
                                               
                                               sendMail(null)
                                             
                                               setMessage(contenu.toString())
                                               }} ><a className="text-muted" ><i className="fa fa-share mr-2"></i></a>{Forward}</li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>
              <Modal isOpen={modal} toggle={toggleAddSetting} size="lg">
                            <ModalHeader toggle={toggleAddSetting}>{MailSetting}</ModalHeader>
                            <ModalBody>
                              <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(AddSetting)}>
                                <div className="form-row">
                                 
                                  <FormGroup className="col-md-12">
                                    <Label>{EmailAddress}</Label>
                                    <Row>
                                    <Col sm="12">
                                    <FormGroup>
                        
                        <InputGroup> 
                          <Input  onChange={(e) => setEmail( e.target.value)} className="form-control" type="text" value={email} placeholder="email" />
                          <Input style={{borderLeftWidth: 0}} onChange={(e) => {
         
                            setselectedDomaine(e.target.value)}} className="form-control" type="text" value={selectedDomaine} placeholder="domaine" />
                        </InputGroup>
                      </FormGroup>
                                      
                                      </Col>
                                    
                                    </Row>
                                  </FormGroup>
                                  <div style={{ overflow: 'auto',height: 90,
  whiteSpace: 'nowrap'}}>

                                  <Button color="primary"  onClick={()=> { setselectedautre(false); setselectedDomaine("@gmail.com"); setimap("imap.gmail.com:993/imap/ssl")}} outline={selectedDomaine === "@gmail.com" ? false :  true} className="btn-air-primary" >@gmail.com</Button>
                                  <Button color="primary" onClick={()=> {setselectedautre(false);  setselectedDomaine("@yahoo.com"); setimap("imap.mail.yahoo.com:993/imap/ssl")}} outline={selectedDomaine === "@yahoo.com" ? false :  true} className="btn-air-primary" >@yahoo.com</Button>
                                 <Button color="primary" onClick={()=> {setselectedautre(false) ; setselectedDomaine("@outlouk.com"); setimap("imap-mail.outlook.com:993/imap/ssl")}} outline={selectedDomaine === "@outlouk.com" ? false :  true} className="btn-air-primary" >@outlouk.com</Button>
                                  
                                  <Button color="primary" onClick={()=> {setselectedautre(false) ; setselectedDomaine("@hotmail.com"); setimap("imap-mail.outlook.com:993/imap/ssl")}} outline={selectedDomaine === "@hotmail.com" ? false :  true} className="btn-air-primary" >@hotmail.com</Button>
                                  {/* 
                                   <Button color="primary" onClick={()=> {setselectedautre(false) ; setselectedDomaine("@hotmail.fr")}} outline={selectedDomaine === "@hotmail.fr" ? false :  true} className="btn-pill btn-air-primary" >@hotmail.fr</Button>
                                   <Button color="primary" onClick={()=> {setselectedautre(false);  setselectedDomaine("@yahoo.fr")}} outline={selectedDomaine === "@yahoo.fr" ? false :  true} className="btn-pill btn-air-primary" >@yahoo.fr</Button>
                                  <Button color="primary" onClick={()=> {setselectedautre(false);  setselectedDomaine("@live.com")}} outline={selectedDomaine === "@live.com" ? false :  true} className="btn-pill btn-air-primary" >@live.com</Button>
                                  <Button color="primary" onClick={()=> {setselectedautre(false) ; setselectedDomaine("@msn.com")}} outline={selectedDomaine === "@msn.com" ? false :  true} className="btn-pill btn-air-primary" >@msn.com</Button>
                                 
                                  <Button color="primary" onClick={()=> {setselectedautre(false) ; setselectedDomaine("@comcast.com")}} outline={selectedDomaine === "@comcast.com" ? false :  true} className="btn-pill btn-air-primary" >@comcast.net</Button>
                                  <Button color="primary" onClick={()=> {setselectedautre(false) ; setselectedDomaine("@aol.com")}} outline={selectedDomaine === "@aol.com" ? false :  true} className="btn-pill btn-air-primary" >@aol.com</Button>
                                  <Button color="primary" onClick={()=> {setselectedautre(false) ; setselectedDomaine("@att.com")}} outline={selectedDomaine === "@att.com" ? false :  true} className="btn-pill btn-air-primary" >@att.net</Button>
                                   */}
                                  <Button color="primary" onClick={()=> {
                            
                                    setselectedautre(true)
                                    handleselectedDomaine() }} outline={!selectedautre} className="btn-air-primary" >Autre</Button>

                                 
                                  </div>
                                  
                                     {selectedDomaine === "@gmail.com" ? <Link to={"https://accounts.google.com/b/0/DisplayUnlockCaptcha"} /> : <Link to={"https://accounts.google.com/b/0/DisplayUnlockCaptcha"} />} 
                                  <FormGroup className="col-md-12">
                                    <Label>{Password}</Label>
                                    <Input className="form-control" name="password" type="password" innerRef={register({ required: true })} />
                                    <span style={{ color: "red" }}>{errors.password && 'password'}</span>
                                  </FormGroup>
                              
                           {(selectedautre === true) ? 
                                  <FormGroup className="col-md-12">
                                    <Label>{Imap}</Label>
                                    <Input className="form-control"  onChange={(e) => setimap(e.target.value)} name="imap" type="text" innerRef={register({ required: selectedautre })} />
                                    <span style={{ color: "red" }}>{errors.imap && 'imap'}</span>
                                  </FormGroup> : null}
                                  <div style={{ overflow: 'auto',width: '100%',
  whiteSpace: 'nowrap'}}>
{selectedDomaine === "@hotmail.com" || selectedDomaine === "@outlouk.com" ?
<ul>
<li><p>1- Accédez à la page des <a href="https://go.microsoft.com/fwlink/?linkid=842325" target='_blank'>notions de base en matière de sécurité</a> et connectez-vous à l’aide de vos adresse de messagerie et mot de passe Outlook.com.</p></li>

<li><p>2- Sélectionnez autres options de sécurité.</p></li>

<li><p>3- Sous Mots de passe d’application, sélectionnez Créer un mot de passe d’application. Un nouveau mot de passe d’application est généré et s’affiche à l’écran.</p></li>

<li><p>4- Entrez le mot de passe d’application à l’endroit où vous entrez votre mot de passe normal.</p></li>
</ul> : ""}
{selectedDomaine === "@gmail.com"  ?
<ul>
<li><p>1- Ouvrez Gmail dans un navigateur Web. <a href="https://www.gmail.com/" target='_blank'>Ouvrez gmail</a> et connectez-vous à l’aide de vos adresse de messagerie et mot de passe gmail.com.</p></li>

<li><p>2- Sélectionnez l'icône équipement dans le coin supérieur droit.</p></li>
<li><p><img height={200} src='https://www.lifewire.com/thmb/uhbXNa-iYcBLZ9wcPFClQ4Lo8qw=/1851x698/filters:no_upscale():max_bytes(150000):strip_icc()/001-how-to-delete-with-a-swift-keyboard-shortcut-in-gmail-1172079-d95f5d87fec34a62bba7f2c1fe204eda.jpg'/></p></li>
<li><p>3- Choisir Réglages.</p></li>
<li><p><img height={200} src='https://www.lifewire.com/thmb/YS_ys2ECpaoDM_H7ISgWXn6f4rc=/1851x698/filters:no_upscale():max_bytes(150000):strip_icc()/002-how-to-delete-with-a-swift-keyboard-shortcut-in-gmail-1172079-c5d28b8872d341b6894b933566647158.jpg'/></p></li>
<li><p>4- Sélectionnez la languette Transfert et POP/IMAP.</p></li>
<li><p><img height={200} src='https://www.lifewire.com/thmb/P0QZk_rGaVwTHtrpq_2cL7RwXp4=/1366x768/filters:no_upscale():max_bytes(150000):strip_icc()/001-how-to-access-a-gmail-account-with-any-email-client-via-pop-4103715-a988658a0c8948eb819d4d7f79f8be8a.jpg'/></p></li>
<li><p>5- Dans la section IMAP accès, sélectionnez Permission IMAP.</p></li>
<li><p><img height={200} src='https://www.lifewire.com/thmb/bPamtktV0Kytt9TWbwTSfRVl3w8=/1167x742/filters:no_upscale():max_bytes(150000):strip_icc()/001-gmail-access-thunderbird-1173150-80fdab9339ec4fb9a6c58965db3b8b7b.jpg'/></p></li>
<li><p>6- Laissez les autres paramètres sur les sélections par défaut.</p></li>

<li><p>7- Choisir Enregistrer Modifications.</p></li>
<li><p>8- Activer Accès moins sécurisé des applications.<a href="https://myaccount.google.com/security?gar=1" >Activer l'Accès</a></p></li>
<li><p><img height={200} src={email4}/></p></li>
<li><p>9- <a  href={"https://accounts.google.com/b/0/DisplayUnlockCaptcha"} target='_blank' > cliquer ici pour configurer gmail</a>.</p></li>

</ul> : ""}


                                 </div>
                                </div>
                                <div className='text-right'>
                               
                                <Button color="secondary" className="mr-1" onClick={toggleAddSetting}>{Cancel}</Button>
                                <Button color="primary" >{"Ajouter"}</Button>
                                </div>
                              </Form>
                            </ModalBody>
                          </Modal>
                          <Modal isOpen={addUserModal} toggle={addUserToggle} size="lg">
                            <ModalHeader toggle={addUserToggle}>Liste groupe</ModalHeader>
                            <ModalBody>
                            <div className="media-body text-right">
                <Form className="d-inline-flex">

                 
                 
                  <Link onClick={() => addUserbuttonToggle()} className="btn btn-primary" > <UserPlus/></Link>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
              
              </div>
                              <Form onSubmit={(e) => {e.preventDefault();addNote() }} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                {addUser ? <>
                               <FormGroup className="col-md-12">
                                    <Label>{"Nom Groupe"}</Label>
                                    <Input className="form-control" value={groupeName} onChange={(e) => setgroupeName(e.target.value)}
                                     name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup >  
                                    <FormGroup className="col-md-12">
                                    <Label className="col-form-Label pt-0">{To}</Label>

                                    <Typeahead
                               
                                    ref={ref2}
                                                    id="custom-typeahead"
                                                    allowNew
                                                    multiple

                                                    onInputChange={(t,e) => {
                                                      if (t.includes(",")){
                                                
                                                       setemailgroup([...emaildestination,{customOption: true, label: t.replace(',',""), id: "new-id-"+(emaildestination.length+1)}])
                                                       const instance = ref.current
    instance.clear()
    instance.focus()
                                                  
                                                    }}
                                                    }
                                                    
                                                    onChange={(e) => setemailgroup(e)}
                                                    selected={emailgroup}
                                                    newSelectionPrefix="Ajouter email: "
                                                    options={[]}
                                                    placeholder="Ecrire email..."
                                                />
                                    {/* <Input value={destinataire} onChange={(e)=> setDestinataire(e.target.value)} className="form-control" type="email"/> */}
                                  </FormGroup>
                                  <FormGroup className="col-md-12 text-right">
                                   
                                  <Button onClick={() => addNote()} color="primary" className="mr-1">{"Ajouter"}</Button>

                                    {/* <Input value={destinataire} onChange={(e)=> setDestinataire(e.target.value)} className="form-control" type="email"/> */}
                                  </FormGroup> </>: ""
                                }
                                  <FormGroup className="col-md-12">
                                    <Label>{"Rechercher groupe"}</Label>
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                  { mailgroup.length > 0 ?
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                        
                                {mailgroup.filter(x => (x.groupeName.includes(searchvalue) == true)).map((item, i) => {
                                    return (
                                   
                                      <li 
                                       className="clearfix">
                                      <Row>
                                       
                                      <Col sm="10" lg="10">
                                            <div>
                                              <div className="name">{item.groupeName}</div>
                                              <div  className="status"> {item.emailListeString}</div>
                                       </div>
                                       </Col>
                                       
                                       <Col style={{paddingRight : 0}} sm="1" lg="1">
                                       <div className="checkbox checkbox-primary text-right">
                                  <Input  id={ i} onClick={() => addToSelectedListe(item.groupe_id,item)}
                                   type="checkbox" checked={selectedUser.includes(item.groupe_id) == true} // name={item.email} value={item.email} 
                                   />
                                  <Label for={i}></Label>
                                </div>
                                </Col>
                                <Col style={{paddingLeft : 0}} sm="1" lg="1">
                                <div  className="primary mt-2 mr-2">  
                                <Trash onClick={() => deleteNote(item.groupe_id)} color='#f73164' className='primary' size={18} />
                               
                                
                            </div>
                                </Col>
                                </Row>
                                     </li>
                                )
                              
                                })}
                         
                            </ul>
                            </div>
                            :  
                            ""
                            }
                                     </FormGroup>
                                </div>
                              
                              </Form>
                              <div style={{paddingRight : 0}} className="col-md-12 text-right">
                                   
                                   <Button onClick={() => setDestinataireEmail()} color="primary" >{"Valider"}</Button>
 
                                     {/* <Input value={destinataire} onChange={(e)=> setDestinataire(e.target.value)} className="form-control" type="email"/> */}
                                   </div> 
                            </ModalBody>
                       
                          </Modal>
            </div>
          </Container>
          </Fragment>
    );
}
export default Email;