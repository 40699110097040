import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import Dropzone from "react-dropzone-uploader";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { addNewProject } from "../../../redux/project-app/action";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  ProjectTitle,
  ClientName,
  ProjectRate,
  ProjectStatus,
  ProgressLevel,
  ProjectSize,
  Small,
  Medium,
  Big,
  StartingDate,
  EndingDate,
  EnterSomeDetails,
  UploadProjectFile,
  Add,
  Cancel,
  Done,
  Doing, Close, SaveChanges, Save, Files
} from "../../../constant";
import {
  WATCH_FRANCHISE_LIST,
  WATCH_SOUS_TACHE_LIST,
  WATCH_SUBFRANCHISE_LIST,
  WATCH_TACHE_LIST,
  WATCH_USER_LIST,
} from "../../../redux/actionTypes";
import { firestorage } from "../../../data/config";
import { toast } from "react-toastify";
import moment from "moment";
import SweetAlert from 'sweetalert2'
import { updateProject, updateSousTache, updateTache } from "../../../redux/project/action";

const UpdateSoustacheModal = (props) => {
 const history = useHistory()
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [marque, setMarque] = useState("");
  const [franchise, setFranchise] = useState("");
  const [random, setrandom] = useState(0)
  const [myfilee, setMyFile] = useState([])
  const [priority, setPriority] = useState("");
  const franchises = useSelector((content) => content.Franchiseapp.franchises);

  const subfranchises = useSelector(
    (content) => content.SubFranchiseapp.subfranchises
  );
  console.log(franchises);
  useEffect(() => {
  
  }, []);

  const handleStartDate = (date) => {
    setstartDate(date);
  };

  const handleEndDate = (date) => {
    setendDate(date);
  };

  const getUploadParams = ({ meta, file }) => {

       
    
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/soustaches/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/soustaches`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;

      
        myfils.push({
            url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          let rescop = props.selectedTache.files
          rescop.push({
            url : fireBaseUrl,
                  name: meta.name,
                  size: meta.size,
                  type: meta.type,
                
                  icon:"fa fa-file-text-o txt-info"
                })
          console.log(myfils)
          props.selectedTache.files = rescop
          setrandom(Math.random())
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
if (status === "removed"){
let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
setMyFile(myfileeCopy)
}
  }

  const users = useSelector(content => content.Userapp.users);
  useEffect(() => {

  },[])
      const [selectedUser, setSelectedUser] = useState({})
      const [scrollingmodal, setscrollingModal] = useState(false);
      const Scrollmodaltoggle = () => setscrollingModal(!scrollingmodal);

  const AddProject = (data) => {

    if (data !== "" ) {
     

    
   
      data.updatedAt = moment(new Date()).format('yyyy-MM-DD HH:mm')
      data.files = myfilee.concat(props.selectedTache.files)   
      if (startDate !== null)
      data.startDate = startDate
      if (endDate !== null)
      data.endDate =   endDate
      data.priority = priority.length > 0 ? priority :  props.selectedTache.priority
    
    
     
       updateSousTache(data,props.selectedTache.projetId,props.selectedTache.idtache,props.selectedTache.id)
       dispatch({type : WATCH_SOUS_TACHE_LIST ,id : props.selectedTache.projetId,idtache : props.selectedTache.idtache})
       SweetAlert.fire(
         'Modifie!',
         'Votre sous tâche a été modifiée',
         'success'
       )
    
      history.goBack()
       props.UpdateToogle()
      
    
    
    } else {
      errors.showMessages();
    }
  };
  const selectFranchise = (id) => {
    dispatch({ type: WATCH_SUBFRANCHISE_LIST });
  };
  const productColumns = [
    {
        name:"Image",
  
        cell: row => {
          return (
            <img src={row.userImage} style={{ width: 50, height: 50 }} alt="" />
          )
        },
        sortable: true,
        center:true,
      },
      {
        name:"Nom responsable",
        selector: 'firstName',
        sortable: true,
        center:true,
      },
      {
        name:"Prenom responsable",
        selector: 'lastName',
        sortable: true,
        center:true,
      },
      {
        name:" Numero télephone",
        selector: 'phoneNumber',
        sortable: true,
        center:true,
      },
      {
        name:"region",
        selector: 'region',
        sortable: true,
        center:true,
      },
      {
        name:"Action",
        selector: 'action',
        sortable: true,
        center:true,
      },
];

const conditionalRowStyles = [
  {
    when: row => row.id === selectedUser.id,
    style: {
      backgroundColor: 'red',
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }
];
const removefile = (name) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
     let rescop = props.selectedTache.files
     props.selectedTache.files = rescop.filter(res => res.name !== name)
     setrandom(Math.random())
      SweetAlert.fire(
        'Supprimé!',
        'Votre fichier a été supprimée',
        'success'
      )
     
   
     
    }
    else {
    
    }
  })
}
const filelist2 = props.selectedTache?.files?.map((data,i)=>{
  return(
    <li    onDoubleClick={() => {
       
      
        }} className="file-box" key={i}>


      <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
      <div className="file-bottom">
        <h6>{data.name} <i className="fa fa-trash" onClick={() => removefile(data.name)}></i></h6>

      </div>
   
           
    </li>
  )
})
  return (
    <Modal isOpen={props.updateModal} toggle={props.UpdateToogle} size="lg">
    <ModalHeader toggle={props.UpdateToogle}>{"Modifier Sous Tâche"}</ModalHeader>
    <ModalBody>
    <Fragment>
     
    <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(AddProject)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Nom tâche"}</Label>
                        <Input
                          defaultValue={props.selectedTache.title}
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="Nom Tâche *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Nom tâche est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{StartingDate}</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          selected={startDate === null ?  props.selectedTache?.startDate?.toDate() : startDate}
                     
                          onChange={handleStartDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{EndingDate}</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          selected={endDate === null ?  props.selectedTache?.endDate?.toDate() : endDate}
                          endDate={endDate}
                          onChange={handleEndDate}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col sm="6">
                          <FormGroup>
                            <Label>{"Responsable tâche"}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue={props.selectedTache?.responsableName}
                          name="responsableName"
                          placeholder="Nom Responsable *"
                          innerRef={register({ required: true })}
                        />
                          <span style={{ color: "red" }}>
                          {errors.responsableName && "Nom responsable est obligatoire"}
                        </span>
                           </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Priorité"}</Label>
                            <select onChange={(e) => setPriority(e.target.value)} className="form-control digits">
                              <option selected={props.selectedTache?.priority === "Faible"}>{"Faible"}</option>
                              <option selected={props.selectedTache?.priority === "moyenne"}>{"moyenne"}</option>
                              <option selected={props.selectedTache?.priority === "Urgent"}>{"Urgent"}</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Détails tâche"}</Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          name="description"
                          defaultValue={props.selectedTache.description}
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Détails est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <h6 className="mt-4">{Files}</h6>
                      {filelist2?.length > 0 ?
              <ul className="files">
                  { filelist2 }
       
              </ul> : ""}
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                        onClick={props.UpdateToogle}
                        >
                          <Button color="secondary">{Cancel}</Button>
                        </Link>
                        <Button color="primary" className="mr-3">
                          {Add}
                        </Button>
                        
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
   
                    
                        </ModalBody>
                      </Modal>
  );
};

export default UpdateSoustacheModal;
