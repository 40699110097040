import React, { Fragment, useState,useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Dropdown, ButtonGroup, DropdownMenu, DropdownItem, Media } from 'reactstrap'
import { Home, Folder, Clock, Star, AlertCircle, Trash2, Database, Grid, Upload, PlusSquare, Eye, User, Share, Download, Trash  } from 'react-feather';
import { toast } from 'react-toastify'
import errorImg from '../../../assets/images/search-not-found.png';
import {BasicModal,Simple,StaticExample,NewMessage,SendMessage,ModalTitle,Close,SaveChanges,VerticallyCentered,TooltipsAndPopovers,UsingTheGrid,SizesModal,LargeModal,SmallModal,ScrollingLongContent,VaryingModalContent,Hometxt,All,Recent,Starred,Recovery,Deleteds,PricingPlan,TrialVersion,FREE,Selected,Premium,ContactUs,AllFiles,RecentlyOpenedFiles,Folders,EndlessAdmin,Files,Storage,AddNew, SharedWithMe, Favourites, AddNewFolder, NewFolder, AddFolder, MultipleImageVideoAudioUpload, AddFile, Newfiles, Partager, Cancel, Title, MYDRIVE, DriveEnseigne} from '../../../constant'
import axios from "axios";
import { db, firestorage, urlbackend } from '../../../data/config';
import { useDispatch, useSelector } from 'react-redux';
import { GET_FILE_LIST_LOADING, GET_SUB_FOLDER_LIST_LOADING, WATCH_ALL_USER, WATCH_FILE_LIST, WATCH_FOLDER_LIST, WATCH_PROVIDER_LIST, WATCH_SUB_FOLDER_LIST, WATCH_USER_LIST } from '../../../redux/actionTypes';
import moment from 'moment';
import { addFavorisFolders, newfolder, newsubfolder, removefolder, removefoldernotracine, removefromsharefolder, removesubfolder, shareFolders, updatefolder, updateTreefolder } from '../../../redux/foldermanager/action';
import {BrowserRouter,Switch,Route,Redirect, Link,useHistory} from 'react-router-dom'
import AppfileFragment from './app'
import Test from './test';
import MyDrive from './myDrive';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router';
import SweetAlert from 'sweetalert2'
import { addFavorisFiles, newFile, newSubFile, removeFile, removefromsharefile, shareFiles, updateFile, updateTreefile } from '../../../redux/filemanager/action';
import Dropzone from 'react-dropzone-uploader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ModalApercuFile from './modalApercuFile';
const liste = ["1","2","3","4","5","6","7"]
const DetailsFilemanagerByFranchise = (props) => {
  const dispatch = useDispatch();
  const {idfolder} = useParams();
  const history = useHistory()
  console.log(idfolder)
  const myfile = useSelector(content => content.Folderapp.subfolders);
  const users = useSelector(content => content.Userapp.users);
  const myfiles = useSelector(content => content.Fileapp.subfiles);
  const [selectedFile, setSelectedFile] = useState(null)   // Initially, no file is selected  
  const [searchTerm, setSearchTerm] = useState("");
  const [folderName, setFolderName] = useState("");
  const [myfilee, setMyFile] = useState([])
  const loading = useSelector(content => content.Fileapp.loading);
  const loadingfolder = useSelector(content => content.Folderapp.loading);
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [AddFilesState, setAddFilesState] = useState(false);
  const [selectedUser, setselectedUser] = useState([]);
  const [selectedUsertype, setselectedUserType] = useState([]);
  const [random, setrandom] = useState(0);
  const [selectedid, setselectedid] = useState("")
  const [selectedidfolder, setselectedidfolder] = useState("")
  const [searchvalue, setsearchvalue] = useState("")
  const [UserListe, setUserListe] = useState([]);
  const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone);
  const AddFilesStatetoggle = () => setAddFilesState(!AddFilesState);
  const addToggle = () => { setaddModal(!addModal) }
  const shareToggle = () => { setshareModal(!shareModal) }
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const [VaryingContentoneUpdate, setVaryingContentoneUpdate] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const VaryingContentoneUpdatetoggle = () => setVaryingContentoneUpdate(!VaryingContentoneUpdate);
  const [addModal, setaddModal] = useState(false)
  const [shareModal, setshareModal] = useState(false)
  const [selectedDataDrag, setselectedDataDrag] = useState({})
  const [idUser, setidUser] = useState("");
  const [typeUser, settypeUser] = useState("");
  const [selectedImage, setselectedImage] = useState({})
  const [choicefileorfolder, setchoicefileorfolder] = useState("")

  const fournisseurs = useSelector(content => content.Fournisseurapp.fournisseurs);
  const [clientX, setclientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [windowHeight, setwindowHeight] = useState(0);
  const [windowWidth, setwindowWidth] = useState(0);
  const [optionModal, setoptionModal] = useState(false)
  const optionToggle = () => { setoptionModal(!optionModal) } 
  const [optionFolderModal, setoptionFolderModal] = useState(false)
  const optionFolderToggle = () => { setoptionFolderModal(!optionFolderModal) } 
  const [imprimeable, setImprimeable] = useState(false);
  const [telechargeable, setTelechargeable] = useState(false);
  const [fileName, setFileName] = useState("");
  const [RenameFileUpdate, setRenameFileUpdate] = useState(false);
  const [sortState , setsortState] = useState("trieraz")
  const RenameFileUpdatetoggle = () => setRenameFileUpdate(!RenameFileUpdate);
  // useEffect(() => {
  //   dispatch({type: WATCH_BOOKMARK_LIST})
  //    axios.get(`${process.env.PUBLIC_URL}/api/files.json`).then((response) => {
  //     setMyFile(response.data);
  //    })
  // },[])
  const handleUpdateFile = () => {
    let folder = {}
    folder.fileName = fileName
 
   
    
    
    folder.updateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  
    
  
    RenameFileUpdatetoggle()
    dispatch(updateFile(selectedid ,folder,selectedImage.idfolder))
    
    }
    const handleUpdateFolder = () => {
      let folder = {}
      folder.folderName = folderName
   
     
      
      
      folder.updateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
    
      
    
      VaryingContentoneUpdatetoggle()
      dispatch(updatefolder(selectedid ,folder))
      
      }
  useEffect(() => {
    dispatch({type: GET_SUB_FOLDER_LIST_LOADING})
    dispatch({type: GET_FILE_LIST_LOADING})
    setwindowWidth(window.innerWidth)
    setwindowHeight(window.innerHeight)
    window.addEventListener('resize', updateWindowDimensions);
    setidUser(localStorage.getItem("@intranetidUser"))
    settypeUser(localStorage.getItem("@intranetTypeUser"))
    dispatch({type: WATCH_PROVIDER_LIST})
    dispatch({type: WATCH_ALL_USER})
    dispatch({type: WATCH_SUB_FOLDER_LIST})
    dispatch({type: WATCH_FILE_LIST})
  },[dispatch])
  const  updateWindowDimensions = () => {
    setwindowWidth(window.innerWidth)
    setwindowHeight(window.innerHeight)
      }
      const getUploadParams = ({ meta, file }) => {

        let folderneme = localStorage.getItem("folderName");
        const formData =  new FormData();
      formData.append('image', file);
      formData.append('foldername', folderneme.trim());
   
    

  
          
          return { url: 'https://gocloud.groupe-fbh.com/intranet/api/files/uploadmedia.php', body : formData  } }
        const handleChangeStatus = ({xhr, meta, file }, status) => {
    
          if(status === "done" ){
              if (xhr.readyState == 4) {
               
                const result = JSON.parse(xhr.response);
            
                let folder = {}
      folder.files_name = meta.name
      folder.prop_id = localStorage.getItem("@intranetidUser")
      folder.prop_type = typeUser
    
      folder.file_url = result.url
      folder.folder_id = localStorage.getItem("idfolder");
      folder.imprimeable = imprimeable ? 1 : 0
      folder.telechargeable = telechargeable ? 1 : 0
      folder.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
      folder.opened_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
      folder.file_state = 1
      folder.download_state = 1
      folder.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
      folder.download_url = result.url
      folder.icon = meta.type.includes("image") === true ?  "fa fa-file-image-o txt-primary" : meta.type.includes("pdf") === true ? "fa fa-file-pdf-o txt-primary" : meta.type.includes("xsel") === true ? "fa fa-file-xsel-o txt-primary" : meta.type.includes("officedocument.") === true ? "fa fa-file-word-o txt-primary" :  "fa fa-file-o txt-primary"
      folder.filetype = meta.type
      folder.file_state_deleted = 0
      folder.file_size = meta.size
      AddFilesStatetoggle()
   
     // dispatch(newFile(folder))
      dispatch(newSubFile(folder))
      setTimeout(() => {
        dispatch({type: WATCH_FILE_LIST, id : localStorage.getItem("idfolder")})
      }, 2000);
     // dispatch({type: WATCH_FILES_LIST})
               // setMyFile(myfils)
                toast.success("Fichier Téléchargé avec succés")
              }
            }
    else if (status === "removed"){
      let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
      setMyFile(myfileeCopy)
    }
        }
  const handleSubmit1 = (files, allFiles) => {
 
   myfilee.forEach(element => {
     handleAddFile(element)
   });
      allFiles.forEach(f => f.remove())
      toast.success("Fichiers Télécharger avec succés");
  }
  const handleSubmit = () => {
 
    myfilee.forEach(element => {
      handleAddFile(element)
    });
      // allFiles.forEach(f => f.remove())
       toast.success("Fichiers Télécharger avec succés");
   }
const handleAddFolder = () => {
let folder = {}
folder.folder_name = folderName
folder.prop_id = localStorage.getItem("@intranetidUser")
folder.prop_type = typeUser
folder.folder_parent_id = localStorage.getItem("idfolder")

folder.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
folder.folder_state = "0"
folder.download_state = "1"
folder.folder_state_deleted = "0"
folder.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
folder.download_url = ""
folder.icon = "fa fa-file-image-o txt-primary"
folder.files_number = "0"

VaryingContentonetoggle()
dispatch(newsubfolder(folder))
setTimeout(() => {

  dispatch({type: WATCH_SUB_FOLDER_LIST, id : localStorage.getItem("idfolder")})
}, 2000);
//dispatch({type: WATCH_SUB_FOLDER_LIST})
setFolderName("")
}
const handleAddFile = (avatar) => {
  let folder = {}
  folder.fileName = avatar.name
  folder.proprietaireId = idUser
  folder.proprietaireType = typeUser
  folder.userListe = []
  folder.userListeType = []
  folder.fileImage = avatar.url
  folder.stateFolderDeleted = false
  folder.imprimeable = imprimeable
  folder.telechargeable = telechargeable
  folder.idfolder = localStorage.getItem("idfolder");
  folder.creationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.openedDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.fileState = true
  folder.downloadState = true
  folder.updateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.downloadUrl = ""
  folder.icon = avatar.type.includes("image") === true ?  "fa fa-file-image-o txt-primary" : avatar.type.includes("pdf") === true ? "fa fa-file-pdf-o txt-primary" : avatar.type.includes("xsel") === true ? "fa fa-file-xsel-o txt-primary" : avatar.type.includes("officedocument.") === true ? "fa fa-file-word-o txt-primary" :  "fa fa-file-o txt-primary"
  folder.fileType = avatar.type
  folder.fileSize = avatar.size
  AddFilesStatetoggle()
  dispatch(newFile(folder))
  dispatch({type: WATCH_SUB_FOLDER_LIST})
  }
  const [showApercu, setshowApercu] = useState(false);
  const showApercuToggle = () => { setshowApercu(!showApercu) }
  const allowDrop = (ev) => {
    ev.preventDefault();
  }
  
  const  drag = (ev, name) => {
    setchoicefileorfolder('folder')
    ev.dataTransfer.setData("text", name);
    console.log(name)
  }
  
  const drop = (ev, name) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    console.log(name)
    if(choicefileorfolder === 'folder'){
    dispatch(updateTreefolder(name, data))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
  }
  else if(choicefileorfolder === 'file') {
    dispatch(updateTreefile(name, data, selectedDataDrag))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
  }
    //ev.target.appendChild(document.getElementById(data));
  }

  const allowDropfile = (ev) => {
    ev.preventDefault();
  }
  const  dragfile = (ev, name, data) => {
    setchoicefileorfolder('file')
    ev.dataTransfer.setData("text", name);
    setselectedDataDrag(data)
    console.log(name)
  }
  const dropfile = (ev, name) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    console.log(name)
    dispatch(updateTreefile(name, data, selectedDataDrag))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
    //ev.target.appendChild(document.getElementById(data));
  }

  const handleChange = event => {
    event.preventDefault();
    setSearchTerm(event.target.value)
  };
  const RemoveFolder  = (taskId) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        dispatch(removesubfolder(taskId));
        SweetAlert.fire(
          'Supprimé!',
          'Votre fichier a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  const RemoveFiles  = (Id) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
       dispatch(removeFile(Id));
        SweetAlert.fire(
          'Supprimé!',
          'Votre fichier a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  // eslint-disable-next-line
//   const filelist = myfiles.filter((data) => {
//     if(searchTerm == null)
//         return   data.stateFolderDeleted === false
//     else if(data.folderName.toLowerCase().includes(searchTerm.toLowerCase())){
//         return  data.stateFolderDeleted === false
//     }
//     }).map((data,i)=>{
//       return(
//         <li onDoubleClick={() => {
//           console.log(selectedImage)
// setselectedImage(data)
//             showApercuToggle()}} className="file-box" key={i}  className="file-box" key={i}>
//           <div className="file-top">{ data?.fileType?.includes("pdf")=== true || data?.fileType?.includes("image")=== true ? <img   width="200" height="100" src={data.fileImage} /> : data?.fileType?.includes("zip") === true ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-image-o txt-primary" ></i>}</div>
//           <div className="file-bottom">
//             <h6>{data.folderName} </h6>
//             <p className="mb-1">{"12"}</p>
//               {/* <p> <b>{"dernière ouverture"} : </b>{data.updateDate}</p> */}
//           </div>
//         </li>
//       )
//     })
const folderlisloading = liste.map((data,i)=>{
  return(
    <li className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
     

   <Link draggable  className="media">
   <Skeleton className="f-36 txt-warning"/>
      <div className="media-body ml-3">
        <h6 className="mb-0"><Skeleton /></h6>
        
        <p><b><Skeleton /> </b><Skeleton /></p>
      </div>
    </Link>
   
        
  </li>
  )
})

const filelisloading = liste.map(i => {
  return(
    <li className="file-box" key={i} style={{marginLeft : 5,marginBottom : 5}} >
      <div className="file-top"><Skeleton /></div>
      <div className="file-bottom">
        <h6><Skeleton /></h6>
        <p className="mb-1"><Skeleton /></p>
        <p> <b><Skeleton /> </b><Skeleton /></p>
      </div>
    </li>
  )
})
const filelist2 = myfiles.sort(function(x,y) {
  if(sortState === "trieraz")
  return x.files_name.toString().toLowerCase().localeCompare(y.files_name.toString().toLowerCase());
  else if(sortState === "trierza")
  return y.files_name.toString().toLowerCase().localeCompare(x.files_name.toString().toLowerCase());
  else if(sortState === "trierdate")
  return y.files_name.toString().localeCompare(x.files_name.toString());
}).filter((data) => {
  if(searchTerm == null)
      return data
  else return data.files_name.toLowerCase().includes(searchTerm.toLowerCase())
  }).map((data,i)=>{
    return(
      <li onContextMenu={e => {
     
        e.preventDefault();


if(e.button === 2){
setselectedUser(data.userListe)
setselectedUserType(data.userListeType || [])
setselectedid(data.files_id)
setselectedidfolder(data.folder_id)
setselectedImage(data)
optionToggle()
setclientX(e.clientX)
setClientY(e.clientY)


}}}   onDoubleClick={() => {
         
          setselectedImage(data)
          showApercuToggle()}} className="file-box" style={{marginLeft : 5,marginBottom : 5}} key={i}>


        <div   draggable="true" onDragStart={(event) => dragfile(event, data.files_id, data)} onDrop={(event) => dropfile(event, data.files_id)} onDragOver={(event) => allowDropfile(event)} className="file-top">{ data.filetype.includes("pdf")=== true || data.filetype.includes("image")=== true || data.filetype.includes("video")=== true ? <img   width="200" height="100" src={`${urlbackend}${data.file_url}`} /> : data.filetype.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
        <div className="file-bottom">
          <h6>{data.files_name.length > 20 ?  data.files_name.slice(0,17)+'...' : data.files_name}</h6>
          <p className="mb-1">{data.file_size} ko</p>
            {/* <p> <b>{"dernière ouverture"} : </b>{data.updateDate}</p> */}
        </div>
        <Dropdown key={i}   className="dropup" >
      
                  <DropdownMenu  className={dropdownOpen === true && data.files_id === selectedid ? "dropup-content show" : "dropup-content" }>
                      <DropdownItem onClick={() => RemoveFiles(data.files_id)}>Delete</DropdownItem>
                      <DropdownItem  onClick={() => {
                        setselectedid(data.files_id)
                    setselectedidfolder(data.folder_id)
                        VaryingContentoneUpdatetoggle()
                      }} >Update</DropdownItem>
                      <DropdownItem onClick={() => {
                                   setselectedUser(data.userListe)
                                   setselectedid(data.files_id)
                                   setselectedidfolder(data.folder_id)
                                   shareToggle()
                        
                        }}>Partager</DropdownItem>
                 </DropdownMenu>
                </Dropdown>
             
      </li>
    )
  })
  const filelistmanager = myfiles.sort(function(x,y) {
    if(sortState === "trieraz")
    return x.files_name.toString().toLowerCase().localeCompare(y.files_name.toString().toLowerCase());
    else if(sortState === "trierza")
    return y.files_name.toString().toLowerCase().localeCompare(x.files_name.toString().toLowerCase());
    else if(sortState === "trierdate")
    return y.files_name.toString().localeCompare(x.files_name.toString());
  }).filter((data) => {
    if(searchTerm == null)
        return data
    else return data.files_name.toLowerCase().includes(searchTerm.toLowerCase())
    
    }).map((data,i)=>{
      return(
        <li onContextMenu = {e => {
     
          e.preventDefault();
  

if(e.button === 2) {
  setselectedUser(data.userListe)
  setselectedUserType(data.userListeType || [])
  setselectedid(data.files_id)
  setselectedidfolder(data.folder_id)
  setselectedImage(data)
  optionToggle()
  setclientX(e.clientX)
  setClientY(e.clientY)


}}}  onDoubleClick={() => {
          console.log(selectedImage)
          setselectedImage(data)

          showApercuToggle()
          }} className="file-box" style={{marginLeft : 5,marginBottom : 5}} key={i}>

  
          <div  draggable="true" onDragStart={(event) => dragfile(event, data.files_id, data)} onDrop={(event) => dropfile(event, data.id)} onDragOver={(event) => allowDropfile(event)} className="file-top">{ data.filetype.includes("pdf")=== true || data.filetype.includes("image")=== true || data.filetype.includes("video")=== true ? <img   width="200" height="100" src={`${urlbackend}${data.file_url}`} /> : data.filetype.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-image-o txt-primary" ></i>}</div>
          <div className="file-bottom">
            <h6>{data.files_name.length > 20 ?  data.files_name.slice(0,17)+'...' : data.files_name} </h6>
            <p className="mb-1">{data.file_size} ko</p>
            <p> <b>{"dernière ouverture"} : </b>{data.update_date}</p>
          </div>
          <Dropdown key={i}   className="dropup" >
        
                    <DropdownMenu  className={dropdownOpen === true && data.files_id === selectedid ? "dropup-content show" : "dropup-content" }>
                        <DropdownItem onClick={() => RemoveFiles(data.files_id)}>Delete</DropdownItem>
                        <DropdownItem  onClick={() => {
                          setselectedid(data.files_id)
                      setselectedidfolder(data.folder_id)
                          VaryingContentoneUpdatetoggle()
                        }} >Update</DropdownItem>
                        <DropdownItem onClick={() => {
                                     setselectedUser(data.userListe)
                                     setselectedid(data.files_id)
                                     setselectedidfolder(data.folder_id)
                                     shareToggle()
                          
                          }}>Partager</DropdownItem>
                   </DropdownMenu>
                  </Dropdown>
               
        </li>
      )
    })
        const folderlist = myfile.sort(function(x,y) {
          if(sortState === "trieraz")
          return x.folder_name.toString().toLowerCase().localeCompare(y.folder_name.toString().toLowerCase());
          else if(sortState === "trierza")
          return y.folder_name.toString().toLowerCase().localeCompare(x.folder_name.toString().toLowerCase());
          else if(sortState === "trierdate")
          return y.folder_name.toString().localeCompare(x.folder_name.toString());
        }).filter((data) => {
          if(searchTerm == null)
              return  true
          else 
              return  data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())
          
          }).map((data,i)=>{
            return(
              <li onContextMenu={e => {
             
                e.preventDefault();
        
    
      if(e.button === 2){
        setselectedUser(data.userListe)
        setselectedUserType(data.userListeType || [])
        localStorage.setItem("idfolder", data.folder_id)
        localStorage.setItem("folderName", data.folder_name)
        setselectedid(data.folder_id)
        setselectedidfolder(data.folder_parent_id)
        setselectedImage(data)
        optionFolderToggle()
        setclientX(e.clientX)
        setClientY(e.clientY)
    
    
      }}} className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
                <div   draggable="true" onDragStart={(event) => drag(event, data.folder_id)} onDrop={(event) => drop(event, data.folder_id)} onDragOver={(event) => allowDrop(event)} className="media">
        
             <Link draggable onDoubleClick={() => {
                  localStorage.setItem("idfolder", data.folder_id)
                  localStorage.setItem("folderName", data.folder_name)
              
        dispatch({type: WATCH_SUB_FOLDER_LIST})
        dispatch({type: WATCH_FILE_LIST})
              }}  className="media"><i className="fa fa-folder f-36 txt-warning"></i>
             
                <div className="media-body ml-3">
                  <h6 className="mb-0">{data.folder_name}</h6>
                  
                    {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
                </div>
              </Link>
              <Dropdown key={i}   className="dropup" >
               
                          <DropdownMenu  className={dropdownOpen === true && data.folder_id === selectedid ? "dropup-content show" : "dropup-content" }>
                              <DropdownItem onClick={() => RemoveFolder(data.folder_id)}>Delete</DropdownItem>
                              <DropdownItem  onClick={() => {
                                setselectedid(data.folder_id)
                            
                                VaryingContentoneUpdatetoggle()
                              }} >Update</DropdownItem>
                              <DropdownItem onClick={() => {
                                           setselectedUser(data.userListe)
                                           setselectedid(data.folder_id)
                            
                                addToggle()
                                
                                }}>Partager</DropdownItem>
                         </DropdownMenu>
                        </Dropdown>
                        </div>
            </li>
            )
          })
      //.filter(res => (res.proprietaireId === idUser || res.userListe.includes(idUser)))
      const folderlist1 = myfile.sort(function(x,y) {
        if(sortState === "trieraz")
        return x.folder_name.toString().toLowerCase().localeCompare(y.folder_name.toString().toLowerCase());
        else if(sortState === "trierza")
        return y.folder_name.toString().toLowerCase().localeCompare(x.folder_name.toString().toLowerCase());
        else if(sortState === "trierdate")
        return y.folder_name.toString().localeCompare(x.folder_name.toString());
      }).filter((data) => {
        if(searchTerm == null)
            return data
        else return data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())
        }).map((data,i)=>{
          return(
            <li onContextMenu={e => {
         
              e.preventDefault();
      
  
    if(e.button === 2){
      setselectedUser(data.userListe)
      setselectedUserType(data.userListeType || [])
      localStorage.setItem("idfolder", data.folder_id)
      localStorage.setItem("folderName", data.folder_name)
      setselectedid(data.folder_id)
      setselectedidfolder(data.folder_parent_id)
      setselectedImage(data)
      optionFolderToggle()
      setclientX(e.clientX)
      setClientY(e.clientY)
  
  
    }}} className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
            <div   draggable="true" onDragStart={(event) => drag(event, data.id)} onDrop={(event) => drop(event, data.folder_id)} onDragOver={(event) => allowDrop(event)} className="media">
    
         <Link draggable onDoubleClick={() => {
              localStorage.setItem("idfolder", data.folder_id)
              localStorage.setItem("folderName", data.folder_name)
    dispatch({type: WATCH_SUB_FOLDER_LIST})
    dispatch({type: WATCH_FILE_LIST})
          }}  className="media"><i className="fa fa-folder f-36 txt-warning"></i>
         
            <div className="media-body ml-3">
              <h6 className="mb-0">{data.folder_name}</h6>
              
                {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
            </div>
          </Link>
          <Dropdown key={i}   className="dropup" >
           
                      <DropdownMenu  className={dropdownOpen === true && data.folder_id === selectedid ? "dropup-content show" : "dropup-content" }>
                          <DropdownItem onClick={() => RemoveFolder(data.folder_id)}>Delete</DropdownItem>
                          <DropdownItem  onClick={() => {
                            setselectedid(data.folder_id)
                        
                            VaryingContentoneUpdatetoggle()
                          }} >Update</DropdownItem>
                          <DropdownItem onClick={() => {
                                       setselectedUser(data.userListe)
                                       setselectedid(data.folder_id)
                        
                            addToggle()
                            
                            }}>Partager</DropdownItem>
                     </DropdownMenu>
                    </Dropdown>
                    </div>
        </li>
          )
        })
  const getFile = () => {
    document.getElementById("upfile").click();
  }

  const onFileChange = event => {
    // Update the state 
    setSelectedFile(event.target.files[0]);
    handleAddFile(event.target.files[0])
  };

  const onFileUpload = () => {
      let myfiles = [...myfile];

      if (selectedFile !== null) {
        myfiles.push({
          id: myfile.length + 1,
          name: selectedFile.name,
          size: `${selectedFile.size}`,
          modify: `${selectedFile.lastModifiedDate}`,
          icon:"fa fa-file-text-o txt-info"
        })
        setMyFile(myfiles)
        toast.success("Fichier Téléchargé avec succés")
      }else {
        toast.error("Veuilez choisir  au moins un fichier")
      }
  };
  const addToSelectedListe = (id,type) => {
    let selectedUserCopy = selectedUser
    let selectedUserTypeCopy = selectedUsertype
    if(selectedUserCopy.includes(id) == true){
      selectedUserCopy.splice(selectedUserCopy.findIndex(res => res == id), 1)
      selectedUserTypeCopy.splice(selectedUserTypeCopy.findIndex(res => res.user_id == id), 1)
    setselectedUser(selectedUserCopy)
    setselectedUserType(selectedUserTypeCopy)
    setrandom(Math.random)
    }
    else {
      selectedUserCopy.push(id)
      selectedUserTypeCopy.push({user_id : id, share_type : type, folder_id : selectedid})
      setselectedUser(selectedUserCopy)
      setselectedUserType(selectedUserTypeCopy)
      setrandom(Math.random)
    }
      }
      const addToSelectedListefile = (id,type) => {
        let selectedUserCopy = selectedUser
        let selectedUserTypeCopy = selectedUsertype
        if(selectedUserCopy.includes(id) == true){
          selectedUserCopy.splice(selectedUserCopy.findIndex(res => res == id), 1)
          selectedUserTypeCopy.splice(selectedUserTypeCopy.findIndex(res => res.user_id == id), 1)
        setselectedUser(selectedUserCopy)
        setselectedUserType(selectedUserTypeCopy)
        setrandom(Math.random)
        }
        else {
          selectedUserCopy.push(id)
          selectedUserTypeCopy.push({user_id : id, share_type : type, file_id : selectedid})
          setselectedUser(selectedUserCopy)
          setselectedUserType(selectedUserTypeCopy)
          setrandom(Math.random)
        }
          }
          const removeFromshareFile = (user_id , idfile) =>{

            dispatch(removefromsharefile(user_id , idfile))
            
                  }
            
                  const removeFromshareFolder = (user_id , idfolder) =>{
            
                    dispatch(removefromsharefolder(user_id , idfolder))
                          }
      const shareFolder = (e) => {
        e.preventDefault()
      
    dispatch(shareFolders(selectedid ,selectedUser, selectedUsertype))
    addToggle()
      }
      const sharefile = (e) => {
        e.preventDefault()
 
    dispatch(shareFiles(selectedid ,selectedUser,selectedUsertype))
    shareToggle()
      }

      const addFolderToFavoris = () => {
      
      
    dispatch(addFavorisFolders(selectedid))
    optionFolderToggle()
   
      }
      const addFileToFavoris = () => {
      
  
    dispatch(addFavorisFiles(selectedid))
    optionToggle()
      }
      const searchUser = (value) =>{
        setsearchvalue(value)
      }
   
  return (
    <Fragment>
      <Breadcrumb parent="Drive" title="Gestion Fichiers" />
      <Container fluid={true}>
        <Row>
       
          <Col xl="12" md="12" className="box-col-12">
      
          <div className="file-content">
        <Card>
          <CardHeader>
            <div className="media">
              <Form className="form-inline">
                <FormGroup>
                  <i className="fa fa-search"></i>
                  <Input
                    className="form-control-plaintext"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleChange(e)}
                    placeholder="Recherche..." />
                </FormGroup>
              </Form>
              <div className="media-body text-right">
                <Form className="d-inline-flex">
                <div className="btn btn-primary mr-1" onClick={VaryingContentonetoggle}> <PlusSquare />{AddNewFolder}</div>
                  <div className="btn btn-primary" onClick={AddFilesStatetoggle}> <PlusSquare />{AddNew}</div>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                    <input id="upfile" multiple type="file" onChange={(e) => onFileChange(e)} />
                  </div>

                </Form>
              
              </div>
              <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
               <ModalHeader toggle={VaryingContentonetoggle}>
                {NewFolder}
               </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Name Folders:"}</Label>
                      <Input onChange={(e) => setFolderName(e.target.value)} value={folderName} className="form-control" type="text" defaultValue=""/>
                    </FormGroup>
                 
                  </Form>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={VaryingContentonetoggle}>{Close}</Button>
                      <Button color="primary" onClick={handleAddFolder}>{AddFolder}</Button>
                </ModalFooter>
          </Modal>
          <Modal isOpen={AddFilesState} toggle={AddFilesStatetoggle}>
               <ModalHeader toggle={AddFilesStatetoggle}>
                {Newfiles}
               </ModalHeader>
                <ModalBody>
                <Card>
                <Row>
              <Col md="6">
                 
                        <div onClick={(e) => {
                            
                            setTelechargeable(!telechargeable)
                       
                          }} className="checkbox ml-3">
                          <input  checked={telechargeable} type="checkbox"/>
                          <Label className="text-muted" for="checkbox1">{"Téléchargeable"}</Label>
                        </div>
                


               
              </Col>
              <Col md="6">
                 
              <div onClick={(e) => {
                            
                            setImprimeable(!imprimeable)
                       
                          }} className="checkbox ml-3">
                          <Input  checked={imprimeable} type="checkbox"/>
                          <Label className="text-muted" for="checkbox1">{"Imrpimable"}</Label>
                        </div>
                


               
              </Col>
              </Row>
                            <CardBody>
                                <Form>
                                    <div className="dz-message needsclick">
                                        <Dropzone
                                             inputContent={"Glisser les fichiers ou cliquez pour parcourir"}
                                             getUploadParams={getUploadParams}
                                             onChangeStatus={handleChangeStatus}
                                             submitButtonDisabled={true}
                                           
                                             inputWithFilesContent={'Ajouter fichier(s)'}
                                           //onSubmit={handleSubmit}
                                          
                                        />
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={AddFilesStatetoggle}>{Close}</Button>
                       <Button color="primary" onClick={handleSubmit}>{AddFile}</Button> 
                </ModalFooter>
          </Modal>
            </div>
          </CardHeader>
      

            <CardBody className="file-manager">
            <Row>
              <Col lg="6">
              <h4 className="mb-3">{AllFiles}</h4>
              </Col>
                <Col lg="6">
            <div className=" media-body file-box text-right">
                      <select onChange={(e) => setsortState(e.target.value)} className="button btn-primary file-box">
                        <option value="trieraz">Trier par alphabet A-Z</option>
                        <option value="trierza">Trier par alphabet Z-A</option>
                        <option value="trierdate">Trier par date création</option>
                      </select>
                    </div>
                    </Col>
                   
              </Row>
              
             
              <h6 className="mt-4">{Folders}</h6>
           
           { loadingfolder === false ?
            myfile.length > 0  && <ul className="folder">
           
           {typeUser === "admin" ? folderlist : folderlist1}

             </ul>
             :
             <ul className="folder">
             {folderlisloading}
             </ul>
             }
           <h6 className="mt-4">{Files}</h6>
           
           {loading === false ?
          filelist2.length > 0 &&  <ul className="files">
               {typeUser === "admin" ? filelist2 : filelistmanager}
    
           </ul>
           :
           <ul className="files">
           {filelisloading }
           </ul>
         }
            </CardBody>
          
        </Card>
      </div>

          </Col>
        </Row>
      </Container>
      <Modal isOpen={addModal} toggle={addToggle} size="lg">
                            <ModalHeader toggle={addToggle}>Recherche utilisateur</ModalHeader>
                            <ModalBody>
                              <Form onSubmit={(e) => shareFolder(e)} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                
                                  <FormGroup className="col-md-12">
                             
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                  { users.length > 0 ?
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                               {selectedImage?.usersInfos !== undefined ?  <h6 className="mt-4">{"Déja Partagé avec"}</h6>  : "" }
                               {selectedImage?.usersInfos !== undefined ? selectedImage?.usersInfos?.map((userpartage , i ) => 
                                   <li   className="clearfix">
                                   <Row>
                                     <Col sm="1" lg="1">
                                     <Media className="rounded-circle user-image" style={{float: 'left',
                                    width: '52px',
                                   height: '52px',
                                   marginRight: '5px'}} src={userpartage.image} alt="" />
                                     </Col>
                                   <Col sm="10" lg="10">
                                         <div>
                                           <div className="name">{userpartage.first_name +" "+ userpartage.last_name}</div>
                                           <div  className="status"> {userpartage.email}</div>
                                    </div>
                                    </Col>
                                    <Col sm="1" lg="1">
                                    <Trash onClick={() => {
                                      removeFromshareFolder(userpartage.user_id, selectedid)
                                      addToggle()
                                    }} />
                                    
                             </Col>
                             </Row>
                                  </li>) : ""}
                                  <h6 className="mt-4">{"Partagé avec"}</h6> 
                               
                                   
                                {  selectedImage?.usersInfos !== undefined ? users.filter(x => selectedImage?.usersInfos.find(res => res.user_id === x.user_id) == undefined &&  (x.email.includes(searchvalue) == true || x.first_name.includes(searchvalue)== true || x.last_name.includes(searchvalue)== true)).map((item, i) => {
                                    return (
                                   
                                      <li  onClick={() => addToSelectedListe(item.user_id, "Users")}  className="clearfix">
                                      <Row>
                                        <Col sm="1" lg="1">
                                        <Media className="rounded-circle user-image" style={{float: 'left',
                                       width: '52px',
                                      height: '52px',
                                      marginRight: '5px'}} src={`${urlbackend}${item.image}`} alt="" />
                                        </Col>
                                      <Col sm="10" lg="10">
                                            <div>
                                            <div className="name">{item.first_name +" "+ item.last_name}      ({item.role === "manager" ? "Marque" : item.role === "submanager" ? "Franchise" :item.role === "Fournisseur" ? "Fournisseur" : item.role === "admin" ?"Administrateur" : "autre"})</div>
                                           <div  className="status"> {item.email}</div>
                                       </div>
                                       </Col>
                                       <Col sm="1" lg="1">
                                       <div className="checkbox checkbox-primary mr-1">
                                  <Input  id={ i} onClick={() => addToSelectedListe(item.user_id, "Users")} type="checkbox" checked={selectedUser.includes(item.user_id) == true}  name={item.email} value={item.email} />
                                  <Label for={i}></Label>
                                </div>
                                </Col>
                                </Row>
                                     </li>
                                )
                              
                                }) : <></>}
                               
                            </ul>
                            </div>
                            :  
                            <Media className="img-fluid m-auto" src={errorImg} alt=""/>
                            }
                                     </FormGroup>
                                </div>
                                <div className='text-right'>   
                                         <Button color="secondary" onClick={addToggle}>{Cancel}</Button>
                                <Button color="primary" className="mr-1">{Partager}</Button>
                    
                                </div>
                              </Form>
                            </ModalBody>
                          </Modal>
                     
                          <Modal isOpen={shareModal} toggle={shareToggle} size="lg">
                            <ModalHeader toggle={shareToggle}>Recherche utilisateur</ModalHeader>
                            <ModalBody>
                              <Form onSubmit={(e) => sharefile(e)} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                
                                  <FormGroup className="col-md-12">
                              
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                  { users.length > 0 ?
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                               {selectedImage?.usersInfos !== undefined ?  <h6 className="mt-4">{"Déja Partagé avec"}</h6>  : "" }
                               {selectedImage?.usersInfos !== undefined ? selectedImage?.usersInfos?.map((userpartage , i ) => 
                                   <li   className="clearfix">
                                   <Row>
                                     <Col sm="1" lg="1">
                                     <Media className="rounded-circle user-image" style={{float: 'left',
                                    width: '52px',
                                   height: '52px',
                                   marginRight: '5px'}} src={userpartage.image} alt="" />
                                     </Col>
                                   <Col sm="10" lg="10">
                                         <div>
                                           <div className="name">{userpartage.first_name +" "+ userpartage.last_name}</div>
                                           <div  className="status"> {userpartage.email}</div>
                                    </div>
                                    </Col>
                                    <Col sm="1" lg="1">
                                    <div className="checkbox checkbox-primary mr-1">
                                      <Trash onClick={() => {
removeFromshareFile(userpartage.user_id, selectedid)
shareToggle()
                                      } } />
                      
                                </div>
                             </Col>
                             </Row>
                                  </li>) : ""}
                                  <h6 className="mt-4">{"Partagé avec"}</h6> 
                               
                                {
                           selectedImage?.usersInfos !== undefined ?
                                users.filter(x => selectedImage?.usersInfos.find(res => res.user_id === x.user_id) == undefined &&  (x.email.includes(searchvalue) == true || x.first_name.includes(searchvalue)== true || x.last_name.includes(searchvalue)== true)).map((item, i) => {
                                    return (
                                   
                                      <li  onClick={() => addToSelectedListefile(item.user_id, "Users")}  className="clearfix">
                                      <Row>
                                        <Col sm="1" lg="1">
                                        <Media className="rounded-circle user-image" style={{float: 'left',
                                       width: '52px',
                                      height: '52px',
                                      marginRight: '5px'}} src={item.userImage} alt="" />
                                        </Col>
                                      <Col sm="10" lg="10">
                                            <div>
                                              <div className="name">{item.first_name +" "+ item.last_name}      ({item.role === "manager" ? "Marque" : item.role === "submanager" ? "Franchise" :item.role === "Fournisseur" ? "Fournisseur" : item.role === "admin" ?"Administrateur" : "autre"})</div>
                                              <div  className="status"> {item.email}</div>
                                       </div>
                                       </Col>
                                       <Col sm="1" lg="1">
                                       <div className="checkbox checkbox-primary mr-1">
                                  <Input  id={ i} onClick={() => addToSelectedListefile(item.user_id, "Users")} type="checkbox" checked={selectedUser.includes(item.user_id) == true}  name={item.email} value={item.email} />
                                  <Label for={i}></Label>
                                </div>
                                </Col>
                                </Row>
                                     </li>
                                )
                              
                                }) : <></>}
                                
                            </ul>
                            </div>
                            :  
                            <Media className="img-fluid m-auto" src={errorImg} alt=""/>
                            }
                                     </FormGroup>
                                </div>
                                <div className='text-right'>   
                                <Button color="secondary" className="mr-1" onClick={shareToggle}>{Cancel}</Button>
                                <Button color="primary">{Partager} </Button>
                             </div>
                              </Form>
                            </ModalBody>
                          </Modal>
                          <ModalApercuFile selectedid={selectedid} showApercu={showApercu} showApercuToggle={showApercuToggle} selectedImage={selectedImage} />


                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionModal} toggle={optionToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  <li>
                      <Link onClick={() =>{
                           showApercuToggle()
                           optionToggle()
                      } } className="btn btn-light"><Eye />{"Apercu"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => {optionToggle(); shareToggle()}}  className="btn btn-light"><User />{"Partager"}</Link>
                    </li>
                    <li>
                      <Link  hidden={selectedImage.telechargeable === "0"} className="btn btn-light" onClick={() => {

                          var httpsReference = firestorage.refFromURL(selectedImage.file_url);  
                      httpsReference.getDownloadURL().then(res => alert(res))
                           
                      
                        }}><Share />{"Obtenir Le lien"}</Link>
                    </li>
                    <li>
                      <Link onClick={() => {addFileToFavoris()}} className="btn btn-light"><Star />{"Ajouter au favoris"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => history.push(`${process.env.PUBLIC_URL}/app/file-manager/imprime`,{file : selectedImage})} hidden={selectedImage.imprimeable === "0"} className="btn btn-light"><AlertCircle />{"Imprimer"}</Link>
                    </li>
                   
                    <li>
                      <Link    onClick={() =>  {optionToggle(); setFileName(selectedImage.files_name);  RenameFileUpdatetoggle()}}   className="btn btn-light"><AlertCircle />{"Renommer"}</Link>
                    </li>
                    <li>
                    <a   target="_blank" hidden={selectedImage.telechargeable === "0"} href={`${urlbackend}${selectedImage.file_url}`}  download={true}  rel="noopener noreferrer" className="btn btn-light"><Download />{"Télécharger"}</a>
                    </li>
                    <li>
                      <Link onClick={() => {optionToggle();  RemoveFiles(selectedImage.files_id)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionFolderModal} toggle={optionFolderToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  <li>
                      <Link onClick={() =>{
                          dispatch({type: WATCH_SUB_FOLDER_LIST})
                          dispatch({type: WATCH_FILE_LIST})
                          optionFolderToggle()
                      } } className="btn btn-light"><Eye />{"Ouvrir"}</Link>
                  </li>
                    <li>
                      <Link  onClick={() => {optionFolderToggle(); addToggle()}}  className="btn btn-light"><User />{"Partager"}</Link>
                    </li>
                    {/* <li>
                      <Link   className="btn btn-light" onClick={() => {

                          var httpsReference = firestorage.refFromURL(selectedImage.fileImage);  
                      httpsReference.getDownloadURL().then(res => alert(res))
                           
                      
                        }}><Share />{"Obtenir Le lien"}</Link>
                    </li> */}
                    <li>
                      <Link onClick={() => {addFolderToFavoris()}}  className="btn btn-light"><Star />{"Ajouter au favoris"}</Link>
                    </li>
                 
                   
                    <li>
                      <Link     onClick={() =>  {optionFolderToggle(); setFolderName(selectedImage.folder_name); VaryingContentoneUpdatetoggle()}}   className="btn btn-light"><AlertCircle />{"Renommer"}</Link>
                    </li>
                   
                    <li>
                      <Link onClick={() => {optionFolderToggle();  RemoveFolder(selectedImage.folder_id)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
                          <Modal isOpen={RenameFileUpdate} toggle={RenameFileUpdatetoggle}>
               <ModalHeader toggle={RenameFileUpdatetoggle}>
                {"Rennomer Fichier"}
               </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Nom fichier:"}</Label>
                      <Input onChange={(e) => setFileName(e.target.value)}  value={fileName} className="form-control" type="text" defaultValue={selectedImage.fileName}/>
                    </FormGroup>
                 
                  </Form>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={RenameFileUpdatetoggle}>{Close}</Button>
                      <Button color="primary" onClick={handleUpdateFile}>{"Modifier"}</Button>
                </ModalFooter>
          </Modal>
          <Modal isOpen={VaryingContentoneUpdate} toggle={VaryingContentoneUpdatetoggle}>
               <ModalHeader toggle={VaryingContentoneUpdatetoggle}>
                {"Renommer Dossier"}
               </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Nom Doosier:"}</Label>
                      <Input onChange={(e) => setFolderName(e.target.value)} value={folderName} className="form-control" type="text" defaultValue=""/>
                    </FormGroup>
                 
                  </Form>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={VaryingContentoneUpdatetoggle}>{Close}</Button>
                      <Button color="primary" onClick={handleUpdateFolder}>{"Rennommer"}</Button>
                </ModalFooter>
          </Modal>

    </Fragment>
  );
}

export default DetailsFilemanagerByFranchise;