import React, { Fragment, useEffect, useState } from 'react';
import ProgressStepBar from './progressStepBar';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import Breadcrumb from '../../layout/breadcrumb'
import { Accordion } from 'react-bootstrap';
import { Add, BasicAccordion, Cancel, CollapsibleGroupItem, Ribbon, UploadProjectFile } from '../../constant';
import { Edit, PlusCircle, Trash } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import Dropzone from "react-dropzone-uploader";
import { useForm } from 'react-hook-form';
import { firestorage } from '../../data/config';
import { toast } from 'react-toastify';
import { addNSousTache, addNTache, removeProcessTaches, removeTaches, updateSubTacheByTache, updateTache } from '../../redux/processdemande/action';
import { useDispatch, useSelector } from 'react-redux';
import {WATCH_DEV_PROCESS_TACHE_LIST} from '../../redux/actionTypes'
import moment from 'moment';
import SweetAlert from 'sweetalert2'
const  ProcessDetails = () => {
  const dispatch = useDispatch()
  const [stepNumber, setstepNumber] = useState(1)
  const [random, setrandom] = useState(1)
  const [selectedTache, setselectedTache] = useState("")
  const [selectedTacheEdit, setselectedTacheEdit] = useState(null)
  const [selectedSubTacheEdit, setselectedSubTacheEdit] = useState(null)

  const { register, handleSubmit, errors } = useForm();
  const [stateModalTache, setstateModalTache] = useState(false)
  const toggleModalTache = () => setstateModalTache(!stateModalTache)

  const [stateModalUpdateTache, setstateModalUpdateTache] = useState(false)
  const toggleModalUpdateTache = () => setstateModalUpdateTache(!stateModalUpdateTache)

  const [stateModalSubTache, setstateModalSubTache] = useState(false)
  const toggleModalSubTache = () => setstateModalSubTache(!stateModalSubTache)

  const [stateModalUpdateSubTache, setstateModalUpdateSubTache] = useState(false)
  const toggleModalUpdateSubTache = () => setstateModalUpdateSubTache(!stateModalUpdateSubTache)
  const {idFranchise, idProcess} = useParams()
  const [myfilee, setMyFile] = useState([])
  const tacheliste = useSelector(content => content.ProcessApp.tache_Process);
   useEffect(() => {
  dispatch({type : WATCH_DEV_PROCESS_TACHE_LIST, idProcess : idProcess,idFranchise : idFranchise})
   }, []);
  

  const getUploadParams = ({ meta, file }) => {

       
    
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/taches${idProcess}/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/taches${idProcess}`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;

      
        myfils.push({
      url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          console.log(myfils)
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
if (status === "removed"){
let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
setMyFile(myfileeCopy)
}
  }

const AddTache = (data) => {
  
  if (data !== "" ) {

    data.processID = idProcess
    
    data.subTacheListe = []
    data.subTacheNumber = 0
    
    data.createdAt = moment(new Date()).format('yyyy-MM-DD HH:mm')
    data.files = myfilee    
   
 toggleModalTache()
    dispatch(addNTache(data,idFranchise,idProcess));
        
        } else {
          errors.showMessages();
        }
}


const UpdateSousTache = (data) => {
  
  if (data !== "" ) {
    
    data.files = myfilee    
   
 toggleModalUpdateSubTache()
 let subtacheliste = selectedTacheEdit.subTacheListe.map((item) => item.title === selectedSubTacheEdit.title  ? { ...item,...data }
  : item)
    dispatch(updateSubTacheByTache(idFranchise,idProcess,selectedTacheEdit.id,subtacheliste));
        
        } else {
          errors.showMessages();
        }
}
const UpdateTache = (data) => {
  
  if (data !== "" ) {
    
    data.files = myfilee    
   
 toggleModalUpdateTache()
    dispatch(updateTache(data,idFranchise,idProcess,selectedTacheEdit.id));
        
        } else {
          errors.showMessages();
        }
}
const AddSousTache = (data) => {
  
  if (data !== "" ) {

    data.processID = idProcess
    

    data.createdAt = moment(new Date()).format('yyyy-MM-DD HH:mm')
    data.files = myfilee    
   
 toggleModalSubTache()
    dispatch(addNSousTache(data,idFranchise,idProcess,selectedTache));
        
        } else {
          errors.showMessages();
        }
}
const deleteTache = (id) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
   
      dispatch(removeProcessTaches(idFranchise,idProcess,id));
  
  
      SweetAlert.fire(
        'Supprimé!',
        'Votre Tache a été supprimée',
        'success'
      )
    }
    else {
    
    }
  })
}  
const deleteSubTache = (tache,id) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
      console.log(tache)
   let subtacheliste = tache.subTacheListe.filter(res => res.title !== id)
      dispatch(updateSubTacheByTache(idFranchise,idProcess,tache.id,subtacheliste));
  
  
      SweetAlert.fire(
        'Supprimé!',
        'Votre Sous Tache a été supprimée',
        'success'
      )
    }
    else {
    
    }
  })
}  
  const removefile = (name) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
     let rescop = myfilee
     rescop = rescop.filter(res => res.name !== name)
     setrandom(Math.random())
     setMyFile(rescop)
      SweetAlert.fire(
        'Supprimé!',
        'Votre fichier a été supprimée',
        'success'
      )
     
   
     
    }
    else {
    
    }
  })
}
    return (
        <Fragment>
        <Breadcrumb parent="Process" title="Liste Tâches" />
        <Container fluid={true}>
          <Row>
          <Col sm="12" xl="12">
          <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <button className="btn btn-primary" style={{ color: 'white' }} onClick={toggleModalTache}>{"Créer nouvelle Tâche"}</button>
                  </div>
                  </Col>
        <Col sm="12" xl="12">
            <Accordion defaultActiveKey="0">
              <Card>
             
                <CardBody>
                  <div className="default-according" id="accordion">
                  {tacheliste.map((tache,index) => 
                     <Card>
                     <CardHeader>
                       <Row>
                         <Col>
                         
                       <h5 className="mb-0">
                         <Accordion.Toggle as={Card.Header} className="btn btn-link" color="default" eventKey={index+1}>
                           {tache.title}<span className="digits"> - Nombre Sous Tâches : {tache.subTacheListe?.length}  </span>

                         </Accordion.Toggle>
                </h5>
                </Col>
                <Col>
                <div className='text-right'>
                         <Trash onClick={() => deleteTache(tache.id)} />
<Edit onClick={() => {
  setselectedTacheEdit(tache)
  toggleModalUpdateTache()
  setMyFile(tache.files)
}} />
</div>  
</Col>
</Row>
                     </CardHeader>
                     <Accordion.Collapse eventKey={index+1}>
                       <CardBody>
                         {tache.description}
                         {tache?.files.map((data,i)=>{
      return(
        <li    onDoubleClick={() => {
           
           
            }} className="file-box" key={i}>
    
    
          <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
          <div className="file-bottom">
            <h6>{data.name.slice(0,20)}...</h6>
    
          </div>
       
               
        </li>
      )
    })}

      <Row>
      <Col sm="12" xl="12">
          <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <button className="btn btn-primary" style={{ color: 'white' }} onClick={() => {
                      setselectedTache(tache.id)
                      toggleModalSubTache()
                    }}>{"Créer nouvelle sous Tâche"}</button>
                  </div>
                  </Col>
      {tache.subTacheListe.map((subtache,i) =>    <Col sm="12" xl="4">
      <Card className="ribbon-wrapper">
              <CardBody>
                  
                      <div className="ribbon ribbon-primary ribbon-space-bottom">{subtache.title}</div>
                      <div className='text-right'>
                         <Trash onClick={() => {
                      
                           deleteSubTache(tache,subtache.title)
                         }} />
<Edit onClick={() => {
    setselectedTacheEdit(tache)
  setselectedSubTacheEdit(subtache)
  toggleModalUpdateSubTache()
  setMyFile(subtache.files)
}} />
</div>  
                      <p>{subtache.description}</p>
                      </CardBody>
            </Card>
                  </Col>)}
               
               
                </Row>
                           </CardBody>
                     </Accordion.Collapse>
                   </Card>

                    )}
                   
                   
                  
                  </div>
                </CardBody>
              </Card>
            </Accordion>
          </Col>
          </Row>
<Modal isOpen={stateModalTache} toggle={toggleModalTache}>
<ModalBody>
<Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(AddTache)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Nom tâche *"}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="Nom Tâche *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Nom tâche est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Détails tâche"}</Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          name="description"
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Détails est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Ordre tâche"}</Label>
                        <Input
                          type="number"
                          className="form-control"
                          name="order"
                          innerRef={register({ required: true,min: 0 })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "order est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                          onClick={toggleModalTache}
                        >
                          <Button color="secondary" className="mr-3">{Cancel}</Button>
                        </Link>
                        <Button color="primary" >
                          {Add}
                        </Button>
                      
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
</ModalBody>
      </Modal>

<Modal isOpen={stateModalUpdateTache} toggle={toggleModalUpdateTache}>
<ModalBody>
<Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(UpdateTache)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Nom tâche *"}</Label>
                        <Input
                        defaultValue={selectedTacheEdit?.title}
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="Nom Tâche *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Nom tâche est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Détails tâche"}</Label>
                        <Input
                          defaultValue={selectedTacheEdit?.description}
                          type="textarea"
                          className="form-control"
                          name="description"
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Détails est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Ordre tâche"}</Label>
                        <Input
                             defaultValue={selectedTacheEdit?.order || 0}
                          type="number"
                          className="form-control"
                          name="order"
                          innerRef={register({ required: true,min: 0 })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "order est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    {selectedTacheEdit?.files.map((data,i)=>{
      return(
        <li    onDoubleClick={() => {
           
           
            }} className="file-box" key={i}>
    
    
          <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
          <div className="file-bottom">
            <h6>{data.name.slice(0,20)}...<i className="fa fa-trash" onClick={() => removefile(data.name)}></i></h6>
    
          </div>
       
               
        </li>
      )
    })}
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                          onClick={toggleModalUpdateTache}
                        >
                          <Button color="secondary" className="mr-3">{Cancel}</Button>
                        </Link>
                        <Button color="primary" >
                          {"Modifier"}
                        </Button>
                      
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
</ModalBody>
      </Modal>

<Modal isOpen={stateModalSubTache} toggle={toggleModalSubTache}>
<ModalBody>
<Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(AddSousTache)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Nom sous tâche *"}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="Nom Sous Tâche *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Nom Sous tâche est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Détails sous tâche"}</Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          name="description"
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Détails est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Ordre sous tâche"}</Label>
                        <Input
                          type="number"
                          className="form-control"
                          name="order"
                          innerRef={register({ required: true,min: 0 })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "order est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                          onClick={toggleModalSubTache}
                        >
                          <Button color="secondary" className="mr-3">{Cancel}</Button>
                        </Link>
                        <Button color="primary" >
                          {Add}
                        </Button>
                      
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
</ModalBody>
      </Modal>
      <Modal isOpen={stateModalUpdateSubTache} toggle={toggleModalUpdateSubTache}>
<ModalBody>
<Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(UpdateSousTache)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Nom sous tâche *"}</Label>
                        <Input
                         defaultValue={selectedSubTacheEdit?.title}
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="Nom Sous Tâche *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Nom Sous tâche est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Détails sous tâche"}</Label>
                        <Input
                         defaultValue={selectedSubTacheEdit?.description}
                          type="textarea"
                          className="form-control"
                          name="description"
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Détails est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Ordre sous tâche"}</Label>
                        <Input
                        defaultValue={selectedSubTacheEdit?.order}
                          type="number"
                          className="form-control"
                          name="order"
                          innerRef={register({ required: true,min: 0 })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "order est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    {selectedSubTacheEdit?.files.map((data,i)=>{
      return(
        <li    onDoubleClick={() => {
           
           
            }} className="file-box" key={i}>
    
    
          <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
          <div className="file-bottom">
            <h6>{data.name.slice(0,20)}...<i className="fa fa-trash" onClick={() => removefile(data.name)}></i></h6>
    
          </div>
       
               
        </li>
      )
    })}
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                          onClick={toggleModalUpdateSubTache}
                        >
                          <Button color="secondary" className="mr-3">{Cancel}</Button>
                        </Link>
                        <Button color="primary" >
                          {"Modifer"}
                        </Button>
                      
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
</ModalBody>
      </Modal>
      </Container>
      
    
      
      </Fragment>
    );
  }


export default ProcessDetails