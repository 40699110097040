import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchCategoryApi, fetchCategoryprovider, fetcheventCategoryApi } from "../../api";
import { WATCH_CATEGORY,GET_CATEGORY, GET_EVENT_CATEGORY, WATCH_EVENT_CATEGORY, WATCH_PROVIDER_CATEGORY, GET_PROVIDER_CATEGORY } from "../../redux/actionTypes";

function* fetchCategorieAsyn() {
    const Data = yield call(fetchCategoryprovider);
    yield put({type : GET_PROVIDER_CATEGORY ,category : Data});
}

export function* watchProviderCategorieList() {
    yield takeLatest(WATCH_PROVIDER_CATEGORY,fetchCategorieAsyn)
}
