import {GET_FOLDER_LIST,
    ADD_TO_MY_FOLDER,
    ADD_NEW_FOLDER,
    REMOVE_FOLDER,
    REMOVE_FROM_MY_FOLDER,
    UPDATE_FOLDER,
    UPDATE_MY_FOLDER,
    GET_FOLDER_LIST_BY_ID,
    UPDATE_FOLDER_TREE,
    SHARE_FOLDER,
    ADD_FOLDER_TO_FAVORIS,
    DELETE_FOLDER_TO_FAVORIS,
    GET_FOLDER_LIST_LOADING,
    GET_SUB_FOLDER_LIST_LOADING,
    GET_FOLDER_BY_ID_USER_LIST,
    DELETE_FOLDER_FROM_SHARE,
    REMOVE_SUB_FOLDER,
    REMOVE_SHARE_FOLDER,
    REMOVE_MY_SHARE_FOLDER,
    ADD_NEW_SUB_FOLDER,
    REMOVE_FINALY_FOLDER,
    RECUPERE_FOLDER} from '../actionTypes'


const initial_state = {
folders:[],
subfolders:[],
foldersfranchise:[],
myfolderdata:[],
loading: true,
loadingsubfolder: true,
}

export default (state = initial_state, action) => {

switch (action.type) {
    case GET_FOLDER_LIST_LOADING:
        return {...state,loading: true};
    case GET_SUB_FOLDER_LIST_LOADING:
        return {...state,loadingsubfolder: true};
    case GET_FOLDER_LIST:
  
        return {...state,folders:action.folders, loading: false,};
        case GET_FOLDER_BY_ID_USER_LIST:
        return {...state,foldersfranchise:action.folders, loading: false,};
    case GET_FOLDER_LIST_BY_ID:
         
        return {...state, subfolders:action.folders, loadingsubfolder: false,};

    case ADD_TO_MY_FOLDER:
        return {...state,myfolderdata:[...state.myfolderdata, { ...action.payload.myfolderdata}]};
        
        case ADD_NEW_SUB_FOLDER:
        
            state.subfolders.push(action.payload)
            return {...state,subfolders:state.subfolders};
    case ADD_NEW_FOLDER:
        
        state.folders.push(action.payload)
    return {...state,folders : state.folders};
    
    case UPDATE_FOLDER:
        return {
            ...state,folders: state.folders.map(item => item.folder_id === action.payload.id ?
                { ...item, 
                
                    folder_name:action.payload.updatedata.folder_name,
                    updated_date: action.payload.updatedata.updated_date,
                  
                } : 
                item
            ) 
        };
    
    case UPDATE_MY_FOLDER:
            return {
                ...state,myfolderdata: state.myfolderdata.map(item => item.id === action.payload.id ?
                    { ...item, 
                        id: action.payload.id,
                        fillstar:action.payload.updateMydata.fillstar,
                        image: action.payload.image_url,
                        title: action.payload.updateMydata.title,
                        website_url: action.payload.updateMydata.url,
                        desc: action.payload.updateMydata.desc,
                        collection:action.payload.updateMydata.collection
                    } : 
                    item
                ) 
            };
    case UPDATE_FOLDER_TREE:
                return {
                    ...state,folders: state.folders.map(item => item.id === action.payload.iddest ?
                        { ...item, 
                            idfolder : action.payload.idsrc
                        } : 
                        item
                    ) 
                };
                
    case ADD_FOLDER_TO_FAVORIS:
                return {
                    ...state,folders: state.folders.map(item => item.id === action.payload.id ?
                        { ...item, 
                            favorisUser : [...item.favorisUser || [] ,action.payload.idUser]
                        } : 
                        item
                    ) 
                };
                
                case DELETE_FOLDER_FROM_SHARE:
                return {
                    ...state,folders: state.folders.map(item => item.id === action.payload.id ?
                        { ...item, 
                            userListe : item.userListe.filter(res => res !== action.payload.idUser)
                        } : 
                        item
                    ) 
                };
    case DELETE_FOLDER_TO_FAVORIS:
                return {
                    ...state,folders: state.folders.filter(item => item.folder_id !== action.payload.id )
                };
                    
                case REMOVE_SHARE_FOLDER:
                    return {
                        ...state,folders: state.folders.map(item => item.folder_id === action.payload.idfolder ?
                            { ...item, 
                                userListe : item.userListe(res => res.user_id !== action.payload.user_id),
                                usersInfos : item.usersInfos(res => res.user_id !== action.payload.user_id)
                            } : 
                            item
                        ) 
                    };      
                    case REMOVE_MY_SHARE_FOLDER:
                        return {
                            ...state,folders: state.folders.filter(item => item.folder_id !== action.payload.idfolder )
                        };          
                case SHARE_FOLDER:
                return {
                    ...state,folders: state.folders.map(item => item.folder_id === action.payload.idfolder ?
                        { ...item, 
                            userListe : action.payload.sharedListe
                        } : 
                        item
                    ) 
                };
                
                case REMOVE_FOLDER:
        let new_folder = state.folders.filter(item=> action.removeid !== item.folder_id)
        return{...state,folders: new_folder,loading : false}
    case REMOVE_FINALY_FOLDER:
        let new_folderdd = state.folders.filter(item=> action.removeid !== item.folder_id)
        return{...state,folders: new_folderdd,loading : false}
  case RECUPERE_FOLDER:
            let new_folderrecup = state.folders.filter(item=> action.removeid !== item.folder_id)
            return{...state,folders: new_folderrecup,loading : false}
    case REMOVE_SUB_FOLDER:
            let new_dub_folder = state.subfolders.filter(item=> action.removeid !== item.folder_id)
            return{...state,subfolders: new_dub_folder,loadingsubfolder : false}
        
    case REMOVE_FROM_MY_FOLDER:
        let new_my_folder = state.myfolderdata.filter(item=> action.folderid !== item.id)
        return{...state,myfolderdata: new_my_folder}
            
    default: return { ...state };
}

}