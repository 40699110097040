import React,{Fragment, useState,useEffect,useRef} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,Button,Media, CardBody, Form, Modal, InputGroup, Input, InputGroupAddon, ModalBody, CardHeader, Label} from 'reactstrap'

import Slider from 'react-slick';
import {Link, useHistory, useParams} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import defaultuser from '../../../assets/images/user/user.png';
import { addToCart,watchfetchSingleProducts } from '../../../redux/ecommerce/product/action';
import { ADD_TO_CART, ADD_TO_WISHLIST, WATCH_ADD_SONDAGE, WATCH_PRODUCT_LIST } from '../../../redux/actionTypes'
import Ratings from 'react-ratings-declarative'
import { ProductReview,  Brand, Availability, AddToCart, BuyNow, AddNewEvent, ManageCategory, Ratio } from "../../../constant";
import { Truck, Gift,CreditCard,Clock, Trash, Edit } from 'react-feather';
import { fetchEventByIdApi, fetchFranchiseByIdApi, fetchNewsByIdApi } from '../../../api';
import { urlbackend } from '../../../data/config';
import htmr from 'htmr';
import moment from 'moment';
import SweetAlert from 'sweetalert2'
import { addcomment, addstatusParticipe, removeevent } from '../../../redux/events/action';
import { addcommentnew, deletecommentaire, removenew, updatecomment } from '../../../redux/news/action';
import DataTable from 'react-data-table-component';

const  Newspage = (props)  => {
    const {id} = useParams()
    const history = useHistory()
    const [listeUserModal, setlisteUserModal] = useState(false);
    const updatelisteUserToogle = () => setlisteUserModal(!listeUserModal)
    const products = useSelector(content => content.data.productsFranchise)
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [news, setNews] = useState(null);
    const [selectedCommenatire, setselectedCommenataire] = useState(null);
    const [stateClick, setstateClick] = useState(null);
    const [messageInput, setMessageInput] = useState('');
    const [commentModal, setCommentModal] = useState(false);
    const commentModalToggle = () => setCommentModal(!commentModal)
    const [userListe, setuserListe] = useState([]);
    const [rating,setRating] = useState(0)
    const [updatecommentModal, setUpdateCommentModal] = useState(false);
    const updatecommentModalToggle = () => setUpdateCommentModal(!updatecommentModal)
    // eslint-disable-next-line
    const [typeUser, settypeUser] = useState("");
    const [quantity,Setquantity] = useState(1)
  
    
    const slider1 = useRef();
    const slider2 = useRef();
    const dispatch = useDispatch()

    useEffect(() => {
      fetchNewsByIdApi(id).then(res => {

        setNews(res)
       
    
      }).catch(e => console.log(e))
        settypeUser(localStorage.getItem("@intranetTypeUser"))
        setState({
            nav1: slider1.current,
            nav2: slider2.current
          });
      } ,[dispatch]);
    const { nav1, nav2 } = state;

  const addComment = () => {
let data = {}
if(messageInput.length > 0){
    data.comment = messageInput
    data.new_id = id
    data.prop_id = localStorage.getItem("@intranetidUser")
    data.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
    addcommentnew(data)

    setMessageInput("")
    setTimeout(() => {
      fetchNewsByIdApi(id).then(res => {
          
            if(res.title !== undefined){
                setNews(res)
                //setStateUser(res.franchise_state)
                //dispatch({type: WATCH_SUBFRANCHISE_LIST})
            }
           
         
          })  
    }, 2000);

}
  }
  const deleteEvent = (id) => {
    
    SweetAlert.fire({
     
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            dispatch(removeevent(id))
       history.push(`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate`)
           // eventClick.news?.remove();
          SweetAlert.fire(
            'Supprimé!',
            'Votre Evénement a été supprimée',
            'success'
          )
        }
        else {
        
        }
      })
               
             
            
     
    }
    const productColumns = [
      {
     
    
          cell: row => {
            return (
              <img src={row.userImage ?? defaultuser} style={{ width: 50, height: 50 }} alt="" />
            )
          },
          sortable: true,
          center:true,
        },
        {
          
          selector: 'fullName',
          sortable: true,
          center:true,
        }
  ];
  const AddSondage = (idNew,contenu ) => {

    dispatch({type: WATCH_ADD_SONDAGE, idNew : idNew, contenu : contenu})

      setTimeout(() => {
      fetchNewsByIdApi(id).then(res => {
          
            if(res.title !== undefined){
                setNews(res)
                //setStateUser(res.franchise_state)
                //dispatch({type: WATCH_SUBFRANCHISE_LIST})
            }
           
         
          })  
    }, 2000);

  }
    const symbol = useSelector(content => content.data.symbol)

    const  addcart = (product, qty) => {
        dispatch({ type: ADD_TO_CART, payload: { product, qty } })
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/cart`)
    }
    
    const buyProduct = (product, qty) => {
        dispatch({ type: ADD_TO_CART, payload: { product, qty } })
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/checkout`)
    }

    const addWishList = (product) => {
        dispatch({ type: ADD_TO_WISHLIST, payload: { product } });
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`);
    }

    const changeRating = (newRating) => {
        setRating(newRating)
    }
  
    const RemoveNews  = (taskId) => {

      SweetAlert.fire({
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          dispatch(removenew(taskId));
       history.goBack()
        }
        else {
          SweetAlert.fire(
            'Votre actualité est sauvgardée'
          )
        }
      })
    }
    const updateComment = () => {
      let data = {}
      if(messageInput.length > 0){
          data.comment = messageInput
          data.comment_id = selectedCommenatire
        
          updatecomment(data)
     
      setMessageInput("")
          updatecommentModalToggle()
          setTimeout(() => {
            fetchNewsByIdApi(id).then(res => {
                
              if(res.title !== undefined){
                  setNews(res)
                  //setStateUser(res.franchise_state)
                  //dispatch({type: WATCH_SUBFRANCHISE_LIST})
              }
             
           
            })  
          }, 2000);
      
      }
        }
        
    const removeCommentaire = (id) => {
      
      SweetAlert.fire({
       
          title: 'Vous Êtes sûr?',
          text: "Une fois supprimé, vous ne pourrez plus récupérer ",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'cancel',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            deletecommentaire(id)
         //history.push(`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate`)
             // eventClick.event.remove();
          setTimeout(() => {
              fetchNewsByIdApi(id).then(res => {
                
                  if(res.title !== undefined){
                      setNews(res)
                      //setStateUser(res.franchise_state)
                      //dispatch({type: WATCH_SUBFRANCHISE_LIST})
                  }
                 
               
                })  
          }, 2000);
            
          }
          else {
          
          }
        })
                 
               
              
       
      }
    return (
        <Fragment>
            <Breadcrumb parent="Actualité" title="Détail Actualité"/>
                <Container fluid={true}>
                {news?.prop_id === localStorage.getItem("@intranetidUser")  ?
                <div className="media-body text-right">

                <Form className="d-inline-flex mr-3">

                  <Link to={localStorage.getItem("@intranetTypeUser") === 'admin' ? `${process.env.PUBLIC_URL}/app/actualites/edit-admin-actualite/${id}` : `${process.env.PUBLIC_URL}/app/actualites/actualiteEdit/${id}`} className="btn btn-primary" > <Edit size={12}/></Link>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
                <Form className="d-inline-flex">

                  <a onClick={() => RemoveNews(id)} className="btn btn-primary" ><Trash size={12}/></a>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
              </div> : null}
                   <Row>
                       <Col>
                       <div>
                                        <table className="product-page-width">
                                        <tbody>
                                            <tr>
                                            <td> <b>{"Créateur"} &nbsp;&nbsp;&nbsp;:</b></td>
                                            <td>{news?.managerName}</td>
                                            </tr>
                                           
                                        </tbody>
                                        </table>
                                    </div>
                            <Card>
                               <Row className="product-page-main">
                                    <Col xl="4">
                         {/* <div className="item" >
                                                <Media src={singleItem.productImage} alt="" className="img-fluid" />
                                            </div>  */}
                            <Slider
                                    asNavFor={nav2} 
                                    arrows= {false}
                                        ref={slider => (slider1.current = slider)} className="product-slider">

                                    {news?.piecesJoint?.length > 0 ? news?.piecesJoint.map((item, i) => {
                                      if(item.type.includes('image'))
                                        return (
                                            <div className="item" key={i}>
                                                <Media src={`${urlbackend}${item.url}`} alt="" className="img-fluid" />
                                            </div>
                                        )
                                        else if( item.type.includes('video'))
                                        return <div className="col-12 p-0">
                                          <video  style={{width : '100%', height: 300}}  controls>
                                       <source src={`${urlbackend}${item.url}`} type="video/mp4"/>
                                       <source src={`${urlbackend}${item.url}`} type="video/ogg"/>
                                       Your browser does not support the video tag.
                                     </video> </div> 
                                    }) :
                                        <></>
                                    }   
                                    </Slider>
                                   
                            </Col>
                            <Col xl="5 xl-100">
                                <Card>
                                    <CardBody>
                                    <div className="product-page-details">
                                        <h3>{news?.title}</h3>
                                    </div>
                                 
                                   
                                    <hr/>
                                    <div dangerouslySetInnerHTML={{ __html: news?.news_contenu}}></div>
                                    <hr/>
                                  <div>
                                  <Row>
                      <Col sm="12" xl="6 xl-100 box-col-12">
                      <Card className="height-equal m-t-20">
                       
                        <CardBody className='p-0'>
                          <Form className="mega-horizontal">
                            <Row>
                            
                              {news?.sondageListe?.map((sondage,i) => 
                              <Col key={i} lg="12" sm="12">
                                <Card style={{width : '100%'}}>
                                  <Media className="p-20" >
                                  
                                    <Media body>
                                      <h6 className="mt-0 mega-title-badge"><span className="badge badge-primary pull-left digits">{ sondage.userListe !== undefined ?  sondage.userListe.length + " votes" :  "0 votes"}</span></h6>
                                      <p>{sondage.contenu_sondage}</p>
                                      <div className="customers d-inline-block avatar-group">
                                     {news?.news_sondage_state === "1" ?
                        <ul>
                          {sondage.userListe?.map((sond, index) => 
                          {
if( index < 4)
                         return (
                             <li className="d-inline-block"><Media body className="img-30 rounded-circle" style={{height: 30}} src={sond.userImage ?? defaultuser} alt=""/></li>
                          ) }
                             )}
                          { sondage.userListe?.length > 4 ? <span>   <a style={{color : "red"}} onClick={() => {
                            setuserListe(sondage.userListe)
updatelisteUserToogle()
                          }}>voir +</a> </span>  : ""} 
                                                 </ul>
                                                  : null}       
                      </div>
                                    </Media>
                                    <div onClick={() => AddSondage(news?.news_id,sondage.id)} className="radio radio-primary mr-1">
                                      <Input  news_id={news?.title + i} type="radio"  name={news?.title} value={sondage.contenu_sondage} />
                                      <Label for={news?.title + i}></Label>
                                    </div>
                                  </Media>
                                </Card>
                              </Col>
                              
                              )}
                             
                            
                             
                            </Row>
                          </Form>
                        </CardBody>
                      
                      </Card>
                    </Col>
                  </Row>
                                  <div className="product-page-details">
                                        <h3>{news?.commentListe?.length} {"Commentaire"}</h3>
                                    </div>
                                    <>
                   
                   <ul >
                   {news?.commentListe?.map((commentaire , index ) => 
                             
                         
                     <li>
                       <Media className="align-self-center">
                       <Media className="align-self-center" src={commentaire.userImage ?? defaultuser} alt=""/> 
                         <Media body>
                           <Row>
                             
                             <Col md="4 xl-100">
                               <h6 className="mt-0">{commentaire.userName}</h6>
                             </Col>
                             <Col lg="1" sm="1">
                  
                  {localStorage.getItem("@intranetidUser") === commentaire.prop_id ? <>
                <i onClick={() =>  {
                  setMessageInput(commentaire.comment)
                  setselectedCommenataire(commentaire.comment_id)
                  updatecommentModalToggle()
                }} className="icofont icofont-edit"></i>
                <i onClick={() => removeCommentaire(commentaire.comment_id)} className="icofont icofont-trash"></i>
                </>:
               null
              }
                
           
                </Col> 
                           </Row>
                           <p>{commentaire.comment}</p>
                           <ul className="files">
                  
</ul>
                         </Media>
                       </Media>
                     </li>
                        )}
                
                   </ul>
                   <div className="chat-message clearfix">
                           <Row>
                          
                             <Col xl="12" className="d-flex">
                             
                               <InputGroup className="text-box">
                                   <Input
                                       type="text"
                                       className="form-control input-txt-bx"
                                       placeholder="Ecrire un commentaire......"
                                       value={messageInput}
                                     onChange={(e) => setMessageInput(e.target.value)}
                                   />
                                   <InputGroupAddon addonType="append">
                                       <Button color="primary" onClick={() => addComment()}   className="form-control input-txt-bx" style={{fontSize: 16}} >{"Commenter"}</Button>
                                   </InputGroupAddon>
                               </InputGroup>
                             </Col>
                           </Row>
                         </div>
                         
                   <Modal centered isOpen={commentModal} toggle={commentModalToggle}>
                       <ModalBody>
                   <div className="chat-message clearfix">
                           <Row>
                          
                             <Col xl="12" className="d-flex">
                             
                               <InputGroup className="text-box">
                                   <Input
                                       type="text"
                                       className="form-control input-txt-bx"
                                       placeholder="Ecrire un commentaire......"
                                       value={messageInput}
                                     onChange={(e) => setMessageInput(e.target.value)}
                                   />
                                   <InputGroupAddon addonType="append">
                                       <Button color="primary" onClick={() => addComment()}   className="form-control input-txt-bx" style={{fontSize: 16}} >{"Commenter"}</Button>
                                   </InputGroupAddon>
                               </InputGroup>
                             </Col>
                           </Row>
                         </div>
                         </ModalBody>
                   </Modal>
                   <Modal centered isOpen={updatecommentModal} toggle={updatecommentModalToggle}>
                       <ModalBody>
                   <div className="chat-message clearfix">
                           <Row>
                          
                             <Col xl="12" className="d-flex">
                             
                               <InputGroup className="text-box">
                                   <Input
                                       type="text"
                                       className="form-control input-txt-bx"
                                       placeholder="Ecrire un commentaire......"
                                       value={messageInput}
                                     onChange={(e) => setMessageInput(e.target.value)}
                                   />
                                   <InputGroupAddon addonType="append">
                                       <Button color="primary" onClick={() => updateComment()}   className="form-control input-txt-bx" style={{fontSize: 16}} >{"Commenter"}</Button>
                                   </InputGroupAddon>
                               </InputGroup>
                             </Col>
                           </Row>
                         </div>
                         </ModalBody>
                   </Modal>
                   </> 
                                    </div> 
                                    <hr/>
                                    {/* <Row>
                                        <Col md="6">
                                        <h6 className="product-title">{"share it"}</h6>
                                        </Col>
                                        <Col md="6">
                                        <div className="product-icon">
                                            <ul className="product-social">
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                                            </ul>
                                            <form className="d-inline-block f-right"></form>
                                        </div>
                                        </Col>
                                    </Row>
                                    <hr/> */}
                                    {/* <Row>
                                        <Col md="6">
                                        <h6 className="product-title">{"Rate Now"}</h6>
                                        </Col>
                                        <Col md="6">
                                        <div className="d-flex">
                                                <Ratings
                                                rating={rating}
                                                widgetRatedColors="blue"
                                                changeRating={changeRating}
                                                >
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                            </Ratings>
                                            <span>{ProductReview}</span>
                                        </div>
                                        </Col>
                                    </Row>
                                    <hr/> */}
                                     {/* {typeUser !== 'admin' ? 
                                    <div className="m-t-15 text-right">
                                        <Button  color="primary" className="m-r-10" onClick={() => addcart(singleItem, quantity)} >
                                            <i className="fa fa-shopping-basket mr-1"></i>{AddToCart}
                                        </Button>
                                        <Button  color="secondary" className="m-r-10" onClick={() => buyProduct(singleItem, quantity)}>
                                            <i className="fa fa-shopping-cart mr-1"></i>{BuyNow}
                                        </Button>
                                        {/* <Button color="secondary" onClick={() => addWishList(singleItem)}>
                                            <i className="fa fa-heart mr-1"></i>{"Add To WishList"}
                                        </Button> 
                                    </div> : <></>} */}
                                    </CardBody>
                                </Card>
                            </Col>
                      
                        </Row>
                    </Card>
                    {/* <Tablet /> */}
                    </Col>
                   </Row>
                   <Modal isOpen={listeUserModal} toggle={updatelisteUserToogle}>
                  <ModalBody>
                  <div className="table-responsive product-table">
                                <DataTable
                         
                                noTableHead="true"
                                    noHeader="true"
                                    columns={productColumns}
                                    data={userListe}
                                />
                            </div>
                  </ModalBody>
                </Modal>
                </Container>
            </Fragment>
    );
}
export default Newspage
