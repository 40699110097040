import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchCategoryApi, fetchSubCategoryApi } from "../../api";
import { WATCH_SUB_CATEGORY,GET_CATEGORY, GET_SUB_CATEGORY } from "../actionTypes";

function* fetchCategorieAsyn() {
    const Data = yield call(fetchSubCategoryApi);
    yield put({type : GET_SUB_CATEGORY ,category : Data});
}

export function* watchSubCategorieList() {
    yield takeLatest(WATCH_SUB_CATEGORY,fetchCategorieAsyn)
}
