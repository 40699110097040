import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, FormGroup, Progress, Nav, NavItem, NavLink, TabContent, TabPane,Media, } from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle } from 'react-feather';
import {Link, useParams} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {Issues,Resolved,Comment,Done,All,Doing,CreateNewProject} from '../../../constant'
import { WATCH_PROJET_LIST,WATCH_TACHE_LIST } from '../../../redux/actionTypes';
import CustomeBoard from './customeBoard';
import moment from 'moment';
import { fetchProjetByIdApi } from '../../../api';
const ProjectsDetails = (props) => {
  const {idprojet} =  useParams()
  const dispatch = useDispatch()
  const [activeTab,setActiveTab] = useState("1")
  const [projet, setprojet] = useState({});
  useEffect(() => {
    fetchProjetByIdApi(idprojet).then(res => {
      if(res !== undefined) 
      setprojet(res)
   
      
        })
 
  dispatch({type : WATCH_TACHE_LIST ,id : idprojet})
}, [])
  return (
    <Fragment>
      <Breadcrumb parent="Projets" title="Détail projet" />
      <Container fluid={true} className="jkanban-container">
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                 
                </Col>
                <Col sm="6">
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/app/projet/add-tache/${idprojet}`}> <PlusCircle />{"Ajouter une Tâche"}</Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md="12" >
        <div className="product-grid">
          <div className="feature-products">
            <Row>
            <Col xl="12 box-col-12 xl-100">
                        <Card>
                            <div className="blog-box blog-shadow">
                                <Media className="img-fluid" style={{width : '100%', height: 300,backgroundSize : 'cover'}} src={projet.marqueImage} alt="" />
                                <div className="blog-details" style={{padding : 9}}>

                                    <h4>{projet.description}</h4>
                                    <ul className="blog-social">
                                        <li><h2>{projet.title}</h2></li>
                                        <li className="digits"><i className="icofont icofont-institution"></i>Nom Enseigne : {projet.marqueName }</li>
                                        <li className="digits"><i className="icofont icofont-institution"></i>Nom Franchise : {projet.FranchiseName }</li>
                                        <li className="digits"><i className="icofont icofont-ui-chat"></i>  <p>{projet.managerprojetName ? 'Responsable : '+ projet.managerprojetName  : ""}</p></li> 
                                    </ul>
                                </div>
                        
                            </div>
                        </Card>
                    </Col>
                    </Row></div></div></Col>
          <Col xs="12">
                    <CustomeBoard />
                </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ProjectsDetails;