import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Modal, ModalBody, ModalHeader,Input, CardFooter, InputGroup, InputGroupAddon, Button, Media } from 'reactstrap'
import DatePicker, { registerLocale } from "react-datepicker";
import ApexCharts from 'react-apexcharts'
import defaultuser from '../../assets/images/user/user.png';
import { Currentlysale } from './chartsData/apex-charts-data'
import start_conversion from '../../assets/images/start-conversion.jpg';
import { Send, Clock, Search } from 'react-feather';
import {Dashboard,Summary,Chat,New,Tomorrow,Yesterday,Daily,Weekly,Monthly,ReferralEarning,CashBalance,SalesForcasting,Yearly,Today,RightNow, ActualitésListe, EventsListe, Turnover, StickyNote, AddNewNote} from '../../constant'
import { Link } from "react-router-dom";
import {WATCH_CHAT_SUCCESS, WATCH_ALL_SUBFRANCHISE_LIST, WATCH_EVENTS_LIST, WATCH_NEWS_LIST,WATCH_EVENTS_BY_MARQUE_LIST, WATCH_NEWS_BY_MARQUE_LIST, SEND_MESSAGE_WATCHER, WATCH_NOTE_LIST, WATCH_NEWS_BY_FOURNISSEUR_LIST, WATCH_EVENTS_BY_FOURNISSEUR_LIST } from '../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';


import moment from 'moment'
import imgwelcome from "../../assets/images/dashboard/welcome.png";
import imgwelcome2 from "../../assets/images/dashboard/welcome.jpeg";

import {  CarsouselFranchise2 } from './carouselFranchise';
import VerticalTimelineCompDashbord from '../application/actualites/timeLineActualiteDashbord';
import InventaireModal from './inventaireModal';
import { fetchInventairesDipensesApi, fetchSubfranchiseByidManagerApi,fetchInventairesDipensesByIdApi ,fetchInventairesmensulleDipensesByIdApi,fetchInventairesannualleDipensesByIdApi,fetchInventairesJouDipensesByIdApi} from '../../api';
import ConfigDB from '../../data/customizer/config';
import fr from "date-fns/locale/fr";
import { Picker } from 'emoji-mart';
import emoji from '../../assets/images/Unknown.png';
import SweetAlert from 'sweetalert2'
import axios from 'axios';
import AppSticky from './sticky';
import { urlbackend } from '../../data/config';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;


registerLocale("fr", fr);
const Default = (props) => {

  const [daytimes,setDayTimes] = useState()
  const today = new Date()
  const curHr = today.getHours()
  const curMi = today.getMinutes()
  const [meridiem,setMeridiem] = useState("AM")
    const [showInventaire, setshowInventaire] = useState(false);
    const [firstname, setfirstname] = useState(localStorage.getItem("@intranetFirstUser"));
    const [lastname, setlastname] = useState(localStorage.getItem("@intranetLastUser"));

    const [role, setrole] = useState(localStorage.getItem("@intranetTypeUser"));

    const [myfilee, setMyFile] = useState([])
    const [messageInput, setMessageInput] = useState('');
  // eslint-disable-next-line
  const [date, setDate] = useState({ date: new Date() });
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(new Date());
  const handleChange = date => {
    setDate(date)
  };
  const dispatch = useDispatch();
  const history = useHistory()
  const [profile,setProfile] = useState("")
  const [activeTab,setActiveTab] = useState("1")
  const [random,setrandom] = useState(1)
  const [revenueanuelle,setrevenueanuelle] = useState(0)
  const [revenuemensuelle,setrevenuemensulle] = useState(0)
  const [revenuejournalier,setrevenuejournalier] = useState(0)
  const [dipenseanuelle,setdipenseanuelle] = useState(0)
  const [dipensemensuelle,setdipensemensulle] = useState(0)
  const [dipensejournalier,setdipensejournalier] = useState(0)

  const allMessages = useSelector(content => content.ChatApp.allMessages);
  const [activetab,setActivetab] = useState("journalier")

  const [selecterSenderId, setselecterSenderId] = useState('0');
  const [lastconvUserName, setlastconvUserName] = useState({});
  const [lastconvUserImage, setlastconvUserImage] = useState([]);
    
  const [selectedMessageID, setselectedMessageID] = useState("")
     
  const [manegeMessageModal, setmanegeMessageModal] = useState(false)
  const manegeMessageToggle = () => { setmanegeMessageModal(!manegeMessageModal) } 
  const [clientX, setclientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [listeDayInventaire,setlisteDayInventaire] = useState([])
  const [listemonthInventaire,setlisteMonthInventaire] = useState({monthcat : [],dipenseVal : [],gainVal : []})
  const [listeAnnuaireInventaire,setlisteAnnuaireInventaire] = useState({yearcat : [],dipenseVal : [],gainVal : []})
  
  const [searchText, setSearchText] = useState("")
  const [journalierchartstate, setjournalierchartstate] = useState({
    datadip : [],datagain : [],datacat :[]
  })


  const news = useSelector(content => content.Newsapp.news);
  const [userImage, setuserImage] = useState(localStorage.getItem("@intranetimageUser").length >10 ? localStorage.getItem("@intranetimageUser") : defaultuser);
  const events = useSelector(content => content.Eventsapp.events);
  const inventairedipenses = useSelector(content => content.InventaireDipense.inventairedipenses);
  const subfranchises = useSelector(content => content.SubFranchiseapp.subfranchises);
  const chats = useSelector(content => content.ChatApp.chats);
  const [showEmojiPicker,setShowEmojiPicker] = useState(false) 

  //const doingProject = useSelector(content => content.Franchiseapp.doing_Project);

  //const doneProject = useSelector(content => content.Franchiseapp.done_Project);
  const [idUser, setidUser] = useState(localStorage.getItem("@intranetidUser"));
  const [typeUser, settypeUser] = useState("");
  const [loading, setloading] = useState(true);
  const notes = useSelector(content => content.NotesApp.notes);

  useEffect(() => {
    dispatch({type : WATCH_NOTE_LIST})
  
    dispatch({type : WATCH_ALL_SUBFRANCHISE_LIST})

    if(localStorage.getItem("@intranetTypeUser") === "submanager"){
 
    fetchSubfranchiseByidManagerApi().then(res => {
      console.log(res)
      if(res.subfranchise_id != undefined){
        getInventairelisteByIdFranchise(res.subfranchise_id)
        let lastdate = moment(res.lastDateInventaire||res.launch_date).format("YYYYMMDD")
   
        let lastday = moment(res.lastDateInventaire||res.launch_date).format("DD")
        let lastmonth =  moment(res.lastDateInventaire || res.launch_date).format("MM")
        let lastyear = moment(res.lastDateInventaire||res.launch_date).format("YYYY")
        localStorage.setItem("@intranetIdFranchiseUser",res.subfranchise_id)
        localStorage.setItem("@intranetIdFranchiseDateInventaire", moment(res.lastDateInventaire||res.launch_date).format("YYYYMMDD"))

if(Number(lastdate) < Number(moment(new Date()).format("YYYYMMDD")))
      setshowInventaire(true)

//         fetchInventairesDipensesApi().then(resinv => {


// let listeDayInventairCopy = listeDayInventaire
// resinv.forEach(element => {
//   listeDayInventairCopy.push(element)
// });
// setlisteDayInventaire( listeDayInventairCopy)
// setrandom(Math.random())




    //  })
      //  dispatch({type: WATCH_INVENTAIREDIPENSE_LIST})
       
      
    }
    })
    }
    setidUser(localStorage.getItem("@intranetidUser"))
    settypeUser(localStorage.getItem("@intranetTypeUser"))
  
    setProfile(localStorage.getItem('profileURL') || imgwelcome);
    if( localStorage.getItem("@intranetTypeUser") === 'admin'){
      dispatch({type: WATCH_EVENTS_LIST})
    dispatch({type: WATCH_NEWS_LIST})
    }
    else if (localStorage.getItem("@intranetTypeUser") === 'Fournisseur'){
  
      dispatch({type: WATCH_EVENTS_BY_FOURNISSEUR_LIST})
      dispatch({type: WATCH_NEWS_BY_FOURNISSEUR_LIST})
    }
  
    
    else  if (localStorage.getItem("@intranetTypeUser") === 'manager' || localStorage.getItem("@intranetTypeUser") === 'submanager'){
        dispatch({type: WATCH_NEWS_BY_MARQUE_LIST})
    dispatch({type: WATCH_EVENTS_BY_MARQUE_LIST})

    }
 
    if (curHr < 12) {
      setDayTimes('Bonjour')
    }else if (curHr < 18) {
      setDayTimes('Bon aprés-midi')
    }else {
      setDayTimes('Bonsoir')
    }

    if(curHr >= 12){
     setMeridiem('PM')
    }else{
      setMeridiem('AM')
    }
   
    
    

   
if(allMessages.length > 0){
  setlastconvUserName(allMessages[0])
   dispatch({type : WATCH_CHAT_SUCCESS, id : allMessages[0]})
          localStorage.setItem("@intranetidConvMessage",allMessages[0].id);
         
  }
       
setTimeout(() => {

setloading(false)
}, 5000);
  //   var ordervalue1 = Knob({
  //     value: 60,
  //     angleOffset: 0,
  //     thickness: 0.3,
  //     width: 65,
  //     fgColor: "#7366ff",
  //     readOnly: false,
  //     dynamicDraw: true,
  //     tickColorizeValues: true,
  //     bgColor: '#eef5fb',
  //     lineCap: 'round',
  //     displayPrevious: false
  //   })
  //  // document.getElementById('ordervalue1').appendChild(ordervalue1);

  //   var ordervalue2 = Knob({
  //     value: 60,
  //     angleOffset: 0,
  //     thickness: 0.3,
  //     fgColor: "#7366ff",
  //     readOnly: false,
  //     width: 65,
  //     dynamicDraw: true,
  //     lineCap: 'round',
  //     displayPrevious: false
  //   })
  //   document.getElementById('ordervalue2').appendChild(ordervalue2);

    // eslint-disable-next-line
  }, [])
const getInventairelisteByIdFranchise = (idSubfranchise) => {
  let now =new Date()
  let lastdate = moment(now).format("YYYYMM")
  let year = now.getFullYear()
  fetchInventairesJouDipensesByIdApi(idSubfranchise,lastdate,year).then(res => {
    console.log(res)

let journaliercharts = {}
let journalierchartsgain = []


let inventaireuser = res
if(inventaireuser.length > 0){

  let datadip = []
  let datagain = []
  let datacat = []
  let toatlrevenue = 0
  let toatldipense = 0

  inventaireuser?.map((daysinver,idaysinventaire) => {
datadip.push(Number(daysinver.dipense.replace(',', '')))
datagain.push(Number(daysinver.gain.replace(',', '')))
datacat.push(daysinver.date)
toatlrevenue +=Number(daysinver.gain.replace(',', ''))
toatldipense +=Number(daysinver.dipense.replace(',', ''))
  })
  setrevenuejournalier(toatlrevenue)
  setdipensejournalier(toatldipense)
  setjournalierchartstate({
    datadip : datadip,datagain : datagain,datacat :datacat
  })

}
else {
  setrevenuejournalier(0)
  setdipensejournalier(0)
  setjournalierchartstate({
    datadip : [],datagain : [],datacat :[]
  })
}
})
fetchInventairesmensulleDipensesByIdApi(idSubfranchise,year).then(res => {
  console.log(res)
let mensuellechartscat = []
let mensuellechartsdipense = []
let mensuellechartsgain = []

let toatlrevenuemen = 0
let toatldipensemen = 0
res.map((inventaire ,index) =>{
  toatlrevenuemen = +inventaire.gain
  toatldipensemen = +inventaire.dipense
mensuellechartscat.push(inventaire.monthName)
mensuellechartsdipense.push(inventaire.dipense)
mensuellechartsgain.push(inventaire.gain)

})
setrevenuemensulle(toatlrevenuemen)
setdipensemensulle(toatldipensemen)


  setlisteMonthInventaire({monthcat : mensuellechartscat,dipenseVal : mensuellechartsdipense,gainVal : mensuellechartsgain})
  })
  fetchInventairesannualleDipensesByIdApi(idSubfranchise).then(res => {
    console.log(res)

  
  let annuellechartscat = []
  let annuellechartsdipense = []
  let annuellechartsgain = []
     let toatlrevenueann = 0
    let toatldipenseann = 0
    res.map((inventaire ,index) => {
      toatlrevenueann = +inventaire.gain
      toatldipenseann = +inventaire.dipense
  
          annuellechartscat.push(inventaire.year)
          annuellechartsdipense.push({year : inventaire.year , totaldipense : inventaire.dipense})
          annuellechartsgain.push({year : inventaire.year , totalgain : inventaire.gain})
        })
    
      
    
      setrevenueanuelle(toatlrevenueann)
      setdipenseanuelle(toatldipenseann)
      setlisteAnnuaireInventaire({yearcat : annuellechartscat,dipenseVal : annuellechartsdipense,gainVal : annuellechartsgain})
  })
}
const handleMessageChange = (message) => {
  setMessageInput(message)
}
const handleMessagePress = (e ) => {
  if (e.key === "Enter" || e === "send") {

      var container = document.querySelector(".chat-history");
      setTimeout(function () {
          container.scrollBy({ top: 200, behavior: 'smooth' });
      }, 310)

      let currentUserId = idUser;
      let selectedConvId = localStorage.getItem("@intranetidConvMessage");
      let currentUserName = firstname + " "+lastname;
      let currentUserImage = userImage;
     
      if (messageInput.length > 0 || myfilee.length > 0) {

          dispatch({type : SEND_MESSAGE_WATCHER, currentUserId,currentUserName,currentUserImage, selectedConvId, messageInput, chats, online : true, files : myfilee})
       
          setMessageInput('');
          setMyFile([])
          setTimeout(() => {
              const replyMessage = "Hey This is " + currentUserName + ", Sorry I busy right now, I will text you later";
              // if (selectedUser.online === true)
              //     document.querySelector(".status-circle").classList.add('online');
              //     selectedUser.online = true;
                //  dispatch({type : REPLY_MESSAGE_WATCHER,currentUserId, selectedConvId, replyMessage, chats, online})
          }, 5000);
      }
  }
}
const toggleEmojiPicker = () => {
  setShowEmojiPicker(!showEmojiPicker);
}
const addEmoji = (emoji) =>  {
  const text = `${messageInput}${emoji.native}`;
  setShowEmojiPicker(false);
  setMessageInput(text)
}
const removefile = (name) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
     let rescop =  myfilee.filter(res => res.name !== name)
     setMyFile(rescop)
     setrandom(Math.random())
      SweetAlert.fire(
        'Supprimé!',
        'Votre fichier a été supprimée',
        'success'
      )
     
   
     
    }
    else {
    
    }
  })
}

  return (
    <Fragment>
      <Breadcrumb parent="Tableau de bord" title="Accueil" />
      <Container fluid={true}>
      <Row className="second-chart-list third-news-update">
          <Col xl="6" lg="12" md="12"  className="morning-sec box-col-4">
            <Card  className="o-hidden profile-greeting" style={{backgroundImage : `url(${imgwelcome2})`, height: '94%'}}>
              <CardBody>
                <div className="media">
                  <div className="badge-groups w-100">
                    <div className="badge f-12">
                      <Clock style={{width:"16px" ,height:"16px"}} className="mr-1"/>
                      <span id="txt">{curHr}:{curMi < 10 ? "0"+curMi :curMi} {meridiem}</span>
                    </div>
                    {/* <div className="badge f-12"><i className="fa fa-spin fa-cog f-14"></i></div> */}
                  </div>
                </div>
                <div className="greeting-user text-center">
                  <div className="profile-vector"><img className="img-fluid user-image" style={{borderRadius : 50,width : 70,height: 70}} width="70" height="70" src={userImage} alt="" /></div>
                  <h4 className="f-w-600"><span id="greeting">{daytimes}</span> <span className="right-circle"><i className="fa fa-check-circle f-14 middle"></i></span></h4>
         
                  <p><span> {localStorage.getItem("@intranetLastUser") + " " + localStorage.getItem("@intranetFirstUser") }</span></p>
                 {localStorage.getItem("@intranetTypeUser") !== 'Fournisseur' ?  <div className="whatsnew-btn"><Link to={`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate/`} className="btn btn-primary">{"Nouveautés"}</Link></div> : ""}
                  {/* <div className="left-icon"><i className="fa fa-bell"> </i></div> */}
                </div>
              </CardBody>
            </Card>
           
          </Col>
          <Col xl="6" lg="12" md="12" className="morning-sec box-col-4">
          <Card style={{ height: '94%'}}>
                
                
                
                  <AppSticky notes = {notes} />
              
               
          </Card>
          {/* */}
            </Col>
          
          </Row>
          <Row className="second-chart-list third-news-update">
          <Col xl="6" lg="12" md="12" >
      
  
      <Card >
  
  <CardHeader>
      <h6>{ActualitésListe}</h6>
  </CardHeader>
  <CardBody style={{height: '1760px', overflow : 'auto'}}>
      <VerticalTimelineCompDashbord news={news} />
  </CardBody>
  <div className="card-footer" style={{height: 40, padding : 8}}>
                <div className="bottom-btn"><Link style={{fontSize : 12}} to={`${process.env.PUBLIC_URL}/app/actualites/actualitesliste`} >{"Voir plus..."}</Link></div>
              </div>
</Card> 

    </Col>

      
    <Col xl="6" lg="12" md="12" >
     
  
     <Card>
           <CardHeader >
             <div className="header-top">
                   <h6 className="m-0">{EventsListe}</h6>
              
             </div>
           </CardHeader>
           <CardBody  style={{height: localStorage.getItem("@intranetTypeUser") === "admin" ? "230px" : "230px", overflow : 'auto'}}>
            
             {events?.filter(res => moment(res.start).isSameOrBefore(new Date()) && moment(res.end).isSameOrAfter(new Date())).sort((a,b) => new Date(a.start) < new Date(b.start)).map((event, index) => 
            <div className="media">
              <div className="media-body">
                {new Date(event.creation_date.slice(0,10)).setHours(0,0).toString() === new Date(moment(new Date()).format("yyyy-MM-DD")).setHours(0).toString() ? 
              <p style={{color : "#f73164"}} >{moment(event.start).format("DD-MM-YYYY")} <span className="badge badge-secondary">{New}</span></p>
              :
                <p style={{color : "#f73164"}} >{moment(event.start).format("DD-MM-YYYY")} <span></span></p>}
                <h6>{event.title}<span className="dot-notification"></span></h6><span><div dangerouslySetInnerHTML={{ __html: event.description }} /></span>
                <div className="d-flex mb-3">
                  {event.piecesJoint.map((file , j) => 
                    <div className="inner-img"><img className="img-fluid" src={`${urlbackend}${file.url}`} alt="Product-1" /></div>
                  )}
                
                
                </div>
              </div>
            </div>
              )}
           
           
      
           </CardBody>
           <div className="card-footer" style={{height: 40, padding : 8}}>
            <div className="bottom-btn"><Link style={{fontSize : 12}} to={`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate`} >{"Voir plus..."}</Link></div>
          </div>
           </Card>
     
      
        {localStorage.getItem("@intranetTypeUser") === "admin" ?

     
      
        <Card>
                        <CardHeader>
                        <div className="header-top">
                            <h6>{"Liste Franchises"}</h6>
                            </div>
                        </CardHeader >
                        <CardBody style={{height: '300px'}}>
                             <CarsouselFranchise2 /> 
                        </CardBody>
                        <div className="card-footer" style={{height: 40, padding : 8,fontSize: 10}}>
            <div className="bottom-btn"><Link style={{fontSize : 12}} to={`${process.env.PUBLIC_URL}/app/franchises/franchiseList`} >{"Voir plus..."}</Link></div>
          </div>
                    </Card>
      
            
              :<></>}
            
      <Card className="gradient-primary o-hidden">
          <CardBody>
            <div className="default-datepicker">
              <DatePicker
              
             locale='fr'
                selected={startDate}
                onChange={handleChange}
                inline
              />
            </div><span className="default-dots-stay overview-dots full-width-dots"><span className="dots-group"><span className="dots dots1"></span><span className="dots dots2 dot-small"></span><span className="dots dots3 dot-small"></span><span className="dots dots4 dot-medium"></span><span className="dots dots5 dot-small"></span><span className="dots dots6 dot-small"></span><span className="dots dots7 dot-small-semi"></span><span className="dots dots8 dot-small-semi"></span><span className="dots dots9 dot-small">                </span></span></span>
          </CardBody>
        </Card> 
        
      
               <Card className="chat-default" >
          <CardHeader className="card-no-border">
            <div className="media media-dashboard">
              <div className="media-body">
             
           
                  
<div className="about">

                        <div style={{fontSize: 17}} className="name"> <img src={lastconvUserName?.usersInfos?.length > 2 ?  lastconvUserName?.usersInfos[0]?.avatar : lastconvUserName?.usersInfos?.find(res=> res.id !== idUser)?.avatar} height={50} width={50} className="rounded-circle user-image" alt="" />  {lastconvUserName?.users?.length > 2 ?  lastconvUserName?.groupeName : lastconvUserName?.usersInfos?.find(res=> res.id !== idUser)?.fullname}</div>
                        <div className="status">
                      
                        </div>
                    </div>
            
              </div>
              {/* <div className="icon-box"><i className="fa fa-ellipsis-h"></i></div> */}
            </div>
          </CardHeader>
          <CardBody className="chat-box custom-scrollbar" >
                        <div className="chat custom-scrollbar" style={{overflow: 'auto',
height: '230px'}}>
                            {chats.length > 0 ? chats.map((item, index) => {
                                 //const participators = allMessages.find(x => x.id === selectedChat);
                                return (<>
                               { item.sender !== idUser ?
                               <>
                                 {item.message.length > 0 ?
                                  <div className="media left-side-chat">
                                  <div className="media-body d-flex">
                                    <div className="img-profile"> <img        className="rounded-circle  user-image" src={item.senderUserImage} alt="Profile" /></div>
                                    <div className="main-chat">
                                      <div className="message-main"><span className="mb-0">{item.message}</span></div>
                                      <div className="sub-message message-main"><span className="mb-0">{item.message}</span></div>
                                    </div>
                                  </div>
                                  <p className="f-w-400">{item.time}</p>
                                </div> : ""}
                                {item.files?.length > 0 ?
                                    item.files?.map((data,i)=>{
                                      return(
                                        <div onContextMenu={e => {
     
                                          e.preventDefault();
                                  
                                if(e.button === 2){
                                  setselecterSenderId(item.sender)
                                  
                                setselectedMessageID(item.id)
                                  setclientX(e.clientX)
                                  setClientY(e.clientY)
                                  manegeMessageToggle()
                                
                              
                              
                                }}}  key={index}  className="media left-side-chat">

    
<div className="media-body d-flex">
    
{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   style={{width : '200px'}} height="200" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}
</div>

 </div> )
})
: <></> }
                                </>
                                : <>
                                {item.message.length > 0 ?
                                <div onContextMenu={e => {
     
                                                                              e.preventDefault();
                                                                      
                                                                    if(e.button === 2){
                                                                      setselecterSenderId(item.sender)
                                                                      
                                                                    setselectedMessageID(item.id)
                                                                      setclientX(e.clientX)
                                                                      setClientY(e.clientY)
                                                                      manegeMessageToggle()
                                                                    
                                                                  
                                                                  
                                                                    }}}  key={index}  className="media right-side-chat">
                <p className="f-w-400">{item.time}</p>
                <div className="media-body text-right">
                  <div className="message-main pull-right"><span  className="mb-0 text-left" style={{opacity : '100%'}}>{item.message}</span>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div> : ""}
              {item.files?.length > 0 ?
                                    item.files?.map((data,i)=>{
                                      return(
                                        <div onContextMenu={e => {
     
                                          e.preventDefault();
                                  
                                if(e.button === 2){
                                  setselecterSenderId(item.sender)
                                  
                                setselectedMessageID(item.id)
                                  setclientX(e.clientX)
                                  setClientY(e.clientY)
                                  manegeMessageToggle()
                                
                              
                              
                                }}}  key={index}  className="media right-side-chat">

    
<div className="media-body text-right">
    
{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   style={{width : '200px'}} height="200" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}
</div>

 </div> )
})
: <></> }
              </>
              }
                                </>

                                );
                            }) :
                           (
                              loading === true ? 
                                 <div>
                                    
                                     <div className="loader-box">
                     <div className="loader-2"></div>
                   </div>
                                
                             
                                   
                                 </div> :  <div class="chat-history chat-msg-box custom-scrollbar"><ul><div><img src={start_conversion} alt="Nouvelle conversation " class="img-fluid media"/></div></ul></div>
                            )
                            
                             }
                            </div>
                            <div className="chat-message clearfix">
                            <Row>
                        <div className="mb-2">
                        {showEmojiPicker ? (
                                <Picker set="apple" emojiSize={30} onSelect={addEmoji} />
                         ) : null}
                        </div>
                          <Col xl="12" className="d-flex">
                         

                            <InputGroup className="text-box">
                            <InputGroupAddon addonType="append">
                            <div className="smiley-box bg-primary" style={{padding: '5px', height : 40, borderRadius: 5}}>
                            
                            <div className="picker" onClick={() => toggleEmojiPicker()}>
                            <Media width="30" height="30" src={emoji}  alt=""/>
                            </div>

                        </div>
                                </InputGroupAddon>
                                <Input
                                    type="text"
                                    className="form-control input-txt-bx"
                                    placeholder="Ecrire message......"
                                    value={messageInput}
                                    onKeyPress={(e) => handleMessagePress(e)}
                                    onChange={(e) => handleMessageChange(e.target.value)}
                                />
                               
                               <InputGroupAddon addonType="append">
                                    <Button color="primary" onClick={() => handleMessagePress('send')} >{"Envoyer"}</Button>
                                </InputGroupAddon>
                            </InputGroup>
                            
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                          <ul className="files">
                        { myfilee.map((data,i)=>{
return(
  <li    onDoubleClick={() => {
     
  //   setselectedImage(data)
    //  showApercuToggle()
      }} className="file-box" key={i}>


    <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="70" height="70" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
    <div className="file-bottom">
    <i className="fa fa-trash" onClick={() => removefile(data.name)}></i>

    </div>
 
         
  </li>
)
})
}
</ul>
                          </Col>
                        </Row>
                      </div>
                            </CardBody>
                            <div className="card-footer" style={{height: 40, padding : 8,fontSize: 10}}>
            <div className="bottom-btn"><Link style={{fontSize : 12}} to={`${process.env.PUBLIC_URL}/app/chat-app`} >{"Voir plus des messages..."}</Link></div>
          </div>
        </Card>
   
    </Col>
    </Row>
          {/* <Row className="second-chart-list third-news-update">
     
          <Col xl="6" lg="12" md="12" className="morning-sec box-col-4">
     
  
         <Card>
               <CardHeader >
                 <div className="header-top">
                       <h6 className="m-0">{EventsListe}</h6>
                  
                 </div>
               </CardHeader>
               <CardBody  style={{height: localStorage.getItem("@intranetTypeUser") === "admin" ? "200px" : "350px", overflow : 'auto'}}>
                
                 {events?.filter(res => new Date(res.start) >= new Date()).sort((a,b) => new Date(a.start) < new Date(b.start)).map((event, index) => 
                <div className="media">
                  <div className="media-body">
                    { new Date(event.creationDate.slice(0,10)).setHours(0,0).toString() === new Date(moment(new Date()).format("yyyy-MM-DD")).setHours(0).toString() ? 
                  <p style={{color : "#f73164"}} >{moment(event.start).format("DD-MM-YYYY")} <span className="badge badge-secondary">{New}</span></p>
                  :
                    <p style={{color : "#f73164"}} >{moment(event.start).format("DD-MM-YYYY")} <span></span></p>}
                    <h6>{event.title}<span className="dot-notification"></span></h6><span><div dangerouslySetInnerHTML={{ __html: event.description }} /></span>
                    <div className="d-flex mb-3">
                      {event.piecesJoint.map((file , j) => 
                        <div className="inner-img"><img className="img-fluid" src={file.url} alt="Product-1" /></div>
                      )}
                    
                    
                    </div>
                  </div>
                </div>
                  )}
               
               
          
               </CardBody>
               <div className="card-footer" style={{height: 40, padding : 8}}>
                <div className="bottom-btn"><Link style={{fontSize : 12}} to={`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate`} >{"Voir plus..."}</Link></div>
              </div>
               </Card>
         
            </Col>
            {localStorage.getItem("@intranetTypeUser") === "admin" ?
            <Col xl="6" lg="12" md="12" className="morning-sec box-col-4">
         
          
            <Card>
                            <CardHeader>
                            <div className="header-top">
                                <h6>{"Liste Franchises"}</h6>
                                </div>
                            </CardHeader >
                            <CardBody style={{height: '190px'}}>
                                 <CarsouselFranchise2 /> 
                            </CardBody>
                            <div className="card-footer" style={{height: 40, padding : 8,fontSize: 10}}>
                <div className="bottom-btn"><Link style={{fontSize : 12}} to={`${process.env.PUBLIC_URL}/app/franchises/franchiseList`} >{"Voir plus..."}</Link></div>
              </div>
                        </Card>
          
                
                  </Col>  :<></>}
                 
          <Col xl="6" lg="12" md="12" className="morning-sec box-col-4">
          <Card className="gradient-primary o-hidden">
              <CardBody>
                <div className="default-datepicker">
                  <DatePicker
                  
                 locale='fr'
                    selected={startDate}
                    onChange={handleChange}
                    inline
                  />
                </div><span className="default-dots-stay overview-dots full-width-dots"><span className="dots-group"><span className="dots dots1"></span><span className="dots dots2 dot-small"></span><span className="dots dots3 dot-small"></span><span className="dots dots4 dot-medium"></span><span className="dots dots5 dot-small"></span><span className="dots dots6 dot-small"></span><span className="dots dots7 dot-small-semi"></span><span className="dots dots8 dot-small-semi"></span><span className="dots dots9 dot-small">                </span></span></span>
              </CardBody>
            </Card> 
            </Col>
            <Col  xl={  localStorage.getItem("@intranetTypeUser") === "admin" ? "6" : "12"} lg="12" md="12" className="morning-sec box-col-4">
                   <Card className="chat-default" style={{height : '94%'}}>
              <CardHeader className="card-no-border">
                <div className="media media-dashboard">
                  <div className="media-body">
                 
               
                      
<div className="about">

                            <div style={{fontSize: 17}} className="name"> <img src={lastconvUserName?.usersInfos?.length > 2 ?  lastconvUserName?.usersInfos[0]?.avatar : lastconvUserName?.usersInfos?.find(res=> res.id !== idUser)?.avatar} height={50} width={50} className="rounded-circle user-image" alt="" />  {lastconvUserName?.users?.length > 2 ?  lastconvUserName?.groupeName : lastconvUserName?.usersInfos?.find(res=> res.id !== idUser)?.fullname}</div>
                            <div className="status">
                          
                            </div>
                        </div>
                
                  </div>
             
                </div>
              </CardHeader>
              <CardBody className="chat-box custom-scrollbar" >
                            <div className="chat custom-scrollbar" style={{overflow: 'auto',
height: '230px'}}>
                                {chats.length > 0 ? chats.map((item, index) => {
                                     //const participators = allMessages.find(x => x.id === selectedChat);
                                    return (<>
                                   { item.sender !== idUser ?
                                   <>
                                     {item.message.length > 0 ?
                                      <div className="media left-side-chat">
                                      <div className="media-body d-flex">
                                        <div className="img-profile"> <img className="img-fluid img-30" src={item.senderUserImage} alt="Profile" /></div>
                                        <div className="main-chat">
                                          <div className="message-main"><span className="mb-0">{item.message}</span></div>
                                          <div className="sub-message message-main"><span className="mb-0">{item.message}</span></div>
                                        </div>
                                      </div>
                                      <p className="f-w-400">{item.time}</p>
                                    </div> : ""}
                                    {item.files?.length > 0 ?
                                        item.files?.map((data,i)=>{
                                          return(
                                            <div onContextMenu={e => {
         
                                              e.preventDefault();
                                      
                                    if(e.button === 2){
                                      setselecterSenderId(item.sender)
                                      
                                    setselectedMessageID(item.id)
                                      setclientX(e.clientX)
                                      setClientY(e.clientY)
                                      manegeMessageToggle()
                                    
                                  
                                  
                                    }}}  key={index}  className="media left-side-chat">
  
        
  <div className="media-body d-flex">
        
{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   style={{width : '200px'}} height="200" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}
</div>

     </div> )
})
: <></> }
                                    </>
                                    : <>
                                    {item.message.length > 0 ?
                                    <div onContextMenu={e => {
         
                                                                                  e.preventDefault();
                                                                          
                                                                        if(e.button === 2){
                                                                          setselecterSenderId(item.sender)
                                                                          
                                                                        setselectedMessageID(item.id)
                                                                          setclientX(e.clientX)
                                                                          setClientY(e.clientY)
                                                                          manegeMessageToggle()
                                                                        
                                                                      
                                                                      
                                                                        }}}  key={index}  className="media right-side-chat">
                    <p className="f-w-400">{item.time}</p>
                    <div className="media-body text-right">
                      <div className="message-main pull-right"><span  className="mb-0 text-left" style={{opacity : '100%'}}>{item.message}</span>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div> : ""}
                  {item.files?.length > 0 ?
                                        item.files?.map((data,i)=>{
                                          return(
                                            <div onContextMenu={e => {
         
                                              e.preventDefault();
                                      
                                    if(e.button === 2){
                                      setselecterSenderId(item.sender)
                                      
                                    setselectedMessageID(item.id)
                                      setclientX(e.clientX)
                                      setClientY(e.clientY)
                                      manegeMessageToggle()
                                    
                                  
                                  
                                    }}}  key={index}  className="media right-side-chat">
  
        
  <div className="media-body text-right">
        
{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   style={{width : '200px'}} height="200" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}
</div>

     </div> )
})
: <></> }
                  </>
                  }
                                    </>

                                    );
                                }) :
                               (
                                  loading === true ? 
                                     <div>
                                        
                                         <div className="loader-box">
                         <div className="loader-2"></div>
                       </div>
                                    
                                 
                                       
                                     </div> :  <div class="chat-history chat-msg-box custom-scrollbar"><ul><div><img src={start_conversion} alt="Nouvelle conversation " class="img-fluid media"/></div></ul></div>
                                )
                                
                                 }
                                </div>
                                <div className="chat-message clearfix">
                                <Row>
                            <div className="mb-2">
                            {showEmojiPicker ? (
                                    <Picker set="apple" emojiSize={30} onSelect={addEmoji} />
                             ) : null}
                            </div>
                              <Col xl="12" className="d-flex">
                             

                                <InputGroup className="text-box">
                                <InputGroupAddon addonType="append">
                                <div className="smiley-box bg-primary" style={{padding: '5px', height : 40, borderRadius: 5}}>
                                
                                <div className="picker" onClick={() => toggleEmojiPicker()}>
                                <Media width="30" height="30" src={emoji}  alt=""/>
                                </div>

                            </div>
                                    </InputGroupAddon>
                                    <Input
                                        type="text"
                                        className="form-control input-txt-bx"
                                        placeholder="Ecrire message......"
                                        value={messageInput}
                                        onKeyPress={(e) => handleMessagePress(e)}
                                        onChange={(e) => handleMessageChange(e.target.value)}
                                    />
                                   
                                   <InputGroupAddon addonType="append">
                                        <Button color="primary" onClick={() => handleMessagePress('send')} >{"Envoyer"}</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                              <ul className="files">
                            { myfilee.map((data,i)=>{
    return(
      <li    onDoubleClick={() => {
         
      //   setselectedImage(data)
        //  showApercuToggle()
          }} className="file-box" key={i}>


        <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="70" height="70" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
        <div className="file-bottom">
        <i className="fa fa-trash" onClick={() => removefile(data.name)}></i>

        </div>
     
             
      </li>
    )
  })
}
</ul>
                              </Col>
                            </Row>
                          </div>
                                </CardBody>
                                <div className="card-footer" style={{height: 40, padding : 8,fontSize: 10}}>
                <div className="bottom-btn"><Link style={{fontSize : 12}} to={`${process.env.PUBLIC_URL}/app/chat-app`} >{"Voir plus des messages..."}</Link></div>
              </div>
            </Card>
          </Col>
          </Row> */}
       
        <Row className="second-chart-list third-news-update">
        {localStorage.getItem("@intranetTypeUser") !== 'Fournisseur' ?
          <Col xl="12" lg="12" md="12" className="calendar-sec box-col-8">
         
          <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  {/* <Col xl="3" className="earning-content p-0">
                    <Row className="m-0" style={{paddingTop: 40, paddingLeft : 10}}>
                      <Col xl="12" className="p-0 left_side_earning">
                        <ul>
                        <li style={{marginBottom : 10}}>
                        <div className="faq-form">
                                                <Input  value={searchText} onChange={(e) => setSearchText(e.target.value)} className="form-control" type="text" placeholder="Rechercher.." />
                                                <Search className="search-icon" />
                                            </div>
                                            </li>
                                            {subfranchises.filter(res => res.FranchiseName.includes(searchText) === true).map((subfranchise ,index) => 
                                                                <li onClick={() => getInventairelisteByIdFranchise(subfranchise.id)} >
                                                                <div className="media"><img className="img-fluid rounded-circle mr-1" style={{width : 50,height : 20,contain:'content'}} src={subfranchise.franchiseimage} alt="" />
                                                                
                                                                  <div className="media-body"><span>{subfranchise.FranchiseName}</span>
                                                                 <p>{subfranchise.ville}</p> 
                                                                  </div>
                                                                
                                                                </div>
                                                                </li>
                                            )}


</ul>
                      </Col>
                     
                    </Row>
                  </Col> */}
                  <Col xl="12" className="p-0">
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                          <div className="inner-top-left">
                            <ul className="d-flex list-unstyled">
                              <li onClick={() => setActivetab('journalier')} className={ activetab === 'journalier' ? 'active' : ''}>{Daily}</li>
                            
                              <li onClick={() => setActivetab('mensuelle')} className={ activetab === 'mensuelle' ? 'active' : ''}>{Monthly}</li>
                              <li onClick={() => setActivetab('annuelle')} className={ activetab === 'annuelle' ? 'active' : ''}>{Yearly}</li>
                              { localStorage.getItem("@intranetTypeUser") !== "submanager"  ?  <li  > <div className="header-top">
                    
                    <div className="card-header-right-icon">
                      <select onChange={(e) =>  e.target.value !== 0 ? getInventairelisteByIdFranchise(e.target.value) : ""} className="button btn btn-primary">
                        <option value="0">Choisir Franchise</option>
                      {subfranchises?.map((subfranchise ,index) => 

<option value={subfranchise.subfranchise_id}  >{subfranchise.franchise_name}</option>
                                                                
                                            )}
                       
                      </select>
                    </div>
                  </div></li> : ""}
                            </ul>
                          </div>
                        </Col>
                        {/* <Col xl="4" md="12" sm="12" className="col-12 p-0">
                 <div className="header-top">
                    
                   <div className="card-header-right-icon">
                     <select className="button btn btn-primary">
                       <option>{Today}</option>
                       <option>{Tomorrow}</option>
                       <option>{Yesterday}</option>
                     </select>
                   </div>
                 </div>
                 </Col> */}
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                            {activetab === 'journalier' ? 
                              <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: true,
      categories: journalierchartstate.datacat,
      labels: {
        low: 0,
        offsetX: 0,
        show: true,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 15,
        right: 0,
        bottom: 0,
        top: -20
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépenses',
    data: journalierchartstate.datadip
  }, {
    name: 'Gains',
    data: journalierchartstate.datagain
  }]} type='area' height={240} /> 
  : activetab === 'hebdomadaire' ?  <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: Turnover,
      categories: journalierchartstate.datacat,
      labels: {
        low: 0,
        offsetX: 0,
        show: true,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 15,
        right: 0,
        bottom: 0,
        top: -20
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépenses',
    data: journalierchartstate.datadip
  }, {
    name: 'Gains',
    data: journalierchartstate.datagain
  }]} type='area' height={240} /> :  activetab === 'mensuelle' ? <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: true,
      categories: listemonthInventaire.monthcat,
      labels: {
        low: 0,
        offsetX: 0,
        show: true,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 15,
        right: 0,
        bottom: 0,
        top: -20
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépenses',
    data: listemonthInventaire.dipenseVal
  }, {
    name: 'Gains',
    data: listemonthInventaire.gainVal
  }]} type='area' height={240} /> : <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: true,
      categories: listeAnnuaireInventaire.yearcat,
      labels: {
        low: 0,
        offsetX: 0,
        show: true,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 15,
        right: 0,
        bottom: 0,
        top: -20
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: true,
    },

  }} series={[{
    name: 'Dépenses',
    data: listeAnnuaireInventaire.dipenseVal.map(res => res.totaldipense)
  }, {
    name: 'Gains',
    data: listeAnnuaireInventaire.gainVal.map(res => res.totalgain)
  }]} type='area' height={240} />}
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <Row className="border-top m-0">
                      <Col xl="4" md="6" sm="6" className="pl-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-sort-alt"></i></div>
                          <div className="media-body">
                            <h6>{"Total Revenus"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{revenuejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{revenuemensuelle}</p> : activetab === 'mensuelle' ?   <p>{revenuemensuelle}</p>:  <p>{revenueanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="6" sm="6">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-sort-alt"></i></div>
                          <div className="media-body">
                            <h6>{"Total Dépenses"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{dipensejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{dipensemensuelle}</p> : activetab === 'mensuelle' ?   <p>{dipensemensuelle}</p>:  <p>{dipenseanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="12" className="pr-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-cur-euro"></i></div>
                          <div className="media-body">
                            <h6>{"Total Gains"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{revenuejournalier - dipensejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{revenuemensuelle -  dipensemensuelle}</p> : activetab === 'mensuelle' ?   <p>{revenuemensuelle - dipensemensuelle}</p>:  <p>{revenueanuelle - dipenseanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
            <Col xl="6 xl-70" className="appointment-sec box-col-6">
           
         
         
        
       </Col>
           
          
            </Row>
            
          </Col> : "" }
        {/* <Row className="second-chart-list third-news-update">
          <Col xl="4 xl-30" lg="4" className="morning-sec box-col-4">
            <Card  className="o-hidden profile-greeting" style={{backgroundImage : `url(${imgwelcome2})`}}>
              <CardBody>
                <div className="media">
                  <div className="badge-groups w-100">
                    <div className="badge f-12">
                      <Clock style={{width:"16px" ,height:"16px"}} className="mr-1"/>
                      <span id="txt">{curHr}:{curMi < 10 ? "0"+curMi :curMi} {meridiem}</span>
                    </div>
                    <div className="badge f-12"><i className="fa fa-spin fa-cog f-14"></i></div>
                  </div>
                </div>
                <div className="greeting-user text-center">
                  <div className="profile-vector"><img className="img-fluid" src={imgwelcome} alt="" /></div>
                  <h4 className="f-w-600"><span id="greeting">{daytimes}</span> <span className="right-circle"><i className="fa fa-check-circle f-14 middle"></i></span></h4>
         
                  <p><span> {localStorage.getItem("@intranetLastUser") + " " + localStorage.getItem("@intranetFirstUser") }</span></p>
                  <div className="whatsnew-btn"><a className="btn btn-primary" href="#javascript">{"Nouveauté"}</a></div>
                  <div className="left-icon"><i className="fa fa-bell"> </i></div>
                </div>
              </CardBody>
            </Card>
            <Row>
                   
                   <Col  sm="12">
                       <Card>
                           <CardHeader>
                               <h6>{ActualitésListe}</h6>
                           </CardHeader>
                           <CardBody>
                               <VerticalTimelineCompDashbord news={news} />
                           </CardBody>
                       </Card>
                   </Col>
               </Row>
          </Col>
          <Col xl="8 xl-70" lg="8" className="calendar-sec box-col-8">
          <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  <Col xl="3" className="earning-content p-0">
                    <Row className="m-0 chart-left">
                      <Col xl="12" className="p-0 left_side_earning">
                        <h6>{Dashboard}</h6>
                        <p className="font-roboto">{"Aperçu du mois dernier"}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h6>{"$4055.56"} </h6>
                        <p className="font-roboto">{"Ce mois-ci gagner"}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h6>{"$1004.11"}</h6>
                        <p className="font-roboto">{"Bénéfice de ce mois"}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h6>{"90%"}</h6>
                        <p className="font-roboto">{"Vente ce mois-ci"}</p>
                      </Col>
                      <Col xl="12" className="p-0 left-btn"><a className="btn btn-gradient" href="#javascript">{Summary}</a></Col>
                    </Row>
                  </Col>
                  <Col xl="9" className="p-0">
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                          <div className="inner-top-left">
                            <ul className="d-flex list-unstyled">
                              <li>{Daily}</li>
                              <li className="active">{Weekly}</li>
                              <li>{Monthly}</li>
                              <li>{Yearly}</li>
                            </ul>
                          </div>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                              <ApexCharts id="chart-currently" options={Currentlysale.options} series={Currentlysale.series} type='area' height={240} />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <Row className="border-top m-0">
                      <Col xl="4" md="6" sm="6" className="pl-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-crown"></i></div>
                          <div className="media-body">
                            <h6>{ReferralEarning}</h6>
                            <p>{"$5,000.20"}</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="6" sm="6">
                        <div className="media p-0">
                          <div className="media-left bg-secondary"><i className="icofont icofont-heart-alt"></i></div>
                          <div className="media-body">
                            <h6>{CashBalance}</h6>
                            <p>{"$2,657.21"}</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="12" className="pr-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-cur-dollar"></i></div>
                          <div className="media-body">
                            <h6>{SalesForcasting}</h6>
                            <p>{"$9,478.50"}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
            <Col xl="6 xl-70" className="appointment-sec box-col-6">
           
         
         
        
       </Col>
           
          
            </Row>
            
          </Col>
          </Row> */}
          {/* <Col xl="8 xl-70" lg="8" className="calendar-sec box-col-8">
          <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  <Col xl="3" className="earning-content p-0">
                    <Row className="m-0 chart-left">
                      <Col xl="12" className="p-0 left_side_earning">
                        <h6>{Dashboard}</h6>
                        <p className="font-roboto">{"Aperçu du mois dernier"}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h6>{"$4055.56"} </h6>
                        <p className="font-roboto">{"Ce mois-ci gagner"}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h6>{"$1004.11"}</h6>
                        <p className="font-roboto">{"Bénéfice de ce mois"}</p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h6>{"90%"}</h6>
                        <p className="font-roboto">{"Vente ce mois-ci"}</p>
                      </Col>
                      <Col xl="12" className="p-0 left-btn"><a className="btn btn-gradient" href="#javascript">{Summary}</a></Col>
                    </Row>
                  </Col>
                  <Col xl="9" className="p-0">
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                          <div className="inner-top-left">
                            <ul className="d-flex list-unstyled">
                              <li>{Daily}</li>
                              <li className="active">{Weekly}</li>
                              <li>{Monthly}</li>
                              <li>{Yearly}</li>
                            </ul>
                          </div>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                              <ApexCharts id="chart-currently" options={Currentlysale.options} series={Currentlysale.series} type='area' height={240} />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <Row className="border-top m-0">
                      <Col xl="4" md="6" sm="6" className="pl-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-crown"></i></div>
                          <div className="media-body">
                            <h6>{ReferralEarning}</h6>
                            <p>{"$5,000.20"}</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="6" sm="6">
                        <div className="media p-0">
                          <div className="media-left bg-secondary"><i className="icofont icofont-heart-alt"></i></div>
                          <div className="media-body">
                            <h6>{CashBalance}</h6>
                            <p>{"$2,657.21"}</p>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="12" className="pr-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-cur-dollar"></i></div>
                          <div className="media-body">
                            <h6>{SalesForcasting}</h6>
                            <p>{"$9,478.50"}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
            <Col xl="6 xl-70" className="appointment-sec box-col-6">
           
           <Col xl="12" className="appointment">
             <Card>
               <CardHeader className="card-no-border">
                 <div className="header-top">
                       <h6 className="m-0">{EventsListe}</h6>
                   <div className="card-header-right-icon">
                     <select className="button btn btn-primary">
                       <option>{Today}</option>
                       <option>{Tomorrow}</option>
                       <option>{Yesterday}</option>
                     </select>
                   </div>
                 </div>
               </CardHeader>
               <CardBody className="pt-0">
                 <div className="appointment-table table-responsive">
                   <table className="table table-bordernone">
                     <tbody>
                       {events.map((event, index) => 
                       <tr>
                       {/* <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/app-ent.jpg")} alt="" />
                         <div className="status-circle bg-primary"></div>
                       </td> */}
                         {/*   <td className="img-content-box"><span className="d-block">{event.title}</span><span className="font-roboto"> <div dangerouslySetInnerHTML={{ __html: event.description }} />{}</span></td>
                       <td>
                         <p className="m-0 font-primary">{moment(event.start).format("DD MMM")}</p>
                       </td>
                   <td className="text-right">
                         <div className="button btn btn-primary">{Done}<i className="fa fa-check-circle ml-2"></i></div>
                       </td>
                                        </tr>  
                     )}
                      
                       
                     </tbody>
                   </table>
                 </div>
               </CardBody>
             </Card>
           </Col>
         
        
       </Col>
            <Col xl="6 xl-70" className="appointment-sec box-col-6">
            <Card className="gradient-primary o-hidden">
              <CardBody>
                <div className="default-datepicker">
                  <DatePicker
                   locale="fr"
                    selected={startDate}
                    onChange={handleChange}
                    inline
                  />
                </div><span className="default-dots-stay overview-dots full-width-dots"><span className="dots-group"><span className="dots dots1"></span><span className="dots dots2 dot-small"></span><span className="dots dots3 dot-small"></span><span className="dots dots4 dot-medium"></span><span className="dots dots5 dot-small"></span><span className="dots dots6 dot-small"></span><span className="dots dots7 dot-small-semi"></span><span className="dots dots8 dot-small-semi"></span><span className="dots dots9 dot-small">                </span></span></span>
              </CardBody>
            </Card>
            </Col>
          
            </Row>
            <Card className="earning-card">
            <Card>
                            <CardHeader>
                                <h6>{"Liste Franchises"}</h6>
                            </CardHeader>
                            <CardBody>
                               
                            </CardBody>
                        </Card>
            </Card>
          </Col>
      */}
    
          
          {/* <Col xl="8 xl-100" className="dashboard-sec box-col-12">
            <Card className="earning-card">
            <Card>
                            <CardHeader>
                                <h6>{"Liste Franchises"}</h6>
                            </CardHeader>
                            <CardBody>
                                <CarsouselFranchise2 />
                            </CardBody>
                        </Card>
            </Card>
          </Col> */}
          {/* <Col xl="9 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{"1001"}</h4><span>{Purchase} </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{"1005"}</h4><span>{Sales}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart3data}
                            options={smallchart3option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{"100"}</h4><span>{SalesReturn}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart3 flot-chart-container"
                            data={smallchart4data}
                            options={smallchart4option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{"101"}</h4><span>{PurchaseRet}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="3 xl-50" className="chart_data_right box-col-12">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>{"$95,900"}<span className="new-box">{Hot}</span></h4><span>{PurchaseOrderValue}</span>
                  </div>
                  <div className="knob-block text-center">
                    <div className="knob1" id="ordervalue1"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="3 xl-50" className="chart_data_right second d-none">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>{"$95,000"}<span className="new-box">{New}</span></h4><span>{ProductOrderValue}</span>
                  </div>
                  <div className="knob-block text-center">
                    <div className="knob1" id="ordervalue2"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="4 xl-50" className="news box-col-6">
            <Card>
              <CardHeader>
                <div className="header-top">
                  <h6 className="m-0">{NewsUpdate}</h6>
                  <div className="card-header-right-icon">
                    <select className="button btn btn-primary">
                      <option>{Today}</option>
                      <option>{Tomorrow}</option>
                      <option>{Yesterday}</option>
                    </select>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="p-0">
                <div className="news-update">
                  <h6>{"36% off For pixel lights Couslations Types."}</h6><span>{"Lorem Ipsum is simply dummy..."}</span>
                </div>
                <div className="news-update">
                  <h6>{"We are produce new product this"}</h6><span> {"Lorem Ipsum is simply text of the printing... "}</span>
                </div>
                <div className="news-update">
                  <h6>{"50% off For COVID Couslations Types."}</h6><span>{"Lorem Ipsum is simply dummy..."}</span>
                </div>
              </CardBody>
              <div className="card-footer">
                <div className="bottom-btn"><a href="#javascript">{"More..."}</a></div>
              </div>
            </Card>
          </Col> */}
          {/* <Col xl="4 xl-50" className="appointment-sec box-col-6">
            <Row>
              <Col xl="12" className="appointment">
                <Card>
                  <CardHeader className="card-no-border">
                    <div className="header-top">
                          <h6 className="m-0">{Appointment}</h6>
                      <div className="card-header-right-icon">
                        <select className="button btn btn-primary">
                          <option>{Today}</option>
                          <option>{Tomorrow}</option>
                          <option>{Yesterday}</option>
                        </select>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <div className="appointment-table table-responsive">
                      <table className="table table-bordernone">
                        <tbody>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/app-ent.jpg")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">{VenterLoren}</span><span className="font-roboto">Now</span></td>
                            <td>
                              <p className="m-0 font-primary">{"28 Sept"}</p>
                            </td>
                            <td className="text-right">
                              <div className="button btn btn-primary">{Done}<i className="fa fa-check-circle ml-2"></i></div>
                            </td>
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle" src={require("../../assets/images/appointment/app-ent.jpg")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">{JohnLoren}</span><span className="font-roboto">{"11:00"}</span></td>
                            <td>
                              <p className="m-0 font-primary">{"22 Sept"}</p>
                            </td>
                            <td className="text-right">
                              <div className="button btn btn-danger">{Pending}<i className="fa fa-check-circle ml-2"></i></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12" className="alert-sec">
                <Card className="bg-img">
                  <CardHeader>
                    <div className="header-top">
                      <h6 className="m-0">{"Alert"}  </h6>
                      <div className="dot-right-icon"><i className="fa fa-ellipsis-h"></i></div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="body-bottom">
                      <h6>  {"10% off For drama lights Couslations..."}</h6><span className="font-roboto">{"Lorem Ipsum is simply dummy...It is a long established fact that a reader will be distracted by "} </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col> */}
          {/* <Col xl="4 xl-50" className="notification box-col-6">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h6 className="m-0">{Notification}</h6>
                  <div className="card-header-right-icon">
                    <select className="button btn btn-primary">
                      <option>{Today}</option>
                      <option>{Tomorrow}</option>
                      <option>{Yesterday}</option>
                    </select>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="media">
                  <div className="media-body">
                    <p>{"20-04-2020"} <span>{"10:10"}</span></p>
                    <h6>{"Updated Product"}<span className="dot-notification"></span></h6><span>{"Quisque a consequat ante sit amet magna..."}</span>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body">
                    <p>{"20-04-2020"}<span className="pl-1">{Today}</span><span className="badge badge-secondary">{New}</span></p>
                    <h6>{"Tello just like your product"}<span className="dot-notification"></span></h6><span>{"Quisque a consequat ante sit amet magna... "}</span>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body">
                    <div className="d-flex mb-3">
                      <div className="inner-img"><img className="img-fluid" src={require("../../assets/images/notification/1.jpg")} alt="Product-1" /></div>
                      <div className="inner-img"><img className="img-fluid" src={require("../../assets/images/notification/2.jpg")} alt="Product-2" /></div>
                    </div><span className="mt-3">{"Quisque a consequat ante sit amet magna..."}</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="4 xl-50" className="appointment box-col-6">
            <Card>
              <CardHeader>
                <div className="header-top">
                  <h6 className="m-0">{MarketValue}</h6>
                  <div className="card-header-right-icon">
                    <select className="button btn btn-primary">
                      <option>{Year}</option>
                      <option>{Month}</option>
                      <option>{Day}</option>
                    </select>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="radar-chart">
                  <ApexCharts id="marketchart" options={Marketvalue.options} series={Marketvalue.series} type='radar' height={300} />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="4 xl-100" className="chat-sec box-col-6">
            <Card className="chat-default">
              <CardHeader className="card-no-border">
                <div className="media media-dashboard">
                  <div className="media-body">
                    <h6 className="mb-0">{Chat}</h6>
                  </div>
                  <div className="icon-box"><i className="fa fa-ellipsis-h"></i></div>
                </div>
              </CardHeader>
              <CardBody className="chat-box">
                <div className="chat">
                  <div className="media left-side-chat">
                    <div className="media-body d-flex">
                      <div className="img-profile"> <img className="img-fluid" src={require("../../assets/images/User.jpg")} alt="Profile" /></div>
                      <div className="main-chat">
                        <div className="message-main"><span className="mb-0">{"Hi deo, Please send me link."}</span></div>
                        <div className="sub-message message-main"><span className="mb-0">{RightNow}</span></div>
                      </div>
                    </div>
                    <p className="f-w-400">{"7:28 PM"}</p>
                  </div>
                  <div className="media right-side-chat">
                    <p className="f-w-400">{"7:28 PM"}</p>
                    <div className="media-body text-right">
                      <div className="message-main pull-right"><span className="mb-0 text-left">{"How can do for you"}</span>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div className="media left-side-chat">
                    <div className="media-body d-flex">
                      <div className="img-profile"> <img className="img-fluid" src={require("../../assets/images/User.jpg")} alt="Profile" /></div>
                      <div className="main-chat">
                        <div className="sub-message message-main mt-0"><span>{"It's argenty"}</span></div>
                      </div>
                    </div>
                    <p className="f-w-400">{"7:28 PM"}</p>
                  </div>
                  <div className="media right-side-chat">
                    <div className="media-body text-right">
                      <div className="message-main pull-right"><span className="loader-span mb-0 text-left" id="wave"><span className="dot"></span><span className="dot"></span><span className="dot"></span></span></div>
                    </div>
                  </div>
                  <div className="input-group">
                    <input className="form-control" id="mail" type="text" placeholder="Type Your Message..." name="text" />
                    <div className="send-msg"><Send /></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
         */}
        </Row>
        <Modal scrollable  isOpen={showInventaire}  size="xl">
        <ModalHeader >
                {"Vous devez remplir tous les inventaires des dates ci desous"}
               </ModalHeader>
                            <ModalBody>
                     
                <InventaireModal setshowInventaire={setshowInventaire} listeDayInventaire={listeDayInventaire} />
              
                
            
                            </ModalBody>
                          </Modal>
      </Container>
    </Fragment>
  );
}

export default Default;