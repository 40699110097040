import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import Dropzone from 'react-dropzone-uploader'
import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button, InputGroupAddon, InputGroupText, InputGroup} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import {addNewProject} from '../../../redux/project-app/action'
import { useDispatch } from 'react-redux';
import {withRouter,Link, useParams,useLocation} from 'react-router-dom'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {ActualiteTitle,ClientName,ProjectRate,ProjectStatus,ProgressLevel,ProjectSize,Small,Medium,Big,StartingDate,EndingDate,EnterSomeDetails,UploadProjectFile,Add,Cancel,Done,Doing, ActualiteCategorie, ActualiteStatepublication, Option, Checked, Disabled, ActualiteDatepublication, ActualiteStateNotifierParEmail, Actualitecontenu, ActualiteAjouterSondage, ActualitéstateSondage, ActualiteChoixSondage, Files} from '../../../constant'
import { firestorage, urlbackend } from '../../../data/config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { deletemedianews, NewNews, updateMynew } from '../../../redux/news/action';
import { fetchNewsByIdApi } from '../../../api';
import parse from 'html-react-parser';
import { UPDATE_MY_NEWS } from '../../../redux/actionTypes';
import SweetAlert from 'sweetalert2'
const EditActualite = (props) => {
const {newsId} = useParams()
const location = useLocation()
    const dispatch = useDispatch()
    const {register, handleSubmit, errors } = useForm();
    const [startDate, setstartDate] = useState(new Date())
    const [endDate, setendDate] = useState(new Date())
    const [publiucationState, setPublicationState] = useState(false)
    const [NotificationState, setNotificationState] = useState(false)
    const [stateSondage, setStateSondage] = useState(false)
    const [actualiteContenu, setActualiteContenu] = useState(location.state?.news?.actualitecontenu)
    const [sondageListe, setSondageListe] = useState([{contenuSondage : "" }])

    const [random, setrandom] = useState(0)
    const [myfilee, setMyFile] = useState([])
    const [myfileenew, setMyFileNew] = useState([])
    const [News, setNews] = useState({})

const [title, settitle] = useState("")
    const handleStartDate = date => {
      setstartDate(date);
    };
useEffect(() => {
 // setActualiteContenu(location.state?.news?.actualitecontenu)
  fetchNewsByIdApi(newsId).then(res => {

    setNews(res)
    settitle(res.title)
    console.log(res.actualitecontenu)
    setActualiteContenu(res.actualitecontenu)
    setPublicationState(res.publiucationState)

    setstartDate(res.actualiteDatepublication !== undefined ? res.actualiteDatepublication.toDate() : new Date())


    setNotificationState(res.actualiteNotifierParEmail)
    setMyFile(res.piecesJoint)
setStateSondage(res.actualitestateSondage)
setSondageListe(res.sondageListe)

  }).catch(e => console.log(e))
}, [])
    
const AddSondage = () => {

  let listesondafecopy = sondageListe
  listesondafecopy.push({ contenuSondage : "" })

setSondageListe(listesondafecopy)
setrandom(Math.random())
}
const Addtext = (i, text) => {
  console.log()
 let sondageListCopy = sondageListe
 sondageListCopy[i].contenuSondage = text
 setSondageListe(sondageListCopy)
 setrandom(Math.random())
}
const DeleteSondage = (value) => {
  
if(value === ""){
  let listesondafecopy = sondageListe.filter((res, i) => i !== sondageListe.length - 1)
  console.log(listesondafecopy)
  setSondageListe(listesondafecopy)
  setrandom(Math.random())
}
else {
let listesondafecopy = sondageListe.filter(res => res.contenuSondage !== value)
setSondageListe(listesondafecopy)
setrandom(Math.random())
}
  
}
    const handleEndDate = date => {
      setendDate(date);
    };
    
    const getUploadParams = ({ meta, file }) => {

       
    
        let bytytrans = 0
        const uploadTask =  firestorage.ref(`/actualites/${meta.name}`).put(file);
        
        uploadTask.on('state_changed', 
        (snapShot) => {
         //takes a snap shot of the process as it is happening
       
         
        }, (err) => {
         //catches the errors
         console.log(err)
        }, () => {
        
         // gets the functions from storage refences the image storage in firebase by the children
         // gets the download url then sets the image from firebase as the value for the imgUrl key:
         firestorage.ref(`/actualites`).child(meta.name).getDownloadURL()
          .then(fireBaseUrl => {
            let myfils = myfileenew;
    
          
            myfils.push({
          url : fireBaseUrl,
                name: meta.name,
                size: meta.size,
                type: meta.type,
              
                icon:"fa fa-file-text-o txt-info"
              })
              console.log(myfils)
              setMyFileNew(myfils)
              toast.success("Fichier Téléchargé avec succés")
           
            console.log(meta.name)
            //setImageUrl(fireBaseUrl)
           
          })})
        
        return { url: 'https://httpbin.org/post' } }
      const handleChangeStatus = ({ meta, file }, status) => {
  if (status === "removed"){
    let myfileeCopy = myfileenew.filter(res => res.name !== meta.name)
    setMyFileNew(myfileeCopy)
  }
      }
    const AddProject = data => {
      data.preventDefault()
      console.log(myfilee)
      if (data !== '') {
        News.title =title
        News.actualitecontenu = actualiteContenu
        News.publiucationState = publiucationState
        News.actualiteDatepublication = startDate
        News.actualitestateSondage = stateSondage
        News.sondageListe = sondageListe
        News.actualiteNotifierParEmail = NotificationState
        News.piecesJoint = myfileenew
        data.allMarque = false
        News.updateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
    
       dispatch(updateMynew(News,newsId))
        props.history.push(`${process.env.PUBLIC_URL}/app/actualites/actualitesliste/`)
      } else {
        errors.showMessages();
      }
    };
    const stringToHTML = (str) => {
      var dom = document.createElement('p');
      dom.innerHTML = str;
      return dom;
    };
    const removefile = (name,id) => {
      SweetAlert.fire({
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
         let rescop = myfilee
         rescop = rescop.filter(res => res.media_news_id !== id)
        
         setMyFile(rescop)
         setrandom(Math.random())
         dispatch(deletemedianews(id))
      
          SweetAlert.fire(
            'Supprimé!',
            'Votre fichier a été supprimé',
            'success'
          )
         
       
         
        }
        else {
        
        }
      })
    }
    const filelist2 = myfilee?.map((data,i)=>{
      return(
        <li    onDoubleClick={() => {
           
       
           
            }} className="file-box" key={i}>
    
    
          <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={`${urlbackend}${data.url}`} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
          <div className="file-bottom">
            <h6>{data.name.slice(0,20)}...<i className="fa fa-trash" onClick={() => removefile(data.name,data.media_news_id)}></i></h6>
    
          </div>
       
               
        </li>
      )
    })
    return (
        <Fragment>
        <Breadcrumb parent="Actualité" title="Modifier actualité" /> 
        <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={AddProject}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{ActualiteTitle}</Label>
                            <Input className="form-control" type="text"  value={title} onChange={(e) => settitle(e.target.value)} name="title" placeholder="Titre Actualité *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{Actualitecontenu}</Label>
                            <SunEditor setContents={actualiteContenu}   onChange={(e) => setActualiteContenu(e)}/>
                            <span style={{ color: "red" }}>{errors.client_name && 'Contenu is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col>
                          <FormGroup>
                            <Label>{ActualiteCategorie}</Label>
                            <select className="form-control digits">
                              <option>{Small}</option>
                              <option>{Medium}</option>
                              <option>{Big}</option>
                            </select>
                          </FormGroup>
                        </Col>
                        </Row> */}
                        <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={publiucationState} onChange={(e) => setPublicationState(e.target.checked)} />
                        <Label for="radio1">{ActualiteStatepublication}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        {/* { publiucationState === false ?
                      <Row>
                        <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{ActualiteDatepublication}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => setstartDate(e)} showPopperArrow={false} selected={startDate === null ?  startDate : startDate}   />
                          </div>
                        </FormGroup>
                        </Col>
                        </Row> : null
} */}
<Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={NotificationState} onChange={(e) => setNotificationState(e.target.checked)} />
                        <Label for="radio1">{ActualiteStateNotifierParEmail}<span className="digits"> </span></Label>
                      </div>
                     
                    </FormGroup>
                        </Col>
                        </Row>
                     
                    
                      <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={stateSondage} onChange={(e) => setStateSondage(e.target.checked)} />
                        <Label for="radio1">{ActualitéstateSondage}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
              {stateSondage === true ?    <> <Row>
                      <Col>
                      <FormGroup >
                      <div>
                      <Button color="primary" onClick={() => AddSondage()}  >{ActualiteAjouterSondage}</Button>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        { 
                        sondageListe.map((sondage,index) => 
                          <Row key={index}>
                      <Col>
                        <FormGroup>
                        
                            <InputGroup>
                              <Input onChange={(e) => Addtext(index, e.target.value)} className="form-control" type="text" value={sondage.contenuSondage} placeholder={ActualiteChoixSondage} aria-label="Recipient's username"/>
                              <InputGroupAddon addonType="append"><InputGroupText onClick={() => DeleteSondage(sondage.contenuSondage)}> {"-"}</InputGroupText></InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                          </Col>
                        </Row>
                        )
                        }</>
                       : null }
                     <Row>
                    <Col>
                    <h6 className="mt-4">{Files}</h6>
                      {filelist2?.length > 0 ?
              <ul className="files">
                  { filelist2 }
       
              </ul> : ""}
                    </Col>
                  </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UploadProjectFile}</Label>
                                <Dropzone
                             
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    inputWithFilesContent={"Ajouter Fichier"}
                                    maxFiles={3}
                                    submitButtonDisabled={true}
                                    multiple={true}
                                    canCancel={true}
                                    inputContent="Uploader un fichier"
                                    styles={{
                                        dropzone: { width: '100%', height: 50 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{alignContent: 'flex-end'}} sm="12">
                          <FormGroup className="mb-0 text-right">
                             
                              <Link className="mr-3" to={`${process.env.PUBLIC_URL}/app/actualites/actualitesliste`}>
                              <Button color="secondary">{Cancel}</Button>
                              </Link>
                              <Button color="primary" >{"Modifier"}</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}

export default withRouter(EditActualite);