import {GET_NEWS_LIST,
        ADD_TO_MY_NEWS,
        ADD_NEW_NEWS,
        REMOVE_NEWS,
        REMOVE_FROM_MY_NEWS,
        UPDATE_NEWS,
        UPDATE_MY_NEWS,
        UPDATE_STATE_NEWS,
        ADD_NEW_SONDAGE,

  } from '../actionTypes'

  import SweetAlert from 'sweetalert2'
const initial_state = {
    news : [],
    newsmanagerdata:[],
    newsfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_NEWS_LIST:
            //
            return {...state,news: action.news.sort((a,b) => new Date(a.creation_date.slice(0,10)) < new Date(b.creation_date.slice(0,10))), loading: true,};

        case ADD_TO_MY_NEWS:
            return {...state,mynewdata:[...state.mynewdata, { ...action.payload.mynewdata}]};
        
        case ADD_NEW_NEWS:
         //   state.news.push(action.payload.newdata)
         return {...state,news:[...state.news, { ...action.payload.newdata}]};
        
        case UPDATE_NEWS:
            return {
                ...state,news: state.news.map(item => item.news_id === action.payload.id ?
                    { ...item, 
                      
                        fillstar:action.payload.updatedata.fillstar,
                        image: action.payload.image_url,
                        title: action.payload.updatedata.title,
                        website_url: action.payload.updatedata.url,
                        desc: action.payload.updatedata.desc,
                        collection:action.payload.updatedata.collection
                    } : 
                    item
                ) 
            };

            case ADD_NEW_SONDAGE:
            
            
                return {
                    ...state,news: state.news.map(item => item.news_id === action.idNew ?
                        { ...item, 
                            sondageListe : action.sondagelisteCopy
                        } : 
                        item
                    ) 
                };
            case UPDATE_STATE_NEWS:
                return {
                    ...state,news: state.news.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            newState:action.payload.state
                        } : 
                        item
                    ) 
                };
        case UPDATE_MY_NEWS:
                return {
                    ...state,news: state.news.map(item => item.news_id === action.payload.id ?
                        { ...item,
                            ...action.payload.updateMydata,
                            
                        } : 
                        item
                    ) 
                };

        case REMOVE_NEWS:
          
            let new_new = state.news.filter(item=> action.removeid !== item.news_id)
            return{...state,news: new_new}
        
        case REMOVE_FROM_MY_NEWS:
            let new_my_new = state.mynewdata.filter(item=> action.newid !== item.news_id)
            return{...state,mynewdata: new_my_new}
                
        default: return { ...state };
    }
    
}