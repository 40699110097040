import React, { Fragment, useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,Table} from "reactstrap";
import { BasicTable,InverseTable,InverseTablePrimaryBackground,HoverableRows,ContextualClasses,TextBackgroundUtilities,TableHeadOptions,StripedRow,StripedRowInverseTable,Caption,ResponsiveTables,BreckpointSpecific } from "../../constant";
import { useParams } from 'react-router-dom';
import { db } from '../../data/config';
import { useState } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { createDemandeFromxsel } from '../../services/demande-enseigne.service';
const DemandesListe = () => {
  const {idFranchise} = useParams()
  const [demadesListe, setdemadesListe] = useState([])
  useEffect(() => {
   
    const unsubscribe = db.collection("Franchises")
    .doc(idFranchise).collection('DemandeFranchise').onSnapshot((snapshot) => {
      const getDemandes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
     // setSelectedUser(getUser[0])
     setdemadesListe(getDemandes)
   
     
     // setActiveTab(getUser[0]?.name)
    })
    return () => unsubscribe();
  }, [db]);
  const handleUpload = (e) => {
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
      for(let j= 0;j<readedData.SheetNames.length;j++){
        const wsname = readedData.SheetNames[j];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        console.log(dataParse)
      //  let contactListe = []
     
          for(let i=0; i < dataParse.length;i++){
              console.log(dataParse[i])
           
            if(dataParse[i].length > 0 ){
              let data = {
                //avatar : user,
                "Développeurs" : dataParse[i][0] || "",
                "DECISION" :  dataParse[i][1] || "",
                "SOURCE" : dataParse[i][4] || "",
                "Date" : dataParse[i][5] || "",
                "Submission-date" : dataParse[i][3] || "",
                "Êtes-vous" : dataParse[i][6] || "",
                "Nom" : dataParse[i][7] || "",
                "Prénom" : dataParse[i][8] || "",
                "Adresse" : dataParse[i][9] || "",
                "Adresse2" : dataParse[i][10] || "",
                "Ville" : dataParse[i][11] || "",
                "Pays" : dataParse[i][12] || "",
                "Téléphoneportable" : dataParse[i][13] || "",
                "Adressee-mail" : dataParse[i][14] || "",
                "Type-a-question" : dataParse[i][15] || "",
                "secteur-géographique-souhaité-1" : dataParse[i][16] || "",
                "secteur-géographique-souhaité-2" : dataParse[i][17] || "",
                "apport-personnel" : dataParse[i][18] || "",
                "complètements-dapports-hors-emprunts" : dataParse[i][19] || "",
                "avancées-du-projet" : dataParse[i][20] || "",
                "Type-a-question2" : dataParse[i][21] || "",
                "Type-a-question3" : dataParse[i][22] || "",
                "votre-parcours-votre-projet" : dataParse[i][23] || "",
                "IP" : dataParse[i][22] || "",
                "Submission-ID" : dataParse[i][24] || "",
                "MAIL-ENVOYER" : dataParse[i][27] || "",
                "1ER-CONTACT" : dataParse[i][28] || "",

              }
              createDemandeFromxsel(data,idFranchise).then(res =>{
            } );
            }
         }

        }

    };
    reader.readAsBinaryString(f)
}
    return (
        <Fragment>
            <Breadcrumb parent="Tables" title="Basic Tables"/>
            <Container fluid={true}>
            <div className='text-right'>
                <button  className="btn btn-primary" //onClick={() => {exportToCSV(selectedUser.contactListe,selectedUser.name)}}
                 >
                         
                              {"Exporter  fichier excel"}
                        </button>
                        <div className='mt-1'> 
                        <label className="btn btn-primary" for="files">Importer  fichier excel</label>
                      
                   <input type="file" name="files"  id="files" style={{display : 'none'}} onChange={(event) => {handleUpload(event)}} />
                     
                         </div>
                        
                  
                </div>
                <Row>
                 

                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <h5>{"Liste demande Franchise"}</h5>
                                          </CardHeader>
                                <div className="table-responsive">
                                    <Table className="table-borderedfor">
                                        <thead>
                                            <tr>
                                                <th scope="col">{"Nom et Prénom"}</th>
                                                <th scope="col">{"Date"}</th>
                                                <th scope="col">{"Téléphone portable"}</th>
                                                <th scope="col">{"Décision"}</th>
                                                <th scope="col">{"Adresse"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {demadesListe.map((demande,key) =>  
                                           demande.DECISION === "REJETE" ?
                                            <tr>
                                            <td className="bg-danger">{demande.Nom + " "+demande.Prénom}</td>
                                            <td className="bg-danger">{demande.Date}</td>
                                            <td className="bg-danger">{demande.Téléphoneportable}</td>
                                            <td className="bg-danger">{demande.DECISION}</td>
                                            <td className="bg-danger">{demande.Adresse}</td>
                                            </tr> : demande.DECISION === "ACCEPTE" ?
                                               <tr>
                                                     <td className="bg-success">{demande.Nom + " "+demande.Prénom}</td>
                                            <td className="bg-success">{demande.Date}</td>
                                            <td className="bg-success">{demande.Téléphoneportable}</td>
                                            <td className="bg-success">{demande.DECISION}</td>
                                            <td className="bg-success">{demande.Adresse}</td>
                                            
                                           </tr> : demande.DECISION === "EN COUR" ?
                                               <tr>
                                                    <td className="bg-warning">{demande.Nom + " "+demande.Prénom}</td>
                                            <td className="bg-warning">{demande.Date}</td>
                                            <td className="bg-warning">{demande.Téléphoneportable}</td>
                                            <td className="bg-warning">{demande.DECISION}</td>
                                            <td className="bg-warning">{demande.Adresse}</td>
                                            
                                            
                                           </tr> : 
                                             <tr className="table-active">
                                            <td className="bg-light">{demande.Nom + " "+demande.Prénom}</td>
                                            <td className="bg-light">{demande.Date}</td>
                                            <td className="bg-light">{demande.Téléphoneportable}</td>
                                            <td className="bg-light">{demande.DECISION}</td>
                                            <td className="bg-light">{demande.Adresse}</td>
                                            
                                           
                                          
                                         </tr> 
                                            )}
                                          
                                          
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                  
                       
                     
                   
                   
                  
                     
                    </Row>
                </Container>
        </Fragment>
            );
        };
        
export default DemandesListe;