import axios from 'axios';
import {
    GET_EMAIL_TYPES,WATCH_EMAIL,GET_ALL_EMAILS, GET_ALL_SEND_EMAILS, GET_ALL_TRASH_EMAILS, REMOVE_EMAIL, ADD_USER_EMAIL
} from '../actionTypes';

import SweetAlert from 'sweetalert2'
import { urlbackend } from '../../data/config';
export const fetchAllEmail = () => ({
    type:WATCH_EMAIL
})


export const getAllTrashEmails = (data) => ({
    type : GET_ALL_TRASH_EMAILS,
    payload : {messagestrash : data.allMessages || []}
})

export const getAllSendEmails = (data) => ({
    type : GET_ALL_SEND_EMAILS,
    payload : {messagessend : data.allMessages || []}
})

export const getAllEmails = (data) => ({
    type : GET_ALL_EMAILS,
    payload : {messages : data.allMessages || [], numbersmessage : data.numbersMsg,numbersallmessage : data.numbersAllMsg}
})

export const getAllTypes = (result) => ({
    type : GET_EMAIL_TYPES,
    payload : result
})
export const createAdresseEmail = (data) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}mailuser/create.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success

     
        SweetAlert.fire(
            'Email ajoutée',
            'Email a été ajoutée avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

    return ({
        type: ADD_USER_EMAIL,
        payload: data
    })

}

export const deleteEmail = (email) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}mailuser/delete.php`,
        data: JSON.stringify({"mail_id" : email}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success

        SweetAlert.fire(
            'Supprimé',
            'Votre media est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    return ({
        type: REMOVE_EMAIL,
         payload : email
    })
}
export const groupBy = ( array , f ) => {
    var groups = {};
    array.forEach( function( o )
    {
        var group = f(o)[0]
        groups[group] = groups[group] || [];
        groups[group].push( o );  
    });
    return groups;
}

