import React,{Fragment, useState,useEffect,useRef} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,Button,Media, CardBody, Form, Modal, InputGroup, Input, InputGroupAddon, ModalBody, CardHeader} from 'reactstrap'

import Slider from 'react-slick';
import {Link, useHistory, useParams} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import defaultuser from '../../../assets/images/user/user.png';
import { addToCart,watchfetchSingleProducts } from '../../../redux/ecommerce/product/action';
import { ADD_TO_CART, ADD_TO_WISHLIST, WATCH_PRODUCT_LIST } from '../../../redux/actionTypes'
import Ratings from 'react-ratings-declarative'
import { ProductReview,  Brand, Availability, AddToCart, BuyNow, AddNewEvent, ManageCategory, Ratio } from "../../../constant";
import { Truck, Gift,CreditCard,Clock, Trash, Edit } from 'react-feather';
import { fetchEventByIdApi, fetchFranchiseByIdApi } from '../../../api';
import { urlbackend } from '../../../data/config';
import htmr from 'htmr';
import moment from 'moment';
import SweetAlert from 'sweetalert2'
import { addcomment, addstatusParticipe, deletyecommentaire, removeevent, updatecomment } from '../../../redux/events/action';

const  Eventpage = (props)  => {
    const {id} = useParams()
    const history = useHistory()
    const products = useSelector(content => content.data.productsFranchise)
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [event, setEvent] = useState(null);
    const [stateClick, setstateClick] = useState(null);
    const [selectedCommenatire, setselectedCommenataire] = useState(null);
    const [messageInput, setMessageInput] = useState('');
    const [commentModal, setCommentModal] = useState(false);
    const commentModalToggle = () => setCommentModal(!commentModal)
    const [updatecommentModal, setUpdateCommentModal] = useState(false);
    const updatecommentModalToggle = () => setUpdateCommentModal(!updatecommentModal)
    const [rating,setRating] = useState(0)
    // eslint-disable-next-line
    const [typeUser, settypeUser] = useState("");
    const [quantity,Setquantity] = useState(1)
  
    
    const slider1 = useRef();
    const slider2 = useRef();
    const dispatch = useDispatch()

    useEffect(() => {
        fetchEventByIdApi(id).then(res => {
            console.log(res)
            if(res.title !== undefined){
                setEvent(res)
                //setStateUser(res.franchise_state)
                //dispatch({type: WATCH_SUBFRANCHISE_LIST})
            }
           
         
          })
        settypeUser(localStorage.getItem("@intranetTypeUser"))
        setState({
            nav1: slider1.current,
            nav2: slider2.current
          });
      } ,[dispatch]);
    const { nav1, nav2 } = state;
    
    const addCommentsimple = () => {
      let data = {}
      if(messageInput.length > 0){
          data.comment = messageInput
          data.event_id = event.id
          data.prop_id = localStorage.getItem("@intranetidUser")
          data.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
          addcomment(data)
             setMessageInput("")
      
      
          
          setTimeout(() => {
              fetchEventByIdApi(id).then(res => {
                
                  if(res.title !== undefined){
                      setEvent(res)
                      //setStateUser(res.franchise_state)
                      //dispatch({type: WATCH_SUBFRANCHISE_LIST})
                  }
                 
               
                })  
          }, 2000);
      
      }
        }
  const addComment = () => {
let data = {}
if(messageInput.length > 0){
    data.comment = messageInput
    data.event_id = event.id
    data.prop_id = localStorage.getItem("@intranetidUser")
    data.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
    addcomment(data)
       setMessageInput("")
if(stateClick === "1")
participer()
else if(stateClick === "2")
pasinteresse()
else
refuser()

    commentModalToggle()
    setTimeout(() => {
        fetchEventByIdApi(id).then(res => {
          
            if(res.title !== undefined){
                setEvent(res)
                //setStateUser(res.franchise_state)
                //dispatch({type: WATCH_SUBFRANCHISE_LIST})
            }
           
         
          })  
    }, 2000);

}
  }
  const updateComment = () => {
    let data = {}
    if(messageInput.length > 0){
        data.comment = messageInput
        data.comment_id = selectedCommenatire
      
        updatecomment(data)
        setMessageInput("")
    
        updatecommentModalToggle()
        setTimeout(() => {
            fetchEventByIdApi(id).then(res => {
              
                if(res.title !== undefined){
                    setEvent(res)
                    //setStateUser(res.franchise_state)
                    //dispatch({type: WATCH_SUBFRANCHISE_LIST})
                }
               
             
              })  
        }, 2000);
    
    }
      }
      
  const removeCommentaire = (id) => {
    
    SweetAlert.fire({
     
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          deletyecommentaire(id)
          
       //history.push(`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate`)
           // eventClick.event.remove();
        setTimeout(() => {
            fetchEventByIdApi(id).then(res => {
              
                if(res.title !== undefined){
                    setEvent(res)
                    //setStateUser(res.franchise_state)
                    //dispatch({type: WATCH_SUBFRANCHISE_LIST})
                }
               
             
              })  
        }, 2000);
          
        }
        else {
        
        }
      })
               
             
            
     
    }
  const deleteEvent = (id) => {
    
    SweetAlert.fire({
     
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            dispatch(removeevent(id))
       history.push(`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate`)
           // eventClick.event.remove();
          SweetAlert.fire(
            'Supprimé!',
            'Votre Evénement a été supprimée',
            'success'
          )
        }
        else {
        
        }
      })
               
             
            
     
    }

    const symbol = useSelector(content => content.data.symbol)

    const  addcart = (product, qty) => {
        dispatch({ type: ADD_TO_CART, payload: { product, qty } })
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/cart`)
    }
    
    const buyProduct = (product, qty) => {
        dispatch({ type: ADD_TO_CART, payload: { product, qty } })
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/checkout`)
    }

    const addWishList = (product) => {
        dispatch({ type: ADD_TO_WISHLIST, payload: { product } });
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`);
    }

    const changeRating = (newRating) => {
        setRating(newRating)
    }
    const participer = () => {
   
        let data = {}
        data.event_p_id = id
        data.participe_id = localStorage.getItem("@intranetidUser")
        data.status = "participé"
        addstatusParticipe(data)
        setTimeout(() => {
          fetchEventByIdApi(id).then(res => {
            
              if(res.title !== undefined){
                  setEvent(res)
                  //setStateUser(res.franchise_state)
                  //dispatch({type: WATCH_SUBFRANCHISE_LIST})
              }
             
           
            })  
      }, 2000);
    }
    const refuser = () => {
        let data = {}
        data.event_p_id = id
        data.participe_id = localStorage.getItem("@intranetidUser")
        data.status = "pas participé"
        addstatusParticipe(data)
        setTimeout(() => {
          fetchEventByIdApi(id).then(res => {
            
              if(res.title !== undefined){
                  setEvent(res)
                  //setStateUser(res.franchise_state)
                  //dispatch({type: WATCH_SUBFRANCHISE_LIST})
              }
             
           
            })  
      }, 2000);
    }
    const pasinteresse = () => {
        let data = {}
        data.event_p_id = id
        data.participe_id = localStorage.getItem("@intranetidUser")
        data.status = "interessé"
        addstatusParticipe(data)
        setTimeout(() => {
          fetchEventByIdApi(id).then(res => {
            
              if(res.title !== undefined){
                  setEvent(res)
                  //setStateUser(res.franchise_state)
                  //dispatch({type: WATCH_SUBFRANCHISE_LIST})
              }
             
           
            })  
      }, 2000);
    }
    return (
      
        <Fragment>
            <Breadcrumb parent="Evénements" title="Détail Evénement"/>
                <Container fluid={true}>
                <div className="media-body text-right">
                <Form className="d-inline-flex mr-3">

<a onClick={() => (event?.participeList?.find(res => res.user_id === localStorage.getItem("@intranetidUser")) == undefined) ? (event.comment_required == "1" ? (setstateClick("1"),commentModalToggle()) : participer()) : null} className="btn btn-primary" >{event?.participeList?.find(res => res.user_id === localStorage.getItem("@intranetidUser") && res.status === "participé") == undefined ? "Participer" : "participé"}</a>
<div style={{ height: "0px", width: "0px", overflow: "hidden" }}>

</div>

</Form>
                <Form className="d-inline-flex mr-3">

<a onClick={() => (event?.participeList?.find(res => res.user_id === localStorage.getItem("@intranetidUser")) == undefined) ? (event.comment_required == "1" ? (setstateClick("2"),commentModalToggle()) : pasinteresse()) : null} className="btn btn-pich" style={{backgroundColor : 'orange'}} >{event?.participeList?.find(res => res.user_id === localStorage.getItem("@intranetidUser") && res.status === "intéressé") == undefined ? "intéresser" : "intéressé"}</a>
<div style={{ height: "0px", width: "0px", overflow: "hidden" }}>

</div>

</Form>
                <Form className="d-inline-flex mr-3">

<a onClick={() => (event?.participeList?.find(res => res.user_id === localStorage.getItem("@intranetidUser")) == undefined) ? (event.comment_required == "1" ? (setstateClick("3"),commentModalToggle()) : refuser()) : null}  className="btn btn-danger" > {event?.participeList?.find(res => res.user_id === localStorage.getItem("@intranetidUser") && res.status === "pas participé") == undefined ? "Refuser" : "Refusé"}</a>
<div style={{ height: "0px", width: "0px", overflow: "hidden" }}>

</div>

</Form>
                <Form className="d-inline-flex mr-3">

                  <Link to={`${process.env.PUBLIC_URL}/app/events/update-event/${id}`} className="btn btn-primary" > <Edit size={12}/></Link>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
                <Form className="d-inline-flex">

                  <a onClick={() => deleteEvent(id)} className="btn btn-primary" ><Trash size={12}/></a>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
              </div>
                  
                       <div>
                                        <table className="product-page-width">
                                        <tbody>
                                            <tr>
                                            <td> <b>{"Créateur"} &nbsp;&nbsp;&nbsp;:</b></td>
                                            <td>{event?.createur}</td>
                                            </tr>
                                            <tr>
                                            <td> <b>{"Date et heure de l'événement"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td className="txt-success">{moment(event?.start).format('yyyy-MM-DD HH:mm')} au {moment(event?.end).format('yyyy-MM-DD HH:mm')}</td>
                                            </tr>
                                            <tr>
                                            <td> <b>{"Date limite d'inscription"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td>{moment(event?.limit_date_inscrit).format('yyyy-MM-DD HH:mm')}</td>
                                            </tr> 
                                            <tr>
                                            <td> <b>{"Lieu"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td>{event?.lieu}</td>
                                            </tr> 
                                        </tbody>
                                        </table>
                                    </div>
                            <Card>
                               <Row className="product-page-main">
                                   
                            <Col xl="12 xl-100">
                                <Card>
                                    <CardBody>
                                    <div className="product-page-details">
                                        <h3>{event?.title}</h3>
                                    </div>
                                  <div className="product-price f-28">
                                  <h3>   Organisateur : {event?.organisateur_id} </h3>
                                        {/* <del>{symbol}{singleItem.discountPrice}</del> */}
                                    </div>
                                   
                                    <hr/>
                                    <div dangerouslySetInnerHTML={{ __html: event?.description}}></div>
                                    <hr/>
                                  <div>
                                  <Slider
                                    asNavFor={nav2} 
                                    arrows= {false}
                                        ref={slider => (slider1.current = slider)} className="product-slider">

                                    {event?.piecesJoint?.length > 0 ? event.piecesJoint.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <Media src={`${urlbackend}${item.url}`} alt="" className="img-fluid" />
                                            </div>
                                        )
                                    }) :
                                        <></>
                                    }   
                                    </Slider>
                                   
                                  <div className="product-page-details">
                                        <h3>{event?.comment?.length} {"Commentaire"}</h3>
                                    </div>
                                    
                                    <>
                   
                   <ul >
                   {event?.comment?.map((commentaire , index ) => 
                             
                         
                     <li>
                       <Media className="align-self-center">
                       <Media className="align-self-center" src={commentaire.userImage} alt=""/> 
                         <Media body>
                           <Row>
                             
                             <Col md="4 xl-100">
                               <h6 className="mt-0">{commentaire.userName}</h6>
                             </Col>
                             <Col lg="1" sm="1">
                  
                  {localStorage.getItem("@intranetidUser") === commentaire.prop_id ? <>
                <i onClick={() =>  {
                  setMessageInput(commentaire.comment)
                  setselectedCommenataire(commentaire.comment_id)
                  updatecommentModalToggle()
                }} className="icofont icofont-edit"></i>
                <i onClick={() => removeCommentaire(commentaire.comment_id)} className="icofont icofont-trash"></i>
                </>:
               null
              }
                
           
                </Col> 
                           </Row>
                           <p>{commentaire.comment}</p>
                           <ul className="files">
                  
</ul>
                         </Media>
                       </Media>
                     </li>
                        )}
                
                   </ul>
                   {event?.comment_state === "1" ? null :     <div className="chat-message clearfix">
                           <Row>
                          
                             <Col xl="12" className="d-flex">
                             
                               <InputGroup className="text-box">
                                   <Input
                                   style={{height: 40}}
                                       type="text"
                                       className="form-control input-txt-bx"
                                       placeholder="Ecrire un commentaire......"
                                       value={messageInput}
                                     onChange={(e) => setMessageInput(e.target.value)}
                                   />
                                   <InputGroupAddon addonType="append">
                                       <Button  color="primary" onClick={() => addCommentsimple()}   className="form-control input-txt-bx" style={{fontSize: 16}}  >{"Commenter"}</Button>
                                   </InputGroupAddon>
                               </InputGroup>
                             </Col>
                           </Row>
                         </div>}
               
                         
                   <Modal centered isOpen={commentModal} toggle={commentModalToggle}>
                       <ModalBody>
                   <div className="chat-message clearfix">
                           <Row>
                          
                             <Col xl="12" className="d-flex">
                             
                               <InputGroup className="text-box">
                                   <Input
                                       type="text"
                                       className="form-control input-txt-bx"
                                       placeholder="Ecrire un commentaire......"
                                       value={messageInput}
                                     onChange={(e) => setMessageInput(e.target.value)}
                                   />
                                   <InputGroupAddon addonType="append">
                                       <Button color="primary" onClick={() => addComment()}   className="form-control input-txt-bx" style={{fontSize: 16}}  >{"Commenter"}</Button>
                                   </InputGroupAddon>
                               </InputGroup>
                             </Col>
                           </Row>
                         </div>
                         </ModalBody>
                   </Modal>
                   <Modal centered isOpen={updatecommentModal} toggle={updatecommentModalToggle}>
                       <ModalBody>
                   <div className="chat-message clearfix">
                           <Row>
                          
                             <Col xl="12" className="d-flex">
                             
                               <InputGroup className="text-box">
                                   <Input
                                       type="text"
                                       className="form-control input-txt-bx"
                                       placeholder="Ecrire un commentaire......"
                                       value={messageInput}
                                     onChange={(e) => setMessageInput(e.target.value)}
                                   />
                                   <InputGroupAddon addonType="append">
                                       <Button color="primary" onClick={() => updateComment()}   className="form-control input-txt-bx" style={{fontSize: 16}} >{"Commenter"}</Button>
                                   </InputGroupAddon>
                               </InputGroup>
                             </Col>
                           </Row>
                         </div>
                         </ModalBody>
                   </Modal>
                
                   </> 
                                    </div> 
                                    <hr/>
                                    {/* <Row>
                                        <Col md="6">
                                        <h6 className="product-title">{"share it"}</h6>
                                        </Col>
                                        <Col md="6">
                                        <div className="product-icon">
                                            <ul className="product-social">
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                                            </ul>
                                            <form className="d-inline-block f-right"></form>
                                        </div>
                                        </Col>
                                    </Row>
                                    <hr/> */}
                                    {/* <Row>
                                        <Col md="6">
                                        <h6 className="product-title">{"Rate Now"}</h6>
                                        </Col>
                                        <Col md="6">
                                        <div className="d-flex">
                                                <Ratings
                                                rating={rating}
                                                widgetRatedColors="blue"
                                                changeRating={changeRating}
                                                >
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                            </Ratings>
                                            <span>{ProductReview}</span>
                                        </div>
                                        </Col>
                                    </Row>
                                    <hr/> */}
                                     {/* {typeUser !== 'admin' ? 
                                    <div className="m-t-15 text-right">
                                        <Button  color="primary" className="m-r-10" onClick={() => addcart(singleItem, quantity)} >
                                            <i className="fa fa-shopping-basket mr-1"></i>{AddToCart}
                                        </Button>
                                        <Button  color="secondary" className="m-r-10" onClick={() => buyProduct(singleItem, quantity)}>
                                            <i className="fa fa-shopping-cart mr-1"></i>{BuyNow}
                                        </Button>
                                        {/* <Button color="secondary" onClick={() => addWishList(singleItem)}>
                                            <i className="fa fa-heart mr-1"></i>{"Add To WishList"}
                                        </Button> 
                                    </div> : <></>} */}
                                    </CardBody>
                                </Card>
                            </Col>
                         <Col xl="12 xl-cs-35">
                         <Card>
                    <CardHeader>
                      <h5>{"Liste participants"}</h5>
                    </CardHeader>
                    <CardBody className="card-body avatar-showcase">
                      <div className="avatars">
                          {event?.participeList?.map(res => 
                           <div className="avatar ratio" style={{textAlign : 'center'}}><Media body className="b-r-8 img-100" src={res.userImage ?? defaultuser} alt="#"/>
                           <p>{res.userName}</p></div>
                            )}
  </div>
                    </CardBody>
                  </Card>
                            </Col> 
                        </Row>
                    </Card>
                    {/* <Tablet /> */}
                 
                </Container>
            </Fragment>
    );
}
export default Eventpage
