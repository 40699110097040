import {GET_NOTIFICATIONS_LIST,
        ADD_TO_MY_NOTIFICATIONS,
        ADD_NEW_NOTIFICATIONS,
        REMOVE_NOTIFICATIONS,
        REMOVE_FROM_MY_NOTIFICATIONS,
        UPDATE_NOTIFICATIONS,
        UPDATE_MY_NOTIFICATIONS,
        UPDATE_STATE_NOTIFICATIONS,
        GET_NOTIFICATIONS_FRANCHISE_LIST,
        UPDATE_NOTE_NOTIFICATIONS,

  } from '../actionTypes'


const initial_state = {
    notifications : [],
    notificationsFranchise : [],
    notificationsmanagerdata:[],
    notificationsfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_NOTIFICATIONS_LIST:
            return {...state,notifications: action.notifications, loading: true,};
            case GET_NOTIFICATIONS_FRANCHISE_LIST:
                return {...state,notificationsFranchise: action.notifications, loading: true,};
    
        case ADD_TO_MY_NOTIFICATIONS:
            return {...state,mynotificationdata:[...state.mynotificationdata, { ...action.payload.mynotificationdata}]};
        
        case ADD_NEW_NOTIFICATIONS:
            state.notifications.push(action.payload.notificationdata)
        return {...state,notifications:state.notifications};
        
        case UPDATE_NOTIFICATIONS:
            return {
         
                ...state,notifications: state.notifications.map(item => item.id === action.payload.id ?
                    { ...item, 
                
                        statetext: action.payload.statetext,
                        state: action.payload.state,
                      
                    } : 
                    item
                ) 
            };
           
        case UPDATE_STATE_NOTIFICATIONS:
                return {
                    ...state,notifications: state.notifications.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            vue: 1
                        } : 
                        item
                    ) 
                };
        case UPDATE_MY_NOTIFICATIONS:
                return {
                    ...state,mynotificationdata: state.mynotificationdata.map(item => item.id === action.payload.id ?
                        { ...item, 
                            id: action.payload.id,
                            fillstar:action.payload.updateMydata.fillstar,
                            image: action.payload.image_url,
                            title: action.payload.updateMydata.title,
                            website_url: action.payload.updateMydata.url,
                            desc: action.payload.updateMydata.desc,
                            collection:action.payload.updateMydata.collection
                        } : 
                        item
                    ) 
                };

        case REMOVE_NOTIFICATIONS:
            let notification_notification = state.notifications.filter(item=> action.removeid !== item.id)
            return{...state,notifications: notification_notification}
        
        case REMOVE_FROM_MY_NOTIFICATIONS:
            let notification_my_notification = state.mynotificationdata.filter(item=> action.notificationid !== item.id)
            return{...state,mynotificationdata: notification_my_notification}
                
        default: return { ...state };
    }
    
}