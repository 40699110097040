import axios from 'axios';
import { Franchises } from 'react-feather';

import SweetAlert from 'sweetalert2'
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_FRANCHISE,
        ADD_NEW_FRANCHISE,
        REMOVE_FRANCHISE,
        REMOVE_FROM_MY_FRANCHISE,
        UPDATE_FRANCHISE,
        UPDATE_MY_FRANCHISE,
        UPDATE_STATE_FRANCHISE,
        GET_FRANCHISE_LIST,
        GET_FRANCHISE_BY_ID,
        ADD_AUDIT_TO_FRANCHISE,ADD_ANNUAIRE_TO_FRANCHISE
       } from '../actionTypes';


export const myfranchise = (myfranchisedata) => {
    return ({
        type: ADD_TO_MY_FRANCHISE,
        payload: {myfranchisedata}
    })
}

export const getFranchiseById = (idFranch) => {

    db.collection("Franchises").doc(idFranch).get().then(res => {


        return ({
            type: GET_FRANCHISE_BY_ID,
            payload: {...res.data(),
            id : res.id}
        })

    })
    //     const uploadTask =  firestorage.ref(`/franchises/${avatar.name}`).putString(avatar.content, 'data_url');
       
    //     uploadTask.on('state_changed', 
    //    (snapShot) => {
    //      //takes a snap shot of the process as it is happening
    //      console.log(snapShot)
    //    }, (err) => {
    //      //catches the errors
    //      console.log(err)
    //    }, () => {
    //      // gets the functions from storage refences the image storage in firebase by the children
    //      // gets the download url then sets the image from firebase as the value for the imgUrl key:
    //      firestorage.ref('/franchises').child(avatar.name).getDownloadURL()
    //       .then(fireBaseUrl => {
    
            
    //         franchisedata.franchiseImage = fireBaseUrl
            
           
            //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
         // })
       //.})
      
       
    }
export const NewFranchise = (franchisedata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
    method: 'post',
    url: `${urlbackend}franchise/create.php`,
    data: JSON.stringify(franchisedata),
    config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
})
.then(function (response) {
    //handle success
    SweetAlert.fire(
        'Franchise ajoutée',
        'Franchise a été ajoutée avec succés',
        'success'
      )
  
})
.catch(function (response) {
    //handle error
    console.log("response")
    console.log(response)
});
      
        return ({
            type: ADD_NEW_FRANCHISE,
            payload: {franchisedata}
        })
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
     // })
   //.})
  
   
}


export const Editfranchise = (id,updatedata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}franchise/update.php`,
        data: JSON.stringify(updatedata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Enseigne modifié',
            'Enseigne a été modifié avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
    return ({
        type: UPDATE_FRANCHISE,
        payload: {id,updatedata}
    })

}
export const addAnnuaire = (annuairesListe ,  id) => {

    //     const uploadTask =  firestorage.ref(`/franchises/${avatar.name}`).putString(avatar.content, 'data_url');
       
    //     uploadTask.on('state_changed', 
    //    (snapShot) => {
    //      //takes a snap shot of the process as it is happening
    //      console.log(snapShot)
    //    }, (err) => {
    //      //catches the errors
    //      console.log(err)
    //    }, () => {
    //      // gets the functions from storage refences the image storage in firebase by the children
    //      // gets the download url then sets the image from firebase as the value for the imgUrl key:
    //      firestorage.ref('/franchises').child(avatar.name).getDownloadURL()
    //       .then(fireBaseUrl => {
    
            
    //         franchisedata.franchiseImage = fireBaseUrl
            db.collection("Franchises").doc(id).update({annuairesListe})
           
            return ({
                type: ADD_ANNUAIRE_TO_FRANCHISE,
                payload: {annuairesListe, id}
            })
            //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
         // })
       //.})
      
       
    }
export const addAudit = (auditListe ,  id) => {

    //     const uploadTask =  firestorage.ref(`/franchises/${avatar.name}`).putString(avatar.content, 'data_url');
       
    //     uploadTask.on('state_changed', 
    //    (snapShot) => {
    //      //takes a snap shot of the process as it is happening
    //      console.log(snapShot)
    //    }, (err) => {
    //      //catches the errors
    //      console.log(err)
    //    }, () => {
    //      // gets the functions from storage refences the image storage in firebase by the children
    //      // gets the download url then sets the image from firebase as the value for the imgUrl key:
    //      firestorage.ref('/franchises').child(avatar.name).getDownloadURL()
    //       .then(fireBaseUrl => {
    
            
    //         franchisedata.franchiseImage = fireBaseUrl
            db.collection("Franchises").doc(id).update({auditListe})
           
            return ({
                type: ADD_AUDIT_TO_FRANCHISE,
                payload: {auditListe, id}
            })
            //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
         // })
       //.})
      
       
    }

export const updatefranchise = (id,updatedata,image_url) => {
    return ({
        type: UPDATE_FRANCHISE,
        payload: {id,updatedata,image_url}
    })

}
export const manageStateFranchise = (id,state) => {
    db.collection('Franchises').doc(id).update({franchiseState : state})
    return ({
        type: UPDATE_STATE_FRANCHISE,
        payload: {id,state}
    })

}

export const updateMyfranchise = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_FRANCHISE,
        payload: {id,updateMydata,image_url}
    })

}

export const removefranchise = (removeid) => {
    return ({
        type: REMOVE_FRANCHISE,
         removeid
    })

}

export const removemyfranchise = (franchiseid) => {
    return ({
        type: REMOVE_FROM_MY_FRANCHISE,
        franchiseid
    })
}