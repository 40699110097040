import {GET_FOLDER_LIST,
    ADD_TO_MY_FOLDER,
    ADD_NEW_FOLDER,
    REMOVE_FOLDER,
    REMOVE_FROM_MY_FOLDER,
    UPDATE_FOLDER,
    UPDATE_MY_FOLDER,
    GET_FOLDER_LIST_BY_ID,
    GET_FILE_LIST,
    ADD_NEW_FILE,
    REMOVE_FILE,
    SHARE_FILE,
    UPDATE_FILE_TREE,
    UPDATE_FILE,
    UPDATE_MY_FILE,
    FAVORIS_FILE,
    DELETE_FAVORIS_FILE,
    ADD_NEW_SUB_FILE,
    GET_FILES_LIST,
    GET_FILE_LIST_LOADING,
    GET_FILE_LIST_BY_ID_USER,
    DELETE_FILE_FROM_SHARE,
    REMOVE_SHARE_FILE,
    REMOVE_MY_SHARE_FILE,
    REMOVE_FINALY_FILE,
    RECUPERE_FILE} from '../actionTypes'


const initial_state = {
files:[],
subfiles:[],
filesfranchise:[],
subfolders:[],
myfolderdata:[],
loading: true,
}

export default (state = initial_state, action) => {

switch (action.type) {
     case GET_FILE_LIST_LOADING:
         return { ...state, loading: true };

    case GET_FILE_LIST:
        return {...state,files : action.files, loading: false,};
        case GET_FILES_LIST:
        return {...state,subfiles:action.files, loading: false,};
         case GET_FILE_LIST_BY_ID_USER:
        return {...state,filesfranchise:action.files, loading: false,};
        
   
    case ADD_TO_MY_FOLDER:
        return {...state,myfolderdata:[...state.myfolderdata, { ...action.payload.myfolderdata}]};
    case ADD_NEW_FILE:
        state.files.push(action.payload)
    return {...state,files:state.files};
    case ADD_NEW_SUB_FILE:
        state.subfiles.push(action.payload)
     
    return {...state,subfiles : state.subfiles};
    case DELETE_FAVORIS_FILE:
                return {
                    ...state,files: state.files.filter(item => item.files_id !== action.payload.id )
                };
   case DELETE_FILE_FROM_SHARE:
                    return {
                        ...state,files: state.files.map(item => item.id === action.payload.id ?
                            { ...item, 
                                userListe : item.userListe.filter(res => res !== action.payload.idUser)
                            } : 
                            item
                        ) 
                    };
    case FAVORIS_FILE:
                return {
                    ...state,files: state.files.map(item => item.id === action.payload.id ?
                        { ...item, 
                            favorisUser : [...item.favorisUser || [] , action.payload.idUser]
                        } : 
                        item
                    ) 
                };

    case SHARE_FILE:
                return {
                    ...state,files: state.files.map(item => item.files_id === action.payload.id ?
                        { ...item, 
                            userListe : action.payload.sharedListe
                        } : 
                        item
                    ) 
                };
    case REMOVE_SHARE_FILE:
                    return {
                        ...state,files: state.files.map(item => item.files_id === action.payload.idfile ?
                            { ...item, 
                             //   userListe : item
                                usersInfos : item.usersInfos.filter(res => res.user_id !== action.payload.user_id)
                            } : 
                            item
                        ) 
                    };
                    case REMOVE_MY_SHARE_FILE:
                        return {
                            ...state,files: state.files.filter(item => item.files_id !== action.payload.idfile 
                                ) 
                            };
    case UPDATE_FILE_TREE:
                    return {
                        ...state,files: state.files.map(item => item.id === action.payload.iddest ?
                            { ...item, 
                                idfolder : action.payload.idsrc
                            } : 
                            item
                        ) 
                    };
                    case UPDATE_MY_FILE:
                        return {
                            ...state,files: state.files.map(item => item.files_id === action.payload.id ?
                                { ...item, 
                                    files_name : action.payload.updatedata.files_name,
                                    updated_date : action.payload.updatedata.updated_date
                                } : 
                                item
                            ),subfiles: state.subfiles.map(item => item.files_id === action.payload.id ?
                                { ...item, 
                                    files_name : action.payload.updatedata.files_name,
                                    updated_date : action.payload.updatedata.updated_date
                                } : 
                                item
                            ) 
                        };
                    case UPDATE_FILE:
                        return {
                            ...state,files: state.files.map(item => item.files_id === action.payload.id ?
                                { ...item, 
                                    files_name : action.payload.updatedata.files_name,
                                    updated_date : action.payload.updatedata.updated_date,
                                } : 
                                item
                            ) 
                        };
    case REMOVE_FILE:
        let new_folder = state.files.filter(item=> action.payload.Id !== item.files_id)
        let newSubfile = state.subfiles.filter(item=> action.payload.Id !== item.files_id)
        return{...state,files: new_folder,subfiles : newSubfile}
        case REMOVE_FINALY_FILE:
            let new_folderfinaly = state.files.filter(item=> action.payload.Id !== item.files_id)
            let newSubfilefinaly = state.subfiles.filter(item=> action.payload.Id !== item.files_id)
            return{...state,files: new_folderfinaly,subfiles : newSubfilefinaly}
            case RECUPERE_FILE:
                let new_folderrecup = state.files.filter(item=> action.payload.Id !== item.files_id)
                let newSubfilerecup = state.subfiles.filter(item=> action.payload.Id !== item.files_id)
                return{...state,files: new_folderrecup,subfiles : newSubfilerecup} 
    case REMOVE_FROM_MY_FOLDER:
        let new_my_folder = state.myfolderdata.filter(item=> action.folderid !== item.files_id)
        return{...state,myfolderdata: new_my_folder}
            
    default: return { ...state };
}

}
