import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchCategoryApi, fetchCategoryByCategoryProviderApi } from "../../api";
import { WATCH_CATEGORY,GET_CATEGORY, WATCH_CATEGORY_BY_PROVIDER } from "../../redux/actionTypes";

function* fetchCategorieAsyn() {
    const Data = yield call(fetchCategoryApi);
    yield put({type : GET_CATEGORY ,category : Data});
}
function* fetchCategorieByidProviderAsyn() {
    const Data = yield call(fetchCategoryByCategoryProviderApi);
    yield put({type : GET_CATEGORY ,category : Data});
}

export function* watchCategorieList() {
    yield takeLatest(WATCH_CATEGORY,fetchCategorieAsyn)
    yield takeLatest(WATCH_CATEGORY_BY_PROVIDER,fetchCategorieByidProviderAsyn)
}
