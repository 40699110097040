import axios from "axios";
import { db, urlbackend } from "../data/config";
import SweetAlert from "sweetalert2";
import moment from "moment";
import WebNotification from 'react-web-notifications'
export const fetchProductApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/product.json`);
};
export const fetchallInventairesDipensesByIdApi = async (idSubFranchise) => {
  
  
  const token = localStorage.getItem('intranetTypeUsertoken')

  return axios.get(`${urlbackend}inventaire/allstatbyfranchise_id.php?id=${idSubFranchise}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
    }
export const fetchInventairesDipensesByIdApi = async (idSubFranchise) => {
  
  
  const token = localStorage.getItem('intranetTypeUsertoken')

  return axios.get(`${urlbackend}inventaire/readbyfranchise_id.php?id=${idSubFranchise}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
    }
    export const fetchInventairesJouDipensesByIdApi = async (idSubFranchise,monthyear,year) => {
  
  
      const token = localStorage.getItem('intranetTypeUsertoken')
    
      return axios.get(`${urlbackend}inventaire/statsJoubyfranchise_id.php?id=${idSubFranchise}&month_year=${monthyear}&year=${year}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
      .then(res => {
      
        return res.data.records ?? []
    
         }).catch(erro => {return []})
        }
        export const fetchInventairesmensulleDipensesByIdApi = async (idSubFranchise,year) => {
  
  
          const token = localStorage.getItem('intranetTypeUsertoken')
        
          return axios.get(`${urlbackend}inventaire/statsmensuellebyfranchise_id.php?id=${idSubFranchise}&year=${year}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
          .then(res => {
          
            return res.data.records ?? []
        
             }).catch(erro => {return []})
            }
            export const fetchInventairesannualleDipensesByIdApi = async (idSubFranchise) => {
  
  
              const token = localStorage.getItem('intranetTypeUsertoken')
            
              return axios.get(`${urlbackend}inventaire/statsannuellebyfranchise_id.php?id=${idSubFranchise}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
              .then(res => {
              
                return res.data.records ?? []
            
                 }).catch(erro => {return []})
                }
export const fetchUpdateSouSTacheApi = async (
  idprojet,
  idtache,
  idsousTache,
  status,
  statussource,
  statusdestination
) => {

  await db
    .collection("Projects")
    .doc(idprojet)
    .collection("Taches")
    .doc(idtache).collection("SousTaches").doc(idsousTache)
    .update({
      status: status,
      updatedAt: moment(new Date()).format("yyyy-MM-DD HH:mm"),
    })
    .then((res2) => {
  
          SweetAlert.fire("Sous Tache " + status , "Sous Tache a été marquée comme "+ status, "success")
          return true;
   
    })
    .catch((e) => {
      return false;
    });
};

export const fetchUpdateTacheApi = async (
  idprojet,
  idtache,
  status,
  statussource,
  statusdestination
) => {

  await db
    .collection("Projects")
    .doc(idprojet)
    .collection("Taches")
    .doc(idtache)
    .update({
      status: status,
      updatedAt: moment(new Date()).format("yyyy-MM-DD HH:mm"),
    })
    .then((res2) => {
      db.collection("Projects")
        .doc(idprojet)
        .get()
        .then((res) => {
          let tacheafaire = res.data().numberTacheAfaire;
          let tacheaencours = res.data().numberTacheEnCours;
          let tacheaenTermine = res.data().numberTacheTermine;

          if (statussource == 1) {
            tacheafaire = Number(tacheafaire) - 1;
            if (statusdestination == 2) {
              tacheaencours = Number(tacheaencours) + 1;
              db.collection("Projects")
                .doc(idprojet)
                .update({numberTacheAfaire : tacheafaire,numberTacheEnCours: tacheaencours })
                .catch((e) => console.log(e));
            } else if (statusdestination == 3) {
              tacheaenTermine = Number(tacheaenTermine) + 1;
              db.collection("Projects")
                .doc(idprojet)
                .update({numberTacheAfaire : tacheafaire, numberTacheTermine: tacheaenTermine })
                .catch((e) => console.log(e));
            }
          } else if (statussource == 2) {
            tacheaencours = Number(tacheaencours) - 1;
            if (statusdestination == 1) {
              tacheafaire = Number(tacheafaire) + 1;
              db.collection("Projects")
                .doc(idprojet)
                .update({
                  numberTacheEnCours: tacheaencours,
                  numberTacheAfaire: tacheafaire,
                })
                .catch((e) => console.log(e));
            } else if (statusdestination == 3) {
              tacheaenTermine = Number(tacheaenTermine) + 1;
              db.collection("Projects")
                .doc(idprojet)
                .update({
                  numberTacheEnCours: tacheaencours,
                  numberTacheTermine: tacheaenTermine,
                })
                .catch((e) => console.log(e));
            }
          } else if (statussource == 3) {
            tacheaenTermine = Number(tacheaenTermine) - 1;
            if (statusdestination == 1) {
              tacheafaire = Number(tacheafaire) + 1;
              db.collection("Projects")
                .doc(idprojet)
                .update({numberTacheTermine : tacheaenTermine,numberTacheAfaire: tacheafaire })
                .catch((e) => console.log(e));
            } else if (statusdestination == 2) {
              tacheaencours = Number(tacheaencours) + 1;
              db.collection("Projects")
                .doc(idprojet)
                .update({numberTacheTermine : tacheaenTermine, numberTacheEnCours: tacheaencours })
                .catch((e) => console.log(e));
            }
          }
          SweetAlert.fire("Tache " + status , "Tache a été marquée comme "+ status, "success")
          return true;
        });
    })
    .catch((e) => {
      return false;
    });
};

export const fetchSousTacheApi = async (id, idtache) => {



  let taches = [];

  await db
    .collection("Projects")
    .doc(id)
    .collection("Taches").doc(idtache).collection("SousTaches")
    .get()
    .then((res) => {
      for (let key of res.docs) {
        taches.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await taches;
};
export const fetchDevSousTacheApi = async (id, idtache) => {



  let taches = [];

  await db
    .collection("DevProjects")
    .doc(id)
    .collection("Taches").doc(idtache).collection("SousTaches")
    .get()
    .then((res) => {
      for (let key of res.docs) {
        taches.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await taches;
};
export const fetchTacheApi = async (id) => {



  let taches = [];

  await db
    .collection("Projects")
    .doc(id)
    .collection("Taches")
    .get()
    .then((res) => {
      for (let key of res.docs) {
        taches.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await taches;
};

export const fetchProcessTacheApi = async (id,idfranchise) => {



  let taches = [];

  await db.collection("Franchises").doc(idfranchise).collection("ProcessDev").doc(id)
    .collection("Taches")
    .get()
    .then((res) => {
      for (let key of res.docs) {
        taches.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await taches;
};
export const fetchDevTacheApi = async (id) => {



  let taches = [];

  await db
    .collection("DevProjects")
    .doc(id)
    .collection("Taches")
    .get()
    .then((res) => {
      for (let key of res.docs) {
        taches.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await taches;
};
export const fetchNotificationsProviderApi = async () => {
  let id = localStorage.getItem("@intranetidUser");

  let Notifications = [];

  await db
    .collection("Notifications")
    .where("idUser", "==", id)
    .onSnapshot((res) => {
      for (let key of res.docChanges()) {
        if (Notifications.findIndex((res) => res.id === key.doc.id) > -1) {
        } else {
          if (key.doc.data().vue === 0)
            Notifications.push({
              ...key.doc.data(),
              id: key.doc.id,
            });
        }
      }
    });

  return await Notifications;
  // }
  // else  { let ConvMessage = []; return await ConvMessage}
};
export const gestionStockQuantite = async (order) => {
  let id = localStorage.getItem("@intranetidUser");
  let state = true;
  const token = localStorage.getItem('intranetTypeUsertoken')

    await axios({
      method: 'post',
      url: `${urlbackend}product/updatequantity.php`,
      data: JSON.stringify(order),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization': `Bearer ${token}` }}
    }).then(res => {
      state = true;
  
      return res;
    }).catch(e => {  state = false; return false})
   
  
  return await { state };
};
export const checkStockQuantite = async (order) => {
  let id = localStorage.getItem("@intranetidUser");
  let state = true;
  let productQteNotAvailble = [];
  console.log(order);
    const token = localStorage.getItem('intranetTypeUsertoken')
  for (const element of order) {

 await axios.get(`${urlbackend}product/read_one.php?id=${element.produit_id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
    if(res.data.succes === 1){
    
        let qte = Number(res.data.product.product_quantity);
      console.log(qte);
      if (qte < element.qty) {
        state = false;
        productQteNotAvailble.push(element);
        return element;
      }
    }
      

  })



  }
  return await { state, productQteNotAvailble };
};
export const fetchOrdersProviderApi = async () => {
  let id = localStorage.getItem("@intranetidUser");

  const token = localStorage.getItem('intranetTypeUsertoken')

  return axios.get(`${urlbackend}order/readbyidprovider.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
    }
export const fetchProjetsByIdUserApi = async () => {
  let id = localStorage.getItem("@intranetidUser");

  let projets = [];
  await db
    .collection("Projects").where("managerprojetId" , "==", id)
    .get()
    .then((res) => {
      for (let key of res.docs) {
        projets.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await projets;
};
export const fetchDevProjetsByIdUserApi = async () => {
  let id = localStorage.getItem("@intranetidUser");

  let projets = [];
  await db
    .collection("DevProjects").where("managerprojetId" , "==", id)
    .get()
    .then((res) => {
      for (let key of res.docs) {
        projets.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await projets;
};
export const fetchaddsondageApi = ( idNew,contenu ) => {
const idUser = localStorage.getItem("@intranetidUser")

const token = localStorage.getItem("intranetTypeUsertoken")

let data = {"news_id": idNew, "sondage_id": contenu, "user_id": idUser}
 return axios({
  method: 'post',
  url: `${urlbackend}sondage_users_news/create.php`,
  data: JSON.stringify(data),
  config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization': `Bearer ${token}` }}
}).then(res => {

  console.log(res)
  return res;
}).catch(e => {return false})


 

  }
export const fetchProjetsApi = async () => {
  let id = localStorage.getItem("@intranetidUser");

  let projets = [];
  await db
    .collection("Projects")
    .get()
    .then((res) => {
      for (let key of res.docs) {
        projets.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await projets;
};

export const fetchDevProcessApi = async (idFranchise) => {


  let projets = [];
  await db.collection("Franchises").doc(idFranchise)
    .collection("ProcessDev")
    .get()
    .then((res) => {
      for (let key of res.docs) {
        projets.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await projets;
};

export const fetchDevProjetsApi = async (idUser) => {


  let projets = [];
  await db
    .collection("DevProjects").where("developerListe", 'array-contains', idUser)
    .get()
    .then((res) => {
      for (let key of res.docs) {
        projets.push({
          ...key.data(),
          id: key.id,
        });
      }
    });

  return await projets;
};

export const fetchOrdersFranchiseApi = async () => {
  let id = localStorage.getItem("@intranetidUser");
let idfranchise =localStorage.getItem("@intranet_franchiseid"); 
  const token = localStorage.getItem('intranetTypeUsertoken')

  return axios.get(`${urlbackend}order/readbyidfranchise.php?id=${idfranchise}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
    }
export const fetchAllOrdersApi1 = async () => {

  const token = localStorage.getItem('intranetTypeUsertoken')

  return axios.get(`${urlbackend}order/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
    }

export const fetchChatApi1 = () => {
  // let id =  localStorage.getItem("@intranetidUser");
  // db.collection("Messages").where(id, 'in', "users").get().then(res => {
  //   console.log()
  // })
  const token = localStorage.getItem('intranetTypeUsertoken')
  return axios.get(`${process.env.PUBLIC_URL}/api/chatMember.json`);
};
export const fetchChatApi3 = async (id) => {
  let idconv = localStorage.getItem("@intranetidConvMessage");
console.log(id)
  let ConvMessage = [];
  if (id !== null && id !== undefined) {
    await db
      .collection("Messages")
      .doc(id.id)
      .collection("Conversations")
      .orderBy("createdAt")
      .onSnapshot((res) => {
        for (let key of res.docChanges()) {
          if (ConvMessage.findIndex((res) => res.id === key.doc.id) > -1) {
          } else
            ConvMessage.push({
              ...key.doc.data(),
              id: key.doc.id,
            });
        }
      });
  }

  return await ConvMessage;
  // }
  // else  { let ConvMessage = []; return await ConvMessage}
};
export const fetchChatApi2 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chat.chats.json`);
};

export const fetchInventairesDipensesApi = async () => {
  let idFranch = localStorage.getItem("@intranetIdFranchiseUser");
  let lastDate = localStorage.getItem("@intranetIdFranchiseDateInventaire");
  const token = localStorage.getItem('intranetTypeUsertoken')

  return axios.get(`${urlbackend}inventaire/read.php?id=${idFranch}&lastdate=${lastDate}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
    }

const alertnotif = () => {
  return  <WebNotification
title="Hello, World!" // the title prop is required
icon="path/to/image.jpg"
body="This is a web notification"
timeout={9000}
onClickFn={ () => window.open('http://www.google.com/', '_blank') } // open your own site on notification click
/>
}
export const fetchAllConvMessage = async () => {
  let id = localStorage.getItem("@intranetidUser");
  let ConvMessage = [];
  const folds = await db
    .collection("Messages")
    .orderBy("lastMessageTime", "desc");
  folds.onSnapshot((res) => {
    for (let key of res.docChanges()) {
      if (key.doc.data().users.includes(id) === true) {
        if (ConvMessage.findIndex((res) => res.id === key.doc.id) > -1) {
          ConvMessage = ConvMessage.map((item) =>
            item.id === key.doc.id
              ? (item.lastMessageTime = key.doc.data().lastMessageTime, item.lastmessage = key.doc.data().lastmessage)
              : item
          );
        } else {
          alertnotif()
         
          let usersInfos = [];
          for (let inuse = 0 ; inuse< key.doc.data().usersDetails.length ; inuse++){
            let sdtat = key.doc.data().usersDetails[inuse]
db.collection(sdtat.type).doc(sdtat.id).get().then(reuser => {
  if(reuser.data() !== undefined && sdtat.type === 'Admin') 
  usersInfos.push({ ...reuser.data(),avatar : reuser.data().userImage  ,  fullname : "Administrateur", id : reuser.id})

if(reuser.data() !== undefined && sdtat.type === 'Users') 
  usersInfos.push({ ...reuser.data(),avatar : reuser.data().userImage  ,  fullname : reuser.data().lastName + " "+ reuser.data().firstName, id : reuser.id})
  else if(reuser.data() !== undefined && sdtat.type === 'Fournisseurs') 
  usersInfos.push({ ...reuser.data(), avatar : reuser.data().fournisseurImage  ,  fullname : reuser.data().lastName + " "+ reuser.data().firstName, id : reuser.id})
})
          }
          ConvMessage.push({
            ...key.doc.data(),
            id: key.doc.id,
           // usersInfos : usersInfos
          });
        }
         
      }
    }
  });

  return await ConvMessage;
};

export const fetchEmailApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/email.json`);
};

export const fetchBookmaekApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/bookmark.json`);
};

export const fetchNewApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')

  return axios.get(`${urlbackend}news/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};

export const fetcheventCategoryApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return axios.get(`${urlbackend}eventcategory/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};



export const fetchevenetsByidApi = async () => {
  const newac = await db.collection("EVENTS").where("marqueId", '==' ,localStorage.getItem('@intranetidMarqueUser') ).get();
  let news = [];

  for await (let key of newac.docs) {
    news.push({
      ...key.data(),
      start: new Date(key.data().start),
      end: new Date(key.data().end),
      id: key.id,
    });
  }

  return news;
};
export const fetchevenetsByfourniiseursByidApi = async () => {
  const newac = await db.collection("EVENTS").where("idProp", '==' ,localStorage.getItem('@intranetidUser') ).get();
  let news = [];

  for await (let key of newac.docs) {
    news.push({
      ...key.data(),
      start: new Date(key.data().start),
      end: new Date(key.data().end),
      id: key.id,
    });
  }

  return news;
};
export const fetchNewByidFournisseruApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')

  return axios.get(`${urlbackend}news/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};
export const fetchNewByidApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const marqueid = localStorage.getItem('@intranetidMarqueUser')

  return axios.get(`${urlbackend}news/readNewByMarque.php?id=${marqueid}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
  .then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};

export const fetchEmailsApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const id = localStorage.getItem('@intranetidUser')
  return axios.get(`${urlbackend}mailuser/read.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};
export const fetchEventsApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return axios.get(`${urlbackend}events/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};
export const fetchSubCategoryApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}subcategory/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};


export const fetchSubCategoryByCategoryApi = async (cat_id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')

  
  return  await axios.get(`${urlbackend}subcategory/read_by_catid.php?id=${cat_id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};
export const fetchCategoryByCategoryProviderApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const provider_cat_id = localStorage.getItem('@intranetProviderCategoryId')
  
  return  await axios.get(`${urlbackend}category/read_by_id_provider.php?id=${provider_cat_id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};
export const fetchCategoryApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}category/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};

export const fetchFranchiseApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}franchise/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};

export const fetchProductsByProviderIdApi = async () => {

   
   const provider_id =  localStorage.getItem("@intranetselectedProviderId")
    const token = localStorage.getItem('intranetTypeUsertoken')
    return  await axios.get(`${urlbackend}product/read.php?id=${provider_id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
    
      return res.data.records ?? []
  
       }).catch(erro => {return []})
  
};
export const fetchProductsByIdFournisseurApi = async () => {
 const provider_id =  localStorage.getItem("@intranetidUser")
    const token = localStorage.getItem('intranetTypeUsertoken')
    return  await axios.get(`${urlbackend}product/read.php?id=${provider_id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
    
      return res.data.records ?? []
  
       }).catch(erro => {return []})
  
};
export const fetchFournisseurApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}user/readprovider.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []

     }).catch(erro => {return []})
};
export const fetchallUserApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}user/readallUser.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
  
  // for await (let key of user.docs) {
  //   users.push({
  //     ...key.data(),
  //     id: key.id,
  //   });
  // }

  // return users;
};
export const fetchUserApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}user/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
  
  // for await (let key of user.docs) {
  //   users.push({
  //     ...key.data(),
  //     id: key.id,
  //   });
  // }

  // return users;
};
export const fetchallUserResponsableApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}user/readallresponsable.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
  
     }).catch(erro => {return []})
  
  // for await (let key of user.docs) {
  //   users.push({
  //     ...key.data(),
  //     id: key.id,
  //   });
  // }

  // return users;
};
export const fetchUserResponsableApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}user/readresponsable.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  console.log(res)
    return res.data.records ?? []
  
     }).catch(erro => {return []})
  
  // for await (let key of user.docs) {
  //   users.push({
  //     ...key.data(),
  //     id: key.id,
  //   });
  // }

  // return users;
};
export const fetchallUserResponsableFranchiseApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}user/readallresponsablefranchise.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
  
  // for await (let key of user.docs) {
  //   users.push({
  //     ...key.data(),
  //     id: key.id,
  //   });
  // }

  // return users;
};
export const fetchUserResponsableFranchiseApi = async () => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}user/readresponsablefranchise.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
  
  // for await (let key of user.docs) {
  //   users.push({
  //     ...key.data(),
  //     id: key.id,
  //   });
  // }

  // return users;
};
export const fetchProjetByIdApi = async (id) => {
  const user = await db.collection("Projects").doc(id).get();

  return {
    ...user.data(),
    id: user.id,
  };
};

export const fetchProductsByIdApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}product/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.product : {},
    id: user.succes === 1 ? user.product.product_id : id,
  };
};
export const fetchDeatilsOrder = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}order/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.order : {},
    id: user.succes === 1 ? user.order.commande_id : id,
  };
};

export const fetchUsersByIdApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}user/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.user : {},
    id: user.succes === 1 ? user.user.user_id : id,
  };
};

export const fetchEventById = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}events/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.events : {},
    id: user.succes === 1 ? user.events.events_id : id,
  };
};
export const fetchCategoryprovider = async () => {

  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}providercategory/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})

};
export const fetchFournisseurByIdApi = async (id) => {

  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}user/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.user : {},
    id: user.succes === 1 ? user.user.user_id : id,
  };
};
export const fetchNewsByIdApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}news/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.news : {},
    id: user.succes === 1 ? user.news.news_id : id,
  };
};
export const fetchSubFranchiseByIdApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}subfranchise/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.franchise : {},
    id: user.succes === 1 ? user.franchise.subfranchise_id : id,
  };
};
export const fetchFranchiseByIdApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
 return await axios.get(`${urlbackend}franchise/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return {
      ...res.data.succes === 1 ? res.data.franchise : {},
      id: res.data.succes === 1 ? res.data.franchise.franchise_id : id,
    };

  }).catch(error => {
    return {
      ...{},
      id: id,
    };
  })

 
};
export const fetchEventByIdApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
 return await axios.get(`${urlbackend}events/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
console.log(res)
    return {
      ...res.data.succes === 1 ? res.data.events : {},
      id: res.data.succes === 1 ? res.data.events.events_id : id,
    };

  }).catch(error => {
    return {
      ...{},
      id: id,
    };
  })

 
};
export const fetchAuditByIdApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}audit/read.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})

};
export const addAnnuiareFranchise = async (data) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  axios({
      method: 'post',
      url: `${urlbackend}annuaire_jot/create.php`,
      data: JSON.stringify(data),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
  }).then(res => {
  
    return true;
    
    
     }).catch(erro => {
       return false
      })

};
export const EditAnnuaire = async (data) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  axios({
      method: 'post',
      url: `${urlbackend}annuaire_jot/update.php`,
      data: JSON.stringify(data),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
  }).then(res => {
  
    return true;
    
    
     }).catch(erro => {
       return false
      })

};
export const EditAudit = async (data) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  axios({
      method: 'post',
      url: `${urlbackend}audit/update.php`,
      data: JSON.stringify(data),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
  }).then(res => {
  
    return true;
    
    
     }).catch(erro => {
       return false
      })

};
export const addAuditFranchise = async (data) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  axios({
      method: 'post',
      url: `${urlbackend}audit/create.php`,
      data: JSON.stringify(data),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
  }).then(res => {
  
    return true;
    
    
     }).catch(erro => {
       return false
      })

};
export const deleteAudit = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  axios({
      method: 'delete',
      url: `${urlbackend}audit/delete.php`,
      data: JSON.stringify({"audit_id" : id}),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
  }).then(res => {
  
    return true;
    
    
     }).catch(erro => {
       return false
      })

};
export const deleteAnnuaire = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  axios({
      method: 'delete',
      url: `${urlbackend}annuaire_jot/delete.php`,
      data: JSON.stringify({"annuaire_id" : id}),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
  }).then(res => {
  
    return true;
    
    
     }).catch(erro => {
       return false
      })

};
export const fetchAnnuaireByIdApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}annuaire_jot/read.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})

};
export const fetchSubFranchiseByIdsubApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}subfranchise/read_one.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.franchise : {},
    id: user.succes === 1 ? user.franchise.franchise_id : id,
  };
};
export const fetchUserByIdApi = async (id) => {
  
  const user = await db.collection("Users").doc(id).get();

  return {
    ...user.data(),
    id: user.id,
  };
};

export const fetchFileByIdUserApi = async (id) => {
  const token = localStorage.getItem('intranetTypeUsertoken')

  return  await axios.get(`${urlbackend}folders/readAllFolderByuserId.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
  return res.data.records ?? []
  
  
   }).catch(erro => {return []})

};

export const fetchShareFoldersApi = async () => {

  const token = localStorage.getItem('intranetTypeUsertoken')
  const id= localStorage.getItem("@intranetidUser")
  return  await axios.get(`${urlbackend}folders/readAllShareFolder.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
  return res.data.records ?? []
  
  
   }).catch(erro => {return []})

  }
  
  export const fetchDeletedFoldersApi = async () => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    const id= localStorage.getItem("@intranetidUser")
    return  await axios.get(`${urlbackend}folders/readAllDeletedFolderByuserId.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
    
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
  
    }
export const fetchFavorisFoldersApi = async () => {
  const id= localStorage.getItem("@intranetidUser")
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}folders/readAllFavorisFolder.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
  return res.data.records ?? []
  
  
   }).catch(erro => {return []})

  }
export const fetchFileApi = async () => {

  const token = localStorage.getItem('intranetTypeUsertoken')
  const id= localStorage.getItem("@intranetidUser")

return  await axios.get(`${urlbackend}folders/readAllFolderByuserId.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

return res.data.records ?? []


 }).catch(erro => {return []})

 
//   const folds = await db
//     .collection("Folders")
//     .where("idfolder", "==", "racine")
//     .get();
//   let folders = [];

//   for await (let key of folds.docs) {
//     let usersInfos = [];
//     let propName = "";
//     let propImage = ""
//     if(key.data().proprietaireType === "admin"){
//       await db.collection("Admin").doc(key.data().proprietaireId).get().then(reu => {
//         propName = reu.data().firstname + " " + reu.data().lastname
//         propImage= reu.data().userImage
//         })
//     }
//     else if(key.data().proprietaireType === "manager" || key.data().proprietaireType === "submanager"){
//       await db.collection("Users").doc(key.data().proprietaireId).get().then(reu => {
//       propName = reu.data().firstName + " " + reu.data().lastName
//       propImage= reu.data().userImage
//       })
//     }
//     else if(key.data().proprietaireType === "provider"){
//       await db.collection("Fournisseurs").doc(key.data().proprietaireId).get().then(reu => {
//       propName = reu.data().firstName + " " + reu.data().lastName
//       propImage= reu.data().fournisseurImage
//       })
//     }
//     if(key.data().userListeType !== undefined){
//       for (let inuse = 0 ; inuse< key.data().userListeType.length ; inuse++){
//             let sdtat = key.data().userListeType[inuse]
// db.collection(sdtat.type).doc(sdtat.id).get().then(reuser => {

// if(reuser.data() !== undefined && sdtat.type === 'Users') 
//   usersInfos.push({ ...reuser.data(),avatar : reuser.data().userImage  ,  fullname : reuser.data().lastName + " "+ reuser.data().firstName, id : reuser.id})
//   else if(reuser.data() !== undefined && sdtat.type === 'Fournisseurs') 
//   usersInfos.push({ ...reuser.data(), avatar : reuser.data().fournisseurImage  ,  fullname : reuser.data().lastName + " "+ reuser.data().firstName, id : reuser.id})
// })
//           }
//         }
    
  //}

 // return await folders;
};

export const fetchAllSubfranchiseApi = async () => {
  if(localStorage.getItem("@intranetTypeUser") === "admin"){
    const data = localStorage.getItem("@intranetidMarqueUser");
    const token = localStorage.getItem('intranetTypeUsertoken')
    return  await axios.get(`${urlbackend}subfranchise/readall.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
      return res.data.records ?? []
      
      
       }).catch(erro => {return []})
}
else if(localStorage.getItem("@intranetTypeUser") === "manager"){
  const folds = await db
  const data = localStorage.getItem("@intranetidMarqueUser");
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}subfranchise/read.php?id=${data}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
};

};
export const fetchSubfranchiseApi = async () => {
  const data = localStorage.getItem("@intranet_franchiseid");
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}subfranchise/read.php?id=${data}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
};

export const fetchfranchiseByidManagerApi = async () => {
  const data = localStorage.getItem('@intranetidMarqueUser');
  const folds = await db
    .collection("Franchises").doc(data)
    .get();
  let folders = {};

  folders = {
      ...folds.data(),
      id: folds.id,
    }
  

  return folders;
};


export const fetchfranchiseDetailsByidManagerApi = async () => {
  const data = localStorage.getItem("@intranetidUser");
  const folds = await db
    .collection("Franchises")
    .where("managerID", "==", data)
    .get();
  let folders = [];

  for await (let key of folds.docs) {
    folders.push({
      ...key.data(),
      id: key.id,
    });
  }

  return folders;
};

export const fetchSubfranchiseByidManagerApi = async () => {
  const data = localStorage.getItem("@intranetidUser");

  const token = localStorage.getItem('intranetTypeUsertoken')
  const user = await (await axios.get(`${urlbackend}subfranchise/read_one_byid_manager.php?id=${data}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})).data

  return {
    ...user.succes === 1 ? user.franchise : {},
    id: user.succes === 1 ? user.franchise.subfranchise_id : data,
  };
};
export const fetchSubFolderApi = async (id) => {
  const data = localStorage.getItem("idfolder");
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}folders/readAllFolderByIdParent.php?id=${data}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
//   
//   const folds = await db
//     .collection("Folders")
//     .where("idfolder", "==", data)
//     .get();
//   let folders = [];

//   for await (let key of folds.docs) {
//     let usersInfos = [];
//     let propName = "";
//     let propImage = ""
//     if(key.data().proprietaireType === "admin"){
//       await db.collection("Admin").doc(key.data().proprietaireId).get().then(reu => {
//         propName = reu.data().firstname + " " + reu.data().lastname
//         propImage= reu.data().userImage
//         })
//     }
//     else if(key.data().proprietaireType === "manager" || key.data().proprietaireType === "submanager"){
//       await  db.collection("Users").doc(key.data().proprietaireId).get().then(reu => {
//       propName = reu.data().firstName + " " + reu.data().lastName
//       propImage= reu.data().userImage
//       })
//     }
//     else if(key.data().proprietaireType === "provider"){
//       await  db.collection("Fournisseurs").doc(key.data().proprietaireId).get().then(reu => {
//       propName = reu.data().firstName + " " + reu.data().lastName
//       propImage= reu.data().fournisseurImage
//       })
//     }
//     if(key.data().userListeType !== undefined){
//       for (let inuse = 0 ; inuse< key.data().userListeType.length ; inuse++){
//             let sdtat = key.data().userListeType[inuse]
// db.collection(sdtat.type).doc(sdtat.id).get().then(reuser => {

// if(reuser.data() !== undefined && sdtat.type === 'Users') 
//   usersInfos.push({ ...reuser.data(),avatar : reuser.data().userImage  ,  fullname : reuser.data().lastName + " "+ reuser.data().firstName, id : reuser.id})
//   else if(reuser.data() !== undefined && sdtat.type === 'Fournisseurs') 
//   usersInfos.push({ ...reuser.data(), avatar : reuser.data().fournisseurImage  ,  fullname : reuser.data().lastName + " "+ reuser.data().firstName, id : reuser.id})
// })
//           }
//         }
//     folders.push({
//       ...key.data(),
//       id: key.id,
//       usersInfos: usersInfos,
//       propName :propName,
//       propImage : propImage
//     });
//   }

//   return folders;
};

export const fetchFilesByFolderApi = async (id) => {

  const data = localStorage.getItem("idfolder");
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}files/readAllFileByIdParent.php?id=${data}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
};
export const addProviderTofavoriteFranchise = (provider) => {
  let iduser = localStorage.getItem("@intranetidUser");
  db.collection("Users")
    .doc(iduser)
    .get()
    .then((res) => {
      let providerListe = res.data().providerListe || [];
      let data = {};
      data.lastName = provider.lastName;
      data.firstName = provider.firstName;
      data.region = provider.region;

      data.email = provider.email;
      data.id = provider.id;

      data.fournisseurImage = provider.fournisseurImage;

      data.adresse = provider.adresse;
      data.phoneNumber = provider.phoneNumber;
      data.category = provider.category;
      providerListe.push(data);
      db.collection("Users")
        .doc(iduser)
        .update({ providerListe: providerListe });
      localStorage.setItem(
        "@intranetProviderListe",
        JSON.stringify(providerListe)
      );
    });
};
export const removeProviderFromfavoriteFranchise = (provider) => {
  let iduser = localStorage.getItem("@intranetidUser");
  db.collection("Users")
    .doc(iduser)
    .get()
    .then((res) => {
      let providerListe = res.data().providerListe || [];
      console.log(providerListe);
      providerListe = providerListe.filter((res) => res.id !== provider.id);
      db.collection("Users")
        .doc(iduser)
        .update({ providerListe: providerListe });
      localStorage.setItem(
        "@intranetProviderListe",
        JSON.stringify(providerListe)
      );
    });
};
export const validerCommande = (commande, notif) => {
  let iduser = localStorage.getItem("@intranetidUser");
  const token = localStorage.getItem('intranetTypeUsertoken')

    return axios({
        method: 'post',
        url: `${urlbackend}order/create.php`,
        data: JSON.stringify(commande),
        config:    { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Commande ajouté',
            'Commande a été ajouté avec succés',
            'success'
          )
  
return response
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            "Erreur survenue au cours de l'ajout veuillez réessayer",
            'success'
          )
          return response
      
    });
};

export const fetchGroupApi = async () => {
  
  const token = localStorage.getItem('intranetTypeUsertoken')

  return  await axios.get(`${urlbackend}groupetravail/read.php`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})

};


export const fetchFilesShareByIdUserApi = async () => {
  
  let iduser = localStorage.getItem("@intranetidUser");
  let token = localStorage.getItem("intranetTypeUsertoken");
  return  await axios.get(`${urlbackend}files/readAllShareFile.php?id=${iduser}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
  
    return res.data.records ?? []
    
    
     }).catch(erro => {return []})

};

export const fetchFilesDeletedByIdUserApi = async () => {
  
  let iduser = localStorage.getItem("@intranetidUser");

  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}files/readAllFileDeletedByuserId.php?id=${iduser}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? [] 
    
     }).catch(erro => {

      return []})

};

export const fetchFilesFavorisByIdUserApi = async () => {
  
    let iduser = localStorage.getItem("@intranetidUser");
    const token = localStorage.getItem('intranetTypeUsertoken')
    return  await axios.get(`${urlbackend}files/readAllFavorisFile.php?id=${iduser}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
    
      return res.data.records ?? [] 
      
      
       }).catch(erro => {return []})

  };
export const fetchFilesByIdUserApi = async (id) => {

  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}files/readAllFileByuserId.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? [] 
    
    
     }).catch(erro => {return []})
};
export const fetchFilesApi = async () => {
  let iduser = localStorage.getItem("@intranetidUser");
  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}files/readAllFileByuserId.php?id=${iduser}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
//   const folds = await db.collection("Files").get();
//   let Files = [];

//   for await (let key of folds.docs) {
//     let usersInfos = [];
//     let propName = "";
//     let propImage = "";
//     if(key.data().proprietaireType === "admin"){
//      await  db.collection("Admin").doc(key.data().proprietaireId).get().then(reu => {
     
//         propName = reu.data().firstname + " " + reu.data().lastname
//         propImage= reu.data().userImage
//         })
//     }
//     else if(key.data().proprietaireType === "manager" || key.data().proprietaireType === "submanager"){
//       await db.collection("Users").doc(key.data().proprietaireId).get().then(reu => {
//       propName = reu.data().firstName + " " + reu.data().lastName
//       propImage = reu.data().userImage
//       })
//     }
//     else if(key.data().proprietaireType === "provider"){
//       await db.collection("Fournisseurs").doc(key.data().proprietaireId).get().then(reu => {
//       propName = reu.data().firstName + " " + reu.data().lastName
//       propImage= reu.data().fournisseurImage
//       })
//     }
//     if(key.data().userListeType !== undefined){
//       for (let inuse = 0 ; inuse< key.data().userListeType.length ; inuse++){
//             let sdtat = key.data().userListeType[inuse]
// db.collection(sdtat.type).doc(sdtat.id).get().then(reuser => {

// if(reuser.data() !== undefined && sdtat.type === 'Users') 
//   usersInfos.push({ ...reuser.data(),avatar : reuser.data().userImage  ,  fullname : reuser.data().lastName + " "+ reuser.data().firstName, id : reuser.id})
//   else if(reuser.data() !== undefined && sdtat.type === 'Fournisseurs') 
//   usersInfos.push({ ...reuser.data(), avatar : reuser.data().fournisseurImage  ,  fullname : reuser.data().lastName + " "+ reuser.data().firstName, id : reuser.id})
// })
//           }
//         }
//     Files.push({
//       ...key.data(),
//       id: key.id,
//       usersInfos: usersInfos,
//       propName :propName,
//       propImage : propImage
//     });
//   }

//   return await Files;
};

export const fetchInventairesApi = async (iduser) => {
  //    let iduser = localStorage.getItem("@intranetidSubfranchise")
  const folds = await db
    .collection("Subfranchises")
    .doc(iduser)
    .collection("Inventaires")
    .get();
  let Files = [];

  for await (let key of folds.docs) {
    Files.push({
      ...key.data(),
      id: key.id,
    });
  }

  return await Files;
};
export const fetchDispensesApi = async (iduser) => {
  //    let iduser = localStorage.getItem("@intranetidSubfranchise")
  const folds = await db
    .collection("Subfranchises")
    .doc(iduser)
    .collection("Dipenses")
    .get();
  let Files = [];

  for await (let key of folds.docs) {
    Files.push({
      ...key.data(),
      id: key.id,
    });
  }

  return await Files;
};
export const fetchSendemailsApi = async (
  selectedEmails,
  message,
  object,
  destinataire
) => {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
console.log(destinataire)
  const instance = axios.create({});

  // Alter defaults after instance has been created

  instance.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  instance
    .post(
      `${urlbackend}test/imap_send_email.php`,
      {
        mailfrom: selectedEmails,
        mailto: destinataire,
        message: message,
        subject: object,
      },
      {
        timeout: 5000,
      }
    )
    .then((res) =>
      SweetAlert.fire("Email Envoyé!", "Votre email a été envoyé", "success")
    )
    .catch((e) => console.log(e));
  //  return  axios.post("${urlbackend}test/imap_send_email.php",{mailfrom : selectedEmails,mailto : destinataire,message : message, subject : object}).then(res => console.log(res)).catch(e => console.log(e))
};

export const fetchSendemailsBienvenueApi = async (
  message,
  object,
  destinataire
) => {
 

  const instance = axios.create({});

  // Alter defaults after instance has been created

  instance.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  instance
    .post(
      `${urlbackend}test/imap_send_welcome_email.php`,
      {
        mailfrom: "gocloud@tmconsultings.com",
        mailto: destinataire,
        message: message,
        subject: object,
      },
      {
        timeout: 5000,
      }
    )
    .then((res) =>
    console.log(res)
     // SweetAlert.fire("Email Envoyé!", "Votre email a été envoyé", "success")
    )
    .catch((e) => console.log(e));
  //  return  axios.post("${urlbackend}test/imap_send_email.php",{mailfrom : selectedEmails,mailto : destinataire,message : message, subject : object}).then(res => console.log(res)).catch(e => console.log(e))
};
export const fetchemailsApi = async () => {

  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");
  let selectedmail = localStorage.getItem("@intranetselectedPageMail") || 1
  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/imap_parse18.php?driver=${imap}&email=${email}&password=${password}&selectedmail=${selectedmail}`
    );
  else return { allMessages: [], numbersMsg: 0 ,getAllEmails : 0};
};

export const fetchnumberemailsApi = async (imap,email,password) => {

//let imap = localStorage.getItem("@intranetimapDriver");
 // let email = localStorage.getItem("@intranetimapemail");
  //let password = localStorage.getItem("@intranetimappassword");
  let selectedmail =  1
  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/numberofmessage.php?driver=${imap}&email=${email}&password=${password}&selectedmail=${selectedmail}`
    );
  else return {numbersMsg: 0 ,getAllEmails : 0};
};
export const fetchsendemailsApi = async () => {

  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");

  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/imap_parsemessageSent.php?driver=${imap}&email=${email}&password=${password}`
    );
  else return { allMessages: [], numbersMsg: 0 };
};
export const fetchtrashemailsApi = async () => {


  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");

  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/imap_parsemessagetrash.php?driver=${imap}&email=${email}&password=${password}`
    );
  else return { allMessages: [], numbersMsg: 0 };
};
export const fetchemailsApiBy = (id) => {
  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");
  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/getmail.php?driver=${imap}&email=${email}&password=${password}&id=${id}`
    );
  else return [];
};

export const fetchemailssendApiBy = (id) => {
  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");
  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/getmailsend.php?driver=${imap}&email=${email}&password=${password}&id=${id}`
    );
  else return [];
};

export const fetchemailstrashApiBy = (id) => {
  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");
  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/getmailtrash.php?driver=${imap}&email=${email}&password=${password}&id=${id}`
    );
  else return [];
};

export const fetchemailsdeleteFromtrashApiBy = (id) => {
  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");
  console.log(id)
  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/deletemailfromTrash.php?driver=${imap}&email=${email}&password=${password}&id=${id}`
    );
  else return [];
};
export const fetchemailsdeleteFromInboxApiBy = (id) => {
  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");
  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/deletemailfromInbox.php?driver=${imap}&email=${email}&password=${password}&id=${id}`
    );
  else return [];
};
export const fetchemailsdeleteFromSendpiBy = (id) => {
  let imap = localStorage.getItem("@intranetimapDriver");
  let email = localStorage.getItem("@intranetimapemail");
  let password = localStorage.getItem("@intranetimappassword");
  if (email !== undefined)
    return axios.get(
      `${urlbackend}test/deletemailfromSend.php?driver=${imap}&email=${email}&password=${password}&id=${id}`
    );
  else return [];
};
export const fetchTodoApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/todo.json`);
};

export const fetchMailGroupApi = async () => {
  let id = localStorage.getItem("@intranetidUser")

  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}mailgroupe/read.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
};
export const fetchNoteApi = async () => {

  let id = localStorage.getItem("@intranetidUser")

  const token = localStorage.getItem('intranetTypeUsertoken')
  return  await axios.get(`${urlbackend}note/read.php?id=${id}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {

    return res.data.records ?? []
    
    
     }).catch(erro => {return []})
};
export const fetchTaskApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/task.json`);
};

export const fetchProjectApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/project.json`);
};

