import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, ModalHeader, ModalBody,  Button, ModalFooter ,Table, Modal} from 'reactstrap'

import { useForm } from 'react-hook-form'
import defaultImg from '../../../assets/images/lightgallry/01.jpg'
import {  useDispatch } from 'react-redux'
import SweetAlert from 'sweetalert2'
import { mybookmark, newbookmark,  removemybookmark, updatebookmark, updateMybookmark } from '../../../redux/bookmark/action'
import { useEffect } from 'react';

import {Cancel, UsersTableTitle} from "../../../constant";
import { useParams } from 'react-router';

import { addAudit } from '../../../redux/franchise/action';

import axios from 'axios';

const SubmissionsAuditList = (props) => {

  const dispatch = useDispatch();
  const [franchise, setFranchise] = useState({});

const {idaudit} = useParams()
  const [editrow, setEditrow] = useState({})

  const [addModal, setaddModal] = useState(false)
  const [editModal, seteditModal] = useState(false)
 
  const [auditModal, setauditModal] = useState(false)
  const [selectedUrl, setselectedUrl] = useState({})
  
  const [tagModal, setTagModal] = useState(false)

  const [json,setjson] = useState({});
  const { register, handleSubmit, errors } = useForm();


  const editAuditToggle = () => { setauditModal(!auditModal) }
  const editToggle = () => { seteditModal(!editModal) }
  const [addauditModal, setaddauditModal] = useState(false)
  const addAuditToggle = () => { setaddauditModal(!addauditModal) }

  
  useEffect(() => {
axios.post(`https://gocloud.groupe-fbh.com/intranet/api/test.php`).then(res=> console.log(res)).catch(e => console.log(e))
    axios.get(`https://api.jotform.com/form/${idaudit}/submissions?apikey=525f76954b7952086e38d090e0777800&orderby=id`).then(res =>{
   console.log(res)
    setjson(res)
   
   } )

  //  dispatch({type: WATCH_BOOKMARK_LIST})
  },[dispatch])


  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="Liste Reponses" />
      <Container fluid={true}>
      {/* <div className="media-body text-right">
                <div className="d-inline-flex">

                  <button onClick={() => addAuditToggle()} className="btn btn-primary" > {"Créer audit"}</button>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </div>
              
              </div> */}
        <div className="email-wrap bookmark-wrap">
        {/* <JsonTable  json={json} /> */}
          <Row>
           
          <Col md="12">
<Card>
  <CardHeader>

    <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
  </CardHeader>
  <div className="table-responsive">
    <table className="table card-table table-vcenter text-nowrap">
      {/* <thead>
        <tr>
          {UsersTableHeader.map((items,i) => 
            <th key={i}>{items}</th>
          )}
        </tr>
      </thead> */}
      <tbody>
     
        {json.data !== undefined ? json.data.content?.map((items,i) => 
          <tr key={i}>
            <td><a className="text-inherit" href="#javascript">{items.form_id} </a></td>
            <td>{items.id}</td>
            <td><span className="status-icon bg-success"></span>{items.created_at}</td>
            <td>{items.status}</td>
            <td className="text-right">
  
              <Button onClick={() => {
                  editAuditToggle()
                  setselectedUrl(items.answers)
              }} color="primary" size="sm"> {"Voir reponse"}</Button>
            </td>
          </tr>
        ) : <></>}
        
      </tbody>
    </table>
  </div>
</Card>
</Col>
          </Row>
          <Modal  isOpen={auditModal} toggle={editAuditToggle} size="xl">
                        <ModalHeader toggle={editAuditToggle}>{"Details Answers"}</ModalHeader>
                        <ModalBody>
                        <div className="table-responsive">
    <Table >
   <thead>
        <tr>
        <th  style={{color : 'rgba(255, 255, 255, 0.7)'}} scope="row">{"Nom"}</th>
             <th  style={{color : 'rgba(255, 255, 255, 0.7)'}} scope="row">{"response"}</th>
        </tr>
      </thead>
      <tbody>
     
                         {/* <JsonTable json={selectedUrl} /> selectedUrl[res].text?.includes("p") === true ? <div dangerouslySetInnerHTML={{ __html : selectedUrl[res].text}}> </div> :  */ }
             {Object.keys(selectedUrl).map((res,key )=> 
              
<tr>
  <td style={{color : 'rgba(255, 255, 255, 0.7)'}}>className="text-right" { selectedUrl[res].text?.includes("<") === true ? <div dangerouslySetInnerHTML={{ __html : `<p style="text-align: center;"><strong><span style="font-family: arial, helvetica, sans-serif; font-size: 14pt;">Accord d'ouverture du restaurant</span></strong></p>`}}></div> : selectedUrl[res].text} </td>
  <td  style={{color : 'rgba(255, 255, 255, 0.7)'}} colSpan={2}><p style={{width : '700px'}}>{JSON.stringify(selectedUrl[res].answer) }</p></td>

</tr>

    )
 
   }
        </tbody>
    </Table>
   </div> 
                  
                        
                    
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={editAuditToggle}>{Cancel}</Button>
                        </ModalFooter>
                      </Modal>
        </div>
      </Container>
    </Fragment>
  );
}

export default SubmissionsAuditList;


