import {call,put, takeLatest} from "redux-saga/effects";
import {fetchProjetsByIdUserApi,  fetchOrdersProviderApi, fetchOrdersFranchiseApi, fetchProjetsApi,fetchTacheApi, fetchSousTacheApi, fetchDevProjetsApi, fetchDevProjetsByIdUserApi, fetchDevTacheApi, fetchDevSousTacheApi, fetchDevProcessApi, fetchProcessTacheApi } from "../../api";
import {GET_LIST_DEV_PROJECT, WATCH_DEV_PROJET_LIST,GET_LIST_DEV_TACHE, WATCH_DEV_TACHE_LIST, WATCH_DEV_SOUS_TACHE_LIST, GET_LIST_DEV_SOUS_TACHE, WATCH_DEV_PROJET_LIST_BY_ID_USET, WATCH_DEV_PROCESS_LIST, GET_LIST_DEV_PROCESS, GET_LIST_DEV_PROCESS_SOUS_TACHE, WATCH_DEV_PROCESS_LIST_BY_ID_USET, WATCH_DEV_PROCESS_TACHE_LIST, WATCH_DEV_PROCESS_SOUS_TACHE_LIST, GET_LIST_DEV_PROCESS_TACHE } from "../../redux/actionTypes";

function* fetchOrderPAsyn({idFranchise}) {
    const Data = yield call(fetchDevProcessApi,idFranchise);
    console.log(Data)
    yield put({type : GET_LIST_DEV_PROCESS,projets : Data});
}
function* fetchOrderUAsyn() {
    const Data = yield call(fetchDevProjetsByIdUserApi);
    yield put({type : GET_LIST_DEV_PROCESS, projets : Data});
}
function* fetchOrderTacheAsyn({idProcess,idFranchise}) {
    const Data = yield call(fetchProcessTacheApi, idProcess,idFranchise);

    yield put({type : GET_LIST_DEV_PROCESS_TACHE,taches : Data});
}
function* fetchSousTacheAsyn({id,idtache}) {
 
    const Data = yield call(fetchDevSousTacheApi, id, idtache);

    yield put({type : GET_LIST_DEV_PROCESS_SOUS_TACHE,taches : Data});
}
export function* watchProcessList() {

    yield takeLatest(WATCH_DEV_PROCESS_LIST,fetchOrderPAsyn)
    yield takeLatest(WATCH_DEV_PROCESS_LIST_BY_ID_USET,fetchOrderUAsyn)
    yield takeLatest(WATCH_DEV_PROCESS_TACHE_LIST,fetchOrderTacheAsyn)
    yield takeLatest(WATCH_DEV_PROCESS_SOUS_TACHE_LIST,fetchSousTacheAsyn)
 
}
