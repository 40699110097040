import { News } from 'react-feather';
import { db, firestorage, urlbackend } from '../../data/config';
import axios from "axios";
import {ADD_TO_MY_NEWS,
        ADD_NEW_NEWS,
        REMOVE_NEWS,
        REMOVE_FROM_MY_NEWS,
        UPDATE_NEWS,
        UPDATE_MY_NEWS,
        UPDATE_STATE_NEWS,ADD_NEW_SONDAGE
       } from '../actionTypes';

       import SweetAlert from 'sweetalert2'
import moment from 'moment';
export const mynew = (mynewdata) => {
    return ({
        type: ADD_TO_MY_NEWS,
        payload: {mynewdata}
    })
}

export const NewNews = (newdata ) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}news/create.php`,
        data: JSON.stringify(newdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Actualité ajoutée',
            'Actualité a été ajoutée avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
    return ({
        type: ADD_NEW_NEWS,
        payload: {newdata}
    })

       // db.collection("News").add(newdata)
       
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))

  
  
}
export const AddSondageValeur =  ( idNew,contenu ) => {

    return dispatch => {
         const firstname =localStorage.getItem("@intranetFirstUser")
  const lastname = localStorage.getItem("@intranetLastUser")
  const userImage = localStorage.getItem("@intranetimageUser")

  const idUser = localStorage.getItem("@intranetidUser")
  db.collection("News").doc(idNew).get().then(res => {
        let sondageListeData =  res.data().sondageListe
 
        let selectedsondage = sondageListeData.find(res => res.contenuSondage === contenu)
    
        let listeUser = selectedsondage.userListe || []
        if( listeUser.find(res => res.idUser === idUser) === undefined ) {
    listeUser.push({
    fullName : firstname + " " + lastname,
    userImage : userImage,
    idUser : idUser,
    fullName : firstname + " " + lastname,
    dateCreation : moment(new Date()).format('yyyy-MM-DD HH:mm')
                  })
selectedsondage.userListe = listeUser
                }

let sondagelisteCopy = sondageListeData.filter(res => res.contenuSondage !== contenu)
for(let i = 0;i<sondagelisteCopy.length;i++)
sondagelisteCopy[i].userListe =   sondagelisteCopy[i].userListe.filter(res => res.idUser !== idUser ) || []

sondagelisteCopy.push(selectedsondage)
db.collection("News").doc(idNew).update({sondageListe : sondagelisteCopy})
SweetAlert.fire(
    'Vote ajouté',
    'Votre vote a été ajouté avec succés',
    'success'
  )
dispatch  ({
    type: ADD_NEW_SONDAGE,
   payload: {sondagelisteCopy,idNew}
})
    })

    
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))




    }}

export const updatenew = (id,updatedata,image_url) => {
    return ({
        type: UPDATE_NEWS,
        payload: {id,updatedata,image_url}
    })

}
export const manageStateNew = (id,state) => {
    db.collection('News').doc(id).update({newState : state})
    return ({
        type: UPDATE_STATE_NEWS,
        payload: {id,state}
    })

}

export const updateMynew = (updateMydata,id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}news/update.php`,
        data: JSON.stringify(updateMydata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Actualité modifiée',
            'Votre actualité est modifiée',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la modification veuillez réessayer',
            'success'
          )
       
      
    });
    
    return ({
        type: UPDATE_MY_NEWS,
        payload: {id,updateMydata}
    })

}

export const deletemedianews = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}medianews/delete.php`,
        data: JSON.stringify({"media_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Votre media est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const addmedianews = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}medianews/delete.php`,
        data: JSON.stringify({"media_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Votre media est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const deletecommentaire = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}newscomment/delete.php`,
        data: JSON.stringify({"comment_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Votre Commenataire est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const updatecomment = (data) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}newscomment/update.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'commentaire modifié',
            'Votre commenatire  est modifié',
            'success'
          )
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la modification veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const addcommentnew = (data) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
  
    axios({
        method: 'post',
        url: `${urlbackend}newscomment/create.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
   
        SweetAlert.fire(
            'commentaire ajouté',
            'Votre commenatire  est ajouté',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de l ajout veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const updatesondage = (contenu , removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')

    axios({
        method: 'post',
        url: `${urlbackend}sondagenews/update.php`,
        data: JSON.stringify({"sondage_id" : removeid,"contenuSondage" : contenu}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Vote Modifié ',
            'Votre vote est modifié',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la modification veuillez réessayer',
            'success'
          )
       
      
    });
   

}
export const removesondage = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}sondagenews/delete.php`,
        data: JSON.stringify({"sondage_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Vote Supprimé',
            'Votre vote est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    return ({
        type: REMOVE_NEWS,
         removeid
    })

}
export const removenew = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}news/delete.php`,
        data: JSON.stringify({"news_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimée',
            'Votre actualité est supprimée',
            'success'
          )
        console.log(response)
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
        console.log(response)
      
    });
    return ({
        type: REMOVE_NEWS,
         removeid
    })

}

export const removemynew = (newid) => {
    return ({
        type: REMOVE_FROM_MY_NEWS,
        newid
    })
}