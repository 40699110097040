import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

import {FirstName,LastName,Address,EmailAddress, IdentifiantFournisseur, InfoFournisseur, SaveChanges, Cancel} from '../../constant'
import { useParams } from 'react-router';
import { fetchFournisseurByIdApi } from '../../api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useFilePicker } from 'use-file-picker';
import { EditFournisseurs } from '../../redux/fournisseur/action';
import { Link ,useHistory} from 'react-router-dom';
import defaultuser from '../../assets/images/user/user.png';
import { WATCH_CATEGORY } from '../../redux/actionTypes';

const FournisseurEdit = (props) => {
  const {idUser} = useParams();
const dispatch = useDispatch()
const history = useHistory()
  const [user, setUser] = useState({});
  const [firstName,setfirstName] = useState("")
  const [lastName,setlastName] = useState("")
  const [email,setemail] = useState("")
  const [phoneNumber,setphoneNumber] = useState("")
  const [random, setrandom] = useState(0);
  const [addurl, setAddurl] = useState(defaultuser)
  const [role,setRole] = useState("Fournisseur")
  const category = useSelector(state => state.Categoryapp.category)
  const [fournisseurImage,setfournisseurImage] = useState(defaultuser)
  const [adresse,setadresse] = useState("")
  const [region,setRegion] = useState("")
  const [password,setpassword] = useState("")
  const [tokenPush,settokenPush] = useState("")
  const [fournisseurState,setfournisseurState] = useState(true)
  const addToggle = () => { setaddModal(!addModal) }
  const [addModal, setaddModal] = useState(false)
  const [searchvalue, setsearchvalue] = useState("")
  const [selectedCategorie, setselectedCategorie] = useState([]);
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
  
  });
  const searchUser = (value) => {
    setsearchvalue(value)
  }
  const addToSelectedListe = (id) => {
    let selectedUserCopy = selectedCategorie
    if(selectedUserCopy.includes(id) == true){
      selectedUserCopy.splice(selectedUserCopy.findIndex(res => res == id), 1)
      setselectedCategorie(selectedUserCopy)
    setrandom(Math.random)
    }
    else {
      selectedUserCopy.push(id)
      setselectedCategorie(selectedUserCopy)
      setrandom(Math.random)
    }
      }
      useEffect(() => {
        dispatch({type: WATCH_CATEGORY})
        fetchFournisseurByIdApi(idUser).then(res => {
          setUser(res)
          setfirstName(res.firstName)
          setlastName(res.lastName)
          setemail(res.email)
     
          setadresse(res.adresse)

          setRegion(res.region)
          setphoneNumber(res.phoneNumber)
          setselectedCategorie(res.category)
        
      
          setfournisseurImage(res.fournisseurImage)
       
        })
  },[])
  const handleAddFournisseur = (event) => {
    event.preventDefault();
    let fournisseur ={}
    fournisseur.lastName = lastName
    fournisseur.firstName = firstName
    fournisseur.region = region
    fournisseur.role = role
    fournisseur.email = email
    fournisseur.tokenPush = tokenPush
    fournisseur.password = password
    fournisseur.userState = fournisseurState
    fournisseur.adresse = adresse
    fournisseur.phoneNumber = phoneNumber
    fournisseur.category = selectedCategorie
    fournisseur.fournisseurImage = fournisseurImage
    fournisseur.UpdateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
    fournisseur.proEmails = [email]
    
     
    
   dispatch(EditFournisseurs(fournisseur, idUser))
   history.push(`${process.env.PUBLIC_URL}/app/providers/providerProfile/${idUser}`)

  }
  const HandleAddUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setfournisseurImage(reader.result)
    }
  }
  return (
    <Fragment>
      <Breadcrumb parent="Users" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
        <Row>
   
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{IdentifiantFournisseur}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <div className="col-auto">
                      <div className="contact-profile">
                                    <img className="rounded-circle img-100" src={fournisseurImage} alt="" />
                                    <div className="icon-wrapper">
                                      <i className="icofont icofont-pencil-alt-5">
                                        <input className="upload" type="file" onChange={(e) => HandleAddUrl(e)} />
                                      </i>
                                    </div>
                                  </div>
                      </div>
                      <Col>
                        <h3 className="mb-1">{firstName} {lastName}</h3>
                       
                      </Col>
                    </Row>
                  
                    <FormGroup>
                      <Label className="form-label">{EmailAddress}</Label>
                      <Input className="form-control" value={email} onChange={(e) => setemail(e.target.value)} placeholder="your-email@domain.com" />
                    </FormGroup>
                   
                  
                   
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <form className="card"  onSubmit={handleAddFournisseur}>
                <CardHeader>
                  <h4 className="card-title mb-0">{InfoFournisseur}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label className="form-label">Numero télephone</Label>
                        <Input required className="form-control" value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value)} type="tel" placeholder="Numero télephone" />
                      </FormGroup>
                    </Col>
                    {/* <Col sm="6" md="3">
                    <FormGroup>
                        <Label className="form-label">Fournisseur type</Label>
                        <Input  type="select" name="select" onChange={(e) => setRole(e.target.value)} className="form-control btn-square">
                      
                            <option key={1}>Manager franchise</option>
                            <option key={2}>Fournisseur</option>
                            <option key={3}>Employe</option>
                          
                        </Input>
                      </FormGroup>
                    </Col> */}
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        <Input email required className="form-control" value={email} onChange={(e) => setemail(e.target.value)}  type="email" placeholder="Email" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{FirstName}</Label>
                        <Input required className="form-control" value={firstName} onChange={(e) => setfirstName(e.target.value)} type="text" placeholder="Nom" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{LastName}</Label>
                        <Input required className="form-control" value={lastName} onChange={(e) => setlastName(e.target.value)} type="text" placeholder="Prénom" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="form-label">{Address}</Label>
                        <Input required className="form-control" value={adresse} onChange={(e) => setadresse(e.target.value)}  type="text" placeholder="Address" />
                      </FormGroup>
                    </Col>
                   
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{"Region"}</Label>
                        <Input required className="form-control" value={region} onChange={(e) => setRegion(e.target.value)}  type="text"  placeholder="Region" />
                      </FormGroup>
                    </Col>
                   <Col sm="6" md="6">
                   <FormGroup>
                             <Label>{"Select Categorie"}</Label>
                             <Link color="success" onClick={addToggle}  name="status"  className="form-control digits" >
                             {"Select Categorie"}
                             </Link>
       
               
                           </FormGroup>
                   </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                 <input className="btn btn-primary" value={"Modifier Fournisseur"} type="submit"/>
                </CardFooter>
               
              </form>
             
            </Col>
    
         
            <Col md="12">
          
       
          
            </Col>
          </Row>
          <Modal isOpen={addModal} toggle={addToggle} size="lg">
                            <ModalHeader toggle={addToggle}>Recherche Categorie</ModalHeader>
                            <ModalBody>
                              <Form onSubmit={(e) => {e.preventDefault();addToggle()}} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                
                                  <FormGroup className="col-md-12">
                                    <Label>{"Nom categorie"}</Label>
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                 
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                             
                                {category.filter(x => x.title.includes(searchvalue)== true).map((item, i) => {
                                    return (
                                   
                                          <li style={{backgroundColor : selectedCategorie.includes(item.title) == true ? 'red' : 'white'}} onClick={() => addToSelectedListe(item.title)}  className="clearfix">
                                              <div>
                                                  <div className="name">{item.title}</div>
                                                  
                                              </div>
                                          </li>
                                )
                              
                                })}
                            </ul>
                            </div>
                          
                                     </FormGroup>
                                </div>
                                <div className='text-right'>
                              
                                <Button color="primary" className="mr-1" onClick={addToggle}>{Cancel}</Button>
                                <Button color="primary" >{SaveChanges}</Button>
                                </div>
                              </Form>
                            </ModalBody>
                          </Modal>
        </div>
      </Container>
    </Fragment>
  );
}

export default FournisseurEdit;
        