import firebase from "firebase"
import "firebase/auth";
import 'firebase/firestore';

const config = require("./app_config.json")

// Firebase 
export const firebase_app = firebase.initializeApp({
  
    apiKey: config.firebase.apiKey,
    authDomain:config.firebase.authDomain,
    databaseURL: config.firebase.databaseURL,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    appId:config.firebase.appId

});

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new  firebase.auth.TwitterAuthProvider();
export const githubProvider = new  firebase.auth.GithubAuthProvider();

export const db =  firebase_app.firestore();
export const firestorage =  firebase_app.storage();


// Auth0
export const auth0 = ({
    domain : config.auth0.domain,
    clientId : config.auth0.clientID,
    redirectUri : config.auth0.redirectUri
})

// Jwt
export const Jwt_token = config.jwt_token
 //export const urlbackend = "http://backend.tannichm41.sg-host.com/intranet/api/"
export const urlbackend = "https://gocloud.groupe-fbh.com/intranet/api/"
    


// //import * as firebase from "firebase/app";

// import { fire, firebase, firestore, googleProvider1, facebookProvider2,twitterProvider3, githubProvider4  } from "../fire";


// const config = require("./app_config.json")

// // Firebase 
// export const firebase_app = fire;

// export const googleProvider =  googleProvider1;
// export const facebookProvider =  facebookProvider2;
// export const twitterProvider =   twitterProvider3;
// export const githubProvider =   githubProvider4;
// export const db =  firestore;


// // Auth0
// export const auth0 = ({
//     domain : config.auth0.domain,
//     clientId : config.auth0.clientID,
//     redirectUri : config.auth0.redirectUri
// })

// // Jwt
// export const Jwt_token = config.jwt_token