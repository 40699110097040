import {GET_EVENTS_LIST,
        ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,
        ADD_CATEGORY,
        GET_CATEGORY,
        ADD_SOUS_CATEGORY,
        REMOVE_SOUS_CATEGORY,
        UPADTE_CATEGORY,
        REMOVE_CATEGORY,

  } from '../actionTypes'


const initial_state = {
    category : [],
    eventsmanagerdata:[],
    eventsfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_CATEGORY:
            return {...state,category: action.category, loading: true,};
            
        case REMOVE_CATEGORY:
                let event_my_event = state.category.filter(item=> action.payload.id !== item.category_id)
                return{...state,category: event_my_event}
                    
     
        case ADD_CATEGORY:
           
        return  {...state,category:[...state.category, { ...action.payload.eventdata}]};
        
        
           
                case UPADTE_CATEGORY:
                    return {
                        ...state,category: state.category.map(item => item.category_id === action.payload.data.category_id ?
                            { ...item, 
                               
                                title:action.payload.data.title
                            } : 
                            item
                        ) 
                };
       
       
        
       
                
        default: return { ...state };
    }
    
}