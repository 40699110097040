import React, { Fragment,useState,useEffect } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media } from 'reactstrap'
import {Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { WATCH_USER_LIST } from '../../redux/actionTypes';

const UserCards = (props) => {

  const [cards,setCards] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({type: WATCH_USER_LIST})
  },[])
  const history = useHistory()

  const users = useSelector(content => content.Userapp.users);
  return (
    <Fragment>
      <Breadcrumb parent="Users" title="User Cards" />
      <Container fluid={true}>
        
      <Row>
          {users?.map((cardItem, i) => 
          <Col onClick={() => {

            
        history.push(`${process.env.PUBLIC_URL}/app/users/userProfile/${cardItem.id}`,{user : cardItem})
        }} md="6" lg="6" xl="4" className="box-col-6" key={i}>
            <Card className="custom-card">
              <CardHeader>
     
                {/* <Media body className="img-fluid" src={cardItem.userImage} alt="" /> */}
              </CardHeader>
              <div className="card-profile">
                <Media body style={{width :150}} className="rounded-circle" src={cardItem.userImage} alt="" />
              </div>
             
              <div className="text-center profile-details">
                <h4>{cardItem.firstName} {cardItem.LastName}</h4>
                <h6>{cardItem.role}</h6>
                <h6>{cardItem.email}</h6>
              </div>
              {/* <CardFooter className="row">
                <Col sm="4 col-4">
                  <h6>{Follower}</h6>
                  <h3 className="counter">{cardItem.follower}</h3>
                </Col>
                <Col sm="4 col-4">
                  <h6>{Following}</h6>
                  <h3><span className="counter">{cardItem.following}</span>{"K"}</h3>
                </Col>
                <Col sm="4 col-4">
                  <h6>{TotalPost}</h6>
                  <h3><span className="counter">{cardItem.totalPost}</span>{"M"}</h3>
                </Col>
              </CardFooter> */}
            </Card>
          </Col>
          )}
        </Row>
      </Container>
    </Fragment>
  );
}

export default UserCards;