// dashbaord
import Default from '../components/dashboard/default'
import Ecommerce from '../components/dashboard/ecommerce'

// widgets
import GeneralWidget from '../components/widgets/general'
import ChartsWidget from '../components/widgets/charts'

// ui-kits
import StateColor from '../components/ui-kits/statecolor'
import Typography from '../components/ui-kits/typography'
import Avatars from '../components/ui-kits/avatars'
import HelperClasses from '../components/ui-kits/helperClasses'
import Grid from '../components/ui-kits/grid'
import TagAndPills from '../components/ui-kits/tagAndPills'
import Progress from '../components/ui-kits/progress'
import Modal from '../components/ui-kits/modal'
import Alert from '../components/ui-kits/alert'
import Popover from '../components/ui-kits/popover'
import Tooltip from '../components/ui-kits/tooltip'
import Spinner from '../components/ui-kits/spinner'
import Dropdown from '../components/ui-kits/dropDown'
import TabBootstrap from '../components/ui-kits/tabs/tabBootstrap'
import TabLine from '../components/ui-kits/tabs/tabLine'
import Accordian from '../components/ui-kits/accordian'
import Shadow from '../components/ui-kits/shadow'
import List from '../components/ui-kits/list'

// bonus-ui
import Scrolling from '../components/bonus_ui/scrolling'
import BootstrapNotify from '../components/bonus_ui/bootstrapNotify'
import Rating from '../components/bonus_ui/rating'
import Dropzone from '../components/bonus_ui/dropzone'
import SweetAlert from '../components/bonus_ui/sweetAlert'
import Tour from '../components/bonus_ui/tour'
import Sticky from '../components/bonus_ui/sticky'
import Breadcrumb from '../components/bonus_ui/breadcrumb'
import Imagecrop from '../components/bonus_ui/imagecrop'
import RangeSlider from '../components/bonus_ui/rangeSlider'
import Carousel from '../components/bonus_ui/carousel'
import Ribbons from '../components/bonus_ui/ribbons'
import Pagination from '../components/bonus_ui/pagination'
import DragAndDrop from '../components/bonus_ui/dragAndDrop'
import UploadImage from '../components/bonus_ui/uploadImage'

// Cards
import BasicCards from '../components/bonus_ui/cards/basicCards'
import ThemeCards from '../components/bonus_ui/cards/themeCards'
import TabCard from '../components/bonus_ui/cards/tabCard'
import DraggingCards from '../components/bonus_ui/cards/draggingCards'

// Timeline
import Timeline1 from '../components/bonus_ui/timelines/timeline'

// Icons
import FlagIcons from '../components/icons/flagIcons'
import FontAwsomeIcon from '../components/icons/fontAwsomeIcon'
import IcoIcons from '../components/icons/icoIcons'
import ThemifyIcons from '../components/icons/themifyIcons'
import FeatherIcons from '../components/icons/featherIcons'
import WeatherIcons from '../components/icons/weatherIcons'

// Buttons 
import DefaultButton from '../components/buttons/default-Button'
import EdgeButton from '../components/buttons/edgeButton'
import FlatButton from '../components/buttons/flatButton'
import GroupButton from '../components/buttons/groupButton'
import RaisedButton from '../components/buttons/raisedButton'


 // Forms
 import FormValidation from '../components/forms/form-control/form-validation'
 import BaseInput from '../components/forms/form-control/baseInput'
 import InputGroup from '../components/forms/form-control/inputGroup'
 import MegaOption from '../components/forms/form-control/megaOption'
 import CheckboxandRadio from '../components/forms/form-control/checkboxandRadio'
 
 // Form Layout
 import FormDefault from '../components/forms/form-layout/formDefault'
 import FormWizard1 from '../components/forms/form-layout/form-wizard-1/formwizard1'
 
  // Forms widgets
 import Datepicker from '../components/forms/form-widget/datepicker'
 import Timepicker from '../components/forms/form-widget/timepickerComponent/timepicker'
 import Typeahead from '../components/forms/form-widget/typeaheadComponent/typeahead'
 
 
 // Tabels
 import BasicTabels from '../components/tables/basicTable'
 import BorderTable from '../components/tables/borderTable'
 import SizingTable from '../components/tables/sizingTable'
 import StylingTable from '../components/tables/stylingTable'
 import DataTable from '../components/tables/dataTable'

 // Charts 
import Apexcharts from "../components/charts/apexCharts"
import GoogleCharts from "../components/charts/googleCharts"
import KnobChart from "../components/charts/knobChart"
import Chartsjs from "../components/charts/chartsjs"
import Chartist from "../components/charts/chartistCharts"

// Gallary
import ImageGallery from "../components/gallery/imageGallery"
import ImageWithDesc from "../components/gallery/imageWithDesc"
import MesonryGallery from "../components/gallery/mesonryGallery"
import MesonryDesc from "../components/gallery/mesonryDesc"
import ImageHover from "../components/gallery/imageHover"

// Blog
import BlogDetail from "../components/blog/blogDetail"
import BlogSingle from "../components/blog/blogSingle"
import BlogPost from "../components/blog/blogPost"

// Job Search 
import CardView from "../components/jobSearch/cardView"
import JobList from "../components/jobSearch/job-list"
import JobDetail from "../components/jobSearch/job-detail"
import JobApply from "../components/jobSearch/job-apply"

// Learning
import Learninglist from "../components/learning/learning-list"
import LearningDeatil from "../components/learning/learning-deatil"



// Calendar
import BasicCalender from "../components/calendar/basicCalendar"
import DraggableCalendar from "../components/calendar/draggableCalendar"

// Users
import UserProfile from "../components/users/userProfile"
import AccountProfile from "../components/users/accountProfile"

import UserEdit from "../components/users/userEdit"
import AccountEdit from "../components/users/accountEdit"

import UserCards from "../components/users/userCards"

// Maps
import GoogleMap from "../components/map/googleMap"

// Editor
import CkEditor from "../components/editor/ckEditor"
import MdeEditor from "../components/editor/mdeEditor"

// Social App 
import SocialApp from "../components/social-app"

// FAQ page 
import FaqComponent from "../components/faq"

// knowledgebase page 
import KnowledgebaseComponent from "../components/knowledgebase"

// Support Ticket
import SupportTicket from "../components/support-ticket"

// Sample page 
import Sample from "../components/sample"

// Search page 
import Search from "../components/search"


// E-commerce-app 
import Product from "../components/application/ecommerce-app/product"
import ProductDetail from "../components/application/ecommerce-app/productpage"
import ProductDetailProvider from "../components/application/ecommerce-app/productpageProvider"

import Cart from "../components/application/ecommerce-app/cart"
import Wishlist from "../components/application/ecommerce-app/wishlist"
import Productlist from "../components/application/ecommerce-app/productlist"
import Paymentdetails from "../components/application/ecommerce-app/paymentdetails"
import OrderHistory from "../components/application/ecommerce-app/orderHistory"
import OrderHistoryAdmin from "../components/application/ecommerce-app/orderHistoryAdmin"

import OrderHistoryProvider from "../components/application/ecommerce-app/orderHistoryProvider"


import Checkout from "../components/application/ecommerce-app/checkout"
import Invoice from "../components/application/ecommerce-app/invoice"
import FileImprime from "../components/application/file-manager/imprime"
import InvoiceFactureProvider from "../components/application/ecommerce-app/invoiceFactureProvider"
import Pricing from "../components/application/ecommerce-app/pricing"
import SubFranchiseDetail from "../components/application/franchise/detailSousFranchise"

// Email
import Email from "../components/application/email-app/emailDefault"

// Chat 
import Chat from "../components/application/chat-app"

// Bookmark
import Bookmark from "../components/application/bookmark"

// Project app 
import ProjectList from '../components/application/project/project'
import NewProject from '../components/application/project/new-project'

// File Manager App 
import FileManager from '../components/application/file-manager/file-manager'

// Kanban Board
import kanbanBoard from '../components/application/kanban-board/kanbanBoard'

// Task 
import TaskApp from "../components/application/task-app"

// Contact app
import ContactApp from "../components/application/contact-app"

// todo-app
import Todo from "../components/application/todo-app"

// todo-firebase-app
import TodoFirebase from "../components/application/todo-firebase-app"
import MyDrive from '../components/application/file-manager/myDrive'
import DetailsFilemanager from '../components/application/file-manager/Deail-folder-manager'
import UserAdd from '../components/users/userAdd'
import FournisseurProfile from '../components/fournisseur/fournisseurProfile'
import FournisseurEdit from '../components/fournisseur/fournisseurEdit'
import FournisseurAdd from '../components/fournisseur/fournisseurAdd'
import FournisseurCards from '../components/fournisseur/fournisseurCards'
import FranchiseAdd from '../components/application/franchise/new-franchise'
import SubFranchiseAdd from '../components/application/franchise/new-subfranchise'
import FranchiseList from '../components/application/franchise/franchiseliste'
import FranchiseDetail from '../components/application/franchise/franchiseDetail'
import Actualités from '../components/application/actualites/actualites'
import newActualite from '../components/application/actualites/new-actualite'
import EditActualite from '../components/application/actualites/edit-actualite'
import EditActualiteAdmin from '../components/application/actualites/edit-actualiteadmin'

import EventsCalender from '../components/application/events/eventsCalendar'
import EventsDraggableCalendar from '../components/application/events/eventsdraggableCalendar'
import NewEvents from '../components/application/events/new-event'
import AllDrive from '../components/application/file-manager/all-drive'
import ShareWithMeDrive from '../components/application/file-manager/sharewithme-drive'
import FovorisDrive from '../components/application/file-manager/Favoris-drive'
import DeletedDrive from '../components/application/file-manager/deleted-drive'
import MyDriveList from '../components/application/file-manager/My-drive'
import AuditList from '../components/application/franchise/auditList'
import AuditListdetail from '../components/application/franchise/auditListDetail'
import AnnuaireList from '../components/application/franchise/annuaireList'
import SubFranchiseEdit from '../components/application/franchise/edit-subfranchise'
import FranchiseEdit from '../components/application/franchise/edit-franchise'
import AnnuaireListdetail from '../components/application/franchise/annuaireListDetail'
import SubFranchiseDetailManager from '../components/application/franchise/detailSousFranchiseManager'
import SubFranchiseManager from '../components/application/franchise/detailFranchiseManager'

import SubmissionsAuditList from '../components/application/franchise/submissionListAudit'
import ManageCategory from '../components/application/categorie/manageCategory'
import FournisseurList from '../components/application/ecommerce-app/fournisseur'
import FournisseurProfileEcommerce from '../components/application/ecommerce-app/fournisseurProfile'
import FournisseurListFavoris from '../components/application/ecommerce-app/fournisseurFavoris'
import ProjectsListe from '../components/application/manageproject/project'
import Addproject from '../components/application/manageproject/new-project'
import ProjectsDetails from '../components/application/manageproject/Detailsproject'
import AddNewTache from '../components/application/manageproject/new-tache'
import AddNewSousTache from '../components/application/manageproject/new-sous-tache'

import TacheDeatil from '../components/application/manageproject/tache-detail'
import SousTacheDeatil from '../components/application/manageproject/sous-tache-detail'
import ProjectsListeUser from '../components/application/manageprojectClient/project'
import ProjectsDetailsUser from '../components/application/manageprojectClient/Detailsproject'
import TacheDeatilUser from '../components/application/manageprojectClient/tache-detail'
import SousTacheDeatilUser from '../components/application/manageprojectClient/sous-tache-detail'
import newActualitefranchise from '../components/application/actualites/new-actualitefranchise'
import MyDriveEnseigneList from '../components/application/file-manager/My-driveenseigne'
import FournisseurMarqueList from '../components/application/ecommerce-app/fournisseurmaque'
import updateEvent from '../components/application/events/update-event'
import DeveloperProfile from '../components/development/developerProfile'
import ProjectsdevListe from '../components/development/project'
import AddDevproject from '../components/development/new-dev-project'
import DevProjetDetails from '../components/development/detailDevProjet'
import DeveloperAdd from '../components/development/developerAdd'
import DeveloperListe from '../components/development/developerListe'
import DemandeEnseigneList from '../components/development/demandeenseigneliste'
import DemandesListe from '../components/development/demandeListe'
import ProcessListe from '../components/process/process'
import ProcessEnseigneList from '../components/process/processenseigneliste'
import Addprocess from '../components/process/new-process'
import ProcessDetails from '../components/process/detailProcess'
import DiscusionetIdee from '../components/discusionetidee'

import EventsCatgeoryListe from '../components/application/events_categorie/eventliste'
import Eventpage from '../components/application/events/eventsDetails'
import Newspage from '../components/application/actualites/actualiteDetails'




export const routes = [
        { path:"/dashboard/default/", Component:Default},
        { path:"/dashboard/ecommerce/", Component:Ecommerce},

        { path:"/widgets/general/", Component:GeneralWidget},
        { path:"/widgets/chart/", Component:ChartsWidget},

        { path:"/ui-kits/statecolor/", Component:StateColor},
        { path:"/ui-kits/typography/", Component:Typography},
        { path:"/ui-kits/avatar/", Component:Avatars},
        { path:"/ui-kits/helperclass/", Component:HelperClasses},
        { path:"/ui-kits/grid/", Component:Grid},
        { path:"/ui-kits/tagsandpills/", Component:TagAndPills},
        { path:"/ui-kits/progress-bar/", Component:Progress},
        { path:"/ui-kits/modal/", Component:Modal},
        { path:"/ui-kits/alert/", Component:Alert},
        { path:"/ui-kits/popover/", Component:Popover},
        { path:"/ui-kits/tooltips/", Component:Tooltip},
        { path:"/ui-kits/spinner/", Component:Spinner},
        { path:"/ui-kits/dropdown/", Component:Dropdown},
        { path:"/ui-kits/tab-bootstrap/", Component:TabBootstrap},
        { path:"/ui-kits/tab-line/", Component:TabLine},
        { path:"/ui-kits/accordion/", Component:Accordian},
        { path:"/ui-kits/shadow/", Component:Shadow},
        { path:"/ui-kits/list/", Component:List},

        { path:"/bonus-ui/scrollable/", Component:Scrolling},
        { path:"/bonus-ui/bootstrap-notify/", Component:BootstrapNotify},
        { path:"/bonus-ui/rating/", Component:Rating},
        { path:"/bonus-ui/dropzone/", Component:Dropzone},
        { path:"/bonus-ui/sweetAlert/", Component:SweetAlert},
        { path:"/bonus-ui/tourComponent/", Component:Tour},
        { path:"/bonus-ui/stickyNotes/", Component:Sticky},
        { path:"/bonus-ui/breadcrumb/", Component:Breadcrumb},
        { path:"/bonus-ui/imageCropper/", Component:Imagecrop},
        { path:"/bonus-ui/rangeSlider/", Component:RangeSlider},
        { path:"/bonus-ui/carousel/", Component:Carousel},
        { path:"/bonus-ui/ribbons/", Component:Ribbons},
        { path:"/bonus-ui/pagination/", Component:Pagination},
        { path:"/bonus-ui/dragNDropComp/", Component:DragAndDrop},
        { path:"/bonus-ui/image-upload/", Component:UploadImage},

        { path:"/bonus-ui/card/basicCards/", Component:BasicCards},
        { path:"/bonus-ui/card/creativeCards/", Component:ThemeCards},
        { path:"/bonus-ui/card/tabCard/", Component:TabCard},
        { path:"/bonus-ui/card/draggingCards/", Component:DraggingCards},

        { path:"/bonus-ui/timelines/timeline1/", Component:Timeline1},

        { path:"/icons/flagIcons/", Component:FlagIcons},
        { path:"/icons/fontAwsomeIcon/", Component:FontAwsomeIcon},
        { path:"/icons/icoIcons/", Component:IcoIcons},
        { path:"/icons/featherIcons/", Component:FeatherIcons},
        { path:"/icons/themifyIcons/", Component:ThemifyIcons},
        { path:"/icons/weatherIcons/", Component:WeatherIcons},
        { path:"/buttons/default-btn/", Component:DefaultButton},
        { path:"/buttons/flatBtn/", Component:FlatButton},
        { path:"/buttons/edgeBtn/", Component:EdgeButton},
        { path:"/buttons/raisedBtn/", Component:RaisedButton},
        { path:"/buttons/groupBtn/", Component:GroupButton},

        { path:"/forms/form-validation/", Component:FormValidation},
        { path:"/forms/baseInput/", Component:BaseInput},
        { path:"/forms/inputGroup/", Component:InputGroup},
        { path:"/forms/megaOptions/", Component:MegaOption},
        { path:"/forms/radio-checkbox/", Component:CheckboxandRadio},
        { path:"/form-layout/formDefault/", Component:FormDefault},
        { path:"/form-layout/formWizard/", Component:FormWizard1},

        { path:"/form-widget/datepicker/", Component:Datepicker},
        { path:"/form-widget/timepicker/", Component:Timepicker},
        { path:"/form-widget/typeahead/", Component:Typeahead},

        { path:"/table/basic/", Component:BasicTabels},
        { path:"/table/border/", Component:BorderTable},
        { path:"/table/sizing/", Component:SizingTable},
        { path:"/table/styling/", Component:StylingTable},
        { path:"/table/datatable/", Component:DataTable},

        { path:"/charts/apexCharts/", Component:Apexcharts},
        { path:"/charts/googleChart/", Component:GoogleCharts},
        { path:"/charts/knobChart/", Component:KnobChart},
        { path:"/charts/chartJs/", Component:Chartsjs},
        { path:"/charts/chartistComponent/", Component:Chartist},

        { path:"/app/gallery/imageGallery/", Component:ImageGallery},
        { path:"/app/gallery/imageWithDesc/", Component:ImageWithDesc},
        { path:"/app/gallery/mesonryGallery/", Component:MesonryGallery},
        { path:"/app/gallery/mesonryDesc/", Component:MesonryDesc},
        { path:"/app/gallery/imageHover/", Component:ImageHover},

        { path:"/app/blog/blogDetail/", Component:BlogDetail},
        { path:"/app/blog/blogSingle/", Component:BlogSingle},
        { path:"/app/blog/blogPost/", Component:BlogPost},

        { path:"/app/projet/project-liste/", Component:ProjectsListe},
        { path:"/app/projet/project-detail/:idprojet", Component:ProjectsDetails},
        { path:"/app/projet/add-tache/:idprojet", Component:AddNewTache},
        { path:"/app/projet/add-sous-tache/:idprojet/:idtache", Component:AddNewSousTache},
        
        { path:"/app/projet/add-project/", Component:Addproject},
        { path:"/app/jobSearch/cardView/", Component:CardView},
        { path:"/app/projet/tache/TacheDeatil/:idprojet/:idtache", Component:TacheDeatil},
        { path:"/app/projet/tache/sous-tache-detail/:idprojet/:idtache", Component:SousTacheDeatil},
        


        { path:"/app/projet/project-liste-user/", Component:ProjectsListeUser},
        { path:"/app/projet/project-detail-user/:idprojet", Component:ProjectsDetailsUser},
      
        
    
 
        { path:"/app/projet/tache/TacheDeatil-user/:idprojet/:idtache", Component:TacheDeatilUser},
        { path:"/app/projet/tache/sous-tache-detail-detail/:idprojet/:idtache", Component:SousTacheDeatilUser},



        { path:"/app/jobSearch/job-list/", Component:JobList},
        { path:"/app/jobSearch/job-detail/", Component:JobDetail},
        { path:"/app/jobSearch/job-apply/", Component:JobApply},

        { path:"/app/learning/learning-list/", Component:Learninglist},
        { path:"/app/learning/learning-detail/", Component:LearningDeatil},

        { path:"/app/users/userProfile/:idUser", Component:UserProfile},
        { path:"/app/users/account", Component:AccountProfile},
        { path:"/app/users/userEdit/:idUser", Component:UserEdit},
        { path:"/app/users/accountEdit", Component:AccountEdit},
        { path:"/app/users/userAdd/", Component:UserAdd},
        { path:"/app/users/developer-profile/:idUser", Component:DeveloperProfile},
        { path:"/app/users/developer-projet/:idUser", Component: ProjectsdevListe},
        { path:"/app/users/add-developer-projet/:idUser", Component: AddDevproject},
        { path:"/app/users/detail-developer-projet/:idprojet", Component: DevProjetDetails},
        { path:"/app/process/process-liste/:idFranchise", Component: ProcessListe},
        { path:"/app/process/process-liste/:idFranchise/:idProcess", Component: ProcessDetails},
        { path:"/app/process/enseigne-liste", Component: ProcessEnseigneList},
        { path:"/app/process/process-add/:idFranchise", Component: Addprocess},
        { path:"/app/discusion", Component: DiscusionetIdee},
        
        { path:"/app/users/developer-liste/", Component:DeveloperAdd},
        { path:"/app/users/developer-list/", Component:DeveloperListe},
        { path:"/app/franchise-demande/enseigne-list", Component:DemandeEnseigneList},
        { path:"/app/franchise-demande/enseigne-list/demande-Liste/:idFranchise", Component:DemandesListe},
        { path:"/app/users/userCards/", Component:UserCards},
        { path:"/app/providers/providerProfile/:idUser", Component:FournisseurProfile},
        { path:"/app/providers/providerEdit/:idUser", Component:FournisseurEdit},
        { path:"/app/providers/providerAdd/", Component:FournisseurAdd},
        { path:"/app/providers/providerCards/", Component:FournisseurCards},
        { path:"/app/franchises/franchiseList/", Component:FranchiseList},
        // { path:"/app/franchises/franchiseEdit/", Component:FranchiseEdit},
         { path:"/app/franchises/franchiseAdd/", Component:FranchiseAdd},
         { path:"/app/franchises/:franchise_id/subfranchiseAdd/", Component:SubFranchiseAdd},
         { path:"/app/franchises/franchisesDetails/:idfranch", Component:FranchiseDetail},
         { path:"/app/franchises/franchisesEdit/:idFranch", Component:FranchiseEdit},
         { path:"/app/franchises/SubfranchisesEdit/:idSubFranch", Component:SubFranchiseEdit},
         { path:"/app/franchises/franchises-Details/", Component:SubFranchiseDetailManager},
         { path:"/app/franchises/franchises-Details-manager/", Component:SubFranchiseManager},
         { path:"/app/franchises/subfranchisesDetails/:idSubFranch", Component: SubFranchiseDetail},
         { path:"/app/franchises/audit-list/:id", Component:AuditList},
         { path:"/app/franchises/audit-list-submission/:idaudit", Component:SubmissionsAuditList},
         { path:"/app/franchises/annuaire-list/:id", Component: AnnuaireList},
         { path:"/app/franchises/audit-list-franchise", Component:AuditListdetail},
         { path:"/app/franchises/annuaire-list-franchise", Component:AnnuaireListdetail},
         { path:"/app/actualites/actualitesliste", Component: Actualités},
         { path:"/app/actualites/actualiteAdd/", Component: newActualite},
         { path:"/app/actualites/add-actualite-franchise/", Component: newActualitefranchise},
         
         { path:"/app/actualites/actualiteEdit/:newsId", Component: EditActualite},
         { path:"/app/actualites/actualiteDetail/:id", Component: Newspage},
         { path:"/app/actualites/edit-admin-actualite/:newsId", Component: EditActualiteAdmin},
         { path:"/app/events/eventsAdd/", Component: NewEvents},
         { path:"/app/events/update-event/:id", Component: updateEvent},
         { path:"/app/events/eventsListe/", Component: EventsCalender},
         { path:"/app/events/eventdetail/:id", Component: Eventpage},
         { path:"/app/events/eventsListeUpdate/", Component: EventsDraggableCalendar},
        // { path:"/app/franchises/franchiseCards/:idUser", Component:FranchiseCards},
        // { path:"/app/subfranchises/subfranchiseInfo/:idUser", Component:SubFranchiseInfo},
        // { path:"/app/subfranchises/subfranchiseEdit/", Component:SubFranchiseEdit},
        // { path:"/app/subfranchises/subfranchiseAdd/", Component:SubFranchiseAdd},
        // { path:"/app/subfranchises/subfranchiseCards/:idUser", Component:SubFranchiseCards},

        { path:"/app/calendar/basic-calendar/", Component:BasicCalender},
        { path:"/app/calendar/draggable-calendar/", Component:DraggableCalendar},

        { path:"/app/map/googleMap/", Component:GoogleMap},

        { path:"/app/editor/ckEditor/", Component:CkEditor},
        { path:"/app/editor/mdeEditor/", Component:MdeEditor},

        { path:"/app/social-app/", Component:SocialApp},
        { path:"/app/faq/", Component:FaqComponent},
        { path:"/app/knowledgebase/", Component:KnowledgebaseComponent},
        { path:"/app/support-ticket/", Component:SupportTicket},


        { path:"/pages/samplepage/", Component:Sample},
        { path:"/pages/searchpage/", Component:Search},


        { path:"/app/ecommerce/product/", Component:Product},
        
        { path:"/app/ecommerce/provider/:id", Component:FournisseurProfileEcommerce},
        { path:"/app/ecommerce/provider/", Component:FournisseurList},
        { path:"/app/ecommerce/providermarque/", Component:FournisseurMarqueList},
        { path:"/app/ecommerce/favorisprovider/", Component:FournisseurListFavoris},
        { path:"/app/ecommerce/product-page/:idProduct/", Component:ProductDetail},
        { path:"/app/ecommerce/product-page-provider/:idProduct/", Component:ProductDetailProvider},
        { path:"/app/ecommerce/cart/", Component:Cart},
        { path:"/app/ecommerce/wishlist/", Component:Wishlist},
        { path:"/app/ecommerce/checkout/", Component:Checkout},
        { path:"/app/ecommerce/invoice/:idorder", Component:Invoice},
        { path:"/app/ecommerce/invoice-provider/:idorder", Component:InvoiceFactureProvider},

        
        { path:"/app/ecommerce/product-list/", Component:Productlist},
        { path:"/app/ecommerce/payment-details/", Component:Paymentdetails},
        { path:"/app/ecommerce/orderhistory/", Component:OrderHistory},
        { path:"/app/ecommerce/all-order/", Component:OrderHistoryAdmin},
        { path:"/app/ecommerce/orderhistoryprovider/", Component:OrderHistoryProvider},
        { path:"/app/ecommerce/pricing/", Component:Pricing},
        { path:"/app/category/categoryList/", Component:ManageCategory},
        
        { path:"/app/email-app/", Component:Email},
        { path:"/app/chat-app/", Component:Chat},
        { path:"/app/bookmark/", Component:Bookmark},
        { path:"/app/task/", Component:TaskApp},

        {path:"/app/project/project-list/", Component:ProjectList},
        {path:"/app/eventcategory", Component:EventsCatgeoryListe},
        {path:"/app/project/new-project/", Component:NewProject},
    
        {path:"/app/file-manager/", Component:FileManager},
        {path:"/app/file-manager/imprime", Component:FileImprime},
        
        {path:"/app/file-manager/alldrive", Component:AllDrive},
        {path:"/app/file-manager/drive-enseigne", Component:MyDriveEnseigneList},
        {path:"/app/file-manager/mydrive", Component:MyDriveList},
        {path:"/app/file-manager/shares-with-me", Component:ShareWithMeDrive},
        {path:"/app/file-manager/favoris-drive", Component: FovorisDrive},
        {path:"/app/file-manager/deleted-drive", Component:DeletedDrive},
        

        {path:"/app/details-folder-manager/:idfolder", Component:DetailsFilemanager},
       
        
        {path:"/app/kanban-board/", Component:kanbanBoard },
        
        { path:"/app/contact/", Component:ContactApp},

        { path:"/app/todo-app/todo/", Component:Todo},
        { path:"/app/todo-app/todo-firebase/", Component:TodoFirebase},
]