import React, { Fragment,useState,useCallback,useMemo, useEffect} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import {tableData} from '../../../data/dummyTableData'
import { Container,Row,Col,Card,CardHeader,CardBody, Modal, ModalBody, FormGroup, Label, Button, Form, Input} from 'reactstrap';
import { Add, AddNewEvent, AddNewEventcategory, Apply, Cancel, EventCategoryTitle, EventsCategoryColor, EventTitle } from '../../../constant';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SweetAlert from 'sweetalert2'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getEventCategory, NewEventCategory, removeEventCategory, updateEventCategorie } from '../../../redux/eventcategory/action';
import { WATCH_EVENT_CATEGORY } from '../../../redux/actionTypes';
import { Edit, Trash } from 'react-feather';

const colorliste = [  {color : "primary",value :"#7366ff"},
 {color:"secondary" ,value : "#f73164"},
 {color:"success",value :"#51bb25"},
{ color:"info",value :"#7a15f7"},
{ color:"warning",value :"#ff5f24"},
{ color:"danger",value :"#fd2e64"},
 {color:"light",value :"#e8ebf2"},
 {color:"dark",value :"#2c323f"}]
const EventsCatgeoryListe = () =>  {
  const {register, handleSubmit, errors } = useForm();
  const [data,setData] = useState(tableData)
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [toggleCleared, setToggleCleared] = useState(false);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch()
  const default_color = localStorage.getItem('default_color');
  const secondary_color = localStorage.getItem('secondary_color');
  const [colorBackground1, setColorBackground1] = useState(default_color || secondary_color)
  const listEventCategory = useSelector(state => state.EventCategory.eventcategory)
const [statemodal, setstatemodal] = useState(false);
const statemodatoggle = () => setstatemodal(!statemodal)

const [statemodalupdate, setstatemodalupdate] = useState(false);
const statemodalupdatetoggle = () => setstatemodalupdate(!statemodalupdate)
const deleteEventCategory  = (eventcategory_id) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
      if(eventcategory_id)
      dispatch(removeEventCategory(eventcategory_id))
   else   
    SweetAlert.fire(
    'Vérifier element supprimer'
  )
    }
    else {
      SweetAlert.fire(
        'Votre actualité est sauvgardée'
      )
    }
  })

}
  const tableColumns = [
   
    {
        name: 'Nom',
        selector: 'title',
        sortable: true,
        center:true,
    },
    {
        name: 'Couleur',
      
        cell: row => {
          return (
            <Input type='color' disabled value={row.color} />
          )
        },
        sortable: true,
        center:true,
    }
    ,
    {
        name: 'Action',
        cell: row => {
          return (<>
            <a className='primary mr-2' onClick={() => {
              setColorBackground1(row.color)
              setTitle(row.title)
          statemodalupdatetoggle()
              setSelectedCategory(row)
            }}> <Edit size={13}></Edit> Editer</a>
            <a  className='secondary' onClick={() => deleteEventCategory(row.event_category_id)}><Trash size={13} ></Trash> Suprimer</a>
            </>
          )
        },
        sortable: true,
        center:true,
    }
  ]
useEffect(() => {
 dispatch({type : WATCH_EVENT_CATEGORY})
 
}, [])

  
  const handleRowSelected = useCallback(state => {
      setSelectedRows(state.selectedRows);
    }, []);

    const contextActions = useMemo(() => {
      const handleDelete = () => {
        
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
          setToggleCleared(!toggleCleared);
          setData(differenceBy(data, selectedRows, 'name'));
          toast.success("Successfully Deleted !")
        }
      };
  
      return <button key="delete" className="btn btn-danger" onClick={handleDelete}>Delete</button>;
    }, [data, selectedRows, toggleCleared]);
    const handleUnlimatedColor1Change = (e) => {
      const { value } = e.target
      setColorBackground1(value)
  }
  const OnUnlimatedColorClick = () => {
    localStorage.setItem('default_color', colorBackground1);

    window.location.reload()
}

const AddeventCategory = data => {
data.creation_date =  moment(new Date()).format('yyyy-MM-DD HH:mm')
data.updated_date =  moment(new Date()).format('yyyy-MM-DD HH:mm')
data.color = colorBackground1
dispatch(NewEventCategory(data))
statemodatoggle()
}

const UpdateeventCategory = () => {
  
  selectedCategory.updated_date =  moment(new Date()).format('yyyy-MM-DD HH:mm')
  selectedCategory.color = colorBackground1
  selectedCategory.title = title
  dispatch(updateEventCategorie(selectedCategory))

  statemodalupdatetoggle()
  }
    return (
        <Fragment>
        <Breadcrumb parent="Catégorie Evénement" title="Liste Catégorie"/>
        <Container fluid={true}>
        <div className="media-body text-right">
                <Form className="d-inline-flex">

                  <a onClick={() => statemodatoggle()} className="btn btn-primary" > {AddNewEventcategory}</a>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
              
              </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>{"Liste des catégories d'événements"}</h5>
                            </CardHeader>
                            <CardBody>
                              <DataTable
                                data={listEventCategory}
                                columns={tableColumns}
                                striped={true}
                                center={true}
                                selectableRows
                                persistTableHead
                                contextActions={contextActions}
                                onSelectedRowsChange={handleRowSelected}
                                clearSelectedRows={toggleCleared}
                              />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={statemodal} toggle={statemodatoggle}>
              <ModalBody>
              <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(AddeventCategory)}
                    >
                    <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventCategoryTitle}</Label>
                            <Input className="form-control" type="text"  name="title" placeholder="Titre categorie *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                   
                     
                    
                      <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{EventsCategoryColor}</Label>
              
                    <input className="form-control"  type="color" name="Color-Background1" value={colorBackground1} onChange={(e) => handleUnlimatedColor1Change(e)} />
                      

                        {/* <select
                          onChange={(e) => {
                            let id = e.target.value;
             

setColor(id)
                           
                          }}
                          className="form-control digits"
                        >
                           <option >
                             Choisir Color
                            </option>
                          {colorliste.map((colorvalue, index) => (
                            <option color={colorvalue.color} value={index}>
                              {colorvalue.color}
                            </option>
                          ))}
                        </select>
                         */}
                      </FormGroup>
                    </Col>

                      </Row>

                      <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right">

                              <Button onClick={statemodatoggle} className="mr-3" color="secondary">{Cancel}</Button>
                            
                              <Button  color="primary" >{Add}</Button>
                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

              </ModalBody>
            </Modal>

            <Modal isOpen={statemodalupdate} toggle={statemodalupdatetoggle}>
              <ModalBody>
              <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={(e) => {
                      e.preventDefault()
                      UpdateeventCategory()
                    }}
                    >
                    <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventCategoryTitle}</Label>
                            <Input className="form-control" type="text"  value={title}  name="title" placeholder="Titre categorie *" onChange={(e) => setTitle(e.target.value)} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>

                      <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{EventsCategoryColor}</Label>
              
                    <input className="form-control"   type="color" name="Color-Background1" value={colorBackground1} onChange={(e) => handleUnlimatedColor1Change(e)} />

                      </FormGroup>
                    </Col>

                      </Row>

                      <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right">
                      
                      
                              <Button onClick={statemodalupdatetoggle} className="mr-3" color="secondary">{Cancel}</Button>
                        
                              <Button  color="primary" >{"Update"}</Button>
                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

              </ModalBody>
            </Modal>
        </Fragment>
    );

};

export default EventsCatgeoryListe;