import { all } from "redux-saga/effects";
import { WatcherEcommerceApp } from '../redux/ecommerce'
import { WatcherChatApp } from '../redux/chap-app'
import { WatcherEmailApp } from '../redux/email'
import { watchTodoList } from '../redux/todo';
import { watchBookmarkList } from "../redux/bookmark";
import { watchFolderList, watchSubFolderList } from "../redux/foldermanager";
import { watcherTaskApp } from "../redux/task-app";
import { watchFileList, watchFileMonDriveList } from "../redux/filemanager";
import { watchUserList } from "../redux/user";
import { watchFournisseurList } from "../redux/fournisseur";
import { watchFranchiseList } from "../redux/franchise";
import { watchSubfranchiseList } from "../redux/subfranchise";
import { watchNewList } from "../redux/news";
import { watchEventList } from "../redux/events";
import { watchInventairesDipensesList } from "../redux/inventairedipense";
import { watchCategorieList } from "../redux/category";
import { watchOrderList } from "../redux/orders";
import { watchNotificationList } from "../redux/notifications";
import { watchProjetList } from "../redux/project";
import { watchNoteList } from "../redux/note";
import { watchMailGroupList } from "../redux/mail-group";
import { watchDevProjetList } from "../redux/projectdev";
import { watchProcessList } from "../redux/processdemande";
import { watchEventCategorieList } from "../redux/eventcategory";
import { watchGroupList } from "../redux/groupetravail";
import { watchProviderCategorieList } from "../redux/providercategory";
import { watchSubCategorieList } from "../redux/subcategory";

export default function* rootSagas() {
    yield all([
        WatcherEcommerceApp(),
        WatcherChatApp(),
        WatcherEmailApp(),
        watchTodoList(),
        watchBookmarkList(),
        watchFolderList(),
        watchSubFolderList(),
        watcherTaskApp(),
        watchFileList(),
        watchUserList(),
        watchFournisseurList(),
        watchFranchiseList(),
        watchSubfranchiseList(),
        watchNewList(),
        watchEventList(),
        watchFileMonDriveList(),
        watchInventairesDipensesList(),
        watchCategorieList(),
        watchOrderList(),watchNotificationList(),
        watchProjetList(),
        watchNoteList(),
        watchMailGroupList(),
        watchDevProjetList(),
        watchProcessList(),
        watchEventCategorieList(),
        watchGroupList(),
        watchProviderCategorieList(),
        watchSubCategorieList()
    ])
}