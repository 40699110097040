import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchEventsApi, fetchNewApi, fetchNewEmptyApi,fetchevenetsByidApi, fetchevenetsByfourniiseursByidApi } from "../../api";
import { GET_EVENTS_EMPTY_LIST, GET_EVENTS_LIST,WATCH_EVENTS_BY_FOURNISSEUR_LIST,WATCH_EVENTS_BY_MARQUE_LIST,WATCH_EVENTS_EMPTY_LIST,WATCH_EVENTS_LIST } from "../../redux/actionTypes";

function* fetchEventAsyn() {
    const Data = yield call(fetchEventsApi);
    yield put({type : GET_EVENTS_LIST,events : Data});
}

function* fetchNewByFournisseuridAsyn() {
    const Data = yield call(fetchevenetsByfourniiseursByidApi);
    yield put({type : GET_EVENTS_LIST,events : Data});
}
function* fetchNewByidAsyn() {
    const Data = yield call(fetchevenetsByidApi);
    yield put({type : GET_EVENTS_LIST,events : Data});
}
export function* watchEventList() {
    yield takeLatest(WATCH_EVENTS_LIST,fetchEventAsyn)
    yield takeLatest(WATCH_EVENTS_BY_MARQUE_LIST,fetchNewByidAsyn)
    yield takeLatest(WATCH_EVENTS_BY_FOURNISSEUR_LIST,fetchNewByFournisseuridAsyn)
    
}
