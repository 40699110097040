import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchFournisseurApi } from "../../api";
import { GET_PROVIDER_LIST,WATCH_PROVIDER_LIST } from "../../redux/actionTypes";

function* fetchFournisseurAsyn() {
    const Data = yield call(fetchFournisseurApi);
    yield put({type : GET_PROVIDER_LIST,fournisseurs : Data});
}

export function* watchFournisseurList() {
    yield takeLatest(WATCH_PROVIDER_LIST,fetchFournisseurAsyn)
}

