import { EVENTS } from 'react-feather';
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,
        ADD_CATEGORY,
        ADD_SOUS_CATEGORY,
        REMOVE_SOUS_CATEGORY,
        REMOVE_CATEGORY,
        UPADTE_CATEGORY
       } from '../actionTypes';

import SweetAlert from 'sweetalert2'
import { ResetPassword } from '../../constant';
import axios from 'axios';
export const myevent = (myeventdata) => {
    return ({
        type: ADD_TO_MY_EVENTS,
        payload: {myeventdata}
    })
}



export const deleteCategorieGroupe = (id ) => {

  
    const token = localStorage.getItem('intranetTypeUsertoken')

    axios({
        method: 'delete',
        url: `${urlbackend}category/delete.php`,
        data: JSON.stringify({"category_id" : id}),
        config:    { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Catégorie supprimé',
            'Catégorie a été supprimé avec succés',
            'success'
          )

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la  suppression veuillez réessayer',
            'success'
          )
       
      
    });
   
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: REMOVE_CATEGORY,
    payload: {id}
})
}
export const updateCategorieGroupe = (data ) => {

  
    const token = localStorage.getItem('intranetTypeUsertoken')

    axios({
        method: 'post',
        url: `${urlbackend}category/update.php`,
        data: JSON.stringify(data),
        config:    { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Catégorie modifié',
            'Catégorie a été modifié avec succés',
            'success'
          )
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la  suppression veuillez réessayer',
            'success'
          )
       
      
    });
    
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: UPADTE_CATEGORY,
    payload: {data}
})
}
export const deleteCategorie = (eventdata,id ) => {

  
    const token = localStorage.getItem('intranetTypeUsertoken')

    axios({
        method: 'delete',
        url: `${urlbackend}category/delete.php`,
        data: JSON.stringify({"category_id" : id}),
        config:    { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Sous Catégorie supprimé',
            'Sous Catégorie a été supprimé avec succés',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la  suppression veuillez réessayer',
            'success'
          )
       
      
    });
  
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: REMOVE_CATEGORY,
    payload: {id}
})
}

export const NewCategory = (eventdata ) => {

  
    const token = localStorage.getItem('intranetTypeUsertoken')

    axios({
        method: 'post',
        url: `${urlbackend}category/create.php`,
        data: JSON.stringify(eventdata),
        config:    { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        console.log(response)
        SweetAlert.fire(
            'Catégorie ajoutée',
            'Catégorie a été ajoutée avec succés',
            'success'
          ).then(res => {
              window.location.reload()
          })
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de modification veuillez réessayer',
            'success'
          )
       
      
    });
       
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))

  
    return ({
        type: ADD_CATEGORY,
        payload: {eventdata}
    })
}
export const updateevent = (id,startdate,enddate) => {
    db.collection("EVENTS").doc(id).update({start : startdate+"" , end : enddate+""})
    return ({
        type: UPDATE_EVENTS,
        payload: {id,startdate,enddate}
    })

}
export const manageStateNew = (id,state) => {
    db.collection('EVENTS').doc(id).update({EVENTState : state})
    return ({
        type: UPDATE_STATE_EVENTS,
        payload: {id,state}
    })

}
export const updateMyevent = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_EVENTS,
        payload: {id,updateMydata,image_url}
    })

}
export const removeevent = (removeid) => {
    db.collection('EVENTS').doc(removeid).delete()
    return ({
        type: REMOVE_EVENTS,
         removeid
    })

}
export const removemyevent = (eventid) => {
    return ({
        type: REMOVE_FROM_MY_EVENTS,
        eventid
    })
}