import { Fournisseurs } from 'react-feather';
import { db, firebase_app, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_PROVIDER,
        ADD_NEW_PROVIDER,
        REMOVE_PROVIDER,
        REMOVE_FROM_MY_PROVIDER,
        UPDATE_PROVIDER,
        UPDATE_MY_PROVIDER,
        UPDATE_STATE_PROVIDER,
       } from '../actionTypes';

import SweetAlert from 'sweetalert2'
import axios from 'axios';
import { fetchSendemailsBienvenueApi } from '../../api';
export const myfournisseur = (myfournisseurdata) => {
    return ({
        type: ADD_TO_MY_PROVIDER,
        payload: {myfournisseurdata}
    })
}

export const NewProvider = (fournisseursdata ) => {
    fetchSendemailsBienvenueApi("<html><body><p>"+`Cher Fournisseur,
        Nous sommes heureux de vous compter parmi nous, 
        voici les accès au manuel de savoir-faire de l'enseigne, <br> Email: ${fournisseursdata.email} <br> Password : ${fournisseursdata.password} <br>
        vous allez pouvoir avec notre intranet être en intéraction permanente avec les équipes supports
        et vivre votre parcours franchisés de façon efficace pour vous accompagner au mieux.
        Un chat en ligne, un service de mail, un accès fournisseurs, un accès aux annuaires, newsletter marketing...<br>
        Vous aurez également accès à vos audits en ligne avec les notes et suivre ainsi votre progression.
        Afin de pouvoir accéder a l'intranet et au manuel, merci de signé en ligne l'audit de formation de l'enseigne"
        attestation formation initale
        attestation remise de manuel...
        Tous signe en ligne (preuve qui se stock)
        `+"<p></body></html>","Bienvenue dans Go Cloud",fournisseursdata.email)
        const token = localStorage.getItem('intranetTypeUsertoken')

        axios({
            method: 'post',
            url: `${urlbackend}user/createProvider.php`,
            data: JSON.stringify(fournisseursdata),
            config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
        })
        .then(function (response) {
            //handle success
         
            SweetAlert.fire(
                'Fournisseur ajoutée',
                'Fournisseur a été ajoutée avec succés',
                'success'
              )
                    
       
            
        })
        .catch(function (response) {
            //handle error
            SweetAlert.fire(
                'Erreur',
                "Erreur Lors  de l'ajout ",
                'success'
              )
           
           
        });
        return ({
            type: ADD_NEW_PROVIDER,
            payload: {fournisseursdata}
        })

}
export const EditFournisseurs = (userdata , id) => {
  
       
            
       
         
                    db.collection("Fournisseurs").doc(id).update(userdata) 
                    SweetAlert.fire(
                        'Fournisseur Modifié!',
                        'Fournisseur est modifié avec succée',
                        'success'
                      )
                    return ({
                        type: UPDATE_MY_PROVIDER,
                        payload: {userdata, id}
                    })
          
               
             
                //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
       
            
    
   
  
   
}
export const updatefournisseur = (id,updatedata,image_url) => {
    return ({
        type: UPDATE_PROVIDER,
        payload: {id,updatedata,image_url}
    })

}
export const manageStateProvider = (id,state) => {
    db.collection('Fournisseurs').doc(id).update({fournisseurState : state})
    return ({
        type: UPDATE_STATE_PROVIDER,
        payload: {id,state}
    })

}

export const updateMyfournisseur = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_PROVIDER,
        payload: {id,updateMydata,image_url}
    })

}

export const removefournisseur = (removeid) => {
    return ({
        type: REMOVE_PROVIDER,
         removeid
    })

}

export const removemyfournisseur = (fournisseurid) => {
    return ({
        type: REMOVE_FROM_MY_PROVIDER,
        fournisseurid
    })
}


