import { EVENTS } from 'react-feather';
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,
        ADD_CATEGORY,
        ADD_SOUS_CATEGORY,
        REMOVE_SOUS_CATEGORY,
        ADD_NOTE,
        UPDATE_NOTE,
        DELETE_NOTE
       } from '../actionTypes';

import SweetAlert from 'sweetalert2'
import { ResetPassword } from '../../constant';
import axios from 'axios';
export const myevent = (myeventdata) => {
    return ({
        type: ADD_TO_MY_EVENTS,
        payload: {myeventdata}
    })
}

export const NewNotes = (data) => {

    const token = localStorage.getItem('intranetTypeUsertoken')
    console.log(data)
    axios({
        method: 'post',
        url: `${urlbackend}note/create.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Note ajoutée',
            'Note a été ajoutée avec succés',
            'success'
          )
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            "Erreur survenue au cours de l'ajout veuillez réessayer",
            'success'
          )
       
      
    });
  
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: ADD_NOTE,
    payload: {data}
})
}

export const UpdateNotes = (eventdata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
   
    axios({
        method: 'post',
        url: `${urlbackend}note/update.php`,
        data: JSON.stringify(eventdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Note Modifiée',
            'Note a été Modifiée avec succés',
            'success'
          )
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la modification veuillez réessayer',
            'success'
          )
       
      
    });
   
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: UPDATE_NOTE,
    payload: {eventdata}
})
}


export const deleteNotes = (note_id) => {

  
    const token = localStorage.getItem('intranetTypeUsertoken')
   
    axios({
        method: 'delete',
        url: `${urlbackend}note/delete.php`,
        data: JSON.stringify({"note_id" : note_id}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Note supprimée',
            'Note a été supprimée avec succés',
            'success'
          )
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la supression veuillez réessayer',
            'success'
          )
       
      
    });
   
   
   
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: DELETE_NOTE,
    payload: {id : note_id}
})
}
export const NewCategory = (eventdata ) => {

  
        db.collection("Category").add(eventdata)
        SweetAlert.fire(
            'Catégorie ajoutée',
            'Catégorie a été ajoutée avec succés',
            'success'
          )
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))

  
    return ({
        type: ADD_CATEGORY,
        payload: {eventdata}
    })
}
export const updateevent = (id,startdate,enddate) => {
    db.collection("EVENTS").doc(id).update({start : startdate+"" , end : enddate+""})
    return ({
        type: UPDATE_EVENTS,
        payload: {id,startdate,enddate}
    })

}
export const manageStateNew = (id,state) => {
    db.collection('EVENTS').doc(id).update({EVENTState : state})
    return ({
        type: UPDATE_STATE_EVENTS,
        payload: {id,state}
    })

}
export const updateMyevent = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_EVENTS,
        payload: {id,updateMydata,image_url}
    })

}
export const removeevent = (removeid) => {
    db.collection('EVENTS').doc(removeid).delete()
    return ({
        type: REMOVE_EVENTS,
         removeid
    })

}
export const removemyevent = (eventid) => {
    return ({
        type: REMOVE_FROM_MY_EVENTS,
        eventid
    })
}