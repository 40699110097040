import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchallUserApi, fetchUserApi, fetchUserEmptyApi } from "../../api";
import { GET_USER_EMPTY_LIST, GET_USER_LIST,WATCH_ALL_USER,WATCH_USER_EMPTY_LIST,WATCH_USER_LIST } from "../../redux/actionTypes";

function* fetchUserAsyn() {
    const Data = yield call(fetchUserApi);
    yield put({type : GET_USER_LIST,users : Data});
}
function* fetchAllUserAsyn() {
    const Data = yield call(fetchallUserApi);
    yield put({type : GET_USER_LIST,users : Data});
}
export function* watchUserList() {
    yield takeLatest(WATCH_USER_LIST,fetchUserAsyn)
    yield takeLatest(WATCH_ALL_USER,fetchAllUserAsyn)
}
