import {
    ADD_NEW_PRODUCT,
    GET_ALL_PRODUCT,
    GET_LIST,
    GET_LIST_PRODUCTS_BY_PROVIDER,
    GET_LIST_PRODUCTS_BY_PROVIDER_ID,
    GET_SINGLE_ITEM,
    REMOVE_PRODUCT,
    UPDATE_PRODUCT,REMOVE_SIZE,REMOVE_FILE_PRODUCT
} from '../../actionTypes';

const INIT_STATE = {
    productItems: [],
    products: [],
    productsFranchise: [],
    symbol: '€',
    singleItem: [],
    list:[]
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        

        case GET_ALL_PRODUCT:
            return { ...state };

        case GET_LIST:
            return { ...state, productItems: action.payload };
            
        case GET_LIST_PRODUCTS_BY_PROVIDER_ID:
    
                return { ...state, productsFranchise: action.payload }; 
        case GET_LIST_PRODUCTS_BY_PROVIDER:
                return { ...state, products: action.payload };
        case GET_SINGLE_ITEM:
            const selectedItem = state.productItems
            return { ...state, singleItem: selectedItem[0] };
            
            case REMOVE_PRODUCT:
                let new_products = state.products.filter(item=> action.payload.id !== item.product_id)
                return{...state,products: new_products}
        case ADD_NEW_PRODUCT:
           
            let selected = state.products
            selected.push(action.payload.eventdata)
            return { ...state, products: selected };
            
            case REMOVE_FILE_PRODUCT:
                return {
                    ...state,products: state.products.map(item => item.product_id === action.payload.product_id ?
                        { ...item, 
                           
                            imageListe : item.imageListe.filter(res => res.media_product_id != action.payload.id)
                            
                        } : 
                        item
                    ) 
                };
            case REMOVE_SIZE:
                return {
                    ...state,products: state.products.map(item => item.product_id === action.payload.product_id ?
                        { ...item, 
                           
                            sizeListe : item.sizeListe.filter(res => res.size_id != action.payload.id)
                            
                        } : 
                        item
                    ) 
                };

            case UPDATE_PRODUCT:
                return {
                    ...state,products: state.products.map(item => item.product_id === action.payload.id ?
                        { ...item, 
                           
                            ...action.payload.eventdata
                            
                        } : 
                        item
                    ) 
                };

        default:
            return state;
    }
}