import React, { Fragment, useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Edit, Video, Activity, Image } from 'react-feather';
import 'react-vertical-timeline-component/style.min.css';
import defaultuser from '../../../assets/images/user/user.png';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Button, Media, Form, Label, Input, Modal, ModalBody } from 'reactstrap'
import { DefaultStyle,COD,Fast,NoBorder,OfferStyleBorder,InlineStyle,Free,Submit,Cancel,VerticalStyle,HorizontalStyle,SolidBorderStyle,Local,XYZSeller,ABCSeller,Standard,DeliveryOption,BuyingOption } from "../../../constant";
import SweetAlert from 'sweetalert2'
import { removenew } from '../../../redux/news/action';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { WATCH_ADD_SONDAGE } from '../../../redux/actionTypes';
import DataTable from 'react-data-table-component';
import { urlbackend } from '../../../data/config';
import moment from 'moment';
const VerticalTimelineCompDashbord = ({news}) => {
  const [idUser, setidUser] = useState("");
  const history = useHistory()
  const dispatch = useDispatch()
  const [listeUserModal, setlisteUserModal] = useState(false);
  const updatelisteUserToogle = () => setlisteUserModal(!listeUserModal)
  const [userListe, setuserListe] = useState([]);
  useEffect(() => {
    setidUser(localStorage.getItem("@intranetidUser"))
  }, [])
  const RemoveNews  = (taskId) => {

    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        dispatch(removenew(taskId));
        SweetAlert.fire(
          'Supprimée',
          'Votre actualité est supprimée',
          'success'
        )
      }
      else {
        SweetAlert.fire(
          'Votre actualité est sauvgardée'
        )
      }
    })
  }
  const voter = (sondage) => {
 //dispatch(voter(sondage,idUser,))
  }
  const AddSondage = (idNew,contenu ) => {
    
    dispatch({type: WATCH_ADD_SONDAGE, idNew : idNew, contenu : contenu})
   
  }
  const productColumns = [
    {
   
  
        cell: row => {
          return (
            <img src={row.userImage ?? defaultuser} style={{ width: 50, height: 50 }} alt="" />
          )
        },
        sortable: true,
        center:true,
      },
      {
        
        selector: 'fullName',
        sortable: true,
        center:true,
      }
];
    return (
        
            <VerticalTimeline   layout={'1-column'}>
                {news.map((newac, i) => {

if( i < 3){
    // if(newac.news_sondage_state === "0" ) {
  
     
    //              return  <VerticalTimelineElement
    //               className="vertical-timeline-element--work"
    //               date={newac.creation_date}
    //               animate={false}
    //               style={{width : '100%'}}
    //               icon={<Image />}>
    //               <Row>
    //                 <Col onClick={()=> history.push(`${process.env.PUBLIC_URL}/app/actualites/actualiteDetail/${newac.news_id}`)} lg="11" sm="11">
    //                 <h4 className="vertical-timeline-element-subtitle">{newac.title}  </h4>
    //                 </Col>
    //                 {newac.prop_id === localStorage.getItem("@intranetidUser") || localStorage.getItem("@intranetTypeUser") === 'admin' ?
    //                 <Col lg="1" sm="1">
    //               {localStorage.getItem("@intranetTypeUser") === 'admin' ? 
    //                 <i onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/actualites/edit-admin-actualite/${newac.news_id}`,{news : newac})} className="icofont icofont-edit"></i>:
    //                 <i onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/actualites/actualiteEdit/${newac.news_id}`,{news : newac})} className="icofont icofont-edit"></i>
    //               }
    //                  <i onClick={() => RemoveNews(newac.news_id)} className="icofont icofont-trash"></i>
               
    //                 </Col> : <></>}
    //                 </Row>
    //               <div dangerouslySetInnerHTML={{ __html: newac.news_contenu }} />
            
    //              {newac.piecesJoint.map((image, i) => {
    //                  if (image.type.includes('image'))
    //                  return <div className="col-12 p-0"> <img style={{width : '100%', height : 300}} className="img-fluid p-t-20" src={`${urlbackend}${image.url}`} alt="timelineImg1" /></div> 
    //                  else if( image.type.includes('video'))
    //                  return <div className="col-12 p-0">
    //                    <video  style={{width : '100%', height: 300}}  controls>
    //                 <source src={`${urlbackend}${image.url}`} type="video/mp4"/>
    //                 <source src={`${urlbackend}${image.url}`} type="video/ogg"/>
    //                 Your browser does not support the video tag.
    //               </video> </div> 
    //             } ) }
    //           </VerticalTimelineElement>
    //             }
//                 else if(moment(newac.news_publication_date).isSameOrBefore(new Date())) {

//                    return <VerticalTimelineElement
//                     className="vertical-timeline-element--work"
//                     date={newac.creation_date}
//                     animate={false}
//                     icon={<Image />}>
//                   <Row>
//                     <Col lg="11" sm="11">
//                     <h4 className="vertical-timeline-element-subtitle">{newac.title}  </h4>
//                     </Col>
//                     {newac.prop_id === localStorage.getItem("@intranetidUser")  ?
//                     <Col lg="1" sm="1">
//                     {localStorage.getItem("@intranetTypeUser") === 'admin' ? 
//                     <i onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/actualites/edit-admin-actualite/${newac.news_id}`,{news : newac})} className="icofont icofont-edit"></i>:
//                     <i onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/actualites/actualiteEdit/${newac.news_id}`,{news : newac})} className="icofont icofont-edit"></i>
//                   } <i onClick={() => RemoveNews(newac.news_id)} className="icofont icofont-trash"></i>
               
//                     </Col> : <></>}
//                     </Row>
//                     <div dangerouslySetInnerHTML={{ __html: newac.news_contenu }} />
              
//                    {newac.piecesJoint.map((image, i) => {
//                        if (image.type.includes('image'))
//                        return <div className="col-12 p-0"><img style={{width : '100%', height : 300}} className="img-fluid p-t-20" src={`${urlbackend}${image.url}`} alt="timelineImg1" /></div>
//                        else if( image.type.includes('video'))
//                        return <div className="col-12 p-0">
//                          <video  style={{width : '100%', height: 300}}  controls>
//                       <source src={`${urlbackend}${image.url}`} type="video/mp4"/>
//                       <source src={`${urlbackend}${image.url}`} type="video/ogg"/>
//                       Your browser does not support the video tag.
//                     </video> </div> 
//                   } ) }
                
//                    <Row>
//                       <Col sm="12" xl="6 xl-100 box-col-12">
//                       <Card className="height-equal m-t-20">
                       
//                         <CardBody className='p-0'>
//                           <Form className="mega-horizontal">
//                             <Row>
                            
//                             {newac.sondageListe?.map((sondage,i) => 
//                               <Col key={i} lg="12" sm="12">
//                                 <Card style={{width : '100%',padding :0}}>
//                                   <Media className="p-20" >
                                  
//                                     <Media body>
//                                       <h6 className="mt-0 mega-title-badge"><span className="badge badge-primary pull-left digits">{ sondage.userListe !== undefined ?  sondage.userListe.length + " votes" :  "0 votes"}</span></h6>
//                                       <p>{sondage.contenuSondage}</p>
//                                       <div className="customers d-inline-block avatar-group">
//                         <ul>
//                           {sondage.userListe?.map((sond, index) => 
//                           {
// if( index < 4)
//                          return (
//                              <li className="d-inline-block"><Media body className="img-30 rounded-circle" style={{height: 30}}  src={`${urlbackend}${sond.userImage}`} alt=""/></li>
//                           ) }
//                              )}
//                           { sondage.userListe?.length > 4 ? <span>   <a style={{color : "red"}} onClick={() => {
//                             setuserListe(sondage.userListe)
// updatelisteUserToogle()
//                           }}>voir +</a> </span>  : ""} 
//                                                  </ul>
                                                         
//                       </div>
//                                     </Media>
//                                     <div onClick={() => AddSondage(newac.news_id,sondage.id)} className="radio radio-primary mr-1">
//                                       <Input  id={newac.title + i} type="radio"  name={newac.title} value={sondage.contenuSondage} />
//                                       <Label for={newac.title + i}></Label>
//                                     </div>
//                                   </Media>
//                                 </Card>
//                               </Col>
                              
//                               )}
                            
                             
//                             </Row>
//                           </Form>
//                         </CardBody>
                      
//                       </Card>
//                     </Col>
//                   </Row>
//                 </VerticalTimelineElement>
//                 }
              
              //else {
                if(newac.news_sondage_state === "0" ) {
  
     
                  return  <VerticalTimelineElement
                   className="vertical-timeline-element--work"
                   date={newac.creation_date}
                   animate={false}
                   style={{width : '100%'}}
                   icon={<Image />}>
                   <Row>
                     <Col onClick={()=> history.push(`${process.env.PUBLIC_URL}/app/actualites/actualiteDetail/${newac.news_id}`)} lg="11" sm="11">
                     <h4 className="vertical-timeline-element-subtitle">{newac.title}  </h4>
                     </Col>
                     {newac.prop_id === localStorage.getItem("@intranetidUser")  ?
                     <Col lg="1" sm="1">
                   {localStorage.getItem("@intranetTypeUser") === 'admin' ? 
                     <i onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/actualites/edit-admin-actualite/${newac.news_id}`,{news : newac})} className="icofont icofont-edit"></i>:
                     <i onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/actualites/actualiteEdit/${newac.news_id}`,{news : newac})} className="icofont icofont-edit"></i>
                   }
                      <i onClick={() => RemoveNews(newac.news_id)} className="icofont icofont-trash"></i>
                
                     </Col> : <></>}
                     </Row>
                   <div dangerouslySetInnerHTML={{ __html: newac.news_contenu }} />
             
                  {newac.piecesJoint.map((image, i) => {
                      if (image.type.includes('image'))
                      return <div className="col-12 p-0"> <img style={{width : '100%', height : 300}} className="img-fluid p-t-20" src={`${urlbackend}${image.url}`} alt="timelineImg1" /></div> 
                      else if( image.type.includes('video'))
                      return <div className="col-12 p-0">
                        <video  style={{width : '100%', height: 300}}  controls>
                     <source src={`${urlbackend}${image.url}`} type="video/mp4"/>
                     <source src={`${urlbackend}${image.url}`} type="video/ogg"/>
                     Your browser does not support the video tag.
                   </video> </div> 
                  } ) }
               </VerticalTimelineElement>
                 }
                 else {
 
                    return <VerticalTimelineElement
                     className="vertical-timeline-element--work"
                     date={newac.creation_date}
                     animate={false}
                     icon={<Image />}>
                   <Row>
                     <Col onClick={()=> history.push(`${process.env.PUBLIC_URL}/app/actualites/actualiteDetail/${newac.news_id}`)} lg="11" sm="11">
                     <h4 className="vertical-timeline-element-subtitle">{newac.title}  </h4>
                     </Col>
                     {newac.prop_id === localStorage.getItem("@intranetidUser")  ?
                     <Col lg="1" sm="1">
                     {localStorage.getItem("@intranetTypeUser") === 'admin' ? 
                     <i onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/actualites/edit-admin-actualite/${newac.news_id}`,{news : newac})} className="icofont icofont-edit"></i>:
                     <i onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/actualites/actualiteEdit/${newac.news_id}`,{news : newac})} className="icofont icofont-edit"></i>
                   } <i onClick={() => RemoveNews(newac.news_id)} className="icofont icofont-trash"></i>
                
                     </Col> : <></>}
                     </Row>
                     <div dangerouslySetInnerHTML={{ __html: newac.news_contenu }} />
               
                    {newac.piecesJoint.map((image, i) => {
                 if (image.type.includes('image'))

                        return <div className="col-12 p-0"><img style={{width : '100%', height : 300}} className="img-fluid p-t-20" src={`${urlbackend}${image.url}`} alt="timelineImg1" /></div>
                    
                        else if( image.type.includes('video'))
                        return <div className="col-12 p-0">
                          <video  style={{width : '100%', height: 300}}  controls>
                       <source src={`${urlbackend}${image.url}`} type="video/mp4"/>
                       <source src={`${urlbackend}${image.url}`} type="video/ogg"/>
                       Your browser does not support the video tag.
                     </video> </div> } ) }
                 
                    <Row>
                       <Col sm="12" xl="6 xl-100 box-col-12">
                       <Card className="height-equal m-t-20">
                        
                         <CardBody className='p-0'>
                           <Form className="mega-horizontal">
                             <Row>
                             
                             {newac.sondageListe?.map((sondage,i) => 
                               <Col key={i} lg="12" sm="12">
                                 <Card style={{width : '100%',padding :0}}>
                                   <Media className="p-20" >
                                   
                                     <Media body>
                                       <h6 className="mt-0 mega-title-badge"><span className="badge badge-primary pull-left digits">{ sondage.userListe !== undefined ?  sondage.userListe.length + " votes" :  "0 votes"}</span></h6>
                                       <p>{sondage.contenuSondage}</p>
                                       <div className="customers d-inline-block avatar-group">
                         <ul>
                           {sondage.userListe?.map((sond, index) => 
                           {
 if( index < 4)
                          return (
                              <li className="d-inline-block"><Media body className="img-30 rounded-circle" style={{height: 30}}  src={sond.userImage ?? defaultuser} alt=""/></li>
                           ) }
                              )}
                           { sondage.userListe?.length > 4 ? <span>   <a style={{color : "red"}} onClick={() => {
                             setuserListe(sondage.userListe)
 updatelisteUserToogle()
                           }}>voir +</a> </span>  : ""} 
                                                  </ul>
                                                          
                       </div>
                                     </Media>
                                     <div onClick={() => AddSondage(newac.news_id,sondage.id)} className="radio radio-primary mr-1">
                                       <Input  id={newac.title + i} type="radio"  name={newac.title} value={sondage.contenuSondage} />
                                       <Label for={newac.title + i}></Label>
                                     </div>
                                   </Media>
                                 </Card>
                               </Col>
                               
                               )}
                             
                              
                             </Row>
                           </Form>
                         </CardBody>
                       
                       </Card>
                     </Col>
                   </Row>
                 </VerticalTimelineElement>
                 }
              }
              }
                //}
            //      <VerticalTimelineElement
            //      className="vertical-timeline-element--work"
            //      animate={true}
            //      date="2011 - present"
            //      icon={<Edit />}>
            //      <h4 className="vertical-timeline-element-subtitle">{"Miami, FL"}</h4>
            //      <p>
            //          {"Creative Direction, User Experience, Visual Design, Project Management, Team Leading"}
            //      </p>
            //  </VerticalTimelineElement>
                )}
               
                {/* <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2010 - 2011"
                    icon={<Video />}>
                    <h4 className="vertical-timeline-element-subtitle">{"San Francisco, CA"}</h4>
                    <div className="embed-responsive embed-responsive-21by9 m-t-20">
                        <iframe src="https://www.youtube.com/embed/wpmHZspl4EM" allowFullScreen  title="myFrame"></iframe>
                    </div>
                </VerticalTimelineElement>
              
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2006 - 2008"
                    animate={true}
                    icon={<Activity />}>
                    <h4 className="vertical-timeline-element-subtitle">{"San Francisco, CA"}</h4>
                    <audio controls preload="none">
                        <source src="horse.ogg" type="audio/ogg" />
                            {"Your browser does not support the audio element."}
                        </audio>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="April 2013"
                    animate={true}
                    icon={<Image />}>
                    <h4 className="vertical-timeline-element-subtitle">{"Online Course"}</h4>
                    <img className="img-fluid p-t-20" src={require("../../../assets/images/banner/3.jpg")} alt="timelineImg2" />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="November 2012"
                    animate={true}
                    icon={<Edit />}>
                    <h4 className="vertical-timeline-element-subtitle">{"Certification"}</h4>
                    <p>
                        {"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident"}
                        {"rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus"}
                        {"veritatis qui ut."}
                    </p>
                </VerticalTimelineElement> */}
                  <Modal isOpen={listeUserModal} toggle={updatelisteUserToogle}>
                  <ModalBody>
                  <div className="table-responsive product-table">
                                <DataTable
                         
                                noTableHead="true"
                                    noHeader="true"
                                    columns={productColumns}
                                    data={userListe}
                                />
                            </div>
                  </ModalBody>
                </Modal>
            </VerticalTimeline>
      
    );
};
export default VerticalTimelineCompDashbord;