import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, FormGroup, Progress, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle, Edit2, Play, Trash2, Tag, Share2 } from 'react-feather';
import {Link, useHistory, useParams} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {Issues,Resolved,Comment,Done,All,Doing,CreateNewProject, Save, Cancel} from '../../constant'
import { WATCH_DEV_PROJET_LIST, WATCH_PROJET_LIST, WATCH_TACHE_LIST } from '../../redux/actionTypes';
import SweetAlert from 'sweetalert2'
import { removeProjets } from '../../redux/project/action';
//import UpdateprojectModal from './update-project-modal';
const ProjectsdevListe = (props) => {
  const {idUser} = useParams();
  const history = useHistory()
  const dispatch = useDispatch()
  const [updateModal,setupdateModal] = useState(false)
  const UpdateToogle = () => { setupdateModal(!updateModal) }
  const [activeTab,setActiveTab] = useState("1")
  const [selectedProjet,setselectedProjet] = useState({})
  const allProject = useSelector(content => content.DevProject.all_DevProject);
  const doingProject = useSelector(content => content.DevProject.doing_DevProject);
  const doneProject = useSelector(content => content.DevProject.done_DevProject);
  
useEffect(() => {
  alert("rfrf")
  dispatch({type : WATCH_DEV_PROJET_LIST,idUser : idUser})

}, [])
const removeProjet = (idprojet) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
   
      dispatch(removeProjets(idprojet));
  
  
      SweetAlert.fire(
        'Supprimé!',
        'Votre projey a été supprimée',
        'success'
      )
    }
    else {
    
    }
  })
}
  return (
    <Fragment>
      <Breadcrumb parent="Projets" title="Liste Projets" />
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  {/* <Nav tabs className="border-tab">
                    <NavItem><NavLink className={activeTab === "1" ? "active" : ''} onClick={() => setActiveTab("1")}><Target />{All}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "2" ? "active" : ''} onClick={() => setActiveTab("2")}><Info />{Doing}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><CheckCircle />{Done}</NavLink></NavItem>
                  </Nav> */}
                </Col>
                <Col sm="6">
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/app/users/add-developer-projet/${idUser}`}> <PlusCircle />{CreateNewProject}</Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      {allProject.map((item, i) =>
                        <Col onDoubleClick={() => {


history.push(`${process.env.PUBLIC_URL}/app/projet/project-detail/${item.id}`)

                        }} sm="4" className="mt-4" key={i}>
                          <div className="project-box">
                            {(100 / (item.numberTacheAfaire + item.numberTacheEnCours + item.numberTacheTermine)) * (item.numberTacheTermine)   === 100 ? 
                            
                            <span className={`badge badge-success`}> {"Terminé"}   </span>    :   <span className={`badge ${item.status === "Terminé" ? 'badge-success' : 'badge-danger'}`}> {item.status}    </span>  
                          }
                          
                            
                            <h6>{item.title}</h6>
                            <div className="customers hover-block">
                           <a onClick={() => history.push(`${process.env.PUBLIC_URL}/app/users/detail-developer-projet/${item.id}`)}  ><Play /></a>
                                              <a onClick={() =>{
                                                setselectedProjet(item)
                                                UpdateToogle()
                                              }} ><Edit2 /></a>

                                                <a onClick={() => removeProjet(item.id)} ><Trash2 /></a>
                                            
                            </div>
                            <div className="media">
                            <img className="img-20 mr-1 rounded-circle" src={item.marqueImage} alt="" /> 
                              <div className="media-body">
                                <p>{item.FranchiseAdresse}</p>
                              </div>
                            </div>
                            <p>{item.description}</p>
                            <Row className="details">
                         
                              <Col xs="6"><span>{'A Faire'} </span></Col>
                              <Col xs="6" className={item.status === "Terminé" ? 'text-success' : 'text-primary'}>{item.numberTacheAfaire}</Col>
                              <Col xs="6"> <span>{"En Cours"}</span></Col>
                              <Col xs="6" className={item.status === "Terminé" ? 'text-success' : 'text-primary'}>{item.numberTacheEnCours}</Col>
                              <Col xs="6"> <span>{'Terminé'}</span></Col>
                              <Col xs="6" className={item.status === "Terminé" ? 'text-success' : 'text-primary'}>{item.numberTacheTermine}</Col>
                              <Col xs="6"> <span>{Comment}</span></Col>
                              <Col xs="6" className={item.status === "Terminé" ? 'text-success' : 'text-primary'}>{item.comment}</Col>
                              <Col xs="6"> <span>{"Nom responsable"}</span></Col>
                              <Col xs="6" className={'text-primary'}>{item.managerprojetName}</Col>
                        
                            </Row>

                            <div className="project-status mt-4">
                              <div className="media mb-0">
                                <p>{(item.numberTacheAfaire + item.numberTacheEnCours + item.numberTacheTermine) > 0 ? ((100 / (item.numberTacheAfaire + item.numberTacheEnCours + item.numberTacheTermine)) * (item.numberTacheTermine)).toFixed(2)  : 0 }% </p>
                                <div className="media-body text-right"><span>{"Etat projet"}</span></div>
                              </div>
                              {((100 /item.numberTacheAfaire + item.numberTacheEnCours + item.numberTacheTermine) ) * (item.numberTacheTermine)  === 100 ?
                                <Progress className="sm-progress-bar" color="success" value={(100/(item.numberTacheAfaire + item.numberTacheEnCours + item.numberTacheTermine)) * (item.numberTacheTermine)  } style={{ height: "5px" }} />
                                :
                                <Progress className="sm-progress-bar" striped color="primary" value={(100 /(item.numberTacheAfaire + item.numberTacheEnCours + item.numberTacheTermine)) * (item.numberTacheTermine)  } style={{ height: "5px" }} />
                              }

                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      {doingProject.map((item, i) =>
                        <Col sm="4" className="mt-4" key={i}>
                          <div className="project-box">
                          <span className="badge badge-primary">{item.badge}</span>
                            <h6 className="f-w-600">{item.title}</h6>
                            <div className="media">
                              <img className="img-20 mr-1 rounded-circle" src={require(`../../assets/images/${item.img}`)} alt="" />
                              <div className="media-body">
                                <p>{item.sites}</p>
                              </div>
                            </div>
                            <p>{item.desc}</p>
                            <Row className="details">
                              <Col xs="6"><span>{Issues} </span></Col>
                              <Col xs="6" className="text-primary">{item.issue}</Col>
                              <Col xs="6"> <span>{Resolved}</span></Col>
                              <Col xs="6" className="text-primary">{item.resolved}</Col>
                              <Col xs="6"> <span>{Comment}</span></Col>
                              <Col xs="6" className="text-primary">{item.comment}</Col>
                            </Row>
                            <div className="customers">
                              <ul>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../assets/images/${item.customers_img1}`)} alt="" /></li>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../assets/images/${item.customers_img2}`)} alt="" /></li>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../assets/images/${item.customers_img3}`)} alt="" /></li>
                                <li className="d-inline-block ml-2">
                                  <p className="f-12">{item.like}</p>
                                </li>
                              </ul>
                            </div>
                            <div className="project-status mt-4">
                              <div className="media mb-0">
                                <p>{item.progress}% </p>
                                <div className="media-body text-right"><span>{Done}</span></div>
                              </div>
                              {item.progress === "100" ?
                                <Progress className="sm-progress-bar" color="primary" value={item.progress} style={{ height: "5px" }} />
                                :
                                <Progress className="sm-progress-bar" striped color="primary" value={item.progress} style={{ height: "5px" }} />
                              }
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      {doneProject.map((item, i) =>
                        <Col sm="4" className="mt-4" key={i}>
                          <div className="project-box">
                            <span className="badge badge-success">{item.badge}</span>
                            <h6 className="f-w-600">{item.title}</h6>
                            <div className="media">
                              <img className="img-20 mr-1 rounded-circle" src={require(`../../assets/images/${item.img}`)} alt="" />
                              <div className="media-body">
                                <p>{item.sites}</p>
                              </div>
                            </div>
                            <p>{item.desc}</p>
                            <Row className="details">
                              <Col xs="6"><span>{Issues} </span></Col>
                              <Col xs="6" className="text-success">{item.issue}</Col>
                              <Col xs="6"> <span>{Resolved}</span></Col>
                              <Col xs="6" className="text-success">{item.resolved}</Col>
                              <Col xs="6"> <span>{Comment}</span></Col>
                              <Col xs="6" className="text-success">{item.comment}</Col>
                            </Row>
                            <div className="customers">
                              <ul>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../assets/images/${item.customers_img1}`)} alt="" /></li>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../assets/images/${item.customers_img2}`)} alt="" /></li>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../assets/images/${item.customers_img3}`)} alt="" /></li>
                                <li className="d-inline-block ml-2">
                                  <p className="f-12">{item.like}</p>
                                </li>
                              </ul>
                            </div>
                            <div className="project-status mt-4">
                              <div className="media mb-0">
                                <p>{item.progress}% </p>
                                <div className="media-body text-right"><span>{Done}</span></div>
                              </div>
                              {item.progress === "100" ?
                                <Progress className="sm-progress-bar" color="success" value={item.progress} style={{ height: "5px" }} />
                                :
                                <Progress className="sm-progress-bar" striped color="success" value={item.progress} style={{ height: "5px" }} />
                              }
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <UpdateprojectModal selectedProjet={selectedProjet} updateModal={updateModal} UpdateToogle={UpdateToogle} /> */}
      </Container>
    </Fragment>
  );
}

export default ProjectsdevListe;