import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchFranchiseApi } from "../../api";
import { GET_FRANCHISE_LIST,WATCH_FRANCHISE_LIST } from "../../redux/actionTypes";

function* fetchFranchiseAsyn() {
    const Data = yield call(fetchFranchiseApi);
    yield put({type : GET_FRANCHISE_LIST,franchises : Data});
}

export function* watchFranchiseList() {
    yield takeLatest(WATCH_FRANCHISE_LIST,fetchFranchiseAsyn)
}

