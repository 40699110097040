import { db } from '../../data/config';
import {ADD_NEW_PROJECT} from '../actionTypes';


export const addNewProject = (data) => {
    db.collection("Projects").add(data).then(res => {
        data.id=res.id
        return{
            type:ADD_NEW_PROJECT,
            payload: {data}
        }
    })
  
}
    