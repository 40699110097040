import {call,put, takeLatest} from "redux-saga/effects";
import { fetchProductApi, fetchProductsByIdFournisseurApi, fetchProductsByProviderIdApi } from "../../api";
import { WATCH_PRODUCT_LIST,WATCH_PRODUCT_LIST_BY_PROVIDER,WATCH_SINGLE_ITEM } from "../../redux/actionTypes";
import { fetchProducts,getSingleItem,fetchProductsByProvider,fetchProductsByProviderId } from "../../redux/ecommerce/product/action";


function* fetchSingleProductsAsyn() {
    const productData  = yield call(fetchProductsByIdFournisseurApi);
    console.log(productData)
    yield put(getSingleItem(productData));
   
}

function* fetchProductsAsyn() {
    const productData  = yield call(fetchProductsByIdFournisseurApi);

    yield put(fetchProductsByProvider(productData));
   
}
function* fetchProductsByProviderAsyn() {
    const productData  = yield call(fetchProductsByProviderIdApi);

    yield put(fetchProductsByProviderId(productData));
   
}
export function* WatcherEcommerceApp() {
    yield takeLatest(WATCH_PRODUCT_LIST,fetchProductsAsyn)
    yield takeLatest(WATCH_PRODUCT_LIST_BY_PROVIDER,fetchProductsByProviderAsyn)

}
// function* fetchProductsAsyn() {
//     const productData = yield call(fetchProductsByIdFournisseurApi);
//     yield put(fetchProducts(productData.data));
//     yield put(getSingleItem());
// }

// export function* WatcherEcommerceApp() {
//     yield takeLatest(WATCH_PRODUCT_LIST,fetchProductsAsyn)
//     yield takeLatest(WATCH_SINGLE_ITEM,fetchProductsAsyn)
// }