import axios from "axios"
import { urlbackend } from "../data/config"

export const getFunction = async (url,parametre) => {
const token = localStorage.getItem('token')
const idUser = localStorage.getItem('intranetidUser')
    return await  axios.get(`${urlbackend}${url}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}).then(res => {
console.log(res)
        return res.data.records
        
        
         }).catch(erro => {
            alert(erro) 
            
            return []})
      
}
export const getFunctionSansRecord = async (url,parametre) => {
  
    const token = localStorage.getItem('token')
    const idUser = localStorage.getItem('intranetidUser')
        return axios.get(`${urlbackend}${url}`,{ headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }})
          
    }

    export const isUserLoggedIn = () => {
      
        return JSON.parse(localStorage.getItem("authenticated")) && localStorage.getItem('intranetTypeUsertoken')
      }

    export const setLocalStorage = (token) => {
        localStorage.setItem("authenticated", true)
        localStorage.setItem('intranetTypeUsertoken',token);
        return JSON.parse(localStorage.getItem("authenticated")) && localStorage.getItem('intranetTypeUsertoken')
      }