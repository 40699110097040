import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, TabContent, TabPane, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Label, Button } from 'reactstrap'
import { Grid, List,Link, Share2, Trash2, Tag, Edit2, Bookmark, PlusCircle, Play } from 'react-feather';
import { useForm } from 'react-hook-form'
import defaultImg from '../../../assets/images/lightgallry/01.jpg'
import { useSelector, useDispatch } from 'react-redux'
import SweetAlert from 'sweetalert2'
import { mybookmark, newbookmark, removebookmark, removemybookmark, updatebookmark, updateMybookmark } from '../../../redux/bookmark/action'
import { useEffect } from 'react';
import { WATCH_BOOKMARK_LIST } from '../../../redux/actionTypes';
import {AddNewActualite,MarkJecno,MARKJENCOEMAIL,NewBookmark,AddBookmark,EditBookmark,WebUrl,Title,Description,Group,General,Save,Cancel,Views,CreatedByMe,Favourites,SharedWithMe,MyBookmark,Tags,Newsletter,Notification,Business,Orgenization,Holidays,Collection,Important,MyBookmarks ,NoBookmarksFound, AuditListes, DetailJotForm, CreateNewSubFranchise, NomAudit, UrlAudit} from "../../../constant";
import { useHistory, useLocation, useParams } from 'react-router';
import JotFormReact from 'jotform-react'
import { addAudit } from '../../../redux/franchise/action';
import {JsonTable} from 'react-json-to-html';
import linkPreview from "http-link-preview";
import axios from 'axios';
import { addAuditFranchise, deleteAudit, EditAudit, fetchAuditByIdApi, fetchFranchiseByIdApi } from '../../../api';
const AuditList = (props) => {
  const {id} = useParams()
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  const [franchise, setFranchise] = useState({});
  const bookmarklist = useSelector(content => content.Bookmarkapp.bookmark);
  const mybookmarklist = useSelector(content => content.Bookmarkapp.mybookmarkdata);
  const [editrow, setEditrow] = useState({})
  const [auditList, setauditList] = useState([])
  const [activeTab, setActiveTab] = useState('1');
  const [addModal, setaddModal] = useState(false)
  const [editModal, seteditModal] = useState(false)
 
  const [auditModal, setauditModal] = useState(false)
  const [selectedUrl, setselectedUrl] = useState('')
  
  const [tagModal, setTagModal] = useState(false)
  const [addimgurl, setAddImgUrl] = useState(defaultImg);
  const [editimgurl, setEditImgUrl] = useState();
  const [gridView,setgridView] = useState(true);
  const [json,setjson] = useState({});
  const [selectedaudit,setselectedaudit] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const { registeraudit, handleSubmitaudit, errorsaudit } = useForm();
  const addToggle = () => { setaddModal(!addModal) }
  const editAuditToggle = () => { setauditModal(!auditModal) }
  const editToggle = () => { seteditModal(!editModal) }
  const [addauditModal, setaddauditModal] = useState(false)
  const addAuditToggle = () => { setaddauditModal(!addauditModal) }
  const tagToggle = () => { setTagModal(!tagModal) }
  
  useEffect(() => {
    fetchAuditByIdApi(id).then(res => {
      setauditList(res)
  
  
   
    })
  // setFranchise(location.state.franchise)
  //  dispatch({type: WATCH_BOOKMARK_LIST})
  },[dispatch])

  const ADDUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setAddImgUrl(reader.result)
    }
  }

  const onSubmit = data => {
    if (data !== '') {
      Addnewbookmark(data, "lightgallry/01.jpg");
      setaddModal(false)
    } else {
      errors.showMessages();
    }
  };

 
  const handleAddSubmit = (data) => {
    if (data !== '') {
    data.franchise_id = id
    addAuditFranchise(data).then(res => {
      if(res){
      SweetAlert.fire(
        'Audit Ajouté',
        'Votre audit est ajouté',
        'success'
      )
      fetchAuditByIdApi(id).then(res => {
        setauditList(res)
    
    
     
      })
    }
    else {
      SweetAlert.fire(
        'Erreur',
        'Erreur lors de supression',
        'success'
      ) 
    }
    }).catch(e =>  SweetAlert.fire(
      'Erreur',
      'Erreur lors de supression',
      'success'
    ) )
addAuditToggle()
    } else {
      errors.showMessages();
    }
  };
  const handleEditSubmit = (data) => {
    if (data !== '') {
      data.audit_id = selectedaudit.audit_id
      EditAudit(data).then(res => {
    if(res){
    SweetAlert.fire(
      'Audit Modifié',
      'Votre audit est Modifié',
      'success'
    )
    fetchAuditByIdApi(id).then(res => {
      setauditList(res)
  
  
   
    })
  }
  else {
    SweetAlert.fire(
      'Erreur',
      'Erreur lors de modification',
      'success'
    ) 
  }
  }).catch(e =>  SweetAlert.fire(
    'Erreur',
    'Erreur lors de modification',
    'success'
  ) )
//dispatch(addAudit(listAudtcopy , franchise.id))
editToggle()
    } else {
      errors.showMessages();
    }
  };
  const editbookmarkdata = (data) => {
    editToggle()
    setEditrow(data);
    setEditImgUrl(require(`../../../assets/images/${data.image}`))
  }


  const Addnewbookmark = (data, image_url) => {
    dispatch(newbookmark(data, image_url))
  }

  const addToFavourites = (data) => {
    if(data.fillstar === false) {
      data.fillstar = true;
      dispatch(mybookmark(data))
    }else{
      dispatch(removemybookmark(data.id));
      data.fillstar = false;
    }
  }

  const Remove_from_favourite = (data) => {
    
    if(data.fillstar === true) {
      data.fillstar = false
      dispatch(removemybookmark(data.id));
      dispatch(updatebookmark(data.id, data, data.image))
    }
  }

  const Removefrombookmark = (idaudit) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

deleteAudit(idaudit).then(res => {
  if(res){
  SweetAlert.fire(
    'Supprimé',
    'Votre audit est supprimé',
    'success'
  )
  fetchAuditByIdApi(id).then(res => {
    setauditList(res)


 
  })
}
else {
  SweetAlert.fire(
    'Erreur',
    'Erreur lors de supression',
    'success'
  ) 
}
}).catch(e =>  SweetAlert.fire(
  'Erreur',
  'Erreur lors de supression',
  'success'
) )
      
      }
      else {
        // SweetAlert.fire(
        //   ''
        // )
      }
    })
  }

  const Remove_from_my_bookmark = (mybookmarkId) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        dispatch(removemybookmark(mybookmarkId));
        SweetAlert.fire(
          'Supprimé!',
          'Votre fichier a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }

  const Gridbookmark = () => {
      setgridView(true)
  }

  const Listbookmark = () => {
      setgridView(false)
  }

  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="Liste Audits" />
      <Container fluid={true}>
      <div className="media-body text-right">
                <div className="d-inline-flex">

                  <button onClick={() => addAuditToggle()} className="btn btn-primary" > {"Créer audit"}</button>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </div>
              
              </div>
        <div className="email-wrap bookmark-wrap">
      
          <Row>
           
            <Col xl="12" md="12" className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="email-body radius-left">
                  <div className="pl-0">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{AuditListes}</h6>
                            <ul>
                              <li><a className="grid-bookmark-view" href="#javascript"><Grid onClick={Gridbookmark} /></a></li>
                              <li><a className="list-layout-view" href="#javascript"><List onClick={Listbookmark} /></a></li>
                            </ul>
                          </CardHeader>
                          <CardBody className="pb-0">
                            <div className={`details-bookmark text-center ${gridView ? '' : 'list-bookmark'}`}>
                              <Row id="bookmarkData">
                                {auditList !== undefined ?
                                  auditList.map((data, index) => {
                                    return (
                                      <Col xl="3 xl-50" md="4" key={index}>
                                        <Card className="card-with-border bookmark-card o-hidden">
                                          <div className="details-website">
                                            {data.url.includes("https://form.jotform.com") !== false ? 
                                            <iframe src={data.url} className="img-fluid" style={{height:'350px',width:'100%'}} title="Iframe Example"></iframe>
                                           :  <img style={{height:'350px'}}  className="img-fluid" src={franchise.franchiseImage} alt="" /> 
                                          }
                                      
                                       
                                            <div className="desciption-data">
                                              <div className="title-bookmark">
                                             
                                                <h6 className="title_0">{data.title}</h6>
                                                <p className="weburl_0">{data.url}</p>
                                                <div className="hover-block">
                                                  <ul>
                                                  <li><a href="#javascript" onClick={() =>{setselectedUrl(data.url); editAuditToggle()}}><Play /></a></li>
                                                    <li><a href="#javascript" onClick={() => {
                                                      setselectedaudit(data)
                                                      editToggle()
                                                    }}><Edit2 /></a></li>
                                                    <li><a  onClick={() => history.push(`${process.env.PUBLIC_URL}/app/franchises/audit-list-submission/${data.url.slice(data.url.indexOf('.com/') + 5)}`)}><Link /></a></li>
                                                    <li><a ><Share2 /></a></li>
                                                    <li><a href="#javascript" onClick={() => Removefrombookmark(data.audit_id)}><Trash2 /></a></li>
                                                    <li className="pull-right text-right"><a href="#javascript"><Tag /></a></li>
                                                  </ul>
                                                </div>
                                                <div className="content-general">
                                                  <p className="desc_0">{data.title}</p><span className="collection_0">{data.title}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      </Col>
                                    )
                                  })
                                  : <Col xl="12"><div className="no-favourite"><span>{NoBookmarksFound}</span></div> </Col>
                                  
                                }
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="2">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{Favourites}</h6>
                            <ul>
                            <li><a className="grid-bookmark-view" href="#javascript"><Grid onClick={Gridbookmark} /></a></li>
                            <li><a className="list-layout-view" href="#javascript"><List onClick={Listbookmark} /></a></li> 
                            </ul>
                          </CardHeader>
                          <CardBody>
                            <div className={`details-bookmark text-center ${gridView ? '' : 'list-bookmark'}`}>
                              <Row>
                                {mybookmarklist.length > 0 ?
                                mybookmarklist.map((mybookdata, index) => {
                                  return (
                                      <Col xl="3 xl-50" md="4" key={index}>
                                        <Card className="card-with-border bookmark-card o-hidden">
                                          <div className="details-website">
                                            <img className="img-fluid" src={require(`../../../assets/images/${mybookdata.image}`)} alt="" />
                                            <div className={`favourite-icon favourite_0 ${mybookdata.fillstar ? 'favourite' : ''}`}><a href="#javascript"><i className="fa fa-star" onClick={() => Remove_from_favourite(mybookdata)}></i></a></div>
                                            <div className="desciption-data">
                                              <div className="title-bookmark">
                                                <h6 className="title_0">{mybookdata.title}</h6>
                                                <p className="weburl_0">{mybookdata.website_url}</p>
                                                <div className="hover-block">
                                                  <ul>
                                                    <li><a href="#javascript" onClick={() => editbookmarkdata(mybookdata)}><Edit2 /></a></li>
                                                    <li><a href="#javascript"><Link /></a></li>
                                                    <li><a href="#javascript"><Share2 /></a></li>
                                                    <li><a href="#javascript" onClick={() => Remove_from_my_bookmark(mybookdata.id)}><Trash2 /></a></li>
                                                    <li className="pull-right text-right"><a href="#javascript"><Tag /></a></li>
                                                  </ul>
                                                </div>
                                                <div className="content-general">
                                                  <p className="desc_0">{mybookdata.desc}</p><span className="collection_0">{mybookdata.collection}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      </Col>
                                  )
                                })
                                : <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                              }
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="3">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{SharedWithMe}</h6>
                            <ul>
                                <li><a className="grid-bookmark-view" href="#javascript"><Grid/></a></li>
                                <li><a className="list-layout-view" href="#javascript"><List/></a></li>
                            </ul>
                          </CardHeader>
                          <CardBody>
                            <div className="details-bookmark text-center">
                              <Row></Row>
                              <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="4">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{MyBookmark}</h6>
                            <ul>
                                <li><a className="grid-bookmark-view" href="#javascript"><Grid onClick={Gridbookmark}/></a></li>
                                <li><a className="list-layout-view" href="#javascript"><List onClick={Listbookmark}/></a></li>
                              </ul>
                          </CardHeader>
                          <CardBody>
                            <div className={`details-bookmark text-center ${gridView ? '' : 'list-bookmark'}`}>
                              <Row>
                              {bookmarklist.length > 0 ?
                                  bookmarklist.map((data, index) => {
                                     return(
                                        <Col xl="3 xl-50" md="4" key={index}>
                                          <Card className="card-with-border bookmark-card o-hidden">
                                          <div className="details-website">
                                            <img className="img-fluid" src={require(`../../../assets/images/${data.image}`)} alt="" />
                                            <div className={`favourite-icon favourite_0 ${data.fillstar ? 'favourite' : ''}`} onClick={(e) => addToFavourites(data)} ><a href="#javascript"><i className="fa fa-star" ></i></a></div>
                                            <div className="desciption-data">
                                              <div className="title-bookmark">
                                                <h6 className="title_0">{data.title}</h6>
                                                <p className="weburl_0">{data.website_url}</p>
                                                <div className="hover-block">
                                                  <ul>
                                                    <li><a href="#javascript" onClick={() => editbookmarkdata(data)}><Edit2 /></a></li>
                                                    <li><a href="#javascript"><Link /></a></li>
                                                    <li><a href="#javascript"><Share2 /></a></li>
                                                    <li><a href="#javascript" onClick={() => Removefrombookmark(data.id)}><Trash2 /></a></li>
                                                    <li className="pull-right text-right"><a href="#javascript"><Tag /></a></li>
                                                  </ul>
                                                </div>
                                                <div className="content-general">
                                                  <p className="desc_0">{data.desc}</p><span className="collection_0">{data.colection}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                        </Col>
                                      
                                     )
                                    })
                                    : <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                                }
                              </Row> 
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="5">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{Notification}</h6>
                            <ul>
                                <li><a className="grid-bookmark-view" href="#javascript"><Grid/></a></li>
                                <li><a className="list-layout-view" href="#javascript"><List/></a></li>
                              </ul>
                          </CardHeader>
                          <CardBody>
                            <div className="details-bookmark text-center">
                              <Row></Row>
                              <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="6">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{Newsletter}</h6>
                            <ul>
                                <li><a className="grid-bookmark-view" href="#javascript"><Grid/></a></li>
                                <li><a className="list-layout-view" href="#javascript"><List/></a></li>
                              </ul>
                          </CardHeader>
                          <CardBody>
                            <div className="details-bookmark text-center">
                              <Row></Row>
                              <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="7">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{Business}</h6>
                            <ul>
                                <li><a className="grid-bookmark-view" href="#javascript"><Grid/></a></li>
                                <li><a className="list-layout-view" href="#javascript"><List/></a></li>
                              </ul>
                          </CardHeader>
                          <CardBody>
                            <div className="details-bookmark text-center">
                              <Row></Row>
                              <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="8">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{Holidays}</h6>
                            <ul>
                                <li><a className="grid-bookmark-view" href="#javascript"><Grid/></a></li>
                                <li><a className="list-layout-view" href="#javascript"><List/></a></li>
                              </ul>
                          </CardHeader>
                          <CardBody>
                            <div className="details-bookmark text-center">
                              <Row></Row>
                              <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="9">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{Important}</h6>
                            <ul>
                                <li><a className="grid-bookmark-view" href="#javascript"><Grid/></a></li>
                                <li><a className="list-layout-view" href="#javascript"><List/></a></li>
                              </ul>
                          </CardHeader>
                          <CardBody>
                            <div className="details-bookmark text-center">
                              <Row></Row>
                              <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="10">
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                            <h6 className="mb-0">{Orgenization}</h6>
                            <ul>
                                <li><a className="grid-bookmark-view" href="#javascript"><Grid/></a></li>
                                <li><a className="list-layout-view" href="#javascript"><List/></a></li>
                              </ul>
                          </CardHeader>
                          <CardBody>
                            <div className="details-bookmark text-center">
                              <Row></Row>
                              <div className="no-favourite"><span>{NoBookmarksFound}</span></div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <Modal isOpen={editModal} toggle={editToggle} size="lg">
                        <ModalHeader toggle={editToggle}>{"Modifer Audit"}</ModalHeader>
                        <ModalBody>
                        <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(handleEditSubmit)}>
                            <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{NomAudit}</Label>
                                <Input className="form-control" defaultValue={selectedaudit.title} name="title" type="text"  autoComplete="off" innerRef={register({ required: true })} />
                                <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                              </FormGroup>
                              <FormGroup className="col-md-12">
                                <Label>{UrlAudit}</Label>
                                <Input className="form-control" defaultValue={selectedaudit.url}  name="url" type="text" autoComplete="off" innerRef={register({ required: true })} />
                                <span style={{ color: "red" }}>{errors.url && 'Url is required'}</span>
                              </FormGroup>
                             
                             
                             
                             
                            </div>
                            <div className='text-right'>
                
                            <Button color="secondary" className="mr-1" onClick={editToggle}>{Cancel}</Button>
                            <Button color="primary" >{Save}</Button>
                            </div>
                          </Form>
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={addauditModal} toggle={addAuditToggle} size="lg">
                        <ModalHeader toggle={addAuditToggle}>{"Ajouter Audit"}</ModalHeader>
                        <ModalBody>
                          <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(handleAddSubmit)}>
                            <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{NomAudit}</Label>
                                <Input className="form-control" name="title" type="text"  autoComplete="off" innerRef={register({ required: true })} />
                                <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                              </FormGroup>
                              <FormGroup className="col-md-12">
                                <Label>{UrlAudit}</Label>
                                <Input className="form-control" name="url" type="text" autoComplete="off" innerRef={register({ required: true })} />
                                <span style={{ color: "red" }}>{errors.url && 'Url is required'}</span>
                              </FormGroup>
                             
                             
                             
                             
                            </div>
                            <div className='text-right'>
                
                <Button color="secondary" className="mr-1" onClick={addAuditToggle}>{Cancel}</Button>
                <Button color="primary" >{Save}</Button>
                </div>
                           
                          </Form>
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={auditModal} toggle={editAuditToggle} size="lg">
                        <ModalHeader toggle={editAuditToggle}>{DetailJotForm}</ModalHeader>
                        <ModalBody>
                         
                          <JotFormReact
                          
        formURL={selectedUrl}
        onSubmit={e => console.log(e) }
      />
       <div className='text-right'>
                
                <Button color="secondary" className="mr-1" onClick={editAuditToggle}>{Cancel}</Button>
                <Button color="primary" >{Save}</Button>
                </div>
                           
                    
                        </ModalBody>
                      </Modal>
                    </TabContent>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default AuditList;