import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchSubfranchiseApi,fetchAllSubfranchiseApi } from "../../api";
import { GET_SUBFRANCHISE_BY_ID,GET_ALL_SUBFRANCHISE, WATCH_ALL_SUBFRANCHISE_LIST,WATCH_SUBFRANCHISE_LIST } from "../../redux/actionTypes";

function* fetchSubfranchiseAsyn() {
    const Data = yield call(fetchSubfranchiseApi);

    yield put({type : GET_SUBFRANCHISE_BY_ID,subfranchises : Data});
}
function* fetchAllSubfranchiseAsyn() {
    const Data = yield call(fetchAllSubfranchiseApi);
    console.log(Data)
    yield put({type : GET_ALL_SUBFRANCHISE,subfranchises : Data});
}

export function* watchSubfranchiseList() {
    yield takeLatest(WATCH_SUBFRANCHISE_LIST,fetchSubfranchiseAsyn)
    yield takeLatest(WATCH_ALL_SUBFRANCHISE_LIST,fetchAllSubfranchiseAsyn)
}

