import { db } from '../../data/config';
import {ADD_DEV_SOUS_COMMENT,ADD_DEV_COMMENT,ADD_DEV_NEW_TACHE,ADD_NEW_DEV_SOUS_TACHE,REMOVE_DEV_PROJET, UPDATE_DEV_PROJET, REMOVE_DEV_TACHE, UPADTE_DEV_TACHE, REMOVE_DEV_SOUS_TACHE, ADD_NEW_DEV_PROJECT, ADD_NEW_DEV_PROCESS_SOUS_TACHE, ADD_NEW_DEV_PROCESS, UPDATE_DEV_PROCESS, UPADTE_DEV_PROCESS_TACHE, REMOVE_DEV_PROCESS_TACHE, ADD_DEV_PROCESS_COMMENT, ADD_DEV_PROCESS_SOUS_COMMENT, REMOVE_DEV_PROCESS_SOUS_TACHE, ADD_DEV_PROCESS_NEW_TACHE, UPADTE_DEV_PROCESS_SOUS_TACHE, ADD_NEW_DEV_PROCESS_TACHE} from '../actionTypes';


export const addNSousTache =  (data,idFranchise , idprocess,idtache,) => {
    db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).collection("Taches").doc(idtache).get().then(res => {

        let subtacheliste =res.data().subTacheListe
        subtacheliste.push(data)
        db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).collection("Taches").doc(idtache).update({subTacheListe : subtacheliste})
     
        db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).get().then(res => {
          let subTachenumber =  res.data().subTachenumber
         
        
          db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).update({subTachenumber : Number(subTachenumber) + 1})
        })
       
    })
    return{
        type:ADD_DEV_PROCESS_SOUS_COMMENT,
        payload: {data,idtache}
    }

        

   
}

export const removeSousTaches = (idprojet,id,idtache,status) => {
     db.collection("ProcessDev").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").doc(id).delete().then(res2 => {
       
     
       
    })
    return {
        type : REMOVE_DEV_PROCESS_SOUS_TACHE,
        payload : {idprojet,id,status,idtache}
    }
}
export const removeProjets = (idprojet) => {
    db.collection("ProcessDev").doc(idprojet).delete().then(res => {
     
        // db.collection("ProcessDev").doc(idprojet).get().then(res => {
        //   let tacheafaire=  res.data().numberTacheAfaire
        //   let tacheaencours=  res.data().numberTacheEnCours
        //   let tacheaenTermine=  res.data().numberTacheTermine
        //   db.collection("ProcessDev").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) + 1})
        // })
        return{
            type:REMOVE_DEV_PROJET,
            payload: {idprojet}
        }
    })
}

export const sendCommentSousTache = (idprojet,idsoustache, idtache, data) => {
    db.collection("ProcessDev").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").doc(idsoustache).get().then(res => {
     let comment = res.data().comment
     comment.push(data)
     db.collection("ProcessDev").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").doc(idsoustache).update({comment : comment})
        // db.collection("ProcessDev").doc(idprojet).get().then(res => {
        //   let tacheafaire=  res.data().numberTacheAfaire
        //   let tacheaencours=  res.data().numberTacheEnCours
        //   let tacheaenTermine=  res.data().numberTacheTermine
        //   db.collection("ProcessDev").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) + 1})
        // })
       
    })
    return{
        type:ADD_DEV_PROCESS_SOUS_COMMENT,
        payload: {idprojet, idtache, data}
    }
}
export const sendCommentTache = (idprojet, idtache, data) => {
    db.collection("ProcessDev").doc(idprojet).collection("Taches").doc(idtache).get().then(res => {
     let comment = res.data().comment
     comment.push(data)
     db.collection("ProcessDev").doc(idprojet).collection("Taches").doc(idtache).update({comment : comment})
        // db.collection("ProcessDev").doc(idprojet).get().then(res => {
        //   let tacheafaire=  res.data().numberTacheAfaire
        //   let tacheaencours=  res.data().numberTacheEnCours
        //   let tacheaenTermine=  res.data().numberTacheTermine
        //   db.collection("ProcessDev").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) + 1})
        // })
       
    })
    return{
        type:ADD_DEV_PROCESS_COMMENT,
        payload: {idprojet, idtache, data}
    }
}
export const removeProcessTaches = (idFranchise, idprocess, idTache) => {
    db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).collection("Taches").doc(idTache).delete()
    
        db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).get().then(res => {
          let tachenumber =  res.data().tachenumber
         
        
          db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).update({tachenumber : Number(tachenumber) - 1})
        })
       
  
    return {
        type:REMOVE_DEV_PROCESS_TACHE,
        payload: {idTache}
    }
}

export const updateSousTache = (data,idprojet, idtache,id) => {
    db.collection("ProcessDev").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").doc(id).update(data).then(res => {
      
      
    })
    
}

export const updateSubTacheByTache = (idFranchise, idprocess, id,subtacheliste) => {
    db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).collection("Taches").doc(id).update({subTacheListe : subtacheliste}).then(res => {
      
       
    })
    return{
        type:UPADTE_DEV_PROCESS_SOUS_TACHE,
        payload: {subtacheliste, id}
    }
}
export const updateTache = (data,idFranchise, idprocess, id) => {
    db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).collection("Taches").doc(id).update(data).then(res => {
      
       
    })
    return{
        type:UPADTE_DEV_PROCESS_TACHE,
        payload: {data, id}
    }
}
export const addNTache = (data,idFranchise,idprocess) => {
    db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).collection("Taches").add(data).then(res => {
        data.id=res.id
        db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).get().then(res => {
          let tachenumber =  res.data().tachenumber
         
        
          db.collection("Franchises").doc(idFranchise).collection("ProcessDev").doc(idprocess).update({tachenumber : Number(tachenumber) + 1})
        })
       
    })
    return{
        type:ADD_NEW_DEV_PROCESS_TACHE,
        payload: {data}
    }
}

export const updateProject = (data,id) => {
    db.collection("ProcessDev").doc(id).update(data).then(res => {
        data.id=res.id
        
    })
    return{
        type:UPDATE_DEV_PROCESS,
        payload: {data,id}
    }
}
export const addNProcess = (data,franchiseID) => {
    db.collection("Franchises").doc(franchiseID).collection("ProcessDev").add(data).then(res => {
        data.id=res.id
        return{
            type: ADD_NEW_DEV_PROCESS,
            payload: {data}
        }
    })
}
    