import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, FormGroup, Progress, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle } from 'react-feather';
import {Link, useParams} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {Issues,Resolved,Comment,Done,All,Doing,CreateNewProject} from '../../../constant'
import { WATCH_PROJET_LIST,WATCH_TACHE_LIST } from '../../../redux/actionTypes';
import CustomeBoard from './customeBoard';

const ProjectsDetailsUser = (props) => {
  const {idprojet} =  useParams()
  const dispatch = useDispatch()
  const [activeTab,setActiveTab] = useState("1")
useEffect(() => {
  dispatch({type : WATCH_TACHE_LIST ,id : idprojet})
}, [])
  return (
    <Fragment>
      <Breadcrumb parent="Projets" title="Liste Projets" />
      <Container fluid={true} className="jkanban-container">
        <Row>
          <Col xs="12">
                    <CustomeBoard />
                </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ProjectsDetailsUser;