import {combineReducers} from 'redux'
import Todoapp from './todo/reducer'
import Ecommerce from './ecommerce/product/reducer'
import Filters from './ecommerce/filter/reducer'
import Wishlist from './ecommerce/wishlist/reducer'
import Cart from './ecommerce/cart/reducer'
import ChatApp from './chap-app/reducer'
import EmailApp from './email/reducer'
import Customizer from './customizer/reducer'
import Bookmarkapp from './bookmark/reducer'
import Folderapp from './foldermanager/reducer'
import Taskapp from './task-app/reducer'
import Projectapp from './project-app/reducer'
import DevProject from './projectdev/reducer'

import Fileapp from './filemanager/reducer'
import Userapp from './user/reducer'
import Fournisseurapp from './fournisseur/reducer'
import Franchiseapp from './franchise/reducer'
import SubFranchiseapp from './subfranchise/reducer'
import Newsapp from './news/reducer'
import Eventsapp from './events/reducer'
import InventaireDipense from './inventairedipense/reducer'
import Categoryapp from './category/reducer'
import OrdersApp from './orders/reducer'
import Notifcationapp from './notifications/reducer'
import Project from './project/reducer'
import NotesApp from './note/reducer'
import MailGroupApp from './mail-group/reducer'
import ProcessApp from './processdemande/reducer'
import EventCategory from './eventcategory/reducer'
import GroupTravail from './groupetravail/reducer'
import ProviderCategory from './providercategory/reducer'
import SubCategory from './subcategory/reducer'

const reducers = combineReducers({
    OrdersApp,
    Todoapp,
    data:Ecommerce,
    filters:Filters,
    Wishlistdata:Wishlist,
    Cartdata:Cart,
    ChatApp,
    EmailApp,
    Customizer,
    Bookmarkapp,
    Folderapp,
    Taskapp,
    Projectapp,
    Fileapp,
    Userapp,
    Fournisseurapp,
    Franchiseapp,
    SubFranchiseapp,
    Newsapp,
    Eventsapp,
    InventaireDipense,
    Categoryapp,
    Notifcationapp,
    Project,
    NotesApp,
    MailGroupApp,
    DevProject,
    ProcessApp,
    EventCategory,
    ProviderCategory,
    SubCategory,
    GroupTravail
});

export default reducers;