import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Label, Button } from 'reactstrap'
import { getCartTotal, getCartTotalTVA } from "../../../services/ecommerce.service";
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { BillingDetails, FirstName, LastName, Phone, EmailAddress, Country, CountryMenu, Address,TownCity,StateCountry,PostalCode,Product,Total,Subtotal,Shipping,Option1,Option2,PlaceOrder,CheckMeOut,CheckPayments,CashOnDelivery,PayPal,ContinueShopping } from '../../../constant';
import { fetchSubfranchiseByidManagerApi, validerCommande } from '../../../api';
import moment from 'moment';

const Checkout = (props) => {
  const [subfranchise, setSubfranchise] = useState({});
  const history = useHistory();
  const fournisseurs = useSelector(content => content.Fournisseurapp.fournisseurs);
  const [firstname, setfirstname] = useState(localStorage.getItem("@intranetFirstUser"));
  const [lastname, setlastname] = useState(localStorage.getItem("@intranetLastUser"));
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = data => {

    if (data !== '') {
     
    
   
      data.productListe = cart
      data.idFranchise = localStorage.getItem("@intranet_franchiseid")
      data.status = 0
     

      data.idprovider = cart[0].provider_id
      data.statustext = "En Attente"
      data.CreationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
      data.UpdateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
      data.subTotal = getCartTotal(cart)
      data.tva = getCartTotalTVA(cart)
      data.user_order_id= localStorage.getItem("@intranetidUser")
      data.Total = getCartTotal(cart) + getCartTotalTVA(cart)
      let notif = {
        idUser : cart[0].provider_id,
        idSender : localStorage.getItem("@intranet_franchiseid"),
       
        CreationDate : moment(new Date()).format('yyyy-MM-DD HH:mm'),
        vue : 0,
        text: "Nouvelle commande",
        collectionType : "Orders"
      }
      console.log(data)
      validerCommande(data,notif).then(res => {

         history.push(`${process.env.PUBLIC_URL}/app/ecommerce/invoice/${res.data.data.commande_id}`,{cartFact : data})
      

        }).catch(e => {
          console.log(e)
          alert("erreur d'ajout")})
  
      
    }else {
      errors.showMessages();
    }
  }
useEffect(() => {
  fetchSubfranchiseByidManagerApi().then(res => {
    console.log()
    if(res.length > 0){
    setSubfranchise(res[0])
   
    }
  })
}, [])
  const cart = useSelector(content => content.Cartdata.cart);
  const symbol = useSelector(content => content.data.symbol);

  return (
    <Fragment>
      <Breadcrumb parent="Ecommerce" title="Checkout" />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="checkout">
              <CardHeader>
                <h5>{BillingDetails}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl="6" sm="12">
                    <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
                    <div className="form-row">
                        <FormGroup className="col-sm-6">
                          <Label>{"Choisir Date Livraison"}</Label>
                          <Input type="date" name="livraisondate" innerRef={register({ required: true })} />
                          <span style={{ color: '#ff5370' }}>{errors.livraisondate && 'Date livraison  est obligatoire'}</span>
                        </FormGroup>
                       
                      </div>
                      <div className="form-row">
                        <FormGroup className="col-sm-6">
                          <Label>{FirstName}</Label>
                          <Input type="text" name="firstName" defaultValue={firstname} innerRef={register({ required: true })} />
                          <span style={{ color: '#ff5370' }}>{errors.firstName && 'Nom est obligatoire'}</span>
                        </FormGroup>
                        <FormGroup className="col-sm-6">
                          <Label>{LastName}</Label>
                          <Input type="text" name="lastName" defaultValue={lastname} innerRef={register({ required: true })} />
                          <span style={{ color: '#ff5370' }}>{errors.lastName && 'Prenom est obligatoire'}</span>
                        </FormGroup>
                      </div>
                      <div className="form-row">
                      <FormGroup className="col-sm-6">
                          <Label>{"Nom franchise"}</Label>
                          <Input type="text" name="franchiseName" defaultValue={subfranchise.FranchiseName} innerRef={register()} />
                         
                        </FormGroup>
                        <FormGroup className="col-sm-6">
                          <Label>{Phone}</Label>
                          <Input type="text" name="phone" defaultValue={subfranchise.phoneNumber} innerRef={register({ pattern: /\d+/ })} />
                          <span style={{ color: '#ff5370' }}>{errors.phone && 'Numero telephone est obligatoire'}</span>
                        </FormGroup>
                      
                      </div>
                     
                      <FormGroup>
                          <Label for="inputAddress5">{Address}</Label>
                        <Input type="text" name="address" defaultValue={subfranchise.subfranchiseAdresse} innerRef={register({ required: true, min: 20, max: 120 })} />
                        <span style={{ color: '#ff5370' }}>{errors.address && 'Please right your address .'}</span>
                      </FormGroup>
                    
                      <FormGroup>
                        <Label for="inputAddress2">{"ville"}</Label>
                        <Input type="text" defaultValue={subfranchise.ville}  name="ville" innerRef={register({ required: true })} />
                        <span style={{ color: '#ff5370' }}>{errors.state && 'Ville est obligatoire'}</span>
                      </FormGroup>
                      <FormGroup>
                        <Label for="inputAddress6">{PostalCode}</Label>
                        <Input type="text" defaultValue={subfranchise.zipcode}  name="pincode" innerRef={register({ pattern: /\d+/ })} />
                        <span style={{ color: '#ff5370' }}>{errors.pincode && 'Required integer'}</span>
                      </FormGroup>
                   

                      <Button color="primary" type="submit" className="mt-2 pull-right">{PlaceOrder}</Button>

                    </Form>
                  </Col>
                  <Col xl="6" sm="12">
                    <div className="checkout-details">
                      <div className="order-box">
                        <div className="title-box">
                          <div className="checkbox-title">
                            <h4>{Product} </h4><span>{Total}</span>
                          </div>
                        </div>
                        <ul className="qty">
                          {cart.map((item, index) => {
                            return <li key={index}>{item.product_name} × {item.qty} <span>{symbol} {item.product_price * item.qty}</span></li>
                          })
                          }
                        </ul>
                        <ul className="sub-total">
                          <li>{Subtotal} <span className="count">{symbol}{getCartTotal(cart)}</span></li>
                          <li>{"TVA"} <span className="count">{symbol}{getCartTotalTVA(cart)}</span></li>
                          <li>{"Total"} <span className="count">{symbol}{getCartTotalTVA(cart) + getCartTotal(cart)}</span></li>
                          {/* <li className="shipping-class">{Shipping}
                            <div className="shopping-checkout-option">
                              <Label className="d-block">
                                <Input className="checkbox_animated" type="checkbox" />{Option1}
                              </Label>
                              <Label className="d-block">
                                <Input className="checkbox_animated" type="checkbox" defaultChecked />{Option2}
                              </Label>
                            </div>
                          </li> */}
                        </ul>
                        <ul className="sub-total total">
                          <li>{Total} <span className="count">{symbol} {getCartTotal(cart)}</span></li>
                        </ul>
                        <div className="animate-chk">
                          <Row>
                            <Col>
                              <Label className="d-block">
                                <Input className="radio_animated"  type="radio" name="rdo-ani" />{CheckPayments}
                              </Label>
                              <Label className="d-block">
                                <Input className="radio_animated" type="radio" name="rdo-ani" />{CashOnDelivery}
                              </Label>
                              <Label className="d-block">
                                <Input className="radio_animated" type="radio" name="rdo-ani" defaultChecked />{PayPal}
                            
                              </Label>
                            </Col>
                          </Row>
                        </div>
                        <div className="text-right mt-2">
                          <Link
                            to={`${process.env.PUBLIC_URL}/app/ecommerce/product`}
                          >
                            <Button
                              color="primary"
                              className="cart-btn-transform"
                            >
                              {ContinueShopping}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row >
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Checkout;