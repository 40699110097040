import React , { Fragment, useState,useEffect }from 'react'
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Dropdown, ButtonGroup, DropdownMenu, DropdownItem, Media } from 'reactstrap'
import { Home, Folder, Clock, Star, AlertCircle, Trash2, Database, Grid, Upload, PlusSquare, Eye,Share, User, Download } from 'react-feather';
import { toast } from 'react-toastify'
import errorImg from '../../../assets/images/search-not-found.png';
import {AllFolders,BasicModal,Simple,StaticExample,NewMessage,SendMessage,ModalTitle,Close,SaveChanges,VerticallyCentered,TooltipsAndPopovers,UsingTheGrid,SizesModal,LargeModal,SmallModal,ScrollingLongContent,VaryingModalContent,Hometxt,All,Recent,Starred,Recovery,Deleteds,PricingPlan,TrialVersion,FREE,Selected,Premium,ContactUs,AllFiles,RecentlyOpenedFiles,Folders,EndlessAdmin,Files,Storage,AddNew, SharedWithMe, Favourites, AddNewFolder, NewFolder, AddFolder, UpdateFolder, Search, Cancel, Title, Partager, OffsetToTooltip} from '../../../constant'
import axios from "axios";

import { useDispatch, useSelector } from 'react-redux';
import { GET_FOLDER_LIST_LOADING, WATCH_FOLDER_LIST, WATCH_PROVIDER_LIST, WATCH_USER_LIST } from '../../../redux/actionTypes';
import moment from 'moment';
import { db, firestorage } from '../../../data/config';
import { addFavorisFolders, newfolder, removefolder, removemyfolder, shareFolders, updatefolder, updateTreefolder } from '../../../redux/foldermanager/action';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Link, useHistory } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import { MultiPopoverOffset } from '../../ui-kits/popover/popoverComponent';
import { addFavorisFiles, removeFile } from '../../../redux/filemanager/action';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const liste = ["1","2","3","4","5","6","7"]
const MyDrive = () =>  {
    const dispatch = useDispatch();
    const history = useHistory()
  
    const myfile = useSelector(content => content.Folderapp.folders);
    const users = useSelector(content => content.Userapp.users);
    const loadingfolder = useSelector(content => content.Folderapp.loading);
    const [selectedFile, setSelectedFile] = useState(null)   // Initially, no file is selected  
    const [searchTerm, setSearchTerm] = useState("");
    const [folder_name, setfolder_name] = useState("");
    const [myfilee, setMyFile] = useState([])
    const [searchvalue, setsearchvalue] = useState("")
    const [selectedid, setselectedid] = useState("")
    const [VaryingContentone, setVaryingContentone] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dropdown, setdropdown] = useState(false);
    const [idUser, setidUser] = useState("");
    const [typeUser, settypeUser] = useState("");
    const [shareModal, setshareModal] = useState(false)
  const shareToggle = () => { setshareModal(!shareModal) }
    const [VaryingContentoneUpdate, setVaryingContentoneUpdate] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedUser, setselectedUser] = useState([]);
    const [selectedUsertype, setselectedUserType] = useState([]);
    const [random, setrandom] = useState(0);
    const [UserListe, setUserListe] = useState([]);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone);
    const [selectedImage, setselectedImage] = useState({})
    const [showApercu, setshowApercu] = useState(false);
    const showApercuToggle = () => { setshowApercu(!showApercu) }
    const [selectedidfolder, setselectedidfolder] = useState("")
    const [clientX, setclientX] = useState(0);
    const [clientY, setClientY] = useState(0);
    const [windowHeight, setwindowHeight] = useState(0);
    const [windowWidth, setwindowWidth] = useState(0);
    const [optionModal, setoptionModal] = useState(false)
    const optionToggle = () => { setoptionModal(!optionModal) } 
    const [optionFolderModal, setoptionFolderModal] = useState(false)
    const optionFolderToggle = () => { setoptionFolderModal(!optionFolderModal) } 
    const VaryingContentoneUpdatetoggle = () => setVaryingContentoneUpdate(!VaryingContentoneUpdate);
    const addToggle = () => { setaddModal(!addModal) }
    const fournisseurs = useSelector(content => content.Fournisseurapp.fournisseurs);
    const [addModal, setaddModal] = useState(false)
    const [sortState , setsortState] = useState("trieraz")
    // useEffect(() => {
    //   dispatch({type: WATCH_BOOKMARK_LIST})
    //    axios.get(`${process.env.PUBLIC_URL}/api/files.json`).then((response) => {
    //     setMyFile(response.data);
    //    })
    // },[])
    const searchUser = (value) => {
      setsearchvalue(value)
    }
    useEffect(() => {
      //dispatch({type: GET_FOLDER_LIST_LOADING})
      setwindowWidth(window.innerWidth)
      setwindowHeight(window.innerHeight)
      window.addEventListener('resize', updateWindowDimensions);

      setidUser(localStorage.getItem("@intranetidUser"))
      settypeUser(localStorage.getItem("@intranetTypeUser"))
      dispatch({type: WATCH_PROVIDER_LIST})
      dispatch({type: WATCH_USER_LIST})
      dispatch({type: WATCH_FOLDER_LIST})
    },[dispatch])
    const  updateWindowDimensions = () => {
      setwindowWidth(window.innerWidth)
      setwindowHeight(window.innerHeight)
        }
  const handleAddFolder = () => {
  let folder = {}
  folder.folder_name = folder_name
  folder.prop_id = localStorage.getItem("@intranetidUser")
  folder.prop_type = typeUser
  folder.folder_parent_id = null
  folder.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.folder_state = "0"
  folder.download_state = "1"
  folder.folder_state_deleted = "0"
  folder.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.download_url = ""
  folder.icon = "fa fa-file-image-o txt-primary"
  folder.files_number = "0"
  //folder.foldersNumber = 0"
  VaryingContentonetoggle()
  dispatch(newfolder(folder))
  setfolder_name("")
  setTimeout(() => {
    dispatch({type: WATCH_FOLDER_LIST})
  }, 2000);
  
  }
  const folderlisloading = liste.map((data,i)=>{
    return(
      <li className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
       

     <Link draggable  className="media">
     <Skeleton className="f-36 txt-warning"/>
        <div className="media-body ml-3">
          <h6 className="mb-0"><Skeleton /></h6>
          
          <p><b><Skeleton /> </b><Skeleton /></p>
        </div>
      </Link>
     
          
    </li>
    )
  })

  const handleUpdateFolder = () => {
    let folder = {}
    folder.folder_name = folder_name
 
   
    
    
    folder.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
  
    
  
    VaryingContentoneUpdatetoggle()
    dispatch(updatefolder(selectedid ,folder))
    
    }
    const handleChange = event => {
      event.preventDefault();
      setSearchTerm(event.target.value)
    };
    const allowDrop = (ev) => {
      ev.preventDefault();
    }
    
    const  drag = (ev, name) => {
      ev.dataTransfer.setData("text", name);
      console.log(name)
    }
    
    const drop = (ev, name) => {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      console.log(name)
      dispatch(updateTreefolder(name, data))
      setTimeout(() => {
        dispatch({type: WATCH_FOLDER_LIST})
      }, 2000);
      //ev.target.appendChild(document.getElementById(data));
    }
    const effect = { effect: 'None' };
    // eslint-disable-next-line
    const filelist = myfile.filter((data) => {
      if(searchTerm == null)
          return data.folder_state_deleted === false
      else if(data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())){
          return data.folder_state_deleted === false
      }
      }).map((data,i)=>{
        return(
          <li className="file-box" key={i}>
            <div className="file-top"><i className={data.icon} ></i><i className="fa fa-ellipsis-v f-14 ellips"></i></div>
            <div className="file-bottom">
              <h6>{data.folder_name} </h6>
              <p className="mb-1">{"12"}</p>
                {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
            </div>
          </li>
        )
      })
      const folderlist = myfile.sort(function(x,y) {
        if(sortState === "trieraz")
        return x.folder_name.toString().toLowerCase().localeCompare(y.folder_name.toString().toLowerCase());
        else if(sortState === "trierza")
        return y.folder_name.toString().toLowerCase().localeCompare(x.folder_name.toString().toLowerCase());
        else if(sortState === "trierdate")
        return y.folder_name.toString().localeCompare(x.folder_name.toString());
      }).filter((data) => {
        if(searchTerm == null )
        return true
        else
    return  data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())
          
        
        })//.filter(res => (res.prop_id === idUser || res.userListe.includes(idUser)) )
        .map((data,i)=>{
          return(
            <li onContextMenu={e => {
         
              e.preventDefault();
      
     
     if(e.button === 2){
      setselectedUser(data.userListe)
      setselectedUserType(data.userListeType || [])
      
      localStorage.setItem("idfolder", data.folder_id)
      localStorage.setItem("folder_name", data.folder_name)
      setselectedid(data.folder_id)
      setselectedidfolder(data.folder_parent_id)
      setselectedImage(data)
      optionFolderToggle()
      setclientX(e.clientX)
      setClientY(e.clientY)
     
     
     }}}   className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
              
            <div 
            //  onContextMenu={(e) => {
            //   setPosition({ x: e.screenX, y: e.screenY });
            //   e.preventDefault()
            //   toggle()
            //   setUserListe(data.userListe)
            //   setselectedid(data.id)}}  
               draggable="true" onDragStart={(event) => drag(event, data.folder_id)} onDrop={(event) => drop(event, data.folder_id)} onDragOver={(event) => allowDrop(event)} className="media">
            <i className="fa fa-folder f-36 txt-warning" ></i>
              <Link className="media-body ml-3" draggable 
          
          
         
                onDoubleClick={() => {

              localStorage.setItem("idfolder", data.folder_id)
              localStorage.setItem("folder_name", data.folder_name)
          history.push(`${process.env.PUBLIC_URL}/app/details-folder-manager/${data.folder_id}`)
          }}  >
            
                <h6 className="mb-0">{data.folder_name}</h6>
                
                  {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
              </Link>

            <Dropdown key={i}    className="dropup" >
           
                      <DropdownMenu  className={dropdownOpen === true && data.folder_id === selectedid ? "dropup-content show" : "dropup-content" }>
                          <DropdownItem onClick={() => RemoveFolder(data.id)}>Delete</DropdownItem>
                          <DropdownItem  onClick={() => {
                            setselectedid(data.folder_id)
                        
                            VaryingContentoneUpdatetoggle()
                          }} >Update</DropdownItem>
                          <DropdownItem onClick={() => {
                                       setselectedUser(data.userListe)
                                       setselectedid(data.folder_id)
                        
                            addToggle()
                            
                            }}>Partager</DropdownItem>
                     </DropdownMenu>
                    </Dropdown>
            </div>
          </li>
          )
        })
        const folderlist1 = myfile.sort(function(x,y) {
          if(sortState === "trieraz")
          return x.folder_name.toString().toLowerCase().localeCompare(y.folder_name.toString().toLowerCase());
          else if(sortState === "trierza")
          return y.folder_name.toString().toLowerCase().localeCompare(x.folder_name.toString().toLowerCase());
          else if(sortState === "trierdate")
          return y.folder_name.toString().localeCompare(x.folder_name.toString());
        }).filter((data) => {
          if(searchTerm == null )
          return true
          else
      return  data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())
            
          
          })//.filter(res => (res.prop_id === idUser || res.userListe.includes(idUser)) )
          .map((data,i)=>{
            return(
              <li onContextMenu={e => {
         
                e.preventDefault();
        
       
       if(e.button === 2){
        setselectedUser(data.userListe)
        setselectedUserType(data.userListeType || [])
        localStorage.setItem("idfolder", data.folder_id)
        localStorage.setItem("folder_name", data.folder_name)
        setselectedid(data.id)
        setselectedidfolder(data.folder_parent_id)
        setselectedImage(data)
        optionFolderToggle()
        setclientX(e.clientX)
        setClientY(e.clientY)
       
       
       }}}   className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
                
              <div   draggable="true" onDragStart={(event) => drag(event, data.folder_id)} onDrop={(event) => drop(event, data.folder_id)} onDragOver={(event) => allowDrop(event)} className="media">
              <i className="fa fa-folder f-36 txt-warning" ></i>
                <Link className="media-body ml-3" draggable 
            
            
           
                  onDoubleClick={() => {
  
                localStorage.setItem("idfolder", data.folder_id)
                localStorage.setItem("folder_name", data.folder_name)
            history.push(`${process.env.PUBLIC_URL}/app/details-folder-manager/${data.folder_id}`)
            }}  >
              
                  <h6 className="mb-0">{data.folder_name}</h6>
                  
                    {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
                </Link>
  
              <Dropdown key={i}   className="dropup" >
              <i class="fa fa-ellipsis-v f-14 " onClick={() => {
                toggle()
                setUserListe(data.userListe)
                setselectedid(data.folder_id)
                }}></i>
                        <DropdownMenu  className={dropdownOpen === true && data.folder_id === selectedid ? "dropup-content show" : "dropup-content" }>
                            <DropdownItem onClick={() => RemoveFolder(data.id)}>Delete</DropdownItem>
                            <DropdownItem  onClick={() => {
                              setselectedid(data.folder_id)
                          
                              VaryingContentoneUpdatetoggle()
                            }} >Update</DropdownItem>
                            <DropdownItem onClick={() => {
                                         setselectedUser(data.userListe)
                                         setselectedid(data.folder_id)
                          
                              addToggle()
                              
                              }}>Partager</DropdownItem>
                       </DropdownMenu>
                      </Dropdown>
              </div>
            </li>
            )
          })
    const getFile = () => {
      document.getElementById("upfile").click();
    }
    const handleDragEvent = (e) => {
      e.preventDefault();
      console.log(e)
      // Do something
    };
  
    const RemoveFolder  = (id) => {
      toggle()
      SweetAlert.fire({
        title: 'Are you sure?',
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
         dispatch(removefolder(id));
         
          SweetAlert.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
        else {
          SweetAlert.fire(
            'Your imaginary file is safe!'
          )
        }
      })
    }
    const onFileChange = event => {
      // Update the state 
      setSelectedFile(event.target.files[0]);
    };
  
    const onFileUpload = () => {
        let myfiles = [...myfile];
  
        if (selectedFile !== null) {
          myfiles.push({
            id: myfile.length + 1,
            name: selectedFile.name,
            size: `${selectedFile.size}`,
            modify: `${selectedFile.lastModifiedDate}`,
            icon:"fa fa-file-text-o txt-info"
          })
          setMyFile(myfiles)
          toast.success("Fichier Téléchargé avec succés")
        }else {
          toast.error("Veuilez choisir  au moins un fichier")
        }
    };
    const addToSelectedListe = (id,type) => {
      let selectedUserCopy = selectedUser
      let selectedUserTypeCopy = selectedUsertype
      if(selectedUserCopy.includes(id) == true){
        selectedUserCopy.splice(selectedUserCopy.findIndex(res => res == id), 1)
        selectedUserTypeCopy.splice(selectedUserTypeCopy.findIndex(res => res.id == id), 1)
      setselectedUser(selectedUserCopy)
      setselectedUserType(selectedUserTypeCopy)
      setrandom(Math.random)
      }
      else {
        selectedUserCopy.push(id)
        selectedUserTypeCopy.push({id, type : type})
        setselectedUser(selectedUserCopy)
        setselectedUserType(selectedUserTypeCopy)
        setrandom(Math.random)
      }
        }
  const shareFolder = (e) => {
    e.preventDefault()
  
    dispatch(shareFolders(selectedid,selectedUser,selectedUsertype))
addToggle()
  }

  const RemoveFiles  = (Id, idfolder ) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
       dispatch(removeFile(Id,idfolder));
        SweetAlert.fire(
          'Supprimé!',
          'Votre fichier a été supprimé',
          'success'
        )
      }
      else {
      
      }
    })
  }
  const addFolderToFavoris = () => {
      
      
    dispatch(addFavorisFolders(selectedid))
    optionFolderToggle()
   
      }
      const addFileToFavoris = () => {
      
  
    dispatch(addFavorisFiles(selectedid, selectedidfolder ,selectedUser))
    optionToggle()
      }
    return (
        <div className="file-content">
           {/* <Card>
                  <CardHeader>
                    <h5>{OffsetToTooltip}</h5><span>{"lorem ipsum dolor sit amet, consectetur adipisicing elit"}</span>
                  </CardHeader>
                  <CardBody className="btn-showcase">
                    <MultiPopoverOffset x={position.x} y={position.y}/>
                  </CardBody>
                </Card> */}
<Modal >
  </Modal>
        <Card>
          <CardHeader>
       
            <div className="media">
              <Form className="form-inline">
                <FormGroup>
                  <i className="fa fa-search"></i>
                  <Input
                    className="form-control-plaintext"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleChange(e)}
                    placeholder="Recherche..." />
                </FormGroup>
              </Form>
              <div className="media-body text-right">
                <Form className="d-inline-flex">
                <div className="btn btn-primary" onClick={VaryingContentonetoggle}> <PlusSquare />{AddNewFolder}</div>
                  {/* <div className="btn btn-primary" onClick={getFile}> <PlusSquare />{AddNew}</div>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                    <input id="upfile" multiple type="file" onChange={(e) => onFileChange(e)} />
                  </div> */}

                </Form>
                {/* <div className="btn btn-outline-primary ml-1" onClick={onFileUpload}><Upload />{"Upload"}</div> */}
              </div>
              <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
               <ModalHeader toggle={VaryingContentonetoggle}>
                {NewFolder}
               </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Nom Dossier:"}</Label>
                      <Input onChange={(e) => setfolder_name(e.target.value)} value={folder_name} className="form-control" type="text" defaultValue=""/>
                    </FormGroup>
                 
                  </Form>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={VaryingContentonetoggle}>{Close}</Button>
                      <Button color="primary" onClick={handleAddFolder}>{AddFolder}</Button>
                </ModalFooter>
          </Modal>
          <Modal isOpen={VaryingContentoneUpdate} toggle={VaryingContentoneUpdatetoggle}>
               <ModalHeader toggle={VaryingContentoneUpdatetoggle}>
                {UpdateFolder}
               </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Name Folders:"}</Label>
                      <Input onChange={(e) => setfolder_name(e.target.value)} value={folder_name} className="form-control" type="text" defaultValue=""/>
                    </FormGroup>
                 
                  </Form>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={VaryingContentoneUpdatetoggle}>{Close}</Button>
                      <Button color="primary" onClick={handleUpdateFolder}>{UpdateFolder}</Button>
                </ModalFooter>
          </Modal>
            </div>
          </CardHeader>
          

            <CardBody className="file-manager">
             <Row>
              <Col lg="6">
              <h4 className="mb-3">{AllFolders}</h4>
              </Col>
                <Col lg="6">
            <div className=" media-body file-box text-right">
                      <select onChange={(e) => setsortState(e.target.value)} className="button btn-primary file-box">
                        <option value="trieraz">Trier par alphabet A-Z</option>
                        <option value="trierza">Trier par alphabet Z-A</option>
                        <option value="trierdate">Trier par date création</option>
                      </select>
                    </div>
                    </Col>
                   
              </Row>
              
              {/* <h6>{RecentlyOpenedFiles}</h6>
              <ul className="files">
                {filelist}
              </ul> */}
              <h6 className="mt-4">{Folders}</h6>
              {myfile.length > 0  && loadingfolder === false ?
                <ul className="folder">
                
                {typeUser === "admin" ? folderlist : folderlist1}

                </ul>
                 :
                 <ul className="folder">
                 {folderlisloading}
                 </ul>
                 
}
              {/* <h6 className="mt-4">{Files}</h6>
              <ul className="files">
                {filelist}
              </ul> */}
            </CardBody>
         
        </Card>
        <Modal isOpen={addModal} toggle={addToggle} size="lg">
                            <ModalHeader toggle={addToggle}>Recherche utilisateur</ModalHeader>
                            <ModalBody>
                              <Form onSubmit={(e) => shareFolder(e)} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                
                                  <FormGroup className="col-md-12">
                             
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                  { users.length > 0 ?
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                               {selectedImage?.usersInfos !== undefined ?  <h6 className="mt-4">{"Déja Partagé avec"}</h6>  : "" }
                               {selectedImage?.usersInfos !== undefined ? selectedImage?.usersInfos?.map((userpartage , i ) => 
                                   <li   className="clearfix">
                                   <Row>
                                     <Col sm="1" lg="1">
                                     <Media className="rounded-circle user-image" style={{float: 'left',
                                    width: '52px',
                                   height: '52px',
                                   marginRight: '5px'}} src={userpartage.userImage} alt="" />
                                     </Col>
                                   <Col sm="10" lg="10">
                                         <div>
                                           <div className="name">{userpartage.firstName +" "+ userpartage.lastName}</div>
                                           <div  className="status"> {userpartage.email}</div>
                                    </div>
                                    </Col>
                                    <Col sm="1" lg="1">
                                  
                             </Col>
                             </Row>
                                  </li>) : ""}
                                  <h6 className="mt-4">{"Partagé avec"}</h6> 
                               
                           
                                {users.filter(x => x.email.includes(searchvalue) == true || x.firstName.includes(searchvalue)== true || x.lastName.includes(searchvalue)== true).map((item, i) => {
                                    return (
                                   
                                      <li  onClick={() => addToSelectedListe(item.id, "Users")}  className="clearfix">
                                      <Row>
                                        <Col sm="1" lg="1">
                                        <Media className="rounded-circle user-image" style={{float: 'left',
                                       width: '52px',
                                      height: '52px',
                                      marginRight: '5px'}} src={item.userImage} alt="" />
                                        </Col>
                                      <Col sm="10" lg="10">
                                            <div>
                                              <div className="name">{item.firstName +" "+ item.lastName}</div>
                                              <div  className="status"> {item.email}</div>
                                       </div>
                                       </Col>
                                       <Col sm="1" lg="1">
                                       <div className="checkbox checkbox-primary mr-1">
                                  <Input  id={ i} onClick={() => addToSelectedListe(item.id, "Users")} type="checkbox" checked={selectedUser.includes(item.id) == true}  name={item.email} value={item.email} />
                                  <Label for={i}></Label>
                                </div>
                                </Col>
                                </Row>
                                     </li>
                                )
                              
                                })}
                                {fournisseurs.filter(x => x.email.includes(searchvalue) == true || x.firstName.includes(searchvalue)== true || x.lastName.includes(searchvalue)== true).map((item, i) => {
                                    return (
                                   
                                      <li  onClick={() => addToSelectedListe(item.id, "Fournisseurs")}  className="clearfix">
                                      <Row>
                                        <Col sm="1" lg="1">
                                        <Media className="rounded-circle user-image" style={{float: 'left',
                                       width: '52px',
                                      height: '52px',
                                      marginRight: '5px'}} src={item.fournisseurImage} alt="" />
                                        </Col>
                                      <Col sm="10" lg="10">
                                            <div>
                                              <div className="name">{item.firstName +" "+ item.lastName}</div>
                                              <div  className="status"> {item.email}</div>
                                       </div>
                                       </Col>
                                       <Col sm="1" lg="1">
                                       <div className="checkbox checkbox-primary mr-1">
                                  <Input  id={ i} onClick={() => addToSelectedListe(item.id, "Fournisseurs")} type="checkbox" checked={selectedUser.includes(item.id) == true}  name={item.email} value={item.email} />
                                  <Label for={i}></Label>
                                </div>
                                </Col>
                                </Row>
                                     </li>
                                )
                              
                                })}
                            </ul>
                            </div>
                            :  
                            <Media className="img-fluid m-auto" src={errorImg} alt=""/>
                            }
                                     </FormGroup>
                                </div>
                                <div className='text-right'>            <Button color="secondary" onClick={addToggle}>{Cancel}</Button>
                                <Button color="primary" className="mr-1">{Partager}</Button>
                    
                                </div>
                              </Form>
                            </ModalBody>
                          </Modal>
                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionModal} toggle={optionToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  <li>
                      <Link onClick={() =>{
                           showApercuToggle()
                           optionToggle()
                      } } className="btn btn-light"><Eye />{"Apercu"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => {optionToggle(); shareToggle()}}  className="btn btn-light"><User />{"Partager"}</Link>
                    </li>
                    <li>
                      <Link   className="btn btn-light" onClick={() => {

                          var httpsReference = firestorage.refFromURL(selectedImage.fileImage);  
                      httpsReference.getDownloadURL().then(res => alert(res))
                           
                      
                        }}><Share />{"Obtenir Le lien"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => {addFileToFavoris()}} className="btn btn-light"><Star />{"Ajouter au favoris"}</Link>
                    </li>
                    <li>
                      <Link  className="btn btn-light"><AlertCircle />{"Imprimer"}</Link>
                    </li>
                   
                    <li>
                      <Link    onClick={() =>  {optionToggle();  VaryingContentoneUpdatetoggle()}}  className="btn btn-light"><AlertCircle />{"Renommer"}</Link>
                    </li>
                    <li>
                    <a   target="_blank"  href={selectedImage.fileImage}  download={true}  rel="noopener noreferrer" className="btn btn-light"><Download />{"Télécharger"}</a>
                    </li>
                    <li>
                      <Link onClick={() => {optionToggle();  RemoveFiles(selectedImage.id, selectedImage.idfolder)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionFolderModal} toggle={optionFolderToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                <ul>
                  <li>
                      <Link onClick={() =>{
                          history.push(`${process.env.PUBLIC_URL}/app/details-folder-manager/${selectedid}`)
                           optionFolderToggle()
                      } } className="btn btn-light"><Eye />{"Ouvrir"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => {optionFolderToggle(); addToggle()}}  className="btn btn-light"><User />{"Partager"}</Link>
                    </li>
                    {/* <li>
                      <Link   className="btn btn-light" onClick={() => {

                          var httpsReference = firestorage.refFromURL(selectedImage.fileImage);  
                      httpsReference.getDownloadURL().then(res => alert(res))
                           
                      
                        }}><Share />{"Obtenir Le lien"}</Link>
                    </li> */}
                    <li>
                      <Link onClick={() => {addFolderToFavoris()}} className="btn btn-light"><Star />{"Ajouter au favoris"}</Link>
                    </li>
                 
                   
                    <li>
                      <Link    onClick={() =>  {optionFolderToggle();  VaryingContentoneUpdatetoggle()}}  className="btn btn-light"><AlertCircle />{"Renommer"}</Link>
                    </li>
                   
                    <li>
                      <Link onClick={() => {optionFolderToggle();  RemoveFolder(selectedImage.folder_id, selectedImage.parent_folder_id)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
      </div>
    )
}

export default MyDrive
