
import React from 'react';
import { Link } from 'react-router-dom';
import { firestorage } from '../../../data/config';



const ApercuFilePdf = ({selectedImage}) => {


    const printIframe = (id) => {
      var httpsReference = firestorage.refFromURL(selectedImage.url);  
     console.log(httpsReference.toString())
      };
     const onError =(e) => {
       
      }
    return (
       
          <div>
           <a href={selectedImage.url} color='secondary'  target='_blank'>Télécharger</a>
            {selectedImage.type?.includes("image") === true ?
            <>
          
            
         <img style={{width : '100%', height: '100%'}} src={selectedImage.url}/> 
         {/* <video width="320" height="240" controls>
  <source src="movie.mp4" type="video/mp4">
  <source src="movie.ogg" type="video/ogg">
  Your browser does not support the video tag.
</video> */}
         
            </>
              : <>
          
           
        <iframe src={selectedImage.url}  id="frame"  style={{width : '100%', height:'500px'}} frameborder="0"></iframe>

                            </>     



          
//                                 <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">

//  <Viewer
//                                fileUrl="https://www.ohchr.org/en/udhr/documents/udhr_translations/eng.pdf"
//                 plugins={[

//                 ]}
//             />
// </Worker>
           
            }

</div>


    
    )
}
export default ApercuFilePdf 