import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Media, Button } from 'reactstrap'
import {Email,MarekjecnoMailId,BOD,DDMMYY,Designer,ContactUs,ContactUsNumber,LocationDetails,JOHANDIO,UserProfileDesc1,UserProfileDesc2,UserProfileDesc3,Comment,MarkJecno,Like,Follower,Following,Location} from '../../../constant'
import { useHistory, useLocation } from 'react-router';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { useDispatch, useSelector } from 'react-redux';
import imageprofile from '../../../assets/images/dashboard/couverture.jpeg'
import { manageStateProvider } from '../../../redux/fournisseur/action';
import { Category } from 'emoji-mart';
import Product from './product';
import { WATCH_CATEGORY, WATCH_PRODUCT_LIST_BY_PROVIDER } from '../../../redux/actionTypes';
import { Link,useParams } from 'react-router-dom';
import { addProviderTofavoriteFranchise, fetchFournisseurByIdApi, removeProviderFromfavoriteFranchise } from '../../../api';
const FournisseurProfileEcommerce = (props) => {
const history = useHistory()
const {id} = useParams()
  const location = useLocation();
  const [url, setUrl] = useState();
  const [stateUser, setStateUser] = useState();
  const [fournisseur, setFournisseur] = useState({});
  const [typeUser, settypeUser] = useState("");
  const [providerListes, setproviderListes] = useState(localStorage.getItem("@intranetProviderListe") !== undefined ? JSON.parse(localStorage.getItem("@intranetProviderListe")) : []);
  useEffect(() => {
    settypeUser(localStorage.getItem("@intranetTypeUser"))
    fetchFournisseurByIdApi(id).then(res => {
 
      if(res !== undefined) 
      setFournisseur(res)
      setStateUser(res.user_state === "1" ? true : false)
        })
    
  
 
  
 }, []);
  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }
  const dispatch = useDispatch()
  const handleActivateChange = (e) => {
    if(e.target.checked === true){
      setStateUser(true)
dispatch(manageStateProvider(fournisseur.id, true))

}
  else {
    setStateUser(false)
    dispatch(manageStateProvider(fournisseur.id, false))

  }
  }
  const addToFavorisListe = (fourni) => {
    providerListes.findIndex(res => res.id === fournisseur.id) === -1 ?
    addProviderTofavoriteFranchise(fourni) : removeProviderFromfavoriteFranchise(fourni)
setTimeout(() => {
  setproviderListes(JSON.parse(localStorage.getItem("@intranetProviderListe")))
}, 2000);

  }
  return (
    <Fragment>
<Breadcrumb parent="Fournisseurs" title="Profile fournisseur" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <CardHeader className="cardheader" style={{backgroundImage : `url(${imageprofile})`, backgroundSize : 'contain', backgroundRepeat : 'no-repeat'}}>
                <div className="media-body text-right">
                {typeUser !== 'admin' ? <Button onClick={() => addToFavorisListe(fournisseur)} color={"primary"}>{ providerListes?.findIndex(res => res.id === fournisseur.user_id) > -1 ? "Delete de favorite" : "Ajouter au favoris"} <i className="icon-heart"></i></Button> : <></>}
</div>
                </CardHeader>
                <div className="user-image">
                  <div className="avatar"><Media body alt="" src={fournisseur ? fournisseur.user_image : require('../../../assets/images/user/7.jpg')} data-intro="This is Profile image" /></div>
                  <div className="icon-wrapper" data-intro="Change Profile image here">
                    <i className="icofont icofont-pencil-alt-5" onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/providers/providerEdit/${fournisseur.user_id}`)}>
                     
                    </i>
                  </div>
                </div>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row >
                        <Col md="6">
                          <div className="ttl-info text-left">
                            <h6><i className="fa fa-envelope mr-2"></i> {Email}</h6><span>{fournisseur.email}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-calendar"></i>   {"Date inscription"}</h6><span>{fournisseur.creation_date}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title"><a target="_blank" href="#javascript">{fournisseur.first_name} {fournisseur.last_name}</a></div>
                        <div className="desc mt-2">{fournisseur.role}</div>
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="4">
                          <div className="ttl-info text-left ttl-xs-mt">
                            <h6><i className="fa fa-phone"></i>   {ContactUs}</h6><span>{fournisseur.phone_number}</span>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-location-arrow"></i>   {Location}</h6><span>{fournisseur.region}</span>
                          </div>
                        </Col>
                        <Col md="4">
                 
                 <div className="ttl-info text-left ttl-sm-mb-0">
             

                       </div>
                


               
               </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <div className="social-media step4" data-intro="This is your Social details">
                    <ul className="list-inline">
                    {/* {fournisseur.category.map((category, i) =>  <li className="list-inline-item"><a href="#javascript">{category}</a></li>)}
                  */}
                    </ul>
                  </div>
             
                <Product /> 
                </div>
              </Card>
            </Col>
            {/* <Col sm="12" >
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc1}</p>
                  <div className="img-container">
                    <div id="aniimated-thumbnials">
                      <a href="#javascript">
                        <Media className="img-fluid rounded" src={require("../../../assets/images/other-images/profile-style-img3.png")} alt="gallery" />
                      </a>
                    </div>
                  </div>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc1}</p>
                  <Row className="mt-4 pictures">
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                  </Row>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc2}</p>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="12" xl="4">
                      <div id="aniimated-thumbnials-3"><a href="#javascript"><Media body className="img-fluid rounded" src={require("../../../assets/images/blog/img.png")} alt="gallery" /></a></div>
                      <div className="like-comment mt-4 like-comment-lg-mb">
                        <ul className="list-inline">
                          <li className="list-inline-item border-right pr-3">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                          </li>
                          <li className="list-inline-item ml-2">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xl="6">
                      <p>{UserProfileDesc3}</p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col> */}
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default FournisseurProfileEcommerce;