import { EVENTS } from 'react-feather';
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,
        ADD_CATEGORY,
        ADD_SOUS_CATEGORY,
        REMOVE_SOUS_CATEGORY,
        ADD_NOTE,
        UPDATE_NOTE,
        DELETE_NOTE,
        ADD_MAIL_GROUP,
        DELETE_MAIL_GROUP
       } from '../actionTypes';
    
import SweetAlert from 'sweetalert2'
import { ResetPassword } from '../../constant';
import axios from 'axios';
export const myevent = (myeventdata) => {
    return ({
        type: ADD_TO_MY_EVENTS,
        payload: {myeventdata}
    })
}

export const NewGroupeMail = (data) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
  
    axios({
        method: 'post',
        url: `${urlbackend}mailgroupe/create.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
   
        SweetAlert.fire(
            'Groupe Email ajoutée',
            'Groupe Email a été ajoutée avec succés',
            'success'
          )
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de l ajout veuillez réessayer',
            'success'
          )
       
      
    });
  
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: ADD_MAIL_GROUP,
    payload: {data}
})
}

export const UpdateNotes = (eventdata) => {
    let id = localStorage.getItem("@intranetidUser");
    let type = localStorage.getItem("@intranetTypeUser");
  
 db
    .collection(type === "admin" ? "Admin" : (type === "manager" || type === "submanager") ? "Users" : "Fournisseurs" )
    .doc(id)
    .collection("MailGroup").doc(eventdata.id).update(eventdata)
      
    SweetAlert.fire(
        'Groupe Email Modifié',
        'Groupe Email a été Modifié avec succés',
        'success'
      )
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: UPDATE_NOTE,
    payload: {eventdata}
})
}


export const deleteNotes = (idconv) => {

  
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}mailgroupe/delete.php`,
        data: JSON.stringify({"groupe_id" : idconv}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Groupe Email supprimé',
            'Groupe Email a été supprimé avec succés',
            'success')
    
  

    }).catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
  
      
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: DELETE_MAIL_GROUP,
    payload: {id : idconv}
})
}
export const NewCategory = (eventdata ) => {

  
        db.collection("Category").add(eventdata)
        SweetAlert.fire(
            'Catégorie ajoutée',
            'Catégorie a été ajoutée avec succés',
            'success'
          )
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))

  
    return ({
        type: ADD_CATEGORY,
        payload: {eventdata}
    })
}
export const updateevent = (id,startdate,enddate) => {
    db.collection("EVENTS").doc(id).update({start : startdate+"" , end : enddate+""})
    return ({
        type: UPDATE_EVENTS,
        payload: {id,startdate,enddate}
    })

}
export const manageStateNew = (id,state) => {
    db.collection('EVENTS').doc(id).update({EVENTState : state})
    return ({
        type: UPDATE_STATE_EVENTS,
        payload: {id,state}
    })

}
export const updateMyevent = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_EVENTS,
        payload: {id,updateMydata,image_url}
    })

}
export const removeevent = (removeid) => {
    db.collection('EVENTS').doc(removeid).delete()
    return ({
        type: REMOVE_EVENTS,
         removeid
    })

}
export const removemyevent = (eventid) => {
    return ({
        type: REMOVE_FROM_MY_EVENTS,
        eventid
    })
}