import React, { useState, useEffect, Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, Button, Form, FormGroup, Input, Media, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { SEARCH_BY, SORT_BY, ADD_TO_CART, ADD_TO_WISHLIST, WATCH_SUBFRANCHISE_LIST } from '../../../redux/actionTypes'

import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import errorImg from '../../../assets/images/search-not-found.png';
import { Lock, MapPin } from 'react-feather';
import GoogleMapReact from 'google-map-react';

import { getVisibleproducts } from '../../../services/ecommerce.service'

import { NotFoundData, CreateNewSubFranchise } from "../../../constant";

import {  manageStateFranchise } from '../../../redux/franchise/action';
import { removesubfranchise } from '../../../redux/subfranchise/action';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import SweetAlert from 'sweetalert2'
import moment from 'moment';
import { fetchFranchiseByIdApi } from '../../../api';
const FranchiseDetail = (props) => {
const {idfranch} = useParams()
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  const data = useSelector(content => content.data.productItems);
  // eslint-disable-next-line 
  const [layoutColumns, setLayoutColumns] = useState(3);
  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  }
  const[locationn,setlocation] = useState({ 

    lat: 18.5204, lng: 73.8567
   
})
  const symbol = useSelector(content => content.data.symbol);
 const subfranchises = useSelector(content => content.SubFranchiseapp.subfranchises);

  const [open, setOpen] = useState(false);
  const [sidebaron, setSidebaron] = useState(true);
  const [stateUser, setStateUser] = useState();
  const [franchise, setFranchise] = useState({});
  const [singleProduct, setSingleProduct] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  // eslint-disable-next-line

  const [quantity, setQuantity] = useState(1);
  const filters = useSelector(content => content.filters);

  const franchises = useSelector(content => content.Franchiseapp.franchises);

  useEffect(() => {
    fetchFranchiseByIdApi(idfranch).then(res => {
      console.log(res)
      setFranchise(res)
      setStateUser(res.franchise_state)
      dispatch({type: WATCH_SUBFRANCHISE_LIST})
   
    })
   
    
   // dispatch(watchfetchProducts())
  },[]);



  const gridLayout = () => {
    document.querySelector(".product-wrapper-grid").classList.remove("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = '';
      el.classList.add('col-xl-3');
      el.classList.add('col-sm-6');
      el.classList.add('xl-4')
    });
  }

  const listLayout = () => {
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".gridRow").childNodes;
    [].forEach.call(elems, function (el) {
      el.className = '';
      el.classList.add('col-xl-12');
    });
  }

  const LayoutView = (layoutColumns) => {
    if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
      var elems = document.querySelector(".gridRow").childNodes;
      [].forEach.call(elems, function (el) {
        el.className = '';
        el.classList.add('col-xl-' + layoutColumns);
      });
    }
  }

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false)
      document.querySelector(".product-wrapper").classList.add('sidebaron');
    } else {
      setSidebaron(true)
      document.querySelector(".product-wrapper").classList.remove('sidebaron');
    }
  }
  const handleActivateChange = (e) => {
    if(e.target.checked === true){
      setStateUser(true)
      dispatch(manageStateFranchise(franchise.id, true))
//dispatch(manageStateUser(user.id, true))

}
  else {
    setStateUser(false)
    dispatch(manageStateFranchise(franchise.id, false))
  //  dispatch(manageStateUser(user.id, false))

  }
  }


  const onCloseModal = () => {
    setOpen(false)
  };




  
  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
    dispatch({ type: SEARCH_BY, search: keyword })
  }



const removeSubFranchise = (id) => {
  SweetAlert.fire({
    title: 'Êtes-vous sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ce sous  franchise !",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
     dispatch(removesubfranchise(id));
     
      SweetAlert.fire(
        'Supprimé!',
        'Votre Sous franchise a été supprimé.',
        'success'
      )
    }
    else {
      
    }
  })
}
const goToListeAudits = () =>  {
history.push(`${process.env.PUBLIC_URL}/app/franchises/audit-list/${franchise.franchise_id}`,{franchise : franchise})
}
const goToListeAnnuaires = () =>  {
  history.push(`${process.env.PUBLIC_URL}/app/franchises/annuaire-list/${franchise.franchise_id}`,{franchise : franchise})
  }
  const AnyReactComponent = ({ text,marquer, franchisesd }) => <div> <MapPin onClick={(e) => {

   history.push(`${process.env.PUBLIC_URL}/app/franchises/subfranchisesDetails/${franchise.subfranchise_id}` ,{franchise : franchise, subfranchise : franchisesd})
   
   }} size={30} color={marquer}></MapPin></div>;
  return (
    <Fragment>
      <Breadcrumb parent="Enseignes" title="Détail Enseigne" />
      <div className="media-body text-right">
      <Form className="d-inline-flex mr-2">
                <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} onClick={() => goToListeAnnuaires() }>{"Liste Annuaires"}</Link>
                

                </Form>
                <Form className="d-inline-flex mr-2">
                <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} onClick={() => goToListeAudits() }>{"Liste audits"}</Link>
                

                </Form>
                <Form className="d-inline-flex">

                <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }}  onClick = {() => history.push(`${process.env.PUBLIC_URL}/app/franchises/${franchise.franchise_id}/subfranchiseAdd/`, {franchise : franchise })}> {CreateNewSubFranchise}</Link>
               
</Form>
              </div>
      <Container fluid={true} className="product-wrapper">
   
        <div className="product-grid">
          <div className="feature-products">
            <Row>
            <Col xl="12 box-col-12 xl-100">
                        <Card>
                            <div className="blog-box blog-shadow">
                            <div style={{ width : '100%', height : '400px', zIndex : 101 }}>
          <GoogleMapReact
        
        options={{mapTypeControl : true, streetView : true, draggable : true }}
        bootstrapURLKeys={{ key: 'AIzaSyCNdpXcuLDb26QUSLCkqW2kBs7aEO9u1dM'}}
        defaultCenter={locationn}
        defaultZoom={11}
        draggable={true}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
          {subfranchises?.map((item, i) =>
    <AnyReactComponent
     franchisesd={item}
     marquer={item.marquer}
     lat={item.lat}
     lng={item.lng} 
    />
          )}
      </GoogleMapReact>
      </div>
                                <Media className="img-fluid" style={{width : '100%', height: 300,backgroundSize : 'cover'}} src={franchise.franchise_image} alt="" />
                                <div className="blog-details" style={{padding : 9}}>
                                    <p className="digits">{franchise !== undefined ? moment(franchise.launchDate).format("DD-MM-YYYY") : ""}</p>
                                    <h4>{franchise.franchise_bio}</h4>
                                    <ul className="blog-social">
                                        <li><h2>{franchise.franchise_name}</h2></li>
                                        {/* <li className="digits"><i className="icofont icofont-institution"></i>{franchise.SubFranchiseNumber + " Franchisés ou restaurants"}</li> */}
                                        <li className="digits"><i className="icofont icofont-ui-chat"></i>  <p>{franchise.manager_name ? 'Responsable : '+ franchise.manager_name  : ""}</p></li> 
                                    </ul>
                                </div>
                     
                            </div>
                        </Card>
                    </Col>
                    {/* <Col md="6" className="products-total">
                    <div className="ttl-info text-left ttl-sm-mb-0" data-intro="This is your Social details">
                        <h6>{"Désactivate"}</h6>
                  <Toggle
  defaultChecked={stateUser}
  value={stateUser}
  aria-label='Activate'
  onChange={handleActivateChange} 
 />


                  </div>
                  </Col> */}
              {/* <Col md="6" className="products-total">
                <div className="square-product-setting d-inline-block">
                  <a className="icon-grid grid-layout-view" onClick={gridLayout} href="#javascript"  >
                    <Grid />
                  </a>
                </div>
                <div className="square-product-setting d-inline-block">
                  <a className="icon-grid m-0 list-layout-view" onClick={listLayout} href="#javascript" >
                    <List />
                  </a>
                </div>
                <span className="d-none-productlist filter-toggle" onClick={() => setFilterSidebar(!filterSidebar)} >
                  <h6 className="mb-0">{Filters}
                      <span className="ml-2">
                      <i className="toggle-data fa fa-chevron-down"></i>
                    </span>
                  </h6>
                </span>
                <div className="grid-options d-inline-block">
                  <ListGroup as="ul">
                    <li>
                      <a className="product-2-layout-view" onClick={() => LayoutView(6)} href="#javascript" >
                        <span className="line-grid line-grid-1 bg-primary"></span>
                        <span className="line-grid line-grid-2 bg-primary"></span>
                      </a>
                    </li>
                    <li><a className="product-3-layout-view" onClick={() => LayoutView(4)} href="#javascript" >
                      <span className="line-grid line-grid-3 bg-primary"></span>
                      <span className="line-grid line-grid-4 bg-primary"></span>
                      <span className="line-grid line-grid-5 bg-primary"></span>
                    </a>
                    </li>
                    <li>
                      <a className="product-4-layout-view" onClick={() => LayoutView(3)} href="#javascript" >
                        <span className="line-grid line-grid-6 bg-primary"></span>
                        <span className="line-grid line-grid-7 bg-primary"></span>
                        <span className="line-grid line-grid-8 bg-primary"></span>
                        <span className="line-grid line-grid-9 bg-primary"></span>
                      </a>
                    </li>
                    <li>
                      <a className="product-6-layout-view" onClick={() => LayoutView(2)} href="#javascript">
                        <span className="line-grid line-grid-10 bg-primary"></span>
                        <span className="line-grid line-grid-11 bg-primary"></span>
                        <span className="line-grid line-grid-12 bg-primary"></span>
                        <span className="line-grid line-grid-13 bg-primary"></span>
                        <span className="line-grid line-grid-14 bg-primary"></span>
                        <span className="line-grid line-grid-15 bg-primary"></span>
                      </a>
                    </li>
                  </ListGroup>
                </div>
              </Col> */}
              {/* <Col md="6" className="text-right">
                <span className="f-w-600 m-r-5">{ShowingProducts}</span>
                <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => filterSortFunc(e.target.value)}>
                  <select className="form-control btn-square" name="select">
                    <option value="Featured">{Featured}</option>
                    <option value="LowestPrices">{LowestPrices}</option>
                    <option value="HighestPrices">{HighestPrices}</option>
                  </select>
                </div>
              </Col> */}
            </Row>
            <Row>
              {/* <Col xl="3">
                <div className={`product-sidebar ${filterSidebar ? '' : 'open'}`}>
                  <div className="filter-section">
                    <Card>
                      <CardHeader>
                        <h6 className="mb-0 f-w-600">{Filters}
                            <span className="pull-right">
                            <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                          </span>
                        </h6>
                      </CardHeader>
                      <div className="left-filter">
                        <CardBody className="filter-cards-view animate-chk">
                          <Allfilters />
                          <Carousal />
                          <div className="product-filter text-center mt-2">
                            <Media className="img-fluid banner-product m-auto" src={require("../../../assets/images/ecommerce/banner.jpg")} alt="" />
                          </div>
                        </CardBody>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col> */}
              <Col xl="12" sm="12">
                <Form>
                  <FormGroup className="m-0">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="search"
                      defaultValue={searchKeyword}
                      onChange={(e) => handleSearchKeyword(e.target.value)}
                    />
                    <i className="fa fa-search"></i>
                  </FormGroup>
                </Form>
              </Col>
             
            </Row>
          </div>

          <div className="product-wrapper-grid">
            {searchKeyword.length > 0 ?
              <div className="search-not-found text-center">
                <div>
                  <img className="img-fluid second-search" src={errorImg} alt="" />
                  <p>{NotFoundData}</p>
                </div>
              </div>
              :
              <Row className="gridRow">
                {subfranchises ? subfranchises.map((item, i) =>
                  <div className={`${layoutColumns === 3 ? 'col-xl-3 col-sm-6 xl-4 col-grid-box' : 'col-xl-' + layoutColumns}`} key={i}>
                    <Card>
                      <div className="product-box">
                        <div className="product-img">
                         
                            <span className={`badge ${ item.subfranchise_state  == "1"  ? 'ribbon ribbon-success' : 'ribbon ribbon-danger'}`} >
                            {item.subfranchise_state  == "1" ? 'Active' : 'Inactive'}
                            </span> 
                         
                          <img className="img-fluid" src={franchise.franchise_image} alt="" />
                          <div className="product-hover">
                            <ul>
                              <li>
                                <Link  onClick={() => removeSubFranchise(item.subfranchise_id)}>
                                  <Button color="default">
                                    <i className="icon-trash"></i>
                                  </Button>
                                </Link>
                              </li>
                              <li>
                                <Button color="default" data-toggle="modal"
                                  onClick={() =>   history.push(`${process.env.PUBLIC_URL}/app/franchises/subfranchisesDetails/${item.subfranchise_id}` ,{franchise : franchise, subfranchise : item})}>
                                  <i className="icon-eye"></i>
                                </Button>
                              </li>
                              <li>
                                <Link onClick={() => history.push(`${process.env.PUBLIC_URL}/app/franchises/subfranchisesEdit/${item.subfranchise_id}` )}>
                                  <Button color="default">
                                    <i className="icofont icofont-pencil-alt-5"></i>
                                  </Button>
                                </Link>
                              </li> 
                            </ul>
                          </div>
                        </div>
                        <div className="product-details">
                          {/* <div className="rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div> */}
                          <h4 className="font-primary" >{item.franchise_name}</h4>
                          <p>{item.manager_name}</p>
                          <p>{item.adresse + " " + item.ville+ " " + item.pays}</p>
                          <p>{item.phone_number}</p>
                          
                         
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : ''}

                <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={open}>
                  <ModalBody>
                    <ModalHeader toggle={onCloseModal}>
                      <div className="product-box row">
                        <Col lg="6" className="product-img">
                          <Media className="img-fluid" src={franchise.franchise_image} alt="" />
                        </Col>
                        <Col lg="6" className="product-details  text-left">
                          <h4>{singleProduct.managerName}</h4>
                       
                          <div className="product-view">
                            <h6 className="f-w-600">{singleProduct.adresse}</h6>
                            <p className="mb-0">{singleProduct.subfranchise_bio}</p>
                          </div>
                          {/* <div className="product-size">
                            {/* <ul>
                              {ProductSizeArray.map((items,i) => 
                                <li key={i}>
                                  <Button color="outline-light">{items}</Button>
                                </li>
                              )}
                            </ul> 
                          </div> */}
                          {/* <div className="product-qnty">
                            <h6 className="f-w-600">{Quantity}</h6>
                            <fieldset>
                              <InputGroup className="bootstrap-touchspin">
                                <InputGroupAddon addonType="prepend">
                                  <Button color="primary btn-square" className="bootstrap-touchspin-down" onClick={minusQty}><i className="fa fa-minus"></i></Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText className="bootstrap-touchspin-prefix" style={{ display: "none" }}></InputGroupText>
                                </InputGroupAddon>
                                <Input className="touchspin text-center" type="text" name="quantity" value={quantity} onChange={(e) => changeQty(e)} style={{ display: "block" }} />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText className="bootstrap-touchspin-postfix" style={{ display: "none" }}></InputGroupText>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append" className="ml-0">
                                  <Button color="primary btn-square" className="bootstrap-touchspin-up" onClick={plusQty}><i className="fa fa-plus"></i></Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </fieldset>
                            <div className="addcart-btn">
                              <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/cart`}><Button color="primary" className="mr-2 mt-2" onClick={() => addcart(singleProduct, quantity)}>{AddToCart}</Button></Link>
                              <Button onClick={() => onClickDetailPage(singleProduct)} color="primary" className="mr-2 mt-2">{ViewDetails}</Button>
                            </div>
                          </div> */}
                        </Col>
                      </div>
                    </ModalHeader>
                  </ModalBody>
                </Modal>
              </Row>
            }
          </div>
        </div>
      </Container>
    </Fragment>
  );
}

export default FranchiseDetail;