import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Calendar, momentLocalizer,Views} from 'react-big-calendar'
import moment from 'moment'
import myEventsList from "./events";
import {Container,Row,Col,Card,CardHeader,CardBody} from 'reactstrap'
import { EventsListe } from '../../../constant';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { WATCH_EVENTS_BY_FOURNISSEUR_LIST, WATCH_EVENTS_BY_MARQUE_LIST, WATCH_EVENTS_LIST, WATCH_NEWS_BY_MARQUE_LIST } from '../../../redux/actionTypes';

const localizer = momentLocalizer(moment)
let allViews = Object.keys(Views).map(k => Views[k])


const EventsCalender = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [activeTab,setActiveTab] = useState("1")
    const events = useSelector(content => content.Eventsapp.events);
    console.log(events)
    //const doingProject = useSelector(content => content.Franchiseapp.doing_Project);
    //const doneProject = useSelector(content => content.Franchiseapp.done_Project);
    useEffect(() => {
        if( localStorage.getItem("@intranetTypeUser") === 'admin')
        dispatch({type: WATCH_EVENTS_LIST})
        else if (localStorage.getItem("@intranetTypeUser") === 'Fournisseur')
        dispatch({type: WATCH_EVENTS_BY_FOURNISSEUR_LIST})
        else 
        dispatch({type: WATCH_EVENTS_BY_MARQUE_LIST})
  
  
        
    },[dispatch])
    console.log(myEventsList)
    return (
        <Fragment>
            <Breadcrumb parent="Calendar" title="Basic Calender"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>{EventsListe}</h5>
                            </CardHeader>
                            <CardBody>
                                <Calendar
                                    localizer={localizer}
                                    scrollToTime={new Date(1970, 1, 1, 6)}
                                    defaultDate={new Date()}
                                    onSelectEvent={event => alert(event.title)}
                                    views={allViews}
                                    events={events}
                                    eventOverlap
                                    dragRevertDuration={500}
                                    dragScroll
                                    droppable={true}
                                    showMultiDayTimes
                                    step={60}
                                    startAccessor="start"
                                    endAccessor="end"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
    
};

export default EventsCalender;