import React, { Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'

import {Container,Row,Col,Media, Card, Nav, NavItem, NavLink,Input,InputGroup,InputGroupAddon,Button, FormGroup, CardBody, Modal, ModalBody, Label} from 'reactstrap'
import { Cancel, Comment,Files,JolioMark } from "../../../constant";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Edit2, Info, Play, PlusCircle, Target, Trash2 } from 'react-feather';
import Board from '@lourenci/react-kanban'
import { fetchUpdateSouSTacheApi, fetchUpdateTacheApi } from '../../../api';
import { WATCH_SOUS_TACHE_LIST } from '../../../redux/actionTypes';
import {  removeSousTaches, removeTaches, sendCommentTache } from '../../../redux/project/action';
import SweetAlert from 'sweetalert2'
import UpdateSoustacheModal from './update-sous-tache-modal';
import UpdatetacheModal from './update-tache-modal';
import ModalApercuFile from './modalApercuFile';
import { toast } from 'react-toastify';
import { firestorage } from '../../../data/config';
import Dropzone from 'react-dropzone-uploader';
const TacheDeatilUser = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const allSousProject = useSelector(content => content.Project.sous_tache_Project);

const [selectedImage, setselectedImage] = useState({})
  const [random, setRandom] = useState(0)
  const [loading, setLoading] = useState(true)
  
  const {idtache,idprojet} = useParams()
  const allProject = useSelector(content => content.Project.tache_Projects);
  const [tache, setTache] = useState({})
  const [stateComment, setstateComment] = useState(false)
  const stateCommenttoggle = () => {setstateComment(!stateComment)}
  const [stateSoustache, setstateSoustache] = useState(false)
  const stateSoustachetoggle = () => {setstateSoustache(!stateSoustache)}

  const [selectedSousTache, setselectedSousTache] = useState({})
  const [updateModal,setupdateModal] = useState(false)
  const UpdateToogle = () => { setupdateModal(!updateModal) }
  const [messageInput, setMessageInput] = useState('');
  const [myfilee, setMyFile] = useState([])
  const [updateTachModal,setupdateTacheModal] = useState(false)
  const UpdateTacheToogle = () =>  setupdateTacheModal(!updateTachModal) 

  const [statejointModal,setstatejointModal] = useState(false)
  const statejointModalToogle = () =>  setstatejointModal(!statejointModal) 

  useEffect(() => {
      setTache(allProject.find(res => res.id === idtache))
      dispatch({type : WATCH_SOUS_TACHE_LIST ,id : idprojet,idtache : idtache})

      setTimeout(() => {
    setRandom(Math.random())
    setLoading(false)
}, 2000); 
}, [])

const handleCardMove = (_card, source, destination) => {

let tacheSelect = _card.lanes[Number(destination.laneId)].cards[destination.index]
fetchUpdateSouSTacheApi(tacheSelect.projetId,tache.id,tacheSelect.id,_card.lanes[Number(destination.laneId)].title,_card.lanes[Number(destination.laneId)].title,source.laneId,destination.laneId)

 
}
const removesoustache = (idprojet,id,idtache,status) => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
     
     
 
     
      
      dispatch(removeSousTaches(idprojet,id,idtache,status))
      dispatch({type : WATCH_SOUS_TACHE_LIST ,id : idprojet,idtache : idtache})
      SweetAlert.fire(
        'Supprimé!',
        'Votre Tâche a été supprimée',
        'success'
      )
      stateSoustachetoggle()
    }
    else {
    
    }
  })
}
const removetache = () => {
  SweetAlert.fire({
    title: 'Vous Êtes sûr?',
    text: "Une fois supprimé, vous ne pourrez plus récupérer ",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
      history.push(`${process.env.PUBLIC_URL}/app/projet/project-liste/`)
    
      SweetAlert.fire(
        'Supprimé!',
        'Votre Tâche a été supprimée',
        'success'
      )
      dispatch(removeTaches(tache.projetId,tache.id,tache.status))
   
     
    }
    else {
    
    }
  })
}
const addComment = () => {

  let data = {}
  data.comment = messageInput
  data.idtache = tache.id
  data.files = myfilee
  data.idprojet = tache.projetId
  data.userName = localStorage.getItem("@intranetLastUser") + " "+ localStorage.getItem("@intranetFirstUser")
  data.userID = localStorage.getItem("@intranetidUser")
  data.userImage = localStorage.getItem("@intranetimageUser")
  data.createdAt = moment(new Date()).format('yyyy-MM-DD HH:mm')

 tache?.comment.push(data)
dispatch(sendCommentTache(tache.projetId,tache.id,data))
}
const manageTache = (status,idStatus) => {
  let idstatus = 0
if(tache.status === "A Faire")
idstatus = 0
else if(tache.status === "En Cours")
idstatus = 1
else if(tache.status === "Terminé")
idstatus = 2
tache.status = status
fetchUpdateTacheApi(tache.projetId,tache.id,status,idstatus,idStatus)

}
const [showApercu, setshowApercu] = useState(false);
const showApercuToggle = () => { setshowApercu(!showApercu) }
const filelist2 = tache?.files?.map((data,i)=>{
    return(
      <li    onDoubleClick={() => {
         
         setselectedImage(data)
          showApercuToggle()
          }} className="file-box" key={i}>


        <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
        <div className="file-bottom">
          <h6>{data.name}</h6>

        </div>
     
             
      </li>
    )
  })


  const getUploadParams = ({ meta, file }) => {

       
    
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/commentairetachesclient/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/commentairetachesclient`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;
  
      
        myfils.push({
      url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          console.log(myfils)
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
  if (status === "removed"){
  let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
  setMyFile(myfileeCopy)
  }
  }
    return (
        <Fragment>
          <Breadcrumb parent="Projets" title="Détail Tâche"/>
            <Container fluid={true} className="jkanban-container">
            <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  <Nav tabs className="border-tab">
                  {/* <NavItem><NavLink disabled={tache?.status === "A Faire"} onClick={() => manageTache ("A Faire", 0)} className={tache?.status === "A Faire" ? "active" : ''}  ><Info />{tache?.status === "A Faire" ? "A Faire" : "Marquer comme En Cours" }</NavLink></NavItem> */}
                    <NavItem><NavLink disabled={tache?.status === "En Cours"} onClick={() => manageTache("En Cours", 1)} className={tache?.status === "En Cours" ? "active" : ''}  ><Info />{tache?.status === "En Cours" ? "En Cours" : "Marquer comme En Cours" }</NavLink></NavItem>
                    <NavItem><NavLink disabled={tache?.status === "Terminé"}  onClick={() => manageTache("Terminé", 2)} className={tache?.status === "Terminé" ? "active" : ''}  ><Info />{tache?.status === "Terminé" ? "Terminée" : "Marquer comme terminée"}</NavLink></NavItem>

                  </Nav>
                </Col>
              
              </Row>
            </Card>
            </Col>
            </Row>
            <Row>
              <Col xl="12">
                <div className="blog-single">
                  <div className="blog-box blog-details">
                    {/* <Media className="img-fluid w-100" src={} alt="blog-main"/> */}
                    <div className="blog-details">
                      <ul className="blog-social">
                        <li className="digits">{moment(tache?.startDate?.toDate()).format("DD-MM-YYYY")}</li>
                        <li><i className="icofont icofont-user"></i>{tache?.responsableName} <span></span></li>
                        <li className="digits"><i className="icofont icofont-ui-chat"></i>{tache?.comment?.length + " Commentaire"}</li>
                         <li className="digits">Etat Tache : {tache?.status}</li>
    
                      </ul>
                      <h4>
                        {tache?.title}
                      </h4>
                      <div className="single-blog-content-top">
                        <p>{tache?.description}</p>
                      
                      </div>
                      <h6 className="mt-4">{Files}</h6>
                      {filelist2?.length > 0 ?
              <ul className="files">
                  { filelist2 }
       
              </ul> : ""}
                    </div>
                  </div>
                  <section className="comment-box">
                    <button className="btn btn-primary" onClick={() => stateCommenttoggle()}>{stateComment ? "Masquer Commentaire" : "Voir Commentaire"}</button>
                    <hr/>
                    {stateComment ? <>
                   
                    <ul >
                    {tache?.comment?.map((commentaire , index ) => 
                              
                          
                      <li>
                        <Media className="align-self-center">
                        <Media className="align-self-center" src={commentaire.userImage} alt=""/> 
                          <Media body>
                            <Row>
                              
                              <Col md="4 xl-100">
                                <h6 className="mt-0">{commentaire.userName}</h6>
                              </Col>
                         
                            </Row>
                            <p>{commentaire.comment}</p>
                            <ul className="files">
                            { commentaire.files?.map((data,i)=>{
    return(
      <li    onDoubleClick={() => {
         
         setselectedImage(data)
          showApercuToggle()
          }} className="file-box" key={i}>


        <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
        <div className="file-bottom">
          <h6>{data.name}</h6>

        </div>
     
             
      </li>
    )
  })
}
</ul>
                          </Media>
                        </Media>
                      </li>
                         )}
                 
                    </ul>
                    <div className="chat-message clearfix">
                            <Row>
                           
                              <Col xl="12" className="d-flex">
                              
                                <InputGroup className="text-box">
                                    <Input
                                        type="text"
                                        className="form-control input-txt-bx"
                                        placeholder="Ecrire un commentaire......"
                                        value={messageInput}
                                      onChange={(e) => setMessageInput(e.target.value)}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button color="primary" onClick={() => addComment()}   className="form-control input-txt-bx"  >{"Commenter"}</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </div>
                    </> : ""
                    }
                  </section>
                  <section className="comment-box">
                    <button className="btn btn-primary" onClick={() => stateSoustachetoggle()}>{stateSoustache ? "Masquer Sous tache" : "Voir Sous Tache"}</button>
                    <hr/>
                    {stateSoustache ? 
                    loading ==false && allSousProject ?
                    <Col xs="12">
                      <Card>
                         
                          <CardBody>
                              <div id="demo2">
                                  <div className="kanban-container">
                                      <div className="kanban-board">
                                          <main  className="kanban-drag">
                                              <Board  onCardDragEnd={handleCardMove}
                                                  renderCard={(item) => (
                                                      <div onDoubleClick={() => history.push(`${process.env.PUBLIC_URL}/app/projet/tache/sous-tache-detail-user/${item.projetId}/${item.id}`)}  className="kanban-item">
                                                          <a className={`kanban-box bg-light-info`} href="#javascript">
                                                              <span className="date">{moment(item.startDate.toDate()).format("DD-MM-YYYY")}</span>
                                                              <span className={`badge badge-success f-right`}>{item.priority}</span>
                                                              <h6>{item.title}</h6>
                                                              <div className="media">
                                                                  
                                                                  <div className="media-body">
                                                                      <p>{item.description}</p>
                                                                  </div>
                                                              </div>
                                                              <div className="d-flex mt-3">
                                                                  <ul className="list">
                                                                      <li><i className="fa fa-comments-o"></i>{item.comment.length}</li>
                                                                      <li><i className="fa fa-paperclip"></i>{item.files.length}</li>
                                                                      <li><i className="fa fa-eye"></i>{item.remarques.length}</li>
                                                                  </ul>
                                                                  <div className="d-flex mt-3">
                                                            <ul className="list">
                                                           

                                                            <li > <a onClick={() => history.push(`${process.env.PUBLIC_URL}/app/projet/tache/sous-tache-detail-user/${item.projetId}/${item.id}`)}  ><Play  size={20} /></a></li>
                                                           
                                                           </ul>
                                                        </div>
                                                              </div>
                                                          </a>
                                                      </div>
                                                  )}
          
                                              >
                                              {allSousProject}
                                              </Board>
                                          </main>
                                      </div>
                                  </div>
                              </div>
                            
                          </CardBody>
                      </Card>
                      </Col>
                      :"" : ""
                    }
                  </section>
                </div>
              </Col>
              <UpdatetacheModal selectedTache={tache} updateModal={updateTachModal} UpdateToogle={UpdateTacheToogle} />
                              <UpdateSoustacheModal selectedTache={selectedSousTache} updateModal={updateModal} UpdateToogle={UpdateToogle} />

              <ModalApercuFile  showApercu={showApercu} showApercuToggle={showApercuToggle} selectedImage={selectedImage} />
              <Modal isOpen={statejointModal} toggle={statejointModalToogle}>
<ModalBody>
<Row>
                 
                        <Label>{"Joint fichier"}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
               </Row> 
               <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                   onClick={() => statejointModalToogle()}
                        >
                          <Button  onClick={() => statejointModalToogle()} color="secondary">{Cancel}</Button>
                        </Link>
                        <Button color="primary" className="mr-3">
                          {"Valider"}
                        </Button>
                       
                      </FormGroup>
                    </Col>
                  </Row>
</ModalBody>

          </Modal>
          </Row>
          </Container>
        </Fragment>
    );
};

export default TacheDeatilUser;