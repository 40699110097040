import { Users } from 'react-feather';
import { db, firebase_app, firestorage, urlbackend } from '../../data/config';
import SweetAlert from 'sweetalert2'
import {ADD_TO_MY_USER,
        ADD_NEW_USER,
        REMOVE_USER,
        REMOVE_FROM_MY_USER,
        UPDATE_USER,
        UPDATE_MY_USER,
        UPDATE_STATE_USER,
        UPDATE_USER_EMAIL,
       } from '../actionTypes';
import { fetchSendemailsApi, fetchSendemailsBienvenueApi } from '../../api';
import axios from 'axios';


export const myuser = (myuserdata) => {
    return ({
        type: ADD_TO_MY_USER,
        payload: {myuserdata}
    })
}

export const NewUser = (userdata ,  avatar) => {
   
   
        fetchSendemailsBienvenueApi("<html><body><p>"+`Cher franchisé,
        Nous sommes heureux de vous compter parmi nous, 
        voici les accès au manuel de savoir-faire de l'enseigne, <br> Email: ${userdata.email} <br> Password : ${userdata.password} <br>
        vous allez pouvoir avec notre intranet être en intéraction permanente avec les équipes supports
        et vivre votre parcours franchisés de façon efficace pour vous accompagner au mieux.
        Un chat en ligne, un service de mail, un accès fournisseurs, un accès aux annuaires, newsletter marketing...<br>
        Vous aurez également accès à vos audits en ligne avec les notes et suivre ainsi votre progression.
        Afin de pouvoir accéder a l'intranet et au manuel, merci de signé en ligne l'audit de formation de l'enseigne"
        attestation formation initale
        attestation remise de manuel...
        Tous signe en ligne (preuve qui se stock)
        `+"<p></body></html>","Bienvenue dans Go Cloud",userdata.email)
        const token = localStorage.getItem('intranetTypeUsertoken')
   
        axios({
            method: 'post',
            url: `${urlbackend}user/create.php`,
            data: JSON.stringify(userdata),
            config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
        })
        .then(function (response) {
            //handle success
         
            SweetAlert.fire(
                'Utilisateur ajoutée',
                'Utilisateur a été ajoutée avec succés',
                'success'
              )
            
        })
        .catch(function (response) {
            //handle error
            SweetAlert.fire(
                'Erreur',
                "Erreur Lors  de l'ajout ",
                'success'
              )
           
        });
    
          
            
        return ({
            type: ADD_NEW_USER,
            payload: {userdata}
        })
  
       
     
    
   

  
   
}
export const createAdresseEmail = (data) => {
    let iduser = localStorage.getItem("@intranetidUser");
    let typeuser = localStorage.getItem("@intranetTypeUser");
    if( typeuser === 'admin')
    db.collection('Admin').doc(iduser).update({proEmails : data});
    else {
        db.collection('Users').doc(iduser).update({proEmails : data})
    }
    return ({
        type: UPDATE_USER_EMAIL,
        payload: {iduser,data}
    })

}

export const EditUsers = (userdata , id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}user/update.php`,
        data: JSON.stringify(userdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
     
        SweetAlert.fire(
            'Utilisateur modifié',
            'Utilisateur a été modifié avec succés',
            'success'
          )
        
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            "Erreur Lors  de la modification ",
            'success'
          )
       
    });

                    return ({
                        type: UPDATE_MY_USER,
                        payload: {userdata, id}
                    })
          
               
             
                //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
       
            
 
   
  
   
}

export const updateListemail = (role ,state) => {
    let id =localStorage.getItem("@intranetidUser")
    if(role === "admin"){
        db.collection('Admin').doc(id).update({proEmails : state})
    }
  else {
    db.collection('Users').doc(id).update({proEmails : state})
  }
  localStorage.setItem("@intranetProEmailsUser" , JSON.stringify(state))

}

export const updateuser = (id,updatedata,image_url) => {
  
    return ({
        type: UPDATE_USER,
        payload: {id,updatedata,image_url}
    })

}
export const manageStateUser = (id,state) => {
    const token = localStorage.getItem('intranetTypeUsertoken')

    axios({
        method: 'post',
        url: `${urlbackend}user/manageuser.php`,
        data: JSON.stringify({"user_id" : id, "user_state": state}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
     console.log(response)
        SweetAlert.fire(
            'Utilisateur modifié',
            'Utilisateur a été ajoutée avec succés',
            'success'
          )
        
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            "Erreur Lors  de l'ajout ",
            'success'
          )
       
    });

    return ({
        type: UPDATE_STATE_USER,
        payload: {id,state}
    })

}

export const updateMyuser = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_USER,
        payload: {id,updateMydata,image_url}
    })

}

export const removeuser = (removeid) => {
    return ({
        type: REMOVE_USER,
         removeid
    })

}

export const removemyuser = (userid) => {
    return ({
        type: REMOVE_FROM_MY_USER,
        userid
    })
}