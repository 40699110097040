import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchInventairesDipensesApi } from "../../api";
import { GET_INVENTAIREDIPENSE_LIST,WATCH_INVENTAIREDIPENSE_LIST } from "../actionTypes";

function* fetchInventairesDipensesAsyn() {
    const Data = yield call(fetchInventairesDipensesApi);
    yield put({type : GET_INVENTAIREDIPENSE_LIST,inventairedipenses : Data});
}

export function* watchInventairesDipensesList() {
    yield takeLatest(WATCH_INVENTAIREDIPENSE_LIST,fetchInventairesDipensesAsyn)
}

