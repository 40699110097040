import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button, Nav, NavItem, NavLink } from 'reactstrap'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import {Follower,Following,TotalPost, AddUser,MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, BirthDateUser, UserFormation, UserCompetences, UserExperience, Userfonctions, InfoUser, IdentifiantUser, All, CreateNewProject} from '../../constant'
import { NewUser } from '../../redux/user/action';
import moment from 'moment';
import { useFilePicker } from 'use-file-picker';
import SweetAlert from 'sweetalert2'
import { WATCH_USER_LIST } from '../../redux/actionTypes';
import { CheckCircle, Info, PlusCircle, PlusSquare, Send, Target } from 'react-feather';
import { useHistory } from 'react-router';
import DatePicker from "react-datepicker";
import defaultuser from '../../assets/images/user/user.png';
import Geocode from "react-geocode"
import GoogleMapReact from 'google-map-react'
import JotFormReact from 'jotform-react'
import { Link } from 'react-router-dom';
const DeveloperListe = (props) => {
  const [zoom, setZoom] = useState(11)
  const [center, setCenter] = useState({
    lat: 59.95,
    lng: 30.33
  })
  const [data,setData] = useState([])
  const [firstName,setfirstName] = useState("")
  const [lastName,setlastName] = useState("")
  const [email,setemail] = useState("")
  const [phoneNumber,setphoneNumber] = useState("")
  const [Mobilephone,setobilephone] = useState("")
  const [birthday, setBirthday] = useState(new Date())
  const [activeTab,setActiveTab] = useState("developer")
  const [role,setRole] = useState("manager")
  const [userImage,setuserImage] = useState("")
  const [adresse,setadresse] = useState("")
  const [region,setRegion] = useState("")
  const [password,setpassword] = useState("")
  const [tokenPush,settokenPush] = useState("")
  const [pays,setPays] = useState("")
  const [ville,setVille] = useState("")
  const [zipcode,setZipcode] = useState("")
  const [competences,setcompetence] = useState("")
  const [formations,setFormation] = useState("")
  const [experiences,setExperience] = useState("")
  const [fonctions,setFonction] = useState("")
  const [userState,setuserState] = useState(true)

  const [addurl, setAddurl] = useState(defaultuser)
 const dispatch = useDispatch()
  const [proEmails,setproEmails] = useState([])
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
  
  });
  const CordinateAdresse = (centervar) => { 
      
    Geocode.fromLatLng(centervar.lat, centervar.lng).then(
        (response) => {
          const address = response.results[0].formatted_address
          console.log(address)
          setadresse(address)
        },
        (error) => {
          console.error(error)
        }
      )
  }
  const history = useHistory()
  console.log(filesContent)
  const [cards,setCards] = useState([])
  const users = useSelector(content => content.Userapp.users);
  const  getLocation = async () => {
    await navigator.geolocation.getCurrentPosition(
      position => {
        setCenter({ 
          lat: position.coords.latitude, 
          lng: position.coords.longitude
        })
      CordinateAdresse({ 
        lat: position.coords.latitude, 
        lng: position.coords.longitude
      })
    }, 
      err => console.log(err)
    )
   
  }
  const HandleAddUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setAddurl(reader.result)
    }
  }

  useEffect(() => {
    // Geocode.setApiKey("AIzaSyCNdpXcuLDb26QUSLCkqW2kBs7aEO9u1dM")
  
    // // set response language. Defaults to english.
    // Geocode.setLanguage("en")
    
    // // set response region. Its optional.
    // // A Geocoding request with region=es (Spain) will return the Spanish city.
    // Geocode.setRegion("es")
    // getLocation()
    dispatch({type: WATCH_USER_LIST})
  },[dispatch])
  const [stateAddUser,setstateAddUser] = useState(false)
  const handleAddUser = (event) => {
    event.preventDefault();
    let user ={}
    user.lastName = lastName
    user.birthday = moment(birthday).format('DD-MM-yyyy')
    user.firstName = firstName
    user.region = region
    user.role = role
    user.email = email
    user.tokenPush = tokenPush
    user.password = password
    user.userState = userState
    user.phoneNumber = phoneNumber
    user.Mobilephone = Mobilephone
    user.adresse = adresse
    user.pays = pays
    user.ville = ville
    user.zipcode = zipcode
    user.formations = formations
    user.experiences = experiences
    user.competences = competences
    user.fonctions = fonctions
    user.firstConnexion = true
    user.contratVerified = false

    user.CreationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
    user.UpdatedDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
    user.proEmails = [email]
    user.userImage = addurl
    SweetAlert.showLoading()
   dispatch(NewUser(user))
  }


  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  }
  return (
    <Fragment>
      <Breadcrumb parent="Développement" title="Gestion Développeurs" />
      <Container fluid={true}>
        <div className="edit-profile">
        {/* <JotFormReact
        formURL="https://form.jotform.com/213215223164544"
      /> */}

             <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  {/* <Nav tabs className="border-tab">
                    <NavItem><NavLink className={activeTab == "1" ? "active" : ''} onClick={() => setActiveTab("1")}><Target />{All}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab == "manager" ? "active" : ''} onClick={() => setActiveTab("manager")}>{"Enseigne"}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab == "submanager" ? "active" : ''} onClick={() => setActiveTab("submanager")}>{"Franchisé ou Restaurant"}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab == "developer" ? "active" : ''} onClick={() => setActiveTab("developer")}>{"Développeurs"}</NavLink></NavItem>
                    
                  </Nav> */}
                </Col>
                <Col sm="6">
            
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0">
                    <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/app/users/add-developer-projet/1`}> <PlusCircle />{CreateNewProject}</Link>
             
                    <div className="btn btn-primary" onClick={() => setstateAddUser(!stateAddUser)}> {"Ajouter développeur"}</div>
              
                    </FormGroup>

                      </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Row>
            {stateAddUser === true ? <>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{IdentifiantUser}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <div className="col-auto">
                      <div className="contact-profile">
                                    <img className="rounded-circle img-100" src={addurl} alt="" />
                                    <div className="icon-wrapper">
                                      <i className="icofont icofont-pencil-alt-5">
                                        <input className="upload" type="file" onChange={(e) => HandleAddUrl(e)} />
                                      </i>
                                    </div>
                                  </div>
                      </div>
                      <Col>
                        <h3 className="mb-1">{firstName} {lastName}</h3>
                       
                      </Col>
                    </Row>
                  
                    <FormGroup>
                      <Label className="form-label">{EmailAddress}</Label>
                      <Input className="form-control" value={email} onChange={(e) => setemail(e.target.value)} placeholder="email@domain.com" />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label">{Password}</Label>
                      <Input className="form-control" value={password} onChange={(e) => setpassword(e.target.value)} type="password" defaultValue="password" />
                    </FormGroup>
                  
                   
                  </Form>
                

                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <form className="card"  onSubmit={handleAddUser}>
                <CardHeader>
                  <h4 className="card-title mb-0">{InfoUser}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                  <Col sm="6" md="4">
                    <FormGroup>
                        <Label className="form-label">Type utilisateur</Label>
                        <Input  type="select" name="select" onChange={(e) => setRole(e.target.value)} className="form-control btn-square">
                        <option value="manager" key={0}>Choisir type utilisateur </option>
                     
                            <option value="developer" key={3}>Développeur</option>
                          
                        </Input>
                      </FormGroup>
                    </Col>
                    </Row>
                  <Row>
                  
                    {/* <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        <Input email required className="form-control" value={email} onChange={(e) => setemail(e.target.value)}  type="email" placeholder="Email" />
                      </FormGroup>
                    </Col> */}
              
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{FirstName}</Label>
                        <Input required className="form-control" value={firstName} onChange={(e) => setfirstName(e.target.value)} type="text" placeholder="Nom" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{LastName}</Label>
                        <Input required className="form-control" value={lastName} onChange={(e) => setlastName(e.target.value)} type="text" placeholder="Prénom" />
                      </FormGroup>
                    </Col>
                 
                        <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{BirthDateUser}</label>
                       
                            <DatePicker  className="form-control digits" onChange={e => setBirthday(e)} showPopperArrow={false} selected={birthday}  dateFormat="P" />
                          </div>
                        </FormGroup>
                        </Col>
                    
                    <Col md="12">
                      <FormGroup>
                        <Label className="form-label">{Address}</Label>
                        <Input required className="form-control" value={adresse} onChange={(e) => setadresse(e.target.value)}  type="text" placeholder="Rue" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Pays"}</Label>
                        <Input required className="form-control" value={pays} onChange={(e) => setPays(e.target.value)}  type="text"  placeholder="Pays" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Ville"}</Label>
                        <Input required className="form-control" value={ville} onChange={(e) => setVille(e.target.value)}  type="text"  placeholder="Ville" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{PostalCode}</Label>
                        <Input required className="form-control" value={zipcode} onChange={(e) => setZipcode(e.target.value)}  type="text"  placeholder="Code Postale" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Région"}</Label>
                        <Input required className="form-control" value={region} onChange={(e) => setRegion(e.target.value)}  type="text"  placeholder="Région" />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <Label className="form-label">Numéro de Téléphone</Label>
                        <Input required className="form-control" value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value)} type="tel" placeholder=" Numero télephone" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" lg="3">
                      <FormGroup>
                        <Label className="form-label">Téléphone Portable</Label>
                        <Input required className="form-control" value={Mobilephone} onChange={(e) => setobilephone(e.target.value)} type="tel" placeholder=" Numéro télephone" />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserFormation}</Label>
                            <Input className="form-control" type="textarea" value={formations} onChange={(e) => setFormation(e.target.value)} rows="3"  name="franchiseBio" placeholder={UserFormation} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserCompetences}</Label>
                            <Input className="form-control" type="textarea" value={competences} onChange={(e) => setcompetence(e.target.value)} rows="3" name="franchiseBio" placeholder={UserCompetences} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserExperience}</Label>
                            <Input className="form-control" type="textarea" value={experiences} onChange={(e) => setExperience(e.target.value)} rows="3" name="franchiseBio" placeholder={UserExperience} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{Userfonctions}</Label>
                            <Input className="form-control" value={fonctions} onChange={(e) => setFonction(e.target.value)} type="textarea" rows="3" name="franchiseBio" placeholder={Userfonctions} />

                          </FormGroup>
                        </Col>
                      </Row>
                   
                </CardBody>
                <CardFooter className="text-right">
                 <input className="btn btn-primary" value={AddUser} type="submit"/>
                </CardFooter>
               
              </form>
             
            </Col>
            </>
            : <div></div> }
            <Col md="12">
          
       
            <Row>
          {users?.filter(res => activeTab === '1' ? true : res.role === activeTab).map((cardItem, i) => 
          <Col onClick={() => {

            
        history.push(`${process.env.PUBLIC_URL}/app/users/developer-projet/${cardItem.id}`,{user : cardItem})
        }} md="6" lg="6" xl="4" className="box-col-6" key={i}>
            <Card className="custom-card">
              <CardHeader>
     
                {/* <Media body className="img-fluid" src={cardItem.userImage} alt="" /> */}
              </CardHeader>
              <div className="card-profile">
                <Media body style={{width :150}} className="rounded-circle" src={cardItem.userImage} alt="" />
              </div>
             
              <div className="text-center profile-details">
                <h4>{cardItem.firstName} {cardItem.lastName}</h4>
                <h6>{cardItem.role === "submanager" ? "Responsable franchise" : cardItem.role === "manager" ? "Responsable marque" : ""}</h6>
                <h6>{cardItem.franchiseName !== undefined && cardItem.role === "submanager" ? cardItem.franchiseName : cardItem.marqueName !== undefined && cardItem.role === "manager" ? cardItem.marqueName :  "Attribuer à une franchise"}</h6>

                <h6>{cardItem.email}</h6>
              </div>
              {/* <CardFooter className="row">
                <Col sm="4 col-4">
                  <h6>{Follower}</h6>
                  <h3 className="counter">{cardItem.follower}</h3>
                </Col>
                <Col sm="4 col-4">
                  <h6>{Following}</h6>
                  <h3><span className="counter">{cardItem.following}</span>{"K"}</h3>
                </Col>
                <Col sm="4 col-4">
                  <h6>{TotalPost}</h6>
                  <h3><span className="counter">{cardItem.totalPost}</span>{"M"}</h3>
                </Col>
              </CardFooter> */}
            </Card>
          </Col>
          )}
        </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default DeveloperListe;