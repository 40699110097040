import React, { Fragment } from 'react';

import {ToastContainer} from 'react-toastify'
import {withRouter} from 'react-router-dom'

import Taptop from '../../../layout/tap-top';
import Loader from '../../../layout/loader';

const AppfileFragment = ({children}) => {
  return (
    <Fragment>
    
            {children}
         
    </Fragment>
  );
}
export default withRouter(AppfileFragment);