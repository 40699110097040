import {call,put, takeLatest} from "redux-saga/effects";
import {   fetchNotificationsProviderApi } from "../../api";
import { WATCH_NOTIFICATIONS_PROVIDER_LIST, GET_NOTIFICATIONS_LIST } from "../../redux/actionTypes";

function* fetchNotificationPAsyn() {
    const Data = yield call(fetchNotificationsProviderApi);
    yield put({type : GET_NOTIFICATIONS_LIST,notifications : Data});
}

export function* watchNotificationList() {
    yield takeLatest(WATCH_NOTIFICATIONS_PROVIDER_LIST,fetchNotificationPAsyn)
   
}
