import { ORDERS } from 'react-feather';
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_ORDERS,
        ADD_NEW_ORDERS,
        REMOVE_ORDERS,
        REMOVE_FROM_MY_ORDERS,
        UPDATE_ORDERS,
        UPDATE_MY_ORDERS,
        UPDATE_STATE_ORDERS,
        UPDATE_NOTE_ORDERS,
       } from '../actionTypes';

import SweetAlert from 'sweetalert2'
import axios from 'axios';
export const myorder = (myorderdata) => {
    return ({
        type: ADD_TO_MY_ORDERS,
        payload: {myorderdata}
    })
}
export const NewORDERS = (orderdata ) => {

  
        db.collection("ORDERS").add(orderdata)
        SweetAlert.fire(
            'Evénement ajoutée',
            'Evénement a été ajoutée avec succés',
            'success'
          )
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))

  
    return ({
        type: ADD_NEW_ORDERS,
        payload: {orderdata}
    })
}
export const updateorder = (id,startdate,enddate) => {
    db.collection("ORDERS").doc(id).update({start : startdate+"" , end : enddate+""})
    return ({
        type: UPDATE_ORDERS,
        payload: {id,startdate,enddate}
    })

}
export const manageStateNew = (id,state) => {
    db.collection('ORDERS').doc(id).update({ORDERState : state})
    return ({
        type: UPDATE_STATE_ORDERS,
        payload: {id,state}
    })

}
export const updateMyorder = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_ORDERS,
        payload: {id,updateMydata,image_url}
    })

}
export const manageOrder = (id,statetext, state, notif) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}order/manage_order.php`,
        data: JSON.stringify({statustext : statetext, status : state,commande_id :id, note : ""}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        db.collection("Notifications").add(notif)
       
        //handle success
        SweetAlert.fire(
            'Commande ' + statetext,
            'Commande a été '+statetext+' avec succés',
            'success'
          ).then(res => {
            window.location.reload()
          })
   // eventcategory.event_category_id = response.data.data.event_category_id
  

    })
    .catch(function (response) {
        //handle error
      
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la modification veuillez réessayer',
            'success'
          )
       
      
    });
   
    return ({
        type: UPDATE_ORDERS,
        payload: {id,statetext, state}
    })

}
export const NotifierUserOrder = (id,note,notif) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}order/manage_order.php`,
        data: JSON.stringify({statustext : "Modifier Commande", status : "3",commande_id :id, note : note}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        notif.collectionID = id
        db.collection("Notifications").add(notif)
       
        //handle success
        SweetAlert.fire(
            'Note envoyée',
            'Votre Note  a été envoyée avec succés',
            'success'
          )
   // eventcategory.event_category_id = response.data.data.event_category_id
  

    })
    .catch(function (response) {
        //handle error
      
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de l acceptation veuillez réessayer',
            'success'
          )
       
      
    });

    return ({
        type: UPDATE_NOTE_ORDERS,
        payload: {id,note, statustext : "Modifier Commande", status : "3"}
    })

}
export const removeorder = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}order/delete.php`,
        data: JSON.stringify({commande_id :removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
      
       
        //handle success
        SweetAlert.fire(
            'Commande supprimée',
            'Votre Commande  a été supprimée avec succés',
            'success'
          )
   // eventcategory.event_category_id = response.data.data.event_category_id
  

    })
    .catch(function (response) {
        //handle error
      
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de l acceptation veuillez réessayer',
            'success'
          )
       
      
    });

    return ({
        type: REMOVE_ORDERS,
         removeid
    })

}
export const removemyorder = (orderid) => {
    return ({
        type: REMOVE_FROM_MY_ORDERS,
        orderid
    })
}