import React, { Fragment, useState,useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button } from 'reactstrap'
import { Home, Folder, Clock, Star, AlertCircle, Trash2, Database, Grid, Upload, PlusSquare } from 'react-feather';
import { toast } from 'react-toastify'
import errorImg from '../../../assets/images/search-not-found.png';
import {BasicModal,Simple,StaticExample,NewMessage,SendMessage,ModalTitle,Close,SaveChanges,VerticallyCentered,TooltipsAndPopovers,UsingTheGrid,SizesModal,LargeModal,SmallModal,ScrollingLongContent,VaryingModalContent,Hometxt,All,Recent,Starred,Recovery,Deleteds,PricingPlan,TrialVersion,FREE,Selected,Premium,ContactUs,AllFiles,RecentlyOpenedFiles,Folders,EndlessAdmin,Files,Storage,AddNew, SharedWithMe, Favourites, AddNewFolder, NewFolder, AddFolder, MYDRIVE, Sharedwithme, DriveEnseigne} from '../../../constant'
import axios from "axios";
import { db } from '../../../data/config';
import { useDispatch, useSelector } from 'react-redux';
import { WATCH_FOLDER_LIST } from '../../../redux/actionTypes';
import moment from 'moment';
import { newfolder } from '../../../redux/foldermanager/action';
import {BrowserRouter,Switch,Route,Redirect, Link} from 'react-router-dom'
import AppfileFragment from './app'
import Test from './test';
import MyDrive from './myDrive';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
const Filemanager = (props) => {
  const dispatch = useDispatch();
  const myfile = useSelector(content => content.Folderapp.folders);
  const [selectedFile, setSelectedFile] = useState(null)   // Initially, no file is selected  
  const [searchTerm, setSearchTerm] = useState("");
  const [folder_name, setfolder_name] = useState("");
  const [myfilee, setMyFile] = useState([])
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone);
  // useEffect(() => {
  //   dispatch({type: WATCH_BOOKMARK_LIST})
  //    axios.get(`${process.env.PUBLIC_URL}/api/files.json`).then((response) => {
  //     setMyFile(response.data);
  //    })
  // },[])
  useEffect(() => {
    dispatch({type: WATCH_FOLDER_LIST})
  },[dispatch])
const handleAddFolder = () => {
let folder = {}
folder.folder_name = folder_name
folder.proprietaireId = "admin"
folder.proprietaireType = "admin"
folder.userListe = []
folder.creationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
folder.folderState = true
folder.downloadState = true
folder.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
folder.downloadUrl = ""
folder.icon = "fa fa-file-image-o txt-primary"
folder.filesNumber = 0
VaryingContentonetoggle()
dispatch(newfolder(folder))

}
  const handleChange = event => {
    event.preventDefault();
    setSearchTerm(event.target.value)
  };

  // eslint-disable-next-line
  const filelist = myfile.filter((data) => {
    if(searchTerm == null)
        return data
    else if(data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())){
        return data
    }
    }).map((data,i)=>{
      return(
        <li className="file-box" key={i}>
          <div className="file-top"><i className={data.icon} ></i><i className="fa fa-ellipsis-v f-14 ellips"></i></div>
          <div className="file-bottom">
            <h6>{data.folder_name} </h6>
            <p className="mb-1">{"12"}</p>
              {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
          </div>
        </li>
      )
    })
    const folderlist = myfile.filter((data) => {
      if(searchTerm == null)
          return data
      else if(data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())){
          return data
      }
      }).map((data,i)=>{
        return(
          <li className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
          <div className="media"><i className="fa fa-folder f-36 txt-warning"></i>
            <div className="media-body ml-3">
              <h6 className="mb-0">{data.folder_name}</h6>
              
                {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
            </div>
          </div>
        </li>
        )
      })
  const getFile = () => {
    document.getElementById("upfile").click();
  }

  const onFileChange = event => {
    // Update the state 
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = () => {
      let myfiles = [...myfile];

      if (selectedFile !== null) {
        myfiles.push({
          id: myfile.length + 1,
          name: selectedFile.name,
          size: `${selectedFile.size}`,
          modify: `${selectedFile.lastModifiedDate}`,
          icon:"fa fa-file-text-o txt-info"
        })
        setMyFile(myfiles)
        toast.success("Fichier Téléchargé avec succés")
      }else {
        toast.error("Veuilez choisir  au moins un fichier")
      }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="Gestion Fichiers" />
      <Container fluid={true}>
        <Row>
        <Col xl="3" className="box-col-6 pr-0 file-spacing">
            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  {localStorage.getItem("@intranetTypeUser") === 'admin' || localStorage.getItem("@intranetTypeUser") === 'manager' ?
                  <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/drive-enseigne`} className="btn btn-light"><Folder />{DriveEnseigne}</Link>
                    </li> : ""}
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager`} className="btn btn-light active"><Folder />{All}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/mydrive`} className="btn btn-light"><Clock />{MYDRIVE}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/favoris-drive`} className="btn btn-light"><Star />{Favourites}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/shares-with-me`}  className="btn btn-light"><AlertCircle />{Sharedwithme}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/deleted-drive`}  className="btn btn-light"><Trash2 />{Deleteds}</Link>
                    </li>
                  </ul>
                  <hr />
                  {/* <ul>
                    <li>
                      <div className="btn btn-outline-primary"><Database />{Storage}</div>
                      <div className="m-t-15">
                        <div className="progress sm-progress-bar mb-1">
                          <div className="progress-bar bg-primary" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p>{"25 GB of 100 GB used"}</p>
                      </div>
                    </li>
                  </ul> */}
             
                  {/*   <hr /> <ul>
                    <li>
                      <div className="btn btn-outline-primary"><Grid />{PricingPlan}</div>
                    </li>
                    <li>
                      <div className="pricing-plan">
                        <h6>{TrialVersion} </h6>
                        <h5>{FREE}</h5>
                        <p>{"100 GB Space"}</p>
                        <div className="btn btn-outline-primary btn-xs">{Selected}</div>
                        <img className="bg-img" src={require("../../../assets/images/dashboard/folder.png")} alt="" />
                      </div>
                    </li>
                    <li>
                      <div className="pricing-plan">
                        <h6>{Premium}</h6>
                        <h5>{"$5/month"}</h5>
                        <p> {"200 GB Space"}</p>
                        <div className="btn btn-outline-primary btn-xs">{ContactUs}</div>
                        <img className="bg-img" src={require("../../../assets/images/dashboard/folder1.png")} alt="" />
                      </div>
                    </li>
                  </ul> */}
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col xl="9" md="12" className="box-col-12">
      
      <MyDrive/>

          </Col>
        </Row>
      </Container>

    </Fragment>
  );
}

export default Filemanager;