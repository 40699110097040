import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import axios from 'axios'
import {Follower,Following,TotalPost, AddUser,MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, BirthDateUser, UserFormation, UserCompetences, UserExperience, Userfonctions, InfoUser, IdentifiantUser, NewUser, EditUser} from '../../constant'
import defaultuser from '../../assets/images/user/user.png';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFilePicker } from 'use-file-picker';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { fetchUserByIdApi, fetchUsersByIdApi } from '../../api';
import { EditUsers } from '../../redux/user/action';
const AccountEdit = (props) => {
  //const {idUser} = useParams();

  const location = useLocation();
  const history = useHistory();
  const [url, setUrl] = useState();
  const [stateUser, setStateUser] = useState();
  const [user, setUser] = useState({});
  const [data,setData] = useState([])
  const [firstName,setfirstName] = useState("")
  const [lastName,setlastName] = useState("")
  const [email,setemail] = useState("")
  const [phoneNumber,setphoneNumber] = useState("")
  const [Mobilephone,setobilephone] = useState("")
  const [birthday, setBirthday] = useState("")

  const [role,setRole] = useState("manager")
  const [userImage,setuserImage] = useState(defaultuser)
  const [adresse,setadresse] = useState("")
  const [region,setRegion] = useState("")
  const [password,setpassword] = useState("")
  const [tokenPush,settokenPush] = useState("")
  const [pays,setPays] = useState("")
  const [ville,setVille] = useState("")
  const [zipcode,setZipcode] = useState("")
  const [competences,setcompetence] = useState("")
  const [formations,setFormation] = useState("")
  const [experiences,setExperience] = useState("")
  const [fonctions,setFonction] = useState("")
  const [userState,setuserState] = useState(true)
  const [langue,setLangue] = useState("")
  const [civility,setCivility] = useState("")
 const dispatch = useDispatch()
  const [proEmails,setproEmails] = useState([])
  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
  
  });
  useEffect(() => {
      fetchUsersByIdApi(localStorage.getItem("@intranetidUser")).then(res => {
      setUser(res)
      setfirstName(res.first_name)
      setCivility(res.civility)
      setlastName(res.last_name)
      setemail(res.email)
      setLangue(res.langue)
      setBirthday(res.birthday !== undefined ? new Date(res.birthday) : new Date())
      setadresse(res.adresse)
      setPays(res.pays)
      setVille(res.ville)
      setZipcode(res.zip_code)
      setRegion(res.region)
      setphoneNumber(res.phone_number)
      setobilephone(res.mobilephone)
      setFormation(res.formations)
      setcompetence(res.competences)
      setExperience(res.experiences)
      setFonction(res.fonctions)
      setuserImage(res.user_image)
   
    })
  
 }, []);
 const handleAddUser = (event) => {
  event.preventDefault();
 
let userdate= user
userdate.last_name = lastName
userdate.first_name = firstName
userdate.region = region

userdate.user_state = userState
userdate.phone_number = phoneNumber
userdate.mobilephone = Mobilephone
userdate.adresse = adresse
userdate.pays = pays
userdate.langue = langue
userdate.ville = ville
userdate.zip_code = zipcode
userdate.formations = formations
userdate.experiences = experiences
userdate.competences = competences
userdate.fonctions = fonctions

userdate.user_image = userImage
userdate.image = userImage
userdate.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
  dispatch(EditUsers(user, localStorage.getItem("@intranetidUser")))
  history.push(`${process.env.PUBLIC_URL}/app/users/account`)

}
const HandleAddUrl = (event) => {
  if (event.target.files.length === 0)
    return;
  //Image upload validation
  var mimeType = event.target.files[0].type;

  if (mimeType.match(/image\/*/) == null) {
    return;
  }
  // Image upload
  var reader = new FileReader();
  reader.readAsDataURL(event.target.files[0]);
  reader.onload = (_event) => {
    setuserImage(reader.result)
  }
}

  return (
    <Fragment>
      <Breadcrumb parent="Utilisateurs" title="Modification Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
        <Row>
         
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{IdentifiantUser}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <div className="col-auto">
                      <div className="contact-profile">
                                    <img className="rounded-circle img-100" src={userImage} alt="" />
                                    <div className="icon-wrapper">
                                      <i className="icofont icofont-pencil-alt-5">
                                        <input className="upload" type="file" onChange={(e) => HandleAddUrl(e)} />
                                      </i>
                                    </div>
                                  </div>                      </div>
                      <Col>
                        <h3 className="mb-1">{firstName} {lastName}</h3>
                       
                      </Col>
                    </Row>
                  
                    <FormGroup>
                      <Label className="form-label">{EmailAddress}</Label>
                      <Input disabled className="form-control" value={email} onChange={(e) => setemail(e.target.value)} placeholder="email@domain.com" />
                    </FormGroup>
                 
                  
                   
                  </Form>
                

                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <form className="card"  onSubmit={handleAddUser}>
                <CardHeader>
                  <h4 className="card-title mb-0">{InfoUser}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
               
                    <Col>
                    <FormGroup>
                        <Label className="form-label">Langue</Label>
                        <Input  type="select" name="select" onChange={(e) => setLangue(e.target.value)} className="form-control btn-square">
                        <option value="" key={0}>Choisir langue </option>
                            <option selected={langue === "francais"} value="francais" key={1}>Francais</option>
                            <option selected={langue === "anglais"} value="anglais" key={2}>Anglais</option>
                            <option selected={langue === "arabic"} value="arabic" key={3}>Arabic</option>
                         
                          
                        </Input>
                      </FormGroup>
                    </Col>
                    </Row>
                  <Row>
                  
                    {/* <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        <Input email required className="form-control" value={email} onChange={(e) => setemail(e.target.value)}  type="email" placeholder="Email" />
                      </FormGroup>
                    </Col> */}
              
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{FirstName}</Label>
                        <Input required className="form-control" value={firstName} onChange={(e) => setfirstName(e.target.value)} type="text" placeholder="Nom" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{LastName}</Label>
                        <Input required className="form-control" value={lastName} onChange={(e) => setlastName(e.target.value)} type="text" placeholder="Prénom" />
                      </FormGroup>
                    </Col>
                 
                      
                    
                    <Col md="12">
                      <FormGroup>
                        <Label className="form-label">{Address}</Label>
                        <Input required className="form-control" value={adresse} onChange={(e) => setadresse(e.target.value)}  type="text" placeholder="Rue" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Pays"}</Label>
                        <Input required className="form-control" value={pays} onChange={(e) => setPays(e.target.value)}  type="text"  placeholder="Pays" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Ville"}</Label>
                        <Input required className="form-control" value={ville} onChange={(e) => setVille(e.target.value)}  type="text"  placeholder="Ville" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Zip Code"}</Label>
                        <Input required className="form-control" value={zipcode} onChange={(e) => setZipcode(e.target.value)}  type="text"  placeholder="Zip Code" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Region"}</Label>
                        <Input  required className="form-control" value={region} onChange={(e) => setRegion(e.target.value)}  type="text"  placeholder="Region" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" lg="6">
                      <FormGroup>
                        <Label className="form-label">Numéro de téléphone</Label>
                        <Input required className="form-control" value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value.replace(/\D/g, ''))} type="tel" placeholder=" Numero télephone" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" lg="6">
                      <FormGroup>
                        <Label className="form-label">Téléphone portable</Label>
                        <Input required className="form-control" value={Mobilephone} onChange={(e) => setobilephone(e.target.value.replace(/\D/g, ''))} type="tel" placeholder=" Numero télephone" />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserFormation}</Label>
                            <Input className="form-control" type="textarea" value={formations} onChange={(e) => setFormation(e.target.value)} rows="3"  name="franchiseBio" placeholder={UserFormation} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserCompetences}</Label>
                            <Input className="form-control" type="textarea" value={competences} onChange={(e) => setcompetence(e.target.value)} rows="3" name="franchiseBio" placeholder={UserCompetences} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserExperience}</Label>
                            <Input className="form-control" type="textarea" value={experiences} onChange={(e) => setExperience(e.target.value)} rows="3" name="franchiseBio" placeholder={UserExperience} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{Userfonctions}</Label>
                            <Input className="form-control" value={fonctions} onChange={(e) => setFonction(e.target.value)} type="textarea" rows="3" name="franchiseBio" placeholder={Userfonctions} />

                          </FormGroup>
                        </Col>
                      </Row>
                   
                </CardBody>
                <CardFooter className="text-right">
                 <input className="btn btn-primary" value={EditUser} type="submit"/>
                </CardFooter>
               
              </form>
             
            </Col>
       
      
            <Col md="12">
          
       
           
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default AccountEdit;