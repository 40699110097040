import React, { Fragment, useRef, useState } from "react";
import Breadcrumb from '../../../layout/breadcrumb'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Media,
  Form,
} from "reactstrap";
import './styel.css'
import { getCartTotal, getCartTotalTVA } from "../../../services/ecommerce.service";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Cuba,HelloCuba,InvoiceHash,IssuedMay,PaymentDue,JohanDeo,JohanDeoMailId,ProjectDescription,ProjectDescriptionDetails,ProductName,Sub_total,ThankBusiness,ThankBusinessDesc,Print,Quantity,Price,Cancel } from '../../../constant';
import Pdf from '../../../Pdf'
import { urlbackend } from "../../../data/config";
class Imprime extends React.Component {
  render() {
    const { file } = this.props
    console.log(file)
const isLoaded = ()	=> {

  let pdfFrame = window.frames['printf1'];
	pdfFrame.focus();
	pdfFrame.print();	
    }
    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col lg="12" sm="12">
              <Card>
                <CardBody>
                  <div className="invoice">
                 {/* <Pdf /> */}
                 {file.filetype?.includes("image") === true ?
                 <Media
                 style={{width : '100%'}}
                 src={`${urlbackend}${file.file_url}`}
                 alt=""
               /> : file.filetype?.includes("officedocument") === true || file.filetype?.includes("msword") === true || file.filetype?.includes("ms-powerpoint") === true || file.filetype?.includes("ms-excel") === true?

               <iframe  id="printf" name="printf" src={`https://view.officeapps.live.com/op/embed.aspx?src=${urlbackend}${file.file_url}&embedded=true`}   style={{width : '100%', height:'700px'}} ></iframe>
:
<iframe  class="toPrint" id="printf" name="printf" src={`${urlbackend}${file.file_url}#toolbar=0`}   style={{width : '100%', height:'700px'}} ></iframe>

    }

                 

                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      
       
      </Fragment>
    );
  }
}
const PrintComponentFile = () => {
  const location = useLocation();
 const history = useHistory()
  const [file, setFile] = useState(location?.state?.file);


  const componentRef = useRef();
  return (
    <Fragment>
      <Breadcrumb parent="drive" title="Imprime" />
      <Imprime file={file}   ref={componentRef} />
      <Col sm="12" className="text-right my-3">
      <Link onClick={() => {
        let newWin = window.frames["printf"];
 
        newWin.document.write('<body onload="window.print()">dddd</body>');
        newWin.document.close();
      }}>
        <Button
         onClick={() =>   history.push(`${process.env.PUBLIC_URL}/app/file-manager/mydrive/`)} 
          color="secondary">{Cancel}</Button> 
      </Link>
        {/* <ReactToPrint
          trigger={() => (
            <Button color="primary" className="mr-2">
              {Print}
            </Button>
          )}
          content={() => componentRef.current}
        />
        */}
      </Col>
    </Fragment>
  );
};

export default PrintComponentFile;
