

import axios from 'axios';
import moment from 'moment';
import SweetAlert from 'sweetalert2'
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_INVENTAIREDIPENSE,
        ADD_NEW_INVENTAIREDIPENSE,
        REMOVE_INVENTAIREDIPENSE,
        REMOVE_FROM_MY_INVENTAIREDIPENSE,
        UPDATE_INVENTAIREDIPENSE,
        UPDATE_MY_INVENTAIREDIPENSE,
        UPDATE_STATE_INVENTAIREDIPENSE,
        GET_INVENTAIREDIPENSE_LIST,
        GET_INVENTAIREDIPENSE_BY_ID,
     
       } from '../actionTypes';


export const myinventairedipense = (myinventairedipensedata) => {
    return ({
        type: ADD_TO_MY_INVENTAIREDIPENSE,
        payload: {myinventairedipensedata}
    })
}
export const addInventaireFromDetail = async (data,month) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
  
    let franchiseid = localStorage.getItem("@intranet_franchiseid")
    let now =new Date()
    let lastdate = moment(now).format("YYYY-MM-DD")
    
        localStorage.setItem("@intranetIdFranchiseDateInventaire",lastdate)
  
        axios({
            method: 'post',
            url: `${urlbackend}inventaire/add_inventaire.php`,
            data: JSON.stringify(data),
            config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
        })
        .then(function (response) {
            //handle success
            axios({
                method: 'post',
                url: `${urlbackend}subfranchise/update_last_date.php`,
                data: JSON.stringify({franchise_id : franchiseid, lastDateInventaire :lastdate}),
                config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
            })
            SweetAlert.fire(
                'Inventaire ajoutée',
                'Inventaire a été ajoutée avec succés',
                'success'
              )
          
        })
        .catch(function (response) {
            //handle error
            console.log("response")
            console.log(response)
        });
          return true
    
    }
export const addInventaire = async (data) => {

const token = localStorage.getItem('intranetTypeUsertoken')
  
let franchiseid = localStorage.getItem("@intranetIdFranchiseUser")
let now =new Date()
let lastdate = moment(now).format("YYYY-MM-DD")

    localStorage.setItem("@intranetIdFranchiseDateInventaire",lastdate)
    console.log(data)
    axios({
        method: 'post',
        url: `${urlbackend}inventaire/create.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        axios({
            method: 'post',
            url: `${urlbackend}subfranchise/update_last_date.php`,
            data: JSON.stringify({franchise_id : franchiseid, lastDateInventaire :lastdate}),
            config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
        })
        SweetAlert.fire(
            'Inventaire ajoutée',
            'Inventaire a été ajoutée avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
      return true

}
export const getInventairesDipensesById = (idFranch) => {

    db.collection("Subfranchises").doc(idFranch).collection("InventairesDipenses").where("year","<=" , "2021").where("monthDayNumber", ">","monthDayRepli").get().then(res => {


        return ({
            type: GET_INVENTAIREDIPENSE_BY_ID,
            payload: {...res.data(),
            id : res.id}
        })

    })
    //     const uploadTask =  firestorage.ref(`/inventairedipenses/${avatar.name}`).putString(avatar.content, 'data_url');
       
    //     uploadTask.on('state_changed', 
    //    (snapShot) => {
    //      //takes a snap shot of the process as it is happening
    //      console.log(snapShot)
    //    }, (err) => {
    //      //catches the errors
    //      console.log(err)
    //    }, () => {
    //      // gets the functions from storage refences the image storage in firebase by the children
    //      // gets the download url then sets the image from firebase as the value for the imgUrl key:
    //      firestorage.ref('/inventairedipenses').child(avatar.name).getDownloadURL()
    //       .then(fireBaseUrl => {
    
            
    //         inventairedipensedata.inventairedipenseImage = fireBaseUrl
            
           
            //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
         // })
       //.})
      
       
    }
export const NewInventairesDipenses = (inventairedipensedata) => {

//     const uploadTask =  firestorage.ref(`/inventairedipenses/${avatar.name}`).putString(avatar.content, 'data_url');
   
//     uploadTask.on('state_changed', 
//    (snapShot) => {
//      //takes a snap shot of the process as it is happening
//      console.log(snapShot)
//    }, (err) => {
//      //catches the errors
//      console.log(err)
//    }, () => {
//      // gets the functions from storage refences the image storage in firebase by the children
//      // gets the download url then sets the image from firebase as the value for the imgUrl key:
//      firestorage.ref('/inventairedipenses').child(avatar.name).getDownloadURL()
//       .then(fireBaseUrl => {

        
//         inventairedipensedata.inventairedipenseImage = fireBaseUrl
        db.collection("InventairesDipenses").add(inventairedipensedata).then(res =>  {
            db.collection("Users").doc(inventairedipensedata.managerID).update({marqueName : inventairedipensedata.InventairesDipensesName,marqueId : res.id})
        })
       
        SweetAlert.fire(
            'Inventaire ajouté',
            'Inventaire a été ajouté avec succés',
            'success'
          )
        return ({
            type: ADD_NEW_INVENTAIREDIPENSE,
            payload: {inventairedipensedata}
        })
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
     // })
   //.})
  
   
}


export const Editinventairedipense = (id,updatedata,idOldManager) => {
    
    db.collection("InventairesDipenses").doc(id).update(updatedata).then(res => {
        if(idOldManager === updatedata.managerID)
        db.collection("Users").doc(updatedata.managerID).update({marqueName : updatedata.InventairesDipensesName,marqueId : id})
        else {
            db.collection("Users").doc(updatedata.managerID).update({marqueName : updatedata.InventairesDipensesName,marqueId : id})
            db.collection("Users").doc(idOldManager).update({marqueName : "null",marqueId : "null"})
        }
 
  
    }).catch(e => console.log(e))
    return ({
        type: UPDATE_INVENTAIREDIPENSE,
        payload: {id,updatedata}
    })

}


export const updateinventairedipense = (id,updatedata,image_url) => {
    return ({
        type: UPDATE_INVENTAIREDIPENSE,
        payload: {id,updatedata,image_url}
    })

}
export const manageStateInventairesDipenses = (id,state) => {
    db.collection('InventairesDipenses').doc(id).update({inventairedipenseState : state})
    return ({
        type: UPDATE_STATE_INVENTAIREDIPENSE,
        payload: {id,state}
    })

}

export const updateMyinventairedipense = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_INVENTAIREDIPENSE,
        payload: {id,updateMydata,image_url}
    })

}

export const removeinventairedipense = (removeid) => {
    return ({
        type: REMOVE_INVENTAIREDIPENSE,
         removeid
    })

}

export const removemyinventairedipense = (inventairedipenseid) => {
    return ({
        type: REMOVE_FROM_MY_INVENTAIREDIPENSE,
        inventairedipenseid
    })
}