import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,CardBody, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button, Media, InputGroupAddon, InputGroup, InputGroupText, Nav, NavLink, NavItem} from 'reactstrap'
import DataTable from 'react-data-table-component';

import {  Cancel, Add, ActualiteAjouterAnnuaires, Files} from '../../../constant';
import { DropDownSplit } from './categorylist';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader';

import { WATCH_CATEGORY, WATCH_CATEGORY_BY_PROVIDER, WATCH_PRODUCT_LIST } from '../../../redux/actionTypes';
import { useFilePicker } from 'use-file-picker';
import { firestorage, urlbackend } from '../../../data/config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { NewProduits, removefile, removeProducts, removeSize, UpdateProduits } from '../../../redux/ecommerce/product/action';
import Compressor from 'compressorjs';
import SweetAlert from 'sweetalert2'
import { NewCategory, updateCategorieGroupe } from '../../../redux/category/action';
import { Edit, Plus } from 'react-feather';
import { fetchSubCategoryByCategoryApi } from '../../../api';
import { NewSubCategory } from '../../../redux/subcategory/action';
const Productlist = () => {
  const history = useHistory()
    const [addauditModal, setaddauditModal] = useState(false)
    const [selectedCategory, setselectedCategory] = useState("")
    const [selectedSubCategory, setselectedSubCategory] = useState("")
    const [groupeName, setgroupeName] = useState("")
    const [statemodal, setstatemodal] = useState(false);
    const statemodatoggle = () => setstatemodal(!statemodal)
    const [selectedCategoryAdd, setselectedCategoryAdd] = useState({})
    const [selectedProduct, setselectedProduct] = useState({})
    const addAuditToggle = () => { setaddauditModal(!addauditModal) } 
    const [compressedFile, setCompressedFile] = useState(null);
    const [addProductModal, setaddProductModal] = useState(false)
    const addProductToggle = () => { setaddProductModal(!addProductModal) } 
    const [stateSize, setstateSize] = useState(false)
    const [stateimpression, setstateimpression ] = useState(false)
    const [updateProductModal, setupdateProductModal] = useState(false)
    const updateProductToggle = () => { setupdateProductModal(!updateProductModal) } 
    const [sizeListe, setsizeListe] = useState([])
    const [sizeancien, setsizeancien] = useState([])
    const [random, setrandom] = useState(0)
    const [searchKeyword, setSearchKeyword] = useState('');
    const [dangerTab, setdangerTab] = useState('0');
    const [addSubModal, setSubModal] = useState(false)
    const addSubToggle = () => { setSubModal(!addSubModal) }
    const [updateSubModal, setupdateSubModal] = useState(false)
    
  const updateAuditToggle = () => { setupdateSubModal(!updateSubModal) }
  const [categoryName, setcategoryName] = useState('');
    const handleAddCategorySubmit = () => {

    }
    const category = useSelector(state => state.Categoryapp.category)
console.log(category)
    const products = useSelector(content => content.data.products)
  console.log(products)
    useEffect(() => {
      dispatch({type: WATCH_CATEGORY_BY_PROVIDER})
      dispatch({type: WATCH_PRODUCT_LIST})
      
    }, [])
    const [subCategory, setsubCategory] = useState('');
    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm();
    const [startDate, setstartDate] = useState(new Date())
    const [endDate, setendDate] = useState(new Date())
    const [myfilee, setMyFile] = useState([])
    const [myfileelast, setMyFilelast] = useState([])
    const [subCategorys, setSubCategorys] = useState([])
    const [filesContent, setfilesContent] = useState("")
    const handleStartDate = date => {
      setstartDate(date);
    };
    const handleAddSubmit = (data) => {
      data.preventDefault()
  let data2 = {}
  data2.title = groupeName
  data2.provider_cat_id = localStorage.getItem("@intranetProviderCategoryId")
    dispatch(NewCategory(data2))
    
    addAuditToggle()
      
      
    
    };
    const handleCompressedUpload = (e) => {

      const image = e.target.files[0];
      setfilesContent(e.target.files)
      if (e.target.files.length === 0)
        return;
      //Image upload validation
      var mimeType = e.target.files[0].type;
    
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      // Image upload
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
        setCompressedFile(reader.result)
      }
    };
    const handleEndDate = date => {
      setendDate(date);
    };
   
    const getUploadParams = ({ meta, file }) => {

       
      const formData =  new FormData();
    formData.append('image', file);
    let url = "https://gocloud.groupe-fbh.com/intranet/api/medianews/uploadmedia.php";

        
        return { url: 'https://gocloud.groupe-fbh.com/intranet/api/mediaproduct/uploadmedia.php', body : formData  } }
      const handleChangeStatus = ({xhr, meta, file }, status) => {
  
        if(status === "done" ){
            if (xhr.readyState == 4) {
              let myfils = myfilee;
              const result = JSON.parse(xhr.response);
             myfils.push({
              media_contenu : result.url,
          
              })
              setMyFile(myfils)
              toast.success("Fichier Téléchargé avec succés")
            }
          }
 else if (status === "removed"){
    let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
    setMyFile(myfileeCopy)
  }
      }
    const AddProduct = data => {
      console.log(data)
      if (data !== '' && compressedFile.length > 10) {

        data.product_size = stateSize === true ? 1 : 0;
        data.imprime_state = stateimpression === true ? 1 : 0;
        
        data.product_image = compressedFile;

        data.product_created_date = moment(new Date()).format('yyyy-MM-DD HH:mm');
        
        data.product_updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm');
        
        data.category_id = selectedCategoryAdd;
        
      
     data.piecesJoint = myfilee
     data.provider_id = localStorage.getItem("@intranetidUser")
     if(stateSize === true){
     data.product_price=sizeListe[0].size_price
     }
     data.sizeListe = sizeListe
 
     addProductToggle()
     dispatch(NewProduits(data,compressedFile))
   
        //props.history.push(`${process.env.PUBLIC_URL}/app/project/project-list`)
      } else {
        errors.showMessages();
      }
    };
    const UpdateProduct = data => {
      if (data !== '' && compressedFile.length>10) {
     
      data.piecesJoint = myfilee
      if(stateSize === true){
      data.product_price=sizeancien.length > 0 ? sizeancien[0].size_price : sizeListe.length > 0 ? sizeListe[0].size_price : selectedProduct.product_price
      }
      data.sizeListe = sizeListe
      data.category_id = selectedCategoryAdd;
      data.product_size = stateSize === true ? "1" : "0";
      data.imprime_state = stateimpression === true ? "1" : "0";
      data.product_updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm');
      data.product_id = selectedProduct.product_id
      data.product_image= compressedFile
      
      console.log(data)
      dispatch(UpdateProduits(data,selectedProduct.product_id))
      updateProductToggle()
        //props.history.push(`${process.env.PUBLIC_URL}/app/project/project-list`)
      }
     else {
        errors.showMessages();
      }
    };
    const removesCategory = () => {

    }
    const UpdateCategorieGroupe = (e) => {
      e.preventDefault()
      let data={title : categoryName, category_id : selectedCategory.category_id}
     dispatch(updateCategorieGroupe(data))
     updateAuditToggle() 
    }
    // const [openFileSelector, { filesContent, loading }] = useFilePicker({
    //   readAs: 'DataURL',
    //   accept: 'image/*',
    //   multiple: true,
    //   limitFilesConfig: { max: 1 },

    //   // minFileSize: 0.1, // in megabytes
    //   maxFileSize: 50,
    
    // });
    const RemoveSubCategorie = () => {

    }
    const removefileproduct = (id) => {
      SweetAlert.fire({
     
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
         dispatch(removefile(id, selectedProduct.product_id));
      
      setMyFilelast(myfileelast.filter(res => res.media_product_id !== id))
        }
        else {
        
        }
      })
    }
    const filelist2 = myfileelast?.map((data,i)=>{
      return(
        <li    onDoubleClick={() => {
           
       
           
            }} className="file-box" key={i}>
    
    
          <div  className="file-top"> <img   width="200" height="100" src={`${urlbackend}${data.media_contenu}`} /> </div>
          <div className="file-bottom">
            <h6><i className="fa fa-trash" onClick={() => removefileproduct(data.media_product_id)}></i></h6>
    
          </div>
       
               
        </li>
      )
    })
    const RemoveProduct  = (Id ) => {
      SweetAlert.fire({
     
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
         dispatch(removeProducts(Id));
         
        }
        else {
        
        }
      })
    }
    const productColumns = [
      {
          name:"Image",
          selector: 'product_image',
          sortable: true,
          cell : (row) => <img src={row.product_image} style={{ width: 50, height: 50 }} alt="" />,
          center:true,
        },
        {
          name:"Nom produit",
          selector: 'product_name',
          sortable: true,
          center:true,
        },
        {
          name:"Description produit",
          selector: 'product_desc',
          sortable: true,
          center:true,
        },
        {
          name:"Prix",
          selector: 'product_price',
          sortable: true,
          center:true,
        },
        {
          name:"Stock",
          selector: 'product_quantity',
          sortable: true,
          center:true,
        },
        // },
        // {
        //   name:"Start_date",
        //   selector: 'start_date',
        //   sortable: true,
        //   center:true,
        // },
        {
          name:"Action",
          selector: 'action',
          cell : (row) => <div>
             <span><i className="fa fa-eye" onClick={() => {goToProductDetails(row.product_id)}} style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
            <span><i onClick={() => RemoveProduct(row.product_id)} className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></span>
          <span><i className="fa fa-pencil" onClick={() => {
            setstateimpression(row.imprime_state == "1" ? true : false);
            setstateSize(row.product_size == "1" ? true : false);
             setsizeancien(row.sizeListe)
             setMyFilelast(row.imageListe);
             setCompressedFile(row.product_image); 
             setselectedCategoryAdd(row.category_id)
             setselectedProduct(row);
             updateProductToggle()
             }} style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
         
          </div>,
          sortable: true,
          center:true,
        },
  ];
  const goToProductDetails = (id) => {
    history.push(`${process.env.PUBLIC_URL}/app/ecommerce/product-page-provider/${id}`)
  }
  const AddSize = () => {

    let listesondafecopy = sizeListe
    listesondafecopy.push({size_name : "", size_price : "" })
  
  setsizeListe(listesondafecopy)
  setrandom(Math.random())
  }
  const AddtextSize = (i, text, url) => {
    console.log()
   let auditListCopy = sizeListe
   if(url === "name")
   auditListCopy[i].size_name = text
   if(url === "url")
   auditListCopy[i].size_price = text
   setsizeListe(auditListCopy)
  
   console.log(auditListCopy)
   setrandom(Math.random())
  }
  const DeleteSizefromDatabase = (value) => {
    SweetAlert.fire({
     
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
      
       dispatch(removeSize(value,selectedProduct.product_id));
     
       setsizeancien(sizeancien.filter(res => res.size_id !== value))
      
        
      }
      else {
      
      }
    })
    
    }  
  const DeleteSize = (value) => {
    
  if(value === ""){
    let listesondafecopy = sizeListe.filter((res, i) => i !== sizeListe.length - 1)
    console.log(listesondafecopy)
    setsizeListe(listesondafecopy)
    setrandom(Math.random())
  }
  else {
  let listesondafecopy = sizeListe.filter(res => res.size_name !== value)
  setsizeListe(listesondafecopy)
  setrandom(Math.random())
  }
    
  }  
  const getSubCategory =(id) => {
    fetchSubCategoryByCategoryApi(id).then(res => {
      console.log(res)
      setSubCategorys(res) 
    })
  }
  const handleAddCategory = (data) => {
    if (data !== '') {
    
    data.provider_cat_id = localStorage.getItem('@intranetProviderCategoryId')

dispatch(NewCategory(data))
addAuditToggle()
    } else {
      errors.showMessages();
    }
  };
const handleAddsSubCategorySubmit = (e) => {
  e.preventDefault()
    if (subCategory !== '') {
let data = {}
data.title = subCategory
data.cat_id =selectedCategory.category_id
   //  let listAudtcopy = franchise.auditListe;
    // listAudtcopy.push({auditName : data.title ,auditurl : data.url})
    // franchise.auditListe = listAudtcopy;
   // data.subCategoryList =[]
dispatch(NewSubCategory(data))
setsubCategory("")
setTimeout(() => {
  fetchSubCategoryByCategoryApi(selectedCategory.category_id).then(res => {
    console.log(res)
    setSubCategorys(res) 
  })
}, 2000);
addSubToggle()
    } else {
      //errors.showMessages();
    }
  };
  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
   // dispatch({ type: SEARCH_BY, search: keyword })
  }

    return (
          <Fragment>
          <Breadcrumb parent="Produits" title="Liste produits"/>
          <Container fluid={true}>
          <div style={{ overflow: 'auto',height: 90,
  whiteSpace: 'nowrap'}}>
    <Button color="primary" onClick={()=>  addAuditToggle()} className="btn-custom" style={{width : 70, height : 75}} ><Plus size={15}></Plus></Button>
             
    {category.map((email , index) => (
    <button onDoubleClick={() => removesCategory(email.category_id)}    className="btn-custom" style={{height : 75,backgroundColor : selectedCategory.category_id == email.category_id ? "black" : "", color : selectedCategory.category_id == email.category_id ? "white" : "black"}}  >
      <p onClick={()=> { 
getSubCategory(email.category_id)
setselectedCategory(email) 
}}> {email.title}</p>
      <Edit size={15} onClick={()=> { 
    setcategoryName(email.title)
    setselectedCategory(email)
  updateAuditToggle()
    }}/>  </button>
                              
    ))}                     
</div>
          <Row>
          <Col sm="12" xl="6 xl-100">
            <Card>
              <CardBody>
            <Nav className="border-tab" tabs >
              {subCategorys.length > 0
                //.filter(res => res.cat_id === selectedCategory)
              }  <NavLink  onClick={() =>{ 
                addSubToggle()
            }}><Plus/>
          </NavLink>
                  {subCategorys.map((cat,index) =>    <NavItem>
                    <NavLink  className={dangerTab === index ? 'active' : ""} onClick={() =>{ 
                        setselectedSubCategory(cat.sub_category_id)
                        setdangerTab(index)}}>{cat.title} 
                  </NavLink>
                  </NavItem>)}
               
                
                </Nav>
                <Form>
                  <FormGroup className="m-0">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Rechercher"
                      defaultValue={searchKeyword}
                      onChange={(e) => handleSearchKeyword(e.target.value)}
                    />
                  
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
            <Row>
           
                <Col sm="12">
                    <Card>
                      
                        <CardBody>
                        <div  className="media-body text-right">
                <div className="d-inline-flex">
                                                     
                  <button  onClick={() => addProductToggle()}  className="btn btn-primary" > {"Ajouter Produit"}</button>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </div>
              
      </div>
                            <div className="table-responsive product-table">
                               <DataTable
                         //.filter(res => res.category_id === selectedCategory.category_id)
                                    noHeader
                                    columns={productColumns}
                                    data={products.filter(res => selectedCategory !== "" && searchKeyword.length > 0 ? selectedCategory.category_id == res.category_id && res.product_name.includes(searchKeyword) : selectedCategory !== "" ? selectedCategory.category_id == res.category_id :  searchKeyword.length > 0 ? res.product_name.includes(searchKeyword) : true)}
                                /> 
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={addauditModal} toggle={addAuditToggle} size="lg">
                        <ModalHeader toggle={addAuditToggle}>{"Ajouter Categorie"}</ModalHeader>
                        <ModalBody>
                          <Form onSubmit={(e) => handleAddSubmit(e)} className="form-bookmark needs-validation" >
                            <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{"Nom Categorie"}</Label>
                                <Input value={groupeName} onChange={(e) => setgroupeName(e.target.value)} className="form-control" name="title" type="text"  autoComplete="off" />
                              </FormGroup>
                            </div>
                            <div className="media-body text-right">
                            <div className="d-inline-flex">
                            <Button color="secondary" className="mr-1" onClick={addAuditToggle}>{Cancel}</Button>
                            <Button color="primary" >{"Ajouter"}</Button>
                            </div>
                            </div>
                          </Form>
                        </ModalBody>
            </Modal>
            <Modal isOpen={addProductModal} toggle={addProductToggle} size="lg">
                        <ModalHeader toggle={addProductToggle}>{"Ajouter Produit"}</ModalHeader>
                        <ModalBody>
                        <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(AddProduct)}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Nom Produit"}</Label>
                            <Input className="form-control" type="text"  name="product_name" placeholder="Nom produit *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.product_name && 'Nom Produit  est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Description produit"}</Label>
                            <Input className="form-control" type="textarea" name="product_desc" placeholder="Description produit" innerRef={register({})}/>
                            <span style={{ color: "red" }}>{errors.product_desc && 'Description Produit  est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                     
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Categorie"}</Label>
                            <Input type="select" onChange={(e) => {  
          getSubCategory(e.target.value)
                              setselectedCategoryAdd(e.target.value)}}  name="category_id" className="form-control digits" innerRef={register({ required: true })}>
                          <option  >{"choisir Categorie"}</option>
                            {category.map((cat, index) =>    <option  value={cat.category_id}>{cat.title}</option>)}

                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Sous Categorie"}</Label>
                            <Input type="select" name="sub_category_id" placeholder="Select Sous categorie" className="form-control digits" innerRef={register({ required: true })}>
                            <option  >{"choisir sous Categorie"}</option>
                            {subCategorys.map((subcat, index) =>    <option  value={subcat.sub_category_id}>{subcat.title}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                       
                      </Row>
                      <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio2" type="checkbox" name="radio2" checked={stateimpression} value={stateimpression} onChange={(e) => setstateimpression(e.target.checked)} />
                        <Label for="radio2">{"Status Impresssion"}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                      <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" checked={stateSize} value={stateSize} onChange={(e) => setstateSize(e.target.checked)} />
                        <Label for="radio1">{"Taille"}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
              {stateSize === true ?    <> <Row>
                      <Col>
                      <FormGroup >
                      <div>
                      <Button color="primary" onClick={() => AddSize()}  >{"Ajouter taille de produit"}</Button>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        { 
                        sizeListe.map((taille,index) => 
                          <Row key={index}>
                      <Col>
                        <FormGroup>
                        
                            <InputGroup> 
                              <Input onChange={(e) => AddtextSize(index, e.target.value, "name")} className="form-control" type="text" value={taille.size_name} placeholder={"Nom taille"} aria-label="Recipient's username"/>
                              <Input onChange={(e) => AddtextSize(index, e.target.value, "url")} className="form-control" type="text" value={taille.size_price} placeholder={"prix taille"} aria-label="Recipient's username"/>
                              <InputGroupAddon addonType="append"><InputGroupText onClick={() => DeleteSize(taille.sizeName)}> {"-"}</InputGroupText></InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                          </Col>
                        </Row>
                        )
                        }</>
                       : null }
                  
                      <Row>
                      {stateSize === false ?  
                      <Col sm="4">
                       <FormGroup>
                         <Label>{"Prix Produit"}</Label>
                         <Input className="form-control" type="number" name="product_price" min="1" defaultValue="0" placeholder="Entrer prix produit" innerRef={register({ required: true, min : 1 })}/>
                       </FormGroup>
                     </Col> : ""}
                      <Col sm="4">
                       <FormGroup>
                         <Label>{"Unité"}</Label>
                         <Input className="form-control" type="number" name="product_unity" min="1" defaultValue="10" placeholder="Entrer unité prix" innerRef={register({ required: true, min : 1 })}/>
                       </FormGroup>
                     </Col>
                     <Col sm="4">
                       <FormGroup>
                         <Label>{"Quantite"}</Label>
                         <Input className="form-control" type="number" name="product_quantity" min="1" defaultValue="10" placeholder="Entrer quantite produit" innerRef={register({ required: true, min : 1 })}/>
                       </FormGroup>
                     </Col>
                   </Row>
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label>{"Disponibilité"}</Label>
                            <select  name="productAvailable" className="form-control digits">
                              <option>{"Disponible"}</option>
                              <option>{"Non disponible"}</option>
                           
                            </select>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{"Marque"}</Label>
                            <Input  type="text" className="form-control" name="product_marque" rows="3" innerRef={register({ })}/>
                            <span style={{ color: "red" }}>{errors.product_marque && 'Marque est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                       <FormGroup>
                         <Label>{"TVA"}</Label>
                         <Input className="form-control" type="number" name="product_tva" defaultValue="10" min="1" placeholder="Entrer TVA produit" innerRef={register({ required: true,min : 1 })}/>
                       </FormGroup>
                     </Col>
                      </Row>
                     <Row>
                       <Col>
                       <div className="col-auto">
                       <Label>{"Image Principal de Produit"}</Label>
                       <Input className="form-control" type="file" onChange={(e) => handleCompressedUpload(e)} name="image" placeholder="Entrer image produit" />
{/*<Media  style={{width : 200, height: 200}} className="img-200 " alt="" src={compressedFile !== null ? compressedFile: require("../../../assets/images/user/7.jpg")} />*/}
                      </div>
                      </Col>
                     </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Autre Image(s) Produit"}</Label>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    maxFiles={1}
                                    multiple={false}
                                    canCancel={false}
                                    inputContent="Uploader un fichier"
                                    styles={{
                                        dropzone: { width: '100%', height: 50 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                          </FormGroup>
                        </Col>
                      </Row>
                    
                      <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right" >
                          <Link onClick={addProductToggle}>
                              <Button color="secondary" className="mr-3">{Cancel}</Button>
                              </Link>
                              <Button color="primary" >{Add}</Button>
                           
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
                        </ModalBody>
            </Modal>


            <Modal isOpen={updateProductModal} toggle={updateProductToggle} size="lg">
                        <ModalHeader toggle={updateProductToggle}>{"Modifier Produit"}</ModalHeader>
                        <ModalBody>
                        <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(UpdateProduct)}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Nom Produit"}</Label>
                            <Input className="form-control" defaultValue={selectedProduct.product_name} type="text"  name="product_name" placeholder="Nom produit *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.product_name && 'Nom Produit  est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Description produit"}</Label>
                            <Input className="form-control" defaultValue={selectedProduct.product_desc}  type="textarea" name="product_desc" placeholder="Description produit" innerRef={register({ required: true })}/>
                            <span style={{ color: "red" }}>{errors.product_desc && 'Description Produit  est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                     
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Categorie"}</Label>
                            <Input type="select" onChange={(e) => {  
          getSubCategory(e.target.value)
                              setselectedCategoryAdd(e.target.value)}}   name="category_id" className="form-control digits" innerRef={register({ required: true })}>
                            {category.map((cat, index) =>    <option selected={cat.categry_id === selectedProduct.category_id}  value={cat.category_id}>{cat.title}</option>)}
                           
                            
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Sous Categorie"}</Label>
                            <Input type="select" name="sub_category_id" defaultValue={selectedProduct.sub_category_id}  placeholder="Select Sous categorie" className="form-control digits" innerRef={register({ required: true })}>
                            <option  >{"choisir sous Categorie"}</option>
                            {subCategorys?.map((subcat, index) =>    <option selected={subcat.sub_category_id === selectedProduct.sub_category_id}  value={subcat.sub_category_id}>{subcat.title}</option>)}
                           
                              
                            </Input>
                          </FormGroup>
                        </Col>
                       
                      </Row>
                      <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio2" type="checkbox" name="radio2" checked={stateimpression} value={stateimpression} onChange={(e) => setstateimpression(e.target.checked)} />
                        <Label for="radio2">{"Status Impresssion"}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                      <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" checked={stateSize} value={stateSize} onChange={(e) => setstateSize(e.target.checked)} />
                        <Label for="radio1">{"Taille"}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
              {stateSize === true ?    <> <Row>
                      <Col>
                      <FormGroup >
                      <div>
                      <Button color="primary" onClick={() => AddSize()}  >{"Ajouter taille de produit"}</Button>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        { 
                        sizeListe.map((taille,index) => 
                          <Row key={index}>
                      <Col>
                        <FormGroup>
                        
                            <InputGroup> 
                              <Input onChange={(e) => AddtextSize(index, e.target.value, "name")} className="form-control" type="text" value={taille.size_name} placeholder={"Nom taille"} aria-label="Recipient's username"/>
                              <Input onChange={(e) => AddtextSize(index, e.target.value, "url")} className="form-control" type="text" value={taille.size_price} placeholder={"prix taille"} aria-label="Recipient's username"/>
                              <InputGroupAddon addonType="append"><InputGroupText onClick={() => DeleteSize(taille.sizeName)}> {"-"}</InputGroupText></InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                          </Col>
                        </Row>
                        )
                        
                        }
                           { 
                        sizeancien.map((taille,index) => 
                          <Row key={index}>
                      <Col>
                        <FormGroup>
                        
                            <InputGroup> 
                              <Input onChange={(e) => AddtextSize(index, e.target.value, "name")} className="form-control" type="text" value={taille.size_name} placeholder={"Nom taille"} aria-label="Recipient's username"/>
                              <Input onChange={(e) => AddtextSize(index, e.target.value, "url")} className="form-control" type="text" value={taille.size_price} placeholder={"prix taille"} aria-label="Recipient's username"/>
                              <InputGroupAddon addonType="append"><InputGroupText onClick={() => DeleteSizefromDatabase(taille.size_id)}> {"-"}</InputGroupText></InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                          </Col>
                        </Row>
                        )
                        
                        }</>
                       : null }
                    
                      <Row>
                      {stateSize === false ? 
                      <Col sm="4">
                       <FormGroup>
                         <Label>{"prix Produit"}</Label>
                         <Input className="form-control" defaultValue={selectedProduct.product_price}  type="number" name="product_price" placeholder="Entrer prix produit" innerRef={register({ required: true })}/>
                       </FormGroup>
                     </Col> : null}
                      <Col sm="4">
                       <FormGroup>
                         <Label>{"Unité"}</Label>
                         <Input className="form-control" defaultValue={selectedProduct.product_unity}  type="number" name="product_unity"  placeholder="Entrer unité prix" innerRef={register({ required: true })}/>
                       </FormGroup>
                     </Col>
                     <Col sm="4">
                       <FormGroup>
                         <Label>{"Quantite"}</Label>
                         <Input className="form-control" defaultValue={selectedProduct.product_qantity}  type="number" name="product_quantity" defaultValue="10" placeholder="Entrer quantite produit" innerRef={register({ required: true })}/>
                       </FormGroup>
                     </Col>
                   </Row>
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label>{"Disponibilité"}</Label>
                            <select  name="productAvailable" className="form-control digits">
                              <option>{"Disponible"}</option>
                              <option>{"Non disponible"}</option>
                           
                            </select>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{"Marque"}</Label>
                            <Input  type="text" defaultValue={selectedProduct.product_marque}  className="form-control" name="product_marque" rows="3" innerRef={register({})}/>
                            <span style={{ color: "red" }}>{errors.productMarque && 'Marque est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                     <Row>
                       <Col>
                       <div className="col-auto">
                       <Label>{"Image Principal de  Produit"}</Label>
                       <Input className="form-control" type="file" onChange={(e) => handleCompressedUpload(e)} name="image"  placeholder="Entrer image produit" />
               
                         {/* <Media style={{width : 200, height: 200}} className="img-200 " alt="" src={filesContent[0] !== undefined ? filesContent[0] : selectedProduct.productImage} />  */}
                      </div></Col>
                      <Col>
                       <div className="col-auto">
                       <Media src={compressedFile} alt="" className="img-fluid" />
                         {/* <Media style={{width : 200, height: 200}} className="img-200 " alt="" src={filesContent[0] !== undefined ? filesContent[0] : selectedProduct.productImage} />  */}
                      </div></Col>
                     </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Autre Image(s) Produit"}</Label>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    maxFiles={1}
                                    multiple={false}
                                    canCancel={false}
                                    inputContent="Uploader un fichier"
                                    styles={{
                                        dropzone: { width: '100%', height: 50 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                    <Col>
                    <h6 className="mt-4">{Files}</h6>
                      {filelist2?.length > 0 ?
              <ul className="files">
                  { filelist2 }
       
              </ul> : ""}
                    </Col>
                  </Row>
                      <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right">
                          <Link onClick={updateProductToggle}>
                              <Button color="secondary" className="mr-3" on >{Cancel}</Button>
                              </Link>
                              <Button color="primary" >{"Modifier"}</Button>
                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
                        </ModalBody>
            </Modal>
            <Modal isOpen={updateSubModal} toggle={updateAuditToggle} size="lg">
                        <ModalHeader toggle={updateAuditToggle}>{"Modifier Categorie"}</ModalHeader>
                        <ModalBody>
                          <Form className="form-bookmark needs-validation" onSubmit={UpdateCategorieGroupe}>
                            <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{"Nom Categorie"}</Label>
                                <Input value={categoryName} onChange={(e) => setcategoryName(e.target.value)} className="form-control" name="title" type="text"  autoComplete="off"  />
                                <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                            </FormGroup>

                            </div>
                            <div className="media-body text-right">
                            <div className="d-inline-flex">
                            <Button color="secondary" className="mr-1" onClick={updateAuditToggle}>{Cancel}</Button>
                            <Button color="primary" >{"Modifier"}</Button>
                            </div>
                            </div>
                          </Form>
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={addSubModal} toggle={addSubToggle} size="lg">
                        <ModalHeader toggle={addSubToggle}>{"Ajouter Sous Categorie"}</ModalHeader>
                        <ModalBody>
                          <Form className="form-bookmark needs-validation" onSubmit={handleAddsSubCategorySubmit}>
                            <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{"Nom Sous Categorie"}</Label>
                                <Input className="form-control" value={subCategory} onChange={(e)=> setsubCategory(e.target.value)} name="title" type="text"  autoComplete="off" />
                              
                              </FormGroup>
                             
                             
                             
                             
                            </div>
                            <div className="media-body text-right">
                            <div className="d-inline-flex">
                            <Button color="secondary" className="mr-1" onClick={addSubToggle}>{Cancel}</Button>
                            <Button color="primary"   >{"Ajouter"}</Button>
                            </div>
                            </div>
                          </Form>
                        </ModalBody>
                      </Modal>
           </Container>
           </Fragment>
    )

  }

export default Productlist


