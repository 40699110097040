import React, { Fragment, useState, useEffect,useRef } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {firebase_app} from '../../../data/config';
import { Container, Row, Col, Card, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, Form, Button } from 'reactstrap'
import defaultuser from '../../../assets/images/user/user.png';
import search from '../../../assets/images/search-not-found.png';
import { createCategory, createCategoryFromxsel, createUser, deletedCategory, deletedUser, editCategory, editUser } from '../../../services/contact.service'
import { useForm } from 'react-hook-form';
import SweetAlert from 'sweetalert2'
import ReactToPrint from "react-to-print";
import PrintPreview from './printpreview'
import {MarkJecno,MARKJENCOEMAIL,MARKJENCO,NewContacts,AddContacts,Views,Name,Age,Mobile,MobileNo,EmailAddress,Gender,Male,Female,FollowUp,Orgenization,Favourites,History,ContactHistory,AddCategory,Ideas,SarahLoren,AndewJon,JasonBorne,Business,Holidays, Important,Personal,Edit,Delete,Print,General,Save,Cancel,NoDataFound,PrintViews} from '../../../constant'
import { Users } from 'react-feather';
import { preventDefault } from '@fullcalendar/react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import user from '../../../assets/images/user/user.png';
const Newcontact = (props) => {

  const [addurl, setAddurl] = useState(defaultuser)
  const [editurl, setEditurl] = useState()
  const [activeTab, setActiveTab] = useState('1');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email2, setemail2] = useState('');
  const [cp, setCP] = useState('');
  const [adresse, setAdresse] = useState('');
  const [localite, setLocalite] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobile1, setmMobile1] = useState('');
  const [categoryName, setcategoryName] = useState('');

  const [categoryID, setcategoryID] = useState('');
  const [dynamictab, setDynamicTab] = useState('0');
  const [orgactiveTab, setorgActiveTab] = useState('1');
  const { register, handleSubmit, errors } = useForm(); // initialise the hook
  const [users, setUsers] = useState([])
  const [editdata, setEditData] = useState({});
  const [editing, setEditing] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const [selectedUserFromCat, setSelectedUserFromCat] = useState(null)
  const db = firebase_app.firestore();
  const [printmodal, setprintModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [categoryModal,setCategoryModal] = useState(false)
  const categoryToggle = () => setCategoryModal(!categoryModal);

  const [editCategoryModal,setEditCategoryModal] = useState(false)
  const editcategoryToggle = () => setEditCategoryModal(!editCategoryModal);

  const toggle = () => setModal(!modal);

  const printModalToggle = () => setprintModal(!printmodal);
  const componentRef = useRef();

  useEffect(() => {
   
    const unsubscribe = db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
    .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').onSnapshot((snapshot) => {
      const getUser = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      setSelectedUser(getUser[0])
      setUsers(getUser)
      console.log(getUser)
     
      setActiveTab(getUser[0]?.name)
    })
    return () => unsubscribe();
  }, [db]);
  
  const addCategory = data => {

    data.preventDefault()
    if (categoryName !== '') {
     createCategory(categoryName)
    categoryToggle()

    } else {
      errors.showMessages();
    }
  };
  const updateCategory = data => {

    data.preventDefault()
    if (categoryName !== '') {
     editCategory(categoryName,categoryID)
editcategoryToggle()

    } else {
      errors.showMessages();
    }
  };
  
  const AddContact = data => {
    if (data !== '') {
      createUser(data, addurl,selectedUser.id);
      setModal(false)

    } else {
      errors.showMessages();
    }
  };


  const UpdateContact = data => {
    if (data !== '') {

  
      data.email=selectedUserFromCat.email
data.name =name
data.surname =surname
data.localite =localite
data.adresse =adresse
data.mobile =mobile
data.mobile1 =mobile1
data.cp =cp
      editUser(data, editurl, selectedUser.id);
      setEditing(false)
    } else {
      errors.showMessages();
    }
  };

  const EditUSers = (usersData) => {
    setEditing(true)
    setEditData(usersData)
    setemail2(usersData.email2)
    setName(usersData.name)
    setSurname(usersData.surname)
    setCP(usersData.cp)
    setAdresse(usersData.adresse)
    setLocalite(usersData.localite)
    setMobile(usersData.mobile)
    setmMobile1(usersData.mobile1)
    setEditurl(usersData.avatar)
  }

  const HandleAddUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setAddurl(reader.result)
    }
  }

  const HandleEditUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setEditurl(reader.result)
    }
  }
  const handleUpload = (e) => {
    e.preventDefault();

    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        console.log(dataParse)
        let contactListe = []
     
          for(let i=0; i < dataParse.length;i++){
       
            if(dataParse[i].length > 0 && dataParse[i][6] !== undefined && dataParse[i][9] !== undefined && dataParse[i][6] !== "Responsable"){
              let data = {
                avatar : user,
name : dataParse[i][6] || "",
surname :  "",
mobile : dataParse[i][9] || "",
email : dataParse[i][7] || "",
mobile1 : dataParse[i][8] || "",
adresse : dataParse[i][2] || "",
cp : dataParse[i][3] || "",
email2 : dataParse[i][6] || "",
localite : dataParse[i][1] || "",
mode : dataParse[i][4] || "",
etat : dataParse[i][5] || "",
entitesiret : dataParse[i][15] || "",

              }
              contactListe.push(data)
            }
          }
          "".
          createCategoryFromxsel(f.name.replace(".xlsx", ""),contactListe).then(res =>{
        } );

    };
    reader.readAsBinaryString(f)
}
  const deleteUser = (userId) => {

    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        deletedUser(selectedUser.id,selectedUserFromCat.email);
        SweetAlert.fire(
          'Supprimé!',
          'Votre contact a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  const deleteCategory = (userId) => {

    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        deletedCategory(userId);
        SweetAlert.fire(
          'Supprimé!',
          'Votre catgorie a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  
  const history = () => {
    document.querySelector(".history").classList.add("show")
  }

  const closehistory = () => {
    document.querySelector(".history").classList.remove("show")
  }

  const ContactDetails = (user) => {
    setSelectedUserFromCat({ id: user.id, ...user})
  }
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Annuaires" title="Contact" />
      <Container fluid={true}>
        <div className="email-wrap bookmark-wrap">
        <div className='text-right'>
                <button  className="btn btn-primary" onClick={() => {exportToCSV(selectedUser.contactListe,selectedUser.name)}} >
                         
                              {"Exporter  fichier excel"}
                        </button>
                        <div className='mt-1'> 
                        <label className="btn btn-primary" for="files">Importer  fichier excel</label>
                      
                   <input type="file" name="files"  id="files" style={{display : 'none'}} onChange={(event) => {handleUpload(event)}} />
                     
                         </div>
                        
                  
                </div>
          <Row>
            <Col xl="3" className="box-col-6">
              <div className="email-left-aside">
                <Card>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark">
                      {/* <div className="media">
                        <div className="media-size-email"><img className="mr-3 rounded-circle" src={require("../../../assets/images/user/user.png")} alt="" /></div>
                        <div className="media-body">
                          <h6 className="f-w-600">{MARKJENCO}</h6>
                          <p>{MARKJENCOEMAIL}</p>
                        </div>
                      </div> */}
                      <Nav className="main-menu contact-options" role="tablist">
                        <NavItem>
                          {selectedUser !==  undefined && selectedUser !==  {} ? 
                          <button  className="badge-light-primary btn-block btn-mail" onClick={toggle}>
                          <Users className="mr-2" />
                              {NewContacts}
                        </button> : ""}
                  
                          <Modal isOpen={modal} toggle={toggle} size="lg">
                            <ModalHeader toggle={toggle}>{AddContacts}</ModalHeader>
                            <ModalBody>
                              <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(AddContact)}>
                                <div className="form-row">
                                  <div className="contact-profile">
                                    <img className="rounded-circle img-100" src={addurl} alt="" />
                                    <div className="icon-wrapper">
                                      <i className="icofont icofont-pencil-alt-5">
                                        <input className="upload" type="file" onChange={(e) => HandleAddUrl(e)} />
                                      </i>
                                    </div>
                                  </div>
                                  <FormGroup className="col-md-12">
                                    <Label>{Name}</Label>
                                    <Row>
                                      <Col sm="6">
                                        <Input className="form-control" name="name" placeholder='Nom' type="text" innerRef={register({ required: true })} />
                                        <span style={{ color: "red" }}>{errors.name && 'Nom est obligatoire'}</span>
                                      </Col>
                                      <Col sm="6">
                                        <Input className="form-control" name="surname" type="text" placeholder='Prénom' innerRef={register({ required: true })} />
                                        <span style={{ color: "red" }}>{errors.surname && 'Prénom est obligatoire'}</span>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{"Email"}</Label>
                                    <Input className="form-control" name="email" type="text" innerRef={register({ required: true, })} />
                                    <span style={{ color: "red" }}>{errors.email && 'Email est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{"Autre Email"}</Label>
                                    <Input className="form-control" name="email2" type="text" innerRef={register({ })} />
                                    <span style={{ color: "red" }}>{errors.email2 && 'Email est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{"Adresse"}</Label>
                                    <Input className="form-control" name="adresse" type="text" innerRef={register({ })} />
                                    <span style={{ color: "red" }}>{errors.adresse && 'adresse est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{"Cp"}</Label>
                                    <Input className="form-control" name="cp" type="text" innerRef={register({ })} />
                                    <span style={{ color: "red" }}>{errors.cp && 'cp est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{"Localité"}</Label>
                                    <Input className="form-control" name="localite" type="text" innerRef={register({ })} />
                                    <span style={{ color: "red" }}>{errors.localite && 'localite est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12 ">
                                    <Label>{"Numero téléphone"}</Label>
                                    <Input className="form-control" name="mobile" type="text" innerRef={register({ pattern: /\d+/, minlength: 8, maxlength: 15 })} />
                                    <span style={{ color: "red" }}>{errors.mobile && 'Maximum 9 number'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12 ">
                                    <Label>{"Numero téléphone 2"}</Label>
                                    <Input className="form-control" name="mobile1" type="text" innerRef={register({ pattern: /\d+/, minlength: 8, maxlength: 15 })} />
                                    <span style={{ color: "red" }}>{errors.mobile1 && 'Maximum 9 number'}</span>
                                  </FormGroup>
                                </div>
                                <div className='text-right'>
                                <Button color="secondary" className="mr-1" onClick={toggle}>{Cancel}</Button>
                                <Button color="primary" >{"Ajouter"}</Button>
                                </div>
                              </Form>
                            </ModalBody>
                          </Modal>
                        </NavItem>
                        <NavItem><span className="main-title">{Views}</span></NavItem>

                        <li>
                            <button className="btn btn-category" onClick={categoryToggle}><span className="title"> + {AddCategory}</span></button>
                            <Modal isOpen={categoryModal} toggle={categoryToggle}>
                            <ModalHeader toggle={categoryToggle}>{AddCategory}</ModalHeader>
                              <ModalBody>
                                    <Form className="form-bookmark" onSubmit={(e) => addCategory(e)}>
                                      <div className="form-row">
                                        <FormGroup className="mb-3 col-md-12 my-0">
                                          <Input onChange={(e) => setcategoryName(e.target.value)} value={categoryName} className="form-control" type="text" required="" placeholder="Saisir le nom de categorie" autoComplete="off"/>
                                        </FormGroup>
                                      </div>
                                  <div className='text-right'>
                                      <Button color="secondary" className="ml-1" onClick={categoryToggle}>{Cancel}</Button>
                                      <Button color="primary"  >{"Ajouter categorie"}</Button>
                                      </div>
                                    </Form>
                              </ModalBody>
                            </Modal>
                            <Modal isOpen={editCategoryModal} toggle={editcategoryToggle}>
                            <ModalHeader toggle={editcategoryToggle}>{"Modifier"}</ModalHeader>
                              <ModalBody>
                                    <Form className="form-bookmark" onSubmit={(e) => updateCategory(e)}>
                                      <div className="form-row">
                                        <FormGroup className="mb-3 col-md-12 my-0">
                                          <Input onChange={(e) => setcategoryName(e.target.value)} value={categoryName} className="form-control" type="text" required="" placeholder="Saisir le nom de categorie" autoComplete="off"/>
                                        </FormGroup>
                                      </div>
                                  <div className='text-right'>
                                      <Button color="secondary" className="mr-1" onClick={editcategoryToggle}>{Cancel}</Button>
                                      <Button color="primary"  >{"Modifier categorie"}</Button>
                                      </div>
                                    </Form>
                              </ModalBody>
                            </Modal>
                        </li>
                        {users.map((data, i) =>   <NavItem><a  className={selectedUser.name !== undefined ? selectedUser.name === data.name ? 'active' : '' : ''} onClick={() => {setSelectedUser(data); setActiveTab(data.name); data.contactListe.length > 0 ? setSelectedUserFromCat(data.contactListe[0]) :  setSelectedUserFromCat(null)}}><span className="title"> {data.name}</span> <i onClick={() => deleteCategory(data.id)} className='fa fa-trash'></i> <i onClick={() => { setcategoryName(data.name); setcategoryID(data.id);editcategoryToggle()}} className='fa fa-edit'></i></a> </NavItem>)}
                      
                      </Nav>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>

            {/* list */}
            <Col xl="9" md="12" className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent contacts-tabs">
              
                <div className="email-body radius-left">
              
                  <div className="pl-0">
                    <TabContent activeTab={activeTab}>
                      {users.map((data,i) => 
                        <TabPane tabId={data.name}>
                        <Card className="mb-0">
                          <CardHeader className="d-flex">
                       
                            <h5>{data.name}</h5><span className="f-14 pull-right mt-0">{`${data.contactListe.length} Contacts`}</span>
                          </CardHeader>
                          <CardBody className="p-0">
                            <Row className="list-persons" id="addcon">
                              <Col xl="4 xl-50" md="5">
                                <Nav className="flex-column nav-pills">
                                  {selectedUser?.contactListe?.length > 0 ?
                                  selectedUser?.contactListe?.map((user, index) => {
                                    return (
                                      <NavLink  className={dynamictab === index ? "active" : ""} onClick={() => setDynamicTab(index)} key={index} >
                                        <div className="media" onClick={() => ContactDetails(user)}>
                                          <img className="img-50 img-fluid m-r-20 rounded-circle update_img_0" src={user.avatar} alt="" />
                                          <div className="media-body">
                                            <h6>
                                              <span className="first_name_0">{user.name +" "} </span>
                                              <span className="last_name_0">{user.surname} </span>
                                            </h6>
                                            <p className="email_add_0">{user.email}</p>
                                          </div>
                                        </div>
                                      </NavLink>
                                    )
                                  })
                                  :
                                  <Col sm="12">
                                          <div>
                                              <div className="search-not-found text-center">
                                                  <div>
                                                      
                                                      <p className="mb-0">{"Aucun contact trouvé"}</p>
                                                  </div>
                                              </div>
                                          </div>
                                  </Col>
                                  }
                                </Nav>
                              </Col>
                              <Col xl="8 xl-50" md="7">
                                {editing ?

                                  <div className="contact-editform pl-0 m-auto">
                                    <Form onSubmit={handleSubmit(UpdateContact)}>
                                      <div className="form-row">
                                        <div className="contact-profile">
                                          <img className="rounded-circle img-100" src={editurl} alt="" />
                                          <div className="icon-wrapper">
                                            <i className="icofont icofont-pencil-alt-5">
                                              <input className="upload" type="file" onChange={(e) => HandleEditUrl(e)} />
                                            </i>
                                          </div>
                                        </div>
                                        <FormGroup className="col-md-12">
                                          <label>{Name}</label>
                                          <Row>
                                            <Col sm="6">
                                              <Input className="form-control" type="text" name="name" defaultValue={name} value={name} onChange={e => setName(e.target.value)} innerRef={register({ required: true })} />
                                              <span style={{ color: "red" }}>{errors.name && 'First name is required'}</span>
                                            </Col>
                                            <Col sm="6">
                                              <Input className="form-control" type="text" name="surname" defaultValue={surname} value={surname} onChange={e => setSurname(e.target.value)} innerRef={register({ required: true })} />
                                              <span style={{ color: "red" }}>{errors.surname && 'Last name is required'}</span>
                                            </Col>
                                          </Row>
                                        </FormGroup>
                                        
                                    <FormGroup className="col-md-12">
                                    <Label>{"Autre Email"}</Label>
                                    <Input className="form-control" name="email2" defaultValue={email2} value={email2} onChange={e => setemail2(e.target.value)} type="text" innerRef={register({ })} />
                                    <span style={{ color: "red" }}>{errors.email2 && 'Email est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{"Adresse"}</Label>
                                    <Input className="form-control" name="adresse" defaultValue={adresse} value={adresse} onChange={e => setAdresse(e.target.value)} type="text" innerRef={register({ })} />
                                    <span style={{ color: "red" }}>{errors.adresse && 'adresse est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{"Cp"}</Label>
                                    <Input className="form-control" name="cp" defaultValue={cp} value={cp} onChange={e => setCP(e.target.value)} type="text" innerRef={register({ })} />
                                    <span style={{ color: "red" }}>{errors.cp && 'cp est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <Label>{"Localité"}</Label>
                                    <Input className="form-control" name="localite" defaultValue={localite} value={localite} onChange={e => setLocalite(e.target.value)} type="text" innerRef={register({ })} />
                                    <span style={{ color: "red" }}>{errors.localite && 'localite est obligatoire.'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12 ">
                                    <Label>{"Numero téléphone"}</Label>
                                    <Input className="form-control" name="mobile" type="text" defaultValue={mobile} value={mobile} onChange={e => setMobile(e.target.value)}  innerRef={register({ pattern: /\d+/, minlength: 8, maxlength: 15 })} />
                                    <span style={{ color: "red" }}>{errors.mobile && 'Minimumu 8 number'}</span>
                                  </FormGroup>
                                  <FormGroup className="col-md-12 ">
                                    <Label>{"Numero téléphone 2"}</Label>
                                    <Input className="form-control" name="mobile1" type="text" defaultValue={mobile1} value={mobile1} onChange={e => setmMobile1(e.target.value)} innerRef={register({ pattern: /\d+/, minlength: 8, maxlength: 15 })} />
                                    <span style={{ color: "red" }}>{errors.mobile1 && 'Minimumu 8 number'}</span>
                                  </FormGroup>
                                      </div>
                                      <div className='text-right'>
                                    
                                      <Button color="secondary" onClick={() => setEditing(false)}>{Cancel}</Button>
                                      <Button color="primary" className="update-contact mr-1">{"Modifier"}</Button>
                                      </div>
                                    </Form>
                                  </div>
                                  :
                                  <TabContent activeTab={dynamictab}>
                                    <TabPane tabId={dynamictab}>
                                      {selectedUserFromCat ?
                                      <div className="profile-mail">
                                        <div className="media">
                                          <img className="img-100 img-fluid m-r-20 rounded-circle update_img_0" src={selectedUserFromCat.avatar} alt="" />
                                          <div className="media-body mt-0">
                                            <h5><span className="first_name_0">{selectedUserFromCat.name}</span> <span className="last_name_0">{" " +selectedUserFromCat.surname}</span></h5>
                                            <p className="email_add_0">{selectedUserFromCat.email}</p>
                                            <ul>
                                              <li><a href="#javaScript" onClick={() => EditUSers(selectedUserFromCat)}>{Edit}</a></li>
                                              <li><a href="#javaScript" onClick={() => deleteUser(selectedUserFromCat.id)}>{Delete}</a></li>
                                              {/* <li><a href="#javaScript" onClick={() => history()}>{History}</a></li> */}
                                              <li><a href="#javaScript" onClick={() => printModalToggle()} data-toggle="modal" data-target="#printModal">{Print}</a></li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="email-general">
                                          <h6 className="mb-3">{General}</h6>
                                          <ul>
                                            <li>{Name} <span className="font-primary first_name_0">{selectedUserFromCat.name}</span></li>
                                            {/* <li>{Age} <span className="font-primary">{selectedUserFromCat.age}</span></li> */}
                                            <li>{MobileNo} <span className="font-primary mobile_num_0">{selectedUserFromCat.mobile}</span></li>
                                            <li>{EmailAddress} <span className="font-primary email_add_0">{`${selectedUserFromCat.email} ${selectedUserFromCat.surname}`} </span></li>
                                            <li>{EmailAddress}2 <span className="font-primary email_add_0">{`${selectedUserFromCat.email2 || ""}  `} </span></li>
                                            <li>{"Adresse"} <span className="font-primary email_add_0">{`${selectedUserFromCat.adresse || ""}`} </span></li>
                                            <li>{"Localité"} <span className="font-primary email_add_0">{`${selectedUserFromCat.localite || ""}`} </span></li>
                                            <li>{"Cp"} <span className="font-primary email_add_0">{`${selectedUserFromCat.cp || ""}`} </span></li>
                                            <li>{EmailAddress} <span className="font-primary email_add_0">{`${selectedUserFromCat.name || ""}`} </span></li>
                                          </ul>
                                        </div>
                                      </div>
                                      :
                                      <Col sm="12">
                                          <div>
                                              <div className="search-not-found text-center">
                                                  <div>
                                                      
                                                      <p className="mb-0">{"Aucun contact trouvé"}</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </Col>
                                      }
                                    </TabPane>
                                  </TabContent>
                                }
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>)}
                  

                      <div id="right-history" className="history">
                        <div className="modal-header p-l-20 p-r-20">
                          <h6 className="modal-title w-100">{ContactHistory}
                                <span className="pull-right">
                              <a className="closehistory" href="#javaScript" onClick={closehistory}>
                                <i className="icofont icofont-close"></i>
                              </a>
                            </span>
                          </h6>
                        </div>
                        <div className="history-details">
                          <div className="text-center"><i className="icofont icofont-ui-edit"></i>
                            <p>{"Contact has not been modified yet."}</p>
                          </div>
                          <div className="media"><i className="icofont icofont-star mr-3"></i>
                            <div className="media-body mt-0">
                              <h6 className="mt-0">{"Contact Created"}</h6>
                              <p className="mb-0">{"Contact is created via mail"}</p><span className="f-12">{"Sep 10, 2019 4:00"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedUser ?
                      <Modal isOpen={printmodal} toggle={printModalToggle} >
                        <ModalHeader toggle={printModalToggle}>{PrintViews}</ModalHeader>
                        <ModalBody className="list-persons">
                          <PrintPreview selectedUser={selectedUser} ref={componentRef}/>
                          <ReactToPrint
                              trigger={() => (
                                <Button color="secondary" className="mr-1">
                                  {Print}
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          
                          <Button color="primary" onClick={printModalToggle}>{Cancel}</Button>
                          
                        </ModalBody>
                      </Modal>
                      :""}
                    </TabContent>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
export default Newcontact;