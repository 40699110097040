import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import Dropzone from 'react-dropzone-uploader'
import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button, InputGroupAddon, InputGroupText, InputGroup} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import {addNewProject} from '../../../redux/project-app/action'
import { useDispatch, useSelector } from 'react-redux';
import {withRouter,Link,useParams, useHistory} from 'react-router-dom'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {UploadProjectFile,Add,Cancel, EventStatevisibilite, EventsCommentBlock, EventsCommentRequired, EventTitle, EventsDescription, EventsDateDebut, EventsDateFin, EventsDateLimite, EventsLieu, EventsOrganisator, Title, EventstimeFin, EventstimeDebut, Files} from '../../../constant'
import { firestorage, urlbackend } from '../../../data/config';
import { toast } from 'react-toastify';
import moment from 'moment';
import SweetAlert from 'sweetalert2';
import { NewNews } from '../../../redux/news/action';
import { deletemediaevents, NewEVENTS, updateEvents } from '../../../redux/events/action';
import { WATCH_EVENT_CATEGORY, WATCH_FRANCHISE_LIST } from '../../../redux/actionTypes';
import { fetchEventById } from '../../../api';
const UpdateEvents = (props) => {
const {id} = useParams()
    const dispatch = useDispatch()
    const {register, handleSubmit, errors } = useForm();
    const [startDate, setstartDate] = useState(new Date())
    const [endDate, setendDate] = useState(new Date())
    const [visibleState, setVisibleState] = useState(false)
    const [commentBlock, setCommentBlock] = useState(false)
    const [commentrequired, setcommentrequired] = useState(false)
    const [dateLimiteinscrit, setDateLimiteinscrit] = useState(new Date())
    const [titre, setTitre] = useState("")
    const [event, setEvents] = useState(null)
    const [description, setDescription] = useState("")
    const [lieu, setLieu] = useState("")
    const [marqueId, setmarqueId] = useState("")
    const [organisateur, setOrganisateur] = useState("")
    const [contenu, setcontenu] = useState("")
    const [organisateur_id, setorganisateur_id] = useState("")
    const [horaire_ouverture, sethoraire_ouverture] = useState()
    const [horaire_fermeture, sethoraire_fermeture] = useState()
    const listEventCategory = useSelector(state => state.EventCategory.eventcategory)
    const [random, setrandom] = useState(0)
    const [category_id, setcategory_id] = useState(0)
    
    const [myfilee, setMyFile] = useState([])
    const [newmyfilee, setNewMyFile] = useState([])
    const handleStartDate = date => {
      setstartDate(date);
    };
    const removefile = (name,id) => {
  
      SweetAlert.fire({
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
         let rescop = myfilee
         rescop = rescop.filter(res => res.name !== name)
         setrandom(Math.random())
        deletemediaevents(id)
         setMyFile(rescop)
          SweetAlert.fire(
            'Supprimé!',
            'Votre fichier a été supprimé',
            'success'
          )
         
       
         
        }
        else {
        
        }
      })
    }

    const [marque, setMarque] = useState("");
    const franchises = useSelector((content) => content.Franchiseapp.franchises);
  const history = useHistory()
  const filelist2 = myfilee?.map((data,i)=>{
    return(
      <li    onDoubleClick={() => {
         
          }} className="file-box" key={i}>
        <div  className="file-top">{ data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   width="200" height="100" src={`${urlbackend}${data.url}`} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
        <div className="file-bottom">
          <h6>{data.name.slice(0,20)}...<i className="fa fa-trash" onClick={() => removefile(data.name,data.media_events_id)}></i></h6>
  
        </div>
     
             
      </li>
    )
  })
    useEffect(() => {
      dispatch({type : WATCH_EVENT_CATEGORY})
      fetchEventById(id).then(res =>{
        if(res.title === undefined)
        history.push(`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate/`)
      
        console.log(res)
        setEvents(res)
        setendDate(new Date(res.end))
        setstartDate(new Date(res.start))
        setDateLimiteinscrit(new Date(res.limit_date_inscrit))
        sethoraire_ouverture(res.horaire_ouverture)
        sethoraire_fermeture(res.horaire_fermeture)
        setcontenu(res.description)
        setLieu(res.lieu)
        setTitre(res.title)
        setMarque(res.marqueId)
        setVisibleState(res.visibility_state === "1" ? true : false)
        setorganisateur_id(res.organisateur_id)
        setcommentrequired(res.comment_required === "1" ? true : false)
        setCommentBlock(res.comment_state === "1" ? true : false)
        setcategory_id(res.category_id)
        setMyFile(res.piecesJoint)
      })
      dispatch({ type: WATCH_FRANCHISE_LIST });
    }, [dispatch]);
  
   
    
    const getUploadParams = ({ meta, file }) => {

      const formData =  new FormData();
      formData.append('image', file);

          return { url: 'https://gocloud.groupe-fbh.com/intranet/api/mediaevents/uploadmedia.php', body : formData  } }
        const handleChangeStatus = ({xhr, meta, file }, status) => {
    
          if(status === "done" ){
              if (xhr.readyState == 4) {
                let myfils = newmyfilee;
                const result = JSON.parse(xhr.response);
               myfils.push({
               url : result.url,
               name: meta.name,
               size: meta.size,
               type: meta.type,
               creation_date : moment(new Date()).format('yyyy-MM-DD HH:mm'),
               updated_date : moment(new Date()).format('yyyy-MM-DD HH:mm'),
               icon:"fa fa-file-text-o txt-info"
                })
                setNewMyFile(myfils)
                toast.success("Fichier Téléchargé avec succés")
              }
            }
   else if (status === "removed"){
      let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
      setMyFile(myfileeCopy)
    }
        }
    const AddProject = () => {
      console.log(myfilee)
      let data = event
   
        data.visibility_state = visibleState ? 1 : 0
        data.comment_state = commentBlock  ? 1 : 0
        data.comment_required = commentrequired  ? 1 : 0
        data.start = startDate+""
        data.end = endDate+""
        data.limit_date_inscrit = dateLimiteinscrit
        data.description = contenu
        data.event_state = true
 data.title = titre
 data.lieu = lieu

         data.prop_id = localStorage.getItem("@intranetidUser")
   
         data.piecesJoint = newmyfilee
        
         data.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
        
         data.category_id = category_id
         data.organisateur_id = organisateur_id
         data.horaire_ouverture = moment(horaire_ouverture).format('HH:mm')
         data.horaire_fermeture =  moment(horaire_fermeture).format('HH:mm')
        if(localStorage.getItem("@intranetTypeUser") === 'admin'){
      
        data.marqueId = marque
      } else if (localStorage.getItem("@intranetTypeUser") === 'Fournisseur'){
     
        data.marqueId =  ""
      }else {
      
        data.marqueId = localStorage.getItem('@intranetidMarqueUser') || ""
    
      }
      dispatch(updateEvents(data,event.events_id))
        props.history.push(`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate/`)
      }
    

    return (
        <Fragment>
        <Breadcrumb parent="Evénements" title="Modifier événement" /> 
        <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={(e) => {
                      e.preventDefault()
                      AddProject()}}>
                    <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventTitle}</Label>
                            <Input className="form-control" type="text" value={titre} onChange={(e) => setTitre(e.target.value)}  name="title" placeholder="Titre Evenement *" required />
                            <span style={{ color: "red" }}>{errors.title && 'Nom événement est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventsDescription}</Label>
                            <SunEditor setContents={contenu}   onChange={(e) => setcontenu(e)} />
                            <span style={{ color: "red" }}>{errors.client_name && 'Description est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
             
                        <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventsLieu}</Label>
                            <Input className="form-control" value={lieu}  onChange={(e) => setLieu(e.target.value)} type="text"  name="lieu" placeholder="Lieu Evenement *" required />
                            <span style={{ color: "red" }}>{errors.lieu && 'Lieu is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Choisir categorie"}</Label>
                        <select
                          onChange={(e) => {
                            let id = e.target.value;
                           // localStorage.setItem("@intranet_franchiseid", id);

setcategory_id(id)
                           
                          }}
                          className="form-control digits"
                        >
                           <option >
                             Choisir categorie
                            </option>
                          {listEventCategory.map((eventCategory, index) => (
                            <option selected={eventCategory.event_category_id === category_id} value={eventCategory.event_category_id}>
                              {eventCategory.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                      {localStorage.getItem("@intranetTypeUser") === 'admin' ?
                      <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Choisir Marque"}</Label>
                        <select
                        
                          onChange={(e) => {
                            let id = e.target.value;
                            localStorage.setItem("@intranet_franchiseid", id);

setMarque(id)
                           
                          }}
                          className="form-control digits"
                        >
                           <option >
                             Choisir marque
                            </option>
                          {franchises.map((franchise, index) => (
                            <option selected={franchise.franchise_id === marque} value={franchise.franchise_id}>
                              {franchise.franchise_name}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  
                  </Row> : ""}
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{EventsOrganisator}</Label>
                            <Input className="form-control" type="text" value={organisateur_id} onChange={(e) => setorganisateur_id(e.target.value)}  name="organisateur" placeholder="Nom Organisateur" required/>
                            <span style={{ color: "red" }}>{errors.organisateur && 'organisateur est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                       <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventstimeDebut}</label>
                       
                            <DatePicker locale={"fr"} className="form-control digits" onChange={e => {
                         
                                sethoraire_ouverture(e.getTime())
                            }} showPopperArrow={true} showTimeSelectOnly showTimeSelect value={horaire_ouverture}  dateFormat="p" />
                          </div>
                        </FormGroup>
                        </Col>
                        <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventstimeFin}</label>
                       
                            <DatePicker locale={"fr"} className="form-control digits" onChange={e => {
                          
                                sethoraire_fermeture(e.getTime())
                            }} showPopperArrow={true} showTimeSelectOnly  showTimeSelect   value={horaire_fermeture} dateFormat="p" />
                          </div>
                        </FormGroup>
                        </Col>
                         </Row>
                       <Row>
                       <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventsDateDebut}</label>
                       
                            <DatePicker className="form-control digits" value={startDate}  onChange={e => setstartDate(e)} showPopperArrow={true} showTimeSelect selected={startDate}  dateFormat="Pp" />
                          </div>
                        </FormGroup>
                        </Col>
                         </Row>
                         <Row>
                       <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventsDateFin}</label>
                       
                            <DatePicker className="form-control digits" value={endDate}  onChange={e => setendDate(e)} showPopperArrow={true} showTimeSelect selected={endDate}  dateFormat="Pp" />
                          </div>
                        </FormGroup>
                        </Col>
                         </Row>
                        
                         <Row>
                       <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{EventsDateLimite}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => setDateLimiteinscrit(e)} showPopperArrow={true} showTimeSelect selected={dateLimiteinscrit}  dateFormat="Pp" />
                          </div>
                        </FormGroup>
                        </Col>
                         </Row>
                         <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" checked={commentrequired}  value={commentrequired} onChange={(e) => setcommentrequired(e.target.checked)} />
                        <Label for="radio1">{EventsCommentRequired}<span className="digits"> </span></Label>
                      </div>

                    </FormGroup>
                        </Col>
                        </Row>
                         <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio2" type="checkbox"  name="radio2" checked={visibleState}  value={visibleState} onChange={(e) => setVisibleState(e.target.checked)} />
                        <Label for="radio2">{EventStatevisibilite}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio3" type="checkbox" name="radio3" checked={commentBlock}  value={commentBlock} onChange={(e) => setCommentBlock(e.target.checked)} />
                        <Label for="radio3">{EventsCommentBlock}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                    <Col>
                    <h6 className="mt-4">{Files}</h6>
                      {filelist2?.length > 0 ?
              <ul className="files">
                  { filelist2 }
       
              </ul> : ""}
                    </Col>
                  </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UploadProjectFile}</Label>
                                <Dropzone
                              
                                    inputWithFilesContent={"Ajouter Fichier"}
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    maxFiles={3}
                                    multiple={true}
                                    canCancel={true}
                                    submitButtonDisabled={true}
                                    inputContent="Glisser Fichier(s)"
                                    styles={{
                                        dropzone: { width: '100%', height: 50 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right">
                      
                          <Link  to={`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate`}>
                              <Button className="mr-3" color="secondary">{Cancel}</Button>
                              
                          </Link>
                              <Button  color="primary" >{"Modifier"}</Button>
                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}

export default withRouter(UpdateEvents);