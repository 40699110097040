import {GET_SUBFRANCHISE_LIST,
        ADD_TO_MY_SUBFRANCHISE,
        ADD_NEW_SUBFRANCHISE,
        REMOVE_SUBFRANCHISE,
        REMOVE_FROM_MY_SUBFRANCHISE,
        UPDATE_SUBFRANCHISE,
        UPDATE_MY_SUBFRANCHISE,
        UPDATE_STATE_SUBFRANCHISE,
        GET_SUBFRANCHISE_BY_ID,
        GET_ALL_SUBFRANCHISE,

  } from '../actionTypes'


const initial_state = {
    subfranchises : [],
    subfranchise : {},
    subfranchisesmanagerdata:[],
    subfranchisesfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {
        case GET_ALL_SUBFRANCHISE:
            return {...state,subfranchises: action.subfranchises, loading: true,};
     
        case GET_SUBFRANCHISE_LIST:
            return {...state,subfranchises: action.subfranchises, loading: true};

        case GET_SUBFRANCHISE_BY_ID:
                return {...state,subfranchises: action.subfranchises, loading: true,};
    
        case ADD_TO_MY_SUBFRANCHISE:
            return {...state,mysubfranchisesdata:[...state.mysubfranchisesdata, { ...action.payload.mysubfranchisesdata}]};
        
        case ADD_NEW_SUBFRANCHISE:
            state.subfranchises.push(action.payload.subfranchisedata)
            return {...state,subfranchises: state.subfranchises};
        
        case UPDATE_SUBFRANCHISE:
            return {
                ...state,subfranchises: state.subfranchises.map(item => item.subfranchise_id === action.payload.id ?
                    { ...item, 
                        id: action.payload.id,
                        ...action.payload.updatedata
                    } : 
                    item
                ) 
            };
        case UPDATE_STATE_SUBFRANCHISE:
                return {
                    ...state,subfranchises: state.subfranchises.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            fournisseurState:action.payload.state
                        } : 
                        item
                    ) 
                };
        case UPDATE_MY_SUBFRANCHISE:
                return {
                    ...state,subfranchises: state.subfranchises.map(item => item.subfranchise_idid === action.payload.id ?
                        { ...item, 
                            subfranchise_id: action.payload.id,
                            ...action.payload.data
                        } : 
                        item
                    ) 
                };

        case REMOVE_SUBFRANCHISE:
            let new_subfranchises = state.subfranchises.filter(item=> action.removeid !== item.subfranchise_id)
            return{...state,subfranchises: new_subfranchises}
        
        case REMOVE_FROM_MY_SUBFRANCHISE:
            let new_my_subfranchises = state.mysubfranchisesdata.filter(item=> action.subfranchisesid !== item.id)
            return{...state,mysubfranchisesdata: new_my_subfranchises}
                
        default: return { ...state };
    }
    
}