import axios from 'axios';
import { Storage } from '../../constant';
import { db, firestorage, urlbackend } from '../../data/config';
import SweetAlert from 'sweetalert2'
import {ADD_TO_MY_FOLDER,
        ADD_NEW_FOLDER,
        REMOVE_FOLDER,
        REMOVE_FROM_MY_FOLDER,
        UPDATE_FOLDER,
        UPDATE_MY_FOLDER,
        GET_FOLDER_LIST_BY_ID,
        ADD_NEW_FILE,
        REMOVE_FILE,
        SHARE_FILE,
        UPDATE_FILE_TREE,
        UPDATE_FILE,
        UPDATE_MY_FILE,
        FAVORIS_FILE,
        DELETE_FAVORIS_FILE,
        DELETE_FILE_FROM_SHARE,
        ADD_NEW_SUB_FILE,
        REMOVE_SHARE_FILE,
        REMOVE_MY_SHARE_FILE,
        REMOVE_FINALY_FILE,
        RECUPERE_FILE,
        } from '../actionTypes';


export const myfolder = (myfolderdata) => {
    return ({
        type: ADD_TO_MY_FOLDER,
        payload: {myfolderdata}
    })
}


export const newFile =   (folderdata) => {
 let folderName = localStorage.getItem("folderName");
//  console.log(imageAsFile)
//     const uploadTask =  firestorage.ref(`/${folderName}/${imageAsFile.name}`).put(imageAsFile)
   
//  uploadTask.on('state_changed', 
// (snapShot) => {
//   //takes a snap shot of the process as it is happening
//   console.log(snapShot)
// }, (err) => {
//   //catches the errors
//   console.log(err)
// }, () => {
//   // gets the functions from storage refences the image storage in firebase by the children
//   // gets the download url then sets the image from firebase as the value for the imgUrl key:
//   firestorage.ref(folderName).child(imageAsFile.name).getDownloadURL()
//    .then(fireBaseUrl => {
//      folderdata.fileImage = fireBaseUrl
     db.collection("Folders").doc(folderdata.idfolder).collection("Files").add(folderdata)
     db.collection("Folders").doc(folderdata.idfolder).get().then(res => {
         let numberfile = res.data().filesNumber || 0;
         db.collection("Folders").doc(folderdata.idfolder).update({filesNumber : Number(numberfile)+1})
     })


    // alert("files ajouter avec succés")
     //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
//    })
// })
    
 
 return   ({
    type: ADD_NEW_SUB_FILE,
    payload: folderdata
})
}
export const newFileRacine =   (folderdata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/create.php`,
        data: JSON.stringify(folderdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier ajoutée',
            'Fichier a été ajoutée avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

       
    
    return  ({
       type: ADD_NEW_FILE,
       payload: folderdata
   })
   }
   export const newSubFile = (folderdata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/create.php`,
        data: JSON.stringify(folderdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier ajoutée',
            'Fichier a été ajoutée avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

       
    
    return   ({
       type: ADD_NEW_SUB_FILE,
       payload: folderdata
   })
   }
export const updateRacineFile = (id,updatedata) => {
    updatedata.files_id = id
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/update.php`,
        data: JSON.stringify(updatedata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier Renomé',
            'Fichier a été Renomé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

    
    return ({
        type: UPDATE_FILE,
        payload: {id,updatedata}
    })

}
export const updateFile = (id ,updatedata) => {
    updatedata.files_id = id
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/update.php`,
        data: JSON.stringify(updatedata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier Renomé',
            'Fichier a été Renomé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

    
    return ({
        type: UPDATE_MY_FILE,
        payload: {id,updatedata}
    })

}
export const updateMyfolder = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_FOLDER,
        payload: {id,updateMydata,image_url}
    })

}
export const updateTreefile = (idsrc, iddest, selectedDataDrag) => {
    selectedDataDrag.idfolder=idsrc;
    const data = localStorage.getItem("idfolder");
    db.collection("Folders").doc(data).collection("Files").doc(iddest).delete()
    db.collection("Folders").doc(idsrc).collection("Files").doc(iddest).set(selectedDataDrag)
    db.collection("Folders").doc(data).get().then(res => {
        let numberfile = res.data().filesNumber || 1;
        db.collection("Folders").doc(data).update({filesNumber : Number(numberfile) - 1})
    })
    db.collection("Folders").doc(idsrc).get().then(res => {
        let numberfile = res.data().filesNumber || 1;
        db.collection("Folders").doc(idsrc).update({filesNumber : Number(numberfile) + 1})
    })
    return ({
        type: UPDATE_FILE_TREE,
        payload: {idsrc, iddest}
    })

}

export const deleteShareFiles = (user_id , idfile) => {

    const idUser = localStorage.getItem("@intranetidUser")
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/removeshare.php`,
        data: JSON.stringify({"user_id" :idUser,"file_id" : idfile}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier supprimé',
            'Fichier a été supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
  
    return ({
        type: DELETE_FAVORIS_FILE,
        payload: {id : idfile}
    })

}
export const removefrommysharefile = (user_id , idfile) => {

    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/removeshare.php`,
        data: JSON.stringify({"user_id" :user_id,"file_id" : idfile}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier supprimé',
            'Fichier a été supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
  
    return ({
        type: REMOVE_MY_SHARE_FILE,
        payload: {user_id , idfile}
    })

}
export const removefromsharefile = (user_id , idfile) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/removeshare.php`,
        data: JSON.stringify({"user_id" :user_id,"file_id" : idfile}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier supprimé',
            'Fichier a été supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
  
    return ({
        type: REMOVE_SHARE_FILE,
        payload: {user_id , idfile}
    })

}
export const shareFiles = (id, sharedListe,selectedUsertype) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/share.php`,
        data: JSON.stringify({"user_liste" :selectedUsertype}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier partagé',
            'Fichier a été partagé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
  
    return ({
        type: SHARE_FILE,
        payload: {id, sharedListe,selectedUsertype}
    })

}
export const addFavorisFiles = (id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    const idUser = localStorage.getItem("@intranetidUser")
    axios({
        method: 'post',
        url: `${urlbackend}files/favoris.php`,
        data: JSON.stringify({"user_id" :idUser,"file_id" : id}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier favorisé',
            'Fichier a été ajouté au liste favoris avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Fichier déja favorisé',
            'Fichier déja ajouté au liste favoris avec succés',
            'success'
          )
    });
        return ({
            type: FAVORIS_FILE,
            payload: {id, idUser}
        })
    
    }
    export const deleteFavorisFiles = (id) => {
        const token = localStorage.getItem('intranetTypeUsertoken')
        const idUser = localStorage.getItem("@intranetidUser")
        axios({
            method: 'post',
            url: `${urlbackend}files/removefavoris.php`,
            data: JSON.stringify({"user_id" :idUser,"file_id" : id}),
            config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
        })
        .then(function (response) {
            //handle success
            SweetAlert.fire(
                'Fichier supprimé',
                'Fichier a été supprimé avec succés',
                'success'
              )
          
        })
        .catch(function (response) {
            //handle error
            console.log("response")
            console.log(response)
        });
      
    
                return ({
                    type: DELETE_FAVORIS_FILE,
                    payload: {id}
                })
            
            } 
export const removeFile = (Id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}files/delete.php`,
        data: JSON.stringify({"files_id" : Id }),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier supprimé',
            'Fichier a été supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

       

    return ({
        type: REMOVE_FILE,
         payload : {Id}
    })

}
export const removeFileFinaly = (Id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}files/deletefinaly.php`,
        data: JSON.stringify({"files_id" : Id }),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier supprimé',
            'Fichier a été supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

       

    return ({
        type: REMOVE_FINALY_FILE,
         payload : {Id}
    })

}
export const recupererFile = (Id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}files/recup.php`,
        data: JSON.stringify({"files_id" : Id }),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Fichier recuperé',
            'Fichier a été recuperé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

       
    return ({
        type: RECUPERE_FILE,
         payload : {Id}
    })

}

export const removemyfolder = (folderid) => {
    return ({
        type: REMOVE_FROM_MY_FOLDER,
        folderid
    })
}