import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from '../../../layout/breadcrumb'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Media,

} from "reactstrap";
import { getCartTotal, getCartTotalTVA } from "../../../services/ecommerce.service";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { ProductName,Sub_total,ThankBusiness,ThankBusinessDesc,Print,Quantity,Price,Cancel } from '../../../constant';
import { manageOrder, NotifierUserOrder } from "../../../redux/orders/action";
import SweetAlert from 'sweetalert2'
import { checkStockQuantite, fetchDeatilsOrder, gestionStockQuantite } from "../../../api";
import moment from "moment";
class InvoiceFactureProvider extends React.Component {
  render() {
    const { symbol,order } = this.props
    console.log(order)

    return (
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <div className="invoice">
                    <div>
                      <div>
                     
                        <Row>
                          <Col sm="6">
                            <Media>
                              <Media left>
                                <Media
                                  className="media-object img-60"
                                  src={order?.provider.image}
                                  alt=""
                                />
                              </Media>
                              <Media body className="m-l-20">
                                <h4 className="media-heading">{order?.provider.last_name} {order?.provider.first_name}</h4>
                                <p>
                                 
                                  <br />
                                  <span className="digits">{order?.provider.phone_number}</span>
                                </p>
                              </Media>
                            </Media>
                          </Col>
                          <Col sm="6">
                            <div className="text-md-right">
                              
                              <p>
                                {"Date commande "}
                                <span className="digits">{order?.CreationDate}</span>
                                <br />
                                {"date livraison commande "}{" "}
                                <span className="digits">{order?.livraisondate}</span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />

                      <Row>
                        <Col md="4">
                          <Media>
                            <Media left>
                              {/* <Media
                                className="media-object rounded-circle img-60"
                                src={require("../../../assets/images/user/1.jpg")}
                                alt=""
                              /> */}
                            </Media>
                            <Media body className="m-l-20">
                              <h4 className="media-heading">{order?.franchise.manager_name }</h4>
                              <p>
                                {order?.franchise.franchise_name}
                                <br />
                                <span className="digits">{order?.franchise.phone_number}</span>
                              </p>
                            </Media>
                          </Media>
                        </Col>
                        {/* <Col md="8">
                          <div className="text-md-right" id="project">
                            <h6>{ProjectDescription}</h6>
                            <p>
                              {ProjectDescriptionDetails}
                            </p>
                          </div>
                        </Col> */}
                      </Row>

                      <div>
                        <div
                          className="table-responsive invoice-table"
                          id="table"
                        >
                          <Table bordered striped>
                            <tbody>
                              <tr>
                                <td className="item">
                                  <h6 className="p-2 mb-0">{ProductName}</h6>
                                </td>
                                <td className="quantity">
                                  <h6 className="p-2 mb-0">{Quantity}</h6>
                                </td>
                                <td className="Rate">
                                  <h6 className="p-2 mb-0">{Price}</h6>
                                </td>
                                </tr>
                               
                              {order?.productListe.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <label>{item.product_name} {item.selectedTaille}</label>
                                    </td>
                                    <td>
                                      <p className="itemtext digits">
                                        {item.qty}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="itemtext digits">
                                        {symbol}
                                        {item.price}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td className="payment digits">
                                <h6 className="p-2 mb-0">{Sub_total} :  {symbol}
                                    {order?.subTotal} </h6>
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="payment digits">
                                <h6 className="p-2 mb-0">{"TVA"} :    {symbol}
                                    {order?.tva}</h6> 
                                 
                                </td>
                              </tr>
                              <tr>
                                <td className="payment digits">
                                <h6 className="p-2 mb-0">{"Total"} :    {symbol}
                                    {Number(order?.subTotal) + Number(order?.tva)}</h6> 
                                 
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <Row>
                          <Col md="8">
                            <div>
                              <p className="legal">
                                <strong>{ThankBusiness}</strong>
                                  {ThankBusinessDesc}
                              </p>
                            </div>
                          </Col>
                          {/* <Col md="4">
                            <Form className="text-right">
                              <input
                                type="image"
                                src={require("../../../assets/images/other-images/paypal.png")}
                                name="submit"
                                alt="PayPal - The safer, easier way to pay online!"
                              />
                            </Form>
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const PrintComponent = () => {

 const dispatch = useDispatch()
 const {idorder} = useParams();
  const [order, setOrder] = useState(null);

  const symbol = useSelector((content) => content.data.symbol);
useEffect(() => {
  fetchDeatilsOrder(idorder).then(res => {
console.log(res)
    setOrder(res)
  })


}, [])

  const componentRef = useRef();
  const validerOrder = () => {
    if(order.status == 1 ){
      SweetAlert.fire(
        'Commmande est deja acceptée!',
        'La commande est deja acceptée!',
        'success'
      )
    }
    else if(order.status == 2 ){
      SweetAlert.fire(
        'Commmande est deja annulée!',
        'La commande est deja annulée!',
        'success'
      )
    }
    else {
    checkStockQuantite(order.productListe).then(res => {
      console.log(res)
      if(res.state === false) {
        SweetAlert.fire({
         
          title: 'Repture de Stock ',
          text: "Ces Produits sont hors Stock: " +   res.productQteNotAvailble.map((pro,i) => pro.product_name+", "),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'cancel',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
      
            SweetAlert.fire({
     
              title: 'Notifier le client ',
              text: "Une fois Notifier, note sera envoyé au client pour modifier le commande ",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Ok',
              cancelButtonText: 'cancel',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                let notif = {
                  idUser : order.user_order_id,
                  idSender : order.idprovider,
                 
                  CreationDate : moment(new Date()).format('yyyy-MM-DD HH:mm'),
                  vue : 0,
                  text: "Repture de stock",
                  collectionType : "Orders"
                }
        dispatch(NotifierUserOrder(order.id,"Ces Produits sont hors Stock: " +   res.productQteNotAvailble.map((pro,i) => pro.productName+", "),notif));
                SweetAlert.fire(
                  'Note envoyer!',
                  'Votre Note est envoyer a été supprimée',
                  'success'
                )
              }
          })}
      })}
      else {
        let notif = {
          idUser : order.user_order_id,
          idSender : order.idprovider,
         
          CreationDate : moment(new Date()).format('yyyy-MM-DD HH:mm'),
          vue : 0,
          text: "Commande accepter",
          collectionType : "Orders"
        }
        gestionStockQuantite(order.productListe).then(res =>         dispatch(manageOrder(order.commande_id,"Accepté", 1,notif)))

      }
      })
}
  }
  const annulerOrder = () => {
    if(order.status == 1 ){
      SweetAlert.fire(
        'Commmande est deja acceptée!',
        'La commande est deja acceptée!',
        'success'
      )
    }
    else if(order.status == 2 ){
      SweetAlert.fire(
        'Commmande est deja annulée!',
        'La commande est deja annulée!',
        'success'
      )
    }else {

  
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois annuleé, vous ne pourrez plus Acceptée ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        let notif = {
          idUser : order.user_order_id,
          idSender : order.idprovider,
         
          CreationDate : moment(new Date()).format('yyyy-MM-DD HH:mm'),
          vue : 0,
          text: "Commande annulée",
          collectionType : "Orders"
        }
       dispatch(manageOrder(order.id,"Annulée", 2,notif));
        SweetAlert.fire(
          'Annulée!',
          'La commande a été annulée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  }
  return (
    <Fragment>
      <Breadcrumb parent="Ecommerce" title="Facture d'achat" />
      <button     className="btn-custom" style={{backgroundColor : order?.status == 0 ? "black" : order?.status == 1 ? "green" : order?.status == 2 ? "red" : "yellow"}} >  {order?.statustext}</button>
     
      <div  className="media-body text-right">
     
                <div className="d-inline-flex">
                {  localStorage.getItem("@intranetTypeUser") == 'Fournisseur' ?                               
                  <button  onClick={() => validerOrder()}   className="btn btn-primary" >  {"Valider Commande"}</button>
                 : ""}
                 {  localStorage.getItem("@intranetTypeUser") !== 'admin' ?   
                  <button   onClick={() => annulerOrder()}    className="btn btn-primary" > {"Annuler Commande"}</button>
                  : ""  } 
              <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </div> 
             
      </div>
      <InvoiceFactureProvider order={order}  symbol={symbol} ref={componentRef} />
      <Col sm="12" className="text-center my-3">
        <ReactToPrint
          trigger={() => (
            <Button color="primary" className="mr-2">
              {Print}
            </Button>
          )}
          content={() => componentRef.current}
        />
        <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/all-order`}>
          <Button color="secondary">{Cancel}</Button>
        </Link>
      </Col>
    </Fragment>
  );
};

export default PrintComponent;
