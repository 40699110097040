import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Form,
  FormGroup,
  Input,
  InputGroup,
  CardHeader,
  InputGroupAddon,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
} from "reactstrap";
import {
  SEND_MESSAGE_WATCHER,
  REPLY_MESSAGE_WATCHER,
  CREATE_CHAT_WATCHER,
  WATCH_USER_LIST,
  SEND_MESSAGE_CONV_WATCHER,
  WATCH_ALL_CONV_MESSAGES,
  WATCH_CHAT_SUCCESS,
  UPDATE_MESSAGE_CONV_WATCHER,
  WATCH_PROVIDER_LIST,
  WATCH_ALL_USER,
} from "../../../redux/actionTypes";
import { useSelector, useDispatch } from "react-redux";
import {
  changeChat,
  searchMember,
  watchfetchChatMember,
  watchfetchChat,
  watchfetchAllConvMessages,
  removeMessage,
  removeConversation,
} from "../../../redux/chap-app/action";
import defaultuser from '../../../assets/images/user/user.png';
import four from "../../../assets/images/user/4.jpg";
import one from "../../../assets/images/user/1.jpg";
import two from "../../../assets/images/user/2.png";
import errorImg from "../../../assets/images/search-not-found.png";
import start_conversion from "../../../assets/images/start-conversion.jpg";
import { Picker } from "emoji-mart";
import {
  CALL,
  STATUS,
  PROFILE,
  EricaHughes,
  VincentPorter,
  Active,
  ChataApp_p1,
  ChataApp_p2,
  Following,
  Follower,
  MarkJecno,
  Send,
  Cancel,
  Title,
  AddFile,
  Close,
  Newfiles,
} from "../../../constant";
import { Link } from "react-router-dom";
import emoji from "../../../assets/images/Unknown.png";
import { data } from "../../charts/chartsjs/chartsData";
import { db, firebase_app, firestorage } from "../../../data/config";
import SweetAlert from "sweetalert2";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { Edit, MoreVertical } from "react-feather";
const Chat = (props) => {
  const dispatch = useDispatch();
  const [firstname, setfirstname] = useState(
    localStorage.getItem("@intranetFirstUser")
  );
  const [lastname, setlastname] = useState(
    localStorage.getItem("@intranetLastUser")
  );
  const [userImage, setuserImage] = useState(
    localStorage.getItem("@intranetimageUser")
  );
  const [role, setrole] = useState(localStorage.getItem("@intranetTypeUser"));
  const [idUser, setidUser] = useState(localStorage.getItem("@intranetidUser"));

  const [convModal, setconvModal] = useState(false);
  const convToggle = () => {
    setconvModal(!convModal);
  };
  const [addUserModal, setaddUserModal] = useState(false);
  const addUserToggle = () => {
    setaddUserModal(!addUserModal);
  };
  const allMembers = useSelector((content) => content.ChatApp.allMembers);

  const chats = useSelector((content) => content.ChatApp.chats);

  const allMessages = useSelector((content) => content.ChatApp.allMessages);

  const selectedUser = useSelector((content) => content.ChatApp.selectedUser);
  const fournisseurs = useSelector(
    (content) => content.Fournisseurapp.fournisseurs
  );
  const currentUser = useSelector((content) => content.ChatApp.currentUser);
  const online = useSelector((content) => content.ChatApp.online);
  const members = useSelector((content) => content.ChatApp.members);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [activeTab, setActiveTab] = useState("0");

  const users = useSelector((content) => content.Userapp.users);
  const [searchvalue, setsearchvalue] = useState("");
  const [groupeName, setGroupeName] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [showApercu, setshowApercu] = useState(false);
  
  const showApercuToggle = () => { setshowApercu(!showApercu) }
  const [selectedConvMessage, setselectedConvMessage] = useState("");
  const [selecterSenderId, setselecterSenderId] = useState("0");

  const [selectedMessageID, setselectedMessageID] = useState("");

  const [manegeMessageModal, setmanegeMessageModal] = useState(false);
  const manegeMessageToggle = () => {
    setmanegeMessageModal(!manegeMessageModal);
  };
  const [myfilee, setMyFile] = useState([]);
  var images = require.context("../../../assets/images", true);
  const [AddFilesState, setAddFilesState] = useState(false);
  const AddFilesStatetoggle = () => setAddFilesState(!AddFilesState);
  const [clientX, setclientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [windowHeight, setwindowHeight] = useState(0);
  const [windowWidth, setwindowWidth] = useState(0);
  const [loading, setloading] = useState(true);

  const updateWindowDimensions = () => {
    setwindowWidth(window.innerWidth);
    setwindowHeight(window.innerHeight);
  };
  useEffect(() => {

    setwindowWidth(window.innerWidth);
    setwindowHeight(window.innerHeight);
    window.addEventListener("resize", updateWindowDimensions);
    setTimeout(() => {
      setloading(false);
    }, 2000);
    setInterval(() => {
      setrandom(Math.random());
    }, 3000);
    dispatch({ type: WATCH_ALL_USER });

    dispatch(watchfetchChatMember());
    dispatch(watchfetchChat());
    dispatch(watchfetchAllConvMessages());
  }, [dispatch]);
  const getUploadParams = ({ meta, file }) => {
    console.log(meta);
    let bytytrans = 0;
    const uploadTask = firestorage.ref(`/messages/${meta.name}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        firestorage
          .ref(`/messages`)
          .child(meta.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            let myfils = myfilee;

            myfils.push({
              url: fireBaseUrl,
              name: meta.name,
              size: meta.size,
              type: meta.type,
              icon: "fa fa-file-text-o txt-info",
            });
            console.log(myfils);
            setMyFile(myfils);
            toast.success("Fichier Téléchargé avec succés");

            console.log(meta.name);
            //setImageUrl(fireBaseUrl)
          });
      }
    );

    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => {};
  const dynamicImage = (image) => {
    return images(`./${image}`);
  };
  const handleSubmit = () => {
    let message = messageInput;

    setMessageInput(message);
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emoji) => {
    const text = `${messageInput}${emoji.native}`;
    setShowEmojiPicker(false);
    setMessageInput(text);
  };

  const changeChatClick = (e, selectedUserId) => {
    handleSearchKeyword("");
    const currentUserId = currentUser.id;
    const currentChat = chats.find(
      (x) =>
        x.users.includes(currentUser.id) && x.users.includes(selectedUserId)
    );
    if (currentChat) {
      dispatch(changeChat(selectedUserId));
    } else {
      dispatch({
        type: CREATE_CHAT_WATCHER,
        currentUserId,
        selectedUserId,
        chats,
      });
    }
  };
  const changeConvMessageClick = (e, selectedUserId, item) => {
    setloading(true);
    handleSearchKeyword("");
    setselectedConvMessage(selectedUserId);
    const currentUserId = idUser;
    localStorage.setItem("@intranetidConvMessage", selectedUserId);
    const currentChat = chats;
    dispatch({ type: WATCH_CHAT_SUCCESS, id: item });
    setTimeout(() => {
      setloading(false);
    }, 2000);
    // if (currentChat) {
    //     dispatch(changeChat(selectedUserId));
    // } else {
    //     dispatch({type : CREATE_CHAT_WATCHER , currentUserId, selectedUserId, chats})
    // }
  };
  const searchUser = (value) => {
    setsearchvalue(value);
  };
  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    dispatch(searchMember(keyword));
  };

  const handleMessageChange = (message) => {
    setMessageInput(message);
  };

  const handleMessagePress = (e) => {
    if (e.key === "Enter" || e === "send") {
      var container = document.querySelector(".chat-history");
      setTimeout(function () {
        container.scrollBy({ top: 200, behavior: "smooth" });
      }, 310);

      let currentUserId = idUser;
      let selectedConvId = selectedConvMessage;
      let currentUserName = firstname + " " + lastname;
      let currentUserImage = userImage;

      if (messageInput.length > 0 || myfilee.length > 0) {
        dispatch({
          type: SEND_MESSAGE_WATCHER,
          currentUserId,
          currentUserName,
          currentUserImage,
          selectedConvId,
          messageInput,
          chats,
          online: true,
          files: myfilee,
        });

        setMessageInput("");
        setMyFile([]);
        setTimeout(() => {
          const replyMessage =
            "Hey This is " +
            currentUserName +
            ", Sorry I busy right now, I will text you later";
          // if (selectedUser.online === true)
          //     document.querySelector(".status-circle").classList.add('online');
          //     selectedUser.online = true;
          //  dispatch({type : REPLY_MESSAGE_WATCHER,currentUserId, selectedConvId, replyMessage, chats, online})
        }, 5000);
      }
    }
  };
  const checkconvExist = () => {
    let selectedUserConvCopy = selectedUserConv;
    selectedUserConvCopy.push(idUser);
    if (selectedUserConvCopy.length === 2) {
      let state = false;

      allMessages.forEach((element) => {
        let missing = element.users.filter(
          (item) => selectedUserConvCopy.indexOf(item) < 0
        );
        if (missing.length === 0) {
          changeConvMessageClick("", element.user_id, element);
          state = true;
        }
      });
      return state;
    } else {
      return false;
    }
  };
  const handleMessageConvPress = (e, e1) => {
    if (selectedUserConv.length > 2 && groupeName.length === 0) {
      alert("saisir nom de groupe");
      return;
    }
    if (checkconvExist() === true) {
      convToggle();
      return;
    }
    if (e.key === "Enter" || e === "send") {
      var container = document.querySelector(".chat-history");
      setTimeout(function () {
        container.scrollBy({ top: 200, behavior: "smooth" });
      }, 310);

      console.log(selectedUserConv);
      console.log(selectedUserConvInfo);
      let data = {};
      data.createdUserName = firstname + " " + lastname;
      data.createdIdUser = idUser;

      let selectedUserConvCopy = selectedUserConv;

      let selectedUserInfoConvCopy = selectedUserConvInfo;
      selectedUserInfoConvCopy.push({
        id: idUser,
        fullname: firstname + " " + lastname,
        avatar: userImage,
        type:
          role === "admin"
            ? "Admin"
            : role === "manager" || role === "submanager"
            ? "Users"
            : "Fournisseurs",
      });
      data.users = selectedUserConvCopy;
      data.usersInfos = selectedUserInfoConvCopy;
      data.usersDetails = selectedUserInfoConvCopy;
      data.lastmessage = "Nouveau message";
      data.groupeName = groupeName.length > 0 ? groupeName : "prive";

      data.createdAt = new Date();
      dispatch({ type: SEND_MESSAGE_CONV_WATCHER, data });
      convToggle();
      setTimeout(() => {
        // if (selectedUser.online === true)
        //     document.querySelector(".status-circle").classList.add('online');
        //     selectedUser.online = true;
        dispatch({ type: WATCH_ALL_CONV_MESSAGES });
      }, 2000);
      if (messageInput.length > 0) {
        dispatch({ type: SEND_MESSAGE_CONV_WATCHER, data });
        setMessageInput("");
        setTimeout(() => {
          const replyMessage =
            "Hey This is " +
            "selectedUserName" +
            ", Sorry I busy right now, I will text you later";
          if (selectedUser.online === true)
            document.querySelector(".status-circle").classList.add("online");
          selectedUser.online = true;
          // dispatch({type : REPLY_MESSAGE_WATCHER,currentUserId, selectedUserId, replyMessage, chats, online})
        }, 5000);
      }
    }
  };

  const handleMessageaddUserPress = (e, e1) => {
    if (e.key === "Enter" || e === "send") {
      var container = document.querySelector(".chat-history");
      setTimeout(function () {
        container.scrollBy({ top: 200, behavior: "smooth" });
      }, 310);

      let data = {};

      let selectedUserConvCopy = selectedUserConv;

      let selectedUserInfoConvCopy = selectedUserConvInfo;

      data.users = selectedUserConvCopy;
      data.usersInfos = selectedUserInfoConvCopy;
      data.usersDetails = selectedUserInfoConvCopy;
      data.groupeName =
        groupeName.length > 0 ? groupeName : firstname + " " + lastname;

      data.updatedAt = new Date();
      dispatch({
        type: UPDATE_MESSAGE_CONV_WATCHER,
        data,
        selectedConvMessage,
      });
      addUserToggle();
      setTimeout(() => {
        // if (selectedUser.online === true)
        //     document.querySelector(".status-circle").classList.add('online');
        //     selectedUser.online = true;
        dispatch({ type: WATCH_ALL_CONV_MESSAGES });
      }, 2000);
      if (messageInput.length > 0) {
        dispatch({ type: SEND_MESSAGE_CONV_WATCHER, data });
        setMessageInput("");
        setTimeout(() => {
          const replyMessage =
            "Hey This is " +
            "selectedUserName" +
            ", Sorry I busy right now, I will text you later";
          if (selectedUser.online === true)
            document.querySelector(".status-circle").classList.add("online");
          selectedUser.online = true;
          // dispatch({type : REPLY_MESSAGE_WATCHER,currentUserId, selectedUserId, replyMessage, chats, online})
        }, 5000);
      }
    }
  };
  const [selectedUserConv, setselectedUserConv] = useState([]);
  const [selectedUserConvInfo, setselectedUserConvInfo] = useState([]);
  const [random, setrandom] = useState(0);
  const addToSelectedListe = (id, fullname, avatar, type) => {
    let selectedUserInfoCopy = selectedUserConvInfo;
    let selectedUserCopy = selectedUserConv;
    if (selectedUserCopy.includes(id) == true) {
      selectedUserInfoCopy.splice(
        selectedUserInfoCopy.findIndex((res) => res.id == id),
        1
      );
      selectedUserCopy.splice(
        selectedUserCopy.findIndex((res) => res == id),
        1
      );
      setselectedUserConv(selectedUserCopy);
      setselectedUserConvInfo(selectedUserInfoCopy);
      setrandom(Math.random);
    } else {
      selectedUserCopy.push(id);
      selectedUserInfoCopy.push({ id, fullname, avatar, type });
      setselectedUserConv(selectedUserCopy);
      setselectedUserConvInfo(selectedUserInfoCopy);
      setrandom(Math.random);
    }
  };
  const chatMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };
  const newMessage = (e) => {
    e.preventDefault();
    console.log(selectedUserConv);
    console.log(selectedUserConvInfo);
    data = {};
    data.createdUserName = firstname + " " + lastname;
    data.createdIdUser = idUser;

    data.users = selectedUserConv.push(idUser);
    data.usersInfos = selectedUserConvInfo.push({
      id: idUser,
      fullname: firstname + " " + lastname,
      avatar: userImage,
    });

    data.createdAt = new Date();
  };
  const RemoveMessages = (Idmessage, idConversation) => {
    SweetAlert.fire({
      title: "Vous Êtes sûr?",
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        dispatch(removeMessage(idConversation,Idmessage ));
        SweetAlert.fire("Supprimé!", "Votre Message a été supprimé", "success");
      } else {
      }
    });
  };
  const RemoveConversations = (Idmessage) => {
    SweetAlert.fire({
      title: "Vous Êtes sûr?",
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        dispatch(removeConversation(Idmessage));
        SweetAlert.fire("Supprimé!", "Votre Message a été supprimé", "success");
      } else {
      }
    });
  };
  // const selectedChat = (allMembers && chats && selectedUser) ?
  // chats.find(x => x.users.includes(currentUser.id) && x.users.includes(selectedUser.id)) :
  // null;

  const addImageToTextArea = () => {
    let img = {
      image_src:
        "http://www.freeiconspng.com/uploads/multimedia-photo-icon-31.png",
      name: "imageA",
    };
    let stringImage = `![${img.name}](${img.image_src})`;
    let { textArea } = this.refs;
    let value = textArea.getDOMNode().value;
    let position = textArea.getDOMNode().selectionStart;
    textArea.getDOMNode().value = `${value.substr(
      0,
      position
    )}${stringImage}${value.substr(position)}`;
  };
  const removefile = (name) => {
    SweetAlert.fire({
      title: "Vous Êtes sûr?",
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        let rescop = myfilee.filter((res) => res.name !== name);
        setMyFile(rescop);
        setrandom(Math.random());
        SweetAlert.fire(
          "Supprimé!",
          "Votre fichier a été supprimée",
          "success"
        );
      } else {
      }
    });
  };
  var activeChat = 0;
  if (selectedUser != null) activeChat = selectedUser.id;

  return allMembers && chats ? (
    <Fragment>
      <Breadcrumb parent="Chats" title="Chat" />
      <Container fluid={true}>
        <div className="media-body text-right">
          <Form className="d-inline-flex mr-2">
            <FormGroup className="mb-0 mr-0"></FormGroup>
            <Link
              className="btn btn-primary"
              style={{ color: "white" }}
              onClick={() => {
                setselectedUserConv([]);
                setselectedUserConvInfo([]);
                convToggle();
              }}
            >
              {"Nouveau message"}
            </Link>
          </Form>
        </div> 
        <Row>
          <Col sm="12" className="call-chat-sidebar">
            <Card>
              <CardBody className="chat-body">
                <div className="chat-box">
                  <div className="chat-left-aside">
                    <div className="media">
                      <Media
                        src={userImage.length > 10 ? userImage : defaultuser}
                        className="rounded-circle user-image"
                        alt=""
                      />
                      <div className="about">
                        <div className="name f-w-600">
                          {firstname + " " + lastname}
                        </div>
                        <div className="status">
                          {/* {currentUser.status} */}
                        </div>
                      </div>
                    </div>
                    <div className="people-list">
                      <div className="search">
                        <Form className="theme-form">
                          <FormGroup className="form-group">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Rechercher"
                              defaultValue={searchKeyword}
                              onChange={(e) =>
                                handleSearchKeyword(e.target.value)
                              }
                            />
                            <i className="fa fa-search"></i>
                          </FormGroup>
                        </Form>
                      </div>
                      {allMessages.length > 0 ? (
                        <ul className="list">
                          {allMessages
                            .sort(
                              (a, b) => a.lastMessageTime < b.lastMessageTime
                            )
                            .map((item, i) => {
                              return (
                                <li
                                  style={{
                                    background:
                                      item.id === selectedConvMessage
                                        ? "white"
                                        : "",
                                    opacity:
                                      item.id === selectedConvMessage
                                        ? "100%"
                                        : "50%",
                                  }}
                                  className={`clearfix`}
                                  key={i}
                                  onClick={(e) => {
                                    setselectedUserConv(item.users);
                                    setselectedUserConvInfo(item.usersInfos);
                                    changeConvMessageClick(e, item.id, item);
                                  }}
                                >
                                  <img
                                    src={
                                      item.usersInfos.length > 2
                                        ? item.usersInfos[0]?.avatar
                                        : item.usersInfos.find(
                                            (res) => res.id !== idUser
                                          )?.avatar
                                    }
                                    className="rounded-circle user-image"
                                    alt=""
                                  />

                                  <div
                                    className={`status-circle ${
                                      true ? "online" : "offline"
                                    }`}
                                  ></div>
                                  <div className="about">
                                    <div className="name">
                                      {item.users.length > 2
                                        ? item.groupeName
                                        : item.usersInfos.find(
                                            (res) => res.id !== idUser
                                          )?.fullname}
                                    </div>
                                    <div className="status">
                                      <p>
                                        {item.lastmessage !== undefined
                                          ? item.lastmessage
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="call-chat-body">
            <Card>
              <CardBody className="p-0">
                <Row className="chat-box">
                  <Col className="pr-0 chat-right-aside">
                    <div className="chat">
                      <div className="chat-header clearfix">
                        {/* <Media src={selectedUser.avatar} className="rounded-circle" alt="" />  */}
                        <div className="about">
                          <div className="name">
                            {/* {selectedUser.fullname}  */}
                          </div>
                          <div className="status digits">
                            {/* {selectedUser.online ? 'online' : selectedUser.lastSeenDate} */}
                          </div>
                        </div>
                        <ul className="list-inline float-left float-sm-right chat-menu-icons">
                          <li className="list-inline-item">
                            <a onClick={() => addUserToggle()}>
                              <i className="icon-plus"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              onClick={() =>
                                RemoveConversations(selectedConvMessage)
                              }
                            >
                              <i className="icon-trash"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a onClick={() => AddFilesStatetoggle()}>
                              <i className="icon-clip"></i>
                            </a>
                          </li>
                          {/* <li className="list-inline-item"><a href="#javascript"><i className="icon-headphone-alt"></i></a></li>
                                <li className="list-inline-item"><a href="#javascript"><i className="icon-video-camera"></i></a></li>
                                <li className="list-inline-item toogle-bar" onClick={() => chatMenuToggle()}><a href="#javascript"><i className="icon-menu"></i></a></li>  */}
                        </ul>
                      </div>
                      <div className="chat-history chat-msg-box">
                        <Card className="chat-default">
                          <CardBody
                            className="chat-box custom-scrollbar"
                            style={{ overflow: "auto", height: "auto" }}
                          >
                            <div className="chat custom-scrollbar">
                              {chats.length > 0 ? (
                                chats.map((item, index) => {
                                  //const participators = allMessages.find(x => x.id === selectedChat);
                                  return (
                                    <>
                                      {item.sender !== idUser ? (
                                        <>
                                          {item.message.length > 0 ? (
                                            <div className="media left-side-chat">
                                              <div className="media-body d-flex">
                                                <div className="img-profile">
                                                  {" "}
                                                  <img 
                                                    className="rounded-circle  user-image"
                                                    src={item.senderUserImage}
                                                    alt="Profile"
                                                  />
                                                </div>
                                                <div className="main-chat">
                                                  <div className="message-main">
                                                    <span className="mb-1">
                                                      {item.message}
                                                    </span>
                                                  </div>
                                                  {/* <div className="sub-message message-main">
                                                    <span className="mb-0">
                                                      {item.message}
                                                    </span>
                                                  </div> */}
                                                </div>
                                              </div>
                                              <p className="f-w-400">
                                                {item.time}
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {item.files?.length > 0 ? (
                                            item.files?.map((data, i) => {
                                              return (
                                                <div
                                                onClick={() => {
                                                  setSelectedFile(data)
                                                  showApercuToggle()
                                                }}
                                                  onContextMenu={(e) => {
                                                    e.preventDefault();

                                                    if (e.button === 2) {
                                                      setselecterSenderId(
                                                        item.sender
                                                      );

                                                      setselectedMessageID(
                                                        item.id
                                                      );
                                                      setclientX(e.clientX);
                                                      setClientY(e.clientY);
                                                      manegeMessageToggle();
                                                    }
                                                  }}
                                                  key={index}
                                                  className="media left-side-chat"
                                                >
                                                  <div className="media-body d-flex">
                                                    {data.type.includes(
                                                      "pdf"
                                                    ) === true ||
                                                    data.type.includes(
                                                      "image"
                                                    ) === true ? (
                                                      <img
                                                        style={{
                                                          width: "200px",
                                                        }}
                                                        height="200"
                                                        src={data.url}
                                                      />
                                                    ) : data.type.includes(
                                                        "zip"
                                                      ) ? (
                                                      <i className="fa fa-file-archive-o txt-primary"></i>
                                                    ) : (
                                                      <i className="fa fa-file-word-o txt-primary"></i>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.message.length > 0 ? (
                                            <div
                                              // onContextMenu={(e) => {
                                              //   e.preventDefault();

                                              //   if (e.button === 2) {
                                              //     setselecterSenderId(
                                              //       item.sender
                                              //     );

                                              //     setselectedMessageID(item.id);
                                              //     setclientX(e.clientX);
                                              //     setClientY(e.clientY);
                                              //     manegeMessageToggle();
                                              //   }
                                              // }}
                                              key={index}
                                              className="media right-side-chat"
                                            >
                                              <p className="f-w-400">
                                              <MoreVertical onClick={(e) => { 
                                                  setselecterSenderId(
                                                    item.sender
                                                  );

                                                  setselectedMessageID(item.id);
                                                  setclientX(e.clientX);
                                                  setClientY(e.clientY);
                                                  manegeMessageToggle();
                                                
                                              }} size={10}></MoreVertical>
                                                {item.time}
                                                
                                              </p>
                                              <div className="media-body text-right">
                                                <div className="message-main pull-right">
                                                  <span
                                                    className="mb-0 text-left"
                                                    style={{ opacity: "100%" }}
                                                  >
                                                    {item.message}
                                                  </span>
                                                  <div className="clearfix"></div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {item.files?.length > 0 ? (
                                            item.files?.map((data, i) => {
                                              return (
                                                <div
                                                onClick={() => {
                                                  setSelectedFile(data)
                                                  showApercuToggle()
                                                }}
                                                  onContextMenu={(e) => {
                                                    e.preventDefault();

                                                    if (e.button === 2) {
                                                      setselecterSenderId(
                                                        item.sender
                                                      );

                                                      setselectedMessageID(
                                                        item.id
                                                      );
                                                      setclientX(e.clientX);
                                                      setClientY(e.clientY);
                                                      manegeMessageToggle();
                                                    }
                                                  }}
                                                  key={index}
                                                  className="media right-side-chat"
                                                >
                                                  <div className="media-body text-right">
                                                    {data.type.includes(
                                                      "pdf"
                                                    ) === true ||
                                                    data.type.includes(
                                                      "image"
                                                    ) === true ? (
                                                      <img
                                                        style={{
                                                          width: "200px",
                                                        }}
                                                        height="200"
                                                        src={data.url}
                                                      />
                                                    ) : data.type.includes(
                                                        "zip"
                                                      ) ? (
                                                      <i className="fa fa-file-archive-o txt-primary"></i>
                                                    ) : (
                                                      <i className="fa fa-file-word-o txt-primary"></i>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                    //                                       <>
                                    //                                         <li onContextMenu={e => {

                                    //                                             e.preventDefault();

                                    //                                   if(e.button === 2){
                                    //                                     setselecterSenderId(item.sender)

                                    //                                   setselectedMessageID(item.id)
                                    //                                     setclientX(e.clientX)
                                    //                                     setClientY(e.clientY)
                                    //                                     manegeMessageToggle()

                                    //                                   }}}  key={index} className="clearfix" >

                                    //                                             <div style={{background : item.sender !== idUser ? '' : '#f73164'}} className={`message my-message ${item.sender !== idUser ? '' : 'float-right'}`}>

                                    //                                              <Media  src={item.senderUserImage}
                                    //                                                     className={`rounded-circle ${item.sender !== idUser ? 'float-left' : 'float-right primary'} chat-user-img img-30`} alt="" />
                                    //                                                 <div className="message-data text-right">
                                    //                                                     <span className="message-data-time">{item.time}</span>
                                    //                                                 </div>
                                    //                                                 {item.message}

                                    // </div>

                                    //                                         </li>
                                    //                                         {item.files?.length > 0 ?
                                    //                                         item.files?.map((data,i)=>{
                                    //                                           return(
                                    //                                         <li onContextMenu={e => {

                                    //          e.preventDefault();

                                    // if(e.button === 2){
                                    //  setselecterSenderId(item.sender)

                                    // setselectedMessageID(item.id)
                                    //  setclientX(e.clientX)
                                    //  setClientY(e.clientY)
                                    //  manegeMessageToggle()

                                    // }}}  key={index} className="clearfix" >

                                    //          <div style={{background : item.sender !== idUser ? '' : '#f73164',padding : 5}} className={`message my-message ${item.sender !== idUser ? '' : 'float-right'}`}>

                                    //           <Media  src={item.senderUserImage}
                                    //                  className={`rounded-circle ${item.sender !== idUser ? 'float-left' : 'float-right primary'} chat-user-img img-30`} alt="" />
                                    // { data.type.includes("pdf")=== true || data.type.includes("image")=== true ? <img   style={{width : '100%'}} height="200" src={data.url} /> : data.type.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}
                                    // </div>

                                    //      </li> )
                                    // })
                                    // : <></> } </>
                                  );
                                })
                              ) : loading === true ? (
                                <div>
                                  <div className="loader-box">
                                    <div className="loader-2"></div>
                                  </div>
                                </div>
                              ) : (
                                <div class="chat-history chat-msg-box custom-scrollbar">
                                  <ul>
                                    <div>
                                      <img
                                        src={start_conversion}
                                        alt="Nouvelle conversation "
                                        class="img-fluid media"
                                      />
                                    </div>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      {selectedConvMessage !== "" ? (
                        <div className="chat-message clearfix">
                          <Row>
                            <div className="mb-2">
                              {showEmojiPicker ? (
                                <Picker
                                  set="apple"
                                  emojiSize={30}
                                  onSelect={addEmoji}
                                />
                              ) : null}
                            </div>
                            <Col xl="12" className="d-flex">
                              <div className="smiley-box bg-primary">
                                <div
                                  className="picker"
                                  onClick={() => toggleEmojiPicker()}
                                >
                                  <Media
                                    width="30"
                                    height="30"
                                    src={emoji}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <InputGroup className="text-box">
                                <Input
                                  type="text"
                                  className="form-control input-txt-bx"
                                  placeholder="Ecrire message......"
                                  value={messageInput}
                                  onKeyPress={(e) => handleMessagePress(e)}
                                  onChange={(e) =>
                                    handleMessageChange(e.target.value)
                                  }
                                />

                                <InputGroupAddon addonType="append">
                                  <Button
                                    color="primary"
                                    onClick={() => handleMessagePress("send")}
                                  >
                                    {Send}
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ul className="files">
                                {myfilee.map((data, i) => {
                                  return (
                                    <li
                                      onDoubleClick={() => {
                                        //   setselectedImage(data)
                                        //  showApercuToggle()
                                      }}
                                      className="file-box"
                                      key={i}
                                    >
                                      <div className="file-top">
                                        {data.type.includes("pdf") === true ||
                                        data.type.includes("image") === true ? (
                                          <img
                                            width="70"
                                            height="70"
                                            src={data.url}
                                          />
                                        ) : data.type.includes("zip") ? (
                                          <i className="fa fa-file-archive-o txt-primary"></i>
                                        ) : (
                                          <i className="fa fa-file-word-o txt-primary"></i>
                                        )}
                                      </div>
                                      <div className="file-bottom">
                                        <i
                                          className="fa fa-trash"
                                          onClick={() => removefile(data.name)}
                                        ></i>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  {/* <Col className={`pl-0 chat-menu ${menuToggle ? 'show' : ''}`}>
                            <Nav tabs className="nav  border-tab nav-primary">
                                {/* <NavItem  id="myTab" role="tablist">
                                    <NavLink tag="a" href="#javascript" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                        {CALL}
                                    </NavLink>
                                </NavItem>
                                <NavItem  id="myTab" role="tablist">
                                    <NavLink tag="a" href="#javascript" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                        {STATUS}
                                    </NavLink>
                                </NavItem> 
                               <NavItem  id="myTab" role="tablist">
                                    <NavLink tag="a" href="#javascript" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                        {PROFILE}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="people-list">
                                        <ul className="list digits custom-scrollbar">
                                            <li className="clearfix"><Media className="rounded-circle user-image" src={four} alt="" />
                                                <div className="about">
                                                    <div className="name">{EricaHughes}</div>
                                                    <div className="status"><i className="fa fa-share font-success"></i>  {"5 May, 4:40 PM"}</div>
                                                </div>
                                            </li>
                                            <li className="clearfix"><Media className="rounded-circle user-image mt-0" src={one} alt="" />
                                                <div className="about">
                                                    <div className="name">{VincentPorter}
                                                        <div className="status">
                                                        <i className="fa fa-reply font-danger"></i>  {"5 May, 5:30 PM"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* {allMembers.chats.filter(x => x.id !== currentUser.id).map((member, i) =>
                                                <li className="clearfix" key={i} onClick={(e) => changeChatClick(e, member.id)}>
                                                    <Media src={dynamicImage(member.thumb)} className="rounded-circle user-image" alt="" />
                                                    <div className="about">
                                                        <div className="name">{member.name}fff</div>
                                                        <div className="status">
                                                            {member.reply}
                                                            {member.status}
                                                        </div>
                                                    </div>
                                                </li>
                                            )} 
                                        </ul>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="people-list">
                                        <div className="search">
                                            <Form className="theme-form">
                                                <FormGroup>
                                                    <Input className="form-control" type="text" placeholder="Write Status..." /><i className="fa fa-pencil"></i>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="status">
                                        <p className="font-dark">{Active}</p>
                                        <hr />
                                        <p>
                                            {ChataApp_p1}
                                            <i className="icofont icofont-emo-heart-eyes font-danger f-20"></i>
                                            <i className="icofont icofont-emo-heart-eyes font-danger f-20 m-l-5"></i>
                                        </p>
                                        <hr />
                                        <p>{ChataApp_p2}<i className="icofont icofont-emo-rolling-eyes font-success f-20"></i></p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <div className="user-profile">
                                        <div className="image">
                                            <div className="avatar text-center"><Media body alt="" src={two} /></div>
                                            <div className="icon-wrapper"><i className="icofont icofont-pencil-alt-5"></i></div>
                                        </div>
                                        <div className="user-content text-center">
                                            <h5 className="text-uppercase">{MarkJecno}</h5>
                                            <div className="social-media">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                                                </ul>
                                            </div>
                                            <hr />
                                            <div className="follow text-center">
                                                <Row>
                                                    <Col className="border-right"><span>{Following}</span>
                                                        <div className="follow-num">{"236k"}</div>
                                                    </Col>
                                                    <Col><span>{Follower}</span>
                                                        <div className="follow-num">{"3691k"}</div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr />
                                            <div className="text-center digits">
                                                <p className="mb-0">{"Mark.jecno23@gmail.com"}</p>
                                                <p className="mb-0">{"+91 365 - 658 - 1236"}</p>
                                                <p className="mb-0">{"Fax: 123-4560"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={convModal} toggle={convToggle} size="lg">
          <ModalHeader toggle={convToggle}>Recherche utilisateur</ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleMessageConvPress("send");
              }}
              className="form-bookmark needs-validation"
            >
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Input
                    className="form-control"
                    value={searchvalue}
                    onChange={(e) => searchUser(e.target.value)}
                    name="title"
                    type="text"
                    placeholder="Rechercher utilisateur"
                    autoComplete="off"
                  />
                </FormGroup>
                {selectedUserConv.length > 2 ? (
                  <FormGroup className="col-md-12">
                    <Label>{"Nom Groupe"}</Label>
                    <Input
                      className="form-control"
                      value={groupeName}
                      onChange={(e) => setGroupeName(e.target.value)}
                      name="title"
                      type="text"
                      autoComplete="off"
                    />
                  </FormGroup>
                ) : (
                  <></>
                )}
                <FormGroup className="col-md-12">
                  {users.length > 0 ? (
                    <div className="people-list">
                      <ul className="list digits custom-scrollbar">
                      
                        {users
                          .filter(
                            (x) =>
                              (x.email.includes(searchvalue) == true ||
                                x.first_name.includes(searchvalue) == true ||
                                x.last_name.includes(searchvalue) == true) &&
                              x.user_id !== idUser
                          )
                          .map((item, i) => {
                            return (
                              <li
                                onClick={() =>
                                  addToSelectedListe(
                                    item.user_id,
                                    item.first_name + " " + item.last_name,
                                    item.image,
                                    "Users"
                                  )
                                }
                                className="clearfix"
                              >
                                <Row>
                                  <Col sm="1" lg="1">
                                    <Media
                                      className="rounded-circle user-image"
                                      style={{
                                        float: "left",
                                        width: "52px",
                                        height: "52px",
                                        marginRight: "5px",
                                      }}
                                      src={item.image}
                                      alt=""
                                    />
                                  </Col>
                                  <Col sm="10" lg="10">
                                    <div>
                                      <div className="name">
                                        {item.first_name + " " + item.last_name}
                                        ({item.role === "manager" ? "Marque" : item.role === "submanager" ? "Franchise" :item.role === "Fournisseur" ? "Fournisseur" : item.role === "admin" ?"Administrateur" : "autre"})
                                      </div>
                                      <div className="status">
                                        {" "}
                                        {item.email}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="1" lg="1">
                                    <div className="checkbox checkbox-primary mr-1">
                                      <Input
                                        id={i}
                                        onClick={() =>
                                          addToSelectedListe(
                                            item.user_id,
                                            item.first_name +
                                              " " +
                                              item.last_name,
                                            item.image,
                                            "Users"
                                          )
                                        }
                                        type="checkbox"
                                        checked={
                                          selectedUserConv.includes(item.user_id) ==
                                          true
                                        }
                                        name={item.email}
                                        value={item.email}
                                      />
                                      <Label for={i}></Label>
                                    </div>
                                  </Col>
                                </Row>
                              </li>
                            );
                          })}
                    
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </div>
              <div className="text-right">
                <Button color="secondary" className="mr-1" onClick={convToggle}>
                  {Cancel}
                </Button>
                <Button type="submit" color="primary">
                  {"Ajouter message"}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        <Modal isOpen={addUserModal} toggle={addUserToggle} size="lg">
          <ModalHeader toggle={addUserToggle}>
            Recherche utilisateur
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleMessageaddUserPress("send");
              }}
              className="form-bookmark needs-validation"
            >
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Label>{"Rechercher Utilisateur"}</Label>
                  <Input
                    className="form-control"
                    value={searchvalue}
                    onChange={(e) => searchUser(e.target.value)}
                    name="title"
                    type="text"
                    autoComplete="off"
                  />
                </FormGroup>
                {selectedUserConv.length > 2 ? (
                  <FormGroup className="col-md-12">
                    <Label>{"Nom Groupe"}</Label>
                    <Input
                      className="form-control"
                      value={groupeName}
                      onChange={(e) => setGroupeName(e.target.value)}
                      name="title"
                      type="text"
                      autoComplete="off"
                    />
                  </FormGroup>
                ) : (
                  <></>
                )}
                <FormGroup className="col-md-12">
                  {users.length > 0 ? (
                    <div className="people-list">
                      <ul className="list digits custom-scrollbar">
                  
                        {users
                          .filter(
                            (x) =>
                              (x.email.includes(searchvalue) == true ||
                                x.first_name.includes(searchvalue) == true ||
                                x.last_name.includes(searchvalue) == true) &&
                              x.user_id !== idUser
                          )
                          .map((item, i) => {
                            return (
                              <li
                                onClick={() =>
                                  addToSelectedListe(
                                    item.user_id,
                                    item.first_name + " " + item.last_name,
                                    item.image,
                                    "Users"
                                  )
                                }
                                className="clearfix"
                              >
                                <Row>
                                  <Col sm="1" lg="1">
                                    <Media
                                      className="rounded-circle user-image"
                                      style={{
                                        float: "left",
                                        width: "52px",
                                        height: "52px",
                                        marginRight: "5px",
                                      }}
                                      src={item.image}
                                      alt=""
                                    />
                                  </Col>
                                  <Col sm="10" lg="10">
                                    <div>
                                      <div className="name">
                                        {item.first_name + " " + item.last_name}
                                      </div>
                                      <div className="status">
                                        {" "}
                                        {item.email}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="1" lg="1">
                                    <div className="checkbox checkbox-primary mr-1">
                                      <Input
                                        id={i}
                                        onClick={() =>
                                          addToSelectedListe(
                                            item.user_id,
                                            item.first_name +
                                              " " +
                                              item.last_name,
                                            item.image,
                                            "Users"
                                          )
                                        }
                                        type="checkbox"
                                        checked={
                                          selectedUserConv.includes(item.user_id) ==
                                          true
                                        }
                                        name={item.email}
                                        value={item.email}
                                      />
                                      <Label for={i}></Label>
                                    </div>
                                  </Col>
                                </Row>
                              </li>
                            );
                          })}
                
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
        <Modal
          style={{ marginTop: clientY, marginLeft: clientX }}
          backdrop={true}
          isOpen={manegeMessageModal}
          toggle={manegeMessageToggle}
          size="sm"
        >
          <ModalBody>
            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                    {/* <li>
                      <Link hidden={selecterSenderId !== idUser}  onClick={() => {manegeMessageToggle();}}  className="btn btn-light">{"Modifier"}</Link>
                    </li> */}
                    {/* <li>
                      <Link   className="btn btn-light" onClick={() => {

                          var httpsReference = firestorage.refFromURL(selectedImage.fileImage);  
                      httpsReference.getDownloadURL().then(res => alert(res))
                           
                      
                        }}><Share />{"Obtenir Le lien"}</Link>
                    </li> */}

                    <li>
                      <Link
                        onClick={() => {
                          manegeMessageToggle();
                          RemoveMessages(
                            selectedConvMessage,
                            selectedMessageID
                          );
                        }}
                        className="btn btn-light"
                      >
                        {"Supprimer"}
                      </Link>
                    </li>
                  </ul>
                  <hr />
                </CardBody>
              </Card>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={AddFilesState} toggle={AddFilesStatetoggle}>
          <ModalHeader toggle={AddFilesStatetoggle}>{Newfiles}</ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <Form>
                  <div className="dz-message needsclick">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      submitButtonDisabled={true}
                      //onSubmit={handleSubmit}
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              <Button
                color="secondary"
                className="mr-1"
                onClick={AddFilesStatetoggle}
              >
                {Close}
              </Button>
              <Button color="primary" onClick={AddFilesStatetoggle}>
                {AddFile}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showApercu} toggle={showApercuToggle} size="lg">
                            <ModalHeader toggle={showApercuToggle}>Apercu Image</ModalHeader>
                            <ModalBody>
                              <div>
                                {selectedFile.type?.includes("image") === true ?
                                 <img style={{width : '100%', height: '100%'}} src={selectedFile.url}/> 
                                : 
                                <img style={{width : '100%', height: '100%'}} src={selectedFile.url}/> 

                                }

</div>

                   
                            </ModalBody>
                            <ModalFooter>
                            <Button color="secondary" onClick={showApercuToggle}>{Cancel}</Button>
                            </ModalFooter>
                          </Modal>

      </Container>
    </Fragment>
  ) : (
    <div className="loading"></div>
  );
};

export default Chat;
