import { db } from '../../data/config';
import {ADD_SOUS_COMMENT,ADD_COMMENT,ADD_NEW_TACHE,ADD_NEW_PROJECT,ADD_NEW_SOUS_TACHE,REMOVE_PROJET, UPDATE_PROJET, REMOVE_TACHE, UPADTE_TACHE, REMOVE_SOUS_TACHE} from '../actionTypes';


export const addNSousTache =  (data,idprojet,idtache) => {
      db.collection("Projects").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").add(data).then(res => {
        data.id=res.id
        // db.collection("Projects").doc(idprojet).get().then(res => {
        //   let tacheafaire=  res.data().numberTacheAfaire
        //   let tacheaencours=  res.data().numberTacheEnCours
        //   let tacheaenTermine=  res.data().numberTacheTermine
        //   db.collection("Projects").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) + 1})
        // })
      
       
    })

        return  {
            type : ADD_NEW_SOUS_TACHE,
            payload : {data,idprojet,idtache}
        }

   
}

export const removeSousTaches = (idprojet,id,idtache,status) => {
     db.collection("Projects").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").doc(id).delete().then(res2 => {
       
     
       
    })
    return {
        type : REMOVE_SOUS_TACHE,
        payload : {idprojet,id,status,idtache}
    }
}
export const removeProjets = (idprojet) => {
    db.collection("Projects").doc(idprojet).delete().then(res => {
     
        // db.collection("Projects").doc(idprojet).get().then(res => {
        //   let tacheafaire=  res.data().numberTacheAfaire
        //   let tacheaencours=  res.data().numberTacheEnCours
        //   let tacheaenTermine=  res.data().numberTacheTermine
        //   db.collection("Projects").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) + 1})
        // })
        return{
            type:REMOVE_PROJET,
            payload: {idprojet}
        }
    })
}

export const sendCommentSousTache = (idprojet,idsoustache, idtache, data) => {
    db.collection("Projects").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").doc(idsoustache).get().then(res => {
     let comment = res.data().comment
     comment.push(data)
     db.collection("Projects").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").doc(idsoustache).update({comment : comment})
        // db.collection("Projects").doc(idprojet).get().then(res => {
        //   let tacheafaire=  res.data().numberTacheAfaire
        //   let tacheaencours=  res.data().numberTacheEnCours
        //   let tacheaenTermine=  res.data().numberTacheTermine
        //   db.collection("Projects").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) + 1})
        // })
       
    })
    return{
        type:ADD_SOUS_COMMENT,
        payload: {idprojet, idtache, data}
    }
}
export const sendCommentTache = (idprojet, idtache, data) => {
    db.collection("Projects").doc(idprojet).collection("Taches").doc(idtache).get().then(res => {
     let comment = res.data().comment
     comment.push(data)
     db.collection("Projects").doc(idprojet).collection("Taches").doc(idtache).update({comment : comment})
        // db.collection("Projects").doc(idprojet).get().then(res => {
        //   let tacheafaire=  res.data().numberTacheAfaire
        //   let tacheaencours=  res.data().numberTacheEnCours
        //   let tacheaenTermine=  res.data().numberTacheTermine
        //   db.collection("Projects").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) + 1})
        // })
       
    })
    return{
        type:ADD_COMMENT,
        payload: {idprojet, idtache, data}
    }
}
export const removeTaches = async  (idprojet,id, status) => {
    await db.collection("Projects").doc(idprojet).collection("Taches").doc(id).delete().then(res2 => {
       
        db.collection("Projects").doc(idprojet).get().then(res => {
            if(status === "En Cours"){
          
                let tacheaencours=  res.data().numberTacheEnCours
            
                db.collection("Projects").doc(idprojet).update({numberTacheEnCours : Number(tacheaencours) - 1})
            }
            else if(status === "A Faire"){
                let tacheafaire=  res.data().numberTacheAfaire
               
                db.collection("Projects").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) - 1})
            }
            else if(status === "Terminé"){
             
                let tacheaenTermine=  res.data().numberTacheTermine
                db.collection("Projects").doc(idprojet).update({numberTacheTermine : Number(tacheaenTermine) - 1}) 
            }
       
        })
       
    })
    return {
        type : REMOVE_TACHE,
        payload : {idprojet,id,status}
    }
}

export const updateSousTache = (data,idprojet, idtache,id) => {
    db.collection("Projects").doc(idprojet).collection("Taches").doc(idtache).collection("SousTaches").doc(id).update(data).then(res => {
      
      
    })
    
}
export const updateTache = (data,idprojet, id) => {
    db.collection("Projects").doc(idprojet).collection("Taches").doc(id).update(data).then(res => {
      
        return{
            type:UPADTE_TACHE,
            payload: {data,idprojet, id}
        }
    })
}
export const addNTache = (data,idprojet) => {
    db.collection("Projects").doc(idprojet).collection("Taches").add(data).then(res => {
        data.id=res.id
        db.collection("Projects").doc(idprojet).get().then(res => {
          let tacheafaire=  res.data().numberTacheAfaire
          let tacheaencours=  res.data().numberTacheEnCours
          let tacheaenTermine=  res.data().numberTacheTermine
          db.collection("Projects").doc(idprojet).update({numberTacheAfaire : Number(tacheafaire) + 1})
        })
        return{
            type:ADD_NEW_TACHE,
            payload: {data}
        }
    })
}

export const updateProject = (data,id) => {
    db.collection("Projects").doc(id).update(data).then(res => {
        data.id=res.id
        
    })
    return{
        type:UPDATE_PROJET,
        payload: {data,id}
    }
}
export const addNProject = (data) => {
    db.collection("Projects").add(data).then(res => {
        data.id=res.id
        return{
            type:ADD_NEW_PROJECT,
            payload: {data}
        }
    })
}
    