import {call,put,takeLatest} from "redux-saga/effects";
import {fetchEmailApi, fetchEmailsApi, fetchemailsApi, fetchsendemailsApi, fetchtrashemailsApi} from '../../api'
import {WATCH_EMAIL,WATCH_ALL_TYPE_EMAIL,GET_ALL_EMAIL_ASYN,GET_ALL_TYPE_ASYN, WATCH_EMAIL_SEND, WATCH_EMAIL_TRASH, WATCH_ALL_EMAIL_ASYN} from '../../redux/actionTypes'
import {getAllEmails,getAllTypes,getAllTrashEmails,getAllSendEmails} from '../../redux/email/action'


function* fetchEmailAsyn() {
    const emailData = yield call(fetchemailsApi);
  
    yield put(getAllEmails(emailData.data));
}

function* fetchSendEmailAsyn() {
    const emailData = yield call(fetchsendemailsApi);
  
    yield put(getAllSendEmails(emailData.data));
}

function* fetchTrashEmailAsyn() {
    const emailData = yield call(fetchtrashemailsApi);
  
    yield put(getAllTrashEmails(emailData.data));
}
function* fetchgetAllTypesAsyn({result}) {
    yield put(getAllTypes(result)); 
}

function* getAllEmailsAsyn() {
    const Data = yield call(fetchEmailsApi);
    yield put({type : GET_ALL_EMAIL_ASYN,email : Data});
}

function* getAllTypesAsyn({result}) {
    yield put(getAllTypes(result));
}


export function* WatcherEmailApp() {
    yield takeLatest(WATCH_EMAIL,fetchEmailAsyn)
    yield takeLatest(WATCH_EMAIL_SEND,fetchSendEmailAsyn)
    yield takeLatest(WATCH_EMAIL_TRASH,fetchTrashEmailAsyn)
    yield takeLatest(WATCH_ALL_TYPE_EMAIL,fetchgetAllTypesAsyn)
    yield takeLatest(WATCH_ALL_EMAIL_ASYN,getAllEmailsAsyn)
    yield takeLatest(GET_ALL_TYPE_ASYN,getAllTypesAsyn)
}

