import {call,put, takeLatest, take} from "redux-saga/effects";
import { fetchFileApi, fetchFilesApi, fetchFilesByFolderApi, fetchFilesByIdUserApi, fetchFilesDeletedByIdUserApi, fetchFilesFavorisByIdUserApi, fetchFilesShareByIdUserApi, fetchSubFolderApi } from "../../api";
import { GET_FILES_LIST,GET_FILE_LIST_BY_ID_USER, GET_FILE_LIST, GET_FOLDER_LIST,GET_FOLDER_LIST_BY_ID,WATCH_FILES_LIST,WATCH_FILE_LIST,WATCH_FOLDER_LIST, WATCH_SUB_FOLDER_LIST, WATCH_FILE_LIST_BY_ID_USER, WATCH_FAVORIS_FILES_LIST, WATCH_SHARE_FILE_LIST, WATCH_DELETED_FILES_LIST } from "../actionTypes";

function* fetchFileAsyn({id}) {
    const Data = yield call(fetchFilesByFolderApi,id);
    console.log(Data)
    yield put({type : GET_FILES_LIST,files: Data});
}
function* fetchFileByIdUserAsyn({id}) {
    const Data = yield call(fetchFilesByIdUserApi,id);
    console.log(Data)
    yield put({type : GET_FILE_LIST_BY_ID_USER,files:Data});
}
function* fetchFileFavorisByIdUserAsyn() {
    const Data = yield call(fetchFilesFavorisByIdUserApi);

    yield put({type : GET_FILE_LIST,files:Data});
}
function* fetchFileDeletedByIdUserAsyn() {
    const Data = yield call(fetchFilesDeletedByIdUserApi);

    yield put({type : GET_FILE_LIST,files: Data});
}
function* fetchFileShareByIdUserAsyn() {
    const Data = yield call(fetchFilesShareByIdUserApi);

    yield put({type : GET_FILE_LIST,files:Data});
}
export function* watchFileList() {
    
    yield takeLatest(WATCH_FILE_LIST,fetchFileAsyn)
    yield takeLatest(WATCH_FILE_LIST_BY_ID_USER,fetchFileByIdUserAsyn)
    yield takeLatest(WATCH_FAVORIS_FILES_LIST,fetchFileFavorisByIdUserAsyn)
    yield takeLatest(WATCH_DELETED_FILES_LIST,fetchFileDeletedByIdUserAsyn)
    yield takeLatest(WATCH_SHARE_FILE_LIST,fetchFileShareByIdUserAsyn)
}

function* fetchFileMonDriveAsyn() {
    const Data = yield call(fetchFilesApi);
    console.log(Data)
    yield put({type : GET_FILE_LIST,files:Data});
}

export function* watchFileMonDriveList() {
    
    yield takeLatest(WATCH_FILES_LIST,fetchFileMonDriveAsyn)
}
