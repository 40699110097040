import React, { Fragment, useEffect, useState ,useRef} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Dropzone from "react-dropzone-uploader";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  ProjectTitle,
  ClientName,
  ProjectRate,
  ProjectStatus,
  ProgressLevel,
  ProjectSize,
  Small,
  Medium,
  Big,
  StartingDate,
  EndingDate,
  EnterSomeDetails,
  UploadProjectFile,
  Add,
  Cancel,
  Done,
  Doing, Close, SaveChanges
} from "../../constant";
import {
  WATCH_FRANCHISE_LIST,
  WATCH_SUBFRANCHISE_LIST,
  WATCH_USER_LIST,
} from "../../redux/actionTypes";
import { firestorage } from "../../data/config";
import { toast } from "react-toastify";
import moment from "moment";
import { addNProject } from "../../redux/projectdev/action";
import { Typeahead } from "react-bootstrap-typeahead";
import { addNProcess } from "../../redux/processdemande/action";


const Addprocess = (props) => {
  const {idFranchise} = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [processType, setprocessType] = useState("");
  const ref = useRef(null);
  const [emaildestination, setemaildestination] = useState([]);
  const [franchise, setFranchise] = useState("");
  const [random, setrandom] = useState(0)
  const [myfilee, setMyFile] = useState([])
  const [marque, setMarque] = useState("");
  const franchises = useSelector((content) => content.Franchiseapp.franchises);
  const subfranchises = useSelector(
    (content) => content.SubFranchiseapp.subfranchises
  );
  console.log(franchises);
  useEffect(() => {
    dispatch({ type: WATCH_FRANCHISE_LIST });
  }, [dispatch]);

  const handleStartDate = (date) => {
    setstartDate(date);
  };

  const handleEndDate = (date) => {
    setendDate(date);
  };

  const getUploadParams = ({ meta, file }) => {

       
    
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/projects/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/projects`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;

      
        myfils.push({
      url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          console.log(myfils)
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
if (status === "removed"){
let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
setMyFile(myfileeCopy)
}
  }

  const users = useSelector(content => content.Userapp.users);
  useEffect(() => {
  
    dispatch({type: WATCH_USER_LIST})
  },[])
      const [selectedUser, setSelectedUser] = useState({})
      const [scrollingmodal, setscrollingModal] = useState(false);
      const Scrollmodaltoggle = () => setscrollingModal(!scrollingmodal);

  const AddProcess = (data) => {

    if (data !== "" ) {
    
data.processType =  processType    
data.marqueID = idFranchise
data.tachenumber = 0
data.subTachenumber = 0

data.createdAt = moment(new Date()).format('yyyy-MM-DD HH:mm')

props.history.push(`${process.env.PUBLIC_URL}/app/process/process-liste/${idFranchise}`);
      dispatch(addNProcess(data,idFranchise));
     
    } else {
      errors.showMessages();
    }
  };

 
  return (
    <Fragment>
      <Breadcrumb parent="Processus" title="Créer processus" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(AddProcess)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Titre processus *"}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="Titre processus *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Titre processus est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Choisir Type"}</Label>
                        <select
                          onChange={(e) => {
                            let id = e.target.value;
                            
setprocessType(id)
                           
                          }}
                          className="form-control digits"
                        >
                         
                            <option value="">
                              Choisir Type processus
                            </option>
                            <option value="demande">
                             demande enseigne
                            </option>
                     
                        </select>
                      </FormGroup>
                    </Col>
                  
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Description Processus"}</Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          name="description"
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Description processus est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                 
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                          to={`${process.env.PUBLIC_URL}/app/project/project-list`}
                        >
                          <Button className="mr-3" color="secondary">{Cancel}</Button>
                        </Link>
                        <Button color="primary" >
                          {Add}
                        </Button>
                       
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default withRouter(Addprocess);
