import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import Timeline1 from './timeline1';
import VerticalTimelineComp from './timeLineActualite';
import {Container,Row,Col,Card,CardHeader,CardBody, Form} from 'reactstrap'
import {ActualitésListe, AddNewActualite, } from "../../../constant";

import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { WATCH_NEWS_LIST,WATCH_NEWS_BY_MARQUE_LIST, WATCH_NEWS_BY_FOURNISSEUR_LIST } from '../../../redux/actionTypes';

const Actualités = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [activeTab,setActiveTab] = useState("1")
    const news = useSelector(content => content.Newsapp.news);
    //const doingProject = useSelector(content => content.Franchiseapp.doing_Project);
    //const doneProject = useSelector(content => content.Franchiseapp.done_Project);
   
    useEffect(() => {
        if( localStorage.getItem("@intranetTypeUser") === 'admin')
      dispatch({type: WATCH_NEWS_LIST})
      else if (localStorage.getItem("@intranetTypeUser") === 'Fournisseur')
      dispatch({type: WATCH_NEWS_BY_FOURNISSEUR_LIST})
      
      else {
          
      dispatch({type : WATCH_NEWS_BY_MARQUE_LIST})

      }
    },[dispatch])
    
    return (
        <Fragment>
            <Breadcrumb parent="Actualité" title="Liste actualités"/>
            <Container fluid={true}>
            <div className="media-body text-right">
                <Form className="d-inline-flex">

                  <Link to={ localStorage.getItem("@intranetTypeUser") === 'admin' ?`${process.env.PUBLIC_URL}/app/actualites/actualiteAdd` : `${process.env.PUBLIC_URL}/app/actualites/add-actualite-franchise`  } className="btn btn-primary" > {AddNewActualite}</Link>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
              
              </div>
                <Row>
                   
                    <Col  sm="12">
                        <Card>
                           
                            <CardBody>
                                <VerticalTimelineComp news={news} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Actualités;