import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ButtonGroup, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux';
import { AddFournisseur,Password,FirstName,LastName,Address,EmailAddress,IdentifiantFournisseur, InfoFournisseur, SaveChanges, Cancel} from '../../../constant'
import { NewProvider } from '../../../redux/fournisseur/action';
import moment from 'moment';
import { useFilePicker } from 'use-file-picker';

import { WATCH_CATEGORY, WATCH_PROVIDER_LIST } from '../../../redux/actionTypes';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { fetchCategoryprovider } from '../../../api';

const FournisseurList = (props) => {

 
  const [firstName,setfirstName] = useState("")
  const [lastName,setlastName] = useState("")
  const [email,setemail] = useState("")
  const [phoneNumber,setphoneNumber] = useState("")


  const [role,setRole] = useState("Fournisseur")

  const [adresse,setadresse] = useState("")
  const [region,setRegion] = useState("")
  const [password,setpassword] = useState("")
  const [tokenPush,settokenPush] = useState("")
  const [fournisseurState,setfournisseurState] = useState(true)
  const addToggle = () => { setaddModal(!addModal) }
  const [addModal, setaddModal] = useState(false)
  const [searchvalue, setsearchvalue] = useState("")
  const [selectedCategorie, setselectedCategorie] = useState([]);
  const category = useSelector(state => state.Categoryapp.category)
  const [selectedroleprovider,setSelectedroleprovider] = useState("1")
  const [providerCategory,setproviderCategory] = useState([])
  const searchUser = (value) => {
    setsearchvalue(value)
  }
  const addToSelectedListe = (id) => {
    let selectedUserCopy = selectedCategorie
    if(selectedUserCopy.includes(id) == true){
      selectedUserCopy.splice(selectedUserCopy.findIndex(res => res == id), 1)
      setselectedCategorie(selectedUserCopy)
    setrandom(Math.random)
    }
    else {
      selectedUserCopy.push(id)
      setselectedCategorie(selectedUserCopy)
      setrandom(Math.random)
    }
      }
  const dispatch = useDispatch()

  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
  
  });
  const history = useHistory()
  const [random, setrandom] = useState(0);
  console.log(filesContent)
  const fournisseurs = useSelector(content => content.Fournisseurapp.fournisseurs);
  useEffect(() => {
    fetchCategoryprovider().then(res => setproviderCategory(res))
    dispatch({type: WATCH_CATEGORY})
    dispatch({type: WATCH_PROVIDER_LIST})
  },[dispatch])
 



  return (
    <Fragment>
      <Breadcrumb parent="Fournisseurs" title="Liste fournisseurs" />
      <Container fluid={true}>
        <div className="edit-profile">
        {/* <div className="media">
             
             <div className="media-body text-right">
               <Form className="d-inline-flex">
               <div className="btn btn-primary" onClick={() => setstateAddFournisseur(!stateAddFournisseur)}> {AddFournisseur}</div>
              
                
               </Form>
              
             </div>
             </div> */}
<div style={{ overflow: 'auto',height: 90,
  whiteSpace: 'nowrap'}}>
    {providerCategory.map((email , index) => (
  <button color="primary" style={{backgroundColor : email.color}}   onClick={()=> { setSelectedroleprovider(email.provider_category_id)
   }}  className="btn-custom" >{email.provider_category_name}</button>
                               

    ))}

                              
</div>
          <Row>
          <Col sn="12" xl="12">
                <Card>
       
                  <CardBody style={{height: '100px',
                  
whiteSpace: 'nowrap',
position: 'relative',
overflowX :'auto',
overflowY : 'hidden',

}} className="dropdown-basic">
                  {category.filter(res => res.provider_cat_id === selectedroleprovider).map((cat,index) => 
       <div className="btn-group">
       <ButtonGroup className="mb-0">
         <Button onClick={() => setsearchvalue(cat.category_id)}  color={"primary"}>{cat.title} </Button>
         {/* <Dropdown className="separated-btn">
         {/* <Button color={"primary"}><i className="icofont icofont-arrow-down"></i></Button>
         <DropdownMenu className="dropdown-content">
       
           {cat.subCategoryList.map((subCategory,i) =>   <DropdownItem onClick={() => props.selectedSubCategory(subCategory)}  href="#">{subCategory}</DropdownItem>)}
        
         </DropdownMenu> 
         </Dropdown> */}
       </ButtonGroup>
     </div>)}
                  </CardBody>
                </Card>
              </Col>
 
            <Col md="12">
          
       
            <Row>
          {fournisseurs?.filter(res => res.provider_category_id === selectedroleprovider).map((cardItem, i) => 
          <Col onClick={() => {
localStorage.setItem("@intranetselectedProviderId",cardItem.user_id)
            
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/provider/${cardItem.user_id}`,{fournisseur : cardItem})
        }} md="6" lg="6" xl="4" className="box-col-6" key={i}>
            <Card className="custom-card">
              <CardHeader>
     
                {/* <Media body className="img-fluid" src={cardItem.userImage} alt="" /> */}
              </CardHeader>
              <div className="card-profile">
                <Media body style={{width :150}} className="rounded-circle" src={cardItem.image} alt="" />
              </div>
             
              <div className="text-center profile-details">
                <h4>{cardItem.first_name} {cardItem.last_name}</h4>
                <h6>{cardItem.role}</h6>
                <h6>{cardItem.email}</h6>
              </div>
              {/* <CardFooter className="row">
                <Col sm="4 col-4">
                  <h6>{Follower}</h6>
                  <h3 className="counter">{cardItem.follower}</h3>
                </Col>
                <Col sm="4 col-4">
                  <h6>{Following}</h6>
                  <h3><span className="counter">{cardItem.following}</span>{"K"}</h3>
                </Col>
                <Col sm="4 col-4">
                  <h6>{TotalPost}</h6>
                  <h3><span className="counter">{cardItem.totalPost}</span>{"M"}</h3>
                </Col>
              </CardFooter> */}
            </Card>
          </Col>
          )}
        </Row>
            </Col>
          </Row>
          <Modal isOpen={addModal} toggle={addToggle} size="lg">
                            <ModalHeader toggle={addToggle}>Recherche Categorie</ModalHeader>
                            <ModalBody>
                              <Form onSubmit={(e) => {e.preventDefault();addToggle()}} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                
                                  <FormGroup className="col-md-12">
                                    <Label>{"Nom category"}</Label>
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                 
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                                {category.filter(x => x.title.includes(searchvalue)== true).map((item, i) => {
                                    return (
                                   
                                          <li style={{backgroundColor : selectedCategorie.includes(item.title) == true ? 'red' : 'white'}} onClick={() => addToSelectedListe(item.title)}  className="clearfix">
                                              <div>
                                                  <div className="name">{item.title}</div>
                                                  
                                              </div>
                                          </li>
                                )
                              
                                })}
                            </ul>
                            </div>
                          
                                     </FormGroup>
                                </div>
                                <Button color="secondary" className="mr-1">{SaveChanges}</Button>
                                <Button color="primary" onClick={addToggle}>{Cancel}</Button>
                              </Form>
                            </ModalBody>
                          </Modal>
        </div>
      </Container>
    </Fragment>
  );
}

export default FournisseurList;