import React, { Fragment, useEffect, useState } from 'react';
import ProgressStepBar from './progressStepBar';
import { Container } from 'reactstrap'
import Breadcrumb from '../../layout/breadcrumb'

class DevProjetDetails extends React.Component {
  state = {
     stepNumber: 1,
  };

  changeStep = e => {
    e.preventDefault();
    this.setState((state) => 
    { 
      return {
        stepNumber: state.stepNumber + 1
      }
    })
  };
  changeStepminus = e => {
    e.preventDefault();
    this.setState((state) => 
    { 
      return {
        stepNumber: state.stepNumber - 1
      }
    })
  };
  render() {
    return (
        <Fragment>
        <Breadcrumb parent="Projets" title="Liste Projets" />
        <Container fluid={true}>
      <div>
        <ProgressStepBar
            stepNumber={this.state.stepNumber}
            steps={[3,2,3,4,5]}
            bullets={true}
        
        />
        <button onClick={this.changeStep}>Click me</button>
        <button onClick={this.changeStepminus}>prev</button>
      </div>
      </Container></Fragment>
    );
  }
}

export default DevProjetDetails