import React, { Fragment,useEffect,useRef,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import Dropzone from 'react-dropzone-uploader'
import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button, InputGroupAddon, InputGroupText, InputGroup} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import {addNewProject} from '../../../redux/project-app/action'
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import {withRouter,Link} from 'react-router-dom'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {ActualiteTitle,ClientName,ProjectRate,ProjectStatus,ProgressLevel,ProjectSize,Small,Medium,Big,StartingDate,EndingDate,EnterSomeDetails,UploadProjectFile,Add,Cancel,Done,Doing, ActualiteCategorie, ActualiteStatepublication, Option, Checked, Disabled, ActualiteDatepublication, ActualiteStateNotifierParEmail, Actualitecontenu, ActualiteAjouterSondage, ActualitéstateSondage, ActualiteChoixSondage} from '../../../constant'
import { firestorage } from '../../../data/config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { NewNews } from '../../../redux/news/action';
import { WATCH_FRANCHISE_LIST } from '../../../redux/actionTypes';
import axios from 'axios';
const NewActualite = (props) => {
  const ref = useRef(null);
    const dispatch = useDispatch()
    const {register, handleSubmit, errors } = useForm();
    const [startDate, setstartDate] = useState(new Date())
    const [endDate, setendDate] = useState(new Date())
    const [publiucationState, setPublicationState] = useState(false)
    const [NotificationState, setNotificationState] = useState(false)
    const [stateSondage, setStateSondage] = useState(false)
    const [actualiteContenu, setActualiteContenu] = useState("")
    const [allMarque, setAllMarque] = useState(false)
    const [sondageListe, setSondageListe] = useState([{contenuSondage : "" }])
    const [random, setrandom] = useState(0)
    const [myfilee, setMyFile] = useState([])
    const [marqueSelection, setmarqueSelection] = useState([]);
    const handleStartDate = date => {
      setstartDate(date);
    };
    const [marque, setMarque] = useState("");
    const franchises = useSelector((content) => content.Franchiseapp.franchises);
  
    
    useEffect(() => {
      dispatch({ type: WATCH_FRANCHISE_LIST });
    }, [dispatch]);
  
    
const AddSondage = () => {

  let listesondafecopy = sondageListe
  listesondafecopy.push({ contenuSondage : "" })

setSondageListe(listesondafecopy)
setrandom(Math.random())
}
const Addtext = (i, text) => {
  console.log()
 let sondageListCopy = sondageListe
 sondageListCopy[i].contenuSondage = text
 setSondageListe(sondageListCopy)
 setrandom(Math.random())
}
const DeleteSondage = (value) => {
  
if(value === ""){
  let listesondafecopy = sondageListe.filter((res, i) => i !== sondageListe.length - 1)
  console.log(listesondafecopy)
  setSondageListe(listesondafecopy)
  setrandom(Math.random())
}
else {
let listesondafecopy = sondageListe.filter(res => res.contenuSondage !== value)
setSondageListe(listesondafecopy)
setrandom(Math.random())
}
  
}
    const handleEndDate = date => {
      setendDate(date);
    };
    
    const getUploadParams = ({ meta, file }) => {

       
      const formData =  new FormData();
    formData.append('image', file);
    let url = "https://gocloud.groupe-fbh.com/intranet/api/medianews/uploadmedia.php";

        
        return { url: 'https://gocloud.groupe-fbh.com/intranet/api/medianews/uploadmedia.php', body : formData  } }
      const handleChangeStatus = ({xhr, meta, file }, status) => {
  
        if(status === "done" ){
            if (xhr.readyState == 4) {
              let myfils = myfilee;
              const result = JSON.parse(xhr.response);
             myfils.push({
             url : result.url,
             name: meta.name,
             size: meta.size,
             type: meta.type,
             creation_date : moment(new Date()).format('yyyy-MM-DD HH:mm'),
             updated_date : moment(new Date()).format('yyyy-MM-DD HH:mm'),
             icon:"fa fa-file-text-o txt-info"
              })
              setMyFile(myfils)
              toast.success("Fichier Téléchargé avec succés")
            }
          }
 else if (status === "removed"){
    let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
    setMyFile(myfileeCopy)
  }
      }
    const AddProject = data => {
      console.log(myfilee)
      if (data !== '') {
      //   data.actualitecontenu = actualiteContenu
      //   data.publiucationState = publiucationState
      //   data.actualiteDatepublication = new Date()
      //   data.actualitestateSondage = stateSondage
      //   data.sondageListe = sondageListe
      //   data.idProp = "admin"
      //   data.allMarque = allMarque
      //   data.userListe = []
      //   data.actualiteNotifierParEmail = NotificationState
      //   data.piecesJoint = myfilee
      //   data.creationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
      //   if (allMarque === false ){
      //   data.marqueName = franchises.find(res => res.id === marque).FranchiseName
      //   data.marqueImage = franchises.find(res => res.id === marque).franchiseImage
      //   data.marqueId = marque
        
      // }
      data.news_contenu = actualiteContenu
      data.publication_state = publiucationState == true ? 1 : 0
      data.news_publication_date = startDate
      data.news_sondage_state = stateSondage
      data.sondageListe = sondageListe
      data.prop_id = localStorage.getItem("@intranetidUser")
      data.prop_type= "Admin"
      data.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
      data.allmarque = allMarque === true ? 1 : 0
      data.status= "1"
      data.marqueSelection = marqueSelection
      data.userListe = []
      data.notifier_mail = NotificationState
      data.piecesJoint = myfilee
      data.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
      // if (allMarque === false ){
      // data.marqueName = franchises.find(res => res.id === marque).FranchiseName
      // data.marqueImage = franchises.find(res => res.id === marque).franchiseImage
      // data.marqueId = marque
      // }
        dispatch(NewNews(data))
        props.history.push(`${process.env.PUBLIC_URL}/app/actualites/actualitesliste/`)
      } else {
        errors.showMessages();
      }
    };

    return (
        <Fragment>
        <Breadcrumb parent="Actualité" title="Créer actualité" /> 
        <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(AddProject)}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{ActualiteTitle}</Label>
                            <Input className="form-control" type="text"  name="title" placeholder="Titre Actualité *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      {/* <Row>
                      <Col>
                          <FormGroup>
                            <Label>{ActualiteCategorie}</Label>
                            <select className="form-control digits">
                              <option>{Small}</option>
                              <option>{Medium}</option>
                              <option>{Big}</option>
                            </select>
                          </FormGroup>
                        </Col>
                        </Row> */}
                        <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={publiucationState} onChange={(e) => setPublicationState(e.target.checked)} />
                        <Label for="radio1">{ActualiteStatepublication}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                     { publiucationState === true ?
                      <Row>
                        <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{ActualiteDatepublication}</label>
                       
                            <DatePicker className="form-control digits" onChange={e => setstartDate(e)} showPopperArrow={false} selected={startDate} showTimeSelect dateFormat="Pp" />
                          </div>
                        </FormGroup>
                        </Col>
                        </Row> : null
} 
<Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={NotificationState} onChange={(e) => setNotificationState(e.target.checked)} />
                        <Label for="radio1">{ActualiteStateNotifierParEmail}<span className="digits"> </span></Label>
                      </div>
                     
                    </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                        <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={allMarque} onChange={(e) => setAllMarque(e.target.checked)} />
                        <Label for="radio1">{"Tous les marques"}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row> 
{ allMarque === false ?
<Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Choisir Marque"}</Label>
                        <Typeahead
                                    
                            ref={ref}
                                                    id="custom-typeahead"
                                                    allowNew={false}
                                                    multiple

    //                                                 onInputChange={(t,e) => {
    //                                                   if (t.includes(",")){
                                                
    //                                                     setmarqueSelection([...marqueSelection,{customOption: true, label: t.replace(',',""), id: "new-id-"+(marqueSelection.length+1)}])
    //                                                    const instance = ref.current
    // instance.clear()
    // instance.focus()
                                                  
    //                                                 }}
    //                                                 }
                                                    
                                                    onChange={(e) => {
                                                      setmarqueSelection(e)}}
                                                    selected={marqueSelection}
                                                    newSelectionPrefix="Ajouter marque: "
                                                    options={franchises.map(res => {return   {id: res.franchise_id, label: res.franchise_name}} )}
                                                    placeholder="Destinataire(s)"
                                                />
                        {/* <select 
                          onChange={(e) => {
                            let id = e.target.value;
                            localStorage.setItem("@intranet_franchiseid", id);

setMarque(id)
                           
                          }}
                          className="form-control digits"
                        >
                           <option >
                             Choisir marque
                            </option>
                          {franchises.map((franchise, index) => (
                            <option selected={franchise.id === marque} value={franchise.id}>
                              {franchise.FranchiseName}
                            </option>
                          ))}
                        </select> */}
                      </FormGroup>
                    </Col>
                  
                  </Row>: null
} 
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{Actualitecontenu}</Label>
                            <SunEditor onChange={(e) => setActualiteContenu(e)} />
                            <span style={{ color: "red" }}>{errors.client_name && 'Contenu is required'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                    
                      <Row>
                      <Col>
                      <FormGroup className="m-t-15 custom-radio-ml">
                      <div>
                        <Input id="radio1" type="checkbox" name="radio1" value={stateSondage} onChange={(e) => setStateSondage(e.target.checked)} />
                        <Label for="radio1">{ActualitéstateSondage}<span className="digits"> </span></Label>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
              {stateSondage === true ?    <> <Row>
                      <Col>
                      <FormGroup >
                      <div>
                      <Button color="primary" onClick={() => AddSondage()}  >{ActualiteAjouterSondage}</Button>
                      </div>
                     
                      
                    </FormGroup>
                        </Col>
                        </Row>
                        { 
                        sondageListe.map((sondage,index) => 
                          <Row key={index}>
                      <Col>
                        <FormGroup>
                        
                            <InputGroup>
                              <Input onChange={(e) => Addtext(index, e.target.value)} className="form-control" type="text" value={sondage.contenuSondage} placeholder={ActualiteChoixSondage} aria-label="Recipient's username"/>
                              <InputGroupAddon addonType="append"><InputGroupText onClick={() => DeleteSondage(sondage.contenuSondage)}> {"-"}</InputGroupText></InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                          </Col>
                        </Row>
                        )
                        }</>
                       : null }
                  
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UploadProjectFile}</Label>
                                <Dropzone
                              
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus}
                                    submitButtonDisabled={true}
                                    inputWithFilesContent={"Ajouter Fichier"}
                                    canCancel={true}
                                    inputContent="Uploader un fichier"
                                    styles={{
                                  
                                        dropzone: { width: '100%', height: 50 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{alignContent: 'flex-end'}} sm="12">
                          <FormGroup className="mb-0 text-right">
                           
                              <Link className="mr-3" to={`${process.env.PUBLIC_URL}/app/actualites/actualitesliste/`}>
                              <Button color="secondary">{Cancel}</Button>
                              </Link>
                              <Button color="primary">{Add}</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}

export default withRouter(NewActualite);