import { EVENTS } from 'react-feather';
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_EVENTS,
    ADD_EVENT_CATEGORY,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,
        ADD_CATEGORY,
        ADD_SOUS_CATEGORY,
        REMOVE_SOUS_CATEGORY,
        REMOVE_CATEGORY,
        UPADTE_CATEGORY,
        GET_EVENT_CATEGORY,
        REMOVE_EVENT_CATEGORY,
        UPDATE_EVENT_CATEGORY,
        ADD_PROVIDER_CATEGORY,
        DELETE_PROVIDER_CATEGORY,
        UPDATE_PROVIDER_CATEGORY
       } from '../actionTypes';

import SweetAlert from 'sweetalert2'
import { ResetPassword } from '../../constant';
import axios from 'axios';
export const myevent = (myeventdata) => {
    return ({
        type: ADD_TO_MY_EVENTS,
        payload: {myeventdata}
    })
}

export const NewProviderCategory = (providercategory ) => {
    const token = localStorage.getItem('intranetTypeUsertoken')

    axios({
        method: 'post',
        url: `${urlbackend}providercategory/create.php`,
        data: JSON.stringify(providercategory),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
   
        //handle success
        SweetAlert.fire(
            'Categorie ajoutée',
            'Categorie ajoutée a été ajoutée',
            'success'
          )

   // eventcategory.event_category_id = response.data.data.event_category_id
  

    })
    .catch(function (response) {
        //handle error
      
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de l ajout veuillez réessayer',
            'success'
          )
       
      
    });
    
   
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: ADD_PROVIDER_CATEGORY,
    payload: {providercategory}
})
}

export const getAllCategory = (id ) => {

  
    db.collection("Category").doc(id).delete()
    SweetAlert.fire(
        'Catégorie supprimé',
        'Catégorie a été supprimé avec succés',
        'success'
      )
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: REMOVE_CATEGORY,
    payload: {id}
})
}
export const UpdateProviderCategory = (datacategorie) => {
    const token = localStorage.getItem('intranetTypeUsertoken')

    axios({
        method: 'post',
        url: `${urlbackend}providercategory/update.php`,
        data: JSON.stringify(datacategorie),
        config:    { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Categorie modifié',
            'Votre categroy est modifié',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de modification veuillez réessayer',
            'success'
          )
       
      
    });
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: UPDATE_PROVIDER_CATEGORY,
    payload: {datacategorie}
})
}
export const removeProviderCategory = (id ) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}providercategory/delete.php`,
        data: JSON.stringify({"provider_category_id" : id}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Votre categroy est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


return ({
    type: DELETE_PROVIDER_CATEGORY,
    payload: {id}
})
}



export const getEventCategory = async () => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    let list  =  []
  await axios({
        method: 'get',
        url: `${urlbackend}eventcategory/read.php`,

        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
     console.log(response.data.records)
     list = response.data.records;
  

    })
    .catch(function (response) {
        //handle error
        
       
      
    });

    return await ({
        type: GET_EVENT_CATEGORY,
        payload: {list}
    })
}