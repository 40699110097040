import { EVENTS } from 'react-feather';
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,
        ADD_COMMENT_EVENT,
       } from '../actionTypes';

import SweetAlert from 'sweetalert2'
import axios from 'axios';
export const myevent = (myeventdata) => {
    return ({
        type: ADD_TO_MY_EVENTS,
        payload: {myeventdata}
    })
}
export const NewEVENTS = (eventdata ) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}events/create.php`,
        data: JSON.stringify(eventdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
eventdata.events_id = response.data.data
        SweetAlert.fire(
            'Evénement ajouté',
            'Evénement a été ajouté avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });


      
        
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))

  
    return ({
        type: ADD_NEW_EVENTS,
        payload: {eventdata}
    })
}
export const deletemediaevents = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}mediaevents/delete.php`,
        data: JSON.stringify({"media_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        console.log(response)
        SweetAlert.fire(
            'Supprimé',
            'Votre media est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const updateEvents = (data, id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}events/update.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Modifié',
            'Votre événement est modifié',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la modification veuillez réessayer',
            'success'
          )
       
      
    });
    return ({
        type: UPDATE_MY_EVENTS,
        payload: {id,data}
    })

}
export const updateevent = (id,startdate,enddate) => {
    db.collection("EVENTS").doc(id).update({start : startdate+"" , end : enddate+""})
    return ({
        type: UPDATE_EVENTS,
        payload: {id,startdate,enddate}
    })

}
export const manageStateNew = (id,state) => {
    db.collection('EVENTS').doc(id).update({EVENTState : state})
    return ({
        type: UPDATE_STATE_EVENTS,
        payload: {id,state}
    })

}
export const updateMyevent = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_EVENTS,
        payload: {id,updateMydata,image_url}
    })

}

export const updatecomment = (data) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}eventcomment/update.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'commentaire modifié',
            'Votre commenatire  est modifié',
            'success'
          )
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de la modification veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const addcomment = (data) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}eventcomment/create.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'commentaire ajouté',
            'Votre commenatire  est ajouté',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de l ajout veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const addstatusParticipe = (data) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}user_event/create.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
       
        SweetAlert.fire(
            data.status,
            'Votre status est ajouté',
            'success'
          )
    

    })
    .catch(function (response) {
        //handle error
       
       
      
    });
    

}


export const deletyecommentaire = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}eventcomment/delete.php`,
        data: JSON.stringify({"comment_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Votre Commenataire est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    

}
export const removeevent = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}events/delete.php`,
        data: JSON.stringify({"events_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Votre événement est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    return ({
        type: REMOVE_EVENTS,
         payload : removeid
    })

}
export const removemyevent = (eventid) => {
    return ({
        type: REMOVE_FROM_MY_EVENTS,
        eventid
    })
}