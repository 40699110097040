import { Subfranchises } from 'react-feather';
import { db, firestorage, urlbackend } from '../../data/config';
import {ADD_TO_MY_SUBFRANCHISE,
        ADD_NEW_SUBFRANCHISE,
        REMOVE_SUBFRANCHISE,
        REMOVE_FROM_MY_SUBFRANCHISE,
        UPDATE_SUBFRANCHISE,
        UPDATE_MY_SUBFRANCHISE,
        UPDATE_STATE_SUBFRANCHISE,
        GET_SUBFRANCHISE_LIST,
        GET_SUBFRANCHISE_BY_ID,
        ADD_INVENTAIRE,
        ADD_DIPENSE,
       } from '../actionTypes';

       import SweetAlert from 'sweetalert2'
import axios from 'axios';
export const mysubfranchise = (mysubfranchisedata) => {
    return ({
        type: ADD_TO_MY_SUBFRANCHISE,
        payload: {mysubfranchisedata}
    })
}

export const getSubfranchiseById = (idFranch) => {

    db.collection("Subfranchises").doc(idFranch).get().then(res => {


        return ({
            type: GET_SUBFRANCHISE_BY_ID,
            payload: {...res.data(),
            id : res.id}
        })

    })
    //     const uploadTask =  firestorage.ref(`/subfranchises/${avatar.name}`).putString(avatar.content, 'data_url');
       
    //     uploadTask.on('state_changed', 
    //    (snapShot) => {
    //      //takes a snap shot of the process as it is happening
    //      console.log(snapShot)
    //    }, (err) => {
    //      //catches the errors
    //      console.log(err)
    //    }, () => {
    //      // gets the functions from storage refences the image storage in firebase by the children
    //      // gets the download url then sets the image from firebase as the value for the imgUrl key:
    //      firestorage.ref('/subfranchises').child(avatar.name).getDownloadURL()
    //       .then(fireBaseUrl => {
    
            
    //         subfranchisedata.subfranchiseImage = fireBaseUrl
            
           
            //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
         // })
       //.})
      
       
    }
    
    export const addinventaires = (id ,  data) => {

        //     const uploadTask =  firestorage.ref(`/subfranchises/${avatar.name}`).putString(avatar.content, 'data_url');
           
        //     uploadTask.on('state_changed', 
        //    (snapShot) => {
        //      //takes a snap shot of the process as it is happening
        //      console.log(snapShot)
        //    }, (err) => {
        //      //catches the errors
        //      console.log(err)
        //    }, () => {
        //      // gets the functions from storage refences the image storage in firebase by the children
        //      // gets the download url then sets the image from firebase as the value for the imgUrl key:
        //      firestorage.ref('/subfranchises').child(avatar.name).getDownloadURL()
        //       .then(fireBaseUrl => {
        
        
        //         subfranchisedata.subfranchiseImage = fireBaseUrl
                db.collection("Subfranchises").doc(id).collection("Inventaires").add(data)
                SweetAlert.fire(
                    'Inventaire ajoutée',
                    'Inventaire a été ajoutée avec succés',
                    'success'
                  )
                return ({
                    type: ADD_INVENTAIRE,
                    payload: {data, id}
                })
                //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
             // })
           //.})
          
           
        }
        export const adddipenses = (id ,  data) => {

            //     const uploadTask =  firestorage.ref(`/subfranchises/${avatar.name}`).putString(avatar.content, 'data_url');
               
            //     uploadTask.on('state_changed', 
            //    (snapShot) => {
            //      //takes a snap shot of the process as it is happening
            //      console.log(snapShot)
            //    }, (err) => {
            //      //catches the errors
            //      console.log(err)
            //    }, () => {
            //      // gets the functions from storage refences the image storage in firebase by the children
            //      // gets the download url then sets the image from firebase as the value for the imgUrl key:
            //      firestorage.ref('/subfranchises').child(avatar.name).getDownloadURL()
            //       .then(fireBaseUrl => {
            
            
            //         subfranchisedata.subfranchiseImage = fireBaseUrl
                    db.collection("Subfranchises").doc(id).collection("Dipenses").add(data)
                    SweetAlert.fire(
                        'Dépense ajoutée',
                        'Dépense a été ajoutée avec succés',
                        'success'
                      )
                    return ({
                        type: ADD_DIPENSE,
                        payload: {data, id}
                    })
                    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
                 // })
               //.})
              
               
            }
export const NewSubfranchise = (subfranchisedata ,  avatar) => {
console.log(subfranchisedata)
//     const uploadTask =  firestorage.ref(`/subfranchises/${avatar.name}`).putString(avatar.content, 'data_url');
   
//     uploadTask.on('state_changed', 
//    (snapShot) => {
//      //takes a snap shot of the process as it is happening
//      console.log(snapShot)
//    }, (err) => {
//      //catches the errors
//      console.log(err)
//    }, () => {
//      // gets the functions from storage refences the image storage in firebase by the children
//      // gets the download url then sets the image from firebase as the value for the imgUrl key:
//      firestorage.ref('/subfranchises').child(avatar.name).getDownloadURL()
//       .then(fireBaseUrl => {


//         subfranchisedata.subfranchiseImage = fireBaseUrl
const token = localStorage.getItem('intranetTypeUsertoken')
axios({
    method: 'post',
    url: `${urlbackend}subfranchise/create.php`,
    data: JSON.stringify(subfranchisedata),
    config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
})
.then(function (response) {
    //handle success
    SweetAlert.fire(
        'Sous Franchise ajoutée',
        'Sous Franchise a été ajoutée avec succés',
        'success'
      )
  
})
.catch(function (response) {
    //handle error
    console.log("response")
    console.log(response)
});
      

        return ({
            type: ADD_NEW_SUBFRANCHISE,
            payload: {subfranchisedata}
        })
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
     // })
   //.})
  
   
}

export const updatesubfranchise = (id,updatedata,image_url) => {
    return ({
        type: UPDATE_SUBFRANCHISE,
        payload: {id,updatedata,image_url}
    })

}

export const addDemandes = (id,state) => {
    db.collection('Demandes').add(state)
    
    SweetAlert.fire(
        'Demande envoyée',
        'Demande a été envoyée avec succés',
        'success'
      )
}
export const manageStateSubfranchise = (id,state) => {
    db.collection('Subfranchises').doc(id).update({subfranchiseState : state})
    return ({
        type: UPDATE_STATE_SUBFRANCHISE,
        payload: {id,state}
    })

}


export const EditSubfranchise = (id,data) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    console.log(data)
    axios({
        method: 'post',
        url: `${urlbackend}subfranchise/update.php`,
        data: JSON.stringify(data),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Franchise modifié',
            'Franchise a été modifié avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
    return ({
        type: UPDATE_MY_SUBFRANCHISE,
        payload: {id,data}
    })

}
export const updateMysubfranchise = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_SUBFRANCHISE,
        payload: {id,updateMydata,image_url}
    })

}

export const removesubfranchise = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}subfranchise/delete.php`,
        data: JSON.stringify({"subfranchise_id" : removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Franchise est supprimé',
            'success'
          )
    
  

    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    
    return ({
        type: REMOVE_SUBFRANCHISE,
         removeid
    })

}

export const removemysubfranchise = (subfranchiseid) => {
    return ({
        type: REMOVE_FROM_MY_SUBFRANCHISE,
        subfranchiseid
    })
}