import React, { useState, Fragment, useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Nav, NavItem, NavLink, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, TabContent, TabPane, Modal, ModalHeader, ModalBody, FormGroup, Form, Button, Label, Input, ListGroup, ListGroupItem } from 'reactstrap'
import {BasicTabs,TabsBottomLeftAlign,TabsWithIcon,TabsRightAlign,TabsVertical,PillTabs,PillTabsWithIcon,PillDarkColor,PrimaryColor,SecondaryColor,SuccessColor,InfoColor,WarningColor,DangerColor,Hometxt,Action,Contact,Profile,Messages,Settings, Save, Cancel, Add} from '../../../constant'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { WATCH_CATEGORY, WATCH_PROVIDER_CATEGORY, WATCH_SUB_CATEGORY } from '../../../redux/actionTypes';
import {  deleteCategorieGroupe, NewCategory, updateCategorieGroupe } from '../../../redux/category/action';
import SweetAlert from 'sweetalert2'
import { fetchCategoryprovider } from '../../../api';
import { Edit, Plus, Trash } from 'react-feather';
import moment from 'moment';
import { NewProviderCategory, removeProviderCategory, UpdateProviderCategory } from '../../../redux/providercategory/action';
import { deleteCategorie, NewSubCategory } from '../../../redux/subcategory/action';
const ManageCategory = (props) => {

const dispatch = useDispatch()
  const [dangerTab, setdangerTab] = useState('0');
  const [subCategory, setsubCategory] = useState('');
  const [selectedCategorie, setselectedCategorie] = useState('');
  const [categoryName, setcategoryName] = useState('');
  const [roleprovider,setRoleProvider] = useState("1")
  const [selectedroleprovider,setSelectedroleprovider] = useState("1")
  const [providerCategory,setproviderCategory] = useState([])
  const { register, handleSubmit, errors } = useForm()
  const [addauditModal, setaddauditModal] = useState(false)
  const addAuditToggle = () => { setaddauditModal(!addauditModal) } 
  const [addSubModal, setSubModal] = useState(false)
  const addSubToggle = () => { setSubModal(!addSubModal) }
  const [statemodal, setstatemodal] = useState(false);
  const statemodatoggle = () => setstatemodal(!statemodal)
  const default_color = localStorage.getItem('default_color');
  const secondary_color = localStorage.getItem('secondary_color');
  const [colorBackground1, setColorBackground1] = useState(default_color || secondary_color)
  const [statemodalupdate, setstatemodalupdate] = useState(false);
  const statemodalupdatetoggle = () => setstatemodalupdate(!statemodalupdate)

  const [selectedProviderCategrory, setselectedProviderCategrory] = useState(null)
  const [updateSubModal, setupdateSubModal] = useState(false)
  const updateAuditToggle = () => { setupdateSubModal(!updateSubModal) }
  
  const category = useSelector(state => state.Categoryapp.category)
  const subcategory = useSelector(state => state.SubCategory.subcategory)
  const providercategory = useSelector(state => state.ProviderCategory.providercategory)
  const handleAddSubmit = (data) => {
    if (data !== '') {
    
    data.provider_cat_id = roleprovider

dispatch(NewCategory(data))
addAuditToggle()
    } else {
      errors.showMessages();
    }
  };
const handleAddsSubCategorySubmit = (e) => {
  e.preventDefault()
    if (subCategory !== '') {
let data = {}
data.title = subCategory
data.cat_id =selectedCategorie
   //  let listAudtcopy = franchise.auditListe;
    // listAudtcopy.push({auditName : data.title ,auditurl : data.url})
    // franchise.auditListe = listAudtcopy;
   // data.subCategoryList =[]
dispatch(NewSubCategory(data))
setTimeout(() => {
  dispatch({type: WATCH_SUB_CATEGORY})
}, 2000);
addSubToggle()
    } else {
      //errors.showMessages();
    }
  };
  useEffect(() => {
    //fetchCategoryprovider().then(res => setproviderCategory(res))
    dispatch({type: WATCH_CATEGORY})
    dispatch({type: WATCH_PROVIDER_CATEGORY})
    dispatch({type: WATCH_SUB_CATEGORY})
   
  }, [])
  const RemoveCategorie = (subCategoryChoice , id) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
      
   dispatch(deleteCategorie( id))
       
      }
      else {
        // SweetAlert.fire(
        //   ''
        // )
      }
    })
  }

  const RemoveCategorieGroupe = ( id) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
      
   dispatch(deleteCategorieGroupe(id))
        
      }
      else {
        // SweetAlert.fire(
        //   ''
        // )
      }
    })
  }
  const RemoveCategorieProvider = ( id) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
      
   dispatch(removeProviderCategory(id))
        SweetAlert.fire(
          'Supprimé',
          'Votre Sous categorie est supprimé',
          'success'
        )
      }
      else {
        // SweetAlert.fire(
        //   ''
        // )
      }
    })
  }
  const UpdateCategorieGroupe = (e) => {
    e.preventDefault()
    let data={title : categoryName, category_id : selectedCategorie}
   dispatch(updateCategorieGroupe(data))
   updateAuditToggle() 
   
      
 
  }
  const handleUnlimatedColor1Change = (e) => {
    const { value } = e.target
    setColorBackground1(value)
}
const AddproviderCategory = data => {
  console.log(data)
  data.creation_date =  moment(new Date()).format('yyyy-MM-DD HH:mm')
  data.updated_date =  moment(new Date()).format('yyyy-MM-DD HH:mm')
  data.color = colorBackground1
  dispatch(NewProviderCategory(data))
  setTimeout(() => {
    dispatch({type: WATCH_CATEGORY})
  }, 2000);
  statemodatoggle()
  }
  const UpdateproviderCategory = data => {
    let selectedProviderCategroryCopy = selectedProviderCategrory
    selectedProviderCategroryCopy.updated_date =  moment(new Date()).format('yyyy-MM-DD HH:mm')
   
    dispatch(UpdateProviderCategory(selectedProviderCategroryCopy))
    statemodalupdatetoggle()
    }
  return (
    <Fragment>
      <Breadcrumb parent="Categorie" title="Categorie" />
      <Container fluid={true}>
    
      <div style={{ overflow: 'auto',height: 90,
    whiteSpace: 'nowrap'}}>
    <Button color="primary" onClick={()=>  statemodatoggle()} className="btn-custom" style={{width : 70, height: '100%'}} ><Plus size={15}></Plus></Button>
             
    {providercategory.map((email , index) => (
    <button onDoubleClick={() => RemoveCategorieProvider(email.provider_category_id)}    className="btn-custom" style={{backgroundColor : email.color}}  >
      <p onClick={()=> { 

setSelectedroleprovider(email.provider_category_id) 
}}> {email.provider_category_name}</p>
      <Edit size={15} onClick={()=> { 
setselectedProviderCategrory(email)
      statemodalupdatetoggle() 
    }}/> <Trash size={15} onClick={()=> { 

      RemoveCategorieProvider(email.provider_category_id) 
    }}/> </button>
                              
    ))}

                              
</div>
<div className="media-body text-right">
                <div className="d-inline-flex">
                                                     
        <button  onClick={() => addAuditToggle()} className="btn btn-primary" > {"Créer catégorie"}</button>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </div>
              
      </div>
        <Row>
          <Col sm="12" xl="6 xl-100">
            <Card>
              <CardHeader>
                <h5>{"Categories"}</h5>
              </CardHeader>
              <CardBody>
            
                
                <Nav className="border-tab" tabs >
                  {category.length > 0
                
                  }
                  {category.filter(res => res.provider_cat_id === selectedroleprovider).map((cat,index) =>    <NavItem>
                    <NavLink  className={dangerTab === index ? 'active' : ""} onClick={() =>{ 
                       setselectedCategorie(cat.category_id)
                      setdangerTab(index)}}>{cat.title} <i onClick={() =>{
                        setcategoryName(cat.title)
                        setselectedCategorie(cat.category_id)
                        updateAuditToggle()
                      } }  className="icofont icofont-edit"></i><i onClick={() => RemoveCategorieGroupe(cat.category_id)}  className="icofont icofont-trash"></i></NavLink>
                  </NavItem>)}
               
                
                </Nav>
                <TabContent  activeTab={dangerTab}>
                {category.filter(res => selectedroleprovider ? res.provider_cat_id === selectedroleprovider : true).map((cat,index) =>   
                  <TabPane className="fade show" tabId={index}>
                  <Col sm="12" xl="12">
          
                <ListGroup>
                  <ListGroupItem onClick={() => {
                    setselectedCategorie(cat.category_id)
                    addSubToggle()
                  } } className="d-flex justify-content-between align-items-center">{"Ajouter sous Categorie"}</ListGroupItem>
                  { subcategory?.filter(res => res.cat_id === selectedCategorie).map((subcategoryitem, ind) =>  <ListGroupItem className="d-flex justify-content-between align-items-center">{subcategoryitem.title}<span ><i onClick={() => RemoveCategorie(subcategoryitem , subcategoryitem.sub_category_id)} className='fa fa-close'></i></span></ListGroupItem>
   )}</ListGroup>
           
          </Col>
                  </TabPane>
                  
                )}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={addauditModal} toggle={addAuditToggle} size="lg">
                        <ModalHeader toggle={addAuditToggle}>{"Ajouter Categorie"}</ModalHeader>
                        <ModalBody>
                          <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(handleAddSubmit)}>
                            <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{"Nom Categorie"}</Label>
                                <Input className="form-control" name="title" type="text"  autoComplete="off" innerRef={register({ required: true })} />
                                <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                              </FormGroup>
                        
                    <FormGroup className="col-md-12">
                        <Label className="form-label">Type Fournisseur</Label>
                        <Input  type="select" name="select" onChange={(e) => setRoleProvider(e.target.value)} className="form-control btn-square">
                        <option value="manager" key={0}>Choisir type Fournisseur </option>
                            
                            {providercategory.map((res,i) =>  <option value={res.provider_category_id} key={i}>{res.provider_category_name}</option> )
                             
                          
}
                          
                        </Input>
                      </FormGroup>
                
                             
                             
                             
                            </div>
                            <div className="media-body text-right">
                            <div className="d-inline-flex">
                            <Button color="secondary" onClick={addAuditToggle}>{Cancel}</Button>
                            <Button color="primary" className="mr-1">{"Ajouter"}</Button>
                            </div>
                            </div>
                          </Form>
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={updateSubModal} toggle={updateAuditToggle} size="lg">
                        <ModalHeader toggle={updateAuditToggle}>{"Modifier Categorie"}</ModalHeader>
                        <ModalBody>
                          <Form className="form-bookmark needs-validation" onSubmit={UpdateCategorieGroupe}>
                            <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{"Nom Categorie"}</Label>
                                <Input value={categoryName} onChange={(e) => setcategoryName(e.target.value)} className="form-control" name="title" type="text"  autoComplete="off"  />
                                <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                            </FormGroup>

                            </div>
                            <div className="media-body text-right">
                            <div className="d-inline-flex">
                            <Button color="secondary" className="mr-1" onClick={updateAuditToggle}>{Cancel}</Button>
                            <Button color="primary" >{"Modifier"}</Button>
                            </div>
                            </div>
                          </Form>
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={addSubModal} toggle={addSubToggle} size="lg">
                        <ModalHeader toggle={addSubToggle}>{"Ajouter Sous Categorie"}</ModalHeader>
                        <ModalBody>
                          <Form className="form-bookmark needs-validation" onSubmit={handleAddsSubCategorySubmit}>
                            <div className="form-row">
                            <FormGroup className="col-md-12">
                                <Label>{"Nom Sous Categorie"}</Label>
                                <Input className="form-control" value={subCategory} onChange={(e)=> setsubCategory(e.target.value)} name="title" type="text"  autoComplete="off" />
                              
                              </FormGroup>
                             
                             
                             
                             
                            </div>
                            <div className="media-body text-right">
                            <div className="d-inline-flex">
                            <Button color="secondary" className="mr-1" onClick={addSubToggle}>{Cancel}</Button>
                            <Button color="primary"   >{"Ajouter"}</Button>
                            </div>
                            </div>
                          </Form>
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={statemodal} toggle={statemodatoggle}>
              <ModalBody>
              <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={handleSubmit(AddproviderCategory)}
                    >
                    <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Titre catégorie"}</Label>
                            <Input className="form-control" type="text"  name="provider_category_name" placeholder="Titre categorie *" innerRef={register({ required: true })} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>
                   
                     
                    
                      <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Couleur Catégorie"}</Label>
              
                    <input className="form-control"  type="color" name="Color-Background1" value={colorBackground1} onChange={(e) => handleUnlimatedColor1Change(e)} />
                      
                      </FormGroup>
                    </Col>

                      </Row>

                      <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right">

                              <Button onClick={statemodatoggle} className="mr-3" color="secondary">{Cancel}</Button>
                            
                              <Button  color="primary" >{Add}</Button>
                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

              </ModalBody>
            </Modal>

        <Modal isOpen={statemodalupdate} toggle={statemodalupdatetoggle}>
              <ModalBody>
              <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form" onSubmit={(e) => {
                      e.preventDefault()
                      UpdateproviderCategory()
                    }}
                    >
                    <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Nom type"}</Label>
                            <Input className="form-control" type="text"  value={selectedProviderCategrory?.provider_category_name}  name="provider_category_name" placeholder="Titre categorie *" onChange={(e) => setselectedProviderCategrory({...selectedProviderCategrory,provider_category_name : e.target.value})} />
                            <span style={{ color: "red" }}>{errors.title && 'Title is required'}</span>
                          </FormGroup>
                        </Col>
                        
                      </Row>

                      <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Couleur type"}</Label>
              
                    <input className="form-control"   type="color" name="Color-Background1" value={selectedProviderCategrory?.color} onChange={(e) => setselectedProviderCategrory({...selectedProviderCategrory,color : e.target.value})} />

                      </FormGroup>
                    </Col>

                      </Row>

                      <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right">
                      
                      
                              <Button onClick={statemodalupdatetoggle} className="mr-3" color="secondary">{Cancel}</Button>
                        
                              <Button  color="primary" >{"Update"}</Button>
                             
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

              </ModalBody>
            </Modal> 
      </Container>
    </Fragment>
  );
}

export default ManageCategory;