
import React from 'react';
import doc from './jardin.doc';
import { BitlyClient } from 'bitly';
import {firestorage, urlbackend} from '../../../../data/config'

const ApercuFilePdf = ({selectedImage,selectedid}) => {


    const printIframe = (id) => {
      const bitly = new BitlyClient('<accessToken>', {});
 
      const response =  bitly.shorten("https://firebasestorage.googleapis.com/v0/b/intranet-a8e8c.appspot.com/o/racine%2Fjardin.doc?alt=media&token=4a98dd85-5bf9-444d-ae0b-823a613135f3");
     response.then(res => {
      console.log(res);

     }) 
     // var httpsReference = firestorage.refFromURL('https://firebasestorage.googleapis.com/v0/b/intranet-a8e8c.appspot.com/o/racine%2Fjardin.doc?alt=media&token=4a98dd85-5bf9-444d-ae0b-823a613135f3');  
     //console.log(httpsReference.toString())
      };
     const onError =(e) => {
       
      }
    return (
       
          <div>
         
            {selectedImage.filetype?.includes("image") === true ?
            <>
            
         <img style={{width : '100%', height: '100%'}} src={`${urlbackend}${selectedImage.file_url}`}/> 
        
            </>
              : selectedImage.filetype?.includes("video") === true ?
              <>
               <video  style={{width : '100%', height: '100%'}}  controls>
       <source src={`${urlbackend}${selectedImage.file_url}`} type="video/mp4"/>
       <source src={`${urlbackend}${selectedImage.file_url}`} type="video/ogg"/>
       Your browser does not support the video tag.
     </video>
     </>
     : 
               <>
             
           <iframe src={`https://docs.google.com/gview?url=${urlbackend}${selectedImage.file_url}&embedded=true`} id="frame"  style={{width : '100%', height:'500px'}} ></iframe>
           {/* <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${urlbackend}${selectedImage.file_url}&embedded=true`} id="frame"  style={{width : '100%', height:'500px'}} ></iframe> */}
        {/* <iframe src={`${urlbackend}${selectedImage.file_url}`}  id="frame"  style={{width : '100%', height:'500px'}} frameborder="0"></iframe> */}

                            </>     



          
//                                 <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">

//  <Viewer
//                                fileUrl="https://www.ohchr.org/en/udhr/documents/udhr_translations/eng.pdf"
//                 plugins={[

//                 ]}
//             />
// </Worker>
           
            }

</div>


    
    )
}
export default ApercuFilePdf 