import React,{Fragment, useState,useEffect,useRef} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,Button,Media, CardBody} from 'reactstrap'
import Tablet from './tabsets';
import Slider from 'react-slick';
import {useHistory, useParams} from 'react-router-dom'
import {useSelector,useDispatch} from 'react-redux'
import { addToCart,watchfetchSingleProducts } from '../../../redux/ecommerce/product/action';
import { ADD_TO_CART, ADD_TO_WISHLIST, WATCH_PRODUCT_LIST } from '../../../redux/actionTypes'
import Ratings from 'react-ratings-declarative'
import { ProductReview,  Brand, Availability, AddToCart, BuyNow } from "../../../constant";
import { Truck, Gift,CreditCard,Clock } from 'react-feather';
import { fetchProductsByIdApi } from '../../../api';
import { urlbackend } from '../../../data/config';

const  Productpage = (props)  => {
    const {idProduct} = useParams()
    const history = useHistory()
    const products = useSelector(content => content.data.productsFranchise)
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [rating,setRating] = useState(0)
    // eslint-disable-next-line
    const [typeUser, settypeUser] = useState("");
    const [quantity,Setquantity] = useState(1)
  
    
    const slider1 = useRef();
    const slider2 = useRef();
    const dispatch = useDispatch()
    const [singleItem,setsingleItem] = useState(null)
    useEffect(() => {
        fetchProductsByIdApi(idProduct).then(res => {
            if(res.product_name !== undefined) 
            setsingleItem(res)
            else 
            history.goBack()
         
            //setStateUser(res.user_state === "1" ? true : false)
              })
        settypeUser(localStorage.getItem("@intranetTypeUser"))
        setState({
            nav1: slider1.current,
            nav2: slider2.current
          });
      } ,[dispatch]);
    const { nav1, nav2 } = state;

  


    const symbol = useSelector(content => content.data.symbol)

    const  addcart = (product, qty) => {
        dispatch({ type: ADD_TO_CART, payload: { product, qty } })
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/cart`)
    }
    
    const buyProduct = (product, qty) => {
        dispatch({ type: ADD_TO_CART, payload: { product, qty } })
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/checkout`)
    }

    const addWishList = (product) => {
        dispatch({ type: ADD_TO_WISHLIST, payload: { product } });
        history.push(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`);
    }

    const changeRating = (newRating) => {
        setRating(newRating)
    }
    
    return (
        <Fragment>
                <Breadcrumb parent="Ecommerce" title="Product Page"/>
                <Container fluid={true}>
                   <Row>
                       <Col>
                       <Card>
                        <Row className="product-page-main">
                            <Col xl="4">
                            {/* <div className="item" >
                                                <Media src={singleItem.productImage} alt="" className="img-fluid" />
                                            </div> */}
                           <Slider  
                                    asNavFor={nav2} 
                                    arrows= {false}
                                        ref={slider => (slider1.current = slider)} className="product-slider">
                                    {singleItem?.imageListe?.length > 0 ? singleItem.imageListe.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <Media src={`${urlbackend}${item.media_contenu}`} alt="" className="img-fluid" />
                                            </div>
                                        )
                                    }) :
                                    singleItem?.product_image !== undefined ? <Media src={singleItem?.product_image} alt="" className="img-fluid" /> : ''
                                    }   
                                    </Slider>
                                    <Slider asNavFor={nav1}
                                    ref={slider => (slider2.current= slider)}
                                    slidesToShow={4}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    infinite={true}
                                    className="small-slick">
                                    {singleItem?.imageListe?.length > 0  ? singleItem.imageListe.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <Media src={`${urlbackend}${item.media_contenu}`} alt="" className="img-fluid" />
                                            </div>
                                        )
                                    }) : ''}
                                </Slider>
                            </Col>
                            <Col xl="5 xl-100">
                                <Card>
                                    <CardBody>
                                    <div className="product-page-details">
                                        <h3>{singleItem?.product_name}</h3>
                                    </div>
                                    <div className="product-price f-28">
                                        {symbol}{singleItem?.product_price}
                                        {/* <del>{symbol}{singleItem.discountPrice}</del> */}
                                    </div>
                                   
                                    <p>{singleItem?.product_bio}</p>
                                    <hr/>
                                    <div>
                                        <table className="product-page-width">
                                        <tbody>
                                            <tr>
                                            <td> <b>{"Marque"} &nbsp;&nbsp;&nbsp;:</b></td>
                                            <td>{singleItem?.product_marque}</td>
                                            </tr>
                                            <tr>
                                            <td> <b>{"Disponibililé"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td className="txt-success">{singleItem?.product_quantity}</td>
                                            </tr>
                                            {/* <tr>
                                            <td> <b>{"Seller"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td>{"ABC"}</td>
                                            </tr> */}
                                            {/* <tr>
                                            <td> <b>{"Fabric"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                                            <td>{"Cotton"}</td>
                                            </tr> */}
                                        </tbody>
                                        </table>
                                    </div>
                                    <hr/>
                                    {/* <Row>
                                        <Col md="6">
                                        <h6 className="product-title">{"share it"}</h6>
                                        </Col>
                                        <Col md="6">
                                        <div className="product-icon">
                                            <ul className="product-social">
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                            <li className="d-inline-block"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                                            </ul>
                                            <form className="d-inline-block f-right"></form>
                                        </div>
                                        </Col>
                                    </Row>
                                    <hr/> */}
                                    {/* <Row>
                                        <Col md="6">
                                        <h6 className="product-title">{"Rate Now"}</h6>
                                        </Col>
                                        <Col md="6">
                                        <div className="d-flex">
                                                <Ratings
                                                rating={rating}
                                                widgetRatedColors="blue"
                                                changeRating={changeRating}
                                                >
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                            </Ratings>
                                            <span>{ProductReview}</span>
                                        </div>
                                        </Col>
                                    </Row>
                                    <hr/> */}
                                     {typeUser !== 'admin' ? 
                                    <div className="m-t-15 text-right">
                                        <Button  color="primary" className="m-r-10" onClick={() => addcart(singleItem, quantity)} >
                                            <i className="fa fa-shopping-basket mr-1"></i>{AddToCart}
                                        </Button>
                                        <Button  color="secondary" className="m-r-10" onClick={() => buyProduct(singleItem, quantity)}>
                                            <i className="fa fa-shopping-cart mr-1"></i>{BuyNow}
                                        </Button>
                                        {/* <Button color="secondary" onClick={() => addWishList(singleItem)}>
                                            <i className="fa fa-heart mr-1"></i>{"Add To WishList"}
                                        </Button> */}
                                    </div> : <></>}
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col xl="3 xl-cs-35">
                                <Card>
                                    <CardBody>
                                    <div className="filter-block">
                                        <h4>{"Brand"}</h4>
                                        <ul>
                                        <li>{"Clothing"}</li>
                                        <li>{"Bags"}</li>
                                        <li>{"Footwear"}</li>
                                        <li>{"Watches"}</li>
                                        <li>{"ACCESSORIES"}</li>
                                        </ul>
                                    </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                    <div className="collection-filter-block">
                                        <ul>
                                        <li>
                                            <div className="media"><Truck/>
                                            <div className="media-body">
                                                <h5>{"Free Shipping"}</h5>
                                                <p>{"Free Shipping World Wide"}</p>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media"><Clock/>
                                            <div className="media-body">
                                                <h5>{"24 X 7 Service"}</h5>
                                                <p>{"Online Service For New Customer"}</p>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media"><Gift/>
                                            <div className="media-body">
                                                <h5>{"Festival Offer"}</h5>
                                                <p>{"New Online Special Festival"}</p>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media"><CreditCard/>
                                            <div className="media-body">
                                                <h5>{"Online Payment"}</h5>
                                                <p>{"Contrary To Popular Belief."}</p>
                                            </div>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    </Card>
                    {/* <Tablet /> */}
                    </Col>
                   </Row>
                </Container>
            </Fragment>
    );
}
export default Productpage
