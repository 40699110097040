import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Media } from 'reactstrap'
import {Email,MarekjecnoMailId,BOD,DDMMYY,Designer,ContactUs,ContactUsNumber,LocationDetails,JOHANDIO,UserProfileDesc1,UserProfileDesc2,UserProfileDesc3,Comment,MarkJecno,Like,Follower,Following,Location} from '../../constant'
import { useHistory, useLocation } from 'react-router';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { useDispatch } from 'react-redux';
import { manageStateUser } from '../../redux/user/action';
import imageprofile from '../../assets/images/dashboard/couverture.jpeg'
import { fetchFournisseurByIdApi, fetchUsersByIdApi } from '../../api';

import { Link,useParams } from 'react-router-dom';
const DeveloperProfile = (props) => {
const {idUser} = useParams();
  const location = useLocation();
  const history = useHistory();
  const [url, setUrl] = useState();
  const [stateUser, setStateUser] = useState();
  const [user, setUser] = useState({});
  useEffect(() => {
    fetchUsersByIdApi(idUser).then(res => {
      if(res !== undefined) 
      setUser(res)
   
      setStateUser(res.userState)
        })
 
 

 }, []);
  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }
  const dispatch = useDispatch()
  const handleActivateChange = (e) => {
    if(e.target.checked === true){
      setStateUser(true)
dispatch(manageStateUser(user.id, true))

}
  else {
    setStateUser(false)
    dispatch(manageStateUser(user.id, false))

  }
  }
  return (
    <Fragment>
      <Breadcrumb parent="Développeurs" title="Profile développeur" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <CardHeader style={{backgroundImage : `url(${imageprofile})`, backgroundSize : 'cover',backgroundRepeat : 'no-repeat'}} className="cardheader"></CardHeader>
                <div className="user-image">
                  <div className="avatar"><Media body alt="" src={user ? user.userImage : require('../../assets/images/user/7.jpg')} data-intro="This is Profile image" /></div>
                  <div className="icon-wrapper" data-intro="Change Profile image here">
                    <i className="icofont icofont-pencil-alt-5" onClick={() =>  history.push(`${process.env.PUBLIC_URL}/app/users/userEdit/${user.id}`,{user : user})}>
                     
                    </i>
                  </div>
                </div>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row >
                        <Col md="6">
                          <div className="ttl-info text-left">
                            <h6><i className="fa fa-envelope mr-2"></i> {Email}</h6><span>{user.email}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-calendar"></i>   {BOD}</h6><span>{user.birthday}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title"><a >{user.firstName} {user.lastName}</a></div>
                        <div className="desc mt-2">{user.role}</div>
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="5">
                          <div className="ttl-info text-left ttl-xs-mt">
                            <h6><i className="fa fa-phone"></i>   {ContactUs}</h6><span>{user.phoneNumber}</span>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-location-arrow"></i>   {Location}</h6><span>{user.region}</span>
                          </div>
                        </Col>
                        <Col md="4">
                 
                    <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-close"></i>   {"Désactiver"}</h6><span> <Toggle
  defaultChecked={stateUser}
  value={stateUser}
  aria-label='Activate'
  onChange={handleActivateChange} 
 /></span>
                          </div>
                   


                  
                  </Col>
                      </Row>
                    </Col>
                   
                  </Row>
                  <hr />
                  
                  <div className="social-media step4" data-intro="This is your Social details">
                    <ul className="list-inline">
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                    </ul>
                  </div>
                  {/* <div className="follow">
                    <Row>
                      <Col col="6" className="text-md-right border-right">
                        <div className="follow-num counter">{"25869"}</div><span>{Follower}</span>
                      </Col>
                      <Col col="6" className="text-md-left">
                        <div className="follow-num counter">{"659887"}</div><span>{Following}</span>
                      </Col>
                    </Row>
                  </div> */}
                </div>
              </Card>
            </Col>
       <Col sm="12" >
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media">
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{"Fomations"}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small></small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{user.formations}</p>
                  <div className="img-container">
                    <div id="aniimated-thumbnials">
                    
                    </div>
                  </div>
                  {/* <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media">
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{"Compétences"}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small></small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{user.competences}</p>
                  {/* <Row className="mt-4 pictures">
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                  </Row> */}
                  {/* <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media">
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{"Experiences"}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small></small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{user.experiences}</p>
                  {/* <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media">
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{"Fonctions"}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small></small></div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    {/* <Col lg="12" xl="4">
                      <div id="aniimated-thumbnials-3"><a href="#javascript"><Media body className="img-fluid rounded" src={require("../../assets/images/blog/img.png")} alt="gallery" /></a></div>
                      <div className="like-comment mt-4 like-comment-lg-mb">
                        <ul className="list-inline">
                          <li className="list-inline-item border-right pr-3">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                          </li>
                          <li className="list-inline-item ml-2">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                          </li>
                        </ul>
                      </div>
                    </Col> */}
                    <Col xl="12">
                      <p>{user.fonctions}</p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col> 
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default DeveloperProfile;