import {call,put, takeLatest} from "redux-saga/effects";
import {fetchProjetsByIdUserApi,  fetchOrdersProviderApi, fetchOrdersFranchiseApi, fetchProjetsApi,fetchTacheApi, fetchSousTacheApi, fetchDevProjetsApi, fetchDevProjetsByIdUserApi, fetchDevTacheApi, fetchDevSousTacheApi } from "../../api";
import {GET_LIST_DEV_PROJECT, WATCH_DEV_PROJET_LIST,GET_LIST_DEV_TACHE, WATCH_DEV_TACHE_LIST, WATCH_DEV_SOUS_TACHE_LIST, GET_LIST_DEV_SOUS_TACHE, WATCH_DEV_PROJET_LIST_BY_ID_USET } from "../../redux/actionTypes";

function* fetchOrderPAsyn({idUser}) {
    const Data = yield call(fetchDevProjetsApi,idUser);
    console.log(Data)
    yield put({type : GET_LIST_DEV_PROJECT,projets : Data});
}
function* fetchOrderUAsyn() {
    const Data = yield call(fetchDevProjetsByIdUserApi);
    yield put({type : GET_LIST_DEV_PROJECT, projets : Data});
}
function* fetchOrderTacheAsyn({id}) {
    const Data = yield call(fetchDevTacheApi, id);

    yield put({type : GET_LIST_DEV_TACHE,taches : Data});
}
function* fetchSousTacheAsyn({id,idtache}) {
 
    const Data = yield call(fetchDevSousTacheApi, id, idtache);

    yield put({type : GET_LIST_DEV_SOUS_TACHE,taches : Data});
}
export function* watchDevProjetList() {

    yield takeLatest(WATCH_DEV_PROJET_LIST,fetchOrderPAsyn)
    yield takeLatest(WATCH_DEV_PROJET_LIST_BY_ID_USET,fetchOrderUAsyn)
    yield takeLatest(WATCH_DEV_TACHE_LIST,fetchOrderTacheAsyn)
    yield takeLatest(WATCH_DEV_SOUS_TACHE_LIST,fetchSousTacheAsyn)

    
    
}
