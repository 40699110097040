import React, { Fragment, useState,useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Dropdown, ButtonGroup, DropdownMenu, DropdownItem, Media } from 'reactstrap'
import { Home, Folder, Clock, Star, AlertCircle, Trash2, Database, Grid, Upload, PlusSquare ,Share, Eye, User, Download } from 'react-feather';
import { toast } from 'react-toastify'
import errorImg from '../../../assets/images/search-not-found.png';
import {BasicModal,Simple,StaticExample,NewMessage,SendMessage,ModalTitle,Close,SaveChanges,VerticallyCentered,TooltipsAndPopovers,UsingTheGrid,SizesModal,LargeModal,SmallModal,ScrollingLongContent,VaryingModalContent,Hometxt,All,Recent,Starred,Recovery,Deleteds,PricingPlan,TrialVersion,FREE,Selected,Premium,ContactUs,AllFiles,RecentlyOpenedFiles,Folders,EndlessAdmin,Files,Storage,AddNew, SharedWithMe, Favourites, AddNewFolder, NewFolder, AddFolder, MultipleImageVideoAudioUpload, AddFile, Newfiles, Partager, Cancel, Title, MYDRIVE, Sharedwithme, DriveEnseigne} from '../../../constant'
import axios from "axios";

import { useDispatch, useSelector } from 'react-redux';
import { GET_FILE_LIST_LOADING, GET_FOLDER_LIST_LOADING, WATCH_DELETED_FILES_LIST, WATCH_DELETED_FOLDERS_LIST, WATCH_FILES_LIST, WATCH_FILE_LIST, WATCH_FOLDER_LIST, WATCH_SUB_FOLDER_LIST, WATCH_USER_LIST } from '../../../redux/actionTypes';
import moment from 'moment';
import { newfolder, recupererfolder, removefolder, removefolderDefinitive, shareFolders, updateTreefolder } from '../../../redux/foldermanager/action';
import {BrowserRouter,Switch,Route,Redirect, Link} from 'react-router-dom'
import AppfileFragment from './app'
import Test from './test';
import { db, firestorage, urlbackend } from '../../../data/config';
import MyDrive from './myDrive';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router';
import SweetAlert from 'sweetalert2'
import { newFile, recupererFile, removeFile, shareFiles,removeFileFinaly, updateTreefile } from '../../../redux/filemanager/action';
import Dropzone from 'react-dropzone-uploader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const liste = ["1","2","3","4","5","6","7"]
const DeletedDrive = (props) => {
  const dispatch = useDispatch();
 

  const myfile = useSelector(content => content.Folderapp.folders);
  const users = useSelector(content => content.Userapp.users);
  const myfiles = useSelector(content => content.Fileapp.files);
  const [selectedFile, setSelectedFile] = useState(null)   // Initially, no file is selected  
  const [searchTerm, setSearchTerm] = useState("");
  const [folderName, setFolderName] = useState("");
  const [myfilee, setMyFile] = useState([])
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [AddFilesState, setAddFilesState] = useState(false);
  const [selectedUser, setselectedUser] = useState([]);
  const loading = useSelector(content => content.Fileapp.loading);
  const loadingfolder = useSelector(content => content.Folderapp.loading);
  const [random, setrandom] = useState(0);
  const [selectedid, setselectedid] = useState("")
  const [selectedidfolder, setselectedidfolder] = useState("")
  const [searchvalue, setsearchvalue] = useState("")
  const [UserListe, setUserListe] = useState([]);
  const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone);
  const AddFilesStatetoggle = () => setAddFilesState(!AddFilesState);
  const addToggle = () => { setaddModal(!addModal) }
  const shareToggle = () => { setshareModal(!shareModal) }
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const [VaryingContentoneUpdate, setVaryingContentoneUpdate] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const VaryingContentoneUpdatetoggle = () => setVaryingContentoneUpdate(!VaryingContentoneUpdate);
  const [addModal, setaddModal] = useState(false)
  const [shareModal, setshareModal] = useState(false)
  const [selectedDataDrag, setselectedDataDrag] = useState({})
  const [idUser, setidUser] = useState("");
  const [typeUser, settypeUser] = useState("");
 
  const [showApercu, setshowApercu] = useState(false);
  const showApercuToggle = () => { setshowApercu(!showApercu) }

  const [clientX, setclientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [windowHeight, setwindowHeight] = useState(0);
  const [windowWidth, setwindowWidth] = useState(0);
  const [optionModal, setoptionModal] = useState(false)
  const optionToggle = () => { setoptionModal(!optionModal) } 
  const [selectedImage, setselectedImage] = useState({})
  const [choicefileorfolder, setchoicefileorfolder] = useState("")
  const [optionFolderModal, setoptionFolderModal] = useState(false)
  const optionFolderToggle = () => { setoptionFolderModal(!optionFolderModal) } 
  const [sortState , setsortState] = useState("trieraz")
  // useEffect(() => {
  //   dispatch({type: WATCH_BOOKMARK_LIST})
  //    axios.get(`${process.env.PUBLIC_URL}/api/files.json`).then((response) => {
  //     setMyFile(response.data);
  //    })
  // },[])
  useEffect(() => {
    dispatch({type: GET_FOLDER_LIST_LOADING})
    dispatch({type: GET_FILE_LIST_LOADING})
    setwindowWidth(window.innerWidth)
    setwindowHeight(window.innerHeight)
    window.addEventListener('resize', updateWindowDimensions);

    setidUser(localStorage.getItem("@intranetidUser"))
    settypeUser(localStorage.getItem("@intranetTypeUser"))
    dispatch({type: WATCH_USER_LIST})
    dispatch({type: WATCH_DELETED_FOLDERS_LIST})
    dispatch({type: WATCH_DELETED_FILES_LIST})
  },[dispatch])

  const  updateWindowDimensions = () => {
    setwindowWidth(window.innerWidth)
    setwindowHeight(window.innerHeight)
      }

  const getUploadParams = ({ meta, file }) => {
  let folderneme = localStorage.getItem("folderName");
   
    console.log(meta)
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/${folderneme}/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/${folderneme}`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;

      
        myfils.push({
      url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          console.log(myfils)
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
  }

  const handleSubmit = () => {
 
    myfilee.forEach(element => {
      handleAddFile(element)
    });
      // allFiles.forEach(f => f.remove())
       toast.success("Fichiers Télécharger avec succés");
   }
const handleAddFolder = () => {
let folder = {}
folder.folderName = folderName
folder.proprietaireId = idUser
folder.proprietaireType = typeUser
folder.userListe = []
folder.idfolder =localStorage.getItem("idfolder");
folder.creationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
folder.folderState = true
folder.downloadState = true
folder.updateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
folder.downloadUrl = ""
folder.stateFolderDeleted = false
folder.icon = "fa fa-file-image-o txt-primary"
folder.filesNumber = 0
VaryingContentonetoggle()
dispatch(newfolder(folder))
dispatch({type: WATCH_FOLDER_LIST})
}
const handleAddFile = (avatar) => {
  let folder = {}
  folder.fileName = avatar.name
  folder.proprietaireId = idUser
  folder.proprietaireType = typeUser
  folder.userListe = []
  folder.fileImage = avatar.url
  folder.idfolder = localStorage.getItem("idfolder");
  folder.creationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.openedDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.fileState = true
  folder.downloadState = true
  folder.stateFolderDeleted = false
  folder.updateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.downloadUrl = ""
  folder.icon = avatar.type.includes("image") === true ?  "fa fa-file-image-o txt-primary" : avatar.type.includes("pdf") === true ? "fa fa-file-pdf-o txt-primary" : avatar.type.includes("xsel") === true ? "fa fa-file-xsel-o txt-primary" : avatar.type.includes("officedocument.") === true ? "fa fa-file-word-o txt-primary" :  "fa fa-file-o txt-primary"
  folder.fileType = avatar.type
  folder.fileSize = avatar.size
  AddFilesStatetoggle()
  dispatch(newFile(folder))
  dispatch({type: WATCH_FILES_LIST})
  }
  const allowDrop = (ev) => {
    ev.preventDefault();
  }
  
  const  drag = (ev, name) => {
    setchoicefileorfolder('folder')
    ev.dataTransfer.setData("text", name);
    console.log(name)
  }
  
  const drop = (ev, name) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    console.log(name)
    if(choicefileorfolder === 'folder'){
    dispatch(updateTreefolder(name, data))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
  }
  else if(choicefileorfolder === 'file') {
    dispatch(updateTreefile(name, data, selectedDataDrag))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
  }
    //ev.target.appendChild(document.getElementById(data));
  }

  const allowDropfile = (ev) => {
    ev.preventDefault();
  }
  const  dragfile = (ev, name, data) => {
    setchoicefileorfolder('file')
    ev.dataTransfer.setData("text", name);
    setselectedDataDrag(data)
    console.log(name)
  }
  const dropfile = (ev, name) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    console.log(name)
    dispatch(updateTreefile(name, data, selectedDataDrag))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
    //ev.target.appendChild(document.getElementById(data));
  }

  const handleChange = event => {
    event.preventDefault();
    setSearchTerm(event.target.value)
  };
  const RemoveFolder  = (taskId) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        dispatch(removefolderDefinitive(taskId));
        SweetAlert.fire(
          'Supprimé!',
          'Votre fichier a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  
  const RecuprerFiles  = (Id ) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "De Récupérer votre fichier ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
       dispatch(recupererFile(Id));
        SweetAlert.fire(
          'Récupéré!',
          'Votre fichier a été Récupéré',
          'success'
        )
      }
      else {
      
      }
    })
  }
  
    const RecuprerFolder  = (Id ) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "De Récupérer votre dossier ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
       dispatch(recupererfolder(Id));
        SweetAlert.fire(
          'Récupéré!',
          'Votre fichier a été Récupéré',
          'success'
        )
      }
      else {
      
      }
    })
  }
  const folderlisloading = liste.map((data,i)=>{
    return(
      <li className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
       

     <Link draggable  className="media">
     <Skeleton className="f-36 txt-warning"/>
        <div className="media-body ml-3">
          <h6 className="mb-0"><Skeleton /></h6>
          
          <p><b><Skeleton /> </b><Skeleton /></p>
        </div>
      </Link>
     
          
    </li>
    )
  })

const filelisloading = liste.map(i => {
    return(
      <li className="file-box" key={i} style={{marginLeft : 5,marginBottom : 5}} >
        <div className="file-top"><Skeleton /></div>
        <div className="file-bottom">
          <h6><Skeleton /></h6>
          <p className="mb-1"><Skeleton /></p>
          <p> <b><Skeleton /> </b><Skeleton /></p>
        </div>
      </li>
    )
  })
  const RemoveFiles  = (Id ) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
       dispatch(removeFileFinaly(Id));
        SweetAlert.fire(
          'Supprimé!',
          'Votre fichier a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  // eslint-disable-next-line
 
    const filelist2 = myfiles?.sort(function(x,y) {
      if(sortState === "trieraz")
      return x.files_name.toString().toLowerCase().localeCompare(y.files_name.toString().toLowerCase());
      else if(sortState === "trierza")
      return y.files_name.toString().toLowerCase().localeCompare(x.files_name.toString().toLowerCase());
      else if(sortState === "trierdate")
      return y.files_name.toString().localeCompare(x.files_name.toString());
    }).filter((data) => {
      if(searchTerm == null)
          return data
      else return data.files_name.toLowerCase().includes(searchTerm.toLowerCase())
      }).map((data,i)=>{
        return(
          <li onContextMenu={e => {
         
            e.preventDefault();
    

  if(e.button === 2){
    setselectedUser(data.userListe)
  
    setselectedid(data.files_id)
    setselectedidfolder(data.folder_id)
    setselectedImage(data)
    optionToggle()
    setclientX(e.clientX)
    setClientY(e.clientY)


  }}}   onDoubleClick={() => {
             
              setselectedImage(data)
              showApercuToggle()}} className="file-box" style={{marginLeft : 5,marginBottom : 5}} key={i}>

    
            <div   draggable="true" onDragStart={(event) => dragfile(event, data.files_id, data)} onDrop={(event) => dropfile(event, data.files_id)} onDragOver={(event) => allowDropfile(event)} className="file-top">{ data.filetype.includes("pdf")=== true || data.filetype.includes("image")=== true || data.filetype.includes("video")=== true ? <img   width="200" height="100" src={`${urlbackend}${data.file_url}`} /> : data.filetype.includes("zip") ? <i className="fa fa-file-archive-o txt-primary" ></i> : <i className="fa fa-file-word-o txt-primary" ></i>}</div>
            <div className="file-bottom">
              <h6>{data.files_name.length > 20 ?  data.files_name.slice(0,17)+'...' : data.files_name}</h6>
              <p className="mb-1">{data.file_size} ko</p>
                {/* <p> <b>{"dernière ouverture"} : </b>{data.updateDate}</p> */}
            </div>
        
          </li>
        )
      })
      const folderlist = myfile.sort(function(x,y) {
        if(sortState === "trieraz")
        return x.folder_name.toString().toLowerCase().localeCompare(y.folder_name.toString().toLowerCase());
        else if(sortState === "trierza")
        return y.folder_name.toString().toLowerCase().localeCompare(x.folder_name.toString().toLowerCase());
        else if(sortState === "trierdate")
        return y.folder_name.toString().localeCompare(x.folder_name.toString());
      }).filter((data) => {
        if(searchTerm == null)
            return data
        else return data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())
        
        }).map((data,i)=>{
          return(
            <li onContextMenu={e => {
           
              e.preventDefault();
      
  
    if(e.button === 2){
      setselectedUser(data.userListe)

      localStorage.setItem("idfolder", data.folder_id)
      localStorage.setItem("folderName", data.folder_name)
      setselectedid(data.folder_id)
      setselectedidfolder(data.folder_parent_id)
      setselectedImage(data)
      optionFolderToggle()
      setclientX(e.clientX)
      setClientY(e.clientY)
  
  
    }}} className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
              <div   draggable="true" onDragStart={(event) => drag(event, data.folder_id)} onDrop={(event) => drop(event, data.folder)} onDragOver={(event) => allowDrop(event)} className="media">
      
           <Link draggable  className="media"><i className="fa fa-folder f-36 txt-warning"></i>
           
              <div className="media-body ml-3">
                <h6 className="mb-0">{data.folder_name}</h6>
                
                  {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
              </div>
            </Link>
        
                      </div>
          </li>
          )
        })
        const folderlist1 = myfile.sort(function(x,y) {
          if(sortState === "trieraz")
          return x.folder_name.toString().toLowerCase().localeCompare(y.folder_name.toString().toLowerCase());
          else if(sortState === "trierza")
          return y.folder_name.toString().toLowerCase().localeCompare(x.folder_name.toString().toLowerCase());
          else if(sortState === "trierdate")
          return y.folder_name.toString().localeCompare(x.folder_name.toString());
        }).filter((data) => {
          if(searchTerm == null)
              return data
          else return data.folder_name.toLowerCase().includes(searchTerm.toLowerCase())
          }).map((data,i)=>{
            
            return(
              <li onContextMenu={e => {
           
                e.preventDefault();
        
    
      if(e.button === 2){
        setselectedUser(data.userListe)

        localStorage.setItem("idfolder", data.folder_id)
        localStorage.setItem("folderName", data.folder_name)
        setselectedid(data.folder_id)
        setselectedidfolder(data.folder_parent_id)
        setselectedImage(data)
        optionFolderToggle()
        setclientX(e.clientX)
        setClientY(e.clientY)
    
    
      }}} className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
                
              <div   draggable="true" onDragStart={(event) => drag(event, data.folder_id)} onDrop={(event) => drop(event, data.folder_id)} onDragOver={(event) => allowDrop(event)} className="media">
              <i className="fa fa-folder f-36 txt-warning" ></i>
                <Link className="media-body ml-3" draggable 
          >
              
                  <h6 className="mb-0">{data.folder_name}</h6>
                  
                    {/* <p> <b>{"dernière ouverture"} : </b>{data.updated_date}</p> */}
                </Link>
  
            
              </div>
            </li>
            )
          })


  return (
    <Fragment>
      <Breadcrumb parent="Drive" title="Gestion Fichiers" />
      <Container fluid={true}>
        <Row>
        <Col xl="3" className="box-col-6 pr-0 file-spacing">
            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  {localStorage.getItem("@intranetTypeUser") === 'admin' || localStorage.getItem("@intranetTypeUser") === 'manager' ?
                  <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/drive-enseigne`} className="btn btn-light"><Folder />{DriveEnseigne}</Link>
                    </li> : ""}
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager`} className="btn btn-light"><Folder />{All}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/mydrive`} className="btn btn-light"><Clock />{MYDRIVE}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/favoris-drive`} className="btn btn-light"><Star />{Favourites}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/shares-with-me`}  className="btn btn-light"><AlertCircle />{Sharedwithme}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/deleted-drive`}  className="btn btn-light active"><Trash2 />{Deleteds}</Link>
                    </li>
                  </ul>
                  <hr />
                  {/* <ul>
                    <li>
                      <div className="btn btn-outline-primary"><Database />{Storage}</div>
                      <div className="m-t-15">
                        <div className="progress sm-progress-bar mb-1">
                          <div className="progress-bar bg-primary" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p>{"25 GB of 100 GB used"}</p>
                      </div>
                    </li>
                  </ul> */}
             
                  {/*   <hr /> <ul>
                    <li>
                      <div className="btn btn-outline-primary"><Grid />{PricingPlan}</div>
                    </li>
                    <li>
                      <div className="pricing-plan">
                        <h6>{TrialVersion} </h6>
                        <h5>{FREE}</h5>
                        <p>{"100 GB Space"}</p>
                        <div className="btn btn-outline-primary btn-xs">{Selected}</div>
                        <img className="bg-img" src={require("../../../assets/images/dashboard/folder.png")} alt="" />
                      </div>
                    </li>
                    <li>
                      <div className="pricing-plan">
                        <h6>{Premium}</h6>
                        <h5>{"$5/month"}</h5>
                        <p> {"200 GB Space"}</p>
                        <div className="btn btn-outline-primary btn-xs">{ContactUs}</div>
                        <img className="bg-img" src={require("../../../assets/images/dashboard/folder1.png")} alt="" />
                      </div>
                    </li>
                  </ul> */}
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col xl="9" md="12" className="box-col-12">
      
          <div className="file-content">
        <Card>
          <CardHeader>
            <div className="media">
              <Form className="form-inline">
                <FormGroup>
                  <i className="fa fa-search"></i>
                  <Input
                    className="form-control-plaintext"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleChange(e)}
                    placeholder="Recherche..." />
                </FormGroup>
              </Form>
      
              <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
               <ModalHeader toggle={VaryingContentonetoggle}>
                {NewFolder}
               </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Name Folders:"}</Label>
                      <Input onChange={(e) => setFolderName(e.target.value)} value={folderName} className="form-control" type="text" defaultValue=""/>
                    </FormGroup>
                 
                  </Form>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={VaryingContentonetoggle}>{Close}</Button>
                      <Button color="primary" onClick={handleAddFolder}>{AddFolder}</Button>
                </ModalFooter>
          </Modal>
          <Modal isOpen={AddFilesState} toggle={AddFilesStatetoggle}>
               <ModalHeader toggle={AddFilesStatetoggle}>
                {Newfiles}
               </ModalHeader>
                <ModalBody>
                <Card>
                           
                            <CardBody>
                                <Form>
                                    <div className="dz-message needsclick">
                                        <Dropzone
                                            getUploadParams={getUploadParams}
                                            onChangeStatus={handleChangeStatus}
                                            submitButtonDisabled={true}
                                           //onSubmit={handleSubmit}
                                          
                                        />
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={AddFilesStatetoggle}>{Close}</Button>
                       <Button color="primary" onClick={handleSubmit}>{AddFile}</Button> 
                </ModalFooter>
          </Modal>
            </div>
          </CardHeader>
      

            <CardBody className="file-manager">
            <Row>
              <Col lg="6">
              <h4 className="mb-3">{AllFiles}</h4>
              </Col>
                <Col lg="6">
            <div className=" media-body file-box text-right">
                      <select onChange={(e) => setsortState(e.target.value)} className="button btn-primary file-box">
                        <option value="trieraz">Trier par alphabet A-Z</option>
                        <option value="trierza">Trier par alphabet Z-A</option>
                        <option value="trierdate">Trier par date création</option>
                      </select>
                    </div>
                    </Col>
                   
              </Row>
              
              <h6 className="mt-4">{Folders}</h6>

             {loadingfolder === false ?
          folderlist.length > 0  &&   <ul className="folder">
          
          {typeUser === "admin" ? folderlist : folderlist1}

            </ul>
            :
            <ul className="folder">
            {folderlisloading}
            </ul>
            }
          <h6 className="mt-4">{Files}</h6>
          
          {loading === false ?
        filelist2?.length > 0 &&   <ul className="files">
              {filelist2}
   
          </ul>
          :
          <ul className="files">
          {filelisloading }
          </ul>
        }
            </CardBody>
          
        </Card>
      </div>

          </Col>
        </Row>
      </Container>
  
                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionModal} toggle={optionToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  <li>
                      <Link onClick={() =>{
                           showApercuToggle()
                           optionToggle()
                      } } className="btn btn-light"><Eye />{"Apercu"}</Link>
                    </li>
                    <li>
                      <Link onClick={() => {optionToggle();  RecuprerFiles(selectedImage.files_id)}}  className="btn btn-light"><User />{"Récuperer"}</Link>
                    </li>
                   
                  
                
                   
                 
                    
                    <li>
                      <Link onClick={() => {optionToggle();  RemoveFiles(selectedImage.files_id)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionFolderModal} toggle={optionFolderToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  <li>
                      <Link onClick={() =>{
                           showApercuToggle()
                           optionFolderToggle()
                      } } className="btn btn-light"><Eye />{"Apercu"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => {optionFolderToggle();  RecuprerFolder(selectedImage.folder_id)}}   className="btn btn-light"><User />{"Récuperer"}</Link>
                    </li>
                  
                    <li>
                      <Link onClick={() => {optionFolderToggle();  RemoveFolder(selectedImage.folder_id)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
    </Fragment>
  );
}

export default DeletedDrive;