import {
  GET_EVENTS_LIST,
  ADD_TO_MY_EVENTS,
  ADD_NEW_EVENTS,
  REMOVE_EVENTS,
  REMOVE_FROM_MY_EVENTS,
  UPDATE_EVENTS,
  UPDATE_MY_EVENTS,
  UPDATE_STATE_EVENTS,
  ADD_CATEGORY,
  GET_CATEGORY,
  ADD_SOUS_CATEGORY,
  REMOVE_SOUS_CATEGORY,
  UPADTE_CATEGORY,
  REMOVE_CATEGORY,
  GET_SUB_CATEGORY,
  REMOVE_SUB_CATEGORY,
} from "../actionTypes";

const initial_state = {
  subcategory: [],
  eventsmanagerdata: [],
  eventsfournisseurdata: [],
  loading: false,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case GET_SUB_CATEGORY:
      return { ...state, subcategory: action.category, loading: true };

    case REMOVE_SUB_CATEGORY:
      let event_my_event = state.subcategory.filter(
        (item) => action.payload.id !== item.sub_category_id
      );
      return { ...state, subcategory: event_my_event };

    case ADD_SOUS_CATEGORY:
      return {
        ...state,
        subcategory: [...state.subcategory, { ...action.payload.eventdata }],
      };

    default:
      return { ...state };
  }
};
