import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';

export default class Pdf extends Component {
	state = { numPages: null, pageNumber: 1 };

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	goToPrevPage = () =>
		this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
	goToNextPage = () =>
		this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));

	render() {
		const { pageNumber, numPages } = this.state;

		return (
			<div>
				<nav>
					<button onClick={this.goToPrevPage}>Prev</button>
					<button onClick={this.goToNextPage}>Next</button>
				</nav>

				<div style={{ width: 600 }}>
					<Document
						file="https://firebasestorage.googleapis.com/v0/b/intranet-a8e8c.appspot.com/o/racine%2FBook%20a%20Test%20Drive%20%7C%20Volkswagen%20UK.pdf?alt=media&token=429c98e4-7ccb-451d-9540-14229b97f541"
						onLoadSuccess={this.onDocumentLoadSuccess}
					>
						<Page pageNumber={pageNumber} width={600} />
					</Document>
				</div>

				<p>
					Page {pageNumber} of {numPages}
				</p>
			</div>
		);
	}
}