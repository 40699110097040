import {GET_INVENTAIREDIPENSE_LIST,
        ADD_TO_MY_INVENTAIREDIPENSE,
        ADD_NEW_INVENTAIREDIPENSE,
        REMOVE_INVENTAIREDIPENSE,
        REMOVE_FROM_MY_INVENTAIREDIPENSE,
        UPDATE_INVENTAIREDIPENSE,
        UPDATE_MY_INVENTAIREDIPENSE,
        UPDATE_STATE_INVENTAIREDIPENSE,
        GET_INVENTAIREDIPENSE_BY_ID,
        ADD_AUDIT_TO_INVENTAIREDIPENSE,


  } from '../actionTypes'


const initial_state = {
    inventairedipenses : [],
    inventairedipense : {},
    inventairedipensesmanagerdata:[],
    inventairedipensesfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_INVENTAIREDIPENSE_LIST:
            return {...state,inventairedipenses: action.inventairedipenses, loading: true,};
            case GET_INVENTAIREDIPENSE_BY_ID:
                return {...state,inventairedipense: action.payload, loading: true,};
    
        case ADD_TO_MY_INVENTAIREDIPENSE:
            return {...state,myinventairedipensesdata:[...state.myinventairedipensesdata, { ...action.payload.myinventairedipensesdata}]};
        
        case ADD_NEW_INVENTAIREDIPENSE:
       
            state.inventairedipenses.push(action.payload.inventairedipensedata)
        return {...state,inventairedipenses: state.inventairedipenses};
        
        case UPDATE_INVENTAIREDIPENSE:
            return {
                ...state,inventairedipenses: state.inventairedipenses.map(item => item.id === action.payload.id ?
                    { ...item, 
                        id: action.payload.id,
                      
                      ...action.payload.updatedata
                    } : 
                    item
                ) 
            };
           
            case UPDATE_STATE_INVENTAIREDIPENSE:
                return {
                    ...state,inventairedipenses: state.inventairedipenses.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            fournisseurState:action.payload.state
                        } : 
                        item
                    ) 
                };
        case UPDATE_MY_INVENTAIREDIPENSE:
                return {
                    ...state,myinventairedipensesdata: state.myinventairedipensesdata.map(item => item.id === action.payload.id ?
                        { ...item, 
                            id: action.payload.id,
                            fillstar:action.payload.updateMydata.fillstar,
                            image: action.payload.image_url,
                            title: action.payload.updateMydata.title,
                            website_url: action.payload.updateMydata.url,
                            desc: action.payload.updateMydata.desc,
                            collection:action.payload.updateMydata.collection
                        } : 
                        item
                    ) 
                };

        case REMOVE_INVENTAIREDIPENSE:
            let new_inventairedipenses = state.inventairedipenses.filter(item=> action.removeid !== item.id)
            return{...state,inventairedipenses: new_inventairedipenses}
        
        case REMOVE_FROM_MY_INVENTAIREDIPENSE:
            let new_my_inventairedipenses = state.myinventairedipensesdata.filter(item=> action.inventairedipensesid !== item.id)
            return{...state,myinventairedipensesdata: new_my_inventairedipenses}
                
        default: return { ...state };
    }
    
}