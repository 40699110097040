import axios from 'axios';
import { db, urlbackend } from '../../data/config';
import {ADD_TO_MY_FOLDER,
        ADD_NEW_FOLDER,
        REMOVE_FOLDER,
        REMOVE_FROM_MY_FOLDER,
        UPDATE_FOLDER,
        UPDATE_MY_FOLDER,
        GET_FOLDER_LIST_BY_ID,
        UPDATE_FOLDER_TREE,
        SHARE_FOLDER,
        ADD_FOLDER_TO_FAVORIS,
        DELETE_FOLDER_TO_FAVORIS,
        DELETE_FOLDER_FROM_SHARE,
        REMOVE_SUB_FOLDER,
        REMOVE_SHARE_FOLDER,
        REMOVE_MY_SHARE_FOLDER,
        ADD_NEW_SUB_FOLDER,
        RECUPERE_FOLDER,
        REMOVE_FINALY_FOLDER
        } from '../actionTypes';

        import SweetAlert from 'sweetalert2'
export const myfolder = (myfolderdata) => {
    return ({
        type: ADD_TO_MY_FOLDER,
        payload: {myfolderdata}
    })
}
export const newIntialfolder = (folderdata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}folders/create.php`,
        data: JSON.stringify(folderdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

   
   }
   export const updateFirstConnection = (idUser) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}user/update_first_connection.php`,
        data: JSON.stringify({user_id : idUser}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

   
   }
   export const newsubfolder = (folderdata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}folders/create.php`,
        data: JSON.stringify(folderdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier ajoutée',
            'Dossier a été ajoutée avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });


 return ({
    type: ADD_NEW_SUB_FOLDER,
    payload: folderdata
})
}
export const newfolder = (folderdata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    console.log(folderdata)
    axios({
        method: 'post',
        url: `${urlbackend}folders/create.php`,
        data: JSON.stringify(folderdata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier ajoutée',
            'Dossier a été ajoutée avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });


 return ({
    type: ADD_NEW_FOLDER,
    payload: folderdata
})
}

export const updatefolder = (id,updatedata) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
     updatedata.folder_id = id 
    axios({
        method: 'post',
        url: `${urlbackend}folders/update.php`,
        data: JSON.stringify(updatedata),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier Renomé',
            'Dossier a été Renomé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });

    
    return ({
        type: UPDATE_FOLDER,
        payload: {id,updatedata}
    })

}

export const updateTreefolder = (idsrc, iddest) => {
    console.log(idsrc)
    console.log(iddest)
    db.collection("Folders").doc(iddest).update({idfolder : idsrc})
    db.collection("Folders").doc(idsrc).get().then(res => {
        let numberfile = res.data().filesNumber || 0;
        db.collection("Folders").doc(idsrc).update({filesNumber : Number(numberfile) + 1})
    })
    return ({
        type: UPDATE_FOLDER_TREE,
        payload: {idsrc, iddest}
    })

}
export const removefrommysharefolder = (user_id , idfolder) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}folders/removeshare.php`,
        data: JSON.stringify({"user_id" :user_id,"folder_id" : idfolder}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier supprimé',
            'Dossier a été supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
  
    return ({
        type: REMOVE_MY_SHARE_FOLDER,
        payload: {user_id , idfolder}
    })

}
export const removefromsharefolder = (user_id , idfolder) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}folders/removeshare.php`,
        data: JSON.stringify({"user_id" :user_id,"folder_id" : idfolder}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier supprimé',
            'Dossier a été supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
  
    return ({
        type: REMOVE_SHARE_FOLDER,
        payload: {user_id , idfolder}
    })

}
export const shareFolders = (idfolder ,sharedListe, selectedUsertype) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}folders/share.php`,
        data: JSON.stringify({"user_liste" :selectedUsertype}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier partagé',
            'Dossier a été partagé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
  
    return ({
        type: SHARE_FOLDER,
        payload: {idfolder ,sharedListe,selectedUsertype}
    })

}

export const removefromfavorisfolder = (user_id , idfolder) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}folders/removefavoris.php`,
        data: JSON.stringify({"user_id" :user_id,"folder_id" : idfolder}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier supprimé',
            'Dossier a été supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });
  
    return ({
        type: REMOVE_SHARE_FOLDER,
        payload: {user_id , idfolder}
    })

}

export const addFavorisFolders = (id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    const idUser = localStorage.getItem("@intranetidUser")
    axios({
        method: 'post',
        url: `${urlbackend}folders/favoris.php`,
        data: JSON.stringify({"user_id" :idUser,"folder_id" : id}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier favorisé',
            'Dossier a été ajouté au liste favoris avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Dossier déja favorisé',
            'Dossier déja ajouté au liste favoris avec succés',
            'success'
          )
    });
    return ({

        type: ADD_FOLDER_TO_FAVORIS,
        payload: {id, idUser}
    })

}

export const deleteshareFolders = (id) => {
    const idUser = localStorage.getItem("@intranetidUser")
    db.collection("Folders").doc(id).get().then(res => {
let favorisUser = res.data().userListe || []
if (favorisUser.includes(idUser) === true) {
    favorisUser = favorisUser.filter(res => res !== idUser)
    db.collection("Folders").doc(id).update({userListe : favorisUser})
}

    })
    return ({

        type: DELETE_FOLDER_FROM_SHARE,
        payload: {id, idUser}
    })

}
export const deleteFavorisFolders = (id) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    const idUser = localStorage.getItem("@intranetidUser")
        axios({
            method: 'post',
            url: `${urlbackend}folders/removefavoris.php`,
            data: JSON.stringify({"user_id" :idUser,"folder_id" : id}),
            config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
        })
        .then(function (response) {
            //handle success
            SweetAlert.fire(
                'Dossier supprimé',
                'Dossier a été supprimé avec succés',
                'success'
              )
          
        })
        .catch(function (response) {
            //handle error
            console.log("response")
            console.log(response)
        });
      
    
return ({
        type: DELETE_FOLDER_TO_FAVORIS,
        payload: {id}
    })

}
export const updateMyfolder = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_FOLDER,
        payload: {id,updateMydata,image_url}
    })

}

export const removefolderDefinitive = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}folders/deletefinaly.php`,
        data: JSON.stringify({"folder_id" :removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier Supprimé',
            'Dossier a été Supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });


    return ({
        type: REMOVE_FINALY_FOLDER,
         removeid
    })

}
export const removefolder = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}folders/delete.php`,
        data: JSON.stringify({"folder_id" :removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier Supprimé',
            'Dossier a été Supprimé avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });


    return ({
        type: REMOVE_FOLDER,
         removeid
    })

}
export const recupererfolder = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'post',
        url: `${urlbackend}folders/recup.php`,
        data: JSON.stringify({"folder_id" :removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier Recupérer',
            'Dossier a été Recupéré avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });


    return ({
        type: RECUPERE_FOLDER,
         removeid
    })

}
export const removesubfolder = (removeid) => {
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}folders/delete.php`,
        data: JSON.stringify({"folder_id" :removeid}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Dossier ajoutée',
            'Dossier a été ajoutée avec succés',
            'success'
          )
      
    })
    .catch(function (response) {
        //handle error
        console.log("response")
        console.log(response)
    });


    return ({
        type: REMOVE_SUB_FOLDER,
         removeid
    })

}

export const removefoldernotracine = (removeid,idfolder) => {
    db.collection("Folders").doc(removeid).update({stateFolderDeleted : true})
    db.collection("Folders").where("idfolder", "==", removeid).get().then (res => {
        for(let key of res.docs)
        
        db.collection("Folders").doc(key.id).update({stateFolderDeleted : true})
    })
    db.collection("Folders").doc(idfolder).get().then(res => {
        let numberfile = res.data().filesNumber || 0;
        db.collection("Folders").doc(idfolder).update({filesNumber : Number(numberfile) - 1})
    })
    return ({
        type: REMOVE_FOLDER,
         removeid
    })

}

export const removemyfolder = (folderid) => {
    return ({
        type: REMOVE_FROM_MY_FOLDER,
        folderid
    })
}