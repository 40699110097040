import {GET_ORDERS_LIST,
        ADD_TO_MY_ORDERS,
        ADD_NEW_ORDERS,
        REMOVE_ORDERS,
        REMOVE_FROM_MY_ORDERS,
        UPDATE_ORDERS,
        UPDATE_MY_ORDERS,
        UPDATE_STATE_ORDERS,
        GET_ORDERS_FRANCHISE_LIST,
        UPDATE_NOTE_ORDERS,
        GET_ALL_ORDERS_LIST,

  } from '../actionTypes'


const initial_state = {
    orders : [],
    ordersFranchise : [],
    ordersmanagerdata:[],
    ordersfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_ORDERS_LIST:
            return {...state,orders: action.orders, loading: true,};

        case GET_ALL_ORDERS_LIST:
            return {...state,orders : action.orders, loading: true,};
        case GET_ORDERS_FRANCHISE_LIST:
                return {...state,ordersFranchise: action.orders, loading: true,};
    
        case ADD_TO_MY_ORDERS:
            return {...state,myorderdata:[...state.myorderdata, { ...action.payload.myorderdata}]};
        
        case ADD_NEW_ORDERS:
            state.orders.push(action.payload.orderdata)
        return {...state,orders:state.orders};
        
        case UPDATE_ORDERS:
            return {
         
                ...state,orders: state.orders.map(item => item.commande_id === action.payload.id ?
                    { ...item, 
                
                        statetext: action.payload.statetext,
                        state: action.payload.state,
                      
                    } : 
                    item
                ) 
            };
            case UPDATE_NOTE_ORDERS:
                return {
             
                    ...state,orders: state.orders.map(item => item.commande_id === action.payload.id ?
                        { ...item, 
                    note : action.payload.note,
                            statetext: action.payload.statetext,
                            state: action.payload.state,
                          
                        } : 
                        item
                    ) 
                };
            case UPDATE_STATE_ORDERS:
                return {
                    ...state,orders: state.orders.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            orderState:action.payload.state
                        } : 
                        item
                    ) 
                };
        case UPDATE_MY_ORDERS:
                return {
                    ...state,myorderdata: state.myorderdata.map(item => item.id === action.payload.id ?
                        { ...item, 
                            id: action.payload.id,
                            fillstar:action.payload.updateMydata.fillstar,
                            image: action.payload.image_url,
                            title: action.payload.updateMydata.title,
                            website_url: action.payload.updateMydata.url,
                            desc: action.payload.updateMydata.desc,
                            collection:action.payload.updateMydata.collection
                        } : 
                        item
                    ) 
                };

        case REMOVE_ORDERS:
            let order_order = state.orders.filter(item=> action.removeid !== item.commande_id)
            return{...state,orders: order_order}
        
        case REMOVE_FROM_MY_ORDERS:
            let order_my_order = state.myorderdata.filter(item=> action.orderid !== item.id)
            return{...state,myorderdata: order_my_order}
                
        default: return { ...state };
    }
    
}