import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchCategoryApi, fetchGroupApi } from "../../api";
import { WATCH_CATEGORY,GET_CATEGORY, WATCH_GROUP_TRAVAIL, GET_GROUP_TRAVAIL } from "../actionTypes";

function* fetchGroupeAsyn() {
    const Data = yield call(fetchGroupApi);
    yield put({type : GET_GROUP_TRAVAIL ,groupes : Data});
}

export function* watchGroupList() {
    yield takeLatest(WATCH_GROUP_TRAVAIL,fetchGroupeAsyn)
}
