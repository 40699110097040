import {call,put, takeLatest} from "redux-saga/effects";
import { fetchAllConvMessage, fetchChatApi1,fetchChatApi2, fetchChatApi3 } from "../../api";
import { WATCH_ALL_CONV_MESSAGES,WATCH_CHAT_MEMBERS,WATCH_CHAT_SUCCESS,SEND_MESSAGE_WATCHER,REPLY_MESSAGE_WATCHER,CREATE_CHAT_WATCHER, SEND_MESSAGE_CONV_WATCHER, UPDATE_MESSAGE_CONV_WATCHER } from "../../redux/actionTypes";
import { getMembersSuccess,sendMessageToChat,replyMessageToChat,getChats,getChatsSuccess,updateSelectedUser,createChat, getAllConvMessage, sendConvMessage, sendMessageToConv, updateConvMessage} from "../../redux/chap-app/action";


function* fetchChatMemberAsyn() {
    const chatData = yield call(fetchChatApi1);
    yield put(getMembersSuccess(chatData.data));
}

function* fetchChatAsyn({id}) {

    const chatData = yield call(fetchChatApi3,id);
    // const currentUserId = 0;
    // yield put(getChats(chatData.data));
     const online = true;
    // const chats = chatData.data;
    // const chat = chats.data.filter(x => x.users.includes(currentUserId));
    // const selectedUser = chats.data[0].users.find(x => x !== currentUserId)
    let selectedUser =  localStorage.getItem("@intranetidConvMessage");
    console.log(chatData)
    yield put(getChatsSuccess(chatData,selectedUser,online))
    //yield put(updateSelectedUser({selectedUser},online))
}
// function* fetchChatAsyn() {

//     const chatData = yield call(fetchChatApi2);
//     const currentUserId = 0;
//     yield put(getChats(chatData.data));
//     const online = true;
//     const chats = chatData.data;
//     const chat = chats.data.filter(x => x.users.includes(currentUserId));
//     const selectedUser = chats.data[0].users.find(x => x !== currentUserId);
//     yield put(getChatsSuccess(chat,selectedUser,online))
//     yield put(updateSelectedUser(selectedUser,online))
// }

function* fetchAllConvMessageAsyn() {

    const chatData = yield call(fetchAllConvMessage);

    yield put(getAllConvMessage(chatData));
    if( chatData.length > 0){
    
    const selectedUser = chatData[0].users[0];
 localStorage.setItem("@intranetidConvMessage",chatData[0].id);
    const chatsData = yield call(fetchChatApi3);
    let chatData1 = chatData.sort((a,b) => a.lastMessageTime <b.lastMessageTime)
    // const currentUserId = 0;
    // yield put(getChats(chatData.data));
     const online = true;
    yield put(getChatsSuccess(chatData1,selectedUser,online))
    yield put(updateSelectedUser(selectedUser,online))
}
}

function* sendMessageAsyn({currentUserId,currentUserName,currentUserImage, selectedConvId, messageInput, chats, online,files}) {

    
     const now = new Date();
     const time =  now.getHours() + ":" + now.getMinutes();
     const status=online
     
    
        let chat ={
             sender: currentUserId,
             time: time,
             message: messageInput,
             status:true,
             createdAt : now,
             senderUserImage : currentUserImage,
            senderUserName : currentUserName,
            files: files
         };
        // chats.push(chat)
      //   const chatData = yield call(fetchAllConvMessage);
       let lastMessageTime = now;
       let UpdatedAt = now;
         //chat.online = status;
         
       
      
    
    yield put(sendMessageToConv(chat,lastMessageTime,UpdatedAt,selectedConvId))  
    yield put(getChatsSuccess(chats,selectedConvId,online))  
}
// function* sendMessageAsyn({currentUserId, selectedUserId, messageInput, chats, online}) {

//     let chat = chats.find(x => x.users.includes(currentUserId) && x.users.includes(selectedUserId))
//     const now = new Date();
//     const time =  now.getHours() + ":" + now.getMinutes();
//     const status=online
//     if (chat) {
//         chat.messages.push({
//             sender: currentUserId,
//             time: time,
//             text: messageInput,
//             status:true
//         });
//         chat.lastMessageTime = time;
//         chat.online = status;
        
//         let chats_data = (chats.filter(x => x.id !== chat.id));
//         chats_data.splice(0, 0, chat)
//         yield put(getChatsSuccess(chats,selectedUserId,online))  
//    }
//    yield put(sendMessageToChat(currentUserId,selectedUserId,messageInput,chats,online))  
// }

function* updateMessageConvAsyn({data,selectedConvMessage}) {

    //let chat = chats.find(x => x.users.includes(currentUserId) && x.users.includes(selectedUserId))

//     const status=online
//     if (chat) {
//         chat.messages.push({
//             sender: currentUserId,
//             time: time,
//             text: messageInput,
//             status:true
//         });
//         chat.lastMessageTime = time;
//         chat.online = status;
        
//         let chats_data = (chats.filter(x => x.id !== chat.id));
//         chats_data.splice(0, 0, chat)
//         yield put(getChatsSuccess(chats,selectedUserId,online))  
//    }
   yield put(updateConvMessage(data,selectedConvMessage))  
}
function* sendMessageConvAsyn({data}) {

    //let chat = chats.find(x => x.users.includes(currentUserId) && x.users.includes(selectedUserId))
   const now = new Date();
    const time =  now.getHours() + ":" + now.getMinutes();
    data.time= time;
    data.lastMessageTime = now;
//     const status=online
//     if (chat) {
//         chat.messages.push({
//             sender: currentUserId,
//             time: time,
//             text: messageInput,
//             status:true
//         });
//         chat.lastMessageTime = time;
//         chat.online = status;
        
//         let chats_data = (chats.filter(x => x.id !== chat.id));
//         chats_data.splice(0, 0, chat)
//         yield put(getChatsSuccess(chats,selectedUserId,online))  
//    }
   yield put(sendConvMessage(data))  
}
function* replyByUserAsyn({currentUserId, selectedUserId, replyMessage, chats, online}) {
  
    let chat = chats.find(x => x.users.includes(currentUserId) && x.users.includes(selectedUserId))
    const now = new Date();
    const time =  now.getHours() + ":" + now.getMinutes();
    const status= online
    if (chat) {
        chat.messages.push({
            sender: selectedUserId,
            time: time,
            text: replyMessage,
            status:true
        });
        chat.lastMessageTime = time;
        chat.online = status;
        let chats_data = (chats.filter(x => x.id !== chat.id));
        chats_data.splice(0, 0, chat)

        yield put(getChatsSuccess(chats_data,selectedUserId,online));
    }
    yield put(replyMessageToChat(currentUserId, selectedUserId, replyMessage, chats, online));
}

function* createNewChatAsyn({currentUserId, selectedUserId, chats}) {

    yield put(createChat(currentUserId, selectedUserId, chats))
    let conversation = {
        id: chats.length + 1,
        users: [currentUserId, selectedUserId],
        lastMessageTime: "-",
        messages: []
    };
    chats.splice(0, 0, conversation)
    yield put(getChatsSuccess(chats,selectedUserId))
}

export function* WatcherChatApp() {
    yield takeLatest(WATCH_CHAT_MEMBERS,fetchChatMemberAsyn)
    yield takeLatest(WATCH_CHAT_SUCCESS,fetchChatAsyn)
    yield takeLatest(WATCH_ALL_CONV_MESSAGES,fetchAllConvMessageAsyn)
    yield takeLatest(SEND_MESSAGE_WATCHER,sendMessageAsyn)
    yield takeLatest(SEND_MESSAGE_CONV_WATCHER,sendMessageConvAsyn)
    yield takeLatest(UPDATE_MESSAGE_CONV_WATCHER,updateMessageConvAsyn)
    yield takeLatest(REPLY_MESSAGE_WATCHER,replyByUserAsyn)
    yield takeLatest(CREATE_CHAT_WATCHER, createNewChatAsyn)
}


