import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, FormGroup, Progress, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle } from 'react-feather';
import {Link,useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import {Issues,Resolved,Comment,Done,All,Doing,CreateNewProject, CreateNewFranchise, Opened, Closed, CreattionDate, Audits} from '../../../constant'
import { WATCH_FRANCHISE_LIST } from '../../../redux/actionTypes';

const AnnuaireListDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [activeTab,setActiveTab] = useState("1")
  const franchises = useSelector(content => content.Franchiseapp.franchises);
  //const doingProject = useSelector(content => content.Franchiseapp.doing_Project);
  //const doneProject = useSelector(content => content.Franchiseapp.done_Project);
  useEffect(() => {
    dispatch({type: WATCH_FRANCHISE_LIST})
  },[dispatch])
  return (
    <Fragment>
      <Breadcrumb parent="Project" title="Project List" />
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  <Nav tabs className="border-tab">
                    <NavItem><NavLink className={activeTab === "1" ? "active" : ''} onClick={() => setActiveTab("1")}><Target />{All}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "2" ? "active" : ''} onClick={() => setActiveTab("2")}><Info />{Opened}</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><CheckCircle />{Closed}</NavLink></NavItem>
                  </Nav>
                </Col>
                <Col sm="6">
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} to={`${process.env.PUBLIC_URL}/app/franchises/franchiseAdd`}> <PlusCircle />{CreateNewFranchise}</Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      {franchises?.map((item, i) =>
                        <Col sm="4" className="mt-4" key={i}>
                          <div onDoubleClick={() =>{
                          localStorage.setItem("@intranet_franchiseid", item.id)
                          history.push(`${process.env.PUBLIC_URL}/app/franchises/audit-list` ,{franchise : item})
                        
                          }} className="project-box">
                            <span className={`badge ${item?.status === "true" ? 'badge-success' : 'badge-primary'}`}>{item.status == "true" ? 'Open' : 'Closed'}</span>
                            <h6>{item.FranchiseName}</h6>
                            <div className="media" style={{textAlign : 'center'}}>
                              <img className="mr-1 rounded-circle" width="100" style={{width : 50, height : 50}} src={item.franchiseImage} alt="" />
                              <div className="media-body">
                                <p>{item.sites}</p>
                              </div>
                            </div>
                            <p>{item.franchiseBio}</p>
                             <Row className="details">
                              <Col xs="6"><span>{Audits} </span></Col>
                              <Col xs="6" className={item.status === "true" ? 'text-success' : 'text-primary'}>{item.auditListe !== undefined ? item.auditListe.length : 0}</Col>
                              <Col xs="6"><span>{Issues} </span></Col>
                              <Col xs="6" className={item.status === "true" ? 'text-success' : 'text-primary'}>{item.SubFranchiseNumber}</Col>
                             <Col xs="6"> <span>{CreattionDate}</span></Col>
                              <Col xs="6" className={item.status === "true" ? 'text-success' : 'text-primary'}>{item.creationDate}</Col>
                               {/* <Col xs="6"> <span>{Comment}</span></Col>
                              <Col xs="6" className={item.status === "Done" ? 'text-success' : 'text-primary'}>{item.comment}</Col> */}
                            </Row> 
                            {/* <div className="customers">
                              <ul>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img1}`)} alt="" /></li>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img2}`)} alt="" /></li>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img3}`)} alt="" /></li>
                                <li className="d-inline-block ml-2">
                                  <p className="f-12">{`+${item.like} More`}</p>
                                </li>
                              </ul>
                            </div> */}
                            {/* <div className="project-status mt-4">
                              <div className="media mb-0">
                                <p>{item.progress}% </p>
                                <div className="media-body text-right"><span>{Done}</span></div>
                              </div>
                              {item.progress === "100" ?
                                <Progress className="sm-progress-bar" color="success" value={item.progress} style={{ height: "5px" }} />
                                :
                                <Progress className="sm-progress-bar" striped color="primary" value={item.progress} style={{ height: "5px" }} />
                              }

                            </div> */}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                <TabPane tabId="2">
                    <Row>
                      {franchises?.filter(res => res.status === "true").map((item, i) =>
                        <Col sm="4" className="mt-4" key={i}>
                        <div  onDoubleClick={() => history.push(`${process.env.PUBLIC_URL}/app/franchises/franchisesDetails/${item.id}`)} className="project-box">
                          <span className={`badge ${item.status === "true" ? 'badge-success' : 'badge-primary'}`}>{item.status == "true" ? 'Open' : 'Closed'}</span>
                          <h6>{item.FranchiseName}</h6>
                          <div className="media" style={{textAlign : 'center'}}>
                            <img className="mr-1 rounded-circle" width="100" style={{width : 50, height : 50}} src={item.franchiseImage} alt="" />
                            <div className="media-body">
                              <p>{item.sites}</p>
                            </div>
                          </div>
                          <p>{item.franchiseBio}</p>
                           <Row className="details">
                            <Col xs="6"><span>{Issues} </span></Col>
                            <Col xs="6" className={item.status === "true" ? 'text-success' : 'text-primary'}>{item.SubFranchiseNumber}</Col>
                            {/* <Col xs="6"> <span>{Resolved}</span></Col>
                            <Col xs="6" className={item.status === "true" ? 'text-success' : 'text-primary'}>{item.resolved}</Col>
                            <Col xs="6"> <span>{Comment}</span></Col>
                            <Col xs="6" className={item.status === "Done" ? 'text-success' : 'text-primary'}>{item.comment}</Col> */}
                          </Row> 
                          {/* <div className="customers">
                            <ul>
                              <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img1}`)} alt="" /></li>
                              <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img2}`)} alt="" /></li>
                              <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img3}`)} alt="" /></li>
                              <li className="d-inline-block ml-2">
                                <p className="f-12">{`+${item.like} More`}</p>
                              </li>
                            </ul>
                          </div> */}
                          {/* <div className="project-status mt-4">
                            <div className="media mb-0">
                              <p>{item.progress}% </p>
                              <div className="media-body text-right"><span>{Done}</span></div>
                            </div>
                            {item.progress === "100" ?
                              <Progress className="sm-progress-bar" color="success" value={item.progress} style={{ height: "5px" }} />
                              :
                              <Progress className="sm-progress-bar" striped color="primary" value={item.progress} style={{ height: "5px" }} />
                            }

                          </div> */}
                        </div>
                      </Col>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      {franchises.filter(res => res.status === "false").map((item, i) =>
                          <Col sm="4" className="mt-4" key={i}>
                          <div  onDoubleClick={() => history.push(`${process.env.PUBLIC_URL}/app/franchises/franchisesDetails/${item.id}`)} className="project-box">
                            <span className={`badge ${item.status === "true" ? 'badge-success' : 'badge-primary'}`}>{item.status == "true" ? 'Open' : 'Closed'}</span>
                            <h6>{item.FranchiseName}</h6>
                            <div className="media" style={{textAlign : 'center'}}>
                              <img className="mr-1 rounded-circle" width="100" style={{width : 50, height : 50}} src={item.franchiseImage} alt="" />
                              <div className="media-body">
                                <p>{item.sites}</p>
                              </div>
                            </div>
                            <p>{item.franchiseBio}</p>
                             <Row className="details">
                              <Col xs="6"><span>{Issues} </span></Col>
                              <Col xs="6" className={item.status === "true" ? 'text-success' : 'text-primary'}>{item.SubFranchiseNumber}</Col>
                              {/* <Col xs="6"> <span>{Resolved}</span></Col>
                              <Col xs="6" className={item.status === "true" ? 'text-success' : 'text-primary'}>{item.resolved}</Col>
                              <Col xs="6"> <span>{Comment}</span></Col>
                              <Col xs="6" className={item.status === "Done" ? 'text-success' : 'text-primary'}>{item.comment}</Col> */}
                            </Row> 
                            {/* <div className="customers">
                              <ul>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img1}`)} alt="" /></li>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img2}`)} alt="" /></li>
                                <li className="d-inline-block"><img className="img-30 rounded-circle" src={require(`../../../assets/images/${item.customers_img3}`)} alt="" /></li>
                                <li className="d-inline-block ml-2">
                                  <p className="f-12">{`+${item.like} More`}</p>
                                </li>
                              </ul>
                            </div> */}
                            {/* <div className="project-status mt-4">
                              <div className="media mb-0">
                                <p>{item.progress}% </p>
                                <div className="media-body text-right"><span>{Done}</span></div>
                              </div>
                              {item.progress === "100" ?
                                <Progress className="sm-progress-bar" color="success" value={item.progress} style={{ height: "5px" }} />
                                :
                                <Progress className="sm-progress-bar" striped color="primary" value={item.progress} style={{ height: "5px" }} />
                              }

                            </div> */}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </TabPane> 
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AnnuaireListDetail;