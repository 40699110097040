import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import Dropzone from "react-dropzone-uploader";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { addNewProject } from "../../../redux/project-app/action";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  ProjectTitle,
  ClientName,
  ProjectRate,
  ProjectStatus,
  ProgressLevel,
  ProjectSize,
  Small,
  Medium,
  Big,
  StartingDate,
  EndingDate,
  EnterSomeDetails,
  UploadProjectFile,
  Add,
  Cancel,
  Done,
  Doing, Close, SaveChanges
} from "../../../constant";
import {
  WATCH_FRANCHISE_LIST,
  WATCH_SUBFRANCHISE_LIST,
  WATCH_USER_LIST,
} from "../../../redux/actionTypes";
import { firestorage } from "../../../data/config";
import { toast } from "react-toastify";
import moment from "moment";
import { addNProject, addNSousTache, addNTache } from "../../../redux/project/action";

const AddNewSousTache = (props) => {
  const {idprojet,idtache} =  useParams()
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [marque, setMarque] = useState("");
  const [franchise, setFranchise] = useState("");
  const [priority, setPriority] = useState("");
  const [random, setrandom] = useState(0)
  const [myfilee, setMyFile] = useState([])
  const franchises = useSelector((content) => content.Franchiseapp.franchises);
  const subfranchises = useSelector(
    (content) => content.SubFranchiseapp.subfranchises
  );
  console.log(franchises);
  useEffect(() => {
    
  }, []);

  const handleStartDate = (date) => {
    setstartDate(date);
  };

  const handleEndDate = (date) => {
    setendDate(date);
  };

  const getUploadParams = ({ meta, file }) => {

       
    
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/soustaches/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/soustaches`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;

      
        myfils.push({
      url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          console.log(myfils)
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
if (status === "removed"){
let myfileeCopy = myfilee.filter(res => res.name !== meta.name)
setMyFile(myfileeCopy)
}
  }

  const users = useSelector(content => content.Userapp.users);

      const [selectedUser, setSelectedUser] = useState({})
      const [scrollingmodal, setscrollingModal] = useState(false);
      const Scrollmodaltoggle = () => setscrollingModal(!scrollingmodal);

  const AddProject = (data) => {

    if (data !== "" ) {

data.status = "A Faire"

data.comment = []
data.progress = 0

data.createdAt = moment(new Date()).format('yyyy-MM-DD HH:mm')
data.files = myfilee  
data.idtache = idtache  
data.startDate = startDate
data.endDate = endDate
data.priority = priority
data.remarques = []
data.projetId = idprojet
dispatch(addNSousTache(data,idprojet,idtache));
props.history.push(`${process.env.PUBLIC_URL}/app/projet/tache/TacheDeatil/${idprojet}/${idtache}`);

    } else {
      errors.showMessages();
    }
  };
  
 

  return (
    <Fragment>
      <Breadcrumb parent="Projets" title="Créer tâche" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(AddProject)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Nom tâche"}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="title"
                          placeholder="Nom Tâche *"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title && "Nom tâche est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
              
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{StartingDate}</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          selected={startDate}
                          onChange={handleStartDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label>{EndingDate}</Label>
                        <DatePicker
                          className="datepicker-here form-control"
                          selected={endDate}
                          endDate={endDate}
                          onChange={handleEndDate}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col sm="6">
                          <FormGroup>
                            <Label>{"Responsable tâche"}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="responsableName"
                          placeholder="Nom Responsable *"
                          innerRef={register({ required: true })}
                        />
                          <span style={{ color: "red" }}>
                          {errors.responsableName && "Nom responsable est obligatoire"}
                        </span>
                           </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Priorité"}</Label>
                            <select onChange={(e) => setPriority(e.target.value)} className="form-control digits">
                              <option>{"Faible"}</option>
                              <option>{"moyenne"}</option>
                              <option>{"Urgent"}</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Détails tâche"}</Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          name="description"
                          rows="3"
                          innerRef={register({ required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.description && "Détails est obligatoire"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{UploadProjectFile}</Label>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={10}
                          multiple={false}
                          canCancel={false}
                          inputContent="Uploader un fichier"
                          styles={{
                            dropzone: { width: "100%", height: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 text-right">
                      <Link
                          to={`${process.env.PUBLIC_URL}/app/project/project-list`}
                        >
                          <Button color="secondary" className="mr-3">{Cancel}</Button>
                        </Link>
                        <Button color="primary" >
                          {Add}
                        </Button>
                        
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default withRouter(AddNewSousTache);
