import { NOTIFICATIONS } from 'react-feather';
import { db, firestorage } from '../../data/config';
import {ADD_TO_MY_NOTIFICATIONS,
        ADD_NEW_NOTIFICATIONS,
        REMOVE_NOTIFICATIONS,
        REMOVE_FROM_MY_NOTIFICATIONS,
        UPDATE_NOTIFICATIONS,
        UPDATE_MY_NOTIFICATIONS,
        UPDATE_STATE_NOTIFICATIONS,
        UPDATE_NOTE_NOTIFICATIONS,
       } from '../actionTypes';

import SweetAlert from 'sweetalert2'
export const mynotification = (mynotificationdata) => {
    return ({
        type: ADD_TO_MY_NOTIFICATIONS,
        payload: {mynotificationdata}
    })
}
export const NewNOTIFICATIONS = (notificationdata ) => {

  
        db.collection("Notifications").add(notificationdata)
        SweetAlert.fire(
            'Notifications ajoutée',
            'Evénement a été ajoutée avec succés',
            'success'
          )
        //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))

  
    return ({
        type: ADD_NEW_NOTIFICATIONS,
        payload: {notificationdata}
    })
}
export const updatenotification = (id,startdate,enddate) => {
    db.collection("Notifications").doc(id).update({start : startdate+"" , end : enddate+""})
    return ({
        type: UPDATE_NOTIFICATIONS,
        payload: {id,startdate,enddate}
    })

}
export const manageNotif = (id) => {
    db.collection('Notifications').doc(id).update({vue : 1})
    return ({
        type: UPDATE_STATE_NOTIFICATIONS,
        payload: {id}
    })

}
export const updateMynotification = (id,updateMydata,image_url) => {
    return ({
        type: UPDATE_MY_NOTIFICATIONS,
        payload: {id,updateMydata,image_url}
    })

}
export const manageOrder = (id,statetext, state) => {
    db.collection("Orders").doc(id).update({statustext : statetext, status : state}).then(res => {
        SweetAlert.fire(
            'Commande ' + statetext,
            'Commande a été '+statetext+' avec succés',
            'success'
          )
    })
    return ({
        type: UPDATE_NOTIFICATIONS,
        payload: {id,statetext, state}
    })

}

export const removenotification = (removeid) => {
    db.collection('Notifications').doc(removeid).delete()
    return ({
        type: REMOVE_NOTIFICATIONS,
         removeid
    })

}
export const removemynotification = (notificationid) => {
    return ({
        type: REMOVE_FROM_MY_NOTIFICATIONS,
        notificationid
    })
}