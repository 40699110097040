import { db, firestorage, urlbackend } from '../../../data/config'
import {GET_LIST_PRODUCTS_BY_PROVIDER_ID,UPDATE_PRODUCT,REMOVE_PRODUCT,ADD_NEW_PRODUCT,GET_SINGLE_ITEM,DECREMENT_QTY,ADD_TO_CART,GET_LIST,WATCH_PRODUCT_LIST, WATCH_SINGLE_ITEM, GET_LIST_PRODUCTS_BY_PROVIDER, REMOVE_SIZE, REMOVE_FILE_PRODUCT} from '../../actionTypes'

import SweetAlert from 'sweetalert2'
import axios from 'axios'
export const watchfetchProducts = () => ({
    type:WATCH_PRODUCT_LIST
})

export const watchfetchSingleProducts = () => ({
    type:WATCH_SINGLE_ITEM
})


export const fetchProductsByProviderId = (product) => ({
    type: GET_LIST_PRODUCTS_BY_PROVIDER_ID,
    payload: product 
})

export const fetchProductsByProvider = (product) => ({
    type: GET_LIST_PRODUCTS_BY_PROVIDER,
    payload: product 
})
export const fetchProducts = (product) => ({
    type: GET_LIST,
    payload: product 
})

export const getSingleItem = () => ({
    type: GET_SINGLE_ITEM,
    
})

export const removeProducts = (id) => {
   
  
  const token = localStorage.getItem('intranetTypeUsertoken')
  axios({
      method: 'delete',
      url: `${urlbackend}product/delete.php`,
      data: JSON.stringify({"product_id" : id}),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
  })
  .then(function (response) {
      //handle success
      SweetAlert.fire(
          'Supprimé',
          'Votre produit  est supprimé',
          'success'
        )
  


  })
  .catch(function (response) {
      //handle error
      SweetAlert.fire(
          'Erreur',
          'Erreur survenue au cours de suppression veuillez réessayer',
          'success'
        )
     
    
  });
  
   
      return ({
        type: REMOVE_PRODUCT,
        payload: {id}
    })



//setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))

    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl})
}
export const removefile = (id,product_id) => {
   
  
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}product/deletefile.php`,
        data: JSON.stringify({"file_id" : id}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Fichier  est supprimé',
            'success'
          )
    
  
  
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    
     
        return ({
          type: REMOVE_FILE_PRODUCT,
          payload: {id,product_id}
      })
  
  
  
  //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
  
      //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl})
  }

export const removeSize = (id,product_id) => {
   
  
    const token = localStorage.getItem('intranetTypeUsertoken')
    axios({
        method: 'delete',
        url: `${urlbackend}product/deletesize.php`,
        data: JSON.stringify({"size_id" : id}),
        config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
    })
    .then(function (response) {
        //handle success
        SweetAlert.fire(
            'Supprimé',
            'Taille  est supprimé',
            'success'
          )
    
  
  
    })
    .catch(function (response) {
        //handle error
        SweetAlert.fire(
            'Erreur',
            'Erreur survenue au cours de suppression veuillez réessayer',
            'success'
          )
       
      
    });
    
     
        return ({
          type: REMOVE_SIZE,
          payload: {id,product_id}
      })
  
  
  
  //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
  
      //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl})
  }

export const UpdateProduits = (eventdata , id ) => {
    let idFournisseur = localStorage.getItem("@intranetidUser");
    

      const token = localStorage.getItem('intranetTypeUsertoken')
      axios({
          method: 'post',
          url: `${urlbackend}product/update.php`,
          data: JSON.stringify(eventdata),
          config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
      })
      .then(function (response) {
          //handle success
         
      
          SweetAlert.fire(
            'Produit Modifié',
            'Produit a été Modifié avec succés',
            'success'
          )
            setTimeout(() => {
              window.location.reload()
            }, 1500);
    
      })
      .catch(function (response) {
          //handle error
          SweetAlert.fire(
            'Error!',
            'error.',
            'success'
            )
         
        
      });
       
          
           return ({
             type: UPDATE_PRODUCT,
             payload: {eventdata,id}
         })
     
    
    
    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl})
}
export const NewProduits = (eventdata ,avatar) => {
  SweetAlert.showLoading()
    
   console.log(eventdata)
  const token = localStorage.getItem('intranetTypeUsertoken')
  axios({
      method: 'post',
      url: `${urlbackend}product/create.php`,
      data: JSON.stringify(eventdata),
      config: { headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization' : `Bearer ${token}` }}
  })
  .then(function (response) {
      //handle success
     
  
        SweetAlert.fire(
          'Produit ajouté',
          'Le Produit a été ajouté avec succés',
          'success'
        )
        setTimeout(() => {
        //  window.location.reload()
        }, 1500);

  })
  .catch(function (response) {
      //handle error
      SweetAlert.fire(
        'Error!',
        'error.',
        'success'
        )
     
    
  });
   
      
    
      return ({
        type: ADD_NEW_PRODUCT,
        payload: {eventdata}
    })



//setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))


    //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl})
}
export const addToCart = (product, qty) => ({
    type: ADD_TO_CART,
    payload: { product, qty }
})

export const decrementQty = productId => ({
    type: DECREMENT_QTY,
    productId
})

