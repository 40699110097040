import React, { useEffect, useState } from 'react'
import {  Button, Card, CardBody, CardHeader, Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText,  Label,  Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { Accordion } from 'react-bootstrap';
import { Cancel, SaveChanges } from '../../constant';
import { useDispatch } from 'react-redux';
import { addInventaire } from '../../redux/inventairedipense/action';
import { Link } from 'react-router-dom';
const daysmonth = []
const InventaireModal = ({listeDayInventaire,setshowInventaire}) => {
    const [daysinventaire, setdaysinventaire] = useState([])
    const [daysdipense, setdaysdipense] = useState([])
    const [monthWithDays, setmonthWithDays] = useState([])
  const dispatch = useDispatch()
    useEffect(() => {
      let lastDate = moment(localStorage.getItem("@intranetIdFranchiseDateInventaire")).format("YYYYMMDD")
      let lastday = moment(localStorage.getItem("@intranetIdFranchiseDateInventaire")).format("DD")
      let lastmonth =  moment(localStorage.getItem("@intranetIdFranchiseDateInventaire")).format("MM")
      let lastyear = moment(localStorage.getItem("@intranetIdFranchiseDateInventaire")).format("YYYY")
       
      
    
        let now = new Date()
        let nowDate = moment(now).format("YYYYMMDD")
        let nowday = moment(now).format("DD")
        let nowmonth =  moment(now).format("MM")
        let nowyear = moment(now).format("YYYY")
        now.setHours(0,0,0,0)
      
        let monthWithDaysCopy = []
        for(let year = Number(lastyear);year <= Number(nowyear);year++ ){
    
          if(year == Number(nowyear))
        for(let monthdate = Number(lastmonth);monthdate <= Number(nowmonth);monthdate++ ){
     
          if(monthdate === Number(nowmonth) && Number(lastmonth) == Number(nowmonth)){
            let daysOfYear = []
            
            
            for (var d = Number(lastday); d < Number(nowday); d++) {
          
          let dateNow = `${('0' + (d)).slice(-2)}/${('0' + (monthdate)).slice(-2)}/${year}`
          
          
            
              daysOfYear.push({date : dateNow, gain : null, dipense :  null});
            
          
            
           
          }
          monthWithDaysCopy.push({daysListeInventaire : daysOfYear, monthNumber : monthdate, id : `${year}${('0' + (monthdate)).slice(-2)}`,year :year, month : monthdate ,daysOfMonth : daysOfYear, monthName  : moment(new Date(year, monthdate-1)).format("MMM-YYYY"), franchise_id : localStorage.getItem("@intranetIdFranchiseUser")})
          
          }
else if(monthdate === Number(nowmonth)){
  let daysOfYear = []
  
  
  for (var d = 1; d < Number(nowday); d++) {

let dateNow = `${('0' + (d)).slice(-2)}/${('0' + (monthdate)).slice(-2)}/${year}`


  
    daysOfYear.push({date : dateNow, gain : null, dipense :  null});
  

  
 
}
monthWithDaysCopy.push({daysListeInventaire : daysOfYear, monthNumber : monthdate, id : `${year}${('0' + (monthdate)).slice(-2)}`,year :year, month : monthdate ,daysOfMonth : daysOfYear, monthName  : moment(new Date(year, monthdate)).format("MMM-YYYY"), franchise_id : localStorage.getItem("@intranetIdFranchiseUser")})

}
else {
  let daysOfYear = []
  
  
  for (var d = 1; d <= new Date(year, monthdate,0).getDate(); d++) {

let dateNow = `${('0' + (d)).slice(-2)}/${('0' + (monthdate)).slice(-2)}/${year}`


  
    daysOfYear.push({date : dateNow, gain : null, dipense :  null});
  

  
 
}
monthWithDaysCopy.push({daysListeInventaire : daysOfYear, monthNumber : monthdate, id : `${year}${('0' + (monthdate)).slice(-2)}`,year :year, month : monthdate ,daysOfMonth : daysOfYear, monthName  : moment(new Date(year, monthdate)).format("MMM-YYYY"), franchise_id : localStorage.getItem("@intranetIdFranchiseUser")})

}
        }
      else 
      for(let monthdate = Number(lastmonth);monthdate <= Number(nowmonth);monthdate++ ){
     

     
          let daysOfYear = []
          
          
          for (var d = 1; d <= new Date(year, monthdate,0).getDate(); d++) {
        
        let dateNow = `${('0' + (d)).slice(-2)}/${('0' + (monthdate)).slice(-2)}/${year}`
        
        
          
            daysOfYear.push({date : dateNow, gain : null, dipense :  null});
          
        
          
         
        }
        monthWithDaysCopy.push({daysListeInventaire : daysOfYear, monthNumber : monthdate, id : `${year}${('0' + (monthdate)).slice(-2)}`,year :year, month : monthdate ,daysOfMonth : daysOfYear, monthName  : moment(new Date(year, monthdate)).format("MMM-YYYY"), franchise_id : localStorage.getItem("@intranetIdFranchiseUser")})
        
        
                }
      }
       
// for (var d = new Date(2021, 11, 1); d <= now; d.setDate(d.getDate() + 1)) {
//   if()
// daysOfYear.push(moment(new Date(d)).format("DD/MM/YYYY"));
// }
if(monthWithDaysCopy.length === 0 )
setshowInventaire(true)

setmonthWithDays(monthWithDaysCopy)

//setdaysdipense(daysOfYear)
    }, [])
    const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1');
    const addGain = (value, day,month) => {
   
setmonthWithDays(monthWithDays.map(item => item.month === month ?
  { ...item, 
    daysOfMonth: item.daysOfMonth.map(dayhoice => dayhoice.date === day ? 
    {
    ...dayhoice,
    gain : value
    }: dayhoice ) 
    
 
  } : 
  item
) )
    }
    const addDipense = (value, day,month) => {

setmonthWithDays(monthWithDays.map(item => item.month === month ?
  { ...item, 
    daysOfMonth: item.daysOfMonth.map(dayhoice => dayhoice.date === day ? 
    {
    ...dayhoice,
    dipense : value
    }: dayhoice ) 
    
 
  } : 
  item
) )
    }
    const putInventaire = () => {
    
let findvalue = monthWithDays.find(res => res.daysOfMonth.find(item => item.gain === null || item.dipense === null || item.gain.length === 0 || item.dipense.length === 0))
console.log(monthWithDays)
if(findvalue === undefined)
addInventaire(monthWithDays).then(res => {
  if (res === true)
  setshowInventaire(false)
  else 
  alert("verify vos infos")
})
else 
alert("il faut remplir tous les champs")
    }
    return (
        <div>
           <Row>
            <Col lg="12 box-col-12" xl="12 xl-100">
            <Card className="height-equal">
                  
                  <CardBody >
                  <div>
                     <Nav className="nav-pills nav-primary" >

                     { monthWithDays.map((inv,index) => 
                       <NavItem>
                           <NavLink className={PrimarycolorLineTab === index ? 'active' : ''} onClick={() => setPrimarycolorLineTab(index)}><i className="icofont icofont-ui-home"></i>{inv.monthName}</NavLink>
                       </NavItem>
                       
                     )}
                     </Nav>
                     <TabContent activeTab={PrimarycolorLineTab}>
                     { monthWithDays.map((inv,index) => 
                       <TabPane  tabId={index}>
                        
                    { inv.daysOfMonth.map((dayy,index) => 
                  <Row>
                <Col>
            <Form className="form-bookmark needs-validation" >
           
            <div className="form-row">

              <FormGroup className="">
           <Label> Revenu </Label>
            <InputGroup>
               <InputGroupAddon className="form-control" addonType="prepend"><InputGroupText>{dayy.date}</InputGroupText><InputGroupText>{"€"}</InputGroupText></InputGroupAddon>
               <Cleave className="form-control" onChange={(e) => addGain(e.currentTarget.value, dayy.date,inv.month)} value={dayy.gain} options={{  numeral: true,
numeralThousandsGroupStyle: 'thousand'}}
       />
            </InputGroup>
          </FormGroup>
      
             
             
             
            </div>
        
               
          </Form>
         
          </Col>
          <Col>
          <Form className="form-bookmark needs-validation" >
     
            <div className="form-row">

              <FormGroup className="">
              <Label> Dépense  </Label>
            <InputGroup>
               <InputGroupAddon className="form-control" addonType="prepend"><InputGroupText>{dayy.date}</InputGroupText><InputGroupText>{"€"}</InputGroupText></InputGroupAddon>
               <Cleave className="form-control" onChange={(e) => addDipense(e.target.value, dayy.date,inv.month)} value={dayy.dipense} options={{  numeral: true,
numeralThousandsGroupStyle: 'thousand'}}
       />
            </InputGroup>
          </FormGroup>
      
             
             
             
            </div>
            
        
          </Form>
          </Col>    
          </Row>     )}  
                       </TabPane>
                      
                     )}
                     </TabContent> 
                     </div>
                  </CardBody>
                </Card>
                </Col>
                </Row>
                <div className="media-body text-right">
      <Form className="d-inline-flex mr-2">
                <FormGroup className="mb-0 mr-0"></FormGroup>
                <Button color="primary" >{Cancel}</Button>
                <Button color="secondary" className="mr-1" onClick={() => putInventaire()}>{SaveChanges}</Button>
                

                </Form>
                
               
              </div>
              
                        
             {/* <Accordion defaultActiveKey="0">
              <Card>
               
                <CardBody>
                  <div className="default-according">
                  { monthWithDays.map((inv,index) => 
                  <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey={index+1}>
                  <i className="icofont icofont-briefcase-alt-2"></i>{inv.monthName}<span className="digits"></span>
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey={index+1}>
                  <CardBody>
                  { inv.daysOfMonth.map((dayy,index) => 
                  <Row>
                <Col>
            <Form className="form-bookmark needs-validation" >
           
            <div className="form-row">

              <FormGroup className="">
           
            <InputGroup>
               <InputGroupAddon className="form-control" addonType="prepend"><InputGroupText>{dayy}</InputGroupText><InputGroupText>{"€"}</InputGroupText></InputGroupAddon>
               <Cleave className="form-control" options={{  numeral: true,
numeralThousandsGroupStyle: 'thousand'}}
       />
            </InputGroup>
          </FormGroup>
      
             
             
             
            </div>
        
               
          </Form>
         
          </Col>
          <Col>
          <Form className="form-bookmark needs-validation" >
     
            <div className="form-row">

              <FormGroup className="">
           
            <InputGroup>
               <InputGroupAddon className="form-control" addonType="prepend"><InputGroupText>{dayy}</InputGroupText><InputGroupText>{"€"}</InputGroupText></InputGroupAddon>
               <Cleave className="form-control" options={{  numeral: true,
numeralThousandsGroupStyle: 'thousand'}}
       />
            </InputGroup>
          </FormGroup>
      
             
             
             
            </div>
            
        
          </Form>
          </Col>    
          </Row>     )}      </CardBody>
            </Accordion.Collapse>
          </Card> )}
                  </div>
                </CardBody>
              </Card>
            </Accordion> */}
         
        </div>
    )
}

export default InventaireModal
