import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchCategoryApi, fetcheventCategoryApi } from "../../api";
import { WATCH_CATEGORY,GET_CATEGORY, GET_EVENT_CATEGORY, WATCH_EVENT_CATEGORY } from "../../redux/actionTypes";

function* fetchCategorieAsyn() {
    const Data = yield call(fetcheventCategoryApi);
    yield put({type : GET_EVENT_CATEGORY ,category : Data});
}

export function* watchEventCategorieList() {
    yield takeLatest(WATCH_EVENT_CATEGORY,fetchCategorieAsyn)
}
