import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Media, CardBody, Form, FormGroup, ModalHeader, ModalBody, ModalFooter, Modal, Label, Input, Button, Table } from 'reactstrap'
import {ContactUs,Follower,Following, Adresse, Dashboard, Summary, Daily, Weekly, Monthly, Yearly, ReferralEarning, CashBalance, SalesForcasting, Save, Cancel} from '../../../constant'
import { useLocation } from 'react-router';

import "react-toggle/style.css"
import { useDispatch } from 'react-redux';

import ApexCharts from 'react-apexcharts'

import { Currentlysale} from '../../dashboard/chartsData/apex-charts-data'
import { fetchallInventairesDipensesByIdApi, fetchDispensesApi, fetchInventairesannualleDipensesByIdApi, fetchInventairesApi, fetchInventairesDipensesByIdApi, fetchInventairesJouDipensesByIdApi, fetchInventairesmensulleDipensesByIdApi, fetchSubfranchiseByidManagerApi } from '../../../api';
import { addDemandes, adddipenses, addinventaires, manageStateSubfranchise } from '../../../redux/subfranchise/action';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import ConfigDB from '../../../data/customizer/config';
import moment from 'moment';
import { addInventaire,addInventaireFromDetail } from '../../../redux/inventairedipense/action';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;


const SubFranchiseDetailManager = (props) => {


  const [typeInventaire, setTypeInventaire] = useState("daily");
  const [valInventaire, setValInventaire] = useState("");
  const [typeDipenses, setTypeDipenses] = useState("daily");
  const [valDipenses, setValDipenses] = useState("");

  const [startDate, setstartDate] = useState("");
  const [selectedMois, setSelectedMois] = useState("Janvier");
  const [textDemande, setTextDemande] = useState("");
  const [endDate, setendDate] = useState("");
  const [subfranchise, setSubfranchise] = useState({});
  const [dipenses, setDipenses] = useState([]);
  const [inventaires, setInventaires] = useState([]);
  const [franchise, setFranchise] = useState({});
  const [revenueanuelle,setrevenueanuelle] = useState(0)
  const [revenuemensuelle,setrevenuemensulle] = useState(0)
  const [revenuejournalier,setrevenuejournalier] = useState(0)
  const [dipenseanuelle,setdipenseanuelle] = useState(0)
  const [dipensemensuelle,setdipensemensulle] = useState(0)
  const [dipensejournalier,setdipensejournalier] = useState(0)


  const [activetab,setActivetab] = useState("journalier")

   
  const [listeDayInventaire,setlisteDayInventaire] = useState([])
  const [listemonthInventaire,setlisteMonthInventaire] = useState({monthcat : [],dipenseVal : [],gainVal : []})
  const [listeAnnuaireInventaire,setlisteAnnuaireInventaire] = useState({yearcat : [],dipenseVal : [],gainVal : []})
  
  const [searchText, setSearchText] = useState("")
  const [journalierchartstate, setjournalierchartstate] = useState({
    datadip : [],datagain : [],datacat :[]
  })

  
  const [modalAddInventaire, setmodalAddInventaire] = useState(false);
  const tooglemodalAddInventaire = () => setmodalAddInventaire(!modalAddInventaire)
  const [modalAddDispense, setmodalAddDispense] = useState(false);
  const [modalDemande, setmodalDemande] = useState(false);
  const tooglemodalAddDispense = () => setmodalAddDispense(!modalAddDispense)
  const tooglemodalDemande = () => setmodalDemande(!modalDemande)


  
  useEffect(() => {

    fetchSubfranchiseByidManagerApi().then(res => {
      console.log()
      if(res.franchise_name != undefined){
      setSubfranchise(res)
      getInventairelisteByIdFranchise(res.subfranchise_id)
      fetchallInventairesDipensesByIdApi(res.subfranchise_id).then(res => {
        setInventaires(res)
      })
   //   fetchDispensesApi(res[0].id).then(res => setDipenses(res))
    //  fetchInventairesApi(res[0].id).then(res => setInventaires(res))
      }
    })
  
   //setFranchise(location.state.franchise)
   //setSubfranchise(location.state.subfranchise)
 }, []);

  const dispatch = useDispatch()
  const putInventaire = () => {
    if(valInventaire.length > 0 && typeDipenses.length > 0){
    let dateNow = moment(new Date()).format("DD/MM/YYYY")
    let monthNow = moment(new Date()).format("YYYYMM")
    let year = moment(new Date()).format("YYYY")
    let monthname = moment(new Date()).format("MMM-YYYY")
 let data = {date : dateNow, gain : valInventaire, dipense :  valDipenses, id : monthNow,franchise_id :subfranchise.subfranchise_id ,year : year,monthName : monthname }


 addInventaireFromDetail(data,dateNow).then(res => {
      if (res === true){

  
      tooglemodalAddInventaire()
      getInventairelisteByIdFranchise(subfranchise.subfranchise_id)
      fetchallInventairesDipensesByIdApi(subfranchise.subfranchise_id).then(res => {
        setInventaires(res)
      })
      }
      else 
      alert("verify vos infos")
    })}
    else 
    alert("il faut remplir tous les champs")
        }
const addInventairee = () => {
  if (typeInventaire === "monthly"){
    let data = {}
    data.typeInventaire= typeInventaire
    data.valInventaire= valInventaire
    data.selectedDate= selectedMois
    data.valTypeinv= "mensuel"
    data.creationDate = new Date().toISOString()
    data.subfranchiseid = subfranchise.id
    dispatch(addinventaires(subfranchise.id, data))
  }  if (typeInventaire === "daily"){
    let data ={}
    data.typeInventaire= typeInventaire
    data.valInventaire= valInventaire
    data.valTypeinv= "journalier"
    data.selectedDate= moment(new Date()).format("DD/MM/YYYY")
    data.creationDate = new Date().toISOString()
    data.subfranchiseid = subfranchise.id
    dispatch(addinventaires(subfranchise.id, data))
  }
  else if (typeInventaire === "weekly"){
    let data ={}
    data.typeInventaire= typeInventaire
    data.valInventaire= valInventaire
    data.valTypeinv= "hibdomadaire"
    data.selectedDate= moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY") 
    data.creationDate = new Date().toISOString()
    data.subfranchiseid = subfranchise.id
    dispatch(addinventaires(subfranchise.id, data))
  }
  tooglemodalAddInventaire()
 
  fetchInventairesApi(subfranchise.id).then(res => setInventaires(res))
}
const addDemande = () => {
  
    let data ={}
    data.textDemande= textDemande

    data.creationDate = new Date().toISOString()
    data.subfranchiseid = subfranchise.id
    dispatch(addDemandes(subfranchise.id, data))

  tooglemodalDemande()
 
 
}
const addDipenses = () => {
  if (typeInventaire === "monthly"){
    let data = {}
    data.typeDipenses= typeDipenses
    data.valTypeDipenses= "Mensuel"
    data.valDipenses= valDipenses
    data.selectedDate= selectedMois
    data.creationDate = new Date().toISOString()
    data.subfranchiseid = subfranchise.id
    dispatch(adddipenses(subfranchise.id, data))
  }  if (typeInventaire === "daily"){
    let data ={}
    data.typeDipenses= typeDipenses
    data.valDipenses= valDipenses
    data.valTypeDipenses= "Journalier"
    data.selectedDate= moment(new Date()).format("DD/MM/YYYY")
    data.creationDate = new Date().toISOString()
    data.subfranchiseid = subfranchise.id
    dispatch(adddipenses(subfranchise.id, data))
  }
  else if (typeDipenses === "weekly"){
    let data ={}
    data.typeDipenses= typeDipenses
    data.valTypeDipenses= "hibdomadaire"
    data.valDipenses= valDipenses
    data.selectedDate= moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY") 
    data.creationDate = new Date().toISOString()
    data.subfranchiseid = subfranchise.id
    dispatch(adddipenses(subfranchise.id, data))
  }
  fetchDispensesApi(subfranchise.id).then(res => setDipenses(res))
  tooglemodalAddDispense()
}
const getInventairelisteByIdFranchise = (idSubfranchise) => {
   let now =new Date()
    let lastdate = moment(now).format("YYYYMM")
    let year = now.getFullYear()
    fetchInventairesJouDipensesByIdApi(idSubfranchise,lastdate,year).then(res => {
      console.log(res)
  
  let journaliercharts = {}
  let journalierchartsgain = []
  
  
  let inventaireuser = res
  if(inventaireuser.length > 0){
  
    let datadip = []
    let datagain = []
    let datacat = []
    let toatlrevenue = 0
    let toatldipense = 0
  
    inventaireuser?.map((daysinver,idaysinventaire) => {
  datadip.push(Number(daysinver.dipense.replace(',', '')))
  datagain.push(Number(daysinver.gain.replace(',', '')))
  datacat.push(daysinver.date)
  toatlrevenue +=Number(daysinver.gain.replace(',', ''))
  toatldipense +=Number(daysinver.dipense.replace(',', ''))
    })
    setrevenuejournalier(toatlrevenue)
    setdipensejournalier(toatldipense)
    setjournalierchartstate({
      datadip : datadip,datagain : datagain,datacat :datacat
    })
  
  }
  else {
    setrevenuejournalier(0)
    setdipensejournalier(0)
    setjournalierchartstate({
      datadip : [],datagain : [],datacat :[]
    })
  }
  })
  fetchInventairesmensulleDipensesByIdApi(idSubfranchise,year).then(res => {
    console.log(res)
  let mensuellechartscat = []
  let mensuellechartsdipense = []
  let mensuellechartsgain = []
  
  let toatlrevenuemen = 0
  let toatldipensemen = 0
  res.map((inventaire ,index) =>{
    toatlrevenuemen = +inventaire.gain
    toatldipensemen = +inventaire.dipense
  mensuellechartscat.push(inventaire.monthName)
  mensuellechartsdipense.push(inventaire.dipense)
  mensuellechartsgain.push(inventaire.gain)
  
  })
  setrevenuemensulle(toatlrevenuemen)
  setdipensemensulle(toatldipensemen)
  
  
    setlisteMonthInventaire({monthcat : mensuellechartscat,dipenseVal : mensuellechartsdipense,gainVal : mensuellechartsgain})
    })
    fetchInventairesannualleDipensesByIdApi(idSubfranchise).then(res => {
      console.log(res)
  
    
    let annuellechartscat = []
    let annuellechartsdipense = []
    let annuellechartsgain = []
       let toatlrevenueann = 0
      let toatldipenseann = 0
      res.map((inventaire ,index) => {
        toatlrevenueann = +inventaire.gain
        toatldipenseann = +inventaire.dipense
    
            annuellechartscat.push(inventaire.year)
            annuellechartsdipense.push({year : inventaire.year , totaldipense : inventaire.dipense})
            annuellechartsgain.push({year : inventaire.year , totalgain : inventaire.gain})
          })
      
        
      
        setrevenueanuelle(toatlrevenueann)
        setdipenseanuelle(toatldipenseann)
        setlisteAnnuaireInventaire({yearcat : annuellechartscat,dipenseVal : annuellechartsdipense,gainVal : annuellechartsgain})
    })
}
  return (
    <Fragment>
      <Breadcrumb parent="Enseignes" title="Détails Franchisé ou restaurant" />
      <Container fluid={true}>
      <div className="media-body text-right">
      <Form className="d-inline-flex mr-2">
                <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} onClick={() => tooglemodalAddInventaire() }>{"Ajouter  Inventaire"}</Link>
                

                </Form>
                {/* <Form className="d-inline-flex mr-2">
                <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} onClick={() => tooglemodalAddDispense() }>{"Ajouter dipense"}</Link>
                

                </Form> */}
                <Form className="d-inline-flex">

                <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }}  to={`${process.env.PUBLIC_URL}/app/franchises/subfranchiseAdd`}> {"Demande modification"}</Link>
               
</Form>
              </div>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <CardHeader className="cardheader" style={{backgroundImage :`url(${subfranchise.franchise_image})`,backgroundRepeat: 'no-repeat' }}></CardHeader>
                <div className="user-image">
                  <div className="avatar"><Media body alt="" src={subfranchise ? subfranchise.manager_image : require('../../../assets/images/user/7.jpg')} data-intro="This is Profile image" /></div>
                  <div className="icon-wrapper" data-intro="Change Profile image here">
                    {/* <i className="icofont icofont-pencil-alt-5">
                      <input className="upload" type="file" onChange={(e) => readUrl(e)} />
                    </i> */}
                  </div>
                </div>
                <div className="info">
                  <Row>
                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                      <Row >
                        <Col md="6">
                          <div className="ttl-info text-left">
                            <h6><i className="fa fa-align-justify mr-2"></i> {"Nom Franchise"}</h6><span>{subfranchise.franchise_name}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-calendar"></i>{"Description"}</h6><span>{subfranchise.subfranchise_bio}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                      <div className="user-designation">
                        <div className="title"><a>{subfranchise.manager_name}</a></div>
                      
                      </div>
                    </Col>
                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                      <Row>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-xs-mt">
                            <h6><i className="fa fa-phone"></i> {ContactUs}</h6><span>{subfranchise.phone_number}</span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ttl-info text-left ttl-sm-mb-0">
                            <h6><i className="fa fa-location-arrow"></i> {Adresse}</h6><span>{subfranchise.adresse}</span>
                          </div>
                        </Col>
                        {/* <Col md="4">
                        <div className="ttl-info text-left ttl-sm-mb-0" data-intro="This is your Social details">
                        <h6>{"Désactiver"}</h6>
                  <Toggle
  //defaultChecked={stateUser}
  value={stateUser}
  aria-label='Activate'
  onChange={handleActivateChange} 
 />


                  </div>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                 
                  {/* <div className="social-media step4" data-intro="This is your Social details">
                    <ul className="list-inline">
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                      <li className="list-inline-item"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                    </ul>
                  </div>
                  <div className="follow">
                    <Row>
                      <Col col="6" className="text-md-right border-right">
                        <div className="follow-num counter">{"25869"}</div><span>{Follower}</span>
                      </Col>
                      <Col col="6" className="text-md-left">
                        <div className="follow-num counter">{"659887"}</div><span>{Following}</span>
                      </Col>
                    </Row>
                  </div> */}
                </div>
              </Card>
            </Col>
            <Col xl="12 xl-100" className="dashboard-sec box-col-12">
            <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                <Col xl="12" className="p-0">
                    <div className="chart-right">
                      <Row className="m-0 p-tb">
                        <Col xl="8" md="8" sm="8" className="col-12 p-0">
                          <div className="inner-top-left">
                            <ul className="d-flex list-unstyled">
                              <li onClick={() => setActivetab('journalier')} className={ activetab === 'journalier' ? 'active' : ''}>{Daily}</li>
                             
                              <li onClick={() => setActivetab('mensuelle')} className={ activetab === 'mensuelle' ? 'active' : ''}>{Monthly}</li>
                              <li onClick={() => setActivetab('annuelle')} className={ activetab === 'annuelle' ? 'active' : ''}>{Yearly}</li>
                            </ul>
                          </div>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col xl="12">
                          <CardBody className="p-0">
                            <div className="current-sale-container">
                            {activetab === 'journalier' ? 
                              <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: journalierchartstate.datacat,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 20,
        right: 0,
        bottom: 0,
        top: -20
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépense',
    data: journalierchartstate.datadip
  }, {
    name: 'Gain',
    data: journalierchartstate.datagain
  }]} type='area' height={240} /> 
  : activetab === 'hebdomadaire' ?  <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: journalierchartstate.datacat,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 20,
        right: 0,
        bottom: 0,
        top: -20
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépense',
    data: journalierchartstate.datadip
  }, {
    name: 'Gain',
    data: journalierchartstate.datagain
  }]} type='area' height={240} /> :  activetab === 'mensuelle' ? <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: listemonthInventaire.monthcat,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 20,
        right: 0,
        bottom: 0,
        top: -10
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépense',
    data: listemonthInventaire.dipenseVal
  }, {
    name: 'Gain',
    data: listemonthInventaire.gainVal
  }]} type='area' height={240} /> : <ApexCharts id="chart-currently"  options={ {
    chart: {
      height: 240,
      type: 'area',
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category',
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      categories: listeAnnuaireInventaire.yearcat,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    markers: {
      strokeWidth: 3,
      colors: "#ffffff",
      strokeColors: [primary, secondary],
      hover: {
        size: 6,
      }
    },
    yaxis: {
      low: 0,
      offsetX: 0,
      offsetY: 0,
      show: false,
      labels: {
        low: 0,
        offsetX: 0,
        show: false,
      },
      axisBorder: {
        low: 0,
        offsetX: 0,
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 20,
        right: 0,
        bottom: 0,
        top: -40
      }
    },
    colors: [primary, secondary],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    legend: {
      show: false,
    },

  }} series={[{
    name: 'Dépense',
    data: listeAnnuaireInventaire.dipenseVal.map(res => res.totaldipense)
  }, {
    name: 'Gain',
    data: listeAnnuaireInventaire.gainVal.map(res => res.totalgain)
  }]} type='area' height={240} />}
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <Row className="border-top m-0">
                      <Col xl="4" md="6" sm="6" className="pl-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-sort-alt"></i></div>
                          <div className="media-body">
                            <h6>{"Total Revenus"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{revenuejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{revenuemensuelle}</p> : activetab === 'mensuelle' ?   <p>{revenuemensuelle}</p>:  <p>{revenueanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="6" sm="6">
                        <div className="media p-0">
                          <div className="media-left bg-secondary"><i className="icofont icofont-sort-alt"></i></div>
                          <div className="media-body">
                            <h6>{"Total Dépenses"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{dipensejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{dipensemensuelle}</p> : activetab === 'mensuelle' ?   <p>{dipensemensuelle}</p>:  <p>{dipenseanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="12" className="pr-0">
                        <div className="media p-0">
                          <div className="media-left"><i className="icofont icofont-cur-euro"></i></div>
                          <div className="media-body">
                            <h6>{"Total Gain"}</h6>
                            {activetab === 'journalier' ? 
                            <p>{revenuejournalier - dipensejournalier}</p>
: activetab === 'hibdomadaire' ?  <p>{revenuemensuelle -  dipensemensuelle}</p> : activetab === 'mensuelle' ?   <p>{revenuemensuelle - dipensemensuelle}</p>:  <p>{revenueanuelle - dipenseanuelle}</p>

}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12">
          <Row>
              <Col sm="6"> 
               <Card>
                                <CardHeader>
                                    <h5>{"Liste Revenue"}</h5>
                                      </CardHeader>
                                <div className="table-responsive">
                                {inventaires.map((inv, i) => ( <>
                                  <h5>{inv.monthName}</h5>
                                    <Table>
                                        <thead>
                                            <tr>
                                
                                                <th scope="col">{"date"}</th>
                                                <th scope="col">{"Valeur"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                         {
                                              inv.daysListeInventaire.map((inccop ,index) =>(
  <tr className="table-primary">
 

  <td>{inccop.date}</td>
  <td>{inccop.gain}</td>
</tr> 
                                          ))}
                                          
                                          
                                        </tbody>
                                    </Table>  </>  ))}
                                </div>
                            </Card></Col>
                            <Col sm="6">  <Card>
                                <CardHeader>
                                    <h5>{"Liste Dépenses"}</h5>
                                        </CardHeader>
                                <div className="table-responsive">
                                {inventaires.map((inv, i) => ( <>
                                  <h5>{inv.monthName}</h5>
                                    <Table>
                                        <thead>
                                            <tr>
                                          
                                                <th scope="col">{"date"}</th>
                                                <th scope="col">{"Valeur"}</th>
                                            </tr>
                                        </thead>
                                      
                                        <tbody>
                           
                                       
                                         {
                                            inv.daysListeInventaire.map((inccop ,index) => (
<tr className="table-primary">


<td>{inccop.date}</td>
<td>{inccop.dipense}</td>
</tr> 
                                        ))}
                                        </tbody>
                                    </Table>
                                  </>  ))}
                                </div>
                            </Card></Col>
            </Row>
        
            </Col>
            {/* <Col sm="12" >
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc1}</p>
                  <div className="img-container">
                    <div id="aniimated-thumbnials">
                      <a href="#javascript">
                        <Media className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img3.png")} alt="gallery" />
                      </a>
                    </div>
                  </div>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc1}</p>
                  <Row className="mt-4 pictures">
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                    <a className="col-sm-6" href="#javascript">
                      <Media body className="img-fluid rounded" src={require("../../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                    </a>
                  </Row>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <p>{UserProfileDesc2}</p>
                  <div className="like-comment mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item border-right pr-3">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                      </li>
                      <li className="list-inline-item ml-2">
                        <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Card>
            </Col> */}

            {/* <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="12" xl="4">
                      <div id="aniimated-thumbnials-3"><a href="#javascript"><Media body className="img-fluid rounded" src={require("../../../assets/images/blog/img.png")} alt="gallery" /></a></div>
                      <div className="like-comment mt-4 like-comment-lg-mb">
                        <ul className="list-inline">
                          <li className="list-inline-item border-right pr-3">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                          </li>
                          <li className="list-inline-item ml-2">
                            <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xl="6">
                      <p>{UserProfileDesc3}</p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col> */}
            <Modal isOpen={modalAddInventaire} toggle={tooglemodalAddInventaire}>
            <ModalHeader toggle={tooglemodalAddInventaire}>{"Ajouter  Inventaire"}</ModalHeader>
              <ModalBody>
              <Form className="form-bookmark needs-validation" onSubmit={(e) => {e.preventDefault(); putInventaire()}} >
                            <div className="form-row">
                            {/* <div className="card-header-right-icon">
                     <select onChange={(e) => setTypeInventaire(e.target.value)} className="button btn btn-primary">
                       <option value="daily">{Daily}</option>
                       <option value="weekly">{Weekly}</option>
                       <option value="monthly">{Monthly}</option>
                     </select>
                   </div> */}
                            <FormGroup className="col-md-12">
                                <Label>{"Revenus"}</Label>
                                <Input className="form-control" value={valInventaire}  onChange={(e) => setValInventaire(e.target.value)} name="title" type="text"  autoComplete="off" />

                              </FormGroup>
                              <FormGroup className="col-md-12">
                                <Label>{"Dépense"}</Label>
                                <Input className="form-control" value={valDipenses}  onChange={(e) => setValDipenses(e.target.value)} name="title" type="text"  autoComplete="off" />

                              </FormGroup>
                              {typeInventaire === "monthly" ? <>
                              <div className="card-header-right-icon">
                     <select onChange={(e) => setSelectedMois(e.target.value)} className="button btn">
                       <option value="1">{"Janvier"}</option>
                       <option value="2">{"Février"}</option>
                       <option value="3">{"Mars"}</option>
                       <option value="4">{"Avril"}</option>
                       <option value="5">{"May"}</option>
                       <option value="6">{"Juin"}</option>
                       <option value="7">{"Juillet"}</option>
                       <option value="8">{"aout"}</option>
                       <option value="9">{"Septembre"}</option>
                       <option value="10">{"Octobre"}</option>
                       <option value="11">{"Novembre"}</option>
                       <option value="12">{"Décembre"}</option>

                     </select>
                   </div></> : <></>}
                              {typeInventaire === "weekly" ? <>
                              <FormGroup className="col-md-12">
                                <Label>{"Date inventaire"}</Label>
                                <Input className="form-control"     value={moment(startDate).format("DD/MM/YYYY") + " - " +moment(endDate).format("DD/MM/YYYY")}     name="url" type="text" autoComplete="off" />
                              
                              </FormGroup>
                     <FormGroup>
                            <Label>{"Date Début"}</Label>
                            <DatePicker   selectsStart="true" selectsEnd="true" className="form-control digits" onWeekSelect  onChange={e => setstartDate(e)} showPopperArrow={false} selected={startDate}  dateFormat="P" />
                          
                     </FormGroup> 
                     <FormGroup>
                            <Label>{"Date Fin"}</Label>
                            <DatePicker   selectsStart="true" selectsEnd="true" className="form-control digits" onWeekSelect  onChange={e => setendDate(e)} showPopperArrow={false} selected={endDate}  dateFormat="P" />
                          
                     </FormGroup> </>: <></>}
                             
                             
                            </div>
                           <div className='text-right'>
                         
                            <Button color="secondary" className="mr-1" onClick={tooglemodalAddInventaire}>{Cancel}</Button>
                            <Button color="primary" >{"Ajouter"}</Button>
                           </div>
                           
                          </Form>

              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>
            <Modal isOpen={modalAddDispense} toggle={tooglemodalAddDispense}>
            <ModalHeader toggle={tooglemodalAddDispense}>{"Ajouter  Dépense"}</ModalHeader>
              <ModalBody>
              <Form className="form-bookmark needs-validation" onSubmit={(e) => {e.preventDefault(); addDipenses()}} >
                            <div className="form-row">
                            <div className="card-header-right-icon">
                     <select onChange={(e) => setTypeDipenses(e.target.value)} className="button btn btn-primary">
                       <option value="daily">{Daily}</option>
                       <option value="weekly">{Weekly}</option>
                       <option value="monthly">{Monthly}</option>
                     </select>
                   </div>
                            <FormGroup className="col-md-12">
                                <Label>{"Valeur Inventaire"}</Label>
                                <Input className="form-control" value={valDipenses}  onChange={(e) => setValDipenses(e.target.value)} name="title" type="text"  autoComplete="off" />

                              </FormGroup>
                              {typeDipenses === "monthly" ? <>
                              <div className="card-header-right-icon">
                     <select onChange={(e) => setSelectedMois(e.target.value)} className="button btn">
                       <option value="Janvier">{"Janvier"}</option>
                       <option value="Février">{"Février"}</option>
                       <option value="Mars">{"Mars"}</option>
                       <option value="Avril">{"Avril"}</option>
                       <option value="May">{"May"}</option>
                       <option value="Juin">{"Juin"}</option>
                       <option value="Juillet">{"Juillet"}</option>
                       <option value="aout">{"aout"}</option>
                       <option value="Septembre">{"Septembre"}</option>
                       <option value="Octobre">{"Octobre"}</option>
                       <option value="Novembre">{"Novembre"}</option>
                       <option value="Décembre">{"Décembre"}</option>

                     </select>
                   </div></> : <></>}
                              {typeDipenses === "weekly" ? <>
                              <FormGroup className="col-md-12">
                                <Label>{"Date inventaire"}</Label>
                                <Input className="form-control"     value={moment(startDate).format("DD/MM/YYYY") + " - " +moment(endDate).format("DD/MM/YYYY")}     name="url" type="text" autoComplete="off" />
                              
                              </FormGroup>
                     <FormGroup>
                            <Label>{"Date Debut"}</Label>
                            <DatePicker   selectsStart="true" selectsEnd="true" className="form-control digits" onWeekSelect  onChange={e => setstartDate(e)} showPopperArrow={false} selected={startDate}  dateFormat="P" />
                          
                     </FormGroup> 
                     <FormGroup>
                            <Label>{"Date Fin"}</Label>
                            <DatePicker   selectsStart="true" selectsEnd="true" className="form-control digits" onWeekSelect  onChange={e => setendDate(e)} showPopperArrow={false} selected={endDate}  dateFormat="P" />
                          
                     </FormGroup> </>: <></>}
                             
                             
                            </div>
                            <div className='text-right'>
                         
                         <Button color="secondary" className="mr-1" onClick={tooglemodalAddDispense}>{Cancel}</Button>
                         <Button color="primary" >{Save}</Button>
                        </div>
                           
                          </Form>

              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>


            <Modal isOpen={modalDemande} toggle={tooglemodalDemande}>
            <ModalHeader toggle={tooglemodalDemande}>{"Ajouter  Demande"}</ModalHeader>
              <ModalBody>
              <Form className="form-bookmark needs-validation" onSubmit={(e) => {e.preventDefault(); addDemande()}} >
                            <div className="form-row">
                          
                            <FormGroup className="col-md-12">
                                <Label>{"Contenu de votre demande de modification"}</Label>
                                <Input className="form-control" value={textDemande}  onChange={(e) => setTextDemande(e.target.value)} name="title" type="text"  autoComplete="off" />

                              </FormGroup>
                            </div>
                            <div className='text-right'>
                         
                         <Button color="secondary" className="mr-1" onClick={tooglemodalDemande}>{Cancel}</Button>
                         <Button color="primary" >{"Ajouter"}</Button>
                        </div>
                          
                          </Form>

              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default SubFranchiseDetailManager;