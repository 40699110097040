import React, { Fragment, useState,useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Dropdown, ButtonGroup, DropdownMenu, DropdownItem, Media } from 'reactstrap'
import { Home, Folder, Clock, Star, AlertCircle, Trash2, Database, Grid, Upload,Share, PlusSquare, Eye, User, Download  } from 'react-feather';
import { toast } from 'react-toastify'
import errorImg from '../../../assets/images/search-not-found.png';
import {BasicModal,Simple,StaticExample,NewMessage,SendMessage,ModalTitle,Close,SaveChanges,VerticallyCentered,TooltipsAndPopovers,UsingTheGrid,SizesModal,LargeModal,SmallModal,ScrollingLongContent,VaryingModalContent,Hometxt,All,Recent,Starred,Recovery,Deleteds,PricingPlan,TrialVersion,FREE,Selected,Premium,ContactUs,AllFiles,RecentlyOpenedFiles,Folders,EndlessAdmin,Files,Storage,AddNew, SharedWithMe, Favourites, AddNewFolder, NewFolder, AddFolder, MultipleImageVideoAudioUpload, AddFile, Newfiles, Partager, Cancel, Title, MYDRIVE, Sharedwithme} from '../../../constant'
import axios from "axios";

import { useDispatch, useSelector } from 'react-redux';
import { WATCH_FILES_LIST, WATCH_FILE_LIST, WATCH_FOLDER_LIST, WATCH_PROVIDER_LIST, WATCH_SUB_FOLDER_LIST, WATCH_USER_LIST } from '../../../redux/actionTypes';
import moment from 'moment';
import { newfolder, removefolder, shareFolders, updateTreefolder } from '../../../redux/foldermanager/action';
import {BrowserRouter,Switch,Route,Redirect, Link} from 'react-router-dom'
import AppfileFragment from './app'
import Test from './test';
import MyDrive from './myDrive';
import { db, firestorage } from '../../../data/config';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory, useParams } from 'react-router';
import SweetAlert from 'sweetalert2'
import { newFile, newFileRacine, removeFile, shareFiles, updateTreefile } from '../../../redux/filemanager/action';
import Dropzone from 'react-dropzone-uploader';
const AllDrive = (props) => {
  const dispatch = useDispatch();
  const {idfolder} = useParams();
  console.log(idfolder)
  const history = useHistory()
  const myfile = useSelector(content => content.Folderapp.folders);
  const users = useSelector(content => content.Userapp.users);
  const myfiles = useSelector(content => content.Fileapp.files);
  const [selectedFile, setSelectedFile] = useState(null)   // Initially, no file is selected  
  const [searchTerm, setSearchTerm] = useState("");
  const [folderName, setFolderName] = useState("");
  const [myfilee, setMyFile] = useState([])
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [AddFilesState, setAddFilesState] = useState(false);
  const [selectedUser, setselectedUser] = useState([]);
  const [random, setrandom] = useState(0);
  const [selectedid, setselectedid] = useState("")
  const [selectedidfolder, setselectedidfolder] = useState("")
  const [searchvalue, setsearchvalue] = useState("")
  const [UserListe, setUserListe] = useState([]);
  const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone);
  const AddFilesStatetoggle = () => setAddFilesState(!AddFilesState);
  const addToggle = () => { setaddModal(!addModal) }
  const [shareModal, setshareModal] = useState(false)
  const shareToggle = () => { setshareModal(!shareModal) }
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const [VaryingContentoneUpdate, setVaryingContentoneUpdate] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const VaryingContentoneUpdatetoggle = () => setVaryingContentoneUpdate(!VaryingContentoneUpdate);
  const [addModal, setaddModal] = useState(false)

  const [idUser, setidUser] = useState("");
  const [typeUser, settypeUser] = useState("");
  const [selectedImage, setselectedImage] = useState({})
  const [showApercu, setshowApercu] = useState(false);
  const showApercuToggle = () => { setshowApercu(!showApercu) }

  const [clientX, setclientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [windowHeight, setwindowHeight] = useState(0);
  const [windowWidth, setwindowWidth] = useState(0);
  const [optionModal, setoptionModal] = useState(false)
  const optionToggle = () => { setoptionModal(!optionModal) } 
  const [optionFolderModal, setoptionFolderModal] = useState(false)
  const optionFolderToggle = () => { setoptionFolderModal(!optionFolderModal) } 
  const [selectedDataDrag, setselectedDataDrag] = useState({})

  const fournisseurs = useSelector(content => content.Fournisseurapp.fournisseurs);
  const [choicefileorfolder, setchoicefileorfolder] = useState("")
  // useEffect(() => {
  //   dispatch({type: WATCH_BOOKMARK_LIST})
  //    axios.get(`${process.env.PUBLIC_URL}/api/files.json`).then((response) => {
  //     setMyFile(response.data);
  //    })
  // },[])
  useEffect(() => {

    setwindowWidth(window.innerWidth)
    setwindowHeight(window.innerHeight)
    window.addEventListener('resize', updateWindowDimensions);

    setidUser(localStorage.getItem("@intranetidUser"))
    settypeUser(localStorage.getItem("@intranetTypeUser"))
    dispatch({type: WATCH_PROVIDER_LIST})
    dispatch({type: WATCH_USER_LIST})
    dispatch({type: WATCH_FOLDER_LIST})
    dispatch({type: WATCH_FILES_LIST})
  },[dispatch])

  const  updateWindowDimensions = () => {
    setwindowWidth(window.innerWidth)
    setwindowHeight(window.innerHeight)
      }

  const getUploadParams = ({ meta, file }) => {
  let folderneme = localStorage.getItem("folderName");
   
    console.log(meta)
    let bytytrans = 0
    const uploadTask =  firestorage.ref(`/${folderneme}/${meta.name}`).put(file);
    
    uploadTask.on('state_changed', 
    (snapShot) => {
     //takes a snap shot of the process as it is happening
   
     
    }, (err) => {
     //catches the errors
     console.log(err)
    }, () => {
    
     // gets the functions from storage refences the image storage in firebase by the children
     // gets the download url then sets the image from firebase as the value for the imgUrl key:
     firestorage.ref(`/${folderneme}`).child(meta.name).getDownloadURL()
      .then(fireBaseUrl => {
        let myfils = myfilee;

      
        myfils.push({
      url : fireBaseUrl,
            name: meta.name,
            size: meta.size,
            type: meta.type,
          
            icon:"fa fa-file-text-o txt-info"
          })
          console.log(myfils)
          setMyFile(myfils)
          toast.success("Fichier Téléchargé avec succés")
       
        console.log(meta.name)
        //setImageUrl(fireBaseUrl)
       
      })})
    
    return { url: 'https://httpbin.org/post' } }
  const handleChangeStatus = ({ meta, file }, status) => {
  }
  const handleSubmit1 = (files, allFiles) => {
 
   myfilee.forEach(element => {
     handleAddFile(element)
   });
      allFiles.forEach(f => f.remove())
      toast.success("Fichiers Télécharger avec succés");
  }
  const handleSubmit = () => {
 
    myfilee.forEach(element => {
      handleAddFile(element)
    });
      // allFiles.forEach(f => f.remove())
       toast.success("Fichiers Télécharger avec succés");
   }
const handleAddFolder = () => {
let folder = {}
folder.folderName = folderName
folder.proprietaireId = idUser
folder.proprietaireType = typeUser
folder.userListe = []
folder.idfolder = "racine";
folder.creationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
folder.folderState = true
folder.downloadState = true
folder.updateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
folder.downloadUrl = ""
folder.icon = "fa fa-file-image-o txt-primary"
folder.filesNumber = 0
folder.foldersNumber = 0
folder.stateFolderDeleted = false
VaryingContentonetoggle()
dispatch(newfolder(folder))
dispatch({type: WATCH_FOLDER_LIST})
}
const handleAddFile = (avatar) => {
  let folder = {}
  folder.fileName = avatar.name
  folder.proprietaireId = idUser
  folder.proprietaireType = typeUser
  folder.userListe = []
  folder.fileImage = avatar.url
  folder.idfolder = "racine";
  folder.creationDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.openedDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.fileState = true
  folder.downloadState = true
  folder.updateDate = moment(new Date()).format('yyyy-MM-DD HH:mm')
  folder.downloadUrl = ""
  folder.stateFolderDeleted = false
  folder.icon = avatar.type.includes("image") === true ?  "fa fa-file-image-o txt-primary" : avatar.type.includes("pdf") === true ? "fa fa-file-pdf-o txt-primary" : avatar.type.includes("xsel") === true ? "fa fa-file-xsel-o txt-primary" : avatar.type.includes("officedocument.") === true ? "fa fa-file-word-o txt-primary" :  "fa fa-file-o txt-primary"
  folder.fileType = avatar.type
  folder.fileSize = avatar.size
  AddFilesStatetoggle()
  dispatch(newFileRacine(folder))
  dispatch({type: WATCH_FILES_LIST})
  }
  const allowDrop = (ev) => {
    ev.preventDefault();
  }
  
  const  drag = (ev, name) => {
    setchoicefileorfolder('folder')
    ev.dataTransfer.setData("text", name);
    console.log(name)
  }
  
  const drop = (ev, name) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    console.log(name)
    if(choicefileorfolder === 'folder'){
    dispatch(updateTreefolder(name, data))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
  }
  else if(choicefileorfolder === 'file') {
    dispatch(updateTreefile(name, data, selectedDataDrag))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
  }
    //ev.target.appendChild(document.getElementById(data));
  }

  const allowDropfile = (ev) => {
    ev.preventDefault();
  }
  const  dragfile = (ev, name, data) => {
    setchoicefileorfolder('file')
    ev.dataTransfer.setData("text", name);
    setselectedDataDrag(data)
    console.log(name)
  }
  const dropfile = (ev, name) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    console.log(name)
    dispatch(updateTreefile(name, data, selectedDataDrag))
    setTimeout(() => {
      dispatch({type: WATCH_SUB_FOLDER_LIST})
      dispatch({type: WATCH_FILE_LIST})
    }, 2000);
    //ev.target.appendChild(document.getElementById(data));
  }

  const handleChange = event => {
    event.preventDefault();
    setSearchTerm(event.target.value)
  };
  const RemoveFolder  = (taskId) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        dispatch(removefolder(taskId));
        SweetAlert.fire(
          'Supprimé!',
          'Votre fichier a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  const RemoveFiles  = (Id, idfolder ) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
       dispatch(removeFile(Id,idfolder));
        SweetAlert.fire(
          'Supprimé!',
          'Votre fichier a été supprimée',
          'success'
        )
      }
      else {
      
      }
    })
  }
  // eslint-disable-next-line
  const filelist = myfile.filter((data) => {
    if(searchTerm == null)
        return data
    else if(data.folderName.toLowerCase().includes(searchTerm.toLowerCase())){
        return data
    }
    }).map((data,i)=>{
      return(
        <li className="file-box" key={i}>
          <div className="file-top"><i className={data.icon} ></i><i className="fa fa-ellipsis-v f-14 ellips"></i></div>
          <div className="file-bottom">
            <h6>{data.folderName}</h6>
            <p className="mb-1">{"12"}</p>
              {/* <p> <b>{"dernière ouverture"} : </b>{data.updateDate}</p> */}
          </div>
        </li>
      )
    })
    const filelist2 = myfiles.filter((data) => {
      if(searchTerm == null)
          return data.stateFolderDeleted === false
      else if(data.fileName.toLowerCase().includes(searchTerm.toLowerCase())){
          return data.stateFolderDeleted === false
      }
      }).map((data,i)=>{
        return(
          <li className="file-box" key={i}>

    
            <div  draggable="true" onDragStart={(event) => dragfile(event, data.id, data)} onDrop={(event) => dropfile(event, data.id)} onDragOver={(event) => allowDropfile(event)} className="file-top"><i className={data.icon} ></i><i className="fa fa-ellipsis-v f-14 ellips" onClick={() => {
              toggle()
              setUserListe(data.userListe)
              setselectedid(data.id)
              }}></i></div>
            <div className="file-bottom">
              <h6>{data.fileName} </h6>
              <p className="mb-1">{data.fileSize} ko</p>
                {/* <p> <b>{"dernière ouverture"} : </b>{data.updateDate}</p> */}
            </div>
            <Dropdown key={i}   className="dropup" >
          
                      <DropdownMenu  className={dropdownOpen === true && data.id === selectedid ? "dropup-content show" : "dropup-content" }>
                          <DropdownItem onClick={() => RemoveFiles(data.id, data.idfolder)}>Delete</DropdownItem>
                          <DropdownItem  onClick={() => {
                            setselectedid(data.id)
                        setselectedidfolder(data.idfolder)
                            VaryingContentoneUpdatetoggle()
                          }} >Update</DropdownItem>
                          <DropdownItem onClick={() => {
                                       setselectedUser(data.userListe)
                                       setselectedid(data.id)
                                       setselectedidfolder(data.idfolder)
                                       shareToggle()
                            
                            }}>Partager</DropdownItem>
                     </DropdownMenu>
                    </Dropdown>
                 
          </li>
        )
      })
      const filelistmanager = myfiles.filter((data) => {
        if(searchTerm == null)
            return data.stateFolderDeleted === false
        else if(data.fileName.toLowerCase().includes(searchTerm.toLowerCase())){
            return data.stateFolderDeleted === false
        }
        }).filter(res => res.proprietaireId === idUser || res.userListe.includes(idUser)).map((data,i)=>{
          return(
            <li className="file-box" key={i}>
  
      
              <div  draggable="true" onDragStart={(event) => dragfile(event, data.id, data)} onDrop={(event) => dropfile(event, data.id)} onDragOver={(event) => allowDropfile(event)} className="file-top"><i className={data.icon} ></i><i className="fa fa-ellipsis-v f-14 ellips" onClick={() => {
                toggle()
                setUserListe(data.userListe)
                setselectedid(data.id)
                }}></i></div>
              <div className="file-bottom">
                <h6>{data.fileName} </h6>
                <p className="mb-1">{data.fileSize} ko</p>
                  {/* <p> <b>{"dernière ouverture"} : </b>{data.updateDate}</p> */}
              </div>
              <Dropdown key={i}   className="dropup" >
            
                        <DropdownMenu  className={dropdownOpen === true && data.id === selectedid ? "dropup-content show" : "dropup-content" }>
                            <DropdownItem onClick={() => RemoveFiles(data.id, data.idfolder)}>Delete</DropdownItem>
                            <DropdownItem  onClick={() => {
                              setselectedid(data.id)
                          setselectedidfolder(data.idfolder)
                              VaryingContentoneUpdatetoggle()
                            }} >Update</DropdownItem>
                            <DropdownItem onClick={() => {
                                         setselectedUser(data.userListe)
                                         setselectedid(data.id)
                                         setselectedidfolder(data.idfolder)
                                         shareToggle()
                              
                              }}>Partager</DropdownItem>
                       </DropdownMenu>
                      </Dropdown>
                   
            </li>
          )
        })
    const folderlist = myfile.filter((data) => {
      if(searchTerm == null)
          return data.stateFolderDeleted === false
      else if(data.folderName.toLowerCase().includes(searchTerm.toLowerCase())){
          return data.stateFolderDeleted === false
      }
      }).map((data,i)=>{
        return(
          <li className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
            <div   draggable="true" onDragStart={(event) => drag(event, data.id)} onDrop={(event) => drop(event, data.id)} onDragOver={(event) => allowDrop(event)} className="media">
    
         <Link onContextMenu={e => {
         
         e.preventDefault();
 

if(e.button === 2){
 setselectedUser(data.userListe)
 
 localStorage.setItem("idfolder", data.id)
 localStorage.setItem("folderName", data.folderName)
 setselectedid(data.id)
 setselectedidfolder(data.idfolder)
 setselectedImage(data)
 optionFolderToggle()
 setclientX(e.clientX)
 setClientY(e.clientY)


}}}  draggable onDoubleClick={() => {
              localStorage.setItem("idfolder", data.id)
              localStorage.setItem("folderName", data.folderName)
          
              history.push(`${process.env.PUBLIC_URL}/app/details-folder-manager/${data.id}`)
          }}  className="media"><i className="fa fa-folder f-36 txt-warning"></i>
         
            <div className="media-body ml-3">
              <h6 className="mb-0">{data.folderName}</h6>
              
                {/* <p> <b>{"dernière ouverture"} : </b>{data.updateDate}</p> */}
            </div>
          </Link>
          <Dropdown key={i}   className="dropup" >
            <i class="fa fa-ellipsis-v f-14 " onClick={() => {
              toggle()
              setUserListe(data.userListe)
              setselectedid(data.id)
              }}></i>
                      <DropdownMenu  className={dropdownOpen === true && data.id === selectedid ? "dropup-content show" : "dropup-content" }>
                          <DropdownItem onClick={() => RemoveFolder(data.id)}>Delete</DropdownItem>
                          <DropdownItem  onClick={() => {
                            setselectedid(data.id)
                        
                            VaryingContentoneUpdatetoggle()
                          }} >Update</DropdownItem>
                          <DropdownItem onClick={() => {
                                       setselectedUser(data.userListe)
                                       setselectedid(data.id)
                        
                            addToggle()
                            
                            }}>Partager</DropdownItem>
                     </DropdownMenu>
                    </Dropdown>
                    </div>
        </li>
        )
      })
      const folderlist1 = myfile.filter((data) => {
        if(searchTerm == null)
            return data.stateFolderDeleted === false
        else if(data.folderName.toLowerCase().includes(searchTerm.toLowerCase())){
            return data.stateFolderDeleted === false
        }
        }).filter(res => (res.proprietaireId === idUser || res.userListe.includes(idUser))).map((data,i)=>{
          return(
            <li onContextMenu={e => {
         
              e.preventDefault();
      
  
    if(e.button === 2){
      setselectedUser(data.userListe)
      
      localStorage.setItem("idfolder", data.id)
      localStorage.setItem("folderName", data.folderName)
      setselectedid(data.id)
      setselectedidfolder(data.idfolder)
      setselectedImage(data)
      optionFolderToggle()
      setclientX(e.clientX)
      setClientY(e.clientY)
  
  
    }}} className="folder-box" style={{marginLeft : 5,marginBottom : 5}}>
            <div   draggable="true" onDragStart={(event) => drag(event, data.id)} onDrop={(event) => drop(event, data.id)} onDragOver={(event) => allowDrop(event)} className="media">
    
         <Link draggable onDoubleClick={() => {
              localStorage.setItem("idfolder", data.id)
              localStorage.setItem("folderName", data.folderName)
          
              history.push(`${process.env.PUBLIC_URL}/app/details-folder-manager/${data.id}`)
          }}  className="media"><i className="fa fa-folder f-36 txt-warning"></i>
         
            <div className="media-body ml-3">
              <h6 className="mb-0">{data.folderName}</h6>
              
                {/* <p> <b>{"dernière ouverture"} : </b>{data.updateDate}</p> */}
            </div>
          </Link>
          <Dropdown key={i}   className="dropup" >
            <i class="fa fa-ellipsis-v f-14 " onClick={() => {
              toggle()
              setUserListe(data.userListe)
              setselectedid(data.id)
              }}></i>
                      <DropdownMenu  className={dropdownOpen === true && data.id === selectedid ? "dropup-content show" : "dropup-content" }>
                          <DropdownItem onClick={() => RemoveFolder(data.id)}>Delete</DropdownItem>
                          <DropdownItem  onClick={() => {
                            setselectedid(data.id)
                        
                            VaryingContentoneUpdatetoggle()
                          }} >Update</DropdownItem>
                          <DropdownItem onClick={() => {
                                       setselectedUser(data.userListe)
                                       setselectedid(data.id)
                        
                            addToggle()
                            
                            }}>Partager</DropdownItem>
                     </DropdownMenu>
                    </Dropdown>
                    </div>
        </li>
          )
        })
  const getFile = () => {
    document.getElementById("upfile").click();
  }

  const onFileChange = event => {
    // Update the state 
    setSelectedFile(event.target.files[0]);
    handleAddFile(event.target.files[0])
  };

  const onFileUpload = () => {
      let myfiles = [...myfile];

      if (selectedFile !== null) {
        myfiles.push({
          id: myfile.length + 1,
          name: selectedFile.name,
          size: `${selectedFile.size}`,
          modify: `${selectedFile.lastModifiedDate}`,
          icon:"fa fa-file-text-o txt-info"
        })
        setMyFile(myfiles)
        toast.success("Fichier Téléchargé avec succés")
      }else {
        toast.error("Veuilez choisir  au moins un fichier")
      }
  };
  const addToSelectedListe = (id) => {
    let selectedUserCopy = selectedUser
    if(selectedUserCopy.includes(id) == true){
      selectedUserCopy.splice(selectedUserCopy.findIndex(res => res == id), 1)
    setselectedUser(selectedUserCopy)
    setrandom(Math.random)
    }
    else {
      selectedUserCopy.push(id)
      setselectedUser(selectedUserCopy)
      setrandom(Math.random)
    }
      }
      const shareFolder = (e) => {
        e.preventDefault()
      
    dispatch(shareFolders(selectedid,selectedUser))
    addToggle()
      }
      const sharefile = (e) => {
        e.preventDefault()
      console.log(selectedidfolder)
    dispatch(shareFiles(selectedid, selectedidfolder ,selectedUser))
    shareToggle()
      }
      const searchUser = (value) =>{
        setsearchvalue(value)
      }
  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="File Manager" />
      <Container fluid={true}>
        <Row>
        <Col xl="3" className="box-col-6 pr-0 file-spacing">
            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager`} className="btn btn-light active"><Folder />{All}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/mydrive`} className="btn btn-light"><Clock />{MYDRIVE}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/favoris-drive`} className="btn btn-light"><Star />{Favourites}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/shares-with-me`}  className="btn btn-light"><AlertCircle />{Sharedwithme}</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/app/file-manager/deleted-drive`}  className="btn btn-light"><Trash2 />{Deleteds}</Link>
                    </li>
                  </ul>
                  <hr />
                  {/* <ul>
                    <li>
                      <div className="btn btn-outline-primary"><Database />{Storage}</div>
                      <div className="m-t-15">
                        <div className="progress sm-progress-bar mb-1">
                          <div className="progress-bar bg-primary" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p>{"25 GB of 100 GB used"}</p>
                      </div>
                    </li>
                  </ul> */}
             
                  {/*   <hr /> <ul>
                    <li>
                      <div className="btn btn-outline-primary"><Grid />{PricingPlan}</div>
                    </li>
                    <li>
                      <div className="pricing-plan">
                        <h6>{TrialVersion} </h6>
                        <h5>{FREE}</h5>
                        <p>{"100 GB Space"}</p>
                        <div className="btn btn-outline-primary btn-xs">{Selected}</div>
                        <img className="bg-img" src={require("../../../assets/images/dashboard/folder.png")} alt="" />
                      </div>
                    </li>
                    <li>
                      <div className="pricing-plan">
                        <h6>{Premium}</h6>
                        <h5>{"$5/month"}</h5>
                        <p> {"200 GB Space"}</p>
                        <div className="btn btn-outline-primary btn-xs">{ContactUs}</div>
                        <img className="bg-img" src={require("../../../assets/images/dashboard/folder1.png")} alt="" />
                      </div>
                    </li>
                  </ul> */}
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col xl="9" md="12" className="box-col-12">
      
          <div className="file-content">
        <Card>
          <CardHeader>
            <div className="media">
              <Form className="form-inline">
                <FormGroup>
                  <i className="fa fa-search"></i>
                  <Input
                    className="form-control-plaintext"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleChange(e)}
                    placeholder="Recherche..." />
                </FormGroup>
              </Form>
              <div className="media-body text-right">
                <Form className="d-inline-flex">
                <div className="btn btn-primary" onClick={VaryingContentonetoggle}> <PlusSquare />{AddNewFolder}</div>
                  <div className="btn btn-primary" onClick={AddFilesStatetoggle}> <PlusSquare />{AddNew}rr</div>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                    <input id="upfile" multiple type="file" onChange={(e) => onFileChange(e)} />
                  </div>

                </Form>
              
              </div>
              <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
               <ModalHeader toggle={VaryingContentonetoggle}>
                {NewFolder}
               </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Name Folders:"}</Label>
                      <Input onChange={(e) => setFolderName(e.target.value)} value={folderName} className="form-control" type="text" defaultValue=""/>
                    </FormGroup>
                 
                  </Form>
                </ModalBody>
                <ModalFooter> 
                  <div className='text-right'>
                      <Button color="secondary" className='mr-1' onClick={VaryingContentonetoggle}>{Close}</Button>
                      <Button color="primary" onClick={handleAddFolder}>{AddFolder}</Button>
                      </div>
                </ModalFooter>
          </Modal>
          <Modal isOpen={AddFilesState} toggle={AddFilesStatetoggle}>
               <ModalHeader toggle={AddFilesStatetoggle}>
                {Newfiles}
               </ModalHeader>
                <ModalBody>
                <Card>
                           
                            <CardBody>
                                <Form>
                                    <div className="dz-message needsclick">
                                        <Dropzone
                                            getUploadParams={getUploadParams}
                                            onChangeStatus={handleChangeStatus}
                                            submitButtonDisabled={true}
                                           //onSubmit={handleSubmit}
                                          
                                        />
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                </ModalBody>
                <ModalFooter>
                      <Button color="secondary" onClick={AddFilesStatetoggle}>{Close}</Button>
                       <Button color="primary" onClick={handleSubmit}>{AddFile}</Button> 
                </ModalFooter>
          </Modal>
            </div>
          </CardHeader>
      

            <CardBody className="file-manager">
              <h4 className="mb-3">{AllFiles}</h4>
             
              
              <h6 className="mt-4">{Folders}</h6>
              {filelist.length > 0 ?
                <ul className="folder">
              
              {typeUser === "admin" ? folderlist : folderlist1}

                </ul>
                :
                ""}
              <h6 className="mt-4">{Files}</h6>
              {filelist2.length > 0 ?
              <ul className="files">
                {filelist2}
              </ul>
              :
              ""
            }
                {filelist2.length > 0 ?
              <ul className="files">
                  {typeUser === "admin" ? filelist2 : filelistmanager}
       
              </ul>
              :
              ""
            }
            </CardBody>
          
        </Card>
      </div>

          </Col>
        </Row>
      </Container>
      <Modal isOpen={addModal} toggle={addToggle} size="lg">
                            <ModalHeader toggle={addToggle}>Recherche utilisateur</ModalHeader>
                            <ModalBody>
                              <Form onSubmit={(e) => shareFolder(e)} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                
                                  <FormGroup className="col-md-12">
                                    <Label>{"rechercher par email ,nom ou prenom"}</Label>
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                  { users.length > 0 ?
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                                {users.filter(x => x.email.includes(searchvalue) == true || x.firstName.includes(searchvalue)== true || x.lastName.includes(searchvalue)== true).map((item, i) => {
                                    return (
                                   
                                      <li  onClick={() => addToSelectedListe(item.id)}  className="clearfix">
                                      <Row>
                                        <Col sm="1" lg="1">
                                        <Media className="rounded-circle user-image" style={{float: 'left',
                                       width: '52px',
                                      height: '52px',
                                      marginRight: '5px'}} src={item.userImage} alt="" />
                                        </Col>
                                      <Col sm="10" lg="10">
                                            <div>
                                              <div className="name">{item.firstName +" "+ item.lastName}</div>
                                              <div  className="status"> {item.email}</div>
                                       </div>
                                       </Col>
                                       <Col sm="1" lg="1">
                                       <div className="checkbox checkbox-primary mr-1">
                                  <Input  id={ i} onClick={() => addToSelectedListe(item.id)} type="checkbox" checked={selectedUser.includes(item.id) == true}  name={item.email} value={item.email} />
                                  <Label for={i}></Label>
                                </div>
                                </Col>
                                </Row>
                                     </li>
                                )
                              
                                })}
                            </ul>
                            </div>
                            :  
                            <Media className="img-fluid m-auto" src={errorImg} alt=""/>
                            }
                                     </FormGroup>
                                </div>
                                <Button color="secondary" className="mr-1">{Partager}</Button>
                                <Button color="primary" onClick={addToggle}>{Cancel}</Button>
                              </Form>
                            </ModalBody>
                          </Modal>
                          <Modal isOpen={shareModal} toggle={shareToggle} size="lg">
                            <ModalHeader toggle={shareToggle}>Recherche utilisateur</ModalHeader>
                            <ModalBody>
                              <Form onSubmit={(e) => sharefile(e)} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                
                                  <FormGroup className="col-md-12">
                                    
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                  { users.length > 0 ?
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                               {typeUser !== "admin" ?     <li  onClick={() => addToSelectedListe("CoP1zf4bqWPwlcrE1luS5ZYtplb2")}  className="clearfix">
                                      <Row>
                                        <Col sm="1" lg="1">
                                
                                        </Col>
                                      <Col sm="10" lg="10">
                                            <div>
                                            <div className="name">{"Administrateur"}</div>
                                              
                                       </div>
                                       </Col>
                                       <Col sm="1" lg="1">
                                       <div className="checkbox checkbox-primary mr-1">
                                  <Input onClick={() => addToSelectedListe("CoP1zf4bqWPwlcrE1luS5ZYtplb2")} type="checkbox" checked={selectedUser.includes("CoP1zf4bqWPwlcrE1luS5ZYtplb2") == true}   />
                                  <Label ></Label>
                                </div>
                                </Col>
                                </Row>
                                     </li> : ""}
                           
                                {users.filter(x => x.email.includes(searchvalue) == true || x.firstName.includes(searchvalue)== true || x.lastName.includes(searchvalue)== true).map((item, i) => {
                                    return (
                                   
                                      <li  onClick={() => addToSelectedListe(item.id)}  className="clearfix">
                                      <Row>
                                        <Col sm="1" lg="1">
                                        <Media className="rounded-circle user-image" style={{float: 'left',
                                       width: '52px',
                                      height: '52px',
                                      marginRight: '5px'}} src={item.userImage} alt="" />
                                        </Col>
                                      <Col sm="10" lg="10">
                                            <div>
                                              <div className="name">{item.firstName +" "+ item.lastName}</div>
                                              <div  className="status"> {item.email}</div>
                                       </div>
                                       </Col>
                                       <Col sm="1" lg="1">
                                       <div className="checkbox checkbox-primary mr-1">
                                  <Input  id={ i} onClick={() => addToSelectedListe(item.id)} type="checkbox" checked={selectedUser.includes(item.id) == true}  name={item.email} value={item.email} />
                                  <Label for={i}></Label>
                                </div>
                                </Col>
                                </Row>
                                     </li>
                                )
                              
                                })}
                                {fournisseurs.filter(x => x.email.includes(searchvalue) == true || x.firstName.includes(searchvalue)== true || x.lastName.includes(searchvalue)== true).map((item, i) => {
                                    return (
                                   
                                      <li  onClick={() => addToSelectedListe(item.id)}  className="clearfix">
                                      <Row>
                                        <Col sm="1" lg="1">
                                        <Media className="rounded-circle user-image" style={{float: 'left',
                                       width: '52px',
                                      height: '52px',
                                      marginRight: '5px'}} src={item.fournisseurImage} alt="" />
                                        </Col>
                                      <Col sm="10" lg="10">
                                            <div>
                                              <div className="name">{item.firstName +" "+ item.lastName}</div>
                                              <div  className="status"> {item.email}</div>
                                       </div>
                                       </Col>
                                       <Col sm="1" lg="1">
                                       <div className="checkbox checkbox-primary mr-1">
                                  <Input  id={ i} onClick={() => addToSelectedListe(item.id)} type="checkbox" checked={selectedUser.includes(item.id) == true}  name={item.email} value={item.email} />
                                  <Label for={i}></Label>
                                </div>
                                </Col>
                                </Row>
                                     </li>
                                )
                              
                                })}
                            </ul>
                            </div>
                            :  
                            <Media className="img-fluid m-auto" src={errorImg} alt=""/>
                            }
                                     </FormGroup>
                                </div>
                                <Button color="secondary" className="mr-1">{Partager}</Button>
                                <Button color="primary" onClick={shareToggle}>{Cancel}</Button>
                              </Form>
                            </ModalBody>
                          </Modal>
                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionModal} toggle={optionToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  <li>
                      <Link onClick={() =>{
                           showApercuToggle()
                           optionToggle()
                      } } className="btn btn-light"><Eye />{"Apercu"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => {optionToggle(); shareToggle()}}  className="btn btn-light"><User />{"Partager"}</Link>
                    </li>
                    <li>
                      <Link   className="btn btn-light" onClick={() => {

                          var httpsReference = firestorage.refFromURL(selectedImage.fileImage);  
                      httpsReference.getDownloadURL().then(res => alert(res))
                           
                      
                        }}><Share />{"Obtenir Le lien"}</Link>
                    </li>
                    <li>
                      <Link  className="btn btn-light"><Star />{"Ajouter au favoris"}</Link>
                    </li>
                    <li>
                      <Link  className="btn btn-light"><AlertCircle />{"Imprimer"}</Link>
                    </li>
                   
                    <li>
                      <Link    onClick={() =>  {optionToggle();  VaryingContentoneUpdatetoggle()}}  className="btn btn-light"><AlertCircle />{"Renommer"}</Link>
                    </li>
                    <li>
                    <a   target="_blank"  href={selectedImage.fileImage}  download={true}  rel="noopener noreferrer" className="btn btn-light"><Download />{"Télécharger"}</a>
                    </li>
                    <li>
                      <Link onClick={() => {optionToggle();  RemoveFiles(selectedImage.id, selectedImage.idfolder)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionFolderModal} toggle={optionFolderToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  <li>
                      <Link onClick={() =>{
                           showApercuToggle()
                           optionToggle()
                      } } className="btn btn-light"><Eye />{"Apercu"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => {optionToggle(); shareToggle()}}  className="btn btn-light"><User />{"Partager"}</Link>
                    </li>
                    <li>
                      <Link   className="btn btn-light" onClick={() => {

                          var httpsReference = firestorage.refFromURL(selectedImage.fileImage);  
                      httpsReference.getDownloadURL().then(res => alert(res))
                           
                      
                        }}><Share />{"Obtenir Le lien"}</Link>
                    </li>
                    <li>
                      <Link  className="btn btn-light"><Star />{"Ajouter au favoris"}</Link>
                    </li>
                    <li>
                      <Link  className="btn btn-light"><AlertCircle />{"Imprimer"}</Link>
                    </li>
                   
                    <li>
                      <Link    onClick={() =>  {optionToggle();  VaryingContentoneUpdatetoggle()}}  className="btn btn-light"><AlertCircle />{"Renommer"}</Link>
                    </li>
                    <li>
                    <a   target="_blank"  href={selectedImage.fileImage}  download={true}  rel="noopener noreferrer" className="btn btn-light"><Download />{"Télécharger"}</a>
                    </li>
                    <li>
                      <Link onClick={() => {optionToggle();  RemoveFiles(selectedImage.id, selectedImage.idfolder)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
                          <Modal style={{marginTop : windowHeight - clientY > 400 ? clientY : clientY-450,marginLeft :  windowWidth - clientX > 300 ? clientX : clientX-300}} backdrop={true} isOpen={optionFolderModal} toggle={optionFolderToggle} size="sm">
                            
                            <ModalBody>
                            <div className="file-sidebar">
              <Card>
                <CardBody>
                  <ul>
                  <li>
                      <Link onClick={() =>{
                           showApercuToggle()
                           optionToggle()
                      } } className="btn btn-light"><Eye />{"Apercu"}</Link>
                    </li>
                    <li>
                      <Link  onClick={() => {optionToggle(); shareToggle()}}  className="btn btn-light"><User />{"Partager"}</Link>
                    </li>
                    <li>
                      <Link   className="btn btn-light" onClick={() => {

                          var httpsReference = firestorage.refFromURL(selectedImage.fileImage);  
                      httpsReference.getDownloadURL().then(res => alert(res))
                           
                      
                        }}><Share />{"Obtenir Le lien"}</Link>
                    </li>
                    <li>
                      <Link  className="btn btn-light"><Star />{"Ajouter au favoris"}</Link>
                    </li>
                    <li>
                      <Link  className="btn btn-light"><AlertCircle />{"Imprimer"}</Link>
                    </li>
                   
                    <li>
                      <Link    onClick={() =>  {optionToggle();  VaryingContentoneUpdatetoggle()}}  className="btn btn-light"><AlertCircle />{"Renommer"}</Link>
                    </li>
                    <li>
                    <a   target="_blank"  href={selectedImage.fileImage}  download={true}  rel="noopener noreferrer" className="btn btn-light"><Download />{"Télécharger"}</a>
                    </li>
                    <li>
                      <Link onClick={() => {optionToggle();  RemoveFiles(selectedImage.id, selectedImage.idfolder)}} className="btn btn-light"><Trash2 />{"Supprimer"}</Link>
                    </li>
                  </ul>
                  <hr />
                
                </CardBody>
              </Card>
            </div>
                            </ModalBody>
                          </Modal>
    </Fragment>
  );
}

export default AllDrive;