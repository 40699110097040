import {firebase_app} from '../data/config';
import user from '../assets/images/user/user.png';

const db = firebase_app.firestore();


export const createCategory = (value) => {

  return   db.collection("Franchises")
    .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').add({
       
        name: value,
        contactListe : []
    }).then(res => {return res.id})
   
}
export const editCategory = (value,id) => {

    return   db.collection("Franchises")
      .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').doc(id).update({
         
          name: value
    
      }).then(res => {return res.id})
     
  }

export const createDemandeFromxsel = (demande,idFranchise) => {

    return   db.collection("Franchises")
      .doc(idFranchise).collection('DemandeFranchise').add(demande).then(res => {return res.id})
     
  }
export const createUser = (value, avatar,id) => {
    db.collection("Franchises")
    .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').doc(id).get().then(res => {
        let listecontact = res.data().contactListe
        listecontact.push({
            ...value,
            avatar: avatar ? avatar : user,
            name: value.name,
            surname: value.surname,
            mobile: value.mobile,
            email: value.email,
            mobile1: value.mobile1
        })
        db.collection("Franchises")
        .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').doc(id).update({contactListe : listecontact})
    })
}

export const deletedCategory = (id) => {
   
    db.collection("Franchises")
    .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').doc(id).delete()
}
export const deletedUser = (id, email) => {
   
    db.collection("Franchises")
    .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').doc(id).get().then(res => {
        let listecontact = res.data().contactListe.filter(res => res.email !== email)

        db.collection("Franchises")
        .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').doc(id).update({contactListe : listecontact})
    })
}

export const editUser = (value, url, id) => {
    db.collection("Franchises")
    .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').doc(id).get().then(res => {
        let listecontact = res.data().contactListe.filter(res => res.email !== value.email)
        listecontact.push(
            {
                ...value,
                avatar: url ? url : user,
                name: value.name,
                surname: value.surname,
                mobile: value.mobile,
              
                mobile1: value.mobile1
            })
        db.collection("Franchises")
        .doc(localStorage.getItem("@intranetidUser")).collection('DemandeFranchise').doc(id).update({contactListe : listecontact})
    })
    
}


export const getAge = (min, max) => {
    db.collection("DemandeFranchise")
        .where('age', '>=', min)
        .where('age', '<=', max)
        .onSnapshot((snapshot) => {
            // eslint-disable-next-line
            const getUser = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))
        })
}