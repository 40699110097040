import {GET_EVENTS_LIST,
        ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,

  } from '../actionTypes'


const initial_state = {
    events : [],
    eventsmanagerdata:[],
    eventsfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_EVENTS_LIST:
            return {...state,events: action.events || [], loading: true,};

        case ADD_TO_MY_EVENTS:
            return {...state,myeventdata:[...state.myeventdata, { ...action.payload.myeventdata}]};
        
        case ADD_NEW_EVENTS:
            state.events.push(action.payload.eventdata)
        return {...state,events:state.events};
        
        case UPDATE_MY_EVENTS:
            return {
         
                ...state,events: state.events.map(item => item.events_id === action.payload.id ?
                    { ...item, 
                        
                        ...action.payload.data
                      
                    } : 
                    item
                ) 
            };
            case UPDATE_STATE_EVENTS:
                return {
                    ...state,events: state.events.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            eventState:action.payload.state
                        } : 
                        item
                    ) 
                };
        case UPDATE_MY_EVENTS:
                return {
                    ...state,events: state.events.map(item => item.id === action.payload.id ?
                        { ...item, 
                            id: action.payload.id,
                            ...action.payload.data,
                          
                            
                        } : 
                        item
                    ) 
                };

        case REMOVE_EVENTS:
            let event_event = state.events.filter(item => action.payload !== item.events_id)
            return{...state, events : event_event}
        
        case REMOVE_FROM_MY_EVENTS:
            let event_my_event = state.myeventdata.filter(item=> action.eventid !== item.events_id)
            return{...state,myeventdata: event_my_event}
                
        default: return { ...state };
    }
    
}