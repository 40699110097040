import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, Media, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import TimelineTab from './timelineTab';
import AboutTab from './aboutTab';
import FriendsTab from './friendsTab';
import PhotosTab from './photosTab';
import {Timline,Friends,About,Photos,GeneralManager} from "../../constant";

const DiscusionetIdee = () => {
    const [activeTab, setActiveTab] = useState('1');
    return (
        <Fragment>
            <Breadcrumb parent="Discussion" title="Discussions et idées" />
            <Container fluid={true}>
                <div className="user-profile social-app-profile">
           
                            <TimelineTab />
                 
                  
                </div>
            </Container>
        </Fragment>
    );
};

export default DiscusionetIdee;