import {GET_PROVIDER_LIST,
        ADD_TO_MY_PROVIDER,
        ADD_NEW_PROVIDER,
        REMOVE_PROVIDER,
        REMOVE_FROM_MY_PROVIDER,
        UPDATE_PROVIDER,
        UPDATE_MY_PROVIDER,
        UPDATE_STATE_PROVIDER,

  } from '../actionTypes'


const initial_state = {
    fournisseurs : [],
    fournisseursmanagerdata:[],
    fournisseursfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_PROVIDER_LIST:
            return {...state,fournisseurs: action.fournisseurs, loading: true,};

        case ADD_TO_MY_PROVIDER:
            return {...state,myfournisseursdata:[...state.myfournisseursdata, { ...action.payload.myfournisseursdata}]};
        
        case ADD_NEW_PROVIDER:
            state.fournisseurs.push(action.payload.fournisseursdata)
        return {...state,fournisseurs:state.fournisseurs};
        
        case UPDATE_PROVIDER:
            return {
                ...state,fournisseurs: state.fournisseurs.map(item => item.id === action.payload.id ?
                    { ...item, 
                        ...action.payload.userdata
                    } : 
                    item
                ) 
            };
            case UPDATE_STATE_PROVIDER:
                return {
                    ...state,fournisseurs: state.fournisseurs.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            fournisseurState:action.payload.state
                        } : 
                        item
                    ) 
                };
        case UPDATE_MY_PROVIDER:
            return {
                ...state,fournisseurs: state.fournisseurs.map(item => item.id === action.payload.id ?
                    { ...item, 
                        ...action.payload.userdata
                    } : 
                    item
                ) 
            };
        case REMOVE_PROVIDER:
            let new_fournisseurs = state.fournisseurs.filter(item=> action.removeid !== item.id)
            return{...state,fournisseurs: new_fournisseurs}
        
        case REMOVE_FROM_MY_PROVIDER:
            let new_my_fournisseurs = state.myfournisseursdata.filter(item=> action.fournisseursid !== item.id)
            return{...state,myfournisseursdata: new_my_fournisseurs}
                
        default: return { ...state };
    }
    
}