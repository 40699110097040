import { db } from '../../data/config';
import {
    GET_MEMBERS,
    GET_MEMBERS_SUCCESS,
    GET_CHATS,
    GET_CHATS_SUCCESS,
    CREATE_CHAT,
    CHANGE_CHAT,
    UPDATE_SELECTED_USER,
    SEARCH_MEMBER,
    SEND_MESSAGE,
    REPLY_BY_SELECTED_USER,
    WATCH_CHAT_MEMBERS,
    WATCH_CHAT_SUCCESS,
    WATCH_ALL_CHAT_SUCCESS,
    SEND_CONV_MESSAGE,
    GET_ALL_CONV_MESSAGES,
    WATCH_ALL_CONV_MESSAGES,
    UPDATE_CONV_MESSAGE,
    REMOVE_CONV_MESSAGE,
    REMOVE_CONV
    
} from '../actionTypes';


export const  watchfetchChatMember = () => ({
    type: WATCH_CHAT_MEMBERS
})
export const  watchfetchAllConvMessages = () => ({
    type: WATCH_ALL_CONV_MESSAGES
})

export const  watchfetchChat = () => ({
    type: WATCH_CHAT_SUCCESS
})

export const  watchfetchAllChat = () => ({
    type: WATCH_ALL_CHAT_SUCCESS
})

export const getChats = (chats) => ({
    type: GET_CHATS,
    payload:chats
});


export const getAllConvMessage = (chats) =>{
    console.log(chats)
  return   ({
        type: GET_ALL_CONV_MESSAGES,
        payload:chats
    });
} 
export const getMembers = () => ({
    type: GET_MEMBERS
});

export const searchMember = (keyword) => ({
    type: SEARCH_MEMBER,
    payload: keyword
});

export const getMembersSuccess = (chats) => {
    return ({
        type: GET_MEMBERS_SUCCESS,
        payload: {chats}
    })
};
export const getChatsSuccess = (chats,online) => {
    return{
        type: GET_CHATS_SUCCESS,
        payload: { chats ,online }
    }
}


// export const getChatsSuccess = (chats, selectedUser,online) => {
//     return{
//         type: GET_CHATS_SUCCESS,
//         payload: { chats, selectedUser,online }
//     }
// }

export const removeMessage =  (idmessage,idconv) => {

    db.collection("Messages").doc(idconv).collection("Conversations").doc(idmessage).delete()
return  ({
   type: REMOVE_CONV_MESSAGE,
   payload: {idmessage,idconv}
})
  };

  export const removeConversation =  (idmessage) => {

    db.collection("Messages").doc(idmessage).delete()
return  ({
   type: REMOVE_CONV,
   payload: {idmessage}
})
  };

export const sendConvMessage =  (data) => {
     db.collection("Messages").add(data).then(res =>  {
      data.id= res.id
      
})
return  ({
    type: SEND_CONV_MESSAGE,
    payload: {data}
})
   };
   export const updateConvMessage =  (data,selectedConvMessage) => {
    db.collection("Messages").doc(selectedConvMessage).update(data)
return  ({
   type: UPDATE_CONV_MESSAGE,
   payload: {data,selectedConvMessage}
})
  };
   
export const sendMessageToChat = (currentUserId,selectedUserId,message,allchats,online) => ({
    type: SEND_MESSAGE,
    payload: {currentUserId,selectedUserId,message,allchats,online}
});
export const sendMessageToConv = (chat,lastMessageTime,UpdatedAt,selectedConvId) => {

    db.collection("Messages").doc(selectedConvId).update({lastMessageTime: lastMessageTime,UpdatedAt,userListeVue : [],lastmessage : chat.message})
    db.collection("Messages").doc(selectedConvId).collection("Conversations").add(chat) 
   return  ({
    type: SEND_MESSAGE,
    payload: {chat,lastMessageTime,selectedConvId}
});
}
export const replyMessageToChat = (currentUserId,selectedUserId,message,allchats,online) => ({
    type: REPLY_BY_SELECTED_USER,
    payload: {currentUserId,selectedUserId,message,allchats,online}
});

export const changeChat = (userId) => ({
    type: CHANGE_CHAT,
    payload: userId
});

export const createChat = (currentUserId,selectedUserId,allChats) => ({
    type: CREATE_CHAT,
    payload: {currentUserId,selectedUserId,allChats}
});

export const updateSelectedUser = (selectedUser,online) => ({
    type:UPDATE_SELECTED_USER,
    payload:{selectedUser,online}
})


