import {firebase_app} from '../data/config';
import user from '../assets/images/user/user.png';

const db = firebase_app.firestore();


export const createCategory = (value) => {

  return   db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
    .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').add({
       
        name: value,
        contactListe : []
    }).then(res => {return res.id})
   
}
export const editCategory = (value,id) => {

    return   db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
      .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').doc(id).update({
         
          name: value
    
      }).then(res => {return res.id})
     
  }

export const createCategoryFromxsel = (value, contactListe) => {

    return   db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
      .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').add({
         
          name: value,
          contactListe : contactListe
      }).then(res => {return res.id})
     
  }
export const createUser = (value, avatar,id) => {
    db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
    .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').doc(id).get().then(res => {
        let listecontact = res.data().contactListe
        listecontact.push({
            ...value,
            avatar: avatar ? avatar : user,
            name: value.name,
            surname: value.surname,
            mobile: value.mobile,
            email: value.email,
            mobile1: value.mobile1
        })
        db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
        .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').doc(id).update({contactListe : listecontact})
    })
}

export const deletedCategory = (id) => {
   
    db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
    .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').doc(id).delete()
}
export const deletedUser = (id, email) => {
   
    db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
    .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').doc(id).get().then(res => {
        let listecontact = res.data().contactListe.filter(res => res.email !== email)

        db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
        .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').doc(id).update({contactListe : listecontact})
    })
}

export const editUser = (value, url, id) => {

    db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
    .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').doc(id).get().then(res => {
        let listecontact = res.data().contactListe.map(res => res.email == value.email ? {
            ...res,
            avatar: url ? url : user,
            name: value.name,
            surname: value.surname,
            mobile: value.mobile,
            localite: value.localite,
            cp: value.cp,
            email2: value.email2,
  
            mobile1: value.mobile1
        } : res)
        // listecontact.push(
        //     {
        //         ...value,
        //         avatar: url ? url : user,
        //         name: value.name,
        //         surname: value.surname,
        //         mobile: value.mobile,
              
        //         mobile1: value.mobile1
        //     })
        db.collection(localStorage.getItem("@intranetTypeUser") === "admin" ? "Admin" : (localStorage.getItem("@intranetTypeUser") === "manager" || localStorage.getItem("@intranetTypeUser") === "submanager") ? "Users" : "Fournisseurs" )
        .doc(localStorage.getItem("@intranetidUser")).collection('contactApp').doc(id).update({contactListe : listecontact})
    })
    
}


export const getAge = (min, max) => {
    db.collection("contactApp")
        .where('age', '>=', min)
        .where('age', '<=', max)
        .onSnapshot((snapshot) => {
            // eslint-disable-next-line
            const getUser = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))
        })
}