import React, { Fragment,useEffect,useState } from 'react';
import Board from '@lourenci/react-kanban'
import { Card, CardHeader, CardBody } from 'reactstrap'
import {CustomBoards} from '../../../constant'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { WATCH_TACHE_LIST } from '../../../redux/actionTypes';
import moment from 'moment';
import { useHistory} from 'react-router-dom'
import {fetchUpdateTacheApi} from '../../../api/index'
import SweetAlert from 'sweetalert2'
import { Edit2, Play, Trash2 } from 'react-feather';
import { removeTaches } from '../../../redux/project/action';
import UpdatetacheModal from './update-tache-modal';
const CustomeBoard = (props) => {
    
  const history = useHistory()
    const dispatch = useDispatch()
    const allProject = useSelector(content => content.Project.tache_Project);
    const [board, setBoard] = useState(allProject);
    const {customboard} = board
    const [random, setRandom] = useState(0)
    const [loading, setLoading] = useState(true)
 
    const doingProject = useSelector(content => content.Project.doing_Project);
    const doneProject = useSelector(content => content.Project.done_Project);
    const [selectedTache, setselectedTache] = useState({})
    const [updateModal,setupdateModal] = useState(false)
    const UpdateToogle = () => { setupdateModal(!updateModal) }
useEffect(() => {
  setTimeout(() => {
      setRandom(Math.random())
      setLoading(false)
  }, 2000); 
}, [])
 const handleCardMove = (_card, source, destination) => {

let tache = _card.lanes[Number(destination.laneId)].cards[destination.index]
fetchUpdateTacheApi(tache.projetId,tache.id,_card.lanes[Number(destination.laneId)].title,source.laneId,destination.laneId)
  
   
  }
  const removetache = (idprojet,id,status) => {
    SweetAlert.fire({
      title: 'Vous Êtes sûr?',
      text: "Une fois supprimé, vous ne pourrez plus récupérer ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        history.push(`${process.env.PUBLIC_URL}/app/projet/project-liste-user/`)
       
   
        SweetAlert.fire(
          'Supprimé!',
          'Votre Tâche a été supprimée',
          'success'
        )
        dispatch(removeTaches(idprojet,id,status))
      }
      else {
      
      }
    })
  }
    return (
        <Fragment>
            {loading === false ?
            <Card>
               
                <CardBody>
                    <div id="demo2">
                        <div className="kanban-container">
                            <div className="kanban-board">
                                <main  className="kanban-drag">
                                    <Board  onCardDragEnd={handleCardMove}
                                        renderCard={(item) => (
                                            <div onDoubleClick={() => history.push(`${process.env.PUBLIC_URL}/app/projet/tache/TacheDeatil-user/${item.projetId}/${item.id}`)}  className="kanban-item">
                                            
                                                <a className={`kanban-box bg-light-info`} href="#javascript">
                                                    <span className="date">{moment(item.startDate.toDate()).format("DD-MM-YYYY")}</span>
                                                    <span className={`badge badge-success f-right`}>{item.priority}</span>
                                                    <h6>{item.title}</h6>
                                                    <div className="media">
                                                        
                                                        <div className="media-body">
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <ul className="list">
                                                            <li><i className="fa fa-comments-o"></i>{item.comment.length}</li>
                                                            <li><i className="fa fa-paperclip"></i>{item.files.length}</li>
                                                            <li><i className="fa fa-eye"></i>{item.remarques.length}</li>
                                                        </ul>
                                                        <div className="d-flex mt-3">
                                                            <ul className="list">
                                                           

                                                            <li> <a onClick={() => history.push(`${process.env.PUBLIC_URL}/app/projet/tache/TacheDeatil-user/${item.projetId}/${item.id}`)}  ><Play  size={20} /></a></li>
                
                                                           
                                                           </ul>
                                                        </div>
                                                    </div>
                                                </a>
                                              
                                            </div>
                                        )}

                                    >
                                    {allProject}
                                    </Board>
                                </main>
                            </div>
                            <UpdatetacheModal selectedTache={selectedTache} updateModal={updateModal} UpdateToogle={UpdateToogle} />
                        </div>
                    </div>
                </CardBody>
            </Card>
            :""}
        </Fragment>
    );
}

export default CustomeBoard;