import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchOrdersProviderApi, fetchOrdersFranchiseApi, fetchAllOrdersApi1 } from "../../api";
import { GET_ALL_ORDERS_LIST,GET_ORDERS_FRANCHISE_LIST,WATCH_ORDERS_PROVIDER_LIST, GET_ORDERS_LIST,WATCH_ORDERS_EMPTY_LIST,WATCH_ORDERS_LIST, WATCH_ORDERS_FRANCHISE_LIST, WATCH_ALL_ORDERS_LIST } from "../../redux/actionTypes";

function* fetchOrderPAsyn() {
    const Data = yield call(fetchOrdersProviderApi);
    yield put({type : GET_ORDERS_LIST,orders : Data});
}
function* fetchOrderFAsyn() {
    const Data = yield call(fetchOrdersFranchiseApi);
    yield put({type : GET_ORDERS_FRANCHISE_LIST,orders : Data});
}
function* fetchAllOrderAsyn() {
    const Data = yield call(fetchAllOrdersApi1);
    yield put({type : GET_ALL_ORDERS_LIST,orders : Data});
}
export function* watchOrderList() {
    yield takeLatest(WATCH_ORDERS_PROVIDER_LIST,fetchOrderPAsyn)
    yield takeLatest(WATCH_ORDERS_FRANCHISE_LIST,fetchOrderFAsyn)
    yield takeLatest(WATCH_ALL_ORDERS_LIST,fetchAllOrderAsyn)
}
