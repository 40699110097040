import {call,put, takeLatest} from "redux-saga/effects";
import {  fetchNewApi, fetchaddsondageApi,fetchNewByidApi, fetchNewByidFournisseruApi } from "../../api";
import { WATCH_ADD_SONDAGE, GET_NEWS_LIST,WATCH_NEWS_EMPTY_LIST,WATCH_NEWS_LIST, ADD_NEW_SONDAGE, WATCH_NEWS_BY_MARQUE_LIST, WATCH_NEWS_BY_FOURNISSEUR_LIST } from "../../redux/actionTypes";

function* fetchNewAsyn() {
    const Data = yield call(fetchNewApi);
   
    yield put({type : GET_NEWS_LIST,news : Data});
}

function* fetchNewByFournisseuridAsyn() {
    const Data = yield call(fetchNewByidFournisseruApi);
    yield put({type : GET_NEWS_LIST,news : Data});
}
function* fetchNewByidAsyn() {
    const Data = yield call(fetchNewByidApi);
    yield put({type : GET_NEWS_LIST,news : Data});
}
function* fetchAddSondageAsyn({idNew,contenu}) {
  
    const Data = yield call(fetchaddsondageApi,idNew,contenu);
    yield put({type : ADD_NEW_SONDAGE,sondagelisteCopy : Data.data.sondageListe ?? [] ,idNew : idNew});
}
export function* watchNewList() {
    yield takeLatest(WATCH_NEWS_LIST,fetchNewAsyn)
    yield takeLatest(WATCH_NEWS_BY_MARQUE_LIST,fetchNewByidAsyn)
    yield takeLatest(WATCH_NEWS_BY_FOURNISSEUR_LIST,fetchNewByFournisseuridAsyn)
    yield takeLatest(WATCH_ADD_SONDAGE,fetchAddSondageAsyn)
}
