import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from '../../../layout/breadcrumb'
import "./button.css";
import {Container,Row,Col,Card,CardHeader,CardBody,Button, Modal, ModalBody, ModalHeader, Media} from 'reactstrap'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { AddNewEvent, BootstrapStateButtons, DefaultButtons, Dragging_Event, ManageCategory, New, PrimaryButton, SecondaryButton, SuccessButton } from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { WATCH_EVENTS_BY_FOURNISSEUR_LIST, WATCH_EVENTS_BY_MARQUE_LIST, WATCH_EVENTS_LIST, WATCH_EVENT_CATEGORY } from "../../../redux/actionTypes";
import { NewEVENTS, removeevent, updateevent } from "../../../redux/events/action";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment, { invalid } from "moment";
import SweetAlert from 'sweetalert2'
import { urlbackend } from "../../../data/config";
import { Calendar, Grid, List } from "react-feather";
const EventsDraggableCalendar = () => {
    const initialstate = {
        calendarEvents: [
           
        ],
       

    }
    const dispatch = useDispatch();
    const history = useHistory()
    const [activeTab,setActiveTab] = useState("1")
    const [selectedTitle,setselectedTitle] = useState("")
    const [selectedDescription,setselectedDescription] = useState("")
    const [selectedOrganisateur,setselectedOrganisateur] = useState("")
    const [valueSearch,setvalueSearch] = useState("")
    const [selectedEvent,setSelectedEvent] = useState(null)
    const [eventdetailModal,seteventdetailModal] = useState(false)
    const [displayStyle,setDisplayStyle] = useState("1")
    const eventdetailModalToggle = () =>  seteventdetailModal(!eventdetailModal)
    const events = useSelector(content => content.Eventsapp.events);
    const listEventCategory = useSelector(state => state.EventCategory.eventcategory)
 
    //const doingProject = useSelector(content => content.Franchiseapp.doing_Project);
    //const doneProject = useSelector(content => content.Franchiseapp.done_Project);
 
    // eslint-disable-next-line
    const [state,setState] = useState(initialstate)
    const updateDateEvent = (id, datestart , datefin) => {
        dispatch(updateevent(id,datestart, datefin))

    }

    useEffect(() => {
        dispatch({type : WATCH_EVENT_CATEGORY})
        if( localStorage.getItem("@intranetTypeUser") === 'admin')
        dispatch({type: WATCH_EVENTS_LIST})
        else if (localStorage.getItem("@intranetTypeUser") === 'Fournisseur')
        dispatch({type: WATCH_EVENTS_BY_FOURNISSEUR_LIST})
        else 
        dispatch({type: WATCH_EVENTS_BY_MARQUE_LIST})
  
        
        let draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
                let title = eventEl.getAttribute("title");
                let id = eventEl.getAttribute("data");
              
                return {
                    title: title,
                    id: id
                };
            }
        });
      },[]);

   const eventClick = (eventClick) => {

    setSelectedEvent(eventClick.event._def.extendedProps)
setselectedDescription(eventClick.event.extendedProps.description)
setselectedTitle(eventClick.event.title)
setselectedOrganisateur(eventClick.event.extendedProps.organisateur_id)
history.push(`${process.env.PUBLIC_URL}/app/events/eventdetail/${eventClick.event._def.extendedProps?.events_id}`)
//eventdetailModalToggle()

    };
    const eventClickFromList = (event) => {
      console.log(event)
      setSelectedEvent(event)
      setselectedDescription(event.description)
      setselectedTitle(event.title)
      setselectedOrganisateur(event.organisateur_id)
history.push(`${process.env.PUBLIC_URL}/app/events/eventdetail/${event?.events_id}`)
      
      //eventdetailModalToggle()
      
          };
          const filterEvent = (value) => {
            setvalueSearch(value)
          }
const deleteEvent = (id) => {
    
    SweetAlert.fire({
     
        title: 'Vous Êtes sûr?',
        text: "Une fois supprimé, vous ne pourrez plus récupérer ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            dispatch(removeevent(id))
            eventdetailModalToggle()
           // eventClick.event.remove();
          SweetAlert.fire(
            'Supprimé!',
            'Votre Evénement a été supprimée',
            'success'
          )
        }
        else {
        
        }
      })
               
             
            
     
    }
    const  changeDisplay = (typestyle) => {

      if( localStorage.getItem("@intranetTypeUser") === 'admin')
        dispatch({type: WATCH_EVENTS_LIST})
        else if (localStorage.getItem("@intranetTypeUser") === 'Fournisseur')
        dispatch({type: WATCH_EVENTS_BY_FOURNISSEUR_LIST})
        else 
        dispatch({type: WATCH_EVENTS_BY_MARQUE_LIST})
         setDisplayStyle(typestyle)
    }
    const AddProject = data => {

     
   
        data.prop_id = localStorage.getItem("@intranetidUser")
  
      
        data.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
        data.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
        data.prop_type = localStorage.getItem("@intranetTypeUser")
       
     
       
      dispatch(NewEVENTS(data))
      //  props.history.push(`${process.env.PUBLIC_URL}/app/events/eventsListeUpdate/`)
    
    };

        return (
            <Fragment>
                <Breadcrumb parent="Evénements" title="Liste événements"/>
                <Container fluid={true}>
                <div className="media-body text-right">
                <Form className="d-inline-flex mr-3">

                  <Link to={`${process.env.PUBLIC_URL}/app/events/eventsAdd`} className="btn btn-primary" > {AddNewEvent}</Link>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
                <Form className="d-inline-flex">

                  <Link to={`${process.env.PUBLIC_URL}/app/eventcategory`} className="btn btn-primary" > {ManageCategory}</Link>
                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                 
                  </div>

                </Form>
              </div>
            <Row>
            <Col md="6" className="products-total">
                <div onClick={() => changeDisplay("1")} className="square-product-setting d-inline-block">
                  <a className="icon-grid grid-layout-view"  onClick={() => changeDisplay("1")}  >
                    <Calendar />
                  </a>
                </div>
                <div onClick={() => changeDisplay("2")} className="square-product-setting d-inline-block">
                  <a className="icon-grid m-0 list-layout-view"   onClick={() => changeDisplay("2")} >
                    <List />
                  </a>
                </div>
                </Col>
            </Row>
        
                    <Row>
                      
                        <Col sm="12">
                        <Card>
                            <CardHeader>
                            <h5>{Dragging_Event}</h5>
                            </CardHeader>
                            <CardBody className="btn-showcase">
                            <button onClick={() => filterEvent("")} className="btn-custom" style={{backgroundColor : 'black'}}>{"ALL"}</button>
                                {listEventCategory.map(res => 
                                      <button onClick={() => filterEvent(res.event_category_id)} className="btn-custom" style={{backgroundColor : res.color}}>{res.title}</button>
                                    )}
                              
                               
                            </CardBody>
                        </Card>

                       
                            <Card>
                                <CardBody>
                                {displayStyle === "1" ? 
                                    <div className="basic-calendar">
                                        <Row>
                                            <Col xl={3} md={4}>
                                                <div
                                                    id="external-events"
                                                >
                                                    <p align="center">
                                                        <strong> {"Evénements"}</strong>
                                                    </p>
                                                    {events.filter(res => valueSearch !== "" ? res.category_id == valueSearch : true).map(event => (
                                                        <div
                                                        onClick={() => eventClickFromList(event)}
                                                            //className="fc-event"
                                                            style={{backgroundColor : listEventCategory.find(res => res.event_category_id === event.category_id ) ? listEventCategory.find(res => res.event_category_id === event.category_id ).color : 'black',
                                                            padding: '7px' ,
                                                            color: '#fff',margin: '1em 0',
                                                            cursor: 'move' }}
                                                            title={event.title}
                                                            data={event.events_id}
                                                            key={event.events_id}
                                                        >
                                                            {event.title}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                            <Col xl={9} md={8}>
                                                <div className="demo-app-calendar" id="mycalendartest">
                                                <FullCalendar
                                                  buttonText={{today : "Aujourd'hui"}}
                                                    locale='fr'
                                                      
                                                        defaultView="dayGridMonth"
                                                        header={{
                                                            left: "prev,next today",
                                                            center: "title",
                                                            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                                                        }}
                                                        rerenderDelay={10}
                                                        eventDurationEditable={false}
                                                        editable={true}
                                                        droppable={true}
                                                      
                                                      eventReceive={(e) => AddProject(e.event)}
                                                        eventChange={(events) => {
                                                         
                                                          updateDateEvent(events.event.events_id,events.event.start,events.event.end )
                                                        } } 
                                                      //  eventsSet={(events) => events.map((res ,i) => console.log(res.start))} 
                                                       // eventDragStop={(e) => console.log(e.event.start)}
                                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                        weekends={state.calendarWeekends}
                                                        events={events.map(res => {return {...res,start : new Date(res.start), end : new Date(res.end)}})}
                                                        eventClick={(e) => eventClick(e)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : events.filter(res => valueSearch !== "" ? res.category_id == valueSearch : true).map((event, index) =>  {
                                  
                                  return  <div onClick={() => history.push(`${process.env.PUBLIC_URL}/app/events/eventdetail/${event?.events_id}`)} className="media">
                                     <div className="media-body">
                                       {new Date(event.creation_date.slice(0,10)).setHours(0,0).toString() === new Date(moment(new Date()).format("yyyy-MM-DD")).setHours(0).toString() ? 
                                     <p style={{color : "#f73164"}} >{moment(event.start).format("DD-MM-YYYY")} <span className="badge badge-secondary">{New}</span></p>
                                     :
                                       <p style={{color : "#f73164"}} >{moment(event.start).format("DD-MM-YYYY")} <span></span></p>}
                                       <h6>{event.title}<span className="dot-notification"></span></h6><span><div dangerouslySetInnerHTML={{ __html: event.description }} /></span>
                                       <div className="d-flex mb-3">
                                         {event.piecesJoint.map((file , j) => 
                                           <div className="inner-img"><img className="img-fluid" src={`${urlbackend}${file.url}`} alt="Product-1" /></div>
                                         )}
                                       
                                       
                                       </div>
                                     </div>
                                   </div>}
                                     )
                                         }
                                </CardBody>
                            </Card> 
                              
                                 
                        </Col>
                    </Row>
             
                </Container>
                <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={eventdetailModal}>
                  <ModalBody>
                    <ModalHeader toggle={eventdetailModalToggle}>
                      <div className="product-box row">
                        
                        <Col lg="12" className="product-details  text-left">
                          <h4> Nom événement : {selectedTitle}</h4>
                          <h4> Nom Organisateur : {selectedOrganisateur}</h4>
                        Date début et date Fin :   {(moment(selectedEvent?.end) === invalid ? moment(selectedEvent?.start).format("DD/MM/YYYY")  :  moment(selectedEvent?.start).format("DD/MM/YYYY") + '-' + moment(selectedEvent?.end).format("DD/MM/YYYY") )}
                          <div className="product-view">
                            <h6 className="f-w-600">{"Description"}</h6>
                            <p className="mb-0"> <div dangerouslySetInnerHTML={{ __html: selectedDescription}} ></div></p>
                          </div>
                          
                          <img className="img-fluid" style={{width : 917}} src={selectedEvent?.piecesJoint?.length > 0  ?  `${urlbackend}selectedEvent.piecesJoint[0].url` : ""} alt="" />
                          
                           
                            <div className="addcart-btn">
                            <Link ><Button color="secondary" className="mr-2 mt-2" onClick={() => eventdetailModalToggle()}>{"Annuler"}</Button></Link>
                             
                            <Link to={`${process.env.PUBLIC_URL}/app/events/update-event/${selectedEvent?.events_id}`} ><Button color="primary"   className="mr-2 mt-2">{"Modifier"}</Button></Link>
                              <Button  color="primary" onClick={() => deleteEvent(selectedEvent?.events_id)} className="mr-2 mt-2">{"Supprimer"}</Button>
                              <Link to={`${process.env.PUBLIC_URL}/app/events/eventdetail/${selectedEvent?.events_id}`} ><Button color="primary"   className="mr-2 mt-2">{"Voir plus"}</Button></Link>

                              
                         
                          </div>

                        </Col>
                      </div>
                   

                  
                    </ModalHeader>
                  </ModalBody>
                </Modal>
            </Fragment>


        );
    }

export default EventsDraggableCalendar;
