import React, { Fragment,useEffect,useRef,useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { AddFournisseur,Password,FirstName,LastName,Address,EmailAddress,IdentifiantFournisseur, InfoFournisseur, SaveChanges, Cancel, BirthDateUser, PostalCode, UserFormation, UserCompetences, UserExperience, Userfonctions, AddUser} from '../../constant'
import { NewProvider } from '../../redux/fournisseur/action';
import moment from 'moment';
import { useFilePicker } from 'use-file-picker';
import defaultuser from '../../assets/images/user/user.png';
import { WATCH_CATEGORY, WATCH_FRANCHISE_LIST, WATCH_GROUP_TRAVAIL, WATCH_PROVIDER_LIST } from '../../redux/actionTypes';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { fetchCategoryprovider } from '../../api';
import { Typeahead } from 'react-bootstrap-typeahead';
import SweetAlert from 'sweetalert2'

const FournisseurAdd = (props) => {


  const [addurl, setAddurl] = useState(defaultuser)
  const [firstName,setfirstName] = useState("")
  const [lastName,setlastName] = useState("")
  const [email,setemail] = useState("")
  const [phoneNumber,setphoneNumber] = useState("")
  const ref = useRef(null);
  const [emaildestination, setemaildestination] = useState([]);

  const [role,setRole] = useState("Fournisseur")
  const [roleprovider,setRoleProvider] = useState("1")
  const [providerCategory,setproviderCategory] = useState([])
  const [adresse,setadresse] = useState("")

  const [region,setRegion] = useState("")
  const [password,setpassword] = useState("")
  const [tokenPush,settokenPush] = useState("")
  const [fournisseurState,setfournisseurState] = useState(true)
  const addToggle = () => { setaddModal(!addModal) }
  const [addModal, setaddModal] = useState(false)
  const [searchvalue, setsearchvalue] = useState("")
  const [selectedCategorie, setselectedCategorie] = useState([]);
  const category = useSelector(state => state.Categoryapp.category)

  const [birthday, setBirthday] = useState(new Date())
  const [Mobilephone,setobilephone] = useState("")
  const [groupeSelection, setgroupeSelection] = useState([]);
  const [pays,setPays] = useState("")
  const [ville,setVille] = useState("")
  const [zipcode,setZipcode] = useState("")

  const [langue,setLangue] = useState("")
  const [groupTravail,setgroupTravail] = useState("")
  const [civility,setCivility] = useState("")
  const [competences,setcompetence] = useState("")
  const [formations,setFormation] = useState("")
  const [experiences,setExperience] = useState("")
  const [fonctions,setFonction] = useState("")
  const grouptravail = useSelector(content => content.GroupTravail.grouptravail);
  const franchises = useSelector((content) => content.Franchiseapp.franchises);
  const [userState,setuserState] = useState(true)
  const searchUser = (value) => {
    setsearchvalue(value)
  }
  const addToSelectedListe = (id) => {
    let selectedUserCopy = selectedCategorie
    if(selectedUserCopy.includes(id) == true){
      selectedUserCopy.splice(selectedUserCopy.findIndex(res => res == id), 1)
      setselectedCategorie(selectedUserCopy)
    setrandom(Math.random)
    }
    else {
      selectedUserCopy.push(id)
      setselectedCategorie(selectedUserCopy)
      setrandom(Math.random)
    }
      }
  const dispatch = useDispatch()

  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
  
  });
  const history = useHistory()
  const [random, setrandom] = useState(0);
  console.log(filesContent)
  const fournisseurs = useSelector(content => content.Fournisseurapp.fournisseurs);
  useEffect(() => {
    dispatch({type: WATCH_GROUP_TRAVAIL})
    dispatch({ type: WATCH_FRANCHISE_LIST });
    fetchCategoryprovider().then(res => setproviderCategory(res))
    dispatch({type: WATCH_CATEGORY})
    dispatch({type: WATCH_PROVIDER_LIST})
  },[dispatch])
  const [stateAddFournisseur,setstateAddFournisseur] = useState(false)
  const handleAddFournisseur = (event) => {
    event.preventDefault();

    let user ={}
    user.last_name = lastName
    user.birthday = moment(birthday).format('DD-MM-yyyy')
    user.first_name = firstName
    user.civility = civility
    user.region = region
    user.role = role
    user.type = role
    user.email = email
    user.token_push = tokenPush
    user.token = tokenPush
    
    user.password = password
    user.userState = userState
    user.phone_number = phoneNumber
    user.mobilephone = Mobilephone
    user.adresse = adresse
    user.pays = pays
    user.ville = ville
    user.region = ville
    user.zip_code = zipcode
    user.formations = formations
    user.experiences = experiences
    user.competences = competences
    user.fonctions = fonctions
    user.first_connexion = 1
    user.contrat_verified = 0
    user.status= 1
    user.langue= langue
    user.creation_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
    user.updated_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
    user.proEmails = [email]
    user.image = addurl
    user.user_image = addurl
    user.user_state = 0
    user.provider_category_id = 1//roleprovider
    user.listemarque = emaildestination 
    SweetAlert.showLoading()
    dispatch(NewProvider(user))
  setstateAddFournisseur(false)
  }
  const HandleAddUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setAddurl(reader.result)
    }
  }




  return (
    <Fragment>
      <Breadcrumb parent="Fournisseurs" title="Ajouter fournisseur" />
      <Container fluid={true}>
        <div className="edit-profile">
        <div className="media">
             
             <div className="media-body text-right">
               <Form className="d-inline-flex">
               <div className="btn btn-primary" onClick={() => setstateAddFournisseur(!stateAddFournisseur)}> {AddFournisseur}</div>
              
                
               </Form>
              
             </div>
             </div>
             <Row>
            {stateAddFournisseur === true ? <>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{IdentifiantFournisseur}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <div className="col-auto">
                      <div className="contact-profile">
                                    <img className="rounded-circle img-100" src={addurl} alt="" />
                                    <div className="icon-wrapper">
                                      <i className="icofont icofont-pencil-alt-5">
                                        <input className="upload" type="file" onChange={(e) => HandleAddUrl(e)} />
                                      </i>
                                    </div>
                                  </div>
                      </div>
                      <Col>
                        <h3 className="mb-1">{firstName} {lastName}</h3>
                       
                      </Col>
                    </Row>
                  
                    <FormGroup>
                      <Label className="form-label">{EmailAddress}</Label>
                      <Input className="form-control" value={email} onChange={(e) => setemail(e.target.value)} placeholder="email@domain.com" />
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label">{Password}</Label>
                      <Input className="form-control" value={password} onChange={(e) => setpassword(e.target.value)} type="password" defaultValue="password" />
                    </FormGroup>
                  
                   
                  </Form>
                

                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <form className="card"  onSubmit={handleAddFournisseur}>
                <CardHeader>
                  <h4 className="card-title mb-0">{InfoFournisseur}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                  <Col>
                  <FormGroup>
                        <Label className="form-label">Type Fournisseur</Label>
                        <Input  type="select" name="select" onChange={(e) => setRoleProvider(e.target.value)} className="form-control btn-square">
                        <option value="manager" key={0}>Choisir type Fournisseur </option>
                            
                            {providerCategory.map((res,i) =>  <option value={res.provider_category_id} key={i}>{res.provider_category_name}</option> )
                             
                          
}
                          
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                    <FormGroup>
                        <Label className="form-label">Langue</Label>
                        <Input  type="select" name="select" onChange={(e) => setLangue(e.target.value)} className="form-control btn-square">
                        <option value="" key={0}>Choisir langue </option>
                            <option value="francais" key={1}>Francais</option>
                            <option value="anglais" key={2}>Anglais</option>
                            <option value="arabic" key={3}>Arabic</option>
                         
                          
                        </Input>
                      </FormGroup>
                    </Col>
                    
                    </Row>
                    <Row>
                
                    <Col>
                    <FormGroup>
                                    <Label className="col-form-Label pt-0">{"Choisir Marque"}</Label>

                                    <Typeahead
                                    
                                    ref={ref}
                                                    id="custom-typeahead"
                                                    allowNew
                                                    multiple
                                                    onChange={(e) => setemaildestination(e)}
                                                    selected={emaildestination}
                                                    newSelectionPrefix="Ajouter email: "
                                                    options={franchises.map(res => {return   {id: res.franchise_id, label: res.franchise_name}} )}
                                                    placeholder="choisir franchise..."
                                    />
                                    {/* <Input value={destinataire} onChange={(e)=> setDestinataire(e.target.value)} className="form-control" type="email"/> */}
                                  </FormGroup>
                                  </Col>
                    </Row>
                  <Row>
                  
                    {/* <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        <Input email required className="form-control" value={email} onChange={(e) => setemail(e.target.value)}  type="email" placeholder="Email" />
                      </FormGroup>
                    </Col> */}
              
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{FirstName}</Label>
                        <Input required className="form-control" value={firstName} onChange={(e) => setfirstName(e.target.value)} type="text" placeholder="Nom" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6">
                      <FormGroup>
                        <Label className="form-label">{LastName}</Label>
                        <Input required className="form-control" value={lastName} onChange={(e) => setlastName(e.target.value)} type="text" placeholder="Prénom" />
                      </FormGroup>
                    </Col>
                 
                        <Col>
                        <FormGroup >
                        <div>
                          <label className="col-form-label text-right">{BirthDateUser}</label>
                       
                            <DatePicker  className="form-control digits" onChange={e => setBirthday(e)} showPopperArrow={false} selected={birthday}  dateFormat="P" />
                          </div>
                        </FormGroup>
                        </Col>
                    
                    <Col md="12">
                      <FormGroup>
                        <Label className="form-label">{Address}</Label>
                        <Input required className="form-control" value={adresse} onChange={(e) => setadresse(e.target.value)}  type="text" placeholder="Rue" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Pays"}</Label>
                        <Input required className="form-control" value={pays} onChange={(e) => setPays(e.target.value)}  type="text"  placeholder="Pays" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Ville"}</Label>
                        <Input required className="form-control" value={ville} onChange={(e) => setVille(e.target.value)}  type="text"  placeholder="Ville" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{PostalCode}</Label>
                        <Input required className="form-control" value={zipcode} onChange={(e) => setZipcode(e.target.value)}  type="text"  placeholder="Code Postale" />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3">
                      <FormGroup>
                        <Label className="form-label">{"Région"}</Label>
                        <Input required className="form-control" value={region} onChange={(e) => setRegion(e.target.value)}  type="text"  placeholder="Région" />
                      </FormGroup>
                    </Col>

                   
                  </Row>
                  <Row>
                  <Col >
                      <FormGroup>
                        <Label className="form-label">Numéro de Téléphone</Label>
                        <Input required className="form-control" value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value)} type="tel" placeholder=" Numero télephone" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Téléphone Portable</Label>
                        <Input required className="form-control" value={Mobilephone} onChange={(e) => setobilephone(e.target.value)} type="tel" placeholder=" Numéro télephone" />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                  <Col sm="12">
                      <FormGroup>
                        <Label>{"Choisir Groupe de travail"}</Label>
                        <Typeahead
                                    
                            ref={ref}
                                                    id="custom-typeahead"
                                                    allowNew={false}
                                                    multiple

                                                    
                                                    onChange={(e) => {
                                                      setgroupeSelection(e)}}
                                                    selected={groupeSelection}
                                                    newSelectionPrefix="Ajouter Groupe: "
                                                    options={grouptravail.map(res => {return   {id: res.groupe_travail_id, label: res.groupe_name}} )}
                                                    placeholder="Groupe travail(s)"
                                                />
               
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserFormation}</Label>
                            <Input className="form-control" type="textarea" value={formations} onChange={(e) => setFormation(e.target.value)} rows="3"  name="franchiseBio" placeholder={UserFormation} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserCompetences}</Label>
                            <Input className="form-control" type="textarea" value={competences} onChange={(e) => setcompetence(e.target.value)} rows="3" name="franchiseBio" placeholder={UserCompetences} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{UserExperience}</Label>
                            <Input className="form-control" type="textarea" value={experiences} onChange={(e) => setExperience(e.target.value)} rows="3" name="franchiseBio" placeholder={UserExperience} />

                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{Userfonctions}</Label>
                            <Input className="form-control" value={fonctions} onChange={(e) => setFonction(e.target.value)} type="textarea" rows="3" name="franchiseBio" placeholder={Userfonctions} />

                          </FormGroup>
                        </Col>
                      </Row>
                   
                </CardBody>
                <CardFooter className="text-right">
                 <input className="btn btn-primary" value={AddUser} type="submit"/>
                </CardFooter>
               
              </form>
             
            </Col>
            </>
            : <div></div> }
            <Col md="12">
          
       
            <Row>
          {fournisseurs?.map((cardItem, i) => 
          <Col onClick={() => {  
            history.push(`${process.env.PUBLIC_URL}/app/providers/providerProfile/${cardItem.user_id}`,{user : cardItem})
          }} md="6" lg="6" xl="4" className="box-col-6" key={i}>
            <Card className="custom-card">
              <CardHeader>
     
                {/* <Media body className="img-fluid" src={cardItem.userImage} alt="" /> */}
              </CardHeader>
              <div className="card-profile">
                <Media body style={{width :150}} className="rounded-circle" src={cardItem.image} alt="" />
              </div>
             
              <div className="text-center profile-details">
                <h4>{cardItem.first_name} {cardItem.last_name}</h4>
                <h6>{cardItem.role}</h6>

                <h6>{cardItem.email}</h6>
              </div>
              {/* <CardFooter className="row">
                <Col sm="4 col-4">
                  <h6>{Follower}</h6>
                  <h3 className="counter">{cardItem.follower}</h3>
                </Col>
                <Col sm="4 col-4">
                  <h6>{Following}</h6>
                  <h3><span className="counter">{cardItem.following}</span>{"K"}</h3>
                </Col>
                <Col sm="4 col-4">
                  <h6>{TotalPost}</h6>
                  <h3><span className="counter">{cardItem.totalPost}</span>{"M"}</h3>
                </Col>
              </CardFooter> */}
            </Card>
          </Col>
          )}
        </Row>
            </Col>
          </Row>
      
          <Modal isOpen={addModal} toggle={addToggle} size="lg">
                            <ModalHeader toggle={addToggle}>Recherche Categorie</ModalHeader>
                            <ModalBody>
                              <Form onSubmit={(e) => {e.preventDefault();addToggle()}} className="form-bookmark needs-validation">
                                <div className="form-row">
                                 
                                
                                  <FormGroup className="col-md-12">
                                    <Label>{"Nom categorie"}</Label>
                                    <Input className="form-control" value={searchvalue} onChange={(e) => searchUser(e.target.value)} name="title" type="text" autoComplete="off"  />
                                   
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                 
                               <div className="people-list">
                               <ul className="list digits custom-scrollbar">
                                {category.filter(x => x.title.includes(searchvalue)== true).map((item, i) => {
                                    return (
                                   
                                          <li style={{backgroundColor : selectedCategorie.includes(item.title) == true ? 'red' : 'white'}} onClick={() => addToSelectedListe(item.title)}  className="clearfix">
                                              <div>
                                                  <div className="name">{item.title}</div>
                                                  
                                              </div>
                                          </li>
                                )
                              
                                })}
                            </ul>
                            </div>
                          
                                     </FormGroup>
                                </div>
                                <div className='text-right'>
                               
                                <Button color="secondary"  className="mr-1" onClick={addToggle}>{Cancel}</Button>
                                <Button color="primary">{SaveChanges}</Button>
                                </div>
                              </Form>
                            </ModalBody>
                          </Modal>
        </div>
      </Container>
    </Fragment>
  );
}

export default FournisseurAdd;