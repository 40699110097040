import {
        GET_PROVIDER_CATEGORY,
        ADD_PROVIDER_CATEGORY,
        DELETE_PROVIDER_CATEGORY,
        UPDATE_PROVIDER_CATEGORY,

  } from '../actionTypes'


const initial_state = {
    providercategory : [],
    providersmanagerdata:[],
    providersfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_PROVIDER_CATEGORY:
            console.log(action.category)
            return {...state,providercategory: action.category, loading: true,};
            
        case DELETE_PROVIDER_CATEGORY:
                let provider_my_provider = state.providercategory.filter(item=> action.payload.id !== item.provider_category_id)
                return{...state,providercategory: provider_my_provider}
                    
        case ADD_PROVIDER_CATEGORY:
            return {...state,providercategory:[...state.providercategory, { ...action.payload.providercategory}]};
     
                  
                case UPDATE_PROVIDER_CATEGORY:
                    return {
                        ...state,providercategory: state.providercategory.map(item => item.provider_category_id === action.payload.datacategorie.provider_category_id ?
                            { ...item, 
                               
                                provider_category_name :action.payload.datacategorie.provider_category_name,
                                color:action.payload.datacategorie.color
                            } : 
                            item
                        ) 
                    };
       
       
        
       
                
        default: return { ...state };
    }
    
}