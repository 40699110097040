import {GET_EVENTS_LIST,
        ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,
        ADD_CATEGORY,
        GET_CATEGORY,
        ADD_SOUS_CATEGORY,
        REMOVE_SOUS_CATEGORY,
        ADD_MAIL_GROUP,
        GET_MAIL_GROUP_LIST,
        UPDATE_MAIL_GROUP,
        DELETE_MAIL_GROUP,

  } from '../actionTypes'


const initial_state = {
    mailgroup : [],
    eventsmanagerdata:[],
    eventsfournisseurdata:[],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_MAIL_GROUP_LIST:
            return {...state,mailgroup: action.list, loading: true,};

        case ADD_MAIL_GROUP:
          
            return {...state,mailgroup:[...state.mailgroup, { ...action.payload.data}]};
        
      
        case UPDATE_MAIL_GROUP:
            return {
         
                ...state,mailgroup: state.mailgroup.map(item => item.id === action.payload.eventdata.id ?
                    { ...item, 
                        
                        titre: action.payload.eventdata.titre,
                        text: action.payload.eventdata.text,
                       
                      
                    } : 
                    item
                ) 
            };
            case DELETE_MAIL_GROUP:
                let event_my_event = state.mailgroup.filter(item=> action.payload.id !== item.groupe_id)
            return{...state,mailgroup: event_my_event}
            case UPDATE_STATE_EVENTS:
                return {
                    ...state,events: state.events.map(item => item.id === action.payload.id ?
                        { ...item, 
                           
                            eventState:action.payload.state
                        } : 
                        item
                    ) 
                };
     
        
                
        default: return { ...state };
    }
    
}