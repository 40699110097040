import {
    GET_MEMBERS,
    GET_MEMBERS_SUCCESS,
    GET_CHATS,
    GET_CHATS_SUCCESS,
    CHANGE_CHAT,
    CREATE_CHAT,
    UPDATE_SELECTED_USER,
    SEARCH_MEMBER,
    SEND_MESSAGE,
    REPLY_BY_SELECTED_USER,
    GET_ALL_CONV_MESSAGES,
    SEND_CONV_MESSAGE,
    UPDATE_CONV_MESSAGE,
    REMOVE_CONV_MESSAGE,
    REMOVE_CONV

} from '../actionTypes';


const initial_state = {
    allMembers: [],
    allMessages: [],
    members: [],
    chats: [],
    loading: false,
    currentUser: null,
    selectedUser: null,
};
export default (state = initial_state, action) => {
    switch (action.type) {

        case GET_MEMBERS:
            return { ...state, loading: true };

        case GET_MEMBERS_SUCCESS:
            const members = action.payload;
            const currentUser = members.chats[0]
            return { ...state, loading: false, allMembers: members, members: members.chats, currentUser: currentUser };

        case GET_CHATS:
            return { ...state,chats:action.payload.chats, loading: true };
            case GET_ALL_CONV_MESSAGES:
                return { ...state,allMessages: action.payload, loading: true };
            
        case GET_CHATS_SUCCESS:
            console.log(action.payload.chats)
            return { ...state, loading: false, chats: action.payload.chats, selectedUser : state.allMessages.find(x => x.id === action.payload.selectedConvId)?.users[0]};

        case CHANGE_CHAT:
            return { ...state, selectedUser: state.allMembers.chats.find(x => x.id === action.payload) };

        case CREATE_CHAT:
            return { ...state };

            
        case SEARCH_MEMBER:
            if (action.payload === '') {
                return { ...state, members: state.allMembers.chats };
            } else {
                const keyword = action.payload.toLowerCase();
                const searchedMembers = state.allMembers.chats.filter((member) =>  member.name.toLowerCase().indexOf(keyword) > -1);
                return { ...state, members: searchedMembers }
            }
            case SEND_MESSAGE:

                 return { ...state
                    //,allMessages: state.allMessages.map(item => item.id === action.payload.selectedConvId ?
                //     { ...item, 
                       
                //         lastMessageTime:action.payload.lastMessageTime
                //     } : 
                //     item
                // )  
            };
            
            case UPDATE_CONV_MESSAGE:
         
                //return { ...state,allMessages : [...state.allMessages, { ...action.payload.data}]} ;
                return { ...state} ;
    
        case SEND_CONV_MESSAGE:
         
            //return { ...state,allMessages : [...state.allMessages, { ...action.payload.data}]} ;
            return { ...state} ;

            case REMOVE_CONV_MESSAGE:
                let event_event = state.chats.filter(item=> action.payload.idmessage !== item.id)
                return{...state,chats: event_event}
            
                case REMOVE_CONV:
                    let event_event2 = state.allMessages.filter(item=> action.payload.idmessage !== item.id)
                    return{...state,allMessages: event_event2}
        case REPLY_BY_SELECTED_USER:
            return { ...state };
    
        case UPDATE_SELECTED_USER:
            return { ...state, updateSelectedUser: state.allMembers.chats.find(x => x.id === action.payload.selectedUser) }

        default: return { ...state };
    }
}