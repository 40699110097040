import React from "react";
import {
    ADD_DEV_COMMENT,
  ADD_NEW_DEV_PROCESS,
  ADD_NEW_DEV_SOUS_TACHE,
  ADD_NEW_DEV_TACHE,
  ADD_DEV_SOUS_COMMENT,
  GET_LIST_DEV_PROCESS,
  GET_LIST_DEV_SOUS_TACHE,
  GET_LIST_DEV_TACHE,
  REMOVE_DEV_PROJET,
  REMOVE_DEV_SOUS_TACHE,
  REMOVE_DEV_TACHE,
  UPDATE_DEV_PROJET,
  GET_LIST_DEV_PROCESS_TACHE,
  GET_LIST_DEV_PROCESS_SOUS_TACHE,
  ADD_DEV_PROCESS_COMMENT,
  ADD_DEV_PROCESS_SOUS_COMMENT,
  REMOVE_DEV_PROCESS_TACHE,
  UPDATE_DEV_PROCESS,
  REMOVE_DEV_PROCESS_SOUS_TACHE,
  ADD_NEW_DEV_PROCESS_TACHE,
  ADD_NEW_DEV_PROCESS_SOUS_TACHE,
  UPADTE_DEV_PROCESS_TACHE,
  UPADTE_DEV_PROCESS_SOUS_TACHE,
} from "../actionTypes";
const { All, Doing, Done } = require("../../data/project");

const initial_state = {
  process: [],
  doing_DevProject: [],
  done_DevProject: [],
  tache_Process:  [] ,
  sous_tache_DevProject: { lanes: [] },
  tache_DevProjects: [],
  sous_tache_DevProjects: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case REMOVE_DEV_PROCESS_SOUS_TACHE:
      return state;
    case ADD_NEW_DEV_PROCESS:
      state.process.push(action.payload.data);
    case ADD_NEW_DEV_PROCESS_TACHE:
      state.tache_Process.push(action.payload.data);
      return { ...state, tache_Process: state.tache_Process };
    case ADD_NEW_DEV_PROCESS_SOUS_TACHE:
      state.sous_tache_DevProjects.push(action.payload.data);
      return state;
    case UPDATE_DEV_PROCESS:
      return {
        ...state,
        all_DevProject: state.all_DevProject.map((item) =>
          item.id === action.payload.id
            ? { ...item, ...action.payload.data }
            : item
        ),
      };
      case UPADTE_DEV_PROCESS_TACHE:
      return {
        ...state,
        tache_Process: state.tache_Process.map((item) =>
          item.id === action.payload.id
            ? { ...item, ...action.payload.data }
            : item
        ),
      };
      case UPADTE_DEV_PROCESS_SOUS_TACHE:
        return {
          ...state,
          tache_Process: state.tache_Process.map((item) =>
            item.id === action.payload.id
              ? { ...item,
                 subTacheListe : action.payload.subtacheliste 
                 }
              : item
          ),
        };
  
    case REMOVE_DEV_PROCESS_TACHE:
  

      return {
        ...state,
        tache_Process: state.tache_Process.filter(
          (res) => res.id !== action.payload.idTache
        ),
       
      };

    case REMOVE_DEV_PROJET:
      return {
        ...state,
        all_DevProject: state.all_DevProject.filter(
          (res) => res.id !== action.payload.idprojet
        ),
      };
    case GET_LIST_DEV_PROCESS:
      return { ...state, process: action.projets };
    case GET_LIST_DEV_PROCESS_TACHE:
      console.log(action.taches);
   

      return {
        ...state,
        tache_Process: action.taches
      };
    case GET_LIST_DEV_PROCESS_SOUS_TACHE:
      let taches = [];
      let objS = {
        id: 1,
        
        title: "A Faire",
        cards: action.taches.filter((res) => res.status === "A Faire"),
      };
      let obj1S = {
        id: 2,
        title: "En Cours",
        cards: action.taches.filter((res) => res.status === "En Cours"),
      };
      let obj2S = {
        id: 3,
        title: "Terminé",
        cards: action.taches.filter((res) => res.status === "Terminé"),
      };
      taches.push(objS);
      taches.push(obj1S);
      taches.push(obj2S);

      return {
        ...state,
        sous_tache_DevProject: { lanes: taches },
        sous_tache_DevProjects: action.taches,
      };
    case ADD_DEV_PROCESS_COMMENT:
      return {
        ...state,
        tache_DevProjects: state.tache_DevProjects.map((item) =>
          item.id === action.payload.id
            ? { ...item, 
                
                comment : [item.comment,...action.payload.data] }
            : item
        ),
      };
    case ADD_DEV_PROCESS_SOUS_COMMENT:
        return {
          ...state,
          tache_Process: state.tache_Process.map((item) =>
            item.id === action.payload.idtache
              ? { ...item, 
                  
                subTacheListe : item.subTacheListe.concat([action.payload.data]) }
              : item
          ),
        };
  
    default:
      return state;
  }
};
