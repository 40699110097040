import React,{Fragment,useEffect,useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,Button} from 'reactstrap'
import {X} from 'react-feather'
import {NewOrder,ShippedOrders,CancelledOrders,Price} from '../../../constant'
import axios from "axios";
import { WATCH_ALL_ORDERS_LIST } from '../../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const OrderHistoryAdmin = (props) =>  {
  const history = useHistory()
  const dispatch = useDispatch()
   const orders = useSelector(content => content.OrdersApp.orders);
   console.log(orders)
   useEffect(() => {
     dispatch({type: WATCH_ALL_ORDERS_LIST})
   
    },[])
    const goToFacture = (order) => {
      history.push(`${process.env.PUBLIC_URL}/app/ecommerce/invoice-provider/${order.commande_id}`,{cartFact : order})
    }
    return (
        <Fragment>
        <Breadcrumb parent="Ecommerce" title="Commandes Recentes"/>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
              
                <CardBody>
                  <Row>
                  {orders.map((items,i) =>
                    <Col xl="4" md="6" key={i}>
                        <div className="prooduct-details-box" onDoubleClick={() => goToFacture(items)}>
                   
                                   <div className="media">
                                   <img className="align-self-center img-fluid img-60" src={items.image} alt="#"/>
                            <div className="media-body ml-3">
                              <div className="product-name">
                                <h6><a onDoubleClick={() => goToFacture(items)}>{items.last_name} {items.first_name}</a></h6>
                              </div>
                              <div className="rating">
                              <h6><a onDoubleClick={() => goToFacture(items)}>{items.franchise_name} {items.first_name}</a></h6>
                            
                              </div>
                              <div className="price d-flex"> 
                                <div className="text-muted mr-2">{Price}</div>: {items.Total}
                              </div>
                              <div className="avaiabilty">
                              
                              </div><Button color="primary" size="xs">{items.statustext}</Button><X className="close"/>
                            </div>
                          </div>
                      
                          </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {/* <Col sm="12">
              <Card>
                <CardHeader>
                <h5>{"Commande Accepté"}</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                  {orders.slice(9,18).map((items,i) =>
                    <Col xl="4" md="6" key={i}>
                        <div className="prooduct-details-box">                                 
                          <div className="media"><img className="align-self-center img-fluid img-60" src={items.imageProvider} alt="#"/>
                            <div className="media-body ml-3">
                              <div className="product-name">
                                <h6><a href="#javascript">{items.product}</a></h6>
                              </div>
                              <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                              </div>
                              <div className="price d-flex"> 
                                <div className="text-muted mr-2">{Price}</div>: {items.price}
                              </div>
                              <div className="avaiabilty">
                                <div className="text-success">{items.stock}</div>
                              </div><Button color="success" size="xs">{items.prdouctstatus}</Button><X className="close"/>
                            </div>
                          </div>
                          </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardHeader>
                <h5>{CancelledOrders}</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                  {orders.slice(18,27).map((items,i) =>
                    <Col xl="4" md="6" key={i}>
                        <div className="prooduct-details-box">                                 
                          <div className="media"><img className="align-self-center img-fluid img-60" src={require("../../../assets/images/" + items.img) } alt="#"/>
                            <div className="media-body ml-3">
                              <div className="product-name">
                                <h6><a href="#javascript">{items.product}</a></h6>
                              </div>
                              <div className="rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                              </div>
                              <div className="price d-flex"> 
                                <div className="text-muted mr-2">{Price}</div>: {items.price}
                              </div>
                              <div className="avaiabilty">
                                <div className="text-success">{items.stock}</div>
                              </div><Button color="danger" size="xs">{items.prdouctstatus}</Button><X className="close"/>
                            </div>
                          </div>
                          </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container> 
        </Fragment>
    );
}
export default OrderHistoryAdmin;