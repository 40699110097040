import {call,put,takeLatest} from "redux-saga/effects";
import {fetchNoteApi, fetchMailGroupApi} from '../../api'
import { WATCH_NOTE_LIST,GET_NOTE_LIST, WATCH_MAIL_GROUP_LIST, GET_MAIL_GROUP_LIST } from "../../redux/actionTypes";

function* fetchTodoAsyn() {
    const data = yield call(fetchMailGroupApi);
    yield put({type : GET_MAIL_GROUP_LIST, list:data});
}

export function* watchMailGroupList() {
    yield takeLatest(WATCH_MAIL_GROUP_LIST,fetchTodoAsyn)
}