import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Carousel, CarouselItem, CarouselIndicators, CarouselCaption, CarouselControl, Media } from 'reactstrap'
import { WATCH_FRANCHISE_LIST } from '../../redux/actionTypes';



export const CarsouselFranchise = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === franchises.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? franchises.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
    if (animating) return;
  }
  const dispatch = useDispatch();

  const franchises = useSelector(content => content.Franchiseapp.franchises);
  //const doingProject = useSelector(content => content.Franchiseapp.doing_Project);
  //const doneProject = useSelector(content => content.Franchiseapp.done_Project);
  useEffect(() => {
    dispatch({type: WATCH_FRANCHISE_LIST})
  },[dispatch])
  return (
    <Carousel
   
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={franchises} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {franchises.map((item) => {
        return (
          <CarouselItem 
          
            onExiting={() => setAnimating(true)}
            key={item.franchise_image}
          >
            <Media src={item.franchise_image}  alt={item.Name} className="img-fluid" />
            <CarouselCaption captionText={item.franchise_bio} captionHeader={item.franchise_bio} />
          </CarouselItem>
        );
      })}
      <CarouselControl  direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}


export const CarsouselFranchise2 = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const history = useHistory()
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === franchises.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? franchises.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
    if (animating) return;
  }
  const dispatch = useDispatch();

  const franchises = useSelector(content => content.Franchiseapp.franchises);
  //const doingProject = useSelector(content => content.Franchiseapp.doing_Project);
  //const doneProject = useSelector(content => content.Franchiseapp.done_Project);
  useEffect(() => {
    dispatch({type: WATCH_FRANCHISE_LIST})
  },[dispatch])
  return (
    <Carousel
    ride={true}
   // slide={true}
    
    interval={2000}
      activeIndex={activeIndex}
       next={next}
     previous={previous}
    >
      <CarouselIndicators   items={franchises} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {franchises.map((item) => {
        return (
          <CarouselItem
       
            onExiting={() => setAnimating(true)}
            key={item.id}
          >
            <Media src={item.franchise_image} onClick={()=>  history.push(`${process.env.PUBLIC_URL}/app/franchises/franchisesDetails/${item.franchise_id}` ,{franchise : item})} style={{color : 'white', height: 180,marginTop: '-1%', width : '66%', marginLeft: '18%'}} alt={item.franchise_name} className="img-fluid" />
            {/* <CarouselCaption className="text-danger" captionText={item.franchiseBio} captionHeader={item.franchiseBio} /> */}
          </CarouselItem>
        );
      })}
      {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} /> */}
    </Carousel>
  );
}

