import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Cancel } from '../../../constant'
import ApercuFilePdf from './ApercuFilePdf';
const ModalApercuFile = ({showApercuToggle,showApercu,selectedImage}) => {
   
    return (
        <Modal isOpen={showApercu} toggle={showApercuToggle} size="lg">
        <ModalHeader toggle={showApercuToggle}>Apercu Fichier</ModalHeader>
        <ModalBody>
        <ApercuFilePdf selectedImage={selectedImage} />
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" onClick={showApercuToggle}>{Cancel}</Button>
        </ModalFooter>
      </Modal>
    )
}
export default ModalApercuFile 