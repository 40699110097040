import React, { Fragment,useEffect,useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'


import {Container,Row,Col,Card,CardBody,Form,FormGroup,Label,Input,Button, Modal, ModalHeader, ModalBody, ModalFooter, NavItem, Nav, NavLink, CardHeader, TabContent, TabPane} from 'reactstrap'
import DatePicker from "react-datepicker";
import {useForm} from 'react-hook-form'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import {withRouter,Link, useLocation, useParams, useHistory} from 'react-router-dom'
import {  toast } from 'react-toastify';
import {ProjectStatus,Opened, Closed, FranchiseAdd1, FranchiseAnnuler, SubFranchiseTitle, SubFranchiseBio, SubFranchiseManager, ModalTitle, Close, SaveChanges, SubFranchiseAdresse} from '../../../constant'
import { Lock, MapPin } from 'react-feather';
import GoogleMapReact from 'google-map-react';
import { Accordion } from 'react-bootstrap';
import moment from 'moment';

import { EditSubfranchise } from '../../../redux/subfranchise/action';
import { fetchSubFranchiseByIdApi, fetchUserResponsableFranchiseApi ,fetchUserApi, fetchallUserResponsableFranchiseApi} from '../../../api';
const AnyReactComponent = ({ text }) => <div><MapPin></MapPin></div>;

    const SubFranchiseEdit = (props) => {
    const {idSubFranch, idFranch} = useParams();
    const history = useHistory()
    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm();
const [selectedUser, setSelectedUser] = useState({})
const [selectedUserManager, setselectedUserManager] = useState("")
const [selectedUserManagerId, setselectedUserManagerId] = useState("")
const [scrollingmodal, setscrollingModal] = useState(false);
const [status, setstatus] = useState('false');

const Scrollmodaltoggle = () => setscrollingModal(!scrollingmodal);

const [phoneNumber,setphoneNumber] = useState("")
const [subfranchiseBio,setsubfranchiseBio] = useState("")
const [subFranchiseName,setsubFranchiseName] = useState("")

const [subFranchise,setsubFranchise] = useState({})
const [percent, setpercent] = useState(0)
const [urlImage, setImageUrl] = useState("")
const [Launchdate, setLaunchdate] = useState(new Date())
const [stateAudit, setstateAudit] = useState(false)
const [stateAnnuaire, setstateAnnuaire] = useState(false)
const [auditListe, setauditListe] = useState([])
const [annuairesListe, setannuairesListe] = useState([])


const[location,setlocation] = useState({ 

     lat: 18.5204, lng: 73.8567
    
 })
 const [activeTab4, setActiveTab4] = useState('1');
const [franchise_adresse, setFranchise_adresse] = useState("")
const [franchise_bio, setFranchise_bio] = useState("")
const [franchise_name, setFranchise_name] = useState("")
const [franchise_state, setFranchise_state] = useState("")
const [pays, setPays] = useState("")
const [phone_number, setPhone_number] = useState("")

const [ville, setVille] = useState("")
const [zipcode, setZipcode] = useState("")
const [email, setEmail] = useState("")
const [fax, setFax] = useState("")
const [date_signature_contrat, setDate_signature_contrat] = useState(new Date())
const [echeance_contrat, setEcheance_contrat] = useState(new Date())
const [denomination_juridique, setDenomination_juridique] = useState("")
const [siret, setSiret] = useState("")
const [code_naf_ape, setCode_naf_ape] = useState("")
const [tv_intracommunautaire, setTv_intracommunautaire] = useState("")
const [zone_chalandise, setZone_chalandise] = useState("")
const [adresse, setAdresse] = useState("")
const [complement_adresse, setComplement_adresse] = useState("")
const [marquer, setMarquer] = useState("")
const [lat, setLat] = useState("")
const [lng, setLng] = useState("")
const [identifiant_franchise, setIdentifiant_franchise] = useState(0)
const [secteur, setSecteur] = useState("")
const [emplacement, setEmplacement] = useState("")
const [vente_emporter, setVente_emporter] = useState("")
const [provider_delivery, setProvider_delivery] = useState("")
const [places_assises, setPlaces_assises] = useState(0)
const [places_terrasses, setPlaces_terrasses] = useState(0)
const [surface_magasin, setSurface_magasin] = useState(0)
const [surface_reserve, setSurface_reserve] = useState(0)
const [horaire_ouverture, setHoraire_ouverture] = useState("")

const [horaire_rendez_vous, setHoraire_rendez_vous] = useState("")
const [horaire_excep_ouverture, setHoraire_excep_ouverture] = useState("")
const [horaire_excep_rendez_vous, setHoraire_excep_rendez_vous] = useState("")
const [visible_site, setVisible_site] = useState("")
const [rendez_vous_web, setRendez_vous_web] = useState("")
const [url_site_web, setUrl_site_web] = useState("")
const [url_facebook, setUrl_facebook] = useState("")
const [url_visit_vertuelle_google, setUrl_visit_vertuelle_google] = useState("")
const [code_google, setCode_google] = useState("")
const [lien_google, setLien_google] = useState("")

const [description_seo, setDescription_seo] = useState("")
const [status_local, setStatus_local] = useState("")
const [dure_bail, setDure_bail] = useState(0)
const [expiration_bail, setExpiration_bail] = useState(new Date())
const [date_dernier_travaux, setDate_dernier_travaux] = useState(null)
const [date_prochaine_travaux, setDate_prochaine_travaux] = useState(new Date())
const [plancha, setPlancha] = useState("")

const handleApiLoaded = (map, maps) => {
  // use map and maps objects
}

const [plaquette_induction, setPlaquette_induction] = useState("")
const [chambre_froid, setChambre_froid] = useState("")
const [machine_cafe, setMachine_cafe] = useState("")
const [marque_machine_cafe, setMarque_machine_cafe] = useState("")
const [photocopieur, setPhotocopieur] = useState("")
const [code_client, setCode_client] = useState("")

const [royalties, setRoyalties] = useState("")
const [royalties_communication, setRoyalties_communication] = useState("")
const [durée_contrat, setDurée_contrat] = useState("")
const [contrat_multi_sites, setContrat_multi_sites] = useState("")
const [multi_enseigne, setMulti_enseigne] = useState("")
const [retard_paiement_royalties, setRetard_paiement_royalties] = useState("")
const [nom_cabinet_comptable, setNom_cabinet_comptable] = useState("")


const [adresse_cabinet_comptable, setAdresse_cabinet_comptable] = useState("")
const [tel_expert_comptable, setTel_expert_comptable] = useState("")
const [email_expert_comptable, setEmail_expert_comptable] = useState("")
const [date_cloture_comptable, setDate_cloture_comptable] = useState(new Date())
const [liasses_fiscales, setLiasses_fiscales] = useState("")
const [banque, setBanque] = useState("")
const [adresse_banque, setAdresse_banque] = useState("")
const [commentaire_facturation, setCommentaire_facturation] = useState("")




const [autre_commentaire, setAutre_commentaire] = useState("")
const [identifiant_ponoma, setIdentifiant_ponoma] = useState(0)
const [region_logistique_sec, setRegion_logistique_sec] = useState("")
const [region_logistique_froid, setRegion_logistique_froid] = useState("")
const [logiciel_caisse, setLogiciel_caisse] = useState("")
const [provider_internet, setProvider_internet] = useState("")
const [identifiant_fournisseur_logiciel, setIdentifiant_fournisseur_logiciel] = useState("")
const [laboratoire, setLaboratoire] = useState([])
const [dernier_controle_sanitaire, setDernier_controle_sanitaire] = useState(new Date())

const [last_part_national, setLast_part_national] = useState(new Date())
const [compte_facebook, setCompte_facebook] = useState("")
const [nbre_recommendation_facebook, setNbre_recommendation_facebook] = useState(0)
const [nbre_avis_google, setNbre_avis_google] = useState(0)
const [nbre_page_jaune, setNbre_page_jaune] = useState(0)
const [nbre_avis_pages_jaune, setNbre_avis_pages_jaune] = useState(0)


const [etat_recompense, setEtat_recompense] = useState("")
const [personne_recompense, setPersonne_recompense] = useState("")
const [best_recompense, setBest_recompense] = useState("")
const [best_result, setBest_result] = useState("")

const [users, setUsers] = useState([]);

useEffect(() => {

  fetchSubFranchiseByIdApi(idSubFranch).then(res => {
    setsubFranchise(res)

if(res.franchise_name == undefined) 
history.push(`${process.env.PUBLIC_URL}/app/franchises/franchiseList/${idFranch}`)


setFranchise_name(res.franchise_name)
setselectedUserManager(res.manager_name)
setFranchise_bio(res.subfranchise_bio)
setFranchise_state(res.subfranchise_state)
setAdresse(res.adresse)
setAdresse_banque(res.adresse_banque)
setAdresse_cabinet_comptable(res.adresse_cabinet_comptable)
setAutre_commentaire(res.autre_commentaire)
setBanque(banque)
setBest_recompense(res.best_recompense)
setBest_result(res.best_result)
setChambre_froid(res.chambre_froid)
setCode_client(res.code_client)
setCode_google(res.code_google)
setCode_naf_ape(res.code_naf_ape)
setCommentaire_facturation(res.commentaire_facturation)
setComplement_adresse(res.complement_adresse)
setCompte_facebook(res.compte_facebook)
setContrat_multi_sites(res.contrat_multi_sites)
setDate_cloture_comptable(moment(res.date_cloture_comptable ?? new Date()).toDate())
setDate_dernier_travaux(moment(res.date_dernier_travaux ?? new Date()).toDate())
setDate_prochaine_travaux(moment(res.date_prochaine_travaux ?? new Date()).toDate())
setDate_signature_contrat(res.date_signature_contrat)
setDenomination_juridique(res.denomination_juridique)
setDernier_controle_sanitaire(moment(res.dernier_controle_sanitaire ?? new Date()).toDate())
setDescription_seo(res.description_seo)
setDure_bail(res.dure_bail)
setDurée_contrat(res.durée_contrat)
//setEcheance_contrat(moment(res.echeance_contrat ?? new Date()))
setEmail(res.email)
setEmail_expert_comptable(res.email_expert_comptable)
setEmplacement(res.emplacement)
setEtat_recompense(res.etat_recompense)
setExpiration_bail(res.expiration_bail)
setFax(res.fax)
setFranchise_adresse(res.franchise_adresse)
setHoraire_excep_ouverture(res.horaire_excep_ouverture)
setHoraire_excep_rendez_vous(res.horaire_excep_rendez_vous)
setHoraire_ouverture(res.horaire_ouverture)
setHoraire_rendez_vous(res.horaire_rendez_vous)
setIdentifiant_fournisseur_logiciel(res.identifiant_fournisseur_logiciel)
setIdentifiant_franchise(identifiant_franchise)
setIdentifiant_ponoma(identifiant_ponoma)
setNbre_page_jaune(res.nbre_page_jaune)
setNbre_avis_pages_jaune(res.nbre_avis_pages_jaune)
setNbre_recommendation_facebook(res.nbre_recommendation_facebook)
setNbre_avis_google(res.nbre_avis_google)
setPhone_number(res.phone_number)
setPays(res.pays)
setPersonne_recompense(res.personne_recompense)
setPhotocopieur(res.photocopieur)
setPlaces_assises(res.places_assises)
setPlaces_terrasses(res.places_terrasses)
setPlancha(res.plancha)
setPlaquette_induction(res.plaquette_induction)
setProvider_delivery(provider_delivery)
setProvider_internet(res.provider_internet)
setMachine_cafe(res.machine_cafe)
setMarque_machine_cafe(res.marque_machine_cafe)
setMarquer(res.marquer)
setSurface_magasin(res.surface_magasin)
setSurface_reserve(res.surface_reserve)
setUrl_site_web(res.url_site_web)
setUrl_facebook(res.url_facebook)
setUrl_visit_vertuelle_google(res.url_visit_vertuelle_google)
setLaboratoire(res.laboratoire)
setLast_part_national(moment(res.last_part_national ?? new Date()).toDate())
setLat(res.lat)
setLng(res.lng)
setLaunchdate(moment(res.Launchdate ?? new Date()).toDate())
setLiasses_fiscales(res.liasses_fiscales)
setLien_google(res.lien_google)
setLogiciel_caisse(res.logiciel_caisse)
setStatus_local(res.status_local)
setRegion_logistique_froid(res.region_logistique_froid)
setRegion_logistique_sec(res.region_logistique_sec)
setRendez_vous_web(res.rendez_vous_web)
setRetard_paiement_royalties(res.retard_paiement_royalties)
setRoyalties(res.royalties)
setRoyalties_communication(res.royalties_communication)
setSecteur(res.secteur)
setSiret(res.siret)
setTel_expert_comptable(res.tel_expert_comptable)
setTv_intracommunautaire(res.tv_intracommunautaire)
setVente_emporter(res.vente_emporter)
setVille(res.ville)
setVisible_site(res.visible_site)
setZipcode(res.zipcode)
setZone_chalandise(res.zone_chalandise)

setauditListe(res.auditListe || [])
setannuairesListe(res.annuairesListe|| [])


setselectedUserManagerId(res.manager_id)
// fetchUserApi.then(res4 => {
  

//   setSelectedUser(res4.find(res3 => res3.user_id === res.manager_id) ?? {})
//   })
  fetchUserResponsableFranchiseApi().then(res2 => {
    console.log(res2)
    setUsers(res2)
    
   
    
      })
  fetchallUserResponsableFranchiseApi().then(res4 => {
console.log(res4)

setSelectedUser(res4.find(res3 => res3.user_id === res.manager_id) ?? {})

  })
})
  //dispatch({type: WATCH_USER_LIST})
},[dispatch])

    const AddProject = data2 => {
      let data = {}
      if (selectedUser.user_id !== undefined) {
        
        data.subfranchise_bio = franchise_bio 
        data.franchise_image= "rfrf"  // (normal Attribute)
     // (normal Attribute)
        data.franchise_name =franchise_name 
        data.subfranchise_avis = 0 // (normal Attribute)
        data.subfranchise_provider = "" // (normal Attribute)
        data.subfranchise_state = franchise_state // (normal Attribute)
        data.launch_date= moment(Launchdate).format('yyyy-MM-DD HH:mm')  // (normal Attribute)
        data.pays = pays  // (normal Attribute)
        data.phone_number = phone_number   // (normal Attribute)
        data.status = franchise_state  // (normal Attribute)
       
        data.ville = ville  // (normal Attribute)
        data.zipcode = zipcode  // (normal Attribute)
      
        data.email= email   // (normal Attribute)
        data.fax = fax  // (normal Attribute)
        data.date_signature_contrat= date_signature_contrat   // (normal Attribute)
        data.echeance_contrat = echeance_contrat  // (normal Attribute)
        data.denomination_juridique= denomination_juridique  // (normal Attribute)
        data.siret= siret   // (normal Attribute)
        data.code_naf_ape= code_naf_ape  // (normal Attribute)
        data.tv_intracommunautaire = tv_intracommunautaire   // (normal Attribute)
        data.zone_chalandise = zone_chalandise   // (normal Attribute)
        data.adresse = adresse  // (normal Attribute)
        data.complement_adresse = complement_adresse   // (normal Attribute)
        data.marquer = marquer   // (normal Attribute)
        data.lat = location.lat   // (normal Attribute)
        data.lng = location.lng   // (normal Attribute)
        data.identifiant_franchise = identifiant_franchise   // (normal Attribute)
        data.secteur = secteur   // (normal Attribute)
        data.emplacement = emplacement  // (normal Attribute)
        data.vente_emporter = vente_emporter   // (normal Attribute)
        data.provider_delivery = provider_delivery   // (normal Attribute)
        data.places_assises = places_assises  // (normal Attribute)
        data.places_terrasses = places_terrasses  // (normal Attribute)
        data.surface_magasin = surface_magasin   // (normal Attribute)
        data.surface_reserve = surface_reserve   // (normal Attribute)
        data.horaire_ouverture = horaire_ouverture   // (normal Attribute)
        data.horaire_rendez_vous = horaire_rendez_vous  // (normal Attribute)
        data.horaire_excep_ouverture = horaire_excep_ouverture  // (normal Attribute)
        data.horaire_excep_rendez_vous = horaire_excep_rendez_vous   // (normal Attribute)
        data.visible_site = visible_site   // (normal Attribute)
        data.rendez_vous_web = rendez_vous_web   // (normal Attribute)
        data.url_site_web = url_site_web   // (normal Attribute)
        data.url_facebook=url_facebook   // (normal Attribute)
        data.url_visit_vertuelle_google = url_visit_vertuelle_google   // (normal Attribute)
        data.code_google=code_google   // (normal Attribute)
        data.lien_google =lien_google   // (normal Attribute)
        data.description_seo =description_seo  // (normal Attribute)
        data.status_local=status_local   // (normal Attribute)
        data.dure_bail =dure_bail  // (normal Attribute)
        data.expiration_bail =expiration_bail  // (normal Attribute)
        data.date_dernier_travaux =date_dernier_travaux   // (normal Attribute)
        data.date_prochaine_travaux =date_prochaine_travaux  // (normal Attribute)
        data.plancha =plancha // (normal Attribute)
        data.plaquette_induction =plaquette_induction  // (normal Attribute)
        data.chambre_froid =chambre_froid  // (normal Attribute)
        data.machine_cafe =  machine_cafe  // (normal Attribute)
        data.marque_machine_cafe =marque_machine_cafe   // (normal Attribute)
        data.photocopieur = photocopieur  // (normal Attribute)
        data.code_client =code_client   // (normal Attribute)
        data.royalties =royalties   // (normal Attribute)
        data.royalties_communication =royalties_communication   // (normal Attribute)
        data.durée_contrat =durée_contrat  // (normal Attribute)
        data.contrat_multi_sites =contrat_multi_sites   // (normal Attribute)
        data.multi_enseigne =multi_enseigne   // (normal Attribute)
        data.retard_paiement_royalties = retard_paiement_royalties  // (normal Attribute)
        data.nom_cabinet_comptable =nom_cabinet_comptable  // (normal Attribute)
        data.adresse_cabinet_comptable = adresse_cabinet_comptable  // (normal Attribute)
        data.tel_expert_comptable =tel_expert_comptable   // (normal Attribute)
        data.email_expert_comptable =email_expert_comptable  // (normal Attribute)
        data.date_cloture_comptable =date_cloture_comptable  // (normal Attribute)
        data.liasses_fiscales =liasses_fiscales  // (normal Attribute)
        data.banque = banque   // (normal Attribute)
        data.adresse_banque = adresse_banque  // (normal Attribute)
        data.commentaire_facturation = commentaire_facturation   // (normal Attribute)
        data.autre_commentaire = autre_commentaire   // (normal Attribute)
        data.identifiant_ponoma = identifiant_ponoma  // (normal Attribute)
        data.region_logistique_sec = region_logistique_sec  // (normal Attribute)
        data.region_logistique_froid = region_logistique_froid   // (normal Attribute)
        data.logiciel_caisse = logiciel_caisse  // (normal Attribute)
        data.provider_internet = provider_internet  // (normal Attribute)
        data.identifiant_fournisseur_logiciel = identifiant_fournisseur_logiciel // (normal Attribute)
        data.laboratoire = laboratoire   // (normal Attribute)
        data.dernier_controle_sanitaire = dernier_controle_sanitaire  // (normal Attribute)
        data.last_part_national = last_part_national  // (normal Attribute)
        data.compte_facebook = compte_facebook  // (normal Attribute)
        data.nbre_recommendation_facebook = nbre_recommendation_facebook   // (normal Attribute)
        data.nbre_avis_google = nbre_avis_google  // (normal Attribute)
        data.nbre_page_jaune = nbre_page_jaune // (normal Attribute)
        data.nbre_avis_pages_jaune = nbre_avis_pages_jaune   // (normal Attribute)
        data.etat_recompense = etat_recompense   // (normal Attribute)
        data.personne_recompense = personne_recompense   // (normal Attribute)
        data.best_recompense = best_recompense   // (normal Attribute)
        data.best_result = best_result  
     
       data.manager_id = selectedUser.user_id
       data.subfranchise_id = idSubFranch
      
         
       //  data.auditListe = auditListe
       
        // data.annuairesListe = annuairesListe
       
         data.update_date = moment(new Date()).format('yyyy-MM-DD HH:mm')
     // data.franchiseID = localStorage.getItem("@intranet_franchiseid")
  
        dispatch(EditSubfranchise(idSubFranch,data))
      //  props.history.goBack()
      }
    };
    const productColumns = [
      {
          name:"Image",
    
          cell: row => {
            return (
              <img src={row.userImage} style={{ width: 50, height: 50 }} alt="" />
            )
          },
          sortable: true,
          center:true,
        },
        {
          name:"Nom responsable",
          selector: 'firstName',
          sortable: true,
          center:true,
        },
        {
          name:"Prenom responsable",
          selector: 'lastName',
          sortable: true,
          center:true,
        },
        {
          name:"Action",
          
          cell : row => {return(
    
     <Input style={{color : 'red'}} className='primary'   onClick={() => setSelectedUser(row)} type="checkbox" checked={row.id === selectedUser.id}  />

 )},
 
          sortable: true,
          center:true,
        },
  ];
  const conditionalRowStyles = [
    {
      when: row => row.id === selectedUser.id,
      style: {
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    }
  ];
    return (
        <Fragment>
        <Breadcrumb parent="franchises" title="Modifier Sous Franchise" /> 
        <Container fluid={true}>
            <Row>
            <Col lg="12 box-col-12" xl="12 xl-100">
                        <Card>
                            <CardHeader>
                              
                            </CardHeader>
                            <CardBody className="tabbed-card">
                                <Nav  className="nav-pills nav-secondary">
                                    <NavItem>
                                        <NavLink className={activeTab4 === '1' ? 'active' : ''} onClick={() => setActiveTab4('1')}>
                                            <i className="icofont icofont-ui-glasses"></i> {"Informations générales"} 
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab4 === '2' ? 'active' : ''} onClick={() => setActiveTab4('2')}>
                                            <i className="icofont icofont-man-in-location"></i> {"Localisation"}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab4 === '3' ? 'active' : ''} onClick={() => setActiveTab4('3')}>
                                            <i className="icofont icofont-infos"></i> {"Autres Informations"}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab4 === '4' ? 'active' : ''} onClick={() => setActiveTab4('4')}>
                                            <i className="icofont icofont-user"></i> {"Responsable"}
                                        </NavLink>
                                    </NavItem>
                                 
                                </Nav>
                                <Form className="theme-form" onSubmit={(e) => {
                                  e.preventDefault()
                                  handleSubmit(AddProject(e))}
                                  }>
                                <TabContent activeTab={activeTab4}>
                                    <TabPane tabId="1">
                                   
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{SubFranchiseTitle}(*)</Label>
                            <Input className="form-control" type="text" value={franchise_name} onChange={(e) => setFranchise_name(e.target.value)}  name="FranchiseName" placeholder={SubFranchiseTitle} innerRef={register({ required: true })} required/>
                            <span style={{ color: "red" }}>{errors.FranchiseName && 'Nom Enseigne est obligatoire'}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                    
                      <Row>
                  <Col>
                      <FormGroup>
                        <Label className="form-label">Numéro de Téléphone(*)</Label>
                        <Input required className="form-control" value={phone_number} onChange={(e) => setPhone_number(e.target.value)} type="tel" placeholder=" Numero télephone" tel />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Fax(*)</Label>
                        <Input required className="form-control" value={fax} onChange={(e) => setFax(e.target.value)} type="tel" placeholder="Fax" tel />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Email de contact(*)</Label>
                        <Input  className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" email required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Date de signature du contrat(*)</Label>
                        <Input  className="form-control" value={date_signature_contrat} onChange={(e) => setDate_signature_contrat(e.target.value)} type="date" placeholder=" Date signature contrat" required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Date lancement"}(*)</Label>
                            <DatePicker  className="form-control digits" onChange={e => setLaunchdate(e)} showPopperArrow={false} selected={Launchdate}  dateFormat="P" />
                        
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{"Echéance du contrat"}(*)</Label>
                            <DatePicker  className="form-control digits" onChange={e => setEcheance_contrat(e)} showPopperArrow={false} selected={echeance_contrat}  dateFormat="P" />
                        
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Dénomination juridique(*)</Label>
                        <Input  className="form-control" value={denomination_juridique} onChange={(e) => setDenomination_juridique(e.target.value)} type="text" placeholder="Dénomination juridique" required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">SIRET(*)</Label>
                        <Input  className="form-control" value={siret} onChange={(e) => setSiret(e.target.value)} type="text" placeholder="SIRET" required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Code NAF/APE(*) </Label>
                        <Input  className="form-control" value={code_naf_ape} onChange={(e) => setCode_naf_ape(e.target.value)} type="text" placeholder="Code NAF/APE " required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">TVA Intracommunautaire(*)</Label>
                        <Input  className="form-control" value={tv_intracommunautaire} onChange={(e) => setTv_intracommunautaire(e.target.value)} type="text" placeholder="TVA Intracommunautaire" required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Zone de chalandise / interventions(*)</Label>
                        <Input  className="form-control" value={zone_chalandise} onChange={(e) => setZone_chalandise(e.target.value)} type="text" placeholder="Zone de chalandise / interventions" required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>{SubFranchiseBio}(*)</Label>
                            <Input className="form-control" type="textarea" rows="3" value={franchise_bio} onChange={(e) => setFranchise_bio(e.target.value)}  name="franchiseBio" placeholder={SubFranchiseBio} innerRef={register({ required: true })}/>
                          
                          </FormGroup>
                        </Col>
                      </Row>
  
                      <Row>
                        <Col>
                          <div className="mb-0 text-right">
                             
                              <Link className="mr-1" to={`${process.env.PUBLIC_URL}/app/franchises/franchiseList`}>
                              <Button color="secondary">{FranchiseAnnuler}</Button>
                              </Link>
                              <Button color="primary" onClick={() => setActiveTab4("2")} >{"Suivant"}</Button>
                          </div>
                        </Col>
                      </Row>
                     
                
          
                                    </TabPane>
                                    <TabPane tabId="2">
                                    <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">{SubFranchiseAdresse}(*)</Label>
                        <Input  className="form-control" value={adresse} onChange={(e) => setAdresse(e.target.value)} type="text" placeholder="Adresse" required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Complément d'adresse</Label>
                        <Input  className="form-control" value={complement_adresse} onChange={(e) => setComplement_adresse(e.target.value)} type="text" placeholder="Complément d'adresse" />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Code postal(*)</Label>
                        <Input  className="form-control" value={zipcode} onChange={(e) => setZipcode(e.target.value)} inputMode={'decimal'} type="text" minLength={4} max={7} placeholder="Code postal" required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Ville(*)</Label>
                        <Input  className="form-control" value={ville} onChange={(e) => setVille(e.target.value)} type="text" placeholder="Ville" required/>
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Pays(*)</Label>
                        <Input  className="form-control" value={pays} onChange={(e) => setPays(e.target.value)} type="text" placeholder="Pays" />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Marqueur(*)</Label>
                        <input  className="form-control" value={marquer} onChange={(e) => setMarquer(e.target.value)} type="color" placeholder="Marqueur" />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                    <div style={{ width : '100%', height : '500px', zIndex : 101 }}>
            <MapPin  style={{ left: '50%', 
          zIndex :100,
top : '50%',
    position: 'absolute'
   }} size={30} color={marquer}></MapPin>
        <GoogleMapReact
          resetBoundsOnResize = {true}
          options={{mapTypeControl : true, streetView : true, draggable : true }}
          bootstrapURLKeys={{ key: 'AIzaSyCNdpXcuLDb26QUSLCkqW2kBs7aEO9u1dM'}}
          defaultCenter={location}
          center={location}
          defaultZoom={1}
          draggable={true}
          onChange={res => { 
            setlocation({ 
              lat: res.center.lat, 
              lng: res.center.lng
            })
            setLat(res.center.lat)
            setLng(res.center.lng)
          }}
          yesIWantToUseGoogleMapApiInternals={true}
        >
        <AnyReactComponent
            lat={lat}
            lng={lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div></Col>
                  </Row>
                  <Row>
                        <Col>
                          <div className="mb-0 text-right">
                             
                             
                              <Button onClick={() => setActiveTab4("1")} color="secondary">{"Précedent"}</Button>
                         
                              <Button color="primary" onClick={() => setActiveTab4("3")} >{"Suivant"}</Button>
                          </div>
                        </Col>
                      </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                  
            <Accordion defaultActiveKey="0">
            <div className="default-according" id="accordion1">
          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="secondary" eventKey="0">
                  <i className="icofont icofont-plus"></i>{"Caractéristiques"}
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
                  <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Identifiant implantation</Label>
                        <Input  className="form-control" value={identifiant_franchise} onChange={(e) => setIdentifiant_franchise(e.target.value)} type="number" placeholder="Identifiant implantation" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Secteurs Concédés (liste de codes IRIS) </Label>
                        <Input  className="form-control" value={secteur} onChange={(e) => setSecteur(e.target.value)} type="text" placeholder="Secteurs Concédés (liste de codes IRIS) " />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Emplacement</Label>
                        <Input  className="form-control" value={emplacement} onChange={(e) => setEmplacement(e.target.value)} type="text" placeholder="Emplacement" />
                      </FormGroup>
                    </Col>
                 
                  
                  </Row>  
                  
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Vente à emporter</Label>
                        <select className="form-control" onChange={(e) => setVente_emporter(e.target.value)} >
                          <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Fournisseurs livraison</Label>
                        <select className="form-control" onChange={(e) => setProvider_delivery(e.target.value)} >
                          <option value={"Uber eats"}>Uber eats</option>
                          <option value={"Deliveroo"}>Deliveroo</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Places assises</Label>
                        <Input  className="form-control" value={places_assises} onChange={(e) => setPlaces_assises(e.target.value)} type="number" placeholder="Places assises" />
                      </FormGroup>
                    </Col>
                 
                  
                  </Row> 

                        <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Places terrasses</Label>
                        <Input  className="form-control" value={places_terrasses} onChange={(e) => setPlaces_terrasses(e.target.value)} type="number" placeholder="Places terrasses" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Surface magasin</Label>
                        <Input  className="form-control" value={surface_magasin} onChange={(e) => setSurface_magasin(e.target.value)} type="number" placeholder="Surface magasin" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Surface réserve</Label>
                        <Input  className="form-control" value={surface_reserve} onChange={(e) => setSurface_reserve(e.target.value)} type="number" placeholder="Surface réserve" />
                      </FormGroup>
                    </Col>
                 
                  
                  </Row> 
                     </CardBody>
            </Accordion.Collapse>
          </Card>
          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="1">
                <i className="icofont icofont-plus"></i>{"Horaire"}
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="1">
                  <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Horaires d'ouverture</Label>
                        <Input  className="form-control" value={horaire_ouverture} onChange={(e) => setHoraire_ouverture(e.target.value)} type="text" placeholder="Horaires d'ouverture" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Horaires de rendez-vous</Label>
                        <Input  className="form-control" value={horaire_rendez_vous} onChange={(e) => setHoraire_rendez_vous(e.target.value)} type="text" placeholder="Horaires de rendez-vous" />
                      </FormGroup>
                    </Col>
                   
                  
                  </Row> 
                  <Row>

                  <Col>
                      <FormGroup>
                        <Label className="form-label">Horaires exceptionnels d'ouverture</Label>
                        <Input  className="form-control" value={horaire_excep_ouverture} onChange={(e) => setHoraire_excep_ouverture(e.target.value)} type="text" placeholder="Horaires exceptionnels d'ouverture" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Horaires exceptionnels de rendez-vous </Label>
                        <Input  className="form-control" value={horaire_excep_rendez_vous} onChange={(e) => setHoraire_excep_rendez_vous(e.target.value)} type="text" placeholder="Horaires exceptionnels de rendez-vous" />
                      </FormGroup>
                    </Col>
                 
                  </Row>
                  </CardBody>
            </Accordion.Collapse>
          </Card>
          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="2">
                <i className="icofont icofont-plus"></i>{"Internet"}
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="2">
                  <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Visible sur le site ?</Label>
                        <select className="form-control" onChange={(e) => setVisible_site(e.target.value)} >
                          <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Rendez-vous Web activé ?</Label>
                        <select className="form-control" onChange={(e) => setRendez_vous_web(e.target.value)} >
                        <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                   
                 
                  
                  </Row> 
                  <Row>
                  
                    <Col>
                      <FormGroup>
                        <Label className="form-label">URL Page Web du magasin :</Label>
                        <Input  className="form-control" value={url_site_web} onChange={(e) => setUrl_site_web(e.target.value)} type="text" placeholder="URL Page Web du magasin :" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">URL page Facebook </Label>
                        <Input  className="form-control" value={url_facebook} onChange={(e) => setUrl_facebook(e.target.value)} type="text" placeholder="URL page Facebook" />
                      </FormGroup>
                    </Col>
                  
                  </Row> 
                  <Row>
                   
                    <Col>
                      <FormGroup>
                        <Label className="form-label">URL Visite Virtuelle Google</Label>
                        <Input  className="form-control" value={url_visit_vertuelle_google} onChange={(e) => setUrl_visit_vertuelle_google(e.target.value)} type="text" placeholder="URL Visite Virtuelle Google" />
                    
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Code google</Label>
                        <Input  className="form-control" value={code_google} onChange={(e) => setCode_google(e.target.value)} type="text" placeholder="URL Page Web du magasin " />
                      </FormGroup>
                    </Col>
                 
                  
                  </Row> 
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Lien Google </Label>
                        <Input  className="form-control" value={lien_google} onChange={(e) => setLien_google(e.target.value)} type="text" placeholder="Lien Google" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Description SEO</Label>
                        <Input  className="form-control" value={description_seo} onChange={(e) => setDescription_seo(e.target.value)} type="text" placeholder="Description SEO" />
                    
                      </FormGroup>
                    </Col>
                  
                
                  </Row> 
                  </CardBody>
            </Accordion.Collapse>
          </Card>
          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="3">
                <i className="icofont icofont-plus"></i>{"Immobilier"}<span className="digits"></span>
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="3">
                  <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Statut local </Label>
                        <select className="form-control" onChange={(e) => setStatus_local(e.target.value)} >
                          <option value={"Locataire"}>Locataire</option>
                          <option value={"Proprietaire"}>Proprietaire</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Durée du bail</Label>
                        <Input  className="form-control" value={dure_bail} onChange={(e) => setDure_bail(e.target.value)} type="number" placeholder="Durée du bail" />
                      </FormGroup>
                    </Col>

                  </Row> 
                  <Row>
                  
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Expiration du bail</Label>
                        <Input  className="form-control" value={expiration_bail} onChange={(e) => setExpiration_bail(e.target.value)} type="date" placeholder="Expiration du bail" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Date derniers travaux </Label>
                        <Input  className="form-control" value={date_dernier_travaux} onChange={(e) => setDate_dernier_travaux(e.target.value)} type="date" placeholder="Date derniers travaux" />
                      </FormGroup>
                    </Col>
                  
                  </Row> 
                  <Row>
                   
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Date prochains travaux</Label>
                        <Input  className="form-control" value={date_prochaine_travaux} onChange={(e) => setDate_prochaine_travaux(e.target.value)} type="date" placeholder="Date prochains travaux" />
                    
                      </FormGroup>
                    </Col>

                  </Row> 
            
                  </CardBody>
            </Accordion.Collapse>
          </Card>
          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="4">
                <i className="icofont icofont-plus"></i>{"Matériels"}<span className="digits"></span>
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="4">
                  <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Plancha</Label>
                        <select className="form-control" onChange={(e) => setPlancha(e.target.value)} >
                          <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Plaquette induction</Label>
                        <select className="form-control" onChange={(e) => setPlaquette_induction(e.target.value)} >
                        <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                   
                 
                  
                  </Row> 
                  <Row>
                  
                  <Col>
                      <FormGroup>
                        <Label className="form-label">Chambre froide </Label>
                        <select className="form-control" onChange={(e) => setChambre_froid(e.target.value)} >
                          <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Machine à café</Label>
                        <select className="form-control" onChange={(e) => setMachine_cafe(e.target.value)} >
                          <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row> 
                  <Row>
                   
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Marque machine à café </Label>
                        <Input  className="form-control" value={marque_machine_cafe} onChange={(e) => setMarque_machine_cafe(e.target.value)} type="text" placeholder="Marque machine à café " />
                    
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Photocopieurs</Label>
                        <Input  className="form-control" value={photocopieur} onChange={(e) => setPhotocopieur(e.target.value)} type="text" placeholder="Photocopieurs" />
                      </FormGroup>
                    </Col>
                 
                  
                  </Row> 
             
                  </CardBody>
            </Accordion.Collapse>
          </Card>

          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="5">
                <i className="icofont icofont-plus"></i>{"Légal – financier - comptabilité"}
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="5">
                  <CardBody>
                  <Row>
                  
                  <Col>
                    <FormGroup>
                      <Label className="form-label">Code client</Label>
                      <Input  className="form-control" value={code_client} onChange={(e) => setCode_client(e.target.value)} type="text" placeholder="Code client" />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="form-label">Royalties</Label>
                      <Input  className="form-control" value={royalties} onChange={(e) => setRoyalties(e.target.value)} type="text" placeholder="Royalties" />
                    </FormGroup>
                  </Col>
                
                </Row> 
                <Row>
                  
                  <Col>
                    <FormGroup>
                      <Label className="form-label">Royalties communication</Label>
                      <Input  className="form-control" value={royalties_communication} onChange={(e) => setRoyalties_communication(e.target.value)} type="text" placeholder="Royalties communication" />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="form-label">Durée de contrat</Label>
                      <Input  className="form-control" value={royalties} onChange={(e) => setDurée_contrat(e.target.value)} type="text" placeholder="Durée de contrat" />
                    </FormGroup>
                  </Col>
                
                </Row> 
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Contrat multi-sites</Label>
                        <select className="form-control" onChange={(e) => setContrat_multi_sites(e.target.value)} >
                          <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Multi enseignes</Label>
                        <select className="form-control" onChange={(e) => setMulti_enseigne(e.target.value)} >
                        <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                   
                 
                  
                  </Row> 
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Retard paiement royalties</Label>
                        <select className="form-control" onChange={(e) => setRetard_paiement_royalties(e.target.value)} >
                          <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Nom cabinet comptable</Label>
                        <Input  className="form-control" value={nom_cabinet_comptable} onChange={(e) => setNom_cabinet_comptable(e.target.value)} type="text" placeholder="Nom cabinet comptable" />
                    
                      </FormGroup>
                    </Col>
                   
                 
                  
                  </Row> 
                  <Row>
                  
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Adresse cabinet comptable</Label>
                        <Input  className="form-control" value={adresse_cabinet_comptable} onChange={(e) => setAdresse_cabinet_comptable(e.target.value)} type="text" placeholder="Adresse cabinet comptable" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Téléphone expert comptable</Label>
                        <Input  className="form-control" value={tel_expert_comptable} onChange={(e) => setTel_expert_comptable(e.target.value)} type="text" placeholder="Téléphone expert comptable" />
                      </FormGroup>
                    </Col>
                  
                  </Row> 
                  <Row>
                   
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Email expert comptable</Label>
                        <Input  className="form-control" value={email_expert_comptable} onChange={(e) => setEmail_expert_comptable(e.target.value)} type="text" placeholder="Email expert comptable" />
                    
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Date de clôture comptable</Label>
                        <Input  className="form-control" value={date_cloture_comptable} onChange={(e) => setDate_cloture_comptable(e.target.value)} type="date" placeholder="Date de clôture comptable" />
                      </FormGroup>
                    </Col>
                 
                  
                  </Row> 
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Liasses fiscales  </Label>
                       <select  className="form-control" onChange={e => setLiasses_fiscales(e.target.value)} >
                         <option value={1}>Oui</option>
                         <option value={0}>Non</option>
                       </select>
                       </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Banque</Label>
                        <Input  className="form-control" value={banque} onChange={(e) => setBanque(e.target.value)} type="text" placeholder="Banque" />
                    
                      </FormGroup>
                    </Col>
                  
                
                  </Row> 
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Adresse banque  </Label>
                        <Input  className="form-control" value={adresse_banque} onChange={(e) => setAdresse_banque(e.target.value)} type="text" placeholder="Adresse banque" />
                    
                       </FormGroup>
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Commentaires facturation</Label>
                        <Input  className="form-control" value={commentaire_facturation} onChange={(e) => setCommentaire_facturation(e.target.value)} type="textarea" placeholder="Commentaires facturation" />
                    
                       </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Autres commentaires</Label>
                        <Input  className="form-control" value={autre_commentaire} onChange={(e) => setAutre_commentaire(e.target.value)} type="textarea" placeholder="Autres Commentaires" />
                    
                      </FormGroup>
                    </Col>
                  
                
                  </Row>
                  </CardBody>
            </Accordion.Collapse>
          </Card>
          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="6">
                <i className="icofont icofont-plus"></i>{"Fournisseurs et achats"}
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="6">
                  <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Identifiant Pomona</Label>
                        <Input  className="form-control" value={identifiant_ponoma} onChange={(e) => setIdentifiant_ponoma(e.target.value)} type="number" placeholder="Identifiant Pomona" />
                     
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Région logistique Sec</Label>
                        <select className="form-control" onChange={(e) => setRegion_logistique_sec(e.target.value)} >
                        <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                   
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Région Logistique Froid </Label>
                        <select className="form-control" onChange={(e) => setRegion_logistique_sec(e.target.value)} >
                        <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                  
                  </Row> 

                  </CardBody>
            </Accordion.Collapse>
          </Card>
          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="7">
                <i className="icofont icofont-plus"></i>{"Hygiène"}
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="7">
                  <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Laboratoire</Label>
                        <select className="form-control" onChange={(e) => setLaboratoire(e.target.value)} >
                        <option value={1}>Merieux</option>
                          <option value={0}>EUROFINS</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Dernier contrôle sanitaire</Label>
                        <Input  className="form-control" value={dernier_controle_sanitaire} onChange={(e) => setDernier_controle_sanitaire(e.target.value)} type="date" placeholder="Dernier contrôle sanitaire" />
                        
                      </FormGroup>
                    </Col>
                   
                   
                  
                  </Row> 

                  </CardBody>
            </Accordion.Collapse>
          </Card>

               <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="8">
                <i className="icofont icofont-plus"></i>{"Logiciels et IT"}
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="8">
                  <CardBody>
                  <Row>
                  <Col>
                      <FormGroup>
                        <Label className="form-label">Logiciel de caisse </Label>
                        <Input  className="form-control" value={logiciel_caisse} onChange={(e) => setLogiciel_caisse(e.target.value)} type="text" placeholder="Logiciel de caisse" />
                        
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Provider internet</Label>
                        <select className="form-control" onChange={(e) => setProvider_internet(e.target.value)} >
                        <option value={"orange"}>Orange</option>
                          <option value={"SFR"}>SFR</option>
                          <option value={"Free"}>Free</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Identifiant fournisseur logiciel </Label>
                        <Input  className="form-control" value={identifiant_fournisseur_logiciel} onChange={(e) => setIdentifiant_fournisseur_logiciel(e.target.value)} type="text" placeholder="Identifiant fournisseur logiciel " />
                        
                      </FormGroup>
                    </Col>
                   
                   
                  
                  </Row> 

                  </CardBody>
            </Accordion.Collapse>
          </Card>


          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="9">
                <i className="icofont icofont-plus"></i>{"Marketing – avis - notes"}<span className="digits"></span>
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="9">
                  <CardBody>
                  <Row>
                  <Col>
                      <FormGroup>
                        <Label className="form-label">Dernière participation opération nationale </Label>
                        <Input  className="form-control" value={last_part_national} onChange={(e) => setLast_part_national(e.target.value)} type="date" placeholder="Dernière participation opération nationale " />
                        
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Compte facebook</Label>
                        <Input  className="form-control" value={compte_facebook} onChange={(e) => setCompte_facebook(e.target.value)} type="text" placeholder="Compte facebook" />
                        
                      </FormGroup>
                    </Col>
                   
                  
                  
                  </Row> 
                  <Row>
                  <Col>
                      <FormGroup>
                        <Label className="form-label">Nbre recommendations facebook</Label>
                        <Input  className="form-control" value={nbre_recommendation_facebook} onChange={(e) => setNbre_recommendation_facebook(e.target.value)} type="number" placeholder="Nbre recommendations facebook" />
                        
                      </FormGroup>
                    </Col>
                  
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Nb avis Google</Label>
                        <Input  className="form-control" value={nbre_avis_google} onChange={(e) => setNbre_avis_google(e.target.value)} type="number" placeholder="Nbre avis Google" />
                        
                      </FormGroup>
                    </Col>
                  </Row> 
                  <Row>
                
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Note pages jaunes</Label>
                        <Input  className="form-control" value={nbre_page_jaune} onChange={(e) => setNbre_page_jaune(e.target.value)} type="text" placeholder="Note pages jaunes" />
                        
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Nbr avis pages jaunes</Label>
                        <Input  className="form-control" value={nbre_avis_pages_jaune} onChange={(e) => setNbre_avis_pages_jaune(e.target.value)} type="number" placeholder="Nbre avis pages jaunes" />
                        
                      </FormGroup>
                    </Col>
                  
                  </Row> 
                
                  </CardBody>
            </Accordion.Collapse>
          </Card>

          <Card>
            <CardHeader className="bg-primary">
              <h5 className="mb-0">
                <Accordion.Toggle as={Card.Header} className="btn btn-link txt-white " color="primary" eventKey="10">
                <i className="icofont icofont-plus"></i>{"Récompenses"}<span className="digits"></span>
                  </Accordion.Toggle>
              </h5>
            </CardHeader>
            <Accordion.Collapse eventKey="10">
                  <CardBody>
                  <Row>
                  <Col>
                      <FormGroup>
                        <Label className="form-label">Déjà récompensé</Label>
                        <select className="form-control" onChange={(e) => setEtat_recompense(e.target.value)} >
                        <option value={1}>Oui</option>
                          <option value={0}>Non</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Personne récompensée</Label>
                        <Input  className="form-control" value={personne_recompense} onChange={(e) => setPersonne_recompense(e.target.value)} type="text" placeholder="Personne récompensée" />
                        
                      </FormGroup>
                    </Col>
                   
                  
                  
                  </Row> 
                  <Row>
                  <Col>
                      <FormGroup>
                        <Label className="form-label">Meilleure progression </Label>
                        <Input  className="form-control" value={best_recompense} onChange={(e) => setBest_recompense(e.target.value)} type="text" placeholder="Meilleure progression " />
                        
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="form-label">Meilleurs résultats</Label>
                        <Input  className="form-control" value={best_result} onChange={(e) => setBest_result(e.target.value)} type="text" placeholder="Meilleurs résultats" />
                        
                      </FormGroup>
                    </Col>
                   
                  </Row> 
            
                
                  </CardBody>
            </Accordion.Collapse>
          </Card>
          </div>
                   </Accordion>           
                   <Row>
                        <Col>
                          <div className="mb-0 text-right">
                             
                             
                              <Button onClick={() => setActiveTab4("2")} color="secondary">{"Précedent"}</Button>
                         
                              <Button color="primary" onClick={() => setActiveTab4("4")} >{"Suivant"}</Button>
                          </div>
                        </Col>
                      </Row>
                       </TabPane>
                                    <TabPane tabId="4">
                                    <Row>
                       
                       <Col sm="6">
                           <FormGroup>
                             <Label>{"Manager franchise"}(*)</Label>
                             <a color="success" onClick={Scrollmodaltoggle}  name="status"  className="form-control digits" >
                             {"Manager franchise"}
                             </a>
        <Modal isOpen={scrollingmodal} toggle={Scrollmodaltoggle}>
                      
                       <ModalBody>
                       <div className="table-responsive product-table">
                                 <DataTable
                                 conditionalRowStyles={conditionalRowStyles}
                                 onRowClicked={(e) => {
 setSelectedUser(e)
 //Scrollmodaltoggle()
                                 } }
                                     noHeader
                                     columns={productColumns}
                                     data={users}
                                 />
                             </div>
                                               
                       </ModalBody>
                       <ModalFooter>
                     
                         <Button color="secondary" onClick={Scrollmodaltoggle}>{Close}</Button>
                         <Button color="primary"   onClick={Scrollmodaltoggle}>{SaveChanges}</Button>

                       </ModalFooter>
                 </Modal>
                 <Label>Responsable choisis : {(selectedUser.last_name ?? "") + " " +(selectedUser.first_name ?? "")}</Label>
                           </FormGroup>
                         </Col>
                         <Col sm="6">
                           <FormGroup>
                             <Label>{ProjectStatus}(*)</Label>
                             <Input type="select" name="status" onChange={(e)=> setFranchise_state(e.target.value)} placeholder="Select Status" className="form-control digits" innerRef={register({ required: true })}>
                             <option value="true">{"choisir status"}</option>
                              <option selected= {franchise_state == "1"} value="1">{Opened}</option>
                               <option selected={franchise_state == "0"} value="0">{Closed}</option>
                             </Input>
                           </FormGroup>
                         </Col>
                       </Row>
                       <Row>
                        <Col>
                          <FormGroup className="mb-0 text-right">
                             
                              <Link className="mr-1" onClick={() => setActiveTab4("3")}>
                              <Button color="secondary">{FranchiseAnnuler}</Button>
                              </Link>
                              <Button color="primary" >{"Modifier Franchise"}</Button>
                          </FormGroup>
                        </Col>
                      </Row>
                                    </TabPane>
                                 
                                </TabContent>
                                
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
       
            </Row>
         
                
          </Container>
        </Fragment>
    );
}

export default withRouter(SubFranchiseEdit);