import {
    GET_EMAILS,
    GET_ALL_EMAILS,
    GET_EMAIL_TYPES,
    GET_ALL_SEND_EMAILS,
    GET_ALL_TRASH_EMAILS,
    GET_ALL_EMAIL_ASYN,
    REMOVE_EMAIL,
    ADD_USER_EMAIL
} from '../actionTypes';

const initial_state = {
    allEmails: null,
    listEmails: [],
    allSendEmails: null,
    allTrashEmails: null,
    types: [],
    loading: false,
    numbersMessages : 0,
    numbersAllMsg : 0
};

export default (state = initial_state, action) => {
    
    switch (action.type) {
    
        case GET_EMAILS:
            return { ...state };
            
            case REMOVE_EMAIL:
                let listeemail = state.listEmails.filter(res => res.mail_id != action.payload)
                return { ...state, listEmails : listeemail };
        case GET_ALL_EMAILS:
          
            return { ...state, allEmails: action.payload.messages, numbersMessages : action.payload.numbersmessage,numbersAllMsg : action.payload.numbersallmessage };

        case GET_ALL_SEND_EMAILS:
            console.log(action.payload.messagessend)
            return { ...state, allSendEmails: action.payload.messagessend };

        case GET_ALL_TRASH_EMAILS:
        
            return { ...state, allTrashEmails: action.payload.messagestrash };
            case ADD_USER_EMAIL:
                state.listEmails.push(action.payload)
                return { ...state, listEmails: state.listEmails };
        case GET_ALL_EMAIL_ASYN:
                return { ...state, listEmails: action.email || [] };
        case GET_EMAIL_TYPES:
            const getTypes = action.payload;
            return { ...state, loading: true, types: getTypes };

        default: return { ...state };
    }
}
