import {GET_EVENTS_LIST,
        ADD_TO_MY_EVENTS,
        ADD_NEW_EVENTS,
        REMOVE_EVENTS,
        REMOVE_FROM_MY_EVENTS,
        UPDATE_EVENTS,
        UPDATE_MY_EVENTS,
        UPDATE_STATE_EVENTS,
        ADD_CATEGORY,
        GET_CATEGORY,
        ADD_SOUS_CATEGORY,
        REMOVE_SOUS_CATEGORY,
        UPADTE_CATEGORY,
        REMOVE_CATEGORY,
        ADD_GROUP_TRAVAIL,
        GET_GROUP_TRAVAIL,
        DELETE_GROUP_TRAVAIL,
        UPDATE_GROUPE_TRAVAIL,

  } from '../actionTypes'


const initial_state = {
    grouptravail : [],
    loading: false,
}

export default (state = initial_state, action) => {

    switch (action.type) {

        case GET_GROUP_TRAVAIL:
            return {...state,grouptravail: action.groupes, loading: true,};
            
        case DELETE_GROUP_TRAVAIL:
                let event_my_event = state.grouptravail.filter(item=> action.payload.id !== item.id)
                return{...state,grouptravail: event_my_event}
                    
        case ADD_GROUP_TRAVAIL:
            return {...state,grouptravail:[...state.grouptravail, { ...action.payload.myeventdata}]};
        
        case UPDATE_GROUPE_TRAVAIL:
            state.grouptravail.push(action.payload.eventdata)
        return {...state,grouptravail: state.grouptravail};
        

       
        
       
                
        default: return { ...state };
    }
    
}