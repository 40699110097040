/* TODO APP*/
export const WATCH_TODO_LIST = "WATCH_TODO_LIST";
export const GET_TODO_LIST = "GET_TODO_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECTED_ITEM = "SELECTED_ITEM";

/* ECOMMERRCE APP */
export const GET_LIST = "GET_LIST";
export const REMOVE_EMAIL = "REMOVE_EMAIL";
export const ADD_USER_EMAIL = "ADD_USER_EMAIL";
export const REMOVE_CONV = "REMOVE_CONV";

export const WATCH_ALL_EMAIL_ASYN = "WATCH_ALL_EMAIL_ASYN";
export const WATCH_PRODUCT_LIST = "WATCH_PRODUCT_LIST";
export const WATCH_SINGLE_ITEM = "WATCH_SINGLE_ITEM"
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";
export const DECREMENT_QTY = "DECREMENT_QTY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";
export const ADD_PROVIDER_CATEGORY = "ADD_PROVIDER_CATEGORY";
export const WATCH_PROVIDER_CATEGORY = "WATCH_PROVIDER_CATEGORY";
export const GET_PROVIDER_CATEGORY = "GET_PROVIDER_CATEGORY";
export const DELETE_PROVIDER_CATEGORY = "DELETE_PROVIDER_CATEGORY";
export const UPDATE_PROVIDER_CATEGORY = "UPDATE_PROVIDER_CATEGORY";
export const WATCH_SUB_CATEGORY = "WATCH_SUB_CATEGORY";
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";
export const REMOVE_SUB_CATEGORY = "REMOVE_SUB_CATEGORY";
export const WATCH_CATEGORY_BY_PROVIDER = "WATCH_CATEGORY_BY_PROVIDER";
export const WATCH_SUB_CATEGORY_BY_PROVIDER = "WATCH_SUB_CATEGORY_BY_PROVIDER";

export const REMOVE_FILE_PRODUCT = "REMOVE_FILE_PRODUCT";
export const REMOVE_SIZE = "REMOVE_SIZE";
export const ADD_COMMENT_EVENT = "ADD_COMMENT_EVENT";

/* Bookmark App */
export const WATCH_BOOKMARK_LIST = "WATCH_BOOKMARK_LIST"
export const GET_BOOKMARK_LIST = "GET_BOOKMARK_LIST"
export const ADD_TO_MY_BOOKMARK = "ADD_TO_MY_BOOKMARK"
export const ADD_NEW_BOOKMARK = "ADD_NEW_BOOKMARK"
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK"
export const REMOVE_FROM_MY_BOOKMARK = "REMOVE_FROM_MY_BOOKMARK"
export const UPDATE_BOOKMARK = "UPDATE_BOOKMARK"
export const UPDATE_MY_BOOKMARK = "UPDATE_MY_BOOKMARK"

/* User App */
export const WATCH_USER_LIST = "WATCH_USER_LIST"
export const GET_USER_LIST = "GET_USER_LIST"
export const ADD_TO_MY_USER = "ADD_TO_MY_USER"
export const ADD_NEW_USER = "ADD_NEW_USER"
export const REMOVE_USER = "REMOVE_USER"
export const REMOVE_FROM_MY_USER = "REMOVE_FROM_MY_USER"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_MY_USER = "UPDATE_MY_USER"
export const UPDATE_STATE_USER = "UPDATE_STATE_USER"
/* Actualite App */
export const WATCH_NEWS_LIST = "WATCH_NEWS_LIST"
export const GET_NEWS_LIST = "GET_NEWS_LIST"
export const ADD_TO_MY_NEWS = "ADD_TO_MY_NEWS"
export const ADD_NEW_NEWS = "ADD_NEW_NEWS"
export const REMOVE_NEWS = "REMOVE_USER"
export const REMOVE_FROM_MY_NEWS = "REMOVE_FROM_MY_NEWS"
export const UPDATE_NEWS = "UPDATE_NEWS"
export const UPDATE_MY_NEWS = "UPDATE_MY_NEWS"
export const UPDATE_STATE_NEWS = "UPDATE_STATE_NEWS"

/* Events App */
export const WATCH_EVENTS_LIST = "WATCH_EVENTS_LIST"
export const GET_EVENTS_LIST = "GET_EVENTS_LIST"
export const ADD_TO_MY_EVENTS = "ADD_TO_MY_EVENTS"
export const ADD_NEW_EVENTS = "ADD_NEW_EVENTS"
export const REMOVE_EVENTS = "REMOVE_EVENTS"
export const REMOVE_FROM_MY_EVENTS = "REMOVE_FROM_MY_EVENTS"
export const UPDATE_EVENTS = "UPDATE_EVENTS"
export const UPDATE_MY_EVENTS = "UPDATE_MY_EVENTS"
export const UPDATE_STATE_EVENTS = "UPDATE_STATE_EVENTS"
export const REMOVE_SUB_FOLDER = "REMOVE_SUB_FOLDER"
export const REMOVE_SHARE_FILE = "REMOVE_SHARE_FILE"
export const REMOVE_SHARE_FOLDER = "REMOVE_SHARE_FOLDER"
export const ADD_NEW_SUB_FOLDER = "ADD_NEW_SUB_FOLDER"

export const WATCH_FAVORIS_FOLDER_LIST = "WATCH_FAVORIS_FOLDER_LIST"
export const WATCH_FAVORIS_FILES_LIST = "WATCH_FAVORIS_FILES_LIST"

export const WATCH_DELETED_FILES_LIST = "WATCH_DELETED_FILES_LIST"
export const WATCH_DELETED_FOLDERS_LIST = "WATCH_DELETED_FOLDERS_LIST"

export const WATCH_SHARE_FOLDER_LIST = "WATCH_SHARE_FOLDER_LIST"
export const WATCH_SHARE_FILE_LIST = "WATCH_SHARE_FILE_LIST"

export const REMOVE_MY_SHARE_FILE = "REMOVE_MY_SHARE_FILE"
export const REMOVE_MY_SHARE_FOLDER = "REMOVE_MY_SHARE_FOLDER"


export const REMOVE_FINALY_FOLDER = "REMOVE_FINALY_FOLDER"
export const RECUPERE_FOLDER = "RECUPERE_FOLDER"


export const REMOVE_FINALY_FILE = "REMOVE_FINALY_FILE"
export const RECUPERE_FILE = "RECUPERE_FILE"


export const ADD_GROUP_TRAVAIL = "ADD_GROUP_TRAVAIL"
export const GET_GROUP_TRAVAIL = "GET_GROUP_TRAVAIL"
export const DELETE_GROUP_TRAVAIL = "DELETE_GROUP_TRAVAIL"
export const UPDATE_GROUPE_TRAVAIL = "UPDATE_GROUPE_TRAVAIL"
export const WATCH_GROUP_TRAVAIL = "WATCH_GROUP_TRAVAIL"


/* Orders App */
export const WATCH_ORDERS_LIST = "WATCH_ORDERS_LIST"
export const GET_ORDERS_LIST = "GET_ORDERS_LIST"
export const ADD_TO_MY_ORDERS = "ADD_TO_MY_ORDERS"
export const ADD_NEW_ORDERS = "ADD_NEW_ORDERS"
export const REMOVE_ORDERS = "REMOVE_ORDERS"
export const REMOVE_FROM_MY_ORDERS = "REMOVE_FROM_MY_ORDERS"
export const UPDATE_ORDERS = "UPDATE_ORDERS"
export const UPDATE_MY_ORDERS = "UPDATE_MY_ORDERS"
export const UPDATE_STATE_ORDERS = "UPDATE_STATE_ORDERS"
export const WATCH_ORDERS_PROVIDER_LIST = "WATCH_ORDERS_PROVIDER_LIST"
export const WATCH_ORDERS_FRANCHISE_LIST = "WATCH_ORDERS_FRANCHISE_LIST"
export const GET_ORDERS_FRANCHISE_LIST = "GET_ORDERS_FRANCHISE_LIST"

/* Orders App */
export const WATCH_NOTIFICATIONS_LIST = "WATCH_NOTIFICATIONS_LIST"
export const GET_NOTIFICATIONS_LIST = "GET_NOTIFICATIONS_LIST"
export const ADD_TO_MY_NOTIFICATIONS = "ADD_TO_MY_NOTIFICATIONS"
export const ADD_NEW_NOTIFICATIONS = "ADD_NEW_NOTIFICATIONS"
export const REMOVE_NOTIFICATIONS = "REMOVE_NOTIFICATIONS"
export const REMOVE_FROM_MY_NOTIFICATIONS = "REMOVE_FROM_MY_NOTIFICATIONS"
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"
export const UPDATE_MY_NOTIFICATIONS = "UPDATE_MY_NOTIFICATIONS"
export const UPDATE_STATE_NOTIFICATIONS = "UPDATE_STATE_NOTIFICATIONS"
export const WATCH_NOTIFICATIONS_PROVIDER_LIST = "WATCH_NOTIFICATIONS_PROVIDER_LIST"
export const WATCH_NOTIFICATIONS_FRANCHISE_LIST = "WATCH_NOTIFICATIONS_FRANCHISE_LIST"
export const GET_NOTIFICATIONS_FRANCHISE_LIST = "GET_NOTIFICATIONS_FRANCHISE_LIST"



 //fournisseur
export const WATCH_PROVIDER_LIST = "WATCH_PROVIDER_LIST"
export const GET_PROVIDER_LIST = "GET_PROVIDER_LIST"
export const ADD_TO_MY_PROVIDER = "ADD_TO_MY_PROVIDER"
export const ADD_NEW_PROVIDER = "ADD_NEW_PROVIDER"
export const REMOVE_PROVIDER = "REMOVE_PROVIDERR"
export const REMOVE_FROM_MY_PROVIDER = "REMOVE_FROM_MY_PROVIDER"
export const UPDATE_PROVIDER = "UPDATE_PROVIDER"
export const UPDATE_MY_PROVIDER = "UPDATE_MY_PROVIDER"
export const UPDATE_STATE_PROVIDER = "UPDATE_STATE_PROVIDER"

 //franchise
 export const WATCH_FRANCHISE_LIST = "WATCH_FRANCHISE_LIST"
 export const GET_FRANCHISE_LIST = "GET_FRANCHISE_LIST"
 export const ADD_TO_MY_FRANCHISE = "ADD_TO_MY_FRANCHISE"
 export const ADD_NEW_FRANCHISE = "ADD_NEW_FRANCHISE"
 export const REMOVE_FRANCHISE = "REMOVE_FRANCHISE"
 export const REMOVE_FROM_MY_FRANCHISE = "REMOVE_FROM_MY_FRANCHISE"
 export const UPDATE_FRANCHISE = "UPDATE_FRANCHISE"
 export const UPDATE_MY_FRANCHISE = "UPDATE_MY_FRANCHISE"
 export const UPDATE_STATE_FRANCHISE = "UPDATE_STATE_FRANCHISE"
 export const GET_FRANCHISE_BY_ID = "GET_FRANCHISE_BY_ID"


 export const ADD_EVENT_CATEGORY = "ADD_EVENT_CATEGORY"
 export const GET_EVENT_CATEGORY = "GET_EVENT_CATEGORY"
 export const WATCH_EVENT_CATEGORY = "WATCH_EVENT_CATEGORY"
 export const REMOVE_EVENT_CATEGORY = "REMOVE_EVENT_CATEGORY"
 export const UPDATE_EVENT_CATEGORY = "UPDATE_EVENT_CATEGORY"
 

 //inventairedipense
 export const WATCH_INVENTAIREDIPENSE_LIST = "WATCH_INVENTAIREDIPENSE_LIST"
 export const GET_INVENTAIREDIPENSE_LIST = "GET_INVENTAIREDIPENSE_LIST"
 export const ADD_TO_MY_INVENTAIREDIPENSE = "ADD_TO_MY_INVENTAIREDIPENSE"
 export const ADD_NEW_INVENTAIREDIPENSE = "ADD_NEW_INVENTAIREDIPENSE"
 export const REMOVE_INVENTAIREDIPENSE = "REMOVE_INVENTAIREDIPENSE"
 export const REMOVE_FROM_MY_INVENTAIREDIPENSE = "REMOVE_FROM_MY_INVENTAIREDIPENSE"
 export const UPDATE_INVENTAIREDIPENSE = "UPDATE_INVENTAIREDIPENSE"
 export const UPDATE_MY_INVENTAIREDIPENSE = "UPDATE_MY_INVENTAIREDIPENSE"
 export const UPDATE_STATE_INVENTAIREDIPENSE = "UPDATE_STATE_INVENTAIREDIPENSE"
 export const GET_INVENTAIREDIPENSE_BY_ID = "GET_INVENTAIREDIPENSE_BY_ID"
 
  //subfranchise
  export const WATCH_SUBFRANCHISE_LIST = "WATCH_SUBFRANCHISE_LIST"
  export const GET_SUBFRANCHISE_LIST = "GET_SUBFRANCHISE_LIST"
  export const ADD_TO_MY_SUBFRANCHISE = "ADD_TO_MY_SUBFRANCHISE"
  export const ADD_NEW_SUBFRANCHISE = "ADD_NEW_SUBFRANCHISE"
  export const REMOVE_SUBFRANCHISE = "REMOVE_SUBFRANCHISE"
  export const REMOVE_FROM_MY_SUBFRANCHISE = "REMOVE_FROM_MY_SUBFRANCHISE"
  export const UPDATE_SUBFRANCHISE = "UPDATE_SUBFRANCHISE"
  export const UPDATE_MY_SUBFRANCHISE = "UPDATE_MY_SUBFRANCHISE"
  export const UPDATE_STATE_SUBFRANCHISE = "UPDATE_STATE_SUBFRANCHISE"
  export const GET_SUBFRANCHISE_BY_ID = "GET_SUBFRANCHISE_BY_ID"
  export const GET_USER_EMPTY_LIST = "GET_USER_EMPTY_LIST"
  export const WATCH_USER_EMPTY_LIST = "WATCH_USER_EMPTY_LIST"
  
  
/* Bookmark App */
export const WATCH_FOLDER_LIST = "WATCH_FOLDER_LIST"
export const WATCH_SUB_FOLDER_LIST = "WATCH_SUB_FOLDER_LIST"
export const GET_FOLDER_LIST = "GET_FOLDER_LIST"
export const GET_FOLDER_LIST_BY_ID = "GET_FOLDER_LIST_BY_ID"
export const ADD_TO_MY_FOLDER = "ADD_TO_MY_FOLDER"
export const ADD_NEW_FOLDER = "ADD_NEW_FOLDER"
export const REMOVE_FOLDER = "REMOVE_FOLDER"
export const REMOVE_FROM_MY_FOLDER = "REMOVE_FROM_MY_FOLDER"
export const UPDATE_FOLDER = "UPDATE_FOLDER"
export const UPDATE_MY_FOLDER = "UPDATE_MY_FOLDER"
export const UPDATE_FOLDER_TREE = "UPDATE_FOLDER_TREE"
export const SHARE_FOLDER = "SHARE_FOLDER"

/* Bookmark App */
export const WATCH_FILE_LIST = "WATCH_FILE_LIST"
export const WATCH_SUB_FILE_LIST = "WATCH_SUB_FILE_LIST"
export const GET_FILE_LIST = "GET_FILE_LIST"
export const GET_FILE_LIST_BY_ID = "GET_FILE_LIST_BY_ID"
export const ADD_TO_MY_FILE = "ADD_TO_MY_FILE"
export const ADD_NEW_FILE = "ADD_NEW_FILE"
export const REMOVE_FILE = "REMOVE_FILE"
export const REMOVE_FROM_MY_FILE = "REMOVE_FROM_MY_FILE"
export const UPDATE_FILE = "UPDATE_FILE"
export const UPDATE_MY_FILE = "UPDATE_MY_FILE"
export const SHARE_FILE = "SHARE_FILE"
export const UPDATE_FILE_TREE = "UPDATE_FILE_TREE"
export const WATCH_FILES_LIST = "WATCH_FILES_LIST"

// Project 
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"
export const ADD_NEW_DEV_PROJECT = "ADD_NEW_DEV_PROJECT"
export const ADD_DEV_NEW_TACHE = "ADD_DEV_NEW_TACHE"


/* Task App */

export const WATCH_TASK_LIST = "WATCH_TASK_LIST"
export const FETCH_ALL_TASK = "FETCH_ALL_TASK"
export const NEW_TASK = "NEW_TASK"
export const REMOVE_TASK = "REMOVE_TASK"

/* Cart */
export const ADD_TO_CART = 'ADD_TO_CART';

/* Wishlist */
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';

/* Filters */
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_COLOR = 'FILTER_COLOR';
export const FILTER_PRICE = 'FILTER_PRICE';
export const SEARCH_BY = 'SEARCH_BY';
export const SORT_BY = 'SORT_BY';

/* CHAT APP */
export const GET_MEMBERS = "GET_MEMBERS";
export const WATCH_CHAT_MEMBERS = "WATCH_CHAT_MEMBERS";
export const WATCH_CHAT_SUCCESS = "WATCH_CHAT_SUCCESS";
export const WATCH_ALL_CHAT_SUCCESS = "WATCH_ALL_CHAT_SUCCESS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export const CHANGE_CHAT = "CHANGE_CHAT";
export const CREATE_CHAT = "CREATE_CHAT";
export const CREATE_CHAT_WATCHER = "CREATE_CHAT_WATCHER";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_WATCHER = "SEND_MESSAGE_WATCHER";
export const SEND_MESSAGE_CONV_WATCHER = "SEND_MESSAGE_CONV_WATCHER";
export const GET_ALL_CONV_MESSAGES = "GET_ALL_CONV_MESSAGES";
export const SEND_CONV_MESSAGE = "SEND_CONV_MESSAGE";
export const WATCH_ALL_CONV_MESSAGES = "WATCH_ALL_CONV_MESSAGES";
export const UPDATE_MESSAGE_CONV_WATCHER = "UPDATE_MESSAGE_CONV_WATCHER";
export const UPDATE_CONV_MESSAGE = "UPDATE_CONV_MESSAGE";
export const REMOVE_CONV_MESSAGE = "REMOVE_CONV_MESSAGE";

export const REPLY_BY_SELECTED_USER = "REPLY_BY_SELECTED_USER";
export const REPLY_MESSAGE_WATCHER = "REPLY_MESSAGE_WATCHER"

/* Email-app */
export const WATCH_EMAIL = "WATCH_EMAIL";
export const WATCH_ALL_TYPE_EMAIL = "WATCH_ALL_TYPE_EMAIL";
export const GET_ALL_EMAIL_ASYN = "GET_ALL_EMAIL_ASYN";
export const GET_ALL_TYPE_ASYN = "GET_ALL_TYPE_ASYN";
export const GET_EMAILS = "GET_EMAILS";
export const GET_ALL_EMAILS = "GET_ALL_EMAILS";
export const GET_EMAIL_TYPES = "GET_EMAIL_TYPES";
export const UPDATE_EMAIL_TYPES = "UPDATE_EMAIL_TYPES";
export const WATCH_EMAIL_BY_ID = "WATCH_EMAIL_BY_ID";
export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";
export const ADD_AUDIT_TO_FRANCHISE = "ADD_AUDIT_TO_FRANCHISE";
export const ADD_ANNUAIRE_TO_FRANCHISE = "ADD_ANNUAIRE_TO_FRANCHISE";
export const ADD_INVENTAIRE = "ADD_INVENTAIRE";
export const ADD_DIPENSE = "ADD_DIPENSE";


/* CUSTOMIZER */
export const WATCH_CUSTOMIZER ="WATCH_CUSTOMIZER";
export const ADD_COSTOMIZER ="ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS ="ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";

/* Categorie */
export const WATCH_ALL_USER ="WATCH_ALL_USER";
export const WATCH_CATEGORY ="WATCH_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_SOUS_CATEGORY = "ADD_SOUS_CATEGORY";
export const REMOVE_SOUS_CATEGORY = "REMOVE_SOUS_CATEGORY";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const GET_LIST_PRODUCTS_BY_PROVIDER = "GET_LIST_PRODUCTS_BY_PROVIDER";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const WATCH_PRODUCT_LIST_BY_PROVIDER = "WATCH_PRODUCT_LIST_BY_PROVIDER";
export const GET_LIST_PRODUCTS_BY_PROVIDER_ID = "GET_LIST_PRODUCTS_BY_PROVIDER_ID";



export const UPDATE_NOTE_ORDERS = "UPDATE_NOTE_ORDERS";
export const WATCH_ALL_ORDERS_LIST = "WATCH_ALL_ORDERS_LIST";
export const GET_ALL_ORDERS_LIST = "GET_ALL_ORDERS_LIST";



export const WATCH_PROJET_LIST = "WATCH_PROJET_LIST";
export const GET_LIST_PROJECT = "GET_LIST_PROJECT";
export const WATCH_TACHE_LIST = "WATCH_TACHE_LIST";
export const WATCH_SOUS_TACHE_LIST = "WATCH_SOUS_TACHE_LIST";
export const GET_LIST_TACHE = "GET_LIST_TACHE";
export const GET_LIST_SOUS_TACHE = "GET_LIST_SOUS_TACHE";

export const ADD_NEW_TACHE = "ADD_NEW_TACHE";
export const ADD_NEW_SOUS_TACHE = "ADD_NEW_SOUS_TACHE";
export const REMOVE_PROJET = "REMOVE_PROJET";
export const REMOVE_TACHE = "REMOVE_TACHE";
export const REMOVE_SOUS_TACHE = "REMOVE_SOUS_TACHE";

export const UPDATE_PROJET = "UPDATE_PROJET";
export const UPADTE_TACHE = "UPADTE_TACHE";
export const UPADTE_SOUS_TACHE = "UPADTE_SOUS_TACHE";
export const ADD_COMMENT = "ADD_COMMENT";


export const ADD_SOUS_COMMENT = "ADD_SOUS_COMMENT";
export const WATCH_PROJET_LIST_BY_ID_USET = "WATCH_PROJET_LIST_BY_ID_USET";


export const ADD_NEW_SONDAGE = "ADD_NEW_SONDAGE";
export const WATCH_ADD_SONDAGE = "WATCH_ADD_SONDAGE";

export const WATCH_ALL_SUBFRANCHISE_LIST = "WATCH_ALL_SUBFRANCHISE_LIST";
export const GET_ALL_SUBFRANCHISE = "GET_ALL_SUBFRANCHISE";


export const GET_ALL_TRASH_EMAILS = "GET_ALL_TRASH_EMAILS";
export const GET_ALL_SEND_EMAILS = "GET_ALL_SEND_EMAILS";


export const WATCH_EMAIL_TRASH = "WATCH_EMAIL_TRASH";
export const WATCH_EMAIL_SEND = "WATCH_EMAIL_SEND";

export const WATCH_NEWS_BY_MARQUE_LIST = "WATCH_NEWS_BY_MARQUE_LIST";
export const WATCH_ADD_USER_LIST = "WATCH_ADD_USER_LIST";


export const WATCH_EVENTS_BY_MARQUE_LIST = "WATCH_EVENTS_BY_MARQUE_LIST";
export const ADD_FOLDER_TO_FAVORIS = "ADD_FOLDER_TO_FAVORIS";


export const FAVORIS_FILE = "FAVORIS_FILE";
export const DELETE_FOLDER_TO_FAVORIS = "DELETE_FOLDER_TO_FAVORIS";


export const DELETE_FAVORIS_FILE = "DELETE_FAVORIS_FILE";



export const WATCH_NOTE_LIST = "WATCH_NOTE_LIST";
export const GET_NOTE_LIST = "GET_NOTE_LIST";

export const ADD_NOTE = "ADD_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";

export const WATCH_MAIL_GROUP_LIST = "WATCH_MAIL_GROUP_LIST";
export const GET_MAIL_GROUP_LIST = "GET_MAIL_GROUP_LIST";

export const ADD_MAIL_GROUP = "ADD_MAIL_GROUP";
export const DELETE_MAIL_GROUP = "DELETE_MAIL_GROUP";
export const UPDATE_MAIL_GROUP = "UPDATE_MAIL_GROUP";
export const GET_FILES_LIST = "GET_FILES_LIST";

export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const UPADTE_CATEGORY = "UPDATE_CATEGORY";
export const WATCH_NEWS_BY_FOURNISSEUR_LIST = "WATCH_NEWS_BY_FOURNISSEUR_LIST";
export const WATCH_EVENTS_BY_FOURNISSEUR_LIST = "WATCH_EVENTS_BY_FOURNISSEUR_LIST";
export const GET_FOLDER_LIST_LOADING = "GET_FOLDER_LIST_LOADING";
export const GET_SUB_FOLDER_LIST_LOADING = "GET_SUB_FOLDER_LIST_LOADING";
export const GET_FILE_LIST_LOADING = "GET_FILE_LIST_LOADING";
export const WATCH_FOLDERBY_ID_USER_LIST = "WATCH_FOLDERBY_ID_USER_LIST";
export const GET_FOLDER_BY_ID_USER_LIST = "GET_FOLDER_BY_ID_USER_LIST";
export const WATCH_FILE_LIST_BY_ID_USER = "WATCH_FILE_LIST_BY_ID_USER";
export const GET_FILE_LIST_BY_ID_USER = "GET_FILE_LIST_BY_ID_USER";

export const DELETE_FOLDER_FROM_SHARE = "DELETE_FOLDER_FROM_SHARE";
export const DELETE_FILE_FROM_SHARE = "DELETE_FILE_FROM_SHARE";

export const WATCH_DEV_PROJET_LIST = "WATCH_DEV_PROJET_LIST";
export const GET_LIST_DEV_PROJECT = "GET_LIST_DEV_PROJECT";
export const WATCH_DEV_TACHE_LIST = "WATCH_DEV_TACHE_LIST";
export const WATCH_DEV_SOUS_TACHE_LIST = "WATCH_DEV_SOUS_TACHE_LIST";
export const GET_LIST_DEV_TACHE = "GET_LIST_DEV_TACHE";
export const GET_LIST_DEV_SOUS_TACHE = "GET_LIST_DEV_SOUS_TACHE";

export const ADD_NEW_DEV_TACHE = "ADD_NEW_DEV_TACHE";
export const ADD_NEW_DEV_SOUS_TACHE = "ADD_NEW_DEV_SOUS_TACHE";
export const REMOVE_DEV_PROJET = "REMOVE_DEV_PROJET";
export const REMOVE_DEV_TACHE = "REMOVE_DEV_TACHE";
export const REMOVE_DEV_SOUS_TACHE = "REMOVE_DEV_SOUS_TACHE";

export const UPDATE_DEV_PROJET = "UPDATE_DEV_PROJET";
export const UPADTE_DEV_TACHE = "UPADTE_DEV_TACHE";
export const UPADTE_DEV_SOUS_TACHE = "UPADTE_DEV_SOUS_TACHE";
export const ADD_DEV_COMMENT = "ADD_DEV_COMMENT";


export const ADD_DEV_SOUS_COMMENT = "ADD_DEV_SOUS_COMMENT";
export const WATCH_DEV_PROJET_LIST_BY_ID_USET = "WATCH_DEV_PROJET_LIST_BY_ID_USET";

//process 
export const WATCH_DEV_PROCESS_LIST = "WATCH_DEV_PROCESS_LIST";
export const GET_LIST_DEV_PROCESS = "GET_LIST_DEV_PROCESS";
export const WATCH_DEV_PROCESS_TACHE_LIST = "WATCH_DEV_PROCESS_TACHE_LIST";
export const WATCH_DEV_PROCESS_SOUS_TACHE_LIST = "WATCH_DEV_PROCESS_SOUS_TACHE_LIST";
export const GET_LIST_DEV_PROCESS_TACHE = "GET_LIST_DEV_PROCESS_TACHE";
export const GET_LIST_DEV_PROCESS_SOUS_TACHE = "GET_LIST_DEV_PROCESS_SOUS_TACHE";

export const ADD_NEW_DEV_PROCESS_TACHE = "ADD_NEW_DEV_PROCESS_TACHE";
export const ADD_NEW_DEV_PROCESS_SOUS_TACHE = "ADD_NEW_DEV_PROCESS_SOUS_TACHE";
export const REMOVE_DEV_PROCESS_PROJET = "REMOVE_DEV_PROCESS_PROJET";
export const REMOVE_DEV_PROCESS_TACHE = "REMOVE_DEV_PROCESS_TACHE";
export const REMOVE_DEV_PROCESS_SOUS_TACHE = "REMOVE_DEV_PROCESS_SOUS_TACHE";

export const UPDATE_DEV_PROCESS_PROJET = "UPDATE_DEV_PROCESS_PROJET";
export const UPADTE_DEV_PROCESS_TACHE = "UPADTE_DEV_PROCESS_TACHE";
export const UPADTE_DEV_PROCESS_SOUS_TACHE = "UPADTE_DEV_PROCESS_SOUS_TACHE";
export const ADD_DEV_PROCESS_COMMENT = "ADD_DEV_PROCESS_COMMENT";

export const ADD_NEW_SUB_FILE = "ADD_NEW_SUB_FILE";


export const ADD_DEV_PROCESS_SOUS_COMMENT = "ADD_DEV_PROCESS_SOUS_COMMENT";
export const WATCH_DEV_PROCESS_LIST_BY_ID_USET = "WATCH_DEV_PROCESS_LIST_BY_ID_USET";

export const ADD_NEW_PROCESS = "ADD_NEW_PROCESS"
export const ADD_NEW_DEV_PROCESS = "ADD_NEW_DEV_PROCESS"
export const ADD_DEV_PROCESS_NEW_TACHE = "ADD_DEV_PROCESS_NEW_TACHE"

export const UPDATE_DEV_PROCESS = "UPDATE_DEV_PROCESS"

