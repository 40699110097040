import {call,put, takeLatest} from "redux-saga/effects";
import {fetchProjetsByIdUserApi,  fetchOrdersProviderApi, fetchOrdersFranchiseApi, fetchProjetsApi,fetchTacheApi, fetchSousTacheApi } from "../../api";
import {GET_LIST_PROJECT, WATCH_PROJET_LIST,GET_LIST_TACHE, WATCH_TACHE_LIST, WATCH_SOUS_TACHE_LIST, GET_LIST_SOUS_TACHE, WATCH_PROJET_LIST_BY_ID_USET } from "../../redux/actionTypes";

function* fetchOrderPAsyn() {
    const Data = yield call(fetchProjetsApi);
    yield put({type : GET_LIST_PROJECT,projets : Data});
}
function* fetchOrderUAsyn() {
    const Data = yield call(fetchProjetsByIdUserApi);
    yield put({type : GET_LIST_PROJECT,projets : Data});
}
function* fetchOrderTacheAsyn({id}) {
    const Data = yield call(fetchTacheApi, id);

    yield put({type : GET_LIST_TACHE,taches : Data});
}
function* fetchSousTacheAsyn({id,idtache}) {
 
    const Data = yield call(fetchSousTacheApi, id, idtache);

    yield put({type : GET_LIST_SOUS_TACHE,taches : Data});
}
export function* watchProjetList() {
    
    yield takeLatest(WATCH_PROJET_LIST_BY_ID_USET,fetchOrderUAsyn)
    yield takeLatest(WATCH_PROJET_LIST,fetchOrderPAsyn)
    yield takeLatest(WATCH_TACHE_LIST,fetchOrderTacheAsyn)
    yield takeLatest(WATCH_SOUS_TACHE_LIST,fetchSousTacheAsyn)

    
}
